import cn from 'classnames';

const InfoIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Info', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 4C12.95 4 4 12.95 4 24C4 35.05 12.95 44 24 44C35.05 44 44 35.05 44 24C44 12.95 35.05 4 24 4ZM26 34H22V22H26V34ZM26 18H22V14H26V18Z" />
    </svg>
  );
};

export { InfoIcon };
