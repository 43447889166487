import * as Sentry from '@sentry/browser';
import * as React from 'react';

import { ALLOWED_ROUTES_FOR_MOBILE } from '@anchorage/frontoffice/utils/routes';

import { useWindowWidth } from 'hooks/useWindowWidth';

import MobilePage from './MobilePage';

type Props = { children: React.ReactElement };

const isRouteAllowedToRenderInMobile = (pathname: string) => {
  return ALLOWED_ROUTES_FOR_MOBILE.includes(pathname);
};

// The device checker handles small devices and renders a mobile-friendly page
// with help text explaining that we don't support mobile access
const DeviceChecker = ({ children }: Props) => {
  const { isMobile } = useWindowWidth();
  const [routeBypassesDeviceChecker, setRouteBypassesDeviceChecker] =
    React.useState(false);

  React.useEffect(() => {
    setRouteBypassesDeviceChecker(
      isRouteAllowedToRenderInMobile(window.location.pathname),
    );
  }, []);

  if (isMobile && !routeBypassesDeviceChecker) {
    // Track attempted mobile login event so we can see if people
    // are trying to access the client dashboard this way
    Sentry.withScope((scope) => {
      scope.setLevel('info');
      Sentry.captureMessage('attempted mobile login');
    });

    return <MobilePage />;
  }

  return children;
};

export default DeviceChecker;
