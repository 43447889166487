import { ApolloError } from '@apollo/client';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useAnalytics } from '@anchorage/analytics';
import { ActionResult } from '@anchorage/analytics/src/lib/constants';
import { FormWizard } from '@anchorage/common/dist/components';
import { useForm } from '@anchorage/common/dist/components/Form/_reactHookForm';
import { yupResolver } from '@anchorage/common/dist/components/Form/_yupResolver';
import { Step } from '@anchorage/common/dist/components/FormWizard/types';
import { useSnackbar } from '@anchorage/common/dist/components/Snackbar';
import {
  UIDeveloperError,
  WrappedError,
  reportError,
} from '@anchorage/common/dist/utils/errors';
import { getGraphQLQueryName } from '@anchorage/frontoffice/utils/getGraphQLQueryName';
import { useLocalStorage } from '@anchorage/hooks';

import VaultWalletsQuery from 'components/Vault/WalletsDrawer/graphql/VaultWallets.graphql';
import EndorseActionModal from 'components/shared/EndorseActionModal';

import { useDisclosureModal } from 'hooks/useDisclosureModal';

import getAmountInputStep from '../utils/getAmountInputStep';
import getAutoCompoundStep from '../utils/getAutoCompoundStep';
import { getStandardizedStakeFlowPageEventName } from '../utils/mixpanel';

import {
  DelegateOperation,
  DelegateType,
  OperationAction,
  StandardizedStakingStep,
  useOperationMakeVisibleInOperationsListMutation,
  usePrepareDelegationMutation,
} from 'generated/graphql';

import css from './styles.module.scss';

import { StandardizedStakeFormValues } from '../../types';

import FirstTimeStakingPage from '../../FirstTimeStakingPage';
import useStandardizedStakingContext from '../StandardizedStakingContext/useStandardizedStakingContext';
import {
  StandardizedStakeFlowWizardPage,
  StandardizedStakingMode,
} from '../constants';
import StandardizedAutoCompoundFormPage from './StandardizedAutoCompoundFormPage';
import StandardizedStakingFormPage from './StandardizedStakingFormPage';
import SSStakeUnstakeReviewPage from './StandardizedStakingReviewPage/SSStakeUnstakeReviewPage';
import { getStandardizedStakeFormValidation } from './validation';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  disclosureID: string;
};

export const StandardizedStakeWizard: React.FC<Props> = ({
  isOpen,
  onClose,
  disclosureID,
}) => {
  const { addSnackbar } = useSnackbar();
  const { track } = useAnalytics();

  const { walletWithStakingInfo, standardizedStakingInfo, assetTypeInfo } =
    useStandardizedStakingContext();

  const asset = walletWithStakingInfo.assets[0]!;
  const vault = walletWithStakingInfo.vault;
  const vaultSubID = vault.vaultID.vaultSubID;
  const accountID = vault.account.accountID;

  const [operationIDToEndorse, setOperationIDToEndorse] = React.useState('');

  const [isDisclosureReadEntirely, setIsDisclosureReadEntirely] =
    useState(false);

  const handleMarkDisclosureAsRead = () => setIsDisclosureReadEntirely(true);

  const {
    resolverSchema,
    defaultAmount,
    autoCompoundFormStep,
    showAutoCompoundStep,
  } = useMemo(() => {
    const stakeFormSteps = standardizedStakingInfo?.stakeForm?.steps ?? [];

    const resolverSchema = getStandardizedStakeFormValidation({
      stakingSteps: stakeFormSteps,
    });

    const amountInputStep = getAmountInputStep(stakeFormSteps);
    const defaultAmount = amountInputStep?.defaultAmount?.value || '0';

    const autoCompoundFormStep = getAutoCompoundStep(
      standardizedStakingInfo?.stakeForm?.steps ?? [],
    );

    // AutoCompound should not be visible for Porto orgs
    // This is driven by BE by returning autoCompoundFormStep as null
    const showAutoCompoundStep = !!autoCompoundFormStep;

    return {
      resolverSchema,
      defaultAmount,
      autoCompoundFormStep,
      showAutoCompoundStep,
    };
  }, [standardizedStakingInfo]);

  const form = useForm<StandardizedStakeFormValues>({
    mode: 'onChange',
    defaultValues: {
      validatorAddress: '',
      amount: defaultAmount,
      comment: '',
    },
    resolver: yupResolver(resolverSchema),
  });

  const {
    getValues,
    formState: { isValid },
  } = form;

  const {
    disclosureComponent: DisclosurePage,
    hasAcceptedDisclosure,
    acceptDisclosureMutation,
  } = useDisclosureModal({
    disclosureID,
    accountID: accountID || '',
    handleMarkDisclosureAsRead,
  });

  const assetAbbreviation = assetTypeInfo.abbreviation || '';
  const walletName = walletWithStakingInfo.name;
  const walletAddress = walletWithStakingInfo.depositAddress.address;

  const [
    hasPreviouslyCompletedStakingFlow,
    setHasPreviouslyCompletedStakingFlow,
  ] = useLocalStorage(`${assetAbbreviation}StakingFlowCompleted`, false);

  const startingStep = useMemo(() => {
    if (!hasPreviouslyCompletedStakingFlow) {
      return StandardizedStakeFlowWizardPage.FIRST_TIME_STAKING;
    }

    if (!hasAcceptedDisclosure) {
      return StandardizedStakeFlowWizardPage.DISCLOSURE;
    }

    return StandardizedStakeFlowWizardPage.STAKE;
  }, [hasPreviouslyCompletedStakingFlow, hasAcceptedDisclosure]);

  const trackStakingPage = React.useCallback(
    (initiationPage: StandardizedStakeFlowWizardPage) => {
      track({
        name: 'standardized_staking:continue:clicked',
        data: {
          initiationPage: getStandardizedStakeFlowPageEventName(initiationPage),
          stakingType: StandardizedStakingMode.STAKE,
          assetTypeID: assetTypeInfo.assetTypeID,
        },
      });
    },
    [track, assetTypeInfo],
  );

  const [
    prepareDelegationMutation,
    { data: preparedOpData, loading: isLoadingPrepareOp },
  ] = usePrepareDelegationMutation({
    fetchPolicy: 'no-cache',
    variables: {
      amount: getValues('amount').toString(),
      assetSubID: asset.assetID.assetSubID || '',
      assetTypeID: assetTypeInfo.assetTypeID || '',
      clientID: uuidv4(),
      delegateType: DelegateType.DELEGATE,
      destination: getValues('validatorAddress').toString(),
      withdrawalAddress: walletAddress,
      message: '',
      vaultSubID: vaultSubID || '',
    },
    onError: (error) => {
      addSnackbar({
        type: 'error',
        text: error.message || 'There was an error previewing your operation.',
        subtext: 'Please try again',
      });
      track({
        name: 'standardized_staking:wizard:flow_completed',
        data: {
          stakingType: StandardizedStakingMode.STAKE,
          initiationPage: getStandardizedStakeFlowPageEventName(
            StandardizedStakeFlowWizardPage.STAKE,
          ),
          status: ActionResult.FAIL,
          assetTypeID: assetTypeInfo.assetTypeID,
        },
      });
      reportError(
        new WrappedError(
          `There was an error preparing the stake operation`,
          error,
        ),
      );
    },
  });

  const delegateOperationData =
    preparedOpData?.prepareDelegation as DelegateOperation;
  const preparedOperationID = delegateOperationData?.operationID || '';

  const handleMakeVisibleOpMutationError = React.useCallback(
    (error?: ApolloError | null) => {
      addSnackbar({
        type: 'error',
        text:
          (error && error.message) ||
          'There was an error creating the stake operation',
      });

      track({
        name: 'standardized_staking:wizard:flow_completed',
        data: {
          stakingType: StandardizedStakingMode.STAKE,
          initiationPage: getStandardizedStakeFlowPageEventName(
            StandardizedStakeFlowWizardPage.REVIEW,
          ),
          status: ActionResult.FAIL,
          assetTypeID: assetTypeInfo.assetTypeID,
        },
      });

      if (error) {
        reportError(
          new WrappedError(
            `There was an error creating the stake operation`,
            error,
          ),
        );
      }
    },
    [addSnackbar, track, assetTypeInfo],
  );

  const [createStakeOperation, { loading: isCreateStakeOperationLoading }] =
    useOperationMakeVisibleInOperationsListMutation({
      onCompleted: (data) => {
        const success = data.operationMakeVisibleInOperationsList;
        if (success) {
          setHasPreviouslyCompletedStakingFlow(true);
          setOperationIDToEndorse(preparedOperationID);
          track({
            name: 'standardized_staking:wizard:flow_completed',
            data: {
              stakingType: StandardizedStakingMode.STAKE,
              status: ActionResult.SUCCESS,
              assetTypeID: assetTypeInfo.assetTypeID,
            },
          });
        } else {
          handleMakeVisibleOpMutationError();
        }
      },
      refetchQueries: [getGraphQLQueryName(VaultWalletsQuery)],
      onError: handleMakeVisibleOpMutationError,
    });

  const handleSubmit = () => {
    if (!preparedOperationID) {
      addSnackbar({
        type: 'error',
        text: 'There was an error creating the stake operation',
      });
      reportError(
        new UIDeveloperError(
          'preparedOperationID is empty or null. Cannot create stake operation.',
        ),
      );
      return;
    }

    trackStakingPage(StandardizedStakeFlowWizardPage.REVIEW);

    createStakeOperation({
      variables: { operationID: preparedOperationID },
    });
  };

  const steps: Step<StandardizedStakeFormValues>[] = [
    {
      title: `Collect rewards through staking your ${assetAbbreviation}`,
      element: (
        <FirstTimeStakingPage
          title={`Put your ${assetAbbreviation} to work by staking with a validator,
and collect rewards`}
        />
      ),
      nextBtnProps: {
        children: 'Continue',
      },
      backBtnProps: {
        style: {
          display: 'none',
        },
      },
      onNext(goToStepIndex) {
        trackStakingPage(StandardizedStakeFlowWizardPage.FIRST_TIME_STAKING);

        if (hasAcceptedDisclosure) {
          goToStepIndex(StandardizedStakeFlowWizardPage.STAKE);
        } else {
          goToStepIndex(StandardizedStakeFlowWizardPage.DISCLOSURE);
        }
      },
      showProgress: false,
    },
    {
      title: 'Terms and conditions',
      element: DisclosurePage,
      nextBtnProps: {
        children: 'Accept',
        isDisabled: !isDisclosureReadEntirely,
      },
      backBtnProps: {
        style: {
          display: 'none',
        },
      },
      showProgress: false,
      onNext(goToStepIndex) {
        trackStakingPage(StandardizedStakeFlowWizardPage.DISCLOSURE);

        acceptDisclosureMutation({
          onCompleted: () => {
            goToStepIndex(StandardizedStakeFlowWizardPage.STAKE);
          },
        });
      },
    },
    {
      title: `Stake ${assetAbbreviation}`,
      description: walletName,
      formFieldNames: ['validatorAddress', 'amount'],
      element: <StandardizedStakingFormPage />,
      showProgress: true,
      displayedStepNumber: 1,
      nextBtnProps: {
        children: 'Preview Operation',
        isDisabled: !isValid || isLoadingPrepareOp,
        loading: isLoadingPrepareOp,
      },
      backBtnProps: {
        style: {
          display: 'none',
        },
      },
      onNext(goToStepIndex) {
        trackStakingPage(StandardizedStakeFlowWizardPage.STAKE);

        if (showAutoCompoundStep) {
          goToStepIndex(StandardizedStakeFlowWizardPage.AUTO_CLAIM_REWARDS);
        } else {
          prepareDelegationMutation({
            onCompleted: () => {
              goToStepIndex(StandardizedStakeFlowWizardPage.REVIEW);
            },
          });
        }
      },
    },
    {
      title: autoCompoundFormStep?.title || '',
      element: autoCompoundFormStep && (
        <StandardizedAutoCompoundFormPage
          autoCompoundFormStep={
            autoCompoundFormStep as StandardizedStakingStep.AutoCompoundStepInlineFragment
          }
        />
      ),
      showProgress: false,
      nextBtnProps: {
        children: 'Continue',
        isDisabled: isLoadingPrepareOp,
        loading: isLoadingPrepareOp,
      },
      backBtnProps: {
        children: 'Go back',
      },
      onNext(goToStepIndex) {
        trackStakingPage(StandardizedStakeFlowWizardPage.AUTO_CLAIM_REWARDS);

        prepareDelegationMutation({
          onCompleted: () => {
            goToStepIndex(StandardizedStakeFlowWizardPage.REVIEW);
          },
        });
      },
    },
    {
      title: 'Preview operation',
      formFieldNames: ['comment'],
      element: (
        <SSStakeUnstakeReviewPage data={preparedOpData?.prepareDelegation} />
      ),
      showProgress: true,
      isSubmitStep: true,
      displayedStepNumber: 2,
      nextBtnProps: {
        children: 'Confirm and submit for approval',
        isDisabled: !isValid || isCreateStakeOperationLoading,
        loading: isCreateStakeOperationLoading,
      },
      backBtnProps: {
        children: 'Go back',
      },
      onPrevious: (goToStepIndex) => {
        if (showAutoCompoundStep) {
          goToStepIndex(StandardizedStakeFlowWizardPage.AUTO_CLAIM_REWARDS);
        } else {
          goToStepIndex(StandardizedStakeFlowWizardPage.STAKE);
        }
      },
    },
  ];

  return (
    <>
      <FormWizard<StandardizedStakeFormValues>
        isOpen={isOpen}
        onClose={() => {
          form.reset();
          onClose();
        }}
        form={form}
        steps={steps}
        onSubmit={handleSubmit}
        modalProps={{
          wrapperClassName: css.modalWrapper,
          allowBackdropClose: true,
          allowEscapeClose: true,
        }}
        startingStep={startingStep}
        displayedMaxSteps={2}
      />
      {operationIDToEndorse ? (
        <EndorseActionModal
          isVisible={!!operationIDToEndorse}
          onClose={() => {
            setOperationIDToEndorse('');
            onClose();
            form.reset();
          }}
          operationID={operationIDToEndorse}
          operationAction={OperationAction.GENERIC}
        />
      ) : null}
    </>
  );
};
