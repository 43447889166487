// @Generated
// This is a generated file, DO NOT MODIFY
import AccountBalanceIcon from './AccountBalanceIcon';
import AccountIcon from './AccountIcon';
import AddCircleIcon from './AddCircleIcon';
import AddIcon from './AddIcon';
import AddOutlineIcon from './AddOutlineIcon';
import AddUserIcon from './AddUserIcon';
import AlertIcon from './AlertIcon';
import AlphabeticalIcon from './AlphabeticalIcon';
import AnchorageDigitalLogoIcon from './AnchorageDigitalLogoIcon';
import AnchorageLogoIcon from './AnchorageLogoIcon';
import AnchorageLogoOutlineIcon from './AnchorageLogoOutlineIcon';
import AnnouncementIcon from './AnnouncementIcon';
import AppIcon from './AppIcon';
import ArchiveIcon from './ArchiveIcon';
import ArrowDownIcon from './ArrowDownIcon';
import AtlasIcon from './AtlasIcon';
import AutoClaimIcon from './AutoClaimIcon';
import BalanceIcon from './BalanceIcon';
import BankTransferInIcon from './BankTransferInIcon';
import BankTransferOutIcon from './BankTransferOutIcon';
import BracketsIcon from './BracketsIcon';
import BrowserTransferIcon from './BrowserTransferIcon';
import BulletListIcon from './BulletListIcon';
import CalendarIcon from './CalendarIcon';
import CalendarV2Icon from './CalendarV2Icon';
import CancelIcon from './CancelIcon';
import ChatIcon from './ChatIcon';
import CheckIcon from './CheckIcon';
import ChevronIcon from './ChevronIcon';
import Circle from './Circle';
import CircleOutline from './CircleOutline';
import CircleRemoveIcon from './CircleRemoveIcon';
import ClockCircleIcon from './ClockCircleIcon';
import ClockIcon from './ClockIcon';
import CloseIcon from './CloseIcon';
import CloudDoneIcon from './CloudDoneIcon';
import CodeIcon from './CodeIcon';
import CoinIcon from './CoinIcon';
import CompleteArrowForwardIcon from './CompleteArrowForwardIcon';
import CompleteIcon from './CompleteIcon';
import CompleteOutlineIcon from './CompleteOutlineIcon';
import ConfigurationPanelIcon from './ConfigurationPanelIcon';
import ConfusedAstronaut from './ConfusedAstronaut';
import ConnectionsIcon from './ConnectionsIcon';
import ContactIcon from './ContactIcon';
import CopyIcon from './CopyIcon';
import DAppIcon from './DAppIcon';
import DeleteIcon from './DeleteIcon';
import DesktopIcon from './DesktopIcon';
import Directory from './Directory';
import DoNotDisturbIcon from './DoNotDisturbIcon';
import DoNotDisturbOutlineIcon from './DoNotDisturbOutlineIcon';
import DocumentArrowsIcon from './DocumentArrowsIcon';
import DocumentCoinsIcon from './DocumentCoinsIcon';
import DocumentWarningIcon from './DocumentWarningIcon';
import DollarSignIcon from './DollarSignIcon';
import DomainIcon from './DomainIcon';
import DoubleCheckIcon from './DoubleCheckIcon';
import DownloadIcon from './DownloadIcon';
import DownloadKeyIcon from './DownloadKeyIcon';
import EditIcon from './EditIcon';
import EmailIcon from './EmailIcon';
import EmailKeyIcon from './EmailKeyIcon';
import EmptyPageAddIcon from './EmptyPageAddIcon';
import EmptyPersonIcon from './EmptyPersonIcon';
import EnterIcon from './EnterIcon';
import EntityIcon from './EntityIcon';
import ErrorIcon from './ErrorIcon';
import ErrorOutlineIcon from './ErrorOutlineIcon';
import ErrorOutlineTriangleIcon from './ErrorOutlineTriangleIcon';
import EthIcon from './EthIcon';
import ExitIcon from './ExitIcon';
import ExpandIcon from './ExpandIcon';
import ExposureIcon from './ExposureIcon';
import FaceIDIcon from './FaceIDIcon';
import FacilitiesIcon from './FacilitiesIcon';
import FileIcon from './FileIcon';
import FileInvoiceIcon from './FileInvoiceIcon';
import FileTableIcon from './FileTableIcon';
import FilterListIcon from './FilterListIcon';
import FinancingDashboardIcon from './FinancingDashboardIcon';
import FingerprintIcon from './FingerprintIcon';
import FirstPageIcon from './FirstPageIcon';
import FolderOpenIcon from './FolderOpenIcon';
import GasIcon from './GasIcon';
import GasStation from './GasStation';
import GiftIcon from './GiftIcon';
import GlobeIcon from './GlobeIcon';
import GridViewIcon from './GridViewIcon';
import HighQuantityIcon from './HighQuantityIcon';
import HomeIcon from './HomeIcon';
import HourglassIcon from './HourglassIcon';
import HumanUserIcon from './HumanUserIcon';
import HyperlinkIcon from './HyperlinkIcon';
import ImageIcon from './ImageIcon';
import ImportContactsIcon from './ImportContactsIcon';
import InfoIcon from './InfoIcon';
import InfoOutlineIcon from './InfoOutlineIcon';
import InvalidLinkIcon from './InvalidLinkIcon';
import InvoiceIcon from './InvoiceIcon';
import InvoiceWithUserIcon from './InvoiceWithUserIcon';
import JiraLinkIcon from './JiraLinkIcon';
import KeyIcon from './KeyIcon';
import LaptopIcon from './LaptopIcon';
import LayersIcon from './LayersIcon';
import LexisNexisIcon from './LexisNexisIcon';
import LineChart from './LineChart';
import ListIcon from './ListIcon';
import LocationOffIcon from './LocationOffIcon';
import LocationOutlineIcon from './LocationOutlineIcon';
import LockIcon from './LockIcon';
import LowQuantityIcon from './LowQuantityIcon';
import MailIcon from './MailIcon';
import MembershipsIcon from './MembershipsIcon';
import MessageIcon from './MessageIcon';
import MicrophoneIcon from './MicrophoneIcon';
import MinusIcon from './MinusIcon';
import MoneyIcon from './MoneyIcon';
import MoreIcon from './MoreIcon';
import MultiUserApprovalIcon from './MultiUserApprovalIcon';
import NFTIcon from './NFTIcon';
import NewReleaseIcon from './NewReleaseIcon';
import NoImageIcon from './NoImageIcon';
import OfficeIcon from './OfficeIcon';
import OverviewIcon from './OverviewIcon';
import PartyIcon from './PartyIcon';
import PauseIcon from './PauseIcon';
import PersonCalendarIcon from './PersonCalendarIcon';
import PersonIcon from './PersonIcon';
import PersonIdentificationIcon from './PersonIdentificationIcon';
import PersonPinIcon from './PersonPinIcon';
import PhoneLockIcon from './PhoneLockIcon';
import PieChartIcon from './PieChartIcon';
import PlayArrowIcon from './PlayArrowIcon';
import PolicyIcon from './PolicyIcon';
import PortoIcon from './PortoIcon';
import PortoLogoIcon from './PortoLogoIcon';
import ProductTradeIcon from './ProductTradeIcon';
import PuzzleIcon from './PuzzleIcon';
import QRCodeIcon from './QRCodeIcon';
import RadioButtonIcon from './RadioButtonIcon';
import ReceiptIcon from './ReceiptIcon';
import RefreshIcon from './RefreshIcon';
import RemoveOutlineIcon from './RemoveOutlineIcon';
import ReportsIcon from './ReportsIcon';
import RestoreIcon from './RestoreIcon';
import RiskDashboardIcon from './RiskDashboardIcon';
import SearchCircleIcon from './SearchCircleIcon';
import SearchIcon from './SearchIcon';
import SearchMapIcon from './SearchMapIcon';
import SecurityIcon from './SecurityIcon';
import SettingsIcon from './SettingsIcon';
import SettlementsIcon from './SettlementsIcon';
import SnapshotIcon from './SnapshotIcon';
import SortIcon from './SortIcon';
import StakingCoinsIcon from './StakingCoinsIcon';
import StakingCoinsWithHandIcon from './StakingCoinsWithHandIcon';
import StarCircleIcon from './StarCircleIcon';
import SupervisorIcon from './SupervisorIcon';
import SwapIcon from './SwapIcon';
import SyncIcon from './SyncIcon';
import TRMIcon from './TRMIcon';
import TagIcon from './TagIcon';
import TestIcon from './TestIcon';
import ThreeCoinsIcon from './ThreeCoinsIcon';
import TradeDashboardIcon from './TradeDashboardIcon';
import TradeIcon from './TradeIcon';
import TransactionRequestsIcon from './TransactionRequestsIcon';
import TrashBinIcon from './TrashBinIcon';
import UnarchiveIcon from './UnarchiveIcon';
import UnsupportedAssetCircleIcon from './UnsupportedAssetCircleIcon';
import UnsupportedAssetIcon from './UnsupportedAssetIcon';
import UploadIcon from './UploadIcon';
import UserCircleIcon from './UserCircleIcon';
import UserIcon from './UserIcon';
import UserUnionIcon from './UserUnionIcon';
import VPNLockIcon from './VPNLockIcon';
import VaultIcon from './VaultIcon';
import VerifiedIcon from './VerifiedIcon';
import VideocamIcon from './VideocamIcon';
import VideocamOffIcon from './VideocamOffIcon';
import WalletAddIcon from './WalletAddIcon';
import WalletConnectIcon from './WalletConnectIcon';
import WalletIcon from './WalletIcon';
import WebIcon from './WebIcon';
import WidgetIcon from './WidgetIcon';
import WorkIcon from './WorkIcon';
import WorldPinIcon from './WorldPinIcon';
import ZoomInIcon from './ZoomInIcon';

export { default as AccountBalanceIcon } from './AccountBalanceIcon';
export { default as AccountIcon } from './AccountIcon';
export { default as AddCircleIcon } from './AddCircleIcon';
export { default as AddIcon } from './AddIcon';
export { default as AddOutlineIcon } from './AddOutlineIcon';
export { default as AddUserIcon } from './AddUserIcon';
export { default as AlertIcon } from './AlertIcon';
export { default as AlphabeticalIcon } from './AlphabeticalIcon';
export { default as AnchorageDigitalLogoIcon } from './AnchorageDigitalLogoIcon';
export { default as AnchorageLogoIcon } from './AnchorageLogoIcon';
export { default as AnchorageLogoOutlineIcon } from './AnchorageLogoOutlineIcon';
export { default as AnnouncementIcon } from './AnnouncementIcon';
export { default as AppIcon } from './AppIcon';
export { default as ArchiveIcon } from './ArchiveIcon';
export { default as ArrowDownIcon } from './ArrowDownIcon';
export { default as AtlasIcon } from './AtlasIcon';
export { default as AutoClaimIcon } from './AutoClaimIcon';
export { default as BalanceIcon } from './BalanceIcon';
export { default as BankTransferInIcon } from './BankTransferInIcon';
export { default as BankTransferOutIcon } from './BankTransferOutIcon';
export { default as BracketsIcon } from './BracketsIcon';
export { default as BrowserTransferIcon } from './BrowserTransferIcon';
export { default as BulletListIcon } from './BulletListIcon';
export { default as CalendarIcon } from './CalendarIcon';
export { default as CalendarV2Icon } from './CalendarV2Icon';
export { default as CancelIcon } from './CancelIcon';
export { default as ChatIcon } from './ChatIcon';
export { default as CheckIcon } from './CheckIcon';
export { default as ChevronIcon } from './ChevronIcon';
export { default as Circle } from './Circle';
export { default as CircleOutline } from './CircleOutline';
export { default as CircleRemoveIcon } from './CircleRemoveIcon';
export { default as ClockCircleIcon } from './ClockCircleIcon';
export { default as ClockIcon } from './ClockIcon';
export { default as CloseIcon } from './CloseIcon';
export { default as CloudDoneIcon } from './CloudDoneIcon';
export { default as CodeIcon } from './CodeIcon';
export { default as CoinIcon } from './CoinIcon';
export { default as CompleteArrowForwardIcon } from './CompleteArrowForwardIcon';
export { default as CompleteIcon } from './CompleteIcon';
export { default as CompleteOutlineIcon } from './CompleteOutlineIcon';
export { default as ConfigurationPanelIcon } from './ConfigurationPanelIcon';
export { default as ConfusedAstronaut } from './ConfusedAstronaut';
export { default as ConnectionsIcon } from './ConnectionsIcon';
export { default as ContactIcon } from './ContactIcon';
export { default as CopyIcon } from './CopyIcon';
export { default as DAppIcon } from './DAppIcon';
export { default as DeleteIcon } from './DeleteIcon';
export { default as DesktopIcon } from './DesktopIcon';
export { default as Directory } from './Directory';
export { default as DoNotDisturbIcon } from './DoNotDisturbIcon';
export { default as DoNotDisturbOutlineIcon } from './DoNotDisturbOutlineIcon';
export { default as DocumentArrowsIcon } from './DocumentArrowsIcon';
export { default as DocumentCoinsIcon } from './DocumentCoinsIcon';
export { default as DocumentWarningIcon } from './DocumentWarningIcon';
export { default as DollarSignIcon } from './DollarSignIcon';
export { default as DomainIcon } from './DomainIcon';
export { default as DoubleCheckIcon } from './DoubleCheckIcon';
export { default as DownloadIcon } from './DownloadIcon';
export { default as DownloadKeyIcon } from './DownloadKeyIcon';
export { default as EditIcon } from './EditIcon';
export { default as EmailIcon } from './EmailIcon';
export { default as EmailKeyIcon } from './EmailKeyIcon';
export { default as EmptyPageAddIcon } from './EmptyPageAddIcon';
export { default as EmptyPersonIcon } from './EmptyPersonIcon';
export { default as EnterIcon } from './EnterIcon';
export { default as EntityIcon } from './EntityIcon';
export { default as ErrorIcon } from './ErrorIcon';
export { default as ErrorOutlineIcon } from './ErrorOutlineIcon';
export { default as ErrorOutlineTriangleIcon } from './ErrorOutlineTriangleIcon';
export { default as EthIcon } from './EthIcon';
export { default as ExitIcon } from './ExitIcon';
export { default as ExpandIcon } from './ExpandIcon';
export { default as ExposureIcon } from './ExposureIcon';
export { default as FaceIDIcon } from './FaceIDIcon';
export { default as FacilitiesIcon } from './FacilitiesIcon';
export { default as FileIcon } from './FileIcon';
export { default as FileInvoiceIcon } from './FileInvoiceIcon';
export { default as FileTableIcon } from './FileTableIcon';
export { default as FilterListIcon } from './FilterListIcon';
export { default as FinancingDashboardIcon } from './FinancingDashboardIcon';
export { default as FingerprintIcon } from './FingerprintIcon';
export { default as FirstPageIcon } from './FirstPageIcon';
export { default as FolderOpenIcon } from './FolderOpenIcon';
export { default as GasIcon } from './GasIcon';
export { default as GasStation } from './GasStation';
export { default as GiftIcon } from './GiftIcon';
export { default as GlobeIcon } from './GlobeIcon';
export { default as GridViewIcon } from './GridViewIcon';
export { default as HighQuantityIcon } from './HighQuantityIcon';
export { default as HomeIcon } from './HomeIcon';
export { default as HourglassIcon } from './HourglassIcon';
export { default as HumanUserIcon } from './HumanUserIcon';
export { default as HyperlinkIcon } from './HyperlinkIcon';
export { default as ImageIcon } from './ImageIcon';
export { default as ImportContactsIcon } from './ImportContactsIcon';
export { default as InfoIcon } from './InfoIcon';
export { default as InfoOutlineIcon } from './InfoOutlineIcon';
export { default as InvalidLinkIcon } from './InvalidLinkIcon';
export { default as InvoiceIcon } from './InvoiceIcon';
export { default as InvoiceWithUserIcon } from './InvoiceWithUserIcon';
export { default as JiraLinkIcon } from './JiraLinkIcon';
export { default as KeyIcon } from './KeyIcon';
export { default as LaptopIcon } from './LaptopIcon';
export { default as LayersIcon } from './LayersIcon';
export { default as LexisNexisIcon } from './LexisNexisIcon';
export { default as LineChart } from './LineChart';
export { default as ListIcon } from './ListIcon';
export { default as LocationOffIcon } from './LocationOffIcon';
export { default as LocationOutlineIcon } from './LocationOutlineIcon';
export { default as LockIcon } from './LockIcon';
export { default as LowQuantityIcon } from './LowQuantityIcon';
export { default as MailIcon } from './MailIcon';
export { default as MembershipsIcon } from './MembershipsIcon';
export { default as MessageIcon } from './MessageIcon';
export { default as MicrophoneIcon } from './MicrophoneIcon';
export { default as MinusIcon } from './MinusIcon';
export { default as MoneyIcon } from './MoneyIcon';
export { default as MoreIcon } from './MoreIcon';
export { default as MultiUserApprovalIcon } from './MultiUserApprovalIcon';
export { default as NFTIcon } from './NFTIcon';
export { default as NewReleaseIcon } from './NewReleaseIcon';
export { default as NoImageIcon } from './NoImageIcon';
export { default as OfficeIcon } from './OfficeIcon';
export { default as OverviewIcon } from './OverviewIcon';
export { default as PartyIcon } from './PartyIcon';
export { default as PauseIcon } from './PauseIcon';
export { default as PersonCalendarIcon } from './PersonCalendarIcon';
export { default as PersonIcon } from './PersonIcon';
export { default as PersonIdentificationIcon } from './PersonIdentificationIcon';
export { default as PersonPinIcon } from './PersonPinIcon';
export { default as PhoneLockIcon } from './PhoneLockIcon';
export { default as PieChartIcon } from './PieChartIcon';
export { default as PlayArrowIcon } from './PlayArrowIcon';
export { default as PolicyIcon } from './PolicyIcon';
export { default as PortoIcon } from './PortoIcon';
export { default as PortoLogoIcon } from './PortoLogoIcon';
export { default as ProductTradeIcon } from './ProductTradeIcon';
export { default as PuzzleIcon } from './PuzzleIcon';
export { default as QRCodeIcon } from './QRCodeIcon';
export { default as RadioButtonIcon } from './RadioButtonIcon';
export { default as ReceiptIcon } from './ReceiptIcon';
export { default as RefreshIcon } from './RefreshIcon';
export { default as RemoveOutlineIcon } from './RemoveOutlineIcon';
export { default as ReportsIcon } from './ReportsIcon';
export { default as RestoreIcon } from './RestoreIcon';
export { default as RiskDashboardIcon } from './RiskDashboardIcon';
export { default as SearchCircleIcon } from './SearchCircleIcon';
export { default as SearchIcon } from './SearchIcon';
export { default as SearchMapIcon } from './SearchMapIcon';
export { default as SecurityIcon } from './SecurityIcon';
export { default as SettingsIcon } from './SettingsIcon';
export { default as SettlementsIcon } from './SettlementsIcon';
export { default as SnapshotIcon } from './SnapshotIcon';
export { default as SortIcon } from './SortIcon';
export { default as StakingCoinsIcon } from './StakingCoinsIcon';
export { default as StakingCoinsWithHandIcon } from './StakingCoinsWithHandIcon';
export { default as StarCircleIcon } from './StarCircleIcon';
export { default as SupervisorIcon } from './SupervisorIcon';
export { default as SwapIcon } from './SwapIcon';
export { default as SyncIcon } from './SyncIcon';
export { default as TRMIcon } from './TRMIcon';
export { default as TagIcon } from './TagIcon';
export { default as TestIcon } from './TestIcon';
export { default as ThreeCoinsIcon } from './ThreeCoinsIcon';
export { default as TradeDashboardIcon } from './TradeDashboardIcon';
export { default as TradeIcon } from './TradeIcon';
export { default as TransactionRequestsIcon } from './TransactionRequestsIcon';
export { default as TrashBinIcon } from './TrashBinIcon';
export { default as UnarchiveIcon } from './UnarchiveIcon';
export { default as UnsupportedAssetCircleIcon } from './UnsupportedAssetCircleIcon';
export { default as UnsupportedAssetIcon } from './UnsupportedAssetIcon';
export { default as UploadIcon } from './UploadIcon';
export { default as UserCircleIcon } from './UserCircleIcon';
export { default as UserIcon } from './UserIcon';
export { default as UserUnionIcon } from './UserUnionIcon';
export { default as VPNLockIcon } from './VPNLockIcon';
export { default as VaultIcon } from './VaultIcon';
export { default as VerifiedIcon } from './VerifiedIcon';
export { default as VideocamIcon } from './VideocamIcon';
export { default as VideocamOffIcon } from './VideocamOffIcon';
export { default as WalletAddIcon } from './WalletAddIcon';
export { default as WalletConnectIcon } from './WalletConnectIcon';
export { default as WalletIcon } from './WalletIcon';
export { default as WebIcon } from './WebIcon';
export { default as WidgetIcon } from './WidgetIcon';
export { default as WorkIcon } from './WorkIcon';
export { default as WorldPinIcon } from './WorldPinIcon';
export { default as ZoomInIcon } from './ZoomInIcon';

export default {
  AccountBalanceIcon,
  AccountIcon,
  AddCircleIcon,
  AddIcon,
  AddOutlineIcon,
  AddUserIcon,
  AlertIcon,
  AlphabeticalIcon,
  AnchorageDigitalLogoIcon,
  AnchorageLogoIcon,
  AnchorageLogoOutlineIcon,
  AnnouncementIcon,
  AppIcon,
  ArchiveIcon,
  ArrowDownIcon,
  AtlasIcon,
  AutoClaimIcon,
  BalanceIcon,
  BankTransferInIcon,
  BankTransferOutIcon,
  BracketsIcon,
  BrowserTransferIcon,
  BulletListIcon,
  CalendarIcon,
  CalendarV2Icon,
  CancelIcon,
  ChatIcon,
  CheckIcon,
  ChevronIcon,
  Circle,
  CircleOutline,
  CircleRemoveIcon,
  ClockCircleIcon,
  ClockIcon,
  CloseIcon,
  CloudDoneIcon,
  CodeIcon,
  CoinIcon,
  CompleteArrowForwardIcon,
  CompleteIcon,
  CompleteOutlineIcon,
  ConfigurationPanelIcon,
  ConfusedAstronaut,
  ConnectionsIcon,
  ContactIcon,
  CopyIcon,
  DAppIcon,
  DeleteIcon,
  DesktopIcon,
  Directory,
  DoNotDisturbIcon,
  DoNotDisturbOutlineIcon,
  DocumentArrowsIcon,
  DocumentCoinsIcon,
  DocumentWarningIcon,
  DollarSignIcon,
  DomainIcon,
  DoubleCheckIcon,
  DownloadIcon,
  DownloadKeyIcon,
  EditIcon,
  EmailIcon,
  EmailKeyIcon,
  EmptyPageAddIcon,
  EmptyPersonIcon,
  EnterIcon,
  EntityIcon,
  ErrorIcon,
  ErrorOutlineIcon,
  ErrorOutlineTriangleIcon,
  EthIcon,
  ExitIcon,
  ExpandIcon,
  ExposureIcon,
  FaceIDIcon,
  FacilitiesIcon,
  FileIcon,
  FileInvoiceIcon,
  FileTableIcon,
  FilterListIcon,
  FinancingDashboardIcon,
  FingerprintIcon,
  FirstPageIcon,
  FolderOpenIcon,
  GasIcon,
  GasStation,
  GiftIcon,
  GlobeIcon,
  GridViewIcon,
  HighQuantityIcon,
  HomeIcon,
  HourglassIcon,
  HumanUserIcon,
  HyperlinkIcon,
  ImageIcon,
  ImportContactsIcon,
  InfoIcon,
  InfoOutlineIcon,
  InvalidLinkIcon,
  InvoiceIcon,
  InvoiceWithUserIcon,
  JiraLinkIcon,
  KeyIcon,
  LaptopIcon,
  LayersIcon,
  LexisNexisIcon,
  LineChart,
  ListIcon,
  LocationOffIcon,
  LocationOutlineIcon,
  LockIcon,
  LowQuantityIcon,
  MailIcon,
  MembershipsIcon,
  MessageIcon,
  MicrophoneIcon,
  MinusIcon,
  MoneyIcon,
  MoreIcon,
  MultiUserApprovalIcon,
  NFTIcon,
  NewReleaseIcon,
  NoImageIcon,
  OfficeIcon,
  OverviewIcon,
  PartyIcon,
  PauseIcon,
  PersonCalendarIcon,
  PersonIcon,
  PersonIdentificationIcon,
  PersonPinIcon,
  PhoneLockIcon,
  PieChartIcon,
  PlayArrowIcon,
  PolicyIcon,
  PortoIcon,
  PortoLogoIcon,
  ProductTradeIcon,
  PuzzleIcon,
  QRCodeIcon,
  RadioButtonIcon,
  ReceiptIcon,
  RefreshIcon,
  RemoveOutlineIcon,
  ReportsIcon,
  RestoreIcon,
  RiskDashboardIcon,
  SearchCircleIcon,
  SearchIcon,
  SearchMapIcon,
  SecurityIcon,
  SettingsIcon,
  SettlementsIcon,
  SnapshotIcon,
  SortIcon,
  StakingCoinsIcon,
  StakingCoinsWithHandIcon,
  StarCircleIcon,
  SupervisorIcon,
  SwapIcon,
  SyncIcon,
  TRMIcon,
  TagIcon,
  TestIcon,
  ThreeCoinsIcon,
  TradeDashboardIcon,
  TradeIcon,
  TransactionRequestsIcon,
  TrashBinIcon,
  UnarchiveIcon,
  UnsupportedAssetCircleIcon,
  UnsupportedAssetIcon,
  UploadIcon,
  UserCircleIcon,
  UserIcon,
  UserUnionIcon,
  VPNLockIcon,
  VaultIcon,
  VerifiedIcon,
  VideocamIcon,
  VideocamOffIcon,
  WalletAddIcon,
  WalletConnectIcon,
  WalletIcon,
  WebIcon,
  WidgetIcon,
  WorkIcon,
  WorldPinIcon,
  ZoomInIcon,
};
