import cn from 'classnames';

const AllOutIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AllOut', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M32 8L40 16V8H32ZM40 32L32 40H40V32ZM16 40L8 32V40H16ZM8 16L16 8H8V16ZM33.9 14.1C28.43 8.63 19.57 8.63 14.1 14.1C8.63 19.57 8.63 28.43 14.1 33.9C19.57 39.37 28.43 39.37 33.9 33.9C39.37 28.43 39.37 19.57 33.9 14.1ZM31.7 31.7C27.45 35.95 20.55 35.95 16.3 31.7C12.05 27.45 12.05 20.55 16.3 16.3C20.55 12.05 27.45 12.05 31.7 16.3C35.95 20.55 35.95 27.45 31.7 31.7Z" />
    </svg>
  );
};

export { AllOutIcon };
