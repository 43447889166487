import cn from 'classnames';

const ImageIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Image', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 38V10C42 7.79 40.21 6 38 6H10C7.79 6 6 7.79 6 10V38C6 40.21 7.79 42 10 42H38C40.21 42 42 40.21 42 38ZM17 27L22 33.01L29 24L38 36H10L17 27Z" />
    </svg>
  );
};

export { ImageIcon };
