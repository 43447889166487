import type { CoreRow, RowSelectionState } from '@tanstack/react-table';
import { useCallback, useState } from 'react';
import {
  useDeepCompareCallback,
  useDeepCompareEffect,
  useDeepCompareMemo,
} from 'use-deep-compare';

import { LABEL_COLUMN_ID } from '../utils/constants';

import { ActionListProps } from '../types';

export type { Option } from '../types';

export { createColumnHelper } from '@tanstack/react-table';

export type { RowSelectionState };

/**
 * Props for the useActionListViewModel hook.
 */
type UseActionListViewModelProps = {
  mediator: ActionListProps['mediator'];
  onSelect?: ActionListProps['onSelect'];
  setCloseNextRender: () => void;
};

/**
 * Custom hook that provides a view model for an action list.
 *
 * @param {UseActionListViewModelProps} props - The hook props.
 * @returns {ActionListViewModel} The action list view model.
 */
export const useActionListViewModel = ({
  mediator,
  onSelect,
  setCloseNextRender,
}: UseActionListViewModelProps) => {
  const [clickedOption, setClickedOption] = useState<unknown>(undefined);
  const [isFirstRender, setIsFirstRender] = useState(true);

  // get the selected row model
  const isMultipleSelection = mediator.options.enableMultiRowSelection;
  const rowModel = mediator.getSelectedRowModel();
  const selectedOptions = mediator
    .getSelectedRowModel()
    .flatRows.map((row) => row.original as unknown);
  // get the column with the label id
  const column = mediator
    .getAllColumns()
    .find((col) => col.id === LABEL_COLUMN_ID);

  // state for the search value
  const [searchValue, setSearchValue] = useState<string>();

  /**
   * Handles the input change event for the search field.
   * Updates the search value and sets the filter value for the column.
   *
   * @param value - The new value of the search field.
   */
  const onInputChangeHandler = useDeepCompareCallback(
    (value: string) => {
      setSearchValue(value);
      column?.setFilterValue(value);
    },
    [setSearchValue, column],
  );

  const onClearHandler = useCallback(() => {
    setSearchValue('');
    column?.setFilterValue('');
    // we need to have a empty dependency array as we only want to
    // clear the search value when the clear button is clicked
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // as the react table only updates the model after the render, we need to use the useEffect to get the updated data
  useDeepCompareEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    } else {
      if (!clickedOption) return;

      onSelect?.(selectedOptions, clickedOption);

      if (!isMultipleSelection) {
        setCloseNextRender();
      }

      setClickedOption(undefined);
    }
    // !!!! we only care to trigger the onSelect when the selectedOptions change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const contextValue = useDeepCompareMemo(
    () => ({
      rowModel,
      searchValue,
      onInputChangeHandler,
      onClearHandler,
      clickedOption,
      setClickedOption: (item: CoreRow<unknown>['original']) => {
        setClickedOption(item);
      },
    }),
    [
      clickedOption,
      onClearHandler,
      onInputChangeHandler,
      rowModel,
      searchValue,
    ],
  );

  return contextValue;
};
