import cn from 'classnames';

const LiveTvIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LiveTv', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 12H26.83L33.42 5.41L32 4L24 12L16 4L14.59 5.41L21.17 12H6C3.79 12 2 13.79 2 16V40C2 42.21 3.79 44 6 44H42C44.21 44 46 42.21 46 40V16C46 13.79 44.21 12 42 12ZM42 40H6V16H42V40ZM18 20V36L32 28L18 20Z" />
    </svg>
  );
};

export { LiveTvIcon };
