import cn from 'classnames';

const ChevronLeftIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ChevronLeft', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M30.83 14.83L28 12L16 24L28 36L30.83 33.17L21.66 24L30.83 14.83Z" />
    </svg>
  );
};

export { ChevronLeftIcon };
