import type {
  Maybe,
  PolicyFragmentBinding,
  PolicyFragmentBindingUpdate,
  PolicyQuorum,
  VaultPolicyFragmentBindingUpdate,
} from '../generated/graphql';
import { PolicyActionType, QuorumRequirement } from '../generated/graphql';

import type {
  BarePolicy,
  BarePolicyFragmentBinding,
  BarePolicyInfo,
  BarePolicyUpdate,
  BareVaultPolicy,
  BareVaultPolicyUpdate,
  OperationWithPolicyUpdates,
  PolicyFragmentBindingUpdateWithBinding,
  VaultPolicyFragmentBindingUpdateWithBinding,
} from '../types';

export const strictQuorumFilterFn = (quorum: PolicyQuorum) =>
  quorum.quorumRequirement === QuorumRequirement.QUORUM_REQUIREMENT_STRICT;

export const weakQuorumFilterFn = (quorum: PolicyQuorum) =>
  quorum.quorumRequirement === QuorumRequirement.QUORUM_REQUIREMENT_WEAK;

export const legacyPoliciesFilterFn = (policy: BarePolicy) =>
  policy.action !== PolicyActionType.DEPOSIT;

export const vaultFragmentHasUpdatedBindingFilterFn = (
  fragment: Maybe<VaultPolicyFragmentBindingUpdate>,
): fragment is VaultPolicyFragmentBindingUpdateWithBinding =>
  !!fragment?.updatedBinding;

export const vaultFragmentHasOriginalBindingFilterFn = (
  fragment: Maybe<VaultPolicyFragmentBindingUpdate>,
): fragment is VaultPolicyFragmentBindingUpdateWithBinding =>
  !!fragment?.originalBinding;

export const vaultFragmentHasVaultFilterFn = (
  fragment: VaultPolicyFragmentBindingUpdateWithBinding,
) => !!fragment.vaultID.vault;

export const fragmentHasUpdatedBindingFilterFn = (
  fragment: Maybe<PolicyFragmentBindingUpdate>,
): fragment is PolicyFragmentBindingUpdateWithBinding =>
  !!fragment?.updatedBinding;

export const fragmentHasOriginalBindingFilterFn = (
  fragment: Maybe<PolicyFragmentBindingUpdate>,
): fragment is PolicyFragmentBindingUpdateWithBinding =>
  !!fragment?.originalBinding;

export const policyMapHasOriginalPolicyFilterFn = (
  update: Partial<BarePolicyUpdate>,
): update is BarePolicyUpdate => !!update.originalPolicy;

export const vaultPolicyMapHasOriginalPolicyFilterFn = (
  update: Partial<BareVaultPolicyUpdate>,
): update is BareVaultPolicyUpdate => !!update.originalPolicy;

export const policyMapOriginalPolicyisNotDepositFilterFn = (
  update: BarePolicyUpdate,
) => update.originalPolicy.action !== PolicyActionType.DEPOSIT;

export const vaultPolicyMapOriginalPolicyisNotDepositFilterFn = (
  update: BareVaultPolicyUpdate,
) => !(update.originalPolicy.policy?.action === PolicyActionType.DEPOSIT);

export const isFragmentBindingNotInternalFilterFn = (
  fragment: BarePolicyFragmentBinding,
) => fragment.internal === false;

export const isFragmentUpdatedBindingNotInternalFilterFn = (
  fragment:
    | VaultPolicyFragmentBindingUpdateWithBinding
    | PolicyFragmentBindingUpdateWithBinding,
) => fragment.updatedBinding.internal === false;

export const isFragmentOriginalBindingNotInternalFilterFn = (
  fragment:
    | VaultPolicyFragmentBindingUpdateWithBinding
    | PolicyFragmentBindingUpdateWithBinding,
) => fragment.originalBinding.internal === false;

export const getPoliciesFromOperation = (op: OperationWithPolicyUpdates) => ({
  policies: op.policy ? [op.policy] : [],
});

export const getPolicyInfoFromEnforcedPolicy = (
  enforcedPolicy: PolicyFragmentBinding[],
): BarePolicyInfo | null =>
  enforcedPolicy
    ? {
        __typename: 'PolicyInfo',
        appliedPolicy: {
          __typename: 'PolicyContainer',
          fragmentContainer: {
            __typename: 'PolicyFragmentContainer',
            fragmentBindings: enforcedPolicy,
          },
        },
      }
    : null;

export const getPolicyFromPolicyUpdate = (
  update: BarePolicyUpdate,
): BarePolicy => ({
  __typename: update.originalPolicy.__typename,
  action: update.originalPolicy.action,
  quorums: update.updatedQuorums || [],
  requiredUsersQuorums: update.updatedRequiredUsersQuorums || [],
});

export const getPolicyFromVaultPolicyUpdate = (
  update: BareVaultPolicyUpdate,
): BareVaultPolicy => ({
  __typename: update.originalPolicy?.__typename,
  policy: {
    __typename: update.originalPolicy?.policy?.__typename,
    action: update.originalPolicy?.policy?.action,
    quorums: update?.updatedQuorums || [],
    requiredUsersQuorums: update?.updatedRequiredUsersQuorums || [],
  },
  vault: update?.originalPolicy.vault,
});
