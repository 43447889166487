import { useCallback } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import { useAnalytics } from '@anchorage/analytics';

import {
  AutoCompoundMode,
  StandardizedStakeFlowOrigin,
  StandardizedStakingMode,
  StandardizedStakingQueryParams,
} from '../../components/Vault/shared/Staking/StandardizedStaking/constants';

export const useStandardizedStakingModals = () => {
  const { track } = useAnalytics();

  const [stakingQueryParams, setStakingQueryParams] = useQueryParams({
    [StandardizedStakingQueryParams.ASSETTYPE_ID]: StringParam,
    [StandardizedStakingQueryParams.WALLET_ID]: StringParam,
    [StandardizedStakingQueryParams.STAKE_MODE]: StringParam,
    [StandardizedStakingQueryParams.VALIDATOR_ADDRESS]: StringParam,
  });

  const openStakeWizard = useCallback(
    ({
      walletID,
      assetTypeID,
      stakeOrigin,
    }: {
      walletID: string;
      assetTypeID: string;
      stakeOrigin: StandardizedStakeFlowOrigin;
    }) => {
      setStakingQueryParams({
        [StandardizedStakingQueryParams.ASSETTYPE_ID]: assetTypeID,
        [StandardizedStakingQueryParams.STAKE_MODE]:
          StandardizedStakingMode.STAKE,
        [StandardizedStakingQueryParams.WALLET_ID]: walletID,
      });

      track({
        name: 'standardized_staking:wizard:opened',
        data: {
          stakingType: StandardizedStakingMode.STAKE,
          stakeOrigin,
          assetTypeID: assetTypeID,
        },
      });
    },
    [setStakingQueryParams, track],
  );

  const openUnstakeWizard = useCallback(
    ({
      walletID,
      assetTypeID,
      validatorDestination,
    }: {
      walletID: string;
      assetTypeID: string;
      validatorDestination: string;
    }) => {
      setStakingQueryParams({
        [StandardizedStakingQueryParams.ASSETTYPE_ID]: assetTypeID,
        [StandardizedStakingQueryParams.STAKE_MODE]:
          StandardizedStakingMode.UNSTAKE,
        [StandardizedStakingQueryParams.WALLET_ID]: walletID,
        [StandardizedStakingQueryParams.VALIDATOR_ADDRESS]:
          validatorDestination,
      });

      track({
        name: 'standardized_staking:wizard:opened',
        data: {
          stakingType: StandardizedStakingMode.UNSTAKE,
          assetTypeID: assetTypeID,
        },
      });
    },
    [setStakingQueryParams, track],
  );

  const openStakeDetailsModal = useCallback(
    ({ walletID, assetTypeID }: { walletID: string; assetTypeID: string }) => {
      setStakingQueryParams({
        [StandardizedStakingQueryParams.ASSETTYPE_ID]: assetTypeID,
        [StandardizedStakingQueryParams.STAKE_MODE]:
          StandardizedStakingMode.VIEW_DETAILS,
        [StandardizedStakingQueryParams.WALLET_ID]: walletID,
      });
      track({
        name: 'standardized_staking:wizard:opened',
        data: {
          stakingType: StandardizedStakingMode.VIEW_DETAILS,
          assetTypeID: assetTypeID,
        },
      });
    },
    [setStakingQueryParams, track],
  );

  const openClaimRewardsWizard = useCallback(
    ({
      walletID,
      assetTypeID,
      validatorDestination,
    }: {
      walletID: string;
      assetTypeID: string;
      validatorDestination: string;
    }) => {
      setStakingQueryParams({
        [StandardizedStakingQueryParams.ASSETTYPE_ID]: assetTypeID,
        [StandardizedStakingQueryParams.STAKE_MODE]:
          StandardizedStakingMode.CLAIM_REWARDS,
        [StandardizedStakingQueryParams.WALLET_ID]: walletID,
        [StandardizedStakingQueryParams.VALIDATOR_ADDRESS]:
          validatorDestination,
      });
      track({
        name: 'standardized_staking:wizard:opened',
        data: {
          stakingType: StandardizedStakingMode.CLAIM_REWARDS,
          assetTypeID: assetTypeID,
        },
      });
    },
    [setStakingQueryParams, track],
  );

  const openAutoCompoundModal = useCallback(
    ({
      walletID,
      assetTypeID,
      stakingAutoCompoundMode,
    }: {
      walletID: string;
      assetTypeID: string;
      stakingAutoCompoundMode: AutoCompoundMode;
    }) => {
      setStakingQueryParams({
        [StandardizedStakingQueryParams.ASSETTYPE_ID]: assetTypeID,
        [StandardizedStakingQueryParams.STAKE_MODE]: stakingAutoCompoundMode,
        [StandardizedStakingQueryParams.WALLET_ID]: walletID,
      });

      track({
        name: 'standardized_staking:wizard:opened',
        data: {
          stakingType: stakingAutoCompoundMode,
          assetTypeID,
        },
      });
    },
    [setStakingQueryParams, track],
  );

  // Close all staking modals/wizards
  const clearStakingQueryParams = useCallback(() => {
    setStakingQueryParams({
      [StandardizedStakingQueryParams.ASSETTYPE_ID]: undefined,
      [StandardizedStakingQueryParams.STAKE_MODE]: undefined,
      [StandardizedStakingQueryParams.WALLET_ID]: undefined,
    });
  }, [setStakingQueryParams]);

  return {
    stakingQueryParams,
    openStakeWizard,
    openUnstakeWizard,
    openClaimRewardsWizard,
    openStakeDetailsModal,
    openAutoCompoundModal,
    clearStakingQueryParams,
  };
};
