import lodash from 'lodash';
import React, { createContext, useState } from 'react';

import { Step } from '@anchorage/common/dist/components/FormWizard/types';

import InAppSurvey from 'components/InAppSurvey';

import {
  InAppSurveyContextReturnValue,
  InAppSurveyWraperProps,
} from './_types';

const InAppSurveyContextDefault: InAppSurveyContextReturnValue = {
  isSurveyOpen: false,
  setIsSurveyOpen: lodash.noop,
  surveySteps: [],
  setSurveySteps: lodash.noop,
  surveyName: '',
  setSurveyName: lodash.noop,
  eventsPrefix: '',
  setEventsPrefix: lodash.noop,
  defaultValues: {},
  setDefaultValues: lodash.noop,
};

export const InAppSurveyContext = createContext(InAppSurveyContextDefault);

const InAppSurveyWrapper = ({
  children,
}: InAppSurveyWraperProps): React.ReactElement => {
  const [isSurveyOpen, setIsSurveyOpen] = useState(false);
  const [surveySteps, setSurveySteps] = useState<Step<Record<string, any>>[]>(
    [],
  );
  const [surveyName, setSurveyName] = useState('');
  const [eventsPrefix, setEventsPrefix] = useState<string>('');
  const [defaultValues, setDefaultValues] = useState<Record<string, any>>({});

  const contextValue: InAppSurveyContextReturnValue = {
    isSurveyOpen,
    setIsSurveyOpen,
    surveySteps,
    setSurveySteps,
    surveyName,
    setSurveyName,
    eventsPrefix,
    setEventsPrefix,
    defaultValues,
    setDefaultValues,
  };

  return (
    <InAppSurveyContext.Provider value={contextValue}>
      {children}
      <InAppSurvey />
    </InAppSurveyContext.Provider>
  );
};

export default InAppSurveyWrapper;
