import type { DocumentNode } from '@apollo/client';
import { useCallback, useState } from 'react';

import { Banner } from '@anchorage/common/dist/components';
import { useSnackbar } from '@anchorage/common/dist/components/Snackbar';
import type { AttributionPair } from '@anchorage/common/dist/widgets/PendingAttributions/PendingAttributionsCard/_types';

import DepositAttributionsSpamModal from 'components/Activity/DepositAttributionsSpamModal';

import {
  DepositAttributionsDocument,
  OperationDrawerDocument,
  useAttributeDepositsAsSpamMutation,
} from 'generated/graphql';

import css from './styles.module.scss';

import DepositAttibutionsDrawer from '../../Activity/DepositAttributionsDrawer';

const SingleDepositAttributionsBanner = ({
  addresses,
  attributionPairs,
  refetchQuery,
}: {
  addresses: string[];
  attributionPairs: AttributionPair[];
  refetchQuery: DocumentNode;
}) => {
  const { addSnackbar } = useSnackbar();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isManualSpamModalOpen, setIsManualSpamModalOpen] = useState(false);

  const closeDrawer = useCallback(
    () => setIsDrawerOpen(false),
    [setIsDrawerOpen],
  );

  const openDrawer = useCallback(
    () => setIsDrawerOpen(true),
    [setIsDrawerOpen],
  );

  const [
    attributeDepositsAsSpam,
    { loading: isAttributeDepositsAsSpamMutationLoading },
  ] = useAttributeDepositsAsSpamMutation({
    refetchQueries: [OperationDrawerDocument, DepositAttributionsDocument],
    awaitRefetchQueries: true,
    onCompleted: () => {
      addSnackbar({
        type: 'success',
        text: 'Deposit flagged as spam',
      });
      setIsManualSpamModalOpen(false);
    },
    onError: () => {
      addSnackbar({
        type: 'error',
        text: 'Sorry, something went wrong. Please try again.',
      });
    },
  });

  const flagAsSpam = useCallback(
    () =>
      attributeDepositsAsSpam({
        variables: {
          operationIDs: attributionPairs.map(
            (attributionPair) => attributionPair.operationID,
          ),
        },
      }),
    [attributeDepositsAsSpam, attributionPairs],
  );

  const bannerType = 'warning';
  const singleBannerText = 'Pending deposit attribution';

  return (
    <>
      <Banner
        actions={[
          {
            text: 'Attribute deposit',
            // 'type' does not exist in type 'BannerActionType' but it exists on
            // ButtonProps
            // @ts-ignore
            type: 'tertiary',
            onClick: openDrawer,
          },
          {
            isLoading: isAttributeDepositsAsSpamMutationLoading,
            text: 'Flag as spam',
            // 'type' does not exist in type 'BannerActionType' but it exists on
            // ButtonProps
            // @ts-ignore
            type: 'destructive',
            onClick: () => setIsManualSpamModalOpen(true),
          },
        ]}
        title={singleBannerText}
        type={bannerType}
        className={css.depositAttributionsBanner}
        childrenIsSubtitle={false}
      />
      <DepositAttibutionsDrawer
        isDrawerOpen={isDrawerOpen}
        addresses={addresses}
        onClose={closeDrawer}
        attributionPairs={attributionPairs}
        refetchQuery={refetchQuery}
      />
      <DepositAttributionsSpamModal
        isLoading={isAttributeDepositsAsSpamMutationLoading}
        isOpen={isManualSpamModalOpen}
        onClose={() => setIsManualSpamModalOpen(false)}
        onSave={flagAsSpam}
      />
    </>
  );
};

export default SingleDepositAttributionsBanner;
