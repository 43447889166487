import cn from 'classnames';

const SquareCheckIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Square Checked', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M19 3H5C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V5C21 3.895 20.105 3 19 3ZM10 17L5 12L6.415 10.585L10 14.17L17.585 6.585L19 8L10 17Z" />
    </svg>
  );
};

export { SquareCheckIcon };
