import cn from 'classnames';

const HdrOnIcon = (props: SVGProps): JSX.Element => {
  const { title = 'HdrOn', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 23V21C42 19.3 40.7 18 39 18H32V30H35V26H37.3L39 30H42L40.2 25.8C41.2 25.3 42 24.2 42 23ZM39 23H35V21H39V23ZM13 22H9V18H6V30H9V25H13V30H16V18H13V22ZM26 18H19V30H26C27.7 30 29 28.7 29 27V21C29 19.3 27.7 18 26 18ZM26 27H22V21H26V27Z" />
    </svg>
  );
};

export { HdrOnIcon };
