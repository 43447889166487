import { Badge } from '@latitude/badge';
import cn from 'classnames';

type Props = {
  label: string;
  counter?: number;
};

export const WithCounter = ({ label, counter }: Props) => {
  return (
    <div className={cn(['flex', 'w-full', 'items-center', 'justify-between'])}>
      {label}
      {counter && (
        <Badge variant="info" priority="high" data-testid="counter-info">
          {counter}
        </Badge>
      )}
    </div>
  );
};
