import { VariantProps } from 'class-variance-authority';
import cn from 'classnames';
import { Command as CommandPrimitive } from 'cmdk';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { commandItemVariants } from 'variants/item-variants';

import { CheckboxItem } from './CheckboxItem';

/**
 * Props for the CommandItem component.
 */
type CommandItemProps = ComponentPropsWithoutRef<typeof CommandPrimitive.Item> &
  VariantProps<typeof commandItemVariants> & {
    isSelected: boolean;
    canSelect: boolean;
    isMultiSelect: boolean;
  };

/**
 * Represents a command item component.
 *
 * @component
 * @example
 * ```tsx
 * <CommandItem
 *   className="custom-class"
 *   size="small"
 *   selected={true}
 *   state="active"
 *   onClick={handleClick}
 * />
 * ```
 */
export const CommandItem = forwardRef<
  ElementRef<typeof CommandPrimitive.Item>,
  CommandItemProps
>(
  (
    {
      className,
      size,
      isSelected,
      canSelect,
      isMultiSelect,
      state,
      children,
      ...props
    },
    ref,
  ) => (
    <CommandPrimitive.Item
      ref={ref}
      data-active={isSelected}
      className={cn(commandItemVariants({ size, state }), className)}
      tabIndex={0}
      {...props}
    >
      {isMultiSelect && (
        <CheckboxItem isSelected={isSelected} canSelect={canSelect} />
      )}
      {children}
    </CommandPrimitive.Item>
  ),
);

CommandItem.displayName = CommandPrimitive.Item.displayName;
