import React from 'react';

// Components
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  useTable,
} from '../../../../components/TableV2';

// Utils
import { useDeepCompare } from '../../../../hooks';

// Styles
import css from './index.css';

// Types
import type { PendingAttributionsTableProps } from './_types';

// Constants
import {
  PENDING_ATTRIBUTIONS_COLUMNS,
  PENDING_ATTRIBUTIONS_COLUMNS_WITH_ACCOUNT,
} from './_constants';

const PendingAttributionsTable = (
  {
    data,
    headerCheckbox,
    onClickOperationId,
    isDisabled = false,
  }: PendingAttributionsTableProps,
) => {
  const hasAccountName = React.useMemo(
    () => data.some((operation) => !!operation.accountName),
    useDeepCompare([data]),
  );

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns: (hasAccountName
        ? PENDING_ATTRIBUTIONS_COLUMNS_WITH_ACCOUNT
        : PENDING_ATTRIBUTIONS_COLUMNS) as any,
      data,
    });

  return (
    <Table className={css.table} {...getTableProps()}>
      <TableHeader>
        {headers.map((column, index) => (
          <TableCell
            {...column.getHeaderProps()}
            key={`pending-attributions-table-header-${index}`}
          >
            {column.render('Header')}
            {index === 0 && headerCheckbox}
          </TableCell>
        ))}
      </TableHeader>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <React.Fragment key={row.getRowProps().key}>
              <TableRow className={css.tableRow}>
                {row.cells.map((cell, i) => (
                  <TableCell
                    className={css.tableCell}
                    {...cell.getCellProps()}
                    key={`pending-attributions-table-cell-${row.original.operationId}-${i}`}
                  >
                    {cell.render('Cell', {
                      ...row.original,
                      onClickOperationId,
                      isDisabled,
                    })}
                  </TableCell>
                ))}
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default React.memo(PendingAttributionsTable);
