import cn from 'classnames';

const FileDownloadIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FileDownload', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 18H30V6H18V18H10L24 32L38 18ZM10 36V40H38V36H10Z" />
    </svg>
  );
};

export { FileDownloadIcon };
