import cn from 'classnames';

const HeadsetMicIcon = (props: SVGProps): JSX.Element => {
  const { title = 'HeadsetMic', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 2C14.06 2 6 10.06 6 20V34C6 37.31 8.69 40 12 40H18V24H10V20C10 12.27 16.27 6 24 6C31.73 6 38 12.27 38 20V24H30V40H38V42H24V46H36C39.31 46 42 43.31 42 40V20C42 10.06 33.94 2 24 2Z" />
    </svg>
  );
};

export { HeadsetMicIcon };
