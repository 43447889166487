import { Text } from '@anchorage/common/dist/components';
import { StakingCoinsWithHandIcon } from '@anchorage/common/dist/components/Icons';

import css from './styles.module.scss';

interface Props {
  title: string;
}

export default function FirstTimeStakingPage({ title }: Props) {
  return (
    <div className={css.mainContainer}>
      <StakingCoinsWithHandIcon className={css.icon} />

      <div className={css.bottomContainer}>
        <div className={css.textContainer}>
          <Text size="medium" className={css.headingText}>
            {title}
          </Text>

          {/* To-Do: Uncomment this once we have the staking guides approved */}
          {/* <Text size="small" className={css.subHeadingText}>
            Read our staking user guide{' '}
            <a
              href={
                isEthStaking
                  ? ETH_STAKING_GUIDE_LINK
                  : STANDARDIZED_STAKING_GUIDE_LINK
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </Text> */}
        </div>
      </div>
    </div>
  );
}
