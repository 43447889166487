export const checkboxItemClasses = [
  'peer',
  '[&_svg]:h-sizing-comp-checkbox-check',
  '[&_svg]:w-sizing-comp-checkbox-check',
  // because the container is of flex the hidden input was beeing redered before the checkbox
  // instead of in top of the checkbox
  '[&~input]:!translate-x-0',
  // uncheched
  '[&_svg]:text-color-comp-checkbox-unchecked-default',
  '[&:not(:disabled):hover_svg]:text-color-comp-checkbox-unchecked-hover',
  '[&:disabled_svg]:text-color-comp-checkbox-unchecked-disabled',
  '[&:has(~input:invalid)_svg]:text-color-comp-checkbox-unchecked-danger',
  '[&[aria-invalid=true]_svg]:text-color-comp-checkbox-unchecked-danger',
  // checked
  '[&[data-state=checked]_svg]:text-color-comp-checkbox-checked-default',
  '[&:not(:disabled):hover[data-state=checked]_svg]:text-color-comp-checkbox-checked-hover',
  '[&:disabled[data-state=checked]_svg]:text-color-comp-checkbox-checked-disabled',
  '[&:has(~input:invalid)[data-state=checked]_svg]:text-color-comp-checkbox-checked-danger',
  // indeterminate
  '[&[data-state=indeterminate]_svg]:text-color-comp-checkbox-checked-default',
  '[&:not(:disabled):hover[data-state=indeterminate]_svg]:text-color-comp-checkbox-checked-hover',
  '[&:disabled[data-state=indeterminate]_svg]:text-color-comp-checkbox-checked-disabled',
  '[&:has(~input:invalid)[data-state=indeterminate]_svg]:text-color-comp-checkbox-checked-danger',
  // has a label after
  '[&:has(~label)]:mr-spacing-comp-checkbox-gap',
  // common lib overrides are giving the button a default border on focus
  // we need to remove it
  'focus-visible:outline-none',
  'focus:outline-none',
];

export const checkboxLabelClasses = [
  'flex',
  'items-center',
  'gap-spacing-comp-checkbox-gap',
  'font-comp-checkbox-label-font-family',
  'font-comp-checkbox-label-font-weight',
  'text-comp-checkbox-label-font-size',
  'peer-[:not(:disabled)]:cursor-pointer',
  'text-color-comp-checkbox-label-default',
  'peer-hover:text-color-comp-checkbox-label-hover',
  'peer-invalid:text-color-comp-checkbox-label-error',
  '[&[aria-invalid=true]_svg]:fill-color-comp-checkbox-unchecked-danger',
];
