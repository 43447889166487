import cn from 'classnames';

const FormatColorResetIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatColorReset', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M36 28C36 20 24 6.40002 24 6.40002C24 6.40002 21.34 9.42002 18.53 13.44L35.7 30.61C35.89 29.77 36 28.9 36 28ZM34.24 34.24L25 25L10.54 10.54L8 13.09L14.64 19.73C13.11 22.65 12 25.58 12 28C12 34.63 17.37 40 24 40C27.04 40 29.8 38.86 31.91 37L37.18 42.27L39.72 39.72L34.24 34.24Z" />
    </svg>
  );
};

export { FormatColorResetIcon };
