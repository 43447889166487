import cn from 'classnames';

const AppsIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Apps', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M8 16H16V7.99996H8V16ZM20 40H28V32H20V40ZM8 40H16V32H8V40ZM8 28H16V20H8V28ZM20 28H28V20H20V28ZM32 7.99996V16H40V7.99996H32ZM20 16H28V7.99996H20V16ZM32 28H40V20H32V28ZM32 40H40V32H32V40Z" />
    </svg>
  );
};

export { AppsIcon };
