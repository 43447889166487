import cn from 'classnames';

const FormatTextdirectionLToRIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatTextdirectionLToR', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18 20V30H22V8H26V30H30V8H34V4H18C13.58 4 10 7.58 10 12C10 16.42 13.58 20 18 20ZM42 36L34 28V34H10V38H34V44L42 36Z" />
    </svg>
  );
};

export { FormatTextdirectionLToRIcon };
