import cn from 'classnames';

const Crop75Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Crop75', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 14H10C7.79 14 6 15.79 6 18V30C6 32.21 7.79 34 10 34H38C40.21 34 42 32.21 42 30V18C42 15.79 40.21 14 38 14ZM38 30H10V18H38V30Z" />
    </svg>
  );
};

export { Crop75Icon };
