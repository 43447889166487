import cn from 'classnames';

const CopyrightIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Copyright', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 4C12.95 4 4 12.95 4 24C4 35.05 12.95 44 24 44C35.05 44 44 35.05 44 24C44 12.95 35.05 4 24 4ZM24 40C15.18 40 8 32.82 8 24C8 15.18 15.18 8 24 8C32.82 8 40 15.18 40 24C40 32.82 32.82 40 24 40ZM20.16 21.73C20.27 21.08 20.47 20.5 20.76 19.99C21.05 19.48 21.45 19.07 21.94 18.76C22.41 18.47 23 18.31 23.73 18.3C24.21 18.31 24.65 18.39 25.03 18.56C25.44 18.74 25.78 18.98 26.07 19.28C26.36 19.58 26.58 19.94 26.74 20.34C26.9 20.74 26.99 21.17 27.01 21.62H30.59C30.56 20.68 30.37 19.82 30.04 19.04C29.71 18.26 29.23 17.59 28.63 17.02C28.03 16.45 27.31 16.02 26.47 15.71C25.63 15.4 24.7 15.24 23.68 15.24C22.38 15.24 21.25 15.46 20.29 15.91C19.33 16.36 18.53 16.97 17.89 17.75C17.25 18.53 16.77 19.43 16.46 20.46C16.15 21.49 16 22.58 16 23.73V24.28C16 25.44 16.16 26.53 16.47 27.56C16.78 28.59 17.26 29.49 17.9 30.26C18.54 31.03 19.34 31.64 20.31 32.09C21.28 32.54 22.41 32.76 23.71 32.76C24.65 32.76 25.53 32.61 26.35 32.3C27.17 31.99 27.89 31.57 28.51 31.03C29.13 30.49 29.63 29.87 29.99 29.15C30.35 28.43 30.56 27.67 30.59 26.85H27.01C26.99 27.27 26.89 27.65 26.71 28.01C26.53 28.37 26.29 28.67 25.99 28.92C25.69 29.17 25.34 29.37 24.94 29.51C24.56 29.64 24.16 29.71 23.73 29.71C23.01 29.69 22.42 29.54 21.94 29.24C21.44 28.92 21.04 28.51 20.75 28C20.46 27.49 20.26 26.91 20.15 26.25C20.04 25.59 20 24.95 20 24.28V23.73C20 23.05 20.05 22.38 20.16 21.73Z" />
    </svg>
  );
};

export { CopyrightIcon };
