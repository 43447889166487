import cn from 'classnames';

const FiberManualRecordIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FiberManualRecord', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40Z" />
    </svg>
  );
};

export { FiberManualRecordIcon };
