import { cva } from 'class-variance-authority';

export const badgeVariants = cva(
  [
    'flex',
    'items-center',
    'flex-start',
    'rounded-radius-comp-badge',
    'min-w-sizing-comp-badge-min-width',
    'max-w-sizing-comp-badge-max-width',
    'py-spacing-comp-badge-vertical',
    'px-spacing-comp-badge-horizontal',
    '[&>svg]:w-sizing-comp-badge-small-icon [&>svg]:h-sizing-comp-badge-small-icon',
    'w-fit',
  ],
  {
    variants: {
      variant: {
        info: '',
        success: '',
        warning: '',
        danger: '',
        neutral: '',
        primary: '',
      },
      priority: {
        high: '',
        low: '',
      },
    },
    compoundVariants: [
      {
        variant: 'info',
        priority: 'high',
        className: [
          'bg-color-comp-badge-info-high-container',
          'text-color-comp-badge-info-high-on-container',
        ],
      },
      {
        variant: 'info',
        priority: 'low',
        className: [
          'bg-color-comp-badge-info-low-container',
          'text-color-comp-badge-info-low-on-container',
        ],
      },
      {
        variant: 'success',
        priority: 'high',
        className: [
          'bg-color-comp-badge-success-high-container',
          'text-color-comp-badge-success-high-on-container',
        ],
      },
      {
        variant: 'success',
        priority: 'low',
        className: [
          'bg-color-comp-badge-success-low-container',
          'text-color-comp-badge-success-low-on-container',
        ],
      },
      {
        variant: 'warning',
        priority: 'high',
        className: [
          'bg-color-comp-badge-warning-high-container',
          'text-color-comp-badge-warning-high-on-container',
        ],
      },
      {
        variant: 'warning',
        priority: 'low',
        className: [
          'bg-color-comp-badge-warning-low-container',
          'text-color-comp-badge-warning-low-on-container',
        ],
      },
      {
        variant: 'danger',
        priority: 'high',
        className: [
          'bg-color-comp-badge-danger-high-container',
          'text-color-comp-badge-danger-high-on-container',
        ],
      },
      {
        variant: 'danger',
        priority: 'low',
        className: [
          'bg-color-comp-badge-danger-low-container',
          'text-color-comp-badge-danger-low-on-container',
        ],
      },
      {
        variant: 'neutral',
        priority: 'high',
        className: [
          'bg-color-comp-badge-neutral-high-container',
          'text-color-comp-badge-neutral-high-on-container',
        ],
      },
      {
        variant: 'neutral',
        priority: 'low',
        className: [
          'bg-color-comp-badge-neutral-low-container',
          'text-color-comp-badge-neutral-low-on-container',
        ],
      },
      {
        variant: 'primary',
        priority: 'high',
        className: [
          'bg-color-comp-badge-primary-high-container',
          'text-color-comp-badge-primary-high-on-container',
        ],
      },
      {
        variant: 'primary',
        priority: 'low',
        className: [
          'bg-color-comp-badge-primary-low-container',
          'text-color-comp-badge-primary-low-on-container',
        ],
      },
    ],
    defaultVariants: {
      variant: 'info',
      priority: 'high',
    },
  },
);
