import { StandardizedStakingStep } from 'generated/graphql';

interface Props {
  step: StandardizedStakingStep.AutoCompoundStepInlineFragment;
}

const StakingAutoCompoundStep = (props: Props) => {
  // @ts-ignore TODO CTD-4570 to implement later
  const { step } = props;
  return <div data-testid="staking-auto-compound-step" />;
};
export default StakingAutoCompoundStep;
