import cn from 'classnames';

const SortIcon = ({
  title = 'Sort',
  className,
  ...restProps
}: SVGProps): JSX.Element => {
  return (
    <svg
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 36.34L17.66 30l-2.83 2.83L24 42l9.17-9.17L30.34 30 24 36.34zM30.34 18L24 11.66 17.66 18l-2.83-2.83L24 6l9.17 9.17L30.34 18z"></path>
    </svg>
  );
};

export { SortIcon };

const SortAsc = ({
  title = 'Sort Ascending',
  className,
  ...restProps
}: SVGProps): JSX.Element => {
  return (
    <svg
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 16L12 28l2.83 2.83L24 21.66l9.17 9.17L36 28 24 16z"></path>
    </svg>
  );
};

export { SortAsc };

const SortDesc = ({
  title = 'Sort Descending',
  className,
  ...restProps
}: SVGProps): JSX.Element => {
  return (
    <svg
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M33.17 17.17L24 26.34l-9.17-9.17L12 20l12 12 12-12-2.83-2.83z"></path>
    </svg>
  );
};

export { SortDesc };
