import cn from 'classnames';

const AssistantIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Assistant', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 4H10C7.79 4 6 5.79 6 8V36C6 38.21 7.79 40 10 40H18L24 46L30 40H38C40.21 40 42 38.21 42 36V8C42 5.79 40.21 4 38 4ZM27.75 25.75L24 34L20.25 25.75L12 22L20.25 18.25L24 10L27.75 18.25L36 22L27.75 25.75Z" />
    </svg>
  );
};

export { AssistantIcon };
