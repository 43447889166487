import { cva } from 'class-variance-authority';
import cn from 'classnames';

export const commandItemVariants = cva(
  cn([
    'w-full',
    'flex',
    'cursor-pointer',
    'select-none',
    'items-center',
    'outline-none',
    'focus:outline-none',
    '[&>svg]:fill-current',
    'rounded-radius-comp-action-list-round',
    'gap-spacing-comp-action-list-medium-gap',
  ]),
  {
    variants: {
      state: {
        default: [
          'bg-color-comp-action-list-container-enabled',
          'text-color-comp-action-list-on-container-default',
          'hover:bg-color-comp-action-list-container-hover',
          'cursor-pointer',
        ],
        selected: [
          'bg-color-comp-action-list-container-selected',
          'text-color-comp-action-list-on-container-selected',
          'hover:bg-color-comp-action-list-container-hover',
        ],
        disabled: [
          'cursor-default',
          'bg-color-comp-action-list-container-disabled',
          'text-color-comp-action-list-on-container-disabled',
        ],
        separator: [
          'cursor-default',
          'bg-color-comp-action-list-container-separator',
          'text-color-comp-action-list-on-container-separator',
        ],
      },
      size: {
        medium: [
          'text-comp-action-list-medium-font-size',
          'font-comp-action-list-medium-font-family',
          'font-comp-action-list-medium-font-weight',
          'leading-comp-action-list-medium-line-height',
          'py-spacing-comp-action-list-medium-vertical',
          'px-spacing-comp-action-list-medium-gap',
        ],
        small: [
          'text-comp-action-list-small-font-size',
          'font-comp-action-list-small-font-family',
          'font-comp-action-list-small-font-weight',
          'leading-comp-action-list-small-line-height',
          'py-spacing-comp-action-list-small-vertical',
          'px-spacing-comp-action-list-small-gap',
        ],
      },
    },
    defaultVariants: {
      size: 'medium',
      state: 'default',
    },
  },
);
