import cn from 'classnames';

const DirectionsWalkIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DirectionsWalk', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M27 9C29.2 9 31 7.2 31 5C31 2.8 29.2 1 27 1C24.8 1 23 2.8 23 5C23 7.2 24.8 9 27 9ZM19.5 15.8L14 44H18.2L21.7 28L26 32V44H30V28.9L25.9 24.8L27.1 18.8C29.7 22 33.6 24 38 24V20C34.3 20 31.1 18 29.3 15.1L27.4 11.9C26.7 10.7 25.4 10 24 10C23.5 10 23 10.1 22.5 10.3L12 14.6V24H16V17.3L19.5 15.8Z" />
    </svg>
  );
};

export { DirectionsWalkIcon };
