import { FC, PropsWithChildren } from 'react';

import LocalEnvBadge from '@anchorage/frontoffice/components/LocalEnvBadge';
import FeatureFlagsProvider from '@anchorage/frontoffice/contexts/featureFlags/FeatureFlagsProvider';

import DeviceChecker from 'components/layouts/App/DeviceChecker';

import css from '../../../../pages/styles.module.css';

// Normally this layout is used for pages that don't have a side navigation bar and don't required the user to be logged in
const NoSideNavLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    // we don't have org or email to pass to the FeatureFlagsProvider
    <FeatureFlagsProvider>
      <DeviceChecker>
        <div id="anchorage" data-testid="no-sidenav-layout" className={css.app}>
          <LocalEnvBadge className="top-4" />
          {children}
        </div>
      </DeviceChecker>
    </FeatureFlagsProvider>
  );
};

export default NoSideNavLayout;
