import React from 'react';

// Types
import type {
  GenericOrgChangeOperationFieldsFragment,
  OperationField,
} from 'generated/graphql';

// Components
import CanonicalPayloadSection from '../../../shared/CanonicalPayloadSection';

interface GenericOrgChangeOperationProps {
  operation: GenericOrgChangeOperationFieldsFragment;
}

const GenericOrgChangeOperation: React.FC<GenericOrgChangeOperationProps> = ({
  operation,
}) => {
  const {
    operationInfo: { signablePayload, operationFields },
  } = operation;

  return (
    <CanonicalPayloadSection
      operationFields={operationFields as OperationField[]}
      canonicalOperationPayload={signablePayload ?? ''}
    />
  );
};

export default GenericOrgChangeOperation;
