import { Button } from '@latitude/button';
import { CloseIcon } from '@latitude/icons';
import { Description, Title } from '@radix-ui/react-dialog';
import cn from 'classnames';
import { FC, useMemo } from 'react';
import {
  headerVariants,
  subTitleVariants,
  titleVariants,
} from 'variants/header-variants';

/**
 * Represents the props for the Header component.
 */
export interface HeaderProps {
  /**
   * The title of the header.
   */
  title: string;

  /**
   * The subtitle of the header (optional).
   */
  subTitle?: string;

  /**
   * Callback function to be called when the header is closed.
   */
  onClose?: () => void;
}
/**
 * Header component of the modal.
 */
export const Header: FC<HeaderProps> = ({ title, subTitle, onClose }) => {
  const styles = useMemo(
    () => ({
      header: cn('flex-0', headerVariants),
      title: cn(titleVariants),
      subTitle: cn(subTitleVariants),
    }),
    [],
  );
  return (
    <div data-testid="modal-header" className={styles.header}>
      <div className="flex grow flex-col justify-center">
        <Title className={styles.title}>{title}</Title>
        {subTitle && (
          <Description className={styles.subTitle}>{subTitle}</Description>
        )}
      </div>
      <Button
        variant="strong"
        type="ghost"
        size="small"
        data-testid="modal-close-button"
        onClick={onClose}
        aria-label="Close"
        trailingElement={<CloseIcon />}
      ></Button>
    </div>
  );
};
