import React from 'react';

import { FieldValues } from '@anchorage/common/dist/components/Form/_types';
import { Step } from '@anchorage/common/dist/components/FormWizard/types';

import css from './styles.module.scss';

import ThankYou from './ThankYou';

interface CreateFormStepsProps<T extends FieldValues> {
  steps: Step<T>[];
  onClose: () => void;
  onSubmit: () => void;
}

/* Given the question steps, create the form steps for the FormWizard */
export const createFormSteps = <T extends FieldValues>({
  steps,
  onSubmit,
  onClose,
}: CreateFormStepsProps<T>): Step<T>[] => {
  const hasMultipleSteps = steps.length > 1;

  return steps
    .map((step, index) => {
      const isLastStep = index === steps.length - 1;
      const nextBtnProps = isLastStep
        ? { nextBtnProps: { children: 'Submit' } }
        : undefined;
      // if not multiple steps we want onPrevious to actually be a close button.
      // if multiple steps then onPrevious is default
      const onPrevious = hasMultipleSteps ? undefined : { onPrevious: onClose };
      const onNext = isLastStep
        ? {
            onNext: (goToStep: (page: number) => void) => {
              onSubmit();
              goToStep(steps.length);
            },
          }
        : undefined;
      return {
        element: step.element,
        formFieldNames: step.formFieldNames,
        backBtnProps: {
          children: hasMultipleSteps ? 'Previous' : 'Close',
          type: 'tertiary',
          className: css.surveyCloseBtn,
          isDisabled: false,
          showBtn: !hasMultipleSteps || index > 0,
        },
        ...nextBtnProps,
        ...onPrevious,
        ...onNext,
      };
    })
    .concat([
      {
        element: <ThankYou />,
        backBtnProps: {
          children: 'Close',
          type: 'secondary',
          className: css.surveyBackBtn,
          isDisabled: false,
          showBtn: true,
        },
        nextBtnProps: {
          showBtn: false,
          children: undefined,
        },
        onPrevious: onClose,
      } as any,
    ]);
};
