import { DelegateType } from '@anchorage/frontoffice/generated/graphql';

export const DELEGATE_TYPES = [
  DelegateType.DELEGATE,
  DelegateType.DELEGATE_STAKE_POOL,
  DelegateType.DELEGATE_DELEGATION_POOL,
];

export const UNDELEGATE_TYPES = [
  DelegateType.UNDELEGATE,
  DelegateType.UNDELEGATE_STAKE_POOL,
  DelegateType.UNDELEGATE_DELEGATION_POOL,
];
