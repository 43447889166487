import { useFeatureFlagsContext } from '@anchorage/feature-flags';
import { FEATURE_FLAGS } from '@anchorage/frontoffice/constants/split';

import { EndorsementFieldsFragment, OperationDrawer } from 'generated/graphql';

import EndorsersV1 from '../EndorsersV1';
import EndorsersV2 from '../EndorsersV2';

type Props = {
  operation: OperationDrawer.operation;
  endorsements: EndorsementFieldsFragment[];
};

export default function Endorsers({ ...props }: Props) {
  const { useIsFeatureActive } = useFeatureFlagsContext();
  const [isMultipleSubquorumsEnabled] = useIsFeatureActive(
    FEATURE_FLAGS.CD_MULTIPLE_SUBQUORUMS,
  );
  return isMultipleSubquorumsEnabled ? (
    <EndorsersV2 {...props}></EndorsersV2>
  ) : (
    <EndorsersV1 {...props}></EndorsersV1>
  );
}
