import cn from 'classnames';

const HourglassEmptyIcon = (props: SVGProps): JSX.Element => {
  const { title = 'HourglassEmpty', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M12 5V15L21 24L12 33V43H36V33L27 24L36 15V5H12ZM32 34V39H16V34L24 26L32 34ZM24 22L16 14V9H32V14L24 22Z" />
    </svg>
  );
};

export { HourglassEmptyIcon };
