import cn from 'classnames';

const DeleteSweepIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DeleteSweep', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M30 32H38V36H30V32ZM30 16H44V20H30V16ZM30 24H42V28H30V24ZM6 36C6 38.2 7.8 40 10 40H22C24.2 40 26 38.2 26 36V16H6V36ZM28 10H22L20 8H12L10 10H4V14H28V10Z" />
    </svg>
  );
};

export { DeleteSweepIcon };
