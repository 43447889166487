import cn from 'classnames';

const FormatIndentDecreaseIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatIndentDecrease', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M22 34H42V30H22V34ZM6 24L14 32V16L6 24ZM6 42H42V38H6V42ZM6 6V10H42V6H6ZM22 18H42V14H22V18ZM22 26H42V22H22V26Z" />
    </svg>
  );
};

export { FormatIndentDecreaseIcon };
