import cn from 'classnames';

const KeyboardReturnIcon = (props: SVGProps): JSX.Element => {
  const { title = 'KeyboardReturn', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 14V22H11.66L18.83 14.83L16 12L4 24L16 36L18.83 33.17L11.66 26H42V14H38Z" />
    </svg>
  );
};

export { KeyboardReturnIcon };
