import { Button } from '@latitude/button';
import { Card } from '@latitude/card';
import Text from '@latitude/text';
import { Tooltip } from '@latitude/tooltip';
import { useCallback } from 'react';

import { useStandardizedStakingModals } from 'hooks/useStandardizedStakingModals';

import { AssetTypeID, WalletWithStakingInfo } from 'generated/graphql';

import useStandardizedStakingContext from '../../StandardizedStakingContext/useStandardizedStakingContext';
import { StandardizedStakingMode } from '../../constants';
import { AutoCompoundStatusIndicator } from '../AutoCompoundStatusIndicator';

interface Props {
  assetTypeID: AssetTypeID;
  autoCompoundForm: WalletWithStakingInfo.autoCompoundForm | null | undefined;
}

const AutoCompoundSection = ({ assetTypeID, autoCompoundForm }: Props) => {
  const { openAutoCompoundModal } = useStandardizedStakingModals();
  const { walletWithStakingInfo, autoCompoundEnabled } =
    useStandardizedStakingContext();

  const buttonDisabled = !autoCompoundForm || !!autoCompoundForm.disabledHint;
  const buttonTooltip = buttonDisabled
    ? (autoCompoundForm?.disabledHint ?? 'Auto-compound is disabled')
    : autoCompoundEnabled
      ? 'Stop auto-compound'
      : 'Start auto-compound';

  const handleAutoCompoundToggle = useCallback(() => {
    const stakingMode = autoCompoundEnabled
      ? StandardizedStakingMode.DISABLE_AUTO_COMPOUND
      : StandardizedStakingMode.ENABLE_AUTO_COMPOUND;

    openAutoCompoundModal({
      walletID: walletWithStakingInfo.walletID,
      assetTypeID,
      stakingAutoCompoundMode: stakingMode,
    });
  }, [
    autoCompoundEnabled,
    openAutoCompoundModal,
    assetTypeID,
    walletWithStakingInfo,
  ]);

  const showLearnMore = !buttonDisabled && !autoCompoundEnabled;

  return (
    <div className="flex flex-col justify-between py-4">
      <Text variant="headingTiny" className="mb-2">
        Staking Rewards
      </Text>
      <Card className="py-4">
        <div className="flex justify-between">
          <div>
            <div className="flex gap-1">
              <Text>Let your rewards compound automatically.</Text>

              {/*
                A disabled link is not a link, it’s just text. So might as well hide the Learn More link if auto compound is disabled.
              */}
              {showLearnMore && (
                <Text variant="link" asChild>
                  <a
                    className="cursor-pointer"
                    onClick={handleAutoCompoundToggle}
                  >
                    Learn more
                  </a>
                </Text>
              )}
            </div>
            <AutoCompoundStatusIndicator enabled={autoCompoundEnabled} />
          </div>

          <Tooltip text={buttonTooltip}>
            <Button
              variant="strong"
              type="outline"
              onClick={handleAutoCompoundToggle}
              disabled={buttonDisabled}
            >
              {autoCompoundEnabled ? 'Stop' : 'Start'}
            </Button>
          </Tooltip>
        </div>
      </Card>
    </div>
  );
};

export default AutoCompoundSection;
