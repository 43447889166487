import cn from 'classnames';

const EventIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Event', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M34 24H24V34H34V24ZM32 2V6H16V2H12V6H10C7.79 6 6.02 7.79 6.02 10L6 38C6 40.21 7.79 42 10 42H38C40.21 42 42 40.21 42 38V10C42 7.79 40.21 6 38 6H36V2H32ZM38 38H10V16H38V38Z" />
    </svg>
  );
};

export { EventIcon };
