import { OperationPolicies } from '@anchorage/authorization';
import { useFeatureFlagsContext } from '@anchorage/feature-flags';
import { FEATURE_FLAGS } from '@anchorage/frontoffice/constants/split';

import type { OperationDrawer } from 'generated/graphql';

import { UpdatedIAMRoleUsers } from '../../../widgets/IAMRoleChanges';
import { OperationDetailPolicyWidget } from '../../../widgets/Policies/OperationDetailPolicyWidget';

type UpdateOrgPoliciesType = Extract<
  OperationDrawer.operation,
  { __typename: 'UpdateOrgPoliciesOperation' }
>;

type Props = {
  operation: UpdateOrgPoliciesType;
};

const UpdateOrgPoliciesOperation = ({ operation }: Props) => {
  const { useIsFeatureActive } = useFeatureFlagsContext();
  const [isIamRoleChangesEnabled] = useIsFeatureActive(
    FEATURE_FLAGS.CD_PERMISSIONS_M3_2,
  );
  const opPolicies = new OperationPolicies(operation);

  return (
    <div data-testid="update-org-policies-operation-detail">
      <OperationDetailPolicyWidget
        policies={opPolicies}
        type={operation.__typename}
      />
      {isIamRoleChangesEnabled &&
        (operation.roleChanges?.changes.length ?? 0) > 0 && (
          <UpdatedIAMRoleUsers changes={operation.roleChanges?.changes ?? []} />
        )}
    </div>
  );
};

export default UpdateOrgPoliciesOperation;
