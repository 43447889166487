import cn from 'classnames';

const LaptopWindowsIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LaptopWindows', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 36V34C42.21 34 43.98 32.21 43.98 30L44 10C44 7.79 42.21 6 40 6H8C5.79 6 4 7.79 4 10V30C4 32.21 5.79 34 8 34V36H0V40H48V36H40ZM8 10H40V30H8V10Z" />
    </svg>
  );
};

export { LaptopWindowsIcon };
