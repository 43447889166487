import cn from 'classnames';

const ExposurePlus1Icon = (props: SVGProps): JSX.Element => {
  const { title = 'ExposurePlus1', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M20 14H16V22H8V26H16V34H20V26H28V22H20V14ZM40 36H36V14.75L30 16.8V13.4L39.4 10H40V36Z" />
    </svg>
  );
};

export { ExposurePlus1Icon };
