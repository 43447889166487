import {
  ColumnDef,
  FilterFn,
  Row,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import {
  BaseActionListMediator,
  HookActionListHelpers,
  HookTableOptions,
  Option,
} from '../types';

export const useBaseActionList = <TData extends Option<TData>>(
  props: HookActionListHelpers<TData> & HookTableOptions<TData>,
): BaseActionListMediator<TData> => {
  const {
    startOpen,
    accessorKey = 'label',
    columns: providedColumns,
    filterFn,
    ...tableOptions
  } = props;

  const [open, setOpen] = useState(!!startOpen);
  // we need an state helper to be able to delete an row from outside the action-list
  // e.g. tag onRemove callback on an select component
  const [rowToDelete, setRowToDelete] = useState<
    Row<Option<TData>> | undefined
  >(undefined);

  const columns: ColumnDef<TData>[] = useMemo(() => {
    if (providedColumns) return providedColumns;

    return [
      {
        accessorKey,
        ...(filterFn
          ? {
              filterFn,
            }
          : {}),
      } as ColumnDef<TData>,
    ];
  }, [filterFn, accessorKey, providedColumns]);

  const filterByIdAndLabelHandler: FilterFn<TData> = (
    row,
    _columnId,
    filterValue,
  ) => {
    // Concatenate the values from multiple columns into a single string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const searchableRowContent = `${(row.original as { id: string } & TData)?.id || ''} ${row.original.label}`;

    // Perform a case-insensitive comparison
    return searchableRowContent
      .toLowerCase()
      .includes((filterValue as string).toLowerCase());
  };

  const mediator = useReactTable({
    ...tableOptions,
    columns,
    filterFns: {
      filterByIdAndLabel: filterByIdAndLabelHandler, //define as a filter function that can be used in column definitions
    },
    // https://github.com/TanStack/table/issues/4614#issuecomment-2014158617
    // these options may break the usage of expanding rows or client side pagination
    autoResetPageIndex: false,
    autoResetExpanded: false,
  });

  return {
    open,
    setOpen,
    rowToDelete,
    setRowToDelete,
    defaultOpen: !!startOpen,
    ...mediator,
  };
};

/**
 * Custom hook for creating an action list.
 *
 * @template TData - The type of data in the action list.
 * @param {object} options - The options for the action list.
 * @param {TData[]} options.data - The data for the action list.
 * @param {object[]} options.columns - The columns configuration for the action list.
 * @param {boolean} options.enableRowSelection - Whether row selection is enabled in the action list.
 * @param {function} options.onRowSelectionChange - The callback function for row selection change in the action list.
 * @returns {object} - The action list object.
 */
export const useActionList = <TData extends Option<TData>>(
  props: Pick<
    HookTableOptions<TData>,
    'data' | 'columns' | 'enableRowSelection' | 'onRowSelectionChange'
  > &
    HookActionListHelpers<TData>,
) => {
  return useBaseActionList({
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableMultiRowSelection: false,
    ...props,
  });
};

/**
 * Custom hook for creating a group action list.
 *
 * @template TData - The type of data in the action list.
 * @param {Object} options - The options for creating the group action list.
 * @param {TData[]} options.data - The data for the action list.
 * @param {Column<TData>[]} options.columns - The columns for the action list.
 * @param {object} options.state - The initial state for the action list.
 * @param {boolean} [options.filterFromLeafRows=true] - Whether to filter from leaf rows.
 * @param {number} [options.maxLeafRowFilterDepth=1] - The maximum depth for leaf row filtering.
 * @param {boolean} options.enableRowSelection - Whether to enable row selection.
 * @param {boolean} [options.enableMultiRowSelection=false] - Whether to enable multi-row selection.
 * @param {function} options.onRowSelectionChange - The callback function for row selection change.
 * @returns {TableInstance<TData>} - The table instance for the group action list.
 */
export const useGroupActionList = <TData extends Option<TData>>({
  data,
  state: initialState,
  filterFromLeafRows = true,
  maxLeafRowFilterDepth = 1,
  enableRowSelection,
  enableMultiRowSelection = false,
  onRowSelectionChange,
  ...props
}: HookActionListHelpers<TData> &
  Pick<
    HookTableOptions<TData>,
    | 'data'
    | 'state'
    | 'columns'
    | 'filterFns'
    | 'filterFromLeafRows'
    | 'maxLeafRowFilterDepth'
    | 'enableRowSelection'
    | 'onRowSelectionChange'
    | 'enableMultiRowSelection'
    | 'getSubRows'
    | 'onExpandedChange'
  >) => {
  const expanded = useMemo(() => {
    const expanded = {} as Record<string, boolean>;
    data.forEach((_, idx) => {
      expanded[idx.toString()] = true;
    });
    return expanded;
  }, [data]);

  const state = useMemo(() => {
    return {
      expanded,
      ...initialState,
    };
  }, [expanded, initialState]);

  return useBaseActionList({
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (originalRow) => {
      return originalRow.subRows;
    },
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    data,
    state,
    filterFromLeafRows,
    enableRowSelection,
    maxLeafRowFilterDepth,
    enableMultiRowSelection,
    ...(onRowSelectionChange ? { onRowSelectionChange } : {}),
    ...props,
  });
};
