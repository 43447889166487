import cn from 'classnames';
import { Command as CommandPrimitive } from 'cmdk';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

/**
 * Content for when the search returns no results.
 *
 * @component
 * @example
 * ```tsx
 * <CommandEmpty />
 * ```
 */
export const CommandEmpty = forwardRef<
  ElementRef<typeof CommandPrimitive.Empty>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className={cn(
      [
        'flex',
        'items-center',
        'justify-center',
        'py-6',
        'text-center',
        'font-comp-action-list-medium-font-family',
        'font-comp-action-list-medium-font-size',
        'font-comp-action-list-medium-font-weight',
        'leading-comp-action-list-medium-line-height',
        'text-color-comp-action-list-on-container-default',
        'min-w-[240px]',
        'min-h-[135px]',
      ],
      className,
    )}
    {...props}
  />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;
