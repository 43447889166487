import cn from 'classnames';

const LightbulbOutlineIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LightbulbOutline', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18 42C18 43.1 18.9 44 20 44H28C29.1 44 30 43.1 30 42V40H18V42ZM24 4C16.27 4 10 10.27 10 18C10 22.76 12.38 26.95 16 29.48V34C16 35.1 16.9 36 18 36H30C31.1 36 32 35.1 32 34V29.48C35.62 26.95 38 22.76 38 18C38 10.27 31.73 4 24 4ZM29.71 26.2L28 27.39V32H20V27.4L18.29 26.21C15.6 24.33 14 21.27 14 18.01C14 12.5 18.49 8.01 24 8.01C29.51 8.01 34 12.5 34 18.01C34 21.26 32.4 24.32 29.71 26.2Z" />
    </svg>
  );
};

export { LightbulbOutlineIcon };
