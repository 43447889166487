import {
  FieldType,
  NormalizedPayload,
  NormalizedSignedAddressField,
  NormalizedSignedAmountField,
  NormalizedSignedPreviewLayout,
  NormalizedSignedTextField,
  NormalizedUnsignedTextField,
} from '@anchorage/vsp';

import { AddressRow } from './AddressRow';
import { AmountRow } from './AmountRow';
import { DividerRow } from './DividerRow';
import PreviewLayoutRow from './PreviewLayoutRow';
import { TextRow } from './TextRow';
import { UnknownRow } from './UnknownRow';

export const getFieldComponent = (
  field: NormalizedPayload['fields'][number],
  options: Record<string, any> = {},
) => {
  switch (field.type) {
    case FieldType.SignedText:
      return <TextRow field={field as NormalizedSignedTextField} />;
    case FieldType.SignedAddress:
      return <AddressRow field={field as NormalizedSignedAddressField} />;
    case FieldType.SignedAmount:
      return <AmountRow field={field as NormalizedSignedAmountField} />;
    case FieldType.UnsignedDivider:
      return <DividerRow />;
    case FieldType.PreviewLayout:
      return (
        <PreviewLayoutRow
          field={field as NormalizedSignedPreviewLayout}
          {...options}
        />
      );
    default:
      return <UnknownRow field={field as NormalizedUnsignedTextField} />;
  }
};
