import { Text } from '@latitude/text';

import { usePageName } from './usePageName';

export const PageName = () => {
  const pageName = usePageName();

  return (
    <Text variant="headingSmall" className="text-color-ref-text-strong">
      {pageName}
    </Text>
  );
};
