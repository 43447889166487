import { animated } from '@react-spring/web';
import { ReactNode, useState } from 'react';

import { Text } from '@anchorage/common/dist/components';
import { useAnimateHeight } from '@anchorage/common/dist/hooks';

import css from './styles.module.scss';

type Props = {
  children: ReactNode[];
  collapsible?: boolean;
  classNameSection?: string;
  visibleItems: number;
  totalItems: number;
};

const SeeMoreCollapse = ({
  children,
  collapsible = false,
  visibleItems,
  totalItems,
}: Props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [ref, animationProps] = useAnimateHeight(!(collapsed && collapsible));

  return (
    <>
      {collapsible && collapsed && (
        <button
          data-testid="collapsible-button"
          className={css.collapsibleButton}
          onClick={() => setCollapsed((collapsed) => !collapsed)}
        >
          <Text className={css.collapsibleButton} size="small">
            {`Show More (${totalItems - visibleItems})`}
          </Text>
        </button>
      )}

      {animationProps(
        (animationStyles, item) =>
          item && (
            <>
              <animated.div
                // @ts-ignore
                style={animationStyles}
                data-testid="collapsible-container"
                className={css.collapsibleContainer}
              >
                <div ref={ref}>
                  {children.map((subSec, index) => {
                    return (
                      <div
                        key={`sub_section_${index}`}
                        className={css.subSections}
                      >
                        {subSec}
                      </div>
                    );
                  })}
                  <button
                    data-testid="collapsible-button"
                    className={css.collapsibleButton}
                    onClick={() => setCollapsed((collapsed) => !collapsed)}
                  >
                    <Text className={css.collapsibleButton} size="small">
                      Show Less
                    </Text>
                  </button>
                </div>
              </animated.div>
            </>
          ),
      )}
    </>
  );
};

export default SeeMoreCollapse;
