import { animated, useSpring } from '@react-spring/web';
import cn from 'classnames';
import QRCodeElement from 'qrcode.react';
import * as React from 'react';

import { Button, Text, Tooltip } from '@anchorage/common/dist/components';
import {
  QRCodeIcon,
  SearchIcon,
} from '@anchorage/common/dist/components/Icons';
import { useAnimateHeight } from '@anchorage/common/dist/hooks';
import {
  ANIMATION_CONFIG_ENTER,
  ANIMATION_CONFIG_LEAVE,
} from '@anchorage/common/dist/hooks/useAnimateHeight';
import { colors } from '@anchorage/common/dist/styles';
import { truncateString } from '@anchorage/common/dist/utils/format';
import CopyToClipboard from '@anchorage/frontoffice/components/CopyToClipboard';

import css from './styles.module.scss';

import AddressBadges from './AddressBadges';

type AddressProps = Pick<
  AddressWithBadgesProps,
  'destinationNames' | 'address'
> & {
  className?: string;
};

const AddressText = ({
  destinationNames,
  address,
  className,
}: AddressProps) => {
  const classes = cn([css.root, className]);
  if (destinationNames.length) {
    return (
      <div className={classes}>
        <Text size="small">{destinationNames.join(', ')}</Text>
        <Text
          className={cn([css.address, css.subtext])}
          size="small"
          type="mono"
        >
          {address}
        </Text>
      </div>
    );
  }

  return (
    <div className={classes}>
      <Text className={css.address} size="small" type="mono">
        {address}
      </Text>
    </div>
  );
};

type AddressWithBadgesProps = {
  address: string;
  blockchainExplorerURL?: string | null;
  isAnchorageVault?: boolean;
  isTrusted?: boolean;
  destinationNames: string[];
  hideQRCode?: boolean;
};

const AddressWithBadges = ({
  address,
  blockchainExplorerURL,
  isAnchorageVault,
  isTrusted,
  destinationNames,
  hideQRCode = false,
  ...restProps
}: AddressWithBadgesProps) => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [isQRVisible, setIsQRVisible] = React.useState(false);
  const styles = useSpring({
    transform: isQRVisible ? 'scale(1)' : 'scale(0.8)',
    opacity: isQRVisible ? '1' : '0',
    transformOrigin: 'top center',
    config: isQRVisible ? ANIMATION_CONFIG_ENTER : ANIMATION_CONFIG_LEAVE,
  });
  const [qrRef, qrAnimationProps] = useAnimateHeight(isQRVisible);

  const inputSectionClasses = cn({
    [css.inputSection!]: true,
    [css.hovering!]: isHovering,
  });

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      {...restProps}
    >
      <div className={inputSectionClasses}>
        <CopyToClipboard
          textToCopy={address}
          successText={`Address "${truncateString(address)}" copied`}
        >
          <AddressText
            className={css.addressWithBadges}
            address={address}
            destinationNames={destinationNames}
          />
        </CopyToClipboard>
        <div className={css.buttons}>
          <CopyToClipboard
            successText={`Address "${truncateString(address)}" copied`}
            tooltipPosition="bottomLeft"
            textToCopy={address}
          />
          {blockchainExplorerURL ? (
            <Tooltip
              background="light"
              position="bottomLeft"
              title="View on blockchain explorer"
            >
              <Text<'a'>
                type="link"
                tag="a"
                href={blockchainExplorerURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button type="tertiary" Icon={SearchIcon} />
              </Text>
            </Tooltip>
          ) : null}
          {hideQRCode ? null : (
            <Tooltip
              position="bottomLeft"
              background="light"
              title={`${isQRVisible ? 'Hide' : 'Show'} QR Code`}
            >
              <Button
                Icon={QRCodeIcon}
                className={cn({ [css.activeButton!]: isQRVisible })}
                type="tertiary"
                onClick={() => setIsQRVisible((isVisible) => !isVisible)}
              />
            </Tooltip>
          )}
        </div>
      </div>
      <AddressBadges
        isTrusted={isTrusted}
        isAnchorageVault={isAnchorageVault}
      />
      {qrAnimationProps(
        (qrAnimationStyles, item) =>
          item && (
            // @ts-ignore
            <animated.div style={qrAnimationStyles}>
              <div className={css.qrCodeWrapper} ref={qrRef}>
                <animated.div style={styles}>
                  <QRCodeElement
                    className={css.qrCode}
                    bgColor={colors.marinaBlue10}
                    fgColor="white"
                    renderAs="svg"
                    size={200}
                    value={address}
                  />
                </animated.div>
              </div>
            </animated.div>
          ),
      )}
    </div>
  );
};

export default AddressWithBadges;
