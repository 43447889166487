import { AssetIcon, Text } from '@anchorage/common/dist/components';
import { SEPARATOR } from '@anchorage/frontoffice/constants/styles';

import css from './styles.module.scss';

import SectionRow from '../SectionRow';
import WidgetWrapper from '../WidgetWrapper';

type Props = {
  abbreviation: string;
  assetName: string;
  networkName?: string;
  isNetworkLevel: boolean;
  includeSubtitle?: boolean;
  iconURL: string;
};

const Asset = ({
  assetName,
  networkName,
  isNetworkLevel,
  abbreviation,
  iconURL,
  includeSubtitle = false,
}: Props) => {
  const assetInfo = isNetworkLevel ? (
    `All ${networkName} assets`
  ) : (
    <Text className={css.asset}>
      <AssetIcon iconURL={iconURL} name={assetName} />{' '}
      {`${abbreviation}${SEPARATOR}${assetName}`}
    </Text>
  );

  return (
    <WidgetWrapper>
      <SectionRow title="Asset">
        <div className={css.container}>
          <Text size="small">{assetInfo}</Text>
          {includeSubtitle && isNetworkLevel ? (
            <Text
              className={css.subtext}
              size="small"
            >{`You'll be able to send any asset in the ${networkName} network to this trusted destination`}</Text>
          ) : null}
        </div>
      </SectionRow>
    </WidgetWrapper>
  );
};

export default Asset;
