import React from 'react';

// Types
import type { NFTItemContentProps } from '../_types';

// Components
import CollectionCard from '../../CollectionCard';

const CollectionNFTItemContent: React.FC<NFTItemContentProps> = ({
  index,
  data,
}) => <CollectionCard key={`${data.title}-${index}`} {...data} />;

export default CollectionNFTItemContent;
