import cn from 'classnames';

const CompareArrowsIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CompareArrows', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18.02 28H4V32H18.02V38L26 30L18.02 22V28ZM29.98 26V20H44V16H29.98V10L22 18L29.98 26Z" />
    </svg>
  );
};

export { CompareArrowsIcon };
