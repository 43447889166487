import cn from 'classnames';

const GpsFixedIcon = (props: SVGProps): JSX.Element => {
  const { title = 'GpsFixed', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 16C19.58 16 16 19.58 16 24C16 28.42 19.58 32 24 32C28.42 32 32 28.42 32 24C32 19.58 28.42 16 24 16ZM41.88 22C40.96 13.66 34.34 7.04 26 6.12V2H22V6.12C13.66 7.04 7.04 13.66 6.12 22H2V26H6.12C7.04 34.34 13.66 40.96 22 41.88V46H26V41.88C34.34 40.96 40.96 34.34 41.88 26H46V22H41.88ZM24 38C16.27 38 10 31.73 10 24C10 16.27 16.27 10 24 10C31.73 10 38 16.27 38 24C38 31.73 31.73 38 24 38Z" />
    </svg>
  );
};

export { GpsFixedIcon };
