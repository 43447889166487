import { Modal } from '@latitude/modal';
import { useMemo } from 'react';

import { Loader } from '@anchorage/common/dist/components';
import FadingTransition from '@anchorage/frontoffice/components/FadingTransition';

import walletHasBlockingOperation from 'components/Vault/shared/utils/walletHasBlockingOperation';

import { useStandardizedStakingModals } from 'hooks/useStandardizedStakingModals';
import useStaticAssetStandardizedStakingSupport from 'hooks/useStaticAssetStandardizedStakingSupport';

import getPositionAmout from '../utils/getPositionAmout';
import getStakingPositionsForWallet from '../utils/getStakingPositionsForWallet';

import {
  StakingPositionState,
  useWalletStakingDetailsQuery,
} from 'generated/graphql';

import AutoCompoundSection from '../AutoCompound/AutoCompoundSection';
import useStandardizedStakingContext from '../StandardizedStakingContext/useStandardizedStakingContext';
import { StandardizedStakeFlowOrigin } from '../constants';
import TotalStaked from './TotalStaked';
import UnclaimedRewardsCard from './UnclaimedRewardsCard';
import ValidatorsList from './ValidatorsList';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  walletId: string;
}

const StandardizedStakingDetailsModal = ({
  isOpen,
  onClose,
  walletId,
}: Props) => {
  const { openStakeWizard } = useStandardizedStakingModals();

  const { walletWithStakingInfo, assetTypeInfo, hasStakingPermission } =
    useStandardizedStakingContext();

  // Get standardized staking support status from static asset data
  const { staticAssetStakingSupport } =
    useStaticAssetStandardizedStakingSupport({
      assetTypeID: assetTypeInfo.assetTypeID,
    });

  const { data, loading: isLoading } = useWalletStakingDetailsQuery({
    variables: {
      walletId,
    },
    // only load data when modal is open
    // otherwise, the query will be called for all wallets in drawer
    skip: !isOpen,
  });

  const wallet = data?.wallets.edges[0];
  const walletAsset = wallet?.node.assets[0];
  const stakingData = walletAsset?.staking;
  const stakeAmountStates = stakingData?.stakeAmountStates;
  const totalStaking = getPositionAmout(
    stakeAmountStates,
    StakingPositionState.STAKED,
  );
  const stakeForm = stakingData?.stakeForm;
  const autoCompoundForm = stakingData?.autoCompoundForm;

  const supportsAutoCompound =
    staticAssetStakingSupport?.supportsAutoCompounding;

  const supportsClaimRewards =
    staticAssetStakingSupport?.supportsClaimingRewards;

  const hasBlockingOperation = walletHasBlockingOperation(
    walletWithStakingInfo,
  );

  const stakeButtonDisabled =
    !hasStakingPermission ||
    isLoading ||
    !stakeForm ||
    !!stakeForm?.disabledHint ||
    hasBlockingOperation;

  // StakingPosition is a position that a Wallet/AssetID has with a validator.
  const stakingPositions = useMemo(() => {
    return getStakingPositionsForWallet(stakingData?.positions ?? []);
  }, [stakingData]);

  return (
    <Modal
      isOpen={isOpen}
      leadingAction={{
        label: 'Cancel',
        onClick: onClose,
        disabled: isLoading,
      }}
      onClose={onClose}
      primaryAction={{
        label: 'Stake with different validator',
        onClick: () => {
          openStakeWizard({
            walletID: walletId,
            assetTypeID: assetTypeInfo.assetTypeID,
            stakeOrigin: StandardizedStakeFlowOrigin.STAKING_DETAILS_PAGE,
          });
        },
        disabled: stakeButtonDisabled,
      }}
      size="large"
      title="Staking Details"
      variant="primary"
      onInteractOutside={(e) => {
        e.preventDefault();
        onClose();
      }}
    >
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <FadingTransition data={totalStaking}>
            <TotalStaked
              amount={totalStaking?.amount}
              assetTypeInfoAbbreviation={assetTypeInfo.abbreviation}
            />
          </FadingTransition>

          {supportsAutoCompound && (
            <AutoCompoundSection
              autoCompoundForm={autoCompoundForm}
              assetTypeID={assetTypeInfo.assetTypeID}
            />
          )}

          {supportsClaimRewards && (
            <UnclaimedRewardsCard amount={stakingData?.totalClaimableRewards} />
          )}

          <FadingTransition data={stakingData}>
            <ValidatorsList stakingPositions={stakingPositions} />
          </FadingTransition>
        </>
      )}
    </Modal>
  );
};

export default StandardizedStakingDetailsModal;
