import { ReactNode } from 'react';

import { AnalyticsProvider } from '@anchorage/analytics';
import { MIXPANEL_OPTIONS } from '@anchorage/frontoffice/constants/app';

import { Organization, User } from 'generated/graphql';

type Props = {
  user: Partial<User>;
  organization: Partial<Organization>;
  children: ReactNode;
};
const AnalyticsWrapper = ({ user, organization, children }: Props) => {
  return (
    <AnalyticsProvider
      user={user}
      organization={organization}
      options={{
        ...MIXPANEL_OPTIONS,
        enabled: true,
      }}
    >
      {children}
    </AnalyticsProvider>
  );
};

export default AnalyticsWrapper;
