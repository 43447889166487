import cn from 'classnames';

const BatteryCharging50Icon = (props: SVGProps): JSX.Element => {
  const { title = 'BatteryCharging50', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M28.93 27L22 40V29H18L19.07 27H14V41.33C14 42.8 15.19 44 16.67 44H31.34C32.81 44 34.01 42.81 34.01 41.33V27H28.93Z" />
      <path
        opacity="0.3"
        d="M31.33 8H28V4H20V8H16.67C15.19 8 14 9.19 14 10.67V27H19.07L26 14V25H30L28.93 27H34V10.67C34 9.19 32.81 8 31.33 8Z"
      />
    </svg>
  );
};

export { BatteryCharging50Icon };
