import cn from 'classnames';
import * as React from 'react';

import { Text } from '@anchorage/common/dist/components';

import css from './styles.module.scss';

type Props = Pick<
  React.HTMLAttributes<HTMLDivElement>,
  'className' | 'children'
>;

const Pill: React.FC<Props> = ({ className = '', children }) => {
  const classes = cn({
    [className]: Boolean(className),
    [css.pill!]: true,
  });

  return (
    <Text size="tiny" className={classes} data-testid="pill">
      <>{children}</>
    </Text>
  );
};

export default Pill;
