import cn from 'classnames';

const AlarmOffIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AlarmOff', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 12.29C31.73 12.29 38 18.56 38 26.29C38 27.98 37.69 29.59 37.14 31.09L40.18 34.13C41.34 31.76 42 29.1 42 26.29C42 16.35 33.94 8.29 23.99 8.29C21.18 8.29 18.53 8.95 16.15 10.1L19.2 13.15C20.7 12.6 22.31 12.29 24 12.29ZM44 11.73L34.81 4.02L32.24 7.08L41.43 14.79L44 11.73ZM5.84004 4.88L3.29004 7.42L5.95004 10.08L3.73004 11.94L6.57004 14.78L8.79004 12.92L10.39 14.52C7.66004 17.68 6.00004 21.79 6.00004 26.29C6.00004 36.23 14.04 44.29 23.99 44.29C28.5 44.29 32.61 42.62 35.76 39.89L40.16 44.29L42.7 41.74L7.79004 6.83L5.84004 4.88ZM32.94 37.07C30.51 39.08 27.4 40.29 24 40.29C16.27 40.29 10 34.02 10 26.29C10 22.89 11.21 19.78 13.22 17.35L32.94 37.07ZM16.03 6.84L13.19 4L11.49 5.43L14.33 8.27L16.03 6.84Z" />
    </svg>
  );
};

export { AlarmOffIcon };
