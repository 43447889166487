import React from 'react';

// Components
import { TableCell, TableRow } from '../../../../components/TableV2';

// Styles
import css from '../index.css';

const NFTHeaders: React.FC = () => (
  <TableRow data-testid="nft-headers">
    <TableCell className={css.nftCell} type="head">
      NFT
    </TableCell>
    <TableCell className={css.dateCell} type="head">
      Date Added
    </TableCell>
    <TableCell className={css.valueCell} type="head">
      Approx. Price
    </TableCell>
  </TableRow>
);

export default NFTHeaders;
