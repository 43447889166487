import cn from 'classnames';

const CakeIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Cake', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 12C26.21 12 28 10.21 28 8C28 7.25 27.79 6.54 27.43 5.94L24 0L20.57 5.94C20.21 6.54 20 7.25 20 8C20 10.21 21.79 12 24 12ZM33.19 31.97L31.04 29.82L28.88 31.97C26.27 34.58 21.71 34.58 19.1 31.97L16.95 29.82L14.79 31.97C13.5 33.28 11.77 34 9.92 34C8.47 34 7.12 33.54 6 32.77V42C6 43.1 6.9 44 8 44H40C41.1 44 42 43.1 42 42V32.77C40.88 33.54 39.54 34 38.08 34C36.23 34 34.5 33.28 33.19 31.97ZM36 18H26V14H22V18H12C8.69 18 6 20.69 6 24V27.08C6 29.24 7.76 31 9.92 31C10.97 31 11.95 30.59 12.69 29.85L16.97 25.58L21.24 29.84C22.72 31.32 25.3 31.32 26.78 29.84L31.06 25.58L35.33 29.84C36.07 30.58 37.05 30.99 38.1 30.99C40.26 30.99 42.02 29.23 42.02 27.07V24C42 20.69 39.31 18 36 18Z" />
    </svg>
  );
};

export { CakeIcon };
