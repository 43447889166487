import cn from 'classnames';

const FlightLandIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FlightLand', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M5 38H43V42H5V38ZM19.37 26.54L28.06 28.87L38.69 31.72C40.29 32.15 41.93 31.2 42.36 29.6C42.79 28 41.84 26.36 40.24 25.93L29.61 23.08L24.1 5.04L20.23 4V20.56L10.3 17.9L8.44 13.26L5.54 12.48V22.83L8.75 23.69L19.37 26.54Z" />
    </svg>
  );
};

export { FlightLandIcon };
