import cn from 'classnames';

const FiberSmartRecordIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FiberSmartRecord', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18 40C26.8366 40 34 32.8366 34 24C34 15.1634 26.8366 8 18 8C9.16344 8 2 15.1634 2 24C2 32.8366 9.16344 40 18 40Z" />
      <path d="M34 8.52V12.7C38.66 14.34 42 18.78 42 24C42 29.22 38.66 33.66 34 35.3V39.48C40.9 37.7 46 31.46 46 24C46 16.54 40.9 10.3 34 8.52Z" />
    </svg>
  );
};

export { FiberSmartRecordIcon };
