import _differenceBy from 'lodash/differenceBy';
import _intersectionBy from 'lodash/intersectionBy';
import _sortBy from 'lodash/sortBy';

import { RoleAction } from 'generated/graphql';
import type {
  OperationDrawer,
  RoleVaultFieldsFragment,
} from 'generated/graphql';

type ChangeRoleOperation = Extract<
  OperationDrawer.operation,
  { __typename: 'ChangeRoleOperation' }
>;
type Permission = ChangeRoleOperation['role']['permissions'][number];
type SourceVault = Permission['sourceVaults'][number];

export enum ChangeRoleStatus {
  ADDED = 'added',
  REMOVED = 'removed',
  ORIGINAL = 'original',
}

type PermissionsProps = {
  currentPermissions: Permission[];
  prevPermissions: Permission[];
};

type PermsByActionName = { [key: string]: SourceVault[] };

type UpdatesByAction = {
  [key in RoleAction]?: NonNullable<UpdatedPermissions>;
};

type UpdatedPermission = SourceVault & {
  vaultStatus: ChangeRoleStatus;
};

export type UpdatedPermissions = {
  updates: UpdatedPermission[];
  actionStatus: ChangeRoleStatus;
};

const ALL_VAULTS: SourceVault = {
  uniqueID: '*',
  name: 'All vaults',
  vaultID: {
    vaultSubID: '',
    __typename: 'VaultID',
  },
  __typename: 'Vault',
};

/**
 * Creates an object that groups permissions by their action.
 * @param permissions List of permissions
 * @returns
 */
export const createPermissionsByAction = (permissions: Permission[]) => {
  return permissions.reduce((acc: PermsByActionName, perm) => {
    acc[perm.action] = perm.sourceVaults.length
      ? perm.sourceVaults
      : [ALL_VAULTS];
    return acc;
  }, {});
};

export const determineActionStatus = (isAdded: boolean, isRemoved: boolean) => {
  if (isAdded) {
    return ChangeRoleStatus.ADDED;
  } else if (isRemoved) {
    return ChangeRoleStatus.REMOVED;
  }
  return ChangeRoleStatus.ORIGINAL;
};

/**
 * Iterates through the actions available in the current and previous permissions.
 * For each action, the logic is split up into these parts:
 * 1. Unchanged vaults are the intersection
 * 2. Removed vaults are the difference of previous and current
 * 3. Added vaults are the difference of current and previous
 * @param currentPermissions The current permissions for the role of this operation
 * @param prevPermissions The permissions attached to the organization
 * @returns An object of @typedef UpdatesByAction
 */
export const generateUpdatedPermissions = ({
  currentPermissions,
  prevPermissions,
}: PermissionsProps) => {
  const updatedPermissions: UpdatesByAction = {};

  const currentActions = currentPermissions.map((perm) => perm.action);
  const prevActions = prevPermissions.map((perm) => perm.action);
  const allActions = new Set([...currentActions, ...prevActions]);

  const prevPermsByAction = createPermissionsByAction(prevPermissions);
  const currPermsByAction = createPermissionsByAction(currentPermissions);

  allActions.forEach((action: RoleAction) => {
    const prevVaults = prevPermsByAction[action] || [];
    const currVaults = currPermsByAction[action] || [];

    const unchangedVaults = _intersectionBy(
      prevVaults,
      currVaults,
      'uniqueID',
    ).map((vault) => ({ ...vault, vaultStatus: ChangeRoleStatus.ORIGINAL }));

    const removedVaults = _differenceBy<
      RoleVaultFieldsFragment,
      RoleVaultFieldsFragment
    >(prevVaults, currVaults, 'uniqueID').map((vault) => ({
      ...vault,
      vaultStatus: ChangeRoleStatus.REMOVED,
    }));

    const addedVaults = _differenceBy<
      RoleVaultFieldsFragment,
      RoleVaultFieldsFragment
    >(currVaults, prevVaults, 'uniqueID').map((vault) => ({
      ...vault,
      vaultStatus: ChangeRoleStatus.ADDED,
    }));

    const isActionAdded = !!(
      !unchangedVaults.length &&
      !removedVaults.length &&
      addedVaults.length
    );
    const isActionRemoved = !!(
      !unchangedVaults.length &&
      removedVaults.length &&
      !addedVaults.length
    );
    const actionStatus = determineActionStatus(isActionAdded, isActionRemoved);
    const sortedVaults = _sortBy(
      [...unchangedVaults, ...removedVaults, ...addedVaults],
      (vault) => vault.name,
    );

    updatedPermissions[action] = { updates: sortedVaults, actionStatus };
  });

  return updatedPermissions;
};
