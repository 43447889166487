import cn from 'classnames';

const LocalMoviesIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalMovies', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M36 6V10H32V6H16V10H12V6H8V42H12V38H16V42H32V38H36V42H40V6H36ZM16 34H12V30H16V34ZM16 26H12V22H16V26ZM16 18H12V14H16V18ZM36 34H32V30H36V34ZM36 26H32V22H36V26ZM36 18H32V14H36V18Z" />
    </svg>
  );
};

export { LocalMoviesIcon };
