import * as React from 'react';

import { MultiUserApprovalIcon } from '@anchorage/common/dist/components/Icons';
import {
  CloseButton,
  Modal,
  Body as ModalBody,
  Header as ModalHeader,
  Wrapper as ModalWrapper,
} from '@anchorage/common/dist/components/ModalV2';
import { API_V2_PATH } from '@anchorage/frontoffice/utils/routes';

import { OperationAction } from 'generated/graphql';

import css from './styles.module.scss';

import EndorseActionModalContent from './EndorseActionModalContent';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onCloseNavigateRoute?: string;
  operationID: string;
  operationAction:
    | OperationAction.ROLE_CHANGE
    | OperationAction.USER_ADD_AND_POLICIES
    | OperationAction.TRUSTED_DESTINATION_ADD
    | OperationAction.TRUSTED_DESTINATION_REMOVE
    | OperationAction.GENERIC_ORGANIZATION_CHANGE
    | OperationAction.WITHDRAW
    | OperationAction.GENERIC
    | OperationAction.OFFCHAIN_OPERATION;
};

const EndorseActionModal = ({
  operationID,
  operationAction,
  isVisible,
  onClose,
  onCloseNavigateRoute = API_V2_PATH,
}: Props) => {
  return (
    <div data-testid="endorse-action-modal-wrapper">
      <Modal
        data-testid="endorse-action-modal"
        isOpen={isVisible}
        onClose={onClose}
        allowEscapeClose
      >
        <ModalWrapper className={css.modalWrapper}>
          <ModalHeader className={css.modalHeader}>
            <div className={css.headerContent}>
              <MultiUserApprovalIcon className={css.phoneIcon} />
              Complete endorsement in the app
            </div>
            <CloseButton data-testid="endorsement-modal-close-button" />
          </ModalHeader>
          <ModalBody>
            <EndorseActionModalContent
              isVisible={isVisible}
              onClose={onClose}
              operationAction={operationAction}
              operationID={operationID}
              onCloseNavigateRoute={onCloseNavigateRoute}
            />
          </ModalBody>
        </ModalWrapper>
      </Modal>
    </div>
  );
};

export default EndorseActionModal;
