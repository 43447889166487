import cn from 'classnames';

const CloudCircleIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CloudCircle', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 4C12.95 4 4 12.95 4 24C4 35.05 12.95 44 24 44C35.05 44 44 35.05 44 24C44 12.95 35.05 4 24 4ZM33 32H16C12.69 32 10 29.31 10 26C10 22.69 12.69 20 16 20L16.27 20.03C17.16 16.57 20.27 14 24 14C28.42 14 32 17.58 32 22H33C35.76 22 38 24.24 38 27C38 29.76 35.76 32 33 32Z" />
    </svg>
  );
};

export { CloudCircleIcon };
