import { ROLE_ACTIONS_TITLES } from 'constants/operations';

import { Text } from '@anchorage/common/dist/components';

import { generateUpdatedPermissions } from 'components/shared/OperationDrawer/utils/permissions';

import { RoleAction } from 'generated/graphql';
import type { OperationDrawer, RoleChangeType } from 'generated/graphql';

import css from './styles.module.scss';

import SectionRow from '../../shared/SectionRow';
import WidgetWrapper from '../../shared/WidgetWrapper';
import ActionSection from './ActionSection';

type ChangeRoleOperation = Extract<
  OperationDrawer.operation,
  { __typename: 'ChangeRoleOperation' }
>;

type RoleActions = Array<keyof typeof RoleAction>;

type Props = {
  organization: ChangeRoleOperation['operationInfo']['organization'];
  role: ChangeRoleOperation['role'];
  roleChangeType: RoleChangeType;
};

const PermissionsTitle = ({ roleChangeType }: { roleChangeType: string }) => (
  <>
    <Text size="small">Permissions</Text>
    {roleChangeType === 'UPDATE' && (
      <div className={css.subtitle}>
        <Text className={css.added} size="small">
          added
        </Text>
        or
        <Text className={css.removed} size="small">
          removed
        </Text>
      </div>
    )}
  </>
);

const Permissions = ({
  organization,
  role: { name: groupName, permissions: currentPermissions },
  roleChangeType,
}: Props) => {
  const prevRole = organization?.roles.find((role) => role.name === groupName);
  const prevPermissions = prevRole?.permissions ?? [];

  const updatedPermissions = generateUpdatedPermissions({
    currentPermissions,
    prevPermissions,
  });

  return (
    <WidgetWrapper>
      <SectionRow title={<PermissionsTitle roleChangeType={roleChangeType} />}>
        {(Object.keys(ROLE_ACTIONS_TITLES) as RoleActions).map((action) => (
          <ActionSection
            data-testid={`action-section-${action}`}
            key={action}
            action={action}
            actionPermissions={updatedPermissions[action]}
            roleChangeType={roleChangeType}
          />
        ))}
      </SectionRow>
    </WidgetWrapper>
  );
};

export default Permissions;
