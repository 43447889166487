import * as ToastPrimitives from '@radix-ui/react-toast';
import cn from 'classnames';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

/**
 * Represents the description of a toast.
 *
 * @component
 * @example
 * ```tsx
 * <ToastDescription className="my-toast-description" />
 * ```
 */
export const ToastDescription = forwardRef<
  ElementRef<typeof ToastPrimitives.Description>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cn(
      [
        'font-ref-web-body-regular-font-family',
        'font-ref-web-body-regular-font-weight',
        'text-ref-web-body-regular-font-size',
        'tracking-font-ref-web-body-regular-letter-spacing',
        'leading-ref-web-body-regular-line-height',
      ],
      className,
    )}
    {...props}
  />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;
