import cn from 'classnames';

const AccountBalanceIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AccountBalance', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M8 21V35H14V21H8ZM20 21V35H26V21H20ZM4 45H42V39H4V45ZM32 21V35H38V21H32ZM23 3L4 13V17H42V13L23 3Z" />
    </svg>
  );
};

export { AccountBalanceIcon };
