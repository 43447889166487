import cn from 'classnames';

const AnchorageDiamondIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AnchorageDiamond-48px', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9999 18.5682L15.749 13.2841L23.9999 8L32.2519 13.2841L23.9999 18.5682ZM23.9999 41.1356L15.749 35.8515L23.9999 30.5674L32.2519 35.8515L23.9999 41.1356ZM4 28.3312L12.252 33.6154L20.5029 28.3312L12.252 23.0471L4 28.3312ZM35.7488 33.6154L27.4968 28.3312L35.7488 23.0471L43.9997 28.3312L35.7488 33.6154Z"
      />
    </svg>
  );
};

export { AnchorageDiamondIcon };
