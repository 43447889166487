import cn from 'classnames';

const GroupIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Group', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M32 22C35.31 22 37.98 19.31 37.98 16C37.98 12.69 35.31 10 32 10C28.69 10 26 12.69 26 16C26 19.31 28.69 22 32 22ZM16 22C19.31 22 21.98 19.31 21.98 16C21.98 12.69 19.31 10 16 10C12.69 10 10 12.69 10 16C10 19.31 12.69 22 16 22ZM16 26C11.33 26 2 28.34 2 33V38H30V33C30 28.34 20.67 26 16 26ZM32 26C31.42 26 30.77 26.04 30.07 26.11C32.39 27.78 34 30.03 34 33V38H46V33C46 28.34 36.67 26 32 26Z" />
    </svg>
  );
};

export { GroupIcon };
