import { cva } from 'class-variance-authority';

export const titleVariants = cva([], {
  variants: {
    variant: {
      info: 'text-color-ref-text-strong',
      success: 'text-color-ref-ui-status-success',
      warning: 'text-color-ref-ui-status-warning',
      error: 'text-color-ref-ui-status-danger',
    },
  },
});
