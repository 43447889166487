import Text from '@latitude/text';
import React from 'react';

import ErrorMessage from 'components/shared/ErrorMessage';
import Address from 'components/shared/OperationDrawer/components/shared/Address';
import SectionRow from 'components/shared/OperationDrawer/components/shared/SectionRow';
import WidgetWrapper from 'components/shared/OperationDrawer/components/shared/WidgetWrapper';
import AddressInfo from 'components/shared/OperationDrawer/components/widgets/AddressInfo';
import AmountAndFees from 'components/shared/OperationDrawer/components/widgets/AmountAndFees';
import PolicyNotes from 'components/shared/PolicyNotes';

import {
  DelegateOperation,
  PrepareDelegationMutation,
} from 'generated/graphql';

import css from './styles.module.scss';

import { EditCommentSection } from '../../../../../../shared/EditCommentSection';
import PricingDisclosureLinkSection from '../../../PricingDisclosureLinkSection';
import {
  STAKING_ERROR_MESSAGE_SUBTITLE,
  STAKING_ERROR_MESSAGE_TITLE,
} from '../../../constants';
import useStandardizedStakingContext from '../../StandardizedStakingContext/useStandardizedStakingContext';
import { StandardizedStakingMode } from '../../constants';

type Props = {
  data?: PrepareDelegationMutation['prepareDelegation'] | null;
};

const getCopy = (
  mode: StandardizedStakingMode | null,
  assetAbbreviation: string,
) => {
  const defaultStakeCopy = {
    operationTitle: `Stake ${assetAbbreviation}`,
    stakeSourceSectionTitle: 'Stake to',
    stakeAmountSectionTitle: 'Staked amount',
  };

  switch (mode) {
    case StandardizedStakingMode.STAKE:
      return defaultStakeCopy;
    case StandardizedStakingMode.UNSTAKE:
      return {
        operationTitle: `Unstake ${assetAbbreviation}`,
        stakeSourceSectionTitle: 'Unstake from',
        stakeAmountSectionTitle: 'Unstaked amount',
      };
    default:
      return defaultStakeCopy;
  }
};

const SSStakeUnstakeReviewPage: React.FC<Props> = ({ data }) => {
  const delegateOperationData = data as DelegateOperation;

  const { standardizedStakingMode, assetTypeInfo, walletWithStakingInfo } =
    useStandardizedStakingContext();
  const assetAbbreviation = assetTypeInfo.abbreviation;

  const vault = walletWithStakingInfo.vault;
  const sourceVaultName = vault.name;
  const accountName = vault.account.name;

  if (!delegateOperationData) {
    return (
      <ErrorMessage
        title={STAKING_ERROR_MESSAGE_TITLE}
        subtitle={STAKING_ERROR_MESSAGE_SUBTITLE}
      />
    );
  }

  const {
    description,
    descriptionEditable,
    operationID = '',
    delegationAddress,
    amount: stakeAmount,
    maxFee,
  } = delegateOperationData;

  const walletName = walletWithStakingInfo.name;
  const depositAddress = walletWithStakingInfo.depositAddress.address;

  const destinationNames = delegationAddress ? [delegationAddress.name] : [];

  const { operationTitle, stakeSourceSectionTitle, stakeAmountSectionTitle } =
    getCopy(standardizedStakingMode, assetAbbreviation);

  return (
    <div className={css.mainContent}>
      <div>
        <Text variant="headingTiny" className={css.headerContentText}>
          {operationTitle}
        </Text>
        <Text>{`for ${assetAbbreviation} Staking`}</Text>
      </div>

      <EditCommentSection
        description={description}
        descriptionEditable={descriptionEditable}
        operationID={operationID}
        hasTopBorder={false}
      />

      {/* "Stake to/unstake from" section */}
      <WidgetWrapper>
        <SectionRow className={css.section} title={stakeSourceSectionTitle}>
          <Address
            destinationNames={destinationNames}
            address={delegationAddress?.destination ?? ''}
            blockchainExplorerURL={null}
          />
        </SectionRow>
      </WidgetWrapper>

      {/* Source, Vault and Account section */}
      <AddressInfo
        addressSectionTitle={'Source'}
        walletName={walletName}
        addresses={{ [depositAddress]: [walletName] }}
        vaultName={sourceVaultName}
        accountName={accountName}
        widgetWrapperClassName={css.stakeAddressInfoWrapper}
      />

      {/* Staked/unstaked amount and fees section */}
      <div className={css.amountAndFeeSection}>
        <AmountAndFees
          amount={stakeAmount}
          amountTitle={stakeAmountSectionTitle}
          fee={maxFee}
          showPriceDisclosure={false}
          widgetWrapperClassName={css.amountAndFeeSectionWrapper}
        />

        <PricingDisclosureLinkSection />
      </div>
      <PolicyNotes />
    </div>
  );
};

export default SSStakeUnstakeReviewPage;
