import cn from 'classnames';

const AssistantPhotoIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AssistantPhoto', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M28.8 12L28 8H10V42H14V28H25.2L26 32H40V12H28.8Z" />
    </svg>
  );
};

export { AssistantPhotoIcon };
