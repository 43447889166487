import cn from 'classnames';

const FormatStrikethroughIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatStrikethrough', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M20 38H28V32H20V38ZM10 8V14H20V20H28V14H38V8H10ZM6 28H42V24H6V28Z" />
    </svg>
  );
};

export { FormatStrikethroughIcon };
