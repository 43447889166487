import { Button } from '@latitude/button';
import { Separator } from '@latitude/separator';
import { Text } from '@latitude/text';
import cn from 'classnames';
import { separatorVariants } from 'variants/separator-variants';

import { Toast } from './Toast';
import { ToastAction } from './ToastAction';
import { ToastClose } from './ToastClose';
import { ToastDescription } from './ToastDescription';
import { ToastIcon } from './ToastIcon';
import { ToastTitle } from './ToastTitle';
import { useToasterContext } from './ToasterProvider';

/**
 * Renders the content of the toaster component.
 * @returns JSX.Element
 */
export const ToasterContent = () => {
  const { toasts } = useToasterContext();

  return toasts.map(
    ({
      id,
      title,
      description,
      action,
      link,
      variant = 'informational',
      ...props
    }) => {
      const { href, children: linkText, altText: linkAltText } = link || {};
      const {
        altText: actionAltText,
        fill = 'outline',
        ...buttonProps
      } = action || {};
      return (
        <Toast key={id} variant={variant} {...props}>
          <ToastIcon variant={variant} />
          <div className="grow">
            {title && <ToastTitle>{title}</ToastTitle>}
            {description && <ToastDescription>{description}</ToastDescription>}
          </div>
          {linkAltText && (
            <ToastAction altText={linkAltText} asChild>
              <Text variant="link">
                <a href={href}>{linkText} </a>
              </Text>
            </ToastAction>
          )}

          {actionAltText && (
            <ToastAction altText={actionAltText} asChild>
              <div>
                <Button
                  variant="strong"
                  type={fill!}
                  size="tiny"
                  className="!border-current !text-current"
                  {...buttonProps}
                />
              </div>
            </ToastAction>
          )}

          <Separator
            orientation="vertical"
            className={cn(separatorVariants({ variant }), '!h-6')}
          />

          <ToastClose />
        </Toast>
      );
    },
  );
};
