import { cva } from 'class-variance-authority';

export const separatorVariants = cva([], {
  variants: {
    variant: {
      informational: '!bg-color-ref-ui-divider-strong',
      success: '!bg-color-ref-ui-divider-strong',
      warning: '!bg-color-ref-ui-divider-divider',
      danger: '!bg-color-ref-ui-divider-strong',
      alert: '!bg-color-ref-ui-divider-strong',
    },
  },
  defaultVariants: {
    variant: 'informational',
  },
});
