// Utils
import cn from 'classnames';
import React from 'react';

import commonCss from '../../common.css';

// Components
import { TableCell } from '../../../../components/TableV2';

// Styles
import css from '../index.css';

const EmptyPlaceholder: React.FC = () => (
  <tr>
    <TableCell className={cn([css.tableCell, css.nftCell])}>
      <div className={css.nameImageContainer}>
        <div
          className={cn([
            css.imageMosaicContainer,
            commonCss.fastShimmerAnimation,
          ])}
        />
        <div className={css.textContainer}>
          <div
            className={cn([
              css.placeholderText,
              commonCss.fastShimmerAnimation,
            ])}
          />
          <div
            className={cn([
              css.placeholderSubText,
              commonCss.fastShimmerAnimation,
            ])}
          />
        </div>
      </div>
    </TableCell>
    <TableCell className={cn([css.tableCell, css.dateCell])}>
      <div
        className={cn([css.placeholderDate, commonCss.fastShimmerAnimation])}
      />
    </TableCell>
    <TableCell className={cn([css.tableCell, css.quantityCell])}>
      <div
        className={cn([
          css.placeholderQuantity,
          commonCss.fastShimmerAnimation,
        ])}
      />
    </TableCell>
    <TableCell className={cn([css.tableCell, css.valueCell])}>
      <div
        className={cn([css.placeholderValue, commonCss.fastShimmerAnimation])}
      />
    </TableCell>
  </tr>
);

export default EmptyPlaceholder;
