import cn from 'classnames';

const LooksIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Looks', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 20C16.28 20 10 26.28 10 34H14C14 28.49 18.49 24 24 24C29.51 24 34 28.49 34 34H38C38 26.28 31.72 20 24 20ZM24 12C11.87 12 2 21.87 2 34H6C6 24.08 14.08 16 24 16C33.92 16 42 24.08 42 34H46C46 21.87 36.13 12 24 12Z" />
    </svg>
  );
};

export { LooksIcon };
