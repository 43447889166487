import { useEffect, useState } from 'react';

type WindowWidth = {
  width: number;
  isMobile: boolean;
};

export const useWindowWidth = (): WindowWidth => {
  const [width, setWidth] = useState(0);
  const [isMobileAgent, setIsMobileAgent] = useState(false);

  useEffect(() => {
    setWidth(window.innerWidth);
    // Check the user agent to detect if the browser is mobile.
    setIsMobileAgent(
      !!navigator.userAgent.match(
        /(iPhone|Android|webOS|BlackBerry|IEMobile|Opera Mini|Windows Phone)/i,
      ),
    );

    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // Empty array - run on mount and unmount only
  }, []);

  // Trust the mobile agent but also check for small devices. We would
  // rather have a false negative than a false positive (think it's mobile when
  // it's not)
  const isMobile = isMobileAgent && width < 600;

  return {
    width,
    isMobile,
  };
};
