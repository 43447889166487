import cn from 'classnames';

const LabelIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Label', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M35.27 11.69C34.54 10.67 33.35 10 32 10L10 10.02C7.79 10.02 6 11.79 6 14V34C6 36.21 7.79 37.98 10 37.98L32 38C33.35 38 34.54 37.33 35.27 36.31L44 24L35.27 11.69Z" />
    </svg>
  );
};

export { LabelIcon };
