import cn from 'classnames';

const CaretUpIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Caret up', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 15.9999L12 27.9999L14.83 30.8299L24 21.6599L33.17 30.8299L36 27.9999L24 15.9999Z" />
    </svg>
  );
};

export { CaretUpIcon };
