import { Banner } from '@anchorage/common/dist/components';
import { BannerType } from '@anchorage/common/dist/components/Banner/_types';
import {
  UIDeveloperError,
  reportError,
} from '@anchorage/common/dist/utils/errors';
import {
  SmartContractRisk,
  SmartContractRiskLevel,
} from '@anchorage/frontoffice/generated/graphql';

import { OperationDrawer, OperationImplementations } from 'generated/graphql';

import css from './styles.module.scss';

const SmartContractRiskAssesment = ({
  operation,
}: {
  operation: OperationDrawer.operation;
}) => {
  if (operation.__typename !== OperationImplementations.GenericOperation) {
    return null;
  }

  const riskAssesment: SmartContractRisk | null = operation.smartContractRisk;
  if (
    riskAssesment == null ||
    riskAssesment.riskLevel == SmartContractRiskLevel.UNDETERMINED
  ) {
    return null;
  }

  let bannerType: BannerType = 'info';
  switch (riskAssesment?.riskLevel) {
    case SmartContractRiskLevel.HIGH:
      bannerType = 'error';
      break;
    case SmartContractRiskLevel.MEDIUM:
      bannerType = 'warning';
      break;
    case SmartContractRiskLevel.LOW:
      bannerType = 'success';
      break;
    case SmartContractRiskLevel.UNAVAILABLE:
      bannerType = 'warning';
      break;
    // No banner if UNDETERMINED:
    default:
      reportError(
        new UIDeveloperError('Unexpected risk level for smart contract risk'),
      );
  }

  return (
    <div className={css.riskAssesment}>
      <div className={css.divider} />

      <Banner type={bannerType} title={riskAssesment.description}>
        {riskAssesment.details}
      </Banner>
    </div>
  );
};

export default SmartContractRiskAssesment;
