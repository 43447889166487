import cn from 'classnames';

const GroupWorkIcon = (props: SVGProps): JSX.Element => {
  const { title = 'GroupWork', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 4C12.95 4 4 12.95 4 24C4 35.04 12.95 44 24 44C35.05 44 44 35.04 44 24C44 12.95 35.05 4 24 4ZM16 35C13.24 35 11 32.76 11 30C11 27.24 13.24 25 16 25C18.76 25 21 27.24 21 30C21 32.76 18.76 35 16 35ZM19 16C19 13.24 21.24 11 24 11C26.76 11 29 13.24 29 16C29 18.76 26.76 21 24 21C21.24 21 19 18.76 19 16ZM32 35C29.24 35 27 32.76 27 30C27 27.24 29.24 25 32 25C34.76 25 37 27.24 37 30C37 32.76 34.76 35 32 35Z" />
    </svg>
  );
};

export { GroupWorkIcon };
