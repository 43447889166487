import cn from 'classnames';

const LocationDisabledIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocationDisabled', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M41.88 22.17C40.96 13.83 34.34 7.21004 26 6.29004V2.17004H22V6.29004C19.74 6.54004 17.62 7.22004 15.69 8.23004L18.69 11.23C20.33 10.56 22.12 10.18 24 10.18C31.73 10.18 38 16.45 38 24.18C38 26.06 37.62 27.85 36.95 29.49L39.95 32.49C40.96 30.56 41.63 28.44 41.88 26.18H46V22.18H41.88V22.17ZM6 8.72004L10.07 12.79C7.93 15.41 6.5 18.63 6.11 22.17H2V26.17H6.12C7.04 34.51 13.66 41.13 22 42.05V46.17H26V42.05C29.54 41.66 32.76 40.23 35.38 38.09L39.46 42.17L42 39.63L8.55 6.17004L6 8.72004ZM32.53 35.25C30.17 37.07 27.21 38.17 24 38.17C16.27 38.17 10 31.9 10 24.17C10 20.95 11.1 18 12.92 15.64L32.53 35.25Z" />
    </svg>
  );
};

export { LocationDisabledIcon };
