// Components
// Utils
import _get from 'lodash/get';

// Constants
import { VoteDetailsUnionTypes, VoteType } from 'generated/graphql';
// Types
import type { OperationDrawer } from 'generated/graphql';

import SectionRow from '../../shared/SectionRow';
import WidgetWrapper from '../../shared/WidgetWrapper';
import CeloGovernanceVote from './CeloGovernanceVote';
import MakerExecutiveVote from './MakerExecutiveVote/';
import MakerGovernancePoll from './MakerGovernancePoll';

type VoteOperation = Extract<
  OperationDrawer.operation,
  { __typename: 'VoteOperation' }
>;

const { EXECUTIVE, POLL, REFERENDUM } = VoteType;
const VOTE_COMPONENT_MAPPING = {
  [EXECUTIVE]: MakerExecutiveVote,
  [POLL]: MakerGovernancePoll,
  [REFERENDUM]: CeloGovernanceVote,
};

type Props = {
  operation: VoteOperation;
};

const VoteFor = ({ operation }: Props) => {
  const { displayVoteType } = operation;

  const VoteComponent = VOTE_COMPONENT_MAPPING[displayVoteType];
  const isVoteComponentEmpty = !VoteComponent;

  const hasErrorOnReferendum =
    displayVoteType === REFERENDUM &&
    _get(operation, 'details.__typename') !==
      VoteDetailsUnionTypes.CeloProposalDetails;

  if (isVoteComponentEmpty || hasErrorOnReferendum) {
    return null;
  }

  return (
    <WidgetWrapper>
      <SectionRow title="Vote for">
        <VoteComponent operation={operation} />
      </SectionRow>
    </WidgetWrapper>
  );
};

export default VoteFor;
