import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';

import { useModal } from '@anchorage/common/dist/components/ModalV2';

import importantProductUpdatesCountQuery from './ImportantProductUpdatesCount.graphql';
import importantProductUpdatesPostsQuery from './ImportantProductUpdatesPosts.graphql';
import type {
  ImportantProductUpdatesCountQuery,
  ImportantProductUpdatesPosts,
  ImportantProductUpdatesPostsQuery,
} from 'generated/graphql';

import ProductUpdatesModal from '../ProductUpdatesModal';

type ImportantPost = ImportantProductUpdatesPosts.items;

const ImportantUpdatesWrapper = () => {
  const apolloClient = useApolloClient();
  const [hasUnreadPosts, setHasUnreadPosts] = useState(false);
  const [importantPosts, setImportantPosts] = useState<ImportantPost[]>([]);
  const [, modalProps] = useModal({ isOpen: true });

  useEffect(() => {
    const getUnreadCount = async () => {
      const { data } =
        await apolloClient.query<ImportantProductUpdatesCountQuery>({
          query: importantProductUpdatesCountQuery,
          fetchPolicy: 'network-only',
        });

      setHasUnreadPosts((data.productUpdates.unreadCount ?? 0) > 0);
    };

    getUnreadCount();
  }, [apolloClient]);

  useEffect(() => {
    const getImportantPosts = async () => {
      if (hasUnreadPosts) {
        const { data } =
          await apolloClient.query<ImportantProductUpdatesPostsQuery>({
            query: importantProductUpdatesPostsQuery,
            fetchPolicy: 'network-only',
          });

        setImportantPosts(data.productUpdates.items ?? []);
      }
    };

    getImportantPosts();
  }, [apolloClient, hasUnreadPosts]);

  if (importantPosts.length < 1) {
    return null;
  } else {
    return <ProductUpdatesModal {...modalProps} posts={importantPosts} />;
  }
};

export default ImportantUpdatesWrapper;
