import { cva } from 'class-variance-authority';

export const switchVariants = cva(
  [
    'focus-visible:ring-ring',
    'focus-visible:ring-offset-background',
    'data-[state=checked]:bg-color-comp-toggle-container-on-default',
    'data-[state=checked]:disabled:bg-color-comp-toggle-container-on-disabled',
    'data-[state=checked]:enabled:hover:bg-color-comp-toggle-container-on-hover',
    'data-[state=unchecked]:bg-color-comp-toggle-container-off-default',
    'data-[state=unchecked]:enabled:hover:bg-color-comp-toggle-container-off-hover',
    'data-[state=unchecked]:disabled:bg-color-comp-toggle-container-off-disabled',
    'group',
    'peer',
    'inline-flex',
    'cursor-pointer',
    'items-center',
    'rounded-full',
    'p-[0.12rem]',
    'transition-colors',
    'focus-visible:outline-none',
    'focus-visible:ring-2',
    'focus-visible:ring-offset-2',
    'disabled:cursor-not-allowed',
  ],
  {
    variants: {
      size: {
        small: 'h-4 w-[1.625rem]',
        large: 'h-6 w-[2.375rem]',
      },
    },
    defaultVariants: {
      size: 'large',
    },
  },
);

export const thumbVariants = cva(
  [
    'bg-color-comp-toggle-on-container',
    'group-disabled:bg-color-comp-toggle-on-container',
    'pointer-events-none',
    'block',
    'rounded-full',
    'transition-transform',
    'group-disabled:opacity-30',
    'data-[state=unchecked]:translate-x-0',
    'drop-shadow-[2px_2px_2px_rgba(244,245,245,0.24)]',
  ],
  {
    variants: {
      size: {
        small: 'size-3 data-[state=checked]:translate-x-[0.65rem]',
        large: 'size-5 data-[state=checked]:translate-x-[0.88rem]',
      },
    },
    defaultVariants: {
      size: 'large',
    },
  },
);

export const switchLabelVariants = cva(
  [
    'ml-spacing-comp-toggle-gap',
    'peer-[:not(:disabled)]:cursor-pointer',
    'text-color-comp-toggle-label-strong',
    'peer-disabled:text-color-comp-toggle-label-disabled',
  ],
  {
    variants: {
      size: {
        small:
          'font-ref-web-caption-regular-font-family font-ref-web-caption-regular-font-weight text-ref-web-caption-regular-font-size font-ref-web-caption-regular-font-weight leading-ref-web-caption-regular-line-height',
        large:
          'font-ref-web-caption-body-font-family font-ref-web-caption-body-font-weight text-ref-web-caption-body-font-size font-ref-web-caption-body-font-weight leading-ref-web-caption-body-line-height',
      },
    },
    defaultVariants: {
      size: 'large',
    },
  },
);
