import { InputV2 } from '@anchorage/common/dist/components';

interface Props {
  validatorAddress: string;
}

const ValidatorAddressReadOnlyInput = ({ validatorAddress }: Props) => {
  return (
    <InputV2
      name="validatorAddress"
      label="Validator address"
      data-testid="staking-validator-input-step"
      value={validatorAddress}
      disabled
    />
  );
};
export default ValidatorAddressReadOnlyInput;
