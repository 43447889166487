import cn from 'classnames';

const CameraIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Camera', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18.8 21L28.33 4.49C26.94 4.18 25.49 4 24 4C19.2 4 14.81 5.69 11.36 8.51L18.69 21.2L18.8 21ZM43.08 18C41.24 12.15 36.78 7.48 31.09 5.32L23.77 18H43.08ZM43.6 20H28.62L29.2 21L38.73 37.5C41.99 33.94 44 29.21 44 24C44 22.63 43.86 21.29 43.6 20ZM17.07 24L9.27 10.5C6.01 14.06 4 18.79 4 24C4 25.37 4.14 26.71 4.4 28H19.38L17.07 24ZM4.92 30C6.76 35.85 11.22 40.52 16.91 42.68L24.23 30H4.92ZM27.46 30L19.66 43.51C21.06 43.82 22.51 44 24 44C28.8 44 33.19 42.31 36.64 39.49L29.31 26.8L27.46 30Z" />
    </svg>
  );
};

export { CameraIcon };
