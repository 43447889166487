import cn from 'classnames';

const ContentCopyIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ContentCopy', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M32 2H8C5.79 2 4 3.79 4 6V34H8V6H32V2ZM38 10H16C13.79 10 12 11.79 12 14V42C12 44.21 13.79 46 16 46H38C40.21 46 42 44.21 42 42V14C42 11.79 40.21 10 38 10ZM38 42H16V14H38V42Z" />
    </svg>
  );
};

export { ContentCopyIcon };
