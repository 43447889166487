// Fetch a session ID from the backend
export const generateSessionId = async () => {
  return window.fetch('/v1/generate_session_id', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  });
};

const SESSION_ID_HEADER = 'X-ANCHORAGE-CLIENT-DASHBOARD-SESSION-ID';

// Check if the user is logged in for the given session
export const claimSession = (sessionID: string) => {
  return window.fetch('/v1/claim_session', {
    method: 'GET',
    headers: {
      [SESSION_ID_HEADER]: sessionID,
    },
    credentials: 'same-origin',
  });
};

// Logout
export const logout = () => {
  return window.fetch('/logout', {
    method: 'GET',
    credentials: 'same-origin',
  });
};
