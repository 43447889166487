import cn from 'classnames';

const ClosedCaptionIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ClosedCaption', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 8H10C7.79 8 6 9.79 6 12V36C6 38.21 7.79 40 10 40H38C40.21 40 42 38.21 42 36V12C42 9.79 40.21 8 38 8ZM22 22H19V21H15V27H19V26H22V28C22 29.1 21.11 30 20 30H14C12.89 30 12 29.1 12 28V20C12 18.9 12.89 18 14 18H20C21.11 18 22 18.9 22 20V22ZM36 22H33V21H29V27H33V26H36V28C36 29.1 35.11 30 34 30H28C26.89 30 26 29.1 26 28V20C26 18.9 26.89 18 28 18H34C35.11 18 36 18.9 36 20V22Z" />
    </svg>
  );
};

export { ClosedCaptionIcon };
