import React from 'react';

import type { AnchorageComponentHook } from '../../types/hooks';

import type { StandaloneInputDefaultProps as InputProps } from './_types';

type InputHookProps = InputProps &
  Pick<
    React.AllHTMLAttributes<HTMLInputElement>,
    'value' | 'placeholder' | 'type'
  >;

const useInput: AnchorageComponentHook<InputHookProps> = (defaultProps) => {
  const { value: defaultValue = '', ...restDefaultProps } = defaultProps;
  const [value, setValue] = React.useState(defaultValue);

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  return [
    {
      value,
      onChange,
      ...restDefaultProps,
    },
    { value, onChange, ...restDefaultProps },
  ];
};

export default useInput;
