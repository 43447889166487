// Utils
import cn from 'classnames';
import _omit from 'lodash/omit';
import React from 'react';

// Components
import { Button, Text } from '../../../components';
import { ExpandIcon, HyperlinkIcon } from '../../../components/Icons';

import { UIDeveloperError, reportError } from '../../../utils/errors';

// Styles
import css from './index.css';

// Types
import type { NFTCardProps } from './_types';

import NFTImage from '../NFTImage';
import ExpandedImageModal from './ExpandedImageModal';

const CondensedNFTCard: React.FC<
  Omit<NFTCardProps, 'isExpandable' | 'isCondensedView'>
> = ({
  title,
  subtext,
  footerText,
  images,
  externalLink,
  className = '',
  ...restProps
}) => {
  const rootClasses = cn({
    [css.condensedRoot]: true,
    [className]: Boolean(className),
  });

  const { className: imageClassName = '', ...imageRestProps } = images[0];

  const imageClasses = cn({
    [css.condensedImage]: true,
    [imageClassName]: Boolean(imageClassName),
  });

  return (
    <div className={rootClasses} {...restProps}>
      <NFTImage
        className={imageClasses}
        fallbackClassName={css.condensedFallbackImg}
        {...imageRestProps}
        alt={images[0].alt}
      />
      <div className={css.condensedDetailsContainer}>
        <div className={css.metadataContainer}>
          <Text className={css.infoText} size="small">
            {title}
          </Text>
          <Text className={css.infoText} size="tiny">
            {subtext}
          </Text>
          {footerText ? (
            <Text className={css.footerText} size="tiny">
              {footerText}
            </Text>
          ) : null}
        </div>
        {externalLink ? (
          <div className={css.iconContainer}>
            <Text<'a'>
              tag="a"
              type="link"
              href={externalLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <HyperlinkIcon className={css.hyperlinkIcon} />
            </Text>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const NFTCard: React.FC<NFTCardProps> = ({
  isCondensedView = false,
  ...restProps
}) => {
  const [isImageExpanded, setIsImageExpanded] = React.useState(false);

  if (isCondensedView) {
    return <CondensedNFTCard {...restProps} />;
  }

  const {
    title,
    subtext,
    footerText,
    images,
    externalLink,
    className = '',
    isImageExpandable = false,
    ...domProps
  } = restProps;

  if (images.length > 1) {
    reportError(
      new UIDeveloperError(
        'More than 1 image passed into the NFT Card component'
      )
    );

    return null;
  }

  const rootClasses = cn({
    [css.infoCardRoot]: true,
    [css.hoverCard]: Boolean(restProps.onClick),
    [className]: Boolean(className),
  });

  const { className: imageClassName = '', ...imageRestProps } = images[0];

  const imageClasses = cn({
    [css.image]: true,
    [imageClassName]: Boolean(imageClassName),
  });

  return (
    <>
      <div
        className={rootClasses}
        {..._omit(domProps, ['addedAt', 'priceInUSD'])}
      >
        <div className={css.imageContainer}>
          <NFTImage
            className={imageClasses}
            fallbackClassName={css.fallBackImg}
            loading="lazy"
            {...imageRestProps}
            alt={images[0].alt}
          />
          {isImageExpandable ? (
            <Button
              className={css.expandButton}
              type="tertiary"
              onClick={() => setIsImageExpanded(true)}
            >
              <ExpandIcon className={css.expandIcon} />
            </Button>
          ) : null}
        </div>
        <div className={css.detailsContainer}>
          <div className={css.metadataContainer}>
            <Text className={css.infoText} size="small">
              {title}
            </Text>
            <Text className={css.infoText} size="tiny">
              {subtext}
            </Text>
            {footerText ? (
              <Text className={css.footerText} size="tiny">
                {footerText}
              </Text>
            ) : null}
          </div>
          {externalLink ? (
            <div className={css.iconContainer}>
              <Text<'a'>
                tag="a"
                type="link"
                href={externalLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <HyperlinkIcon className={css.hyperlinkIcon} />
              </Text>
            </div>
          ) : null}
        </div>
      </div>
      <ExpandedImageModal
        isOpen={isImageExpanded}
        onClose={() => setIsImageExpanded(false)}
        image={images[0]}
      />
    </>
  );
};

export default NFTCard;
