import cn from 'classnames';

const DehazeIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Dehaze', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M4 31V35H44V31H4ZM4 21V25H44V21H4ZM4 11V15H44V11H4Z" />
    </svg>
  );
};

export { DehazeIcon };
