import TextSection from '../TextSection';

type Props = {
  comment?: string | null;
  title?: string;
};
const Comment = ({ comment, title = 'Comment' }: Props) => {
  if (!comment) {
    return null;
  }

  return <TextSection content={comment} title={title} />;
};

export default Comment;
