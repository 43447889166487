import { Text } from '@anchorage/common/dist/components';
import {
  AtlasIcon,
  BalanceIcon,
  BracketsIcon,
  BulletListIcon,
  EmailIcon,
  FileIcon,
  HomeIcon,
  HyperlinkIcon,
  PuzzleIcon,
  ReportsIcon,
  SettingsIcon,
  SwapIcon,
  WidgetIcon,
} from '@anchorage/common/dist/components/Icons';

import {
  ACCESS_MANAGEMENT_PATH,
  ACTIVITY_PATH,
  ADMINISTRATOR_POLICIES_PATH,
  API_V2_PATH,
  CONNECTED_APPS_PATH,
  DASHBOARD_PATH,
  DOCUMENT_DELIVERY_PATH,
  IAM_ROLES_PATH,
  IAM_ROLE_PATH,
  LEGAL_DISCLOSURES_PATH,
  REPORTS_PATH,
  SETTLEMENTS_COUNTERPARTIES_PATH,
  SETTLEMENTS_LIST_PATH,
  SETTLEMENTS_PARTICIPANTS_PATH,
  STATEMENTS_PATH,
  TRADE_PATH,
  TRUSTED_DESTINATIONS_PATH,
  TRUSTED_SOURCES_PATH,
  WALLET_KEYS_LIBRARY_PATH,
  WEBHOOKS_PATH,
} from '../../utils/routes';

import css from './styles.module.scss';

import { MenuItemProps, SubMenuItems, TSubMenuHeaderProps } from './types';

import SettlementNotification from '../SettlementNotification/SettlementNotification';

export enum MenuItemName {
  DASHBOARD = 'DASHBOARD',
  ACTIVITY = 'ACTIVITY',
  STATEMENTS = 'STATEMENTS',
  REPORTS = 'REPORTS',
  TRADE = 'TRADE',
  SETTLEMENTS = 'SETTLEMENTS',
  API = 'API',
  SETTINGS = 'SETTINGS',
  CONNECTED_APPS = 'CONNECTED_APPS',
  LEGAL_DISCLOSURES = 'LEGAL_DISCLOSURES',
  HELP_AND_SUPPORT = 'HELP_AND_SUPPORT',
  DIRECTORY = 'DIRECTORY',
  REPORTING = 'REPORTING',
  DEVELOPER = 'DEVELOPER',
}

export enum SubMenuItemName {
  DOCUMENT_DELIVERY = 'DOCUMENT_DELIVERY',
  TRUSTED_SOURCES = 'TRUSTED_SOURCES',
  TRUSTED_DESTINATIONS = 'TRUSTED_DESTINATIONS',
  ACCESS_MANAGEMENT = 'ACCESS_MANAGEMENT',
  FLEXIBLE_POLICIES = 'FLEXIBLE_POLICIES',
  WALLET_KEYS = 'WALLET_KEYS',
  API_V1 = 'API_V1',
  API_V2 = 'API_V2',
  WEBHOOKS = 'WEBHOOKS',
  STATEMENTS = 'STATEMENTS',
  REPORTS = 'REPORTS',
}

export const SUB_MENU_ITEMS: {
  [x in keyof typeof SubMenuItemName]: SubMenuItems;
} = {
  [SubMenuItemName.DOCUMENT_DELIVERY]: {
    key: 'document-delivery',
    label: 'Document delivery',
    route: DOCUMENT_DELIVERY_PATH,
    testId: 'document-delivery',
  },
  [SubMenuItemName.TRUSTED_SOURCES]: {
    key: 'trusted-sources',
    label: 'Trusted sources',
    route: TRUSTED_SOURCES_PATH,
    testId: 'trusted-sources',
  },
  [SubMenuItemName.TRUSTED_DESTINATIONS]: {
    key: 'trusted-destinations',
    label: 'Trusted destinations',
    route: TRUSTED_DESTINATIONS_PATH,
    testId: 'trusted-destinations-tab',
  },
  [SubMenuItemName.ACCESS_MANAGEMENT]: {
    key: 'access-management',
    label: 'Access management',
    // order matters. primary route is first.
    route: [IAM_ROLES_PATH, IAM_ROLE_PATH],
    testId: 'access-management-tab',
  },
  [SubMenuItemName.FLEXIBLE_POLICIES]: {
    key: 'administrator-tab',
    label: 'Administrator policies',
    route: ADMINISTRATOR_POLICIES_PATH,
    testId: 'administrator-tab',
  },
  [SubMenuItemName.WALLET_KEYS]: {
    key: 'wallet-keys-tab',
    label: 'Encrypted wallet keys',
    route: WALLET_KEYS_LIBRARY_PATH,
    testId: 'wallet-keys-tab',
  },
  [SubMenuItemName.API_V1]: {
    key: 'api-1-tab',
    label: 'API 1.0',
    route: ACCESS_MANAGEMENT_PATH,
    testId: 'api-1-tab',
  },
  [SubMenuItemName.API_V2]: {
    key: 'api-2-tab',
    label: 'API 2.0',
    route: API_V2_PATH,
    testId: 'api-2-tab',
  },
  [SubMenuItemName.WEBHOOKS]: {
    key: 'webhooks-tab',
    label: 'Webhooks',
    route: WEBHOOKS_PATH,
    testId: 'webhooks-tab',
  },
  [SubMenuItemName.STATEMENTS]: {
    key: 'statements-tab',
    label: 'Statements',
    route: STATEMENTS_PATH,
    testId: 'statements-tab',
  },
  [SubMenuItemName.REPORTS]: {
    key: 'reports-tab',
    label: 'Reports',
    route: REPORTS_PATH,
    testId: 'reports-tab',
  },
};

export const MENU_ITEMS: {
  [x in keyof typeof MenuItemName]: MenuItemProps | TSubMenuHeaderProps;
} = {
  [MenuItemName.DASHBOARD]: {
    label: 'Dashboard',
    route: DASHBOARD_PATH,
    Icon: HomeIcon,
    testId: 'dashboard-tab',
  },
  [MenuItemName.ACTIVITY]: {
    label: 'Activity',
    route: ACTIVITY_PATH,
    Icon: BulletListIcon,
    testId: 'activity-tab',
  },
  [MenuItemName.STATEMENTS]: {
    label: 'Statements',
    route: STATEMENTS_PATH,
    Icon: FileIcon,
    testId: 'statements-tab',
  },
  [MenuItemName.REPORTS]: {
    label: 'Reports',
    route: REPORTS_PATH,
    Icon: ReportsIcon,
    testId: 'reports-tab',
  },
  [MenuItemName.TRADE]: {
    label: 'Trade',
    route: TRADE_PATH,
    Icon: SwapIcon,
    testId: 'trade-tab',
  },
  [MenuItemName.SETTLEMENTS]: {
    label: 'Settle',
    Icon: WidgetIcon,
    testId: 'settlements-tab',
    extra: <SettlementNotification />,
    route: SETTLEMENTS_LIST_PATH,
  },
  [MenuItemName.API]: {
    label: 'API',
    Icon: BracketsIcon,
    testId: 'api-tab',
    subMenuItems: [
      SUB_MENU_ITEMS[SubMenuItemName.API_V1],
      SUB_MENU_ITEMS[SubMenuItemName.API_V2],
    ],
  },
  [MenuItemName.DIRECTORY]: {
    label: 'Directory',
    Icon: AtlasIcon,
    testId: 'directory-tab',
    subMenuItems: [
      {
        label: 'Your participants',
        route: SETTLEMENTS_PARTICIPANTS_PATH,
        testId: 'settlements-participant-tab',
      },
      {
        label: 'Trusted counterparties',
        route: SETTLEMENTS_COUNTERPARTIES_PATH,
        testId: 'settlements-counterparties-tab',
      },
    ],
  },
  [MenuItemName.SETTINGS]: {
    Icon: SettingsIcon,
    key: 'settings-tab',
    label: 'Settings',
    testId: 'settings-tab',
  },
  [MenuItemName.CONNECTED_APPS]: {
    Icon: PuzzleIcon,
    key: 'connected-apps-tab',
    label: 'Connected apps',
    route: CONNECTED_APPS_PATH,
    testId: 'connected-apps-tab',
  },
  [MenuItemName.LEGAL_DISCLOSURES]: {
    Icon: BalanceIcon,
    key: 'legal-disclosures-tab',
    label: 'Legal disclosures',
    route: LEGAL_DISCLOSURES_PATH,
    testId: 'legal-disclosures-tab',
  },
  [MenuItemName.HELP_AND_SUPPORT]: {
    Icon: EmailIcon,
    key: 'help-and-support',
    label: 'Help and support',
    testId: 'help-and-support-tab',
    subMenuItems: [
      {
        key: 'user-guide',
        isSelected: false,
        label: '',
        extra: (
          <a
            className={css.userGuideTab}
            href="/v1/docs"
            target="_blank"
            rel="noopener noreferrer"
            data-testid="user-guide-button"
          >
            <Text className={css.label}>User guide</Text>
            <HyperlinkIcon className={css.hyperlinkIcon} />
          </a>
        ),
      },
      {
        key: 'contact-button',
        isSelected: false,
        label: '',
        extra: (
          <a
            href="mailto:accountexecutive@anchorage.com"
            target="_blank"
            rel="noopener noreferrer"
            data-testid="contact-button"
          >
            <Text className={css.label}>Contact us</Text>
          </a>
        ),
      },
    ],
  },
  [MenuItemName.REPORTING]: {
    label: 'Reporting',
    Icon: ReportsIcon,
    testId: 'reporting-tab',
    subMenuItems: [
      SUB_MENU_ITEMS[SubMenuItemName.STATEMENTS],
      SUB_MENU_ITEMS[SubMenuItemName.REPORTS],
    ],
  },
  [MenuItemName.DEVELOPER]: {
    label: 'Developer',
    Icon: BracketsIcon,
    testId: 'developer-tab',
    subMenuItems: [
      SUB_MENU_ITEMS[SubMenuItemName.API_V1],
      SUB_MENU_ITEMS[SubMenuItemName.API_V2],
    ],
  },
};
