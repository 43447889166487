import cn from 'classnames';

const Forward10Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Forward10', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M8 26C8 34.8 15.2 42 24 42C32.8 42 40 34.8 40 26H36C36 32.6 30.6 38 24 38C17.4 38 12 32.6 12 26C12 19.4 17.4 14 24 14V22L34 12L24 2V10C15.2 10 8 17.2 8 26ZM21.7 32H20V25.5L18 26.1V24.7L21.5 23.4H21.7V32ZM30.2 28.5C30.2 29.1 30.1 29.7 30 30.1C29.9 30.5 29.7 30.9 29.4 31.2C29.1 31.5 28.8 31.7 28.5 31.9C28.2 32.1 27.8 32.1 27.3 32.1C26.8 32.1 26.5 32 26.1 31.9C25.7 31.8 25.4 31.5 25.2 31.2C25 30.9 24.7 30.5 24.6 30.1C24.5 29.7 24.4 29.1 24.4 28.5V27C24.4 26.4 24.5 25.8 24.6 25.4C24.7 25 24.9 24.6 25.2 24.3C25.5 24 25.8 23.8 26.1 23.6C26.4 23.4 26.8 23.4 27.3 23.4C27.8 23.4 28.1 23.5 28.5 23.6C28.9 23.7 29.2 24 29.4 24.3C29.6 24.6 29.9 25 30 25.4C30.1 25.8 30.2 26.4 30.2 27V28.5ZM28.5 26.8C28.5 26.4 28.5 26.1 28.4 25.8C28.3 25.5 28.3 25.3 28.2 25.2C28.1 25.1 28 24.9 27.8 24.9C27.6 24.9 27.5 24.8 27.3 24.8C27.1 24.8 26.9 24.8 26.8 24.9C26.7 25 26.5 25.1 26.4 25.2C26.3 25.3 26.2 25.6 26.2 25.8C26.2 26 26.1 26.4 26.1 26.8V28.7C26.1 29.1 26.1 29.4 26.2 29.7C26.3 30 26.3 30.2 26.4 30.3C26.5 30.4 26.6 30.6 26.8 30.6C27 30.6 27.1 30.7 27.3 30.7C27.5 30.7 27.7 30.7 27.8 30.6C27.9 30.5 28.1 30.4 28.2 30.3C28.3 30.2 28.4 29.9 28.4 29.7C28.4 29.5 28.5 29.1 28.5 28.7V26.8Z" />
    </svg>
  );
};

export { Forward10Icon };
