import cn from 'classnames';

const HistoryIcon = (props: SVGProps): JSX.Element => {
  const { title = 'History', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M25.99 6C16.04 6 8 14.06 8 24H2L9.79 31.79L9.93 32.08L18 24H12C12 16.27 18.27 10 26 10C33.73 10 40 16.27 40 24C40 31.73 33.73 38 26 38C22.13 38 18.64 36.42 16.11 33.89L13.28 36.72C16.53 39.98 21.02 42 25.99 42C35.94 42 44 33.94 44 24C44 14.06 35.94 6 25.99 6ZM24 16V26L32.56 31.08L34 28.65L27 24.5V16H24Z" />
    </svg>
  );
};

export { HistoryIcon };
