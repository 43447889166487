export const headerVariants = [
  'flex',
  'min-h-sizing-sys-size-64',
  'p-spacing-sys-default-16',
  'border-b-border-sys-1',
  'border-color-ref-ui-divider-strong',
];

export const titleVariants = [
  'text-color-ref-text-strong',
  'text-ref-web-heading-small-font-size',
  'font-ref-web-heading-small-font-weight',
  'font-ref-web-heading-small-font-family',
  'tracking-font-ref-web-heading-small-letter-spacing',
];

export const subTitleVariants = [
  'text-color-ref-text-muted',
  'text-ref-web-body-regular-font-size',
  'font-ref-web-body-regular-font-weight',
  'font-ref-web-body-regular-font-family',
  'tracking-font-ref-web-body-regular-letter-spacing',
];
