import cn from 'classnames';

const LowPriorityIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LowPriority', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M28 10H44V14H28V10ZM28 21H44V25H28V21ZM28 32H44V36H28V32ZM4 23C4 30.17 9.83 36 17 36H18V40L24 34L18 28V32H17C12.04 32 8 27.96 8 23C8 18.04 12.04 14 17 14H24V10H17C9.83 10 4 15.83 4 23Z" />
    </svg>
  );
};

export { LowPriorityIcon };
