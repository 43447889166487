import { Checkbox } from '@latitude/checkbox';
import { ChangeEvent, HTMLProps, useRef } from 'react';

/**
 * Represents a checkbox item component.
 *
 * @template TData - The type of data associated with the checkbox item.
 * @param {Object} props - The component props.
 * @param {boolean} props.isSelected - The Cell selected state.
 * @param {boolean} props.canSelect - if the cell can be selected.
 * @returns {JSX.Element} The rendered checkbox item component.
 */
export const CheckboxItem = ({
  onChange,
  className,
  isSelected,
  canSelect,
  ...rest
}: {
  isSelected: boolean;
  canSelect: boolean;
} & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLButtonElement>(null);

  const handleCheckedChange = () => {
    onChange?.({
      target: { checked: (ref.current as HTMLInputElement)?.checked },
    } as ChangeEvent<HTMLInputElement>);
  };

  return (
    <div className="flex items-center">
      <Checkbox
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Type 'RefObject<HTMLButtonElement> | LegacyRef<HTMLInputElement>' is not assignable to type 'LegacyRef<HTMLButtonElement> | undefined'.
        ref={ref}
        checked={isSelected}
        disabled={!canSelect}
        onCheckedChange={handleCheckedChange}
        className={className}
        {...rest}
      />
    </div>
  );
};
