import cn from 'classnames';

const AvTimerIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AvTimer', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M22 34C22 35.1 22.9 36 24 36C25.1 36 26 35.1 26 34C26 32.9 25.1 32 24 32C22.9 32 22 32.9 22 34ZM22 6V14H26V10.16C32.78 11.13 38 16.95 38 24C38 31.73 31.73 38 24 38C16.27 38 10 31.73 10 24C10 20.64 11.18 17.57 13.15 15.15L24 26L26.83 23.17L13.23 9.57L13.21 9.61C8.84 12.89 6 18.11 6 24C6 33.94 14.04 42 23.99 42C33.94 42 42 33.94 42 24C42 14.06 33.94 6 23.99 6H22ZM36 24C36 22.9 35.1 22 34 22C32.9 22 32 22.9 32 24C32 25.1 32.9 26 34 26C35.1 26 36 25.1 36 24ZM12 24C12 25.1 12.9 26 14 26C15.1 26 16 25.1 16 24C16 22.9 15.1 22 14 22C12.9 22 12 22.9 12 24Z" />
    </svg>
  );
};

export { AvTimerIcon };
