import cn from 'classnames';

const InvertColorsOffIcon = (props: SVGProps): JSX.Element => {
  const { title = 'InvertColorsOff', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M41.29 41.75L36.58 37.04L24 24.46L16.87 17.33L14.04 14.5L8.54 9.00004L6 11.55L11.56 17.11C6.46 23.39 6.84 32.64 12.68 38.49C15.8 41.61 19.9 43.18 23.99 43.18C27.56 43.18 31.13 41.99 34.05 39.62L39.45 45L42 42.45L41.29 41.75ZM24 39.17C20.79 39.17 17.78 37.92 15.52 35.66C13.25 33.39 12 30.38 12 27.17C12 24.53 12.86 22.03 14.42 19.96L24 29.55V39.17ZM24 10.2V19.36L38.51 33.87C41.24 27.96 40.19 20.73 35.31 15.85L24 4.54004L16.59 11.95L19.42 14.78L24 10.2Z" />
    </svg>
  );
};

export { InvertColorsOffIcon };
