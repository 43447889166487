import { Badge } from '@latitude/badge';
import * as React from 'react';

import { Text } from '@anchorage/common/dist/components';
import { formatDateTime } from '@anchorage/common/dist/utils/dates';
import { normalizeCanonicalOperationPayload } from '@anchorage/vsp';

import useOperationContext from '../../hooks/useOperationContext';

import { operationStatusNote } from '../../utils/operationStatus';

import {
  DepositEventOperation,
  OperationImplementations,
} from 'generated/graphql';

import css from './styles.module.scss';

import SmartContractRiskAssesment from '../SmartContractRiskAssesment';
import ExternalBadge from '../shared/ExternalBadge';
import Status from './Status';

type TimeProps = {
  createdAt: string;
  endTime: string | null;
};

export const getOperationTime = ({ createdAt, endTime }: TimeProps) => {
  if (endTime) {
    return formatDateTime(endTime);
  }
  return `Initiated on ${formatDateTime(createdAt)}`;
};

const Header = () => {
  const { operation } = useOperationContext()!;

  const {
    operationInfo: { displayTitle, displaySubtitle, showExternalBadge },
    operationState,
    createdAt,
    endTime,
    appStatus,
  } = operation!;

  const statusNote = React.useMemo(
    () => operationStatusNote(operation),
    [operation],
  );

  let operationDisplayTitle: React.ReactNode = displayTitle;

  const showGasStationBadge =
    operation.__typename === OperationImplementations.DepositEventOperation &&
    (operation as DepositEventOperation).isFromGasStation;

  const isGenericOrOffchainOperation =
    operation.__typename === OperationImplementations.GenericOperation ||
    operation.__typename === OperationImplementations.OffChainOperation;

  if (isGenericOrOffchainOperation) {
    const { title } = normalizeCanonicalOperationPayload({
      canonicalOperationPayload:
        operation.operationInfo.canonicalOperationPayload ?? '',
    });
    operationDisplayTitle = title;
  }

  return (
    <div data-testid="operation-drawer-header" className={css.root}>
      <Text type="heading" className={css.titleContainer}>
        {operationDisplayTitle}
        {isGenericOrOffchainOperation && showExternalBadge ? (
          <ExternalBadge />
        ) : null}
        {showGasStationBadge ? (
          <Badge variant="primary">Gas station</Badge>
        ) : null}
      </Text>

      <Text className={css.description} size="small">
        {displaySubtitle}
      </Text>

      <div
        data-testid="operation-drawer-metadata"
        className={css.metadataContainer}
      >
        <Status
          operationState={operationState}
          appStatus={appStatus}
          statusNote={statusNote}
        />
        <Text size="small">{getOperationTime({ createdAt, endTime })}</Text>
      </div>

      <SmartContractRiskAssesment operation={operation} />
    </div>
  );
};

export default Header;
