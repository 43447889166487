import css from './styles.module.scss';

import { MenuItemProps, TSubMenuHeaderProps } from '../types';

import Pill from '../../Pill';
import {
  MENU_ITEMS,
  MenuItemName,
  SUB_MENU_ITEMS,
  SubMenuItemName,
} from '../constants';

type MenuItem = MenuItemProps | TSubMenuHeaderProps;

const getDeveloperSubMenuItems = ({
  isShowAPIV1Active,
  isWebhooksPageActive,
}: {
  isShowAPIV1Active?: boolean;
  isWebhooksPageActive?: boolean;
}) => {
  return [
    SUB_MENU_ITEMS[SubMenuItemName.API_V2],
    ...(isShowAPIV1Active ? [SUB_MENU_ITEMS[SubMenuItemName.API_V1]] : []),
    ...(isWebhooksPageActive ? [SUB_MENU_ITEMS[SubMenuItemName.WEBHOOKS]] : []),
  ];
};

const getSettingsSubMenuItems = ({
  isAccessManagementEntryEnabled,
  isFlexiblePoliciesEnabled,
}: {
  isAccessManagementEntryEnabled?: boolean;
  isFlexiblePoliciesEnabled?: boolean;
}) => {
  return [
    SUB_MENU_ITEMS[SubMenuItemName.TRUSTED_SOURCES],
    SUB_MENU_ITEMS[SubMenuItemName.TRUSTED_DESTINATIONS],
    ...(isAccessManagementEntryEnabled
      ? [SUB_MENU_ITEMS[SubMenuItemName.ACCESS_MANAGEMENT]]
      : []),
    ...(isFlexiblePoliciesEnabled
      ? [SUB_MENU_ITEMS[SubMenuItemName.FLEXIBLE_POLICIES]]
      : []),
    SUB_MENU_ITEMS[SubMenuItemName.DOCUMENT_DELIVERY],
  ];
};

const getOldTopMenuItems = ({
  totalPendingAttributions,
  isShowAPIV1Active,
  isWebhooksPageActive,
}: {
  totalPendingAttributions?: number;
  isShowAPIV1Active?: boolean;
  isWebhooksPageActive?: boolean;
}) => {
  const developerMenuSubitems = getDeveloperSubMenuItems({
    isShowAPIV1Active,
    isWebhooksPageActive,
  });

  return [
    MENU_ITEMS[MenuItemName.DASHBOARD],
    {
      ...MENU_ITEMS[MenuItemName.ACTIVITY],
      extra:
        totalPendingAttributions && totalPendingAttributions > 0 ? (
          <Pill className={css.activityPill}>{totalPendingAttributions}</Pill>
        ) : undefined,
    },
    MENU_ITEMS[MenuItemName.STATEMENTS],
    MENU_ITEMS[MenuItemName.REPORTS],
    MENU_ITEMS[MenuItemName.TRADE],
    MENU_ITEMS[MenuItemName.DIRECTORY],
    MENU_ITEMS[MenuItemName.SETTLEMENTS],
    {
      ...MENU_ITEMS[MenuItemName.API],
      subMenuItems: developerMenuSubitems,
    },
  ];
};

export const getTopMenuItems = ({
  totalPendingAttributions,
  isShowAPIV1Active,
  isWebhooksPageActive,
  isAccessManagementEntryEnabled,
  isFlexiblePoliciesEnabled,
  hasConnectedAppsViewPermissionsOrIsAdmin,
  isWebMenuActive,
}: {
  totalPendingAttributions?: number;
  isShowAPIV1Active?: boolean;
  isWebhooksPageActive?: boolean;
  isWebMenuActive?: boolean;
  isAccessManagementEntryEnabled?: boolean;
  isFlexiblePoliciesEnabled?: boolean;
  hasConnectedAppsViewPermissionsOrIsAdmin?: boolean;
}): MenuItem[] => {
  if (!isWebMenuActive) {
    return getOldTopMenuItems({
      totalPendingAttributions,
      isShowAPIV1Active,
      isWebhooksPageActive,
    });
  }

  const developerMenuSubitems = getDeveloperSubMenuItems({
    isShowAPIV1Active,
    isWebhooksPageActive,
  });

  return [
    MENU_ITEMS[MenuItemName.DASHBOARD],
    {
      ...MENU_ITEMS[MenuItemName.ACTIVITY],
      extra:
        totalPendingAttributions && totalPendingAttributions > 0 ? (
          <Pill className={css.activityPill}>{totalPendingAttributions}</Pill>
        ) : undefined,
    },
    MENU_ITEMS[MenuItemName.TRADE],
    MENU_ITEMS[MenuItemName.SETTLEMENTS],
    MENU_ITEMS[MenuItemName.DIRECTORY],
    MENU_ITEMS[MenuItemName.REPORTING],
    {
      ...MENU_ITEMS[MenuItemName.DEVELOPER],
      subMenuItems: developerMenuSubitems,
    },
    {
      ...MENU_ITEMS[MenuItemName.SETTINGS],
      subMenuItems: getSettingsSubMenuItems({
        isAccessManagementEntryEnabled,
        isFlexiblePoliciesEnabled,
      }),
    },
    ...(hasConnectedAppsViewPermissionsOrIsAdmin
      ? [
          {
            ...MENU_ITEMS[MenuItemName.CONNECTED_APPS],
          },
        ]
      : []),
  ];
};

const getOldBottomMenuItems = ({
  isAccessManagementEntryEnabled,
  isFlexiblePoliciesEnabled,
  hasConnectedAppsViewPermissionsOrIsAdmin,
  isWebTopBarActive,
}: {
  isAccessManagementEntryEnabled?: boolean;
  isFlexiblePoliciesEnabled?: boolean;
  hasConnectedAppsViewPermissionsOrIsAdmin?: boolean;
  isWebTopBarActive?: boolean;
}): MenuItem[] => {
  return [
    {
      ...MENU_ITEMS[MenuItemName.SETTINGS],
      subMenuItems: [
        SUB_MENU_ITEMS[SubMenuItemName.DOCUMENT_DELIVERY],
        SUB_MENU_ITEMS[SubMenuItemName.TRUSTED_SOURCES],
        SUB_MENU_ITEMS[SubMenuItemName.TRUSTED_DESTINATIONS],
        ...(isAccessManagementEntryEnabled
          ? [SUB_MENU_ITEMS[SubMenuItemName.ACCESS_MANAGEMENT]]
          : []),
        ...(isFlexiblePoliciesEnabled
          ? [SUB_MENU_ITEMS[SubMenuItemName.FLEXIBLE_POLICIES]]
          : []),
      ],
    },
    ...(hasConnectedAppsViewPermissionsOrIsAdmin
      ? [
          {
            ...MENU_ITEMS[MenuItemName.CONNECTED_APPS],
          },
        ]
      : []),
    ...(!isWebTopBarActive ? [MENU_ITEMS[MenuItemName.LEGAL_DISCLOSURES]] : []),
    ...(!isWebTopBarActive ? [MENU_ITEMS[MenuItemName.HELP_AND_SUPPORT]] : []),
  ];
};

export const getBottomMenuItems = ({
  isAccessManagementEntryEnabled,
  isFlexiblePoliciesEnabled,
  hasConnectedAppsViewPermissionsOrIsAdmin,
  isWebTopBarActive,
  isWebMenuActive,
}: {
  isAccessManagementEntryEnabled?: boolean;
  isFlexiblePoliciesEnabled?: boolean;
  hasConnectedAppsViewPermissionsOrIsAdmin?: boolean;
  isWebTopBarActive?: boolean;
  isWebMenuActive?: boolean;
}): MenuItem[] => {
  if (!isWebMenuActive) {
    return getOldBottomMenuItems({
      isAccessManagementEntryEnabled,
      isFlexiblePoliciesEnabled,
      hasConnectedAppsViewPermissionsOrIsAdmin,
      isWebTopBarActive,
    });
  }

  return [];
};
