import cn from 'classnames';

const BorderOuterIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BorderOuter', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M26 14H22V18H26V14ZM26 22H22V26H26V22ZM34 22H30V26H34V22ZM6 6V42H42V6H6ZM38 38H10V10H38V38ZM26 30H22V34H26V30ZM18 22H14V26H18V22Z" />
    </svg>
  );
};

export { BorderOuterIcon };
