import _differenceBy from 'lodash/differenceBy';
import _intersectionBy from 'lodash/intersectionBy';

import type { Policy, VaultPolicy } from '@anchorage/authorization';

import { UserFieldsFragment } from 'generated/graphql';

/**
 * @param originalUsers The original users for the given vault
 * @param updatedUsers The updated users for the given vault
 * @returns Uses difference and intersection to determine added, removed, unchanged users
 */
export const getMemberChanges = (
  originalUsers: UserFieldsFragment[],
  updatedUsers: UserFieldsFragment[],
) => {
  const addedUsers = _differenceBy<UserFieldsFragment, UserFieldsFragment>(
    updatedUsers,
    originalUsers,
    'publicKeyID',
  );

  const removedUsers = _differenceBy<UserFieldsFragment, UserFieldsFragment>(
    originalUsers,
    updatedUsers,
    'publicKeyID',
  );

  const unchangedUsers = _intersectionBy<
    UserFieldsFragment,
    UserFieldsFragment
  >(updatedUsers, originalUsers, 'publicKeyID');

  return { addedUsers, removedUsers, unchangedUsers };
};

export const getPoliciesMap = (
  originalPolicies: Policy[],
  updatedPolicies: Policy[],
) => {
  const policiesMap: Record<string, { original?: Policy; updated?: Policy }> =
    {};

  originalPolicies.forEach((policy) => {
    policiesMap[policy.displayName] = Object.assign(
      policiesMap[policy.displayName] ?? {},
      {
        original: policy,
      },
    );
  });

  updatedPolicies.forEach((policy) => {
    policiesMap[policy.displayName] = Object.assign(
      policiesMap[policy.displayName] ?? {},
      {
        updated: policy,
      },
    );
  });

  return policiesMap;
};

export const getVaultPoliciesMap = (
  originalPolicies: VaultPolicy[],
  updatedPolicies: VaultPolicy[],
) => {
  const policiesMap: Record<
    string,
    Record<string, { original?: VaultPolicy; updated?: VaultPolicy }>
  > = {};

  originalPolicies.forEach((policy) => {
    const vaultName = policy.vault?.name;

    if (!vaultName) {
      return;
    }

    policiesMap[vaultName] = policiesMap[vaultName] ?? {};
    const policyVault = policiesMap[vaultName];
    if (policyVault) {
      policyVault[policy.displayName] = policyVault[policy.displayName] ?? {};
      Object.assign(policyVault[policy.displayName] ?? {}, {
        original: policy,
      });
    }
  });

  updatedPolicies.forEach((policy) => {
    const vaultName = policy.vault?.name;

    if (!vaultName) {
      return;
    }

    policiesMap[vaultName] = policiesMap[vaultName] ?? {};
    const policyVault = policiesMap[vaultName];
    if (policyVault) {
      policyVault[policy.displayName] = policyVault[policy.displayName] ?? {};
      Object.assign(policyVault[policy.displayName] ?? {}, {
        updated: policy,
      });
    }
  });

  return policiesMap;
};

export const getPolicyDiff = (originalPolicy: Policy) => ({
  quorumMembers: {
    original: originalPolicy.quorumSize ?? 0,
  },
  requiredUsersQuorumSize: {
    original: originalPolicy.requiredUsersQuorumSize ?? 0,
  },
  quorumNecessaryEndorsements: {
    original: originalPolicy.quorumNecessaryEndorsements ?? 0,
  },
  requiredUsersQuorumMembers: {
    original: originalPolicy.requiredUsersQuorumSize ?? 0,
  },
  quorumSize: { original: originalPolicy.quorumSize ?? 0 },
  requiredUsersNecessaryEndorsements: {
    original: originalPolicy.requiredUsersNecessaryEndorsements ?? 0,
  },
});
