import cn from 'classnames';

const BuildIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Build', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M45.4 37.8999L27.1 19.5999C28.9 14.9999 27.9 9.49993 24.2 5.79993C20.2 1.79993 14.2 0.999925 9.39999 3.29993L18.1 11.9999L12 18.0999L3.29999 9.39992C0.999986 14.1999 1.79999 20.1999 5.79999 24.1999C9.49999 27.8999 15 28.8999 19.6 27.0999L37.9 45.3999C38.7 46.1999 40 46.1999 40.7 45.3999L45.4 40.6999C46.2 39.9999 46.2 38.6999 45.4 37.8999Z" />
    </svg>
  );
};

export { BuildIcon };
