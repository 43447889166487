import cn from 'classnames';

const FavoriteBorderIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FavoriteBorder', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M33 6C29.52 6 26.18 7.62 24 10.17C21.82 7.62 18.48 6 15 6C8.83 6 4 10.83 4 17C4 24.55 10.8 30.72 21.1 40.07L24 42.7L26.9 40.07C37.2 30.72 44 24.55 44 17C44 10.83 39.17 6 33 6ZM24.21 37.11L24 37.3L23.79 37.11C14.28 28.48 8 22.78 8 17C8 13.01 11.01 10 15 10C18.08 10 21.08 11.99 22.13 14.72H25.86C26.92 11.99 29.92 10 33 10C36.99 10 40 13.01 40 17C40 22.78 33.72 28.48 24.21 37.11Z" />
    </svg>
  );
};

export { FavoriteBorderIcon };
