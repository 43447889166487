import cn from 'classnames';

const GraphicFinalEqIcon = (props: SVGProps): JSX.Element => {
  const { title = 'GraphicFinalEq', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M14 36H18V12H14V36ZM22 44H26V4H22V44ZM6 28H10V20H6V28ZM30 36H34V12H30V36ZM38 20V28H42V20H38Z" />
    </svg>
  );
};

export { GraphicFinalEqIcon };
