import _isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';

import type { OperationPolicies, VaultPolicy } from '@anchorage/authorization';
import { Text } from '@anchorage/common/dist/components';
import useDeepCompare from '@anchorage/common/dist/hooks/useDeepCompare';
import {
  UIDeveloperError,
  reportError,
} from '@anchorage/common/dist/utils/errors';

import { getPoliciesMap } from './utils';

import css from './styles.module.scss';

import SectionRow from '../../shared/SectionRow';
import WidgetWrapper from '../../shared/WidgetWrapper';

const UpdatedVaultMetadata = ({
  policies,
}: {
  policies: OperationPolicies;
}) => {
  const policiesMap = useMemo(
    () =>
      getPoliciesMap(
        policies.updatedVaultPolicies?.original ?? [],
        policies.updatedVaultPolicies?.updated ?? [],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([policies]),
  );

  // We check if the updates contain a policy that already existed
  // If that is the case, this is the policy that will be used to compute
  // the new approving members and changes
  const policyForMembershipChange = useMemo(
    () =>
      Object.values(policiesMap).find(
        (mapEntry) =>
          !_isEmpty(mapEntry.original?.quorumMembers) &&
          !_isEmpty(mapEntry.updated?.quorumMembers),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([policiesMap]),
  );

  // We also need to pick the first policy as a fallback for when
  // there are just policies being added and/or reset
  const firstPolicy = useMemo(
    () => Object.values(policiesMap)[0],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([policiesMap]),
  );

  const affectedVault =
    (policyForMembershipChange?.original as VaultPolicy)?.vault ??
    (firstPolicy?.updated as VaultPolicy)?.vault ??
    (firstPolicy?.original as VaultPolicy)?.vault;

  if (!affectedVault) {
    reportError(
      new UIDeveloperError(
        `Cannot find an update vault policy in the vault policy operation detail.`,
      ),
    );
    return null;
  }

  const { name, description, account } = affectedVault;

  return (
    <WidgetWrapper className={css.vaultMetadataContainer}>
      <SectionRow title="Vault name">
        <Text size="small">{name}</Text>
      </SectionRow>
      <SectionRow title="Vault description">
        <Text size="small">{description}</Text>
      </SectionRow>
      {account && (
        <SectionRow title="Account">
          <Text size="small">{account.displayName}</Text>
        </SectionRow>
      )}
    </WidgetWrapper>
  );
};

export default UpdatedVaultMetadata;
