import cn from 'classnames';

const LaptopMacIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LaptopMac', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 36C42.21 36 43.98 34.21 43.98 32L44 10C44 7.79 42.21 6 40 6H8C5.79 6 4 7.79 4 10V32C4 34.21 5.79 36 8 36H0C0 38.21 1.79 40 4 40H44C46.21 40 48 38.21 48 36H40ZM8 10H40V32H8V10ZM24 38C22.9 38 22 37.1 22 36C22 34.9 22.9 34 24 34C25.1 34 26 34.9 26 36C26 37.1 25.1 38 24 38Z" />
    </svg>
  );
};

export { LaptopMacIcon };
