import cn from 'classnames';

const BorderAllIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BorderAll', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6 6V42H42V6H6ZM22 38H10V26H22V38ZM22 22H10V10H22V22ZM38 38H26V26H38V38ZM38 22H26V10H38V22Z" />
    </svg>
  );
};

export { BorderAllIcon };
