import cn from 'classnames';
import * as React from 'react';

import { Text } from '@anchorage/common/dist/components';
import {
  CancelIcon,
  CompleteIcon,
} from '@anchorage/common/dist/components/Icons';

import type { EndorsementFields } from 'generated/graphql';

import css from './styles.module.scss';

import AvatarWithText from '../../../shared/AvatarWithText';
import SectionRow from '../../../shared/SectionRow';

enum Section {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  WAITING = 'Waiting',
}

type User = EndorsementFields.user;

type Props = {
  title: string;
  quorums: {
    displayName: string;
    members: User[];
  }[];
};
const EndorsementSection = ({ title, quorums }: Props) => {
  if (quorums.length === 0) {
    return null;
  }

  const icon =
    title === Section.APPROVED ? (
      <CompleteIcon className={cn([css.approvalIcon, css.icon])} />
    ) : (
      <CancelIcon className={cn([css.icon, css.rejectionIcon])} />
    );

  return (
    <SectionRow
      data-testid={`${title}-test-id`}
      className={css.endorsersSection}
      title={`${title}`}
    >
      {quorums.map(({ displayName, members }, idx) => (
        <div key={`${displayName}-${idx}`}>
          <Text className={idx !== 0 ? css.quorumSpacing : undefined}>
            {displayName} ({members.length})
          </Text>
          {members.map((member, idx) => (
            <div
              key={`${displayName}-${member.publicKeyID}-${idx}`}
              className={css.endorsement}
            >
              <AvatarWithText className={css.avatar} title={member.name} />
              {title !== Section.WAITING ? icon : null}
            </div>
          ))}
        </div>
      ))}
    </SectionRow>
  );
};

export default EndorsementSection;
