import cn from 'classnames';

const ExtensionIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Extension', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M41 22H38V14C38 11.79 36.21 10 34 10H26V7C26 4.24 23.76 2 21 2C18.24 2 16 4.24 16 7V10H8C5.79 10 4.02 11.79 4.02 14L4.01 21.6H7C9.98 21.6 12.4 24.02 12.4 27C12.4 29.98 9.98 32.4 7 32.4H4.01L4 40C4 42.21 5.79 44 8 44H15.6V41C15.6 38.02 18.02 35.6 21 35.6C23.98 35.6 26.4 38.02 26.4 41V44H34C36.21 44 38 42.21 38 40V32H41C43.76 32 46 29.76 46 27C46 24.24 43.76 22 41 22Z" />
    </svg>
  );
};

export { ExtensionIcon };
