import { Button } from '@latitude/button';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import { Text } from '@anchorage/common/dist/components';
import {
  Body,
  CloseButton,
  Header,
  Modal,
  Wrapper,
} from '@anchorage/common/dist/components/ModalV2';
import DisclosureText from '@anchorage/frontoffice/components/DisclosureText';

import useStaticData from 'hooks/useStaticDataContext';

const DisclosureModal = ({
  children,
  disclosureID,
  disclosureContent,
  className = '',
}: {
  children?: React.ReactNode;
  disclosureID?: string;
  className?: string;
  disclosureContent?: string;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { disclosures } = useStaticData();

  const disclosure = disclosureID ? disclosures[disclosureID] : null;

  const ModalContent = React.useMemo(() => {
    if (disclosure) {
      const { title: disclosureTitle, markdownContent } = disclosure;
      return (
        <>
          <Header>
            <Text title="disclosure-title" type="heading" size="small">
              <ReactMarkdown className="markdown">
                {disclosureTitle}
              </ReactMarkdown>
            </Text>
            <CloseButton />
          </Header>
          <Body>
            <Text title="disclosure-content" size="small">
              <ReactMarkdown className="markdown">
                {markdownContent}
              </ReactMarkdown>
            </Text>
          </Body>
        </>
      );
    }
    return (
      <>
        <Header>
          <Text title="disclosure-title" type="heading" size="small">
            {children}
          </Text>
          <CloseButton />
        </Header>
        <Body>
          <Text title="disclosure-content" size="small">
            <DisclosureText>{disclosureContent || ''}</DisclosureText>
          </Text>
        </Body>
      </>
    );
  }, [children, disclosure, disclosureContent]);

  if (!disclosure && !disclosureContent) {
    return null;
  }

  return (
    <>
      <Button
        className={className}
        type="ghost"
        onClick={() => setIsOpen(true)}
        data-testid="disclosure-link"
      >
        {children || disclosure?.title}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        allowEscapeClose
        allowBackdropClose
      >
        <Wrapper className="bg-color-ref-ui-surface-container">
          {ModalContent}
        </Wrapper>
      </Modal>
    </>
  );
};

export default DisclosureModal;
