import { Text } from '@anchorage/common/dist/components';

import css from './styles.module.scss';

type Props = { label: string };
const PolicyLabel = ({ label }: Props) => (
  <Text className={css.policyLabel} size="small">
    {label}
  </Text>
);

export default PolicyLabel;
