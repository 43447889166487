import cn from 'classnames';

const Crop169Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Crop169', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 12H10C7.79 12 6 13.79 6 16V32C6 34.21 7.79 36 10 36H38C40.21 36 42 34.21 42 32V16C42 13.79 40.21 12 38 12ZM38 32H10V16H38V32Z" />
    </svg>
  );
};

export { Crop169Icon };
