import cn from 'classnames';

const FiberPinIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FiberPin', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M11 21H15V23H11V21ZM40 8H8C5.78 8 4.02 9.78 4.02 12L4 36C4 38.22 5.78 40 8 40H40C42.22 40 44 38.22 44 36V12C44 9.78 42.22 8 40 8ZM18 23C18 24.7 16.7 26 15 26H11V30H8V18H15C16.7 18 18 19.3 18 21V23ZM25 30H22V18H25V30ZM40 30H37.6L32.5 23V30H30V18H32.5L37.5 25V18H40V30Z" />
    </svg>
  );
};

export { FiberPinIcon };
