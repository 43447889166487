import cn from 'classnames';

const LinkIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Link', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M7.8 24C7.8 20.58 10.58 17.8 14 17.8H22V14H14C8.48 14 4 18.48 4 24C4 29.52 8.48 34 14 34H22V30.2H14C10.58 30.2 7.8 27.42 7.8 24ZM16 26H32V22H16V26ZM34 14H26V17.8H34C37.42 17.8 40.2 20.58 40.2 24C40.2 27.42 37.42 30.2 34 30.2H26V34H34C39.52 34 44 29.52 44 24C44 18.48 39.52 14 34 14Z" />
    </svg>
  );
};

export { LinkIcon };
