import cn from 'classnames';

const AddBoxIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AddBox', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 6H10C7.79 6 6 7.79 6 10V38C6 40.21 7.79 42 10 42H38C40.21 42 42 40.21 42 38V10C42 7.79 40.21 6 38 6ZM34 26H26V34H22V26H14V22H22V14H26V22H34V26Z" />
    </svg>
  );
};

export { AddBoxIcon };
