import cn from 'classnames';

const DataUsageIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DataUsage', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M26 4.09998V10.16C32.78 11.13 38 16.95 38 24C38 25.79 37.65 27.5 37.04 29.07L42.24 32.14C43.35 29.65 44 26.91 44 24C44 13.63 36.11 5.10998 26 4.09998ZM24 38C16.27 38 10 31.73 10 24C10 16.95 15.22 11.13 22 10.16V4.09998C11.88 5.09998 4 13.63 4 24C4 35.05 12.94 44 23.99 44C30.61 44 36.46 40.77 40.1 35.82L34.91 32.76C32.35 35.95 28.42 38 24 38Z" />
    </svg>
  );
};

export { DataUsageIcon };
