import cn from 'classnames';

const BeachAccessIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BeachAccess', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M26.2479 29.11L29.1109 26.248L42.0109 39.138L39.1509 42.002L26.2479 29.11ZM34.8399 17.66L40.5699 11.93C32.6699 4.03003 19.8699 4.02003 11.9599 11.91C19.8299 9.30003 28.5799 11.39 34.8399 17.66ZM11.9099 11.95C4.02994 19.86 4.02994 32.66 11.9299 40.56L17.6599 34.83C11.3899 28.58 9.29994 19.83 11.9099 11.95ZM11.9499 11.92C11.9399 11.93 11.9399 11.93 11.9299 11.93C11.9199 11.94 11.9199 11.94 11.9199 11.95C11.1699 17.97 14.2599 25.72 20.5299 31.98L31.9899 20.52C25.7099 14.26 17.9699 11.16 11.9499 11.92Z" />
    </svg>
  );
};

export { BeachAccessIcon };
