import { ORG_OPERATOR_TYPES } from '@anchorage/frontoffice/constants/app';

import {
  DelegateType,
  DepositAttributionStatus,
  DepositAttributionType,
  DepositAttributionWalletType,
  DepositRewardType,
  OperationAction,
  OperationAction as OperationActionEnums,
  OperationImplementations,
  OperationState,
  RoleAction,
  UserType,
} from 'generated/graphql';

export const READABLE_USER_TYPES = {
  [UserType.ADMIN]: 'Administrator',
  [UserType.BROKERAGE_DESK]: 'Brokerage desk',
  [UserType.MEMBER]: 'Member',
  [UserType.VIEW_ONLY]: 'View only',
  [UserType.NOT_A_USER]: 'Not a user',
  [UserType.REMOVED]: 'Removed',
};

export const ROLE_ACTIONS_TITLES = {
  [RoleAction.READ]: 'Read',
  [RoleAction.CREATE_DEPOSIT_ADDRESS]: 'Create address',
  [RoleAction.TRANSFER]: 'Transfer funds',
  [RoleAction.INTERNAL_TRANSFER]: 'Transfer within Anchorage Digital',
  [RoleAction.TRADE]: 'Trade',
  [RoleAction.HOLD]: 'Hold',
  [RoleAction.INITIATE_WITHDRAWAL]: 'Initiate withdrawal',
  [RoleAction.READ_TRADE]: 'Read trade',
  [RoleAction.SUBACCOUNT]: 'Subaccount',
  [RoleAction.LENDER_READ]: 'Read lending account',
  [RoleAction.FACILITY_ONLY]: 'Read lending facility',
  [RoleAction.ONBOARDING]: 'Onboarding',
  [RoleAction.EXTERNAL_LOAN_WRITE]: 'External loan origination',
  [RoleAction.READ_SUBACCOUNT]: 'Read subaccount',
  [RoleAction.READ_DEPOSIT_ATTRIBUTION]: 'Read deposit attribution',
  [RoleAction.DEPOSIT_ATTRIBUTION]: 'Deposit attribution',
  [RoleAction.PROPOSE_ACCEPT_SETTLEMENTS]: 'Propose and accept settlements',
  [RoleAction.AUTHORIZE_SETTLEMENTS]: 'Authorize settlements',
  [RoleAction.DIEM_PREBURN]: 'Diem preburn',
  [RoleAction.CONFIGURE_WEBHOOKS]: 'Configure Webhooks',
  [RoleAction.STAKE]: 'Initiate Staking and Unstaking',
};

const {
  AUTHORIZE_SPENDER,
  BALANCE_ADJUSTMENT,
  CREATE_VAULT,
  DELEGATE,
  DEPOSIT_EVENT,
  DEPOSIT,
  LOCK,
  OFFCHAIN_OPERATION,
  GENERIC,
  ONE_OFF,
  ORGANIZATION_POLICY_CHANGES,
  ROLE_CHANGE,
  TRANSFER,
  TRUSTED_DESTINATION_ADD,
  TRUSTED_DESTINATION_REMOVE,
  UNLOCK,
  USER_ADD_AND_POLICIES,
  USER_REMOVE_AND_POLICIES,
  USER_REPLACE,
  VAULT_DISABLE,
  VAULT_POLICY_CHANGES,
  VOTE,
  WITHDRAW,
} = OperationActionEnums;

const {
  AddUserAndPoliciesOperation,
  AddTrustedDestinationOperation,
  AuthorizeSpenderOperation,
  BalanceAdjustmentOperation,
  ChangeRoleOperation,
  CreateVaultOperation,
  DelegateOperation,
  DepositEventOperation,
  DepositOperation,
  DisableVaultOperation,
  LockOperation,
  OffChainOperation,
  GenericOperation,
  OneOffOperation,
  RemoveTrustedDestinationOperation,
  RemoveUserAndPoliciesOperation,
  ReplaceUserOperation,
  TransferOperation,
  UnlockOperation,
  UpdateOrgPoliciesOperation,
  UpdateVaultPoliciesOperation,
  VoteOperation,
  WithdrawOperation,
} = OperationImplementations;

//----------------------------------------------------------------------------
// Map of Operation Implementations to Actions for constant lookup
//----------------------------------------------------------------------------
type OperationToActionType = Partial<{
  [key in OperationImplementations]: OperationAction;
}>;

export const OPERATION_TYPE_TO_ACTION_MAP: OperationToActionType = {
  [AddTrustedDestinationOperation]: TRUSTED_DESTINATION_ADD,
  [AddUserAndPoliciesOperation]: USER_ADD_AND_POLICIES,
  [AuthorizeSpenderOperation]: AUTHORIZE_SPENDER,
  [BalanceAdjustmentOperation]: BALANCE_ADJUSTMENT,
  [ChangeRoleOperation]: ROLE_CHANGE,
  [CreateVaultOperation]: CREATE_VAULT,
  [DelegateOperation]: DELEGATE,
  [DepositOperation]: DEPOSIT,
  [DepositEventOperation]: DEPOSIT_EVENT,
  [DisableVaultOperation]: VAULT_DISABLE,
  [LockOperation]: LOCK,
  [OffChainOperation]: OFFCHAIN_OPERATION,
  [GenericOperation]: GENERIC,
  [OneOffOperation]: ONE_OFF,
  [RemoveTrustedDestinationOperation]: TRUSTED_DESTINATION_REMOVE,
  [RemoveUserAndPoliciesOperation]: USER_REMOVE_AND_POLICIES,
  [ReplaceUserOperation]: USER_REPLACE,
  [TransferOperation]: TRANSFER,
  [UnlockOperation]: UNLOCK,
  [UpdateOrgPoliciesOperation]: ORGANIZATION_POLICY_CHANGES,
  [UpdateVaultPoliciesOperation]: VAULT_POLICY_CHANGES,
  [VoteOperation]: VOTE,
  [WithdrawOperation]: WITHDRAW,
};

// Include a operation typename here if there are no examples in the populated
// environment for end-to-end tests
export const OPERATIONS_WITHOUT_EXAMPLES: Array<OperationImplementations> = [];

// -----------------------------------------------------------------------------
// The constants in this section are generated from the map above - please add
// new operation types and actions to the list above instead of modifying the
// variables below
// -----------------------------------------------------------------------------
export const SUPPORTED_OPERATION_IMPLEMENTATIONS: Array<OperationImplementations> =
  Object.keys(OPERATION_TYPE_TO_ACTION_MAP) as Array<OperationImplementations>;

type ActionArray = Array<OperationAction>;

// SUPPORTED_OPERATION_ACTION_ENUMS is an array of all the action enums for the
// operations that we support
export const SUPPORTED_OPERATION_ACTION_ENUMS: ActionArray = Object.values(
  OPERATION_TYPE_TO_ACTION_MAP,
);

// Create a map of operation actions to the operation implementation name for
// constant lookup
export const OPERATION_ACTION_TO_TYPE_MAP: OperationToActionType =
  Object.entries(OPERATION_TYPE_TO_ACTION_MAP).reduce(
    (acc, [implementation, action]) => ({
      ...acc,
      [action]: implementation as OperationImplementations,
    }),
    {},
  );

type DepositAdditionalFilters = {
  filterDepositRewardTypes: DepositRewardType[];
};

type StakingAdditionalFilters = {
  filterDelegateTypes: DelegateType[];
};

export type PossibleAdditionalFilters =
  | DepositAdditionalFilters
  | StakingAdditionalFilters;

export type OperationFilterOption = {
  label: string;
  value: string;
  actions: Array<OperationAction>;
  excludeFor?: ORG_OPERATOR_TYPES[];
  additionalFilters?: PossibleAdditionalFilters;
};

export const PARTICIPATION_ACTIONS = [
  AUTHORIZE_SPENDER,
  DELEGATE,
  LOCK,
  ONE_OFF,
  UNLOCK,
  VOTE,
];

export const OPERATION_PAST_STATES = [
  OperationState.COMPLETE,
  OperationState.FAILURE_COMMITTED,
  OperationState.EXPIRED,
  OperationState.ERRORED,
  OperationState.SIGS_FAILED,
  OperationState.INITIATOR_REJECTED,
  OperationState.RISK_REJECTED,
  OperationState.INITIATOR_EXPIRED,
];

export const OPERATION_PENDING_STATES = [
  OperationState.DEPOSIT_EVENT_DETECTED,
  OperationState.TRANSACTION_REQUEST,
  OperationState.ORGANIZATION_CHANGE_REQUEST,
  OperationState.INITIATOR_REQUIRED,
  OperationState.SIGS_REQUIRED,
  OperationState.RISK_RECON,
  OperationState.ORG_RISK_RECON,
  OperationState.RISK_PENDING,
  OperationState.ADDRESS_REQUEST,
  OperationState.ADDRESS_AVAILABLE,
  OperationState.ORG_CREATION_SIGNING,
  OperationState.TRANSACTION_SIGNING,
  OperationState.ORGANIZATION_SIGNING,
  OperationState.BROADCASTING,
];

// Each of the given options will display with the given label and add the
// associated actions to the filterActions query
export const OPERATION_FILTER_OPTIONS: Array<OperationFilterOption> = [
  {
    label: 'Deposit',
    value: 'DEPOSIT',
    actions: [DEPOSIT_EVENT],
    additionalFilters: {
      filterDepositRewardTypes: [DepositRewardType.NONE],
    },
  },
  {
    label: 'Withdraw',
    value: 'WITHDRAW',
    actions: [WITHDRAW],
  },
  {
    label: 'Transfer',
    value: 'TRANSFER',
    actions: [TRANSFER],
  },
  {
    label: 'Staking',
    value: 'STAKING',
    actions: [DELEGATE],
    additionalFilters: {
      filterDelegateTypes: [
        DelegateType.DELEGATE,
        DelegateType.REDELEGATE,
        DelegateType.INITIALIZE,
        DelegateType.INITIALIZE_STAKE_POOL,
        DelegateType.INITIALIZE_DELEGATION_POOL,
        DelegateType.INITIALIZE_VESTING,
        DelegateType.DELEGATE_STAKE_POOL,
        DelegateType.DELEGATE_DELEGATION_POOL,
      ],
    },
  },
  {
    label: 'Unstaking',
    value: 'UNSTAKING',
    actions: [DELEGATE],
    additionalFilters: {
      filterDelegateTypes: [
        DelegateType.UNDELEGATE,
        DelegateType.UNDELEGATE_STAKE_POOL,
        DelegateType.UNDELEGATE_DELEGATION_POOL,
      ],
    },
  },
  {
    label: 'Staking Rewards',
    value: 'STAKING_REWARDS',
    actions: [DEPOSIT_EVENT],
    additionalFilters: {
      filterDepositRewardTypes: [
        DepositRewardType.STAKING_REWARD,
        DepositRewardType.NON_ANCHORAGE_STAKING_REWARD,
        DepositRewardType.DELEGATION_REWARD,
        DepositRewardType.ANCHORAGE_DELEGATION_REWARD,
      ],
    },
  },
  {
    label: 'Balance adjustment',
    value: 'BALANCE_ADJUSTMENT',
    actions: [BALANCE_ADJUSTMENT],
  },
  {
    label: 'Participate',
    value: 'PARTICIPATE',
    actions: PARTICIPATION_ACTIONS,
  },
  {
    label: 'Generic',
    value: 'GENERIC',
    actions: [GENERIC],
  },
  {
    label: 'Off-chain signing',
    value: 'OFF_CHAIN_SIGNING',
    actions: [OFFCHAIN_OPERATION],
  },
  {
    label: 'Add user',
    value: 'ADD_USER',
    actions: [USER_ADD_AND_POLICIES],
  },
  {
    label: 'Remove user',
    value: 'REMOVE_USER',
    actions: [USER_REMOVE_AND_POLICIES],
  },
  {
    label: 'Replace user',
    value: 'REPLACE_USER',
    actions: [USER_REPLACE],
  },
  {
    label: 'Trusted destinations',
    value: 'TRUSTED_DESTINATIONS',
    actions: [TRUSTED_DESTINATION_ADD, TRUSTED_DESTINATION_REMOVE],
  },
  {
    label: 'Admin policy changes',
    value: 'ADMIN_POLICY_CHANGES',
    actions: [ORGANIZATION_POLICY_CHANGES],
  },
  {
    label: 'Vault policy changes',
    value: 'VAULT_POLICY_CHANGES',
    actions: [CREATE_VAULT, VAULT_DISABLE, VAULT_POLICY_CHANGES],
  },
  {
    label: 'API permission groups',
    value: 'API_PERMISSION_GROUPS',
    actions: [ROLE_CHANGE],
    // Exclude for Porto
    excludeFor: [ORG_OPERATOR_TYPES.SELF],
  },
];

export const OPERATION_FILTER_OPTIONS_ADB = OPERATION_FILTER_OPTIONS.filter(
  (filter) =>
    !filter.excludeFor ||
    !filter.excludeFor.includes(ORG_OPERATOR_TYPES.ANCHORAGE),
);

export const OPERATION_FILTER_OPTIONS_PORTO = OPERATION_FILTER_OPTIONS.filter(
  (filter) =>
    !filter.excludeFor || !filter.excludeFor.includes(ORG_OPERATOR_TYPES.SELF),
);

export const ALL_FILTER_ACTIONS = OPERATION_FILTER_OPTIONS.map(
  ({ actions }) => actions,
).flat();
// This exists for future operation types where we don't
// initially support CSV exports for.
export const CSV_FILTERED_ACTIONS: OperationActionEnums[] = [
  OFFCHAIN_OPERATION,
  GENERIC, // Olny visible for Porto, and Porto does have csv exports for now
];

// Based on the filter options we have available, return all of the actions we
// should query in the downloadable CSV.
export const DOWNLOADABLE_CSV_ACTIONS = Array.from(
  new Set(
    ALL_FILTER_ACTIONS.filter(
      (action) => !CSV_FILTERED_ACTIONS.includes(action),
    ),
  ),
);

// Mapping between deposit attributions status and label
export const DEPOSIT_ATTRIBUTION_TYPE_LABEL = {
  [DepositAttributionType.AUTOMATIC]: 'Automatic',
  [DepositAttributionType.MANUAL_CLIENT]: 'Manual (attributed by the client) ',
  [DepositAttributionType.CLIENT_API]:
    'Manual (attributed by the client via API)',
  [DepositAttributionType.MANUAL_STAFF]: 'Manual (attributed by internal user)',
  [DepositAttributionType.SPAM]: 'Flagged as spam',
  [DepositAttributionType.TRUSTED_SOURCES]: 'In trusted sources',
};

export const getDepositAttributionLabel = ({
  status,
  type,
}: {
  status: DepositAttributionStatus;
  type: DepositAttributionType;
}) => {
  if (status === DepositAttributionStatus.NON_ATTRIBUTABLE) {
    return 'Unknown originator';
  } else if (status === DepositAttributionStatus.INITIATED) {
    return 'Attempting auto attribution';
  } else {
    return DEPOSIT_ATTRIBUTION_TYPE_LABEL[
      type as keyof typeof DEPOSIT_ATTRIBUTION_TYPE_LABEL
    ];
  }
};

export const TRUSTED_SOURCE_WALLET_TYPE_LABEL: {
  [x in DepositAttributionWalletType]?: string;
} = {
  [DepositAttributionWalletType.CUSTODIAL]: 'Custodial',
  [DepositAttributionWalletType.SELF_HOSTED]: 'Self-hosted',
};
