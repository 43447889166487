import cn from 'classnames';

const DialpadIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Dialpad', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 38C21.79 38 20 39.79 20 42C20 44.21 21.79 46 24 46C26.21 46 28 44.21 28 42C28 39.79 26.21 38 24 38ZM12 2C9.79 2 8 3.79 8 6C8 8.21 9.79 10 12 10C14.21 10 16 8.21 16 6C16 3.79 14.21 2 12 2ZM12 14C9.79 14 8 15.79 8 18C8 20.21 9.79 22 12 22C14.21 22 16 20.21 16 18C16 15.79 14.21 14 12 14ZM12 26C9.79 26 8 27.79 8 30C8 32.21 9.79 34 12 34C14.21 34 16 32.21 16 30C16 27.79 14.21 26 12 26ZM36 10C38.21 10 40 8.21 40 6C40 3.79 38.21 2 36 2C33.79 2 32 3.79 32 6C32 8.21 33.79 10 36 10ZM24 26C21.79 26 20 27.79 20 30C20 32.21 21.79 34 24 34C26.21 34 28 32.21 28 30C28 27.79 26.21 26 24 26ZM36 26C33.79 26 32 27.79 32 30C32 32.21 33.79 34 36 34C38.21 34 40 32.21 40 30C40 27.79 38.21 26 36 26ZM36 14C33.79 14 32 15.79 32 18C32 20.21 33.79 22 36 22C38.21 22 40 20.21 40 18C40 15.79 38.21 14 36 14ZM24 14C21.79 14 20 15.79 20 18C20 20.21 21.79 22 24 22C26.21 22 28 20.21 28 18C28 15.79 26.21 14 24 14ZM24 2C21.79 2 20 3.79 20 6C20 8.21 21.79 10 24 10C26.21 10 28 8.21 28 6C28 3.79 26.21 2 24 2Z" />
    </svg>
  );
};

export { DialpadIcon };
