import { READABLE_USER_TYPES } from 'constants/operations';
import Link from 'next/link';
import * as React from 'react';
import { XOR } from 'ts-xor';

import { HyperlinkIcon } from '@anchorage/common/dist/components/Icons';
import { formatDate } from '@anchorage/common/dist/utils/dates';
import { useFeatureFlagsContext } from '@anchorage/feature-flags';
import { FEATURE_FLAGS } from '@anchorage/frontoffice/constants/split';

import type { InitiatorFields, OperationDrawer } from 'generated/graphql';

import css from './styles.module.css';

import AvatarWithText from '../../shared/AvatarWithText';
import SectionRow from '../../shared/SectionRow';
import TitleAndSubtitle from '../../shared/TitleAndSubtitle';
import WidgetWrapper from '../../shared/WidgetWrapper';

type TransferOperation = Extract<
  OperationDrawer.operation,
  { __typename: 'TransferOperation' }
>;

type Props = XOR<
  {
    initiator: InitiatorFields.UserInlineFragment | null;
  },
  {
    initiatorKey: TransferOperation['operationInfo']['initiatorV2'];
  }
>;

const InitiatedBy: React.FC<Props> = ({ initiator, initiatorKey }) => {
  let content = <TitleAndSubtitle title="Anchorage Digital" />;

  const { useIsFeatureActive } = useFeatureFlagsContext();
  const [isPermissionsM3_1Active] = useIsFeatureActive(
    FEATURE_FLAGS.CD_PERMISSIONS_M3_1,
  );

  if (initiator) {
    const { name, userType } = initiator;

    content = (
      <AvatarWithText
        title={name}
        subtitle={isPermissionsM3_1Active ? '' : READABLE_USER_TYPES[userType]}
      />
    );
  }

  if (initiatorKey && initiatorKey.__typename === 'APIKeyV2') {
    const {
      id,
      actorInfo: { displayName },
      keyInfo: { addedAt },
    } = initiatorKey;
    const subtitle = `API Key created on ${formatDate(addedAt)}`;

    const title = (
      <div className={css.titleContainer}>
        <Link target="_blank" href={`/apis/view-key/${id}`}>
          {displayName}
        </Link>
        <HyperlinkIcon className={css.linkIcon} />
      </div>
    );
    content = <TitleAndSubtitle title={title} subtitle={subtitle} />;
  }

  if (initiatorKey && initiatorKey.__typename === 'UserKey') {
    const {
      user: { name },
    } = initiatorKey;
    content = <AvatarWithText title={name} />;
  }

  return (
    <WidgetWrapper>
      <SectionRow title="Initiated by">{content}</SectionRow>
    </WidgetWrapper>
  );
};

export default InitiatedBy;
