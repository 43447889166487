import cn from 'classnames';

const FiberDvrIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FiberDvr', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M35 21H39V23H35V21ZM9 21H13V27H9V21ZM42 6H6C3.79 6 2 7.79 2 10V38C2 40.21 3.79 42 6 42H42C44.21 42 46 40.21 46 38V10C46 7.79 44.21 6 42 6ZM16 27C16 28.7 14.7 30 13 30H6V18H13C14.7 18 16 19.3 16 21V27ZM25.24 30H22.24L18.74 18H21.74L23.74 24.86L25.74 18H28.74L25.24 30ZM42 23C42 24.2 41.2 25.3 40.2 25.8L42 30H39L37.3 26H35V30H32V18H39C40.7 18 42 19.3 42 21V23Z" />
    </svg>
  );
};

export { FiberDvrIcon };
