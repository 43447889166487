import cn from 'classnames';

const AccessibilityIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Accessibility', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 4C26.21 4 28 5.79 28 8C28 10.21 26.21 12 24 12C21.79 12 20 10.21 20 8C20 5.79 21.79 4 24 4ZM42 18H30V44H26V32H22V44H18V18H6V14H42V18Z" />
    </svg>
  );
};

export { AccessibilityIcon };
