import cn from 'classnames';

const FlipToBackIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FlipToBack', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18 14H14V18H18V14ZM18 22H14V26H18V22ZM18 6C15.79 6 14 7.79 14 10H18V6ZM26 30H22V34H26V30ZM38 6V10H42C42 7.79 40.21 6 38 6ZM26 6H22V10H26V6ZM18 34V30H14C14 32.21 15.79 34 18 34ZM38 26H42V22H38V26ZM38 18H42V14H38V18ZM38 34C40.21 34 42 32.21 42 30H38V34ZM10 14H6V38C6 40.21 7.79 42 10 42H34V38H10V14ZM30 10H34V6H30V10ZM30 34H34V30H30V34Z" />
    </svg>
  );
};

export { FlipToBackIcon };
