import cn from 'classnames';
import * as React from 'react';

import css from './styles.module.scss';

type Props = React.ComponentPropsWithoutRef<'div'>;

const WidgetWrapper: React.FC<Props> = ({ className = '', ...restProps }) => {
  return <div className={cn([css.root, className])} {...restProps} />;
};
export default WidgetWrapper;
