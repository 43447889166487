import cn from 'classnames';

const InsertDriveFileIcon = (props: SVGProps): JSX.Element => {
  const { title = 'InsertDriveFile', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M12 4C9.79 4 8.02 5.79 8.02 8L8 40C8 42.21 9.77 44 11.98 44H36C38.21 44 40 42.21 40 40V16L28 4H12ZM26 18V7L37 18H26Z" />
    </svg>
  );
};

export { InsertDriveFileIcon };
