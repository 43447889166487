import cn from 'classnames';

const GrainIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Grain', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M20 24C17.79 24 16 25.79 16 28C16 30.21 17.79 32 20 32C22.21 32 24 30.21 24 28C24 25.79 22.21 24 20 24ZM12 16C9.79 16 8 17.79 8 20C8 22.21 9.79 24 12 24C14.21 24 16 22.21 16 20C16 17.79 14.21 16 12 16ZM12 32C9.79 32 8 33.79 8 36C8 38.21 9.79 40 12 40C14.21 40 16 38.21 16 36C16 33.79 14.21 32 12 32ZM36 16C38.21 16 40 14.21 40 12C40 9.79 38.21 8 36 8C33.79 8 32 9.79 32 12C32 14.21 33.79 16 36 16ZM28 32C25.79 32 24 33.79 24 36C24 38.21 25.79 40 28 40C30.21 40 32 38.21 32 36C32 33.79 30.21 32 28 32ZM36 24C33.79 24 32 25.79 32 28C32 30.21 33.79 32 36 32C38.21 32 40 30.21 40 28C40 25.79 38.21 24 36 24ZM28 16C25.79 16 24 17.79 24 20C24 22.21 25.79 24 28 24C30.21 24 32 22.21 32 20C32 17.79 30.21 16 28 16ZM20 8C17.79 8 16 9.79 16 12C16 14.21 17.79 16 20 16C22.21 16 24 14.21 24 12C24 9.79 22.21 8 20 8Z" />
    </svg>
  );
};

export { GrainIcon };
