import cn from 'classnames';

const DirectionsIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Directions', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M43.41 22.59L25.41 4.59C24.63 3.81 23.36 3.81 22.59 4.59L4.59 22.59C3.81 23.37 3.81 24.64 4.59 25.42L22.59 43.41V43.42C23.37 44.2 24.64 44.2 25.42 43.42L43.42 25.42C44.2 24.63 44.2 23.37 43.41 22.59ZM28 29V24H20V30H16V22C16 20.89 16.89 20 18 20H28V15L35 22L28 29Z" />
    </svg>
  );
};

export { DirectionsIcon };
