import cn from 'classnames';

const CameraRollIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CameraRoll', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M28 10C28 7.79 26.21 6 24 6H22V4C22 2.9 21.1 2 20 2H12C10.9 2 10 2.9 10 4V6H8C5.79 6 4 7.79 4 10V40C4 42.21 5.79 44 8 44H24C26.21 44 28 42.21 28 40H44V10H28ZM24 36H20V32H24V36ZM24 18H20V14H24V18ZM32 36H28V32H32V36ZM32 18H28V14H32V18ZM40 36H36V32H40V36ZM40 18H36V14H40V18Z" />
    </svg>
  );
};

export { CameraRollIcon };
