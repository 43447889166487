import cn from 'classnames';

const GpsNotFixedIcon = (props: SVGProps): JSX.Element => {
  const { title = 'GpsNotFixed', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M41.88 22C40.96 13.66 34.34 7.04 26 6.12V2H22V6.12C13.66 7.04 7.04 13.66 6.12 22H2V26H6.12C7.04 34.34 13.66 40.96 22 41.88V46H26V41.88C34.34 40.96 40.96 34.34 41.88 26H46V22H41.88ZM24 38C16.27 38 10 31.73 10 24C10 16.27 16.27 10 24 10C31.73 10 38 16.27 38 24C38 31.73 31.73 38 24 38Z" />
    </svg>
  );
};

export { GpsNotFixedIcon };
