import cn from 'classnames';

const FilterListIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FilterList', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M20 36H28V32H20V36ZM6 12V16H42V12H6ZM12 26H36V22H12V26Z" />
    </svg>
  );
};

export { FilterListIcon };
