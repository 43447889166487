import cn from 'classnames';

const DevicesOtherIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DevicesOther', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6 12H42V8H6C3.8 8 2 9.8 2 12V36C2 38.2 3.8 40 6 40H14V36H6V12ZM26 24H18V27.56C16.78 28.66 16 30.23 16 32C16 33.77 16.78 35.34 18 36.44V40H26V36.44C27.22 35.34 28 33.77 28 32C28 30.23 27.22 28.66 26 27.56V24ZM22 35C20.34 35 19 33.66 19 32C19 30.34 20.34 29 22 29C23.66 29 25 30.34 25 32C25 33.66 23.66 35 22 35ZM44 16H32C31 16 30 17 30 18V38C30 39 31 40 32 40H44C45 40 46 39 46 38V18C46 17 45 16 44 16ZM42 36H34V20H42V36Z" />
    </svg>
  );
};

export { DevicesOtherIcon };
