import { cva } from 'class-variance-authority';

// the follwing rule is disabled because the arbitrary value is used to set the max-width
// on the medium size variant as max-w-[640px], but corresponds to some oficial tailwind class.
// we want to use our own sizes and not tailwind classes that can change values without notice.
//
// eslint-disable-next-line tailwindcss/no-unnecessary-arbitrary-value
export const modalVariants = cva(
  [
    'flex',
    'flex-col',
    'data-[state=open]:animate-contentShow',
    'bg-color-ref-ui-surface-container',
    'text-color-ref-text-strong',
    'fixed',
    'left-1/2',
    'top-1/2',
    'max-h-[85vh]',
    'w-full',
    '-translate-x-1/2',
    '-translate-y-1/2',
    'rounded-[--spacingSysXsmall8]',
    'shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]',
    'pb-spacing-sys-default-16',
  ],
  {
    variants: {
      size: {
        small: 'max-w-[512px]',
        medium: 'max-w-[640px]',
        large: 'max-w-[860px]',
      },
    },
    defaultVariants: {
      size: 'medium',
    },
  },
);
