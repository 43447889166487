import { useRouter } from 'next/router';

import {
  ACCESS_MANAGEMENT_PATH,
  ACTIVITY_PATH,
  API_V2_PATH,
  CONNECTED_APPS_PATH,
  DASHBOARD_PATH,
  DEFAULT_PATH,
  LEGAL_DISCLOSURES_PATH,
  REPORTS_PATH,
  SETTINGS_PATH,
  SETTLEMENTS_COUNTERPARTIES_PATH,
  SETTLEMENTS_PARTICIPANTS_PATH,
  SETTLEMENTS_PATH,
  STATEMENTS_PATH,
  TRADE_PATH,
  VAULT_PATH,
  WEBHOOKS_PATH,
} from '@anchorage/frontoffice/utils/routes';

type Route = string;
enum PageName {
  DASHBOARD = 'Dashboard',
  TRADE = 'Trade',
  ACTIVITY = 'Activity',
  SETTLE = 'Settle',
  REPORTING = 'Reporting',
  DIRECTORY = 'Directory',
  DEVELOPER = 'Developer',
  SETTINGS = 'Settings',
  CONNECTED_APPS = 'Connected apps',
  LEGAL_DISCLOSURES = 'Legal disclosures',
}

export const pageNamesByRoutesList: [Route, PageName][] = [
  [DASHBOARD_PATH, PageName.DASHBOARD],
  [VAULT_PATH, PageName.DASHBOARD],
  [TRADE_PATH, PageName.TRADE],
  [ACTIVITY_PATH, PageName.ACTIVITY],
  [SETTLEMENTS_PATH, PageName.SETTLE],
  [STATEMENTS_PATH, PageName.REPORTING],
  [REPORTS_PATH, PageName.REPORTING],
  [SETTLEMENTS_PARTICIPANTS_PATH, PageName.DIRECTORY],
  [SETTLEMENTS_COUNTERPARTIES_PATH, PageName.DIRECTORY],
  [API_V2_PATH, PageName.DEVELOPER],
  [WEBHOOKS_PATH, PageName.DEVELOPER],
  [ACCESS_MANAGEMENT_PATH, PageName.DEVELOPER],
  [SETTINGS_PATH, PageName.SETTINGS],
  [CONNECTED_APPS_PATH, PageName.CONNECTED_APPS],
  [LEGAL_DISCLOSURES_PATH, PageName.LEGAL_DISCLOSURES],
];

export const usePageName = () => {
  const { asPath } = useRouter();

  if (asPath === DEFAULT_PATH) {
    return '';
  }

  const [page] = pageNamesByRoutesList
    // This validates that the path of the route starts with the path map above
    .filter(([route]) => asPath.startsWith(route))
    // This sorts the list by the most exact route matched
    .sort(([routeA], [routeB]) => routeB.length - routeA.length);

  if (!page) {
    // This will serve to notify the developer that the page created does not have a page name
    // and should take action to define it. This error will only appear in the dev server
    // in our environments the users will not see it.
    // eslint-disable-next-line turbo/no-undeclared-env-vars
    return process.env.NODE_ENV === 'development'
      ? 'Top Bar: the page name is not correctly defined for the page'
      : '';
  }

  const [_, pageName] = page;

  return pageName;
};
