import cn from 'classnames';

const Edit1Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Edit1', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44ZM15 32V28.2495L26.0665 17.1829L29.8171 20.9335L18.7505 32H15ZM32.7075 16.6279C33.0975 17.0179 33.0975 17.653 32.7075 18.0431L30.8772 19.8733L27.1267 16.1228L28.9569 14.2925C29.347 13.9025 29.9821 13.9025 30.3721 14.2925L32.7075 16.6279Z"
      />
    </svg>
  );
};

export { Edit1Icon };
