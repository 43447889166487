import cn from 'classnames';

const Crop54Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Crop54', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 10H10C7.79 10 6 11.79 6 14V34C6 36.21 7.79 38 10 38H38C40.21 38 42 36.21 42 34V14C42 11.79 40.21 10 38 10ZM38 34H10V14H38V34Z" />
    </svg>
  );
};

export { Crop54Icon };
