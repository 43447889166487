import cn from 'classnames';

const Brightness3Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Brightness3', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18 4C15.91 4 13.89 4.32 12 4.92C20.11 7.47 26 15.05 26 24C26 32.95 20.11 40.53 12 43.08C13.89 43.67 15.91 44 18 44C29.05 44 38 35.05 38 24C38 12.95 29.05 4 18 4Z" />
    </svg>
  );
};

export { Brightness3Icon };
