import cn from 'classnames';
import Link from 'next/link';
import React from 'react';

import { Text } from '@anchorage/common/dist/components';
import {
  CancelIcon,
  CompleteIcon,
  HyperlinkIcon,
} from '@anchorage/common/dist/components/Icons';

import css from './styles.module.scss';

import { APIEndorsementSectionProps } from './types';

import SectionRow from '../../../shared/SectionRow';
import WidgetWrapper from '../../../shared/WidgetWrapper';

const APIEndorsementSection: React.FC<APIEndorsementSectionProps> = ({
  accepted,
  rejected,
}) => {
  if (accepted.length === 0 && rejected.length === 0) {
    return null;
  }

  return (
    <WidgetWrapper>
      {accepted.length > 0 ? (
        <SectionRow
          title="Approved by API key"
          className={cn({ [css.sectionBorderBottom!]: rejected.length > 0 })}
        >
          <div className={css.section}>
            {accepted.map((endorsement, index) => (
              <div
                key={`${endorsement.id}-${index}`}
                className={cn({
                  [css.endorsementRow!]: true,
                  [css.borderBottom!]: index < accepted.length - 1,
                })}
              >
                <Text className={css.textContainer}>
                  <Link
                    target="_blank"
                    href={`/apis/view-key/${endorsement.id}`}
                  >
                    {endorsement.actorInfo.displayName}
                  </Link>
                  <HyperlinkIcon className={css.linkIcon} />
                </Text>
                <CompleteIcon className={css.approvalIcon} />
              </div>
            ))}
          </div>
        </SectionRow>
      ) : null}
      {rejected.length > 0 ? (
        <SectionRow title="Rejected by API key">
          <div className={css.section}>
            {rejected.map((endorsement, index) => (
              <div
                key={`${endorsement.id}-${index}`}
                className={cn({
                  [css.endorsementRow!]: true,
                  [css.borderBottom!]: index < rejected.length - 1,
                })}
              >
                <Text className={css.textContainer}>
                  <Link
                    target="_blank"
                    href={`/apis/view-key/${endorsement.id}`}
                  >
                    {endorsement.actorInfo.displayName}
                  </Link>
                  <HyperlinkIcon className={css.linkIcon} />
                </Text>
                <CancelIcon className={css.rejectionIcon} />
              </div>
            ))}
          </div>
        </SectionRow>
      ) : null}
    </WidgetWrapper>
  );
};

export default APIEndorsementSection;
