import cn from 'classnames';

const DiscFullIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DiscFull', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 32H44V28H40V32ZM40 14V24H44V14H40ZM20 8C11.16 8 4 15.16 4 24C4 32.84 11.16 40 20 40C28.84 40 36 32.84 36 24C36 15.16 28.84 8 20 8ZM20 28C17.79 28 16 26.21 16 24C16 21.79 17.79 20 20 20C22.21 20 24 21.79 24 24C24 26.21 22.21 28 20 28Z" />
    </svg>
  );
};

export { DiscFullIcon };
