import { Text } from '@anchorage/common/dist/components';

import css from './styles.module.css';

import SectionRow from '../../shared/SectionRow';
import WidgetWrapper from '../../shared/WidgetWrapper';

type Props = {
  abbreviation: string;
  assetName: string;
};

export const AssetInfo = ({ abbreviation, assetName }: Props) => {
  return (
    <WidgetWrapper>
      <SectionRow title="Asset">
        <Text type="body" size="small">
          {abbreviation}
        </Text>
        <Text type="body" size="small" className={css.assetName}>
          {assetName}
        </Text>
      </SectionRow>
    </WidgetWrapper>
  );
};
