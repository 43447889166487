import cn from 'classnames';
import * as React from 'react';

import { Button, Text } from '@anchorage/common/dist/components';
import { HyperlinkIcon } from '@anchorage/common/dist/components/Icons';
import Tooltip from '@anchorage/common/dist/components/Tooltip';
import CopyToClipboard from '@anchorage/frontoffice/components/CopyToClipboard';

import { truncateAddress } from '../../../utils/mapAddressToWallets';

import css from './styles.module.scss';

import SectionRow from '../../shared/SectionRow';
import WidgetWrapper from '../../shared/WidgetWrapper';

type Props = {
  transactionLink?: string | null;
  transactionID: string | null;
};

const Transaction: React.FC<Props> = ({ transactionLink, transactionID }) => {
  const [isHovering, setIsHovering] = React.useState(false);
  const transactionIDClasses = cn({
    [css.transactionSection!]: true,
    [css.hovering!]: isHovering,
  });

  const toggleHover = () => {
    setIsHovering((isHovering) => !isHovering);
  };

  return transactionID ? (
    <WidgetWrapper>
      <SectionRow
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        title="Transaction ID"
      >
        <div className={transactionIDClasses}>
          <CopyToClipboard
            successText={`Address "${truncateAddress(transactionID)}" copied`}
            textToCopy={transactionID}
          >
            <Text className={css.addressText} size="small" type="mono">
              {transactionID}
            </Text>
          </CopyToClipboard>
          <div className={css.buttons}>
            <CopyToClipboard
              successText={`Address "${truncateAddress(transactionID)}" copied`}
              tooltipPosition="bottomLeft"
              textToCopy={transactionID}
            />
            {transactionLink ? (
              <Tooltip
                background="light"
                position="bottomLeft"
                title="View on blockchain explorer"
              >
                <Text<'a'>
                  type="link"
                  tag="a"
                  href={transactionLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                    e.currentTarget.blur();
                  }}
                >
                  <Button type="tertiary" Icon={HyperlinkIcon} />
                </Text>
              </Tooltip>
            ) : null}
          </div>
        </div>
      </SectionRow>
    </WidgetWrapper>
  ) : null;
};

export default Transaction;
