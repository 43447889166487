import cn from 'classnames';

const FlareIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Flare', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M14 22H2V26H14V22ZM18.34 15.51L14.1 11.27L11.27 14.1L15.51 18.34L18.34 15.51ZM26 2H22V14H26V2ZM36.73 14.1L33.9 11.27L29.66 15.51L32.49 18.34L36.73 14.1ZM34 22V26H46V22H34ZM24 18C20.69 18 18 20.69 18 24C18 27.31 20.69 30 24 30C27.31 30 30 27.31 30 24C30 20.69 27.31 18 24 18ZM29.66 32.49L33.9 36.73L36.73 33.9L32.49 29.66L29.66 32.49ZM11.27 33.9L14.1 36.73L18.34 32.49L15.51 29.66L11.27 33.9ZM22 46H26V34H22V46Z" />
    </svg>
  );
};

export { FlareIcon };
