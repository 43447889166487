import cn from 'classnames';

const FlipIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Flip', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M30 42H34V38H30V42ZM38 18H42V14H38V18ZM6 10V38C6 40.21 7.79 42 10 42H18V38H10V10H18V6H10C7.79 6 6 7.79 6 10ZM38 6V10H42C42 7.79 40.21 6 38 6ZM22 46H26V2H22V46ZM38 34H42V30H38V34ZM30 10H34V6H30V10ZM38 26H42V22H38V26ZM38 42C40.21 42 42 40.21 42 38H38V42Z" />
    </svg>
  );
};

export { FlipIcon };
