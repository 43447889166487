import type {
  PolicyFragmentBinding,
  PolicySourceOfTruth,
} from '../generated/graphql';

import type { BareVault, BareVaultPolicy } from '../types';

import { BasePolicy } from './BasePolicy';

export class VaultPolicy extends BasePolicy {
  constructor(
    sourceOfTruth: PolicySourceOfTruth,
    rawPolicy: BareVaultPolicy | PolicyFragmentBinding,
    readonly vault?: BareVault,
  ) {
    super(sourceOfTruth, (rawPolicy as BareVaultPolicy)?.policy || rawPolicy);
  }
}
