import cn from 'classnames';
import { type ComponentProps } from 'react';

import { Text } from '@anchorage/common/dist/components';

import SeeMoreCollapse from 'components/shared/SeeMoreCollapse';

import css from './styles.module.scss';

import { User } from './types';

import UpdatedUsersRowTitle from './UpdatedUsersRowTitle';

const VISIBLE_USERS_ON_BASE_QUORUM = 5;

const UpdatedUsersRow = ({
  users,
  title,
  type,
}: {
  users: User[];
  title: string;
  type: ComponentProps<typeof UpdatedUsersRowTitle>['type'];
}) => {
  const styles = cn({
    [css.addText!]: type === 'add',
    [css.removeText!]: type === 'remove',
    [css.unchangedText!]: type === 'unchanged',
    [css.rowWrapper!]: true,
  });
  const firstArray = [...users].splice(0, VISIBLE_USERS_ON_BASE_QUORUM);
  const collapsedItems = [...users].splice(VISIBLE_USERS_ON_BASE_QUORUM);

  const getUserContent = (user: User, idx: number) => {
    return (
      <Text size="small" key={idx} className={css.user} data-testid="user">
        {user.name}
      </Text>
    );
  };

  return (
    <>
      <div className={styles}>
        <UpdatedUsersRowTitle title={title} type={type} />
      </div>
      <div className={styles}>
        {firstArray.map(getUserContent)}
        {!!collapsedItems.length && (
          <SeeMoreCollapse
            visibleItems={VISIBLE_USERS_ON_BASE_QUORUM}
            totalItems={users.length}
            collapsible={collapsedItems.length > 0}
          >
            {collapsedItems.map(getUserContent)}
          </SeeMoreCollapse>
        )}
      </div>
    </>
  );
};

export default UpdatedUsersRow;
