import React from 'react';
// Components
import { components } from 'react-select-v5';
// Types & constants
import type { ValueContainerProps } from 'react-select-v5';

// Styles
import css from '../index.css';

import type { NFTSortOption } from '../_types';

import { SORT_ICON_MAPPING } from '../_constants';

const SelectValueContainer: React.FC<
  ValueContainerProps<NFTSortOption, false>
> = ({ children, getValue, ...props }) => {
  const Icon = getValue()[0]
    ? SORT_ICON_MAPPING[getValue()[0].value]
    : () => <></>;

  return (
    <components.ValueContainer
      className={css.valueContainer}
      getValue={getValue}
      {...props}
    >
      <Icon className={css.selectedSortIcon} />
      {children}
    </components.ValueContainer>
  );
};

export default SelectValueContainer;
