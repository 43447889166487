import css from './styles.module.scss';

type Props = {
  className?: string;
  children: string;
  testId?: string;
};

// Disclosure text can often contain HTML markup with links, so we need to use
// dangerouslySetInnerHTML.
//
// NOTE: DO NOT USE THIS COMPONENT WITH USER-SUPPLIED INPUT.
// This component should only be used with sanitized inputs (like those in our
// disclosures that we control).
const DisclosureText = ({
  className = '',
  children,
  testId = 'disclosure-text',
}: Props) => {
  const innerHTML = { __html: children };
  const classes = className ? `${className} ${css.disclosure}` : css.disclosure;

  return (
    <div
      className={classes}
      data-testid={testId}
      dangerouslySetInnerHTML={innerHTML}
    />
  );
};

export default DisclosureText;
