import React from 'react';
// Components
import { Controller } from 'react-hook-form';

// Styles
import css from './styles.css';

import type { FieldValues, FormOptions } from '../../_types';

import Switch from '../../../Switch';
// Types
import type { Props } from '../../../Switch/component';
import Text from '../../../Text';

export interface FormSwitchProps<T extends FieldValues = FieldValues>
  extends Omit<Props, 'onBlur' | 'onChange' | 'children' | 'checked'>,
    FormOptions<T> {
  label?: string;
}

const FormSwitch = <T extends FieldValues = FieldValues>({
  name,
  control,
  label = '',
  defaultValue,
  rules,
  ...switchProps
}: FormSwitchProps<T>) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
      <>
        <Switch
          {...switchProps}
          checked={Boolean(value)}
          onBlur={onBlur}
          onChange={onChange}
        >
          {label}
        </Switch>
        {error && (
          <Text size="small" className={css.error}>
            {error.message}
          </Text>
        )}
      </>
    )}
  />
);

export default FormSwitch;
