import { AlertBanner } from '@latitude/alert-banner';
import InAppSurveyProvider from 'context/InAppSurvey';
import { StaticDataProvider } from 'context/StaticData';
import NextAdapterApp from 'next-query-params/app';
import { useRouter } from 'next/router';
import { FC, PropsWithChildren } from 'react';
import { QueryParamProvider } from 'use-query-params';

import { useAppUser } from '@anchorage/frontoffice/contexts/AppUser';
import FeatureFlagsProvider, {
  FeatureFlagsBEProvider,
} from '@anchorage/frontoffice/contexts/featureFlags';
import usePortoTheme from '@anchorage/frontoffice/utils/usePortoTheme';

import AppLayout from 'components/AppLayout';
import DeviceChecker from 'components/layouts/App/DeviceChecker';

import {
  Organization,
  PolicySourceOfTruth,
  useStaticDataFOQuery,
} from 'generated/graphql';

import css from '../../../pages/styles.module.css';

import AnalyticsWrapper from './AnalyticsWrapper';
import AppAnalytics from './AppAnalytics';
import LoadingPage from './LoadingPage';

const SideNavLayout: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();

  const {
    isLoading,
    error,
    currentUser,
    isPortoOrg,
    organization,
    enabledFeatures,
  } = useAppUser() || {};

  const { data: staticData, refetch } = useStaticDataFOQuery();

  usePortoTheme({
    active: isPortoOrg,
  });

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error) {
    return (
      <div className="m-spacing-sys-xx-large-40">
        <AlertBanner
          variant="error"
          title="Something happened loading the page"
          description="It was not possible to load the page. Please reload the page and try again."
          buttonLabel="Reload"
          onActionClick={() => {
            router.reload();
          }}
        />
      </div>
    );
  }

  if (!currentUser) {
    return <></>;
  }

  return (
    <FeatureFlagsProvider
      organizationKeyID={organization.organizationKeyID}
      email={currentUser.email}
      policySourceOfTruth={
        organization.policyInfo?.sourceOfTruth ??
        PolicySourceOfTruth.POLICY_SOT_CONTAINER
      }
    >
      <FeatureFlagsBEProvider features={enabledFeatures || []}>
        <QueryParamProvider adapter={NextAdapterApp}>
          <StaticDataProvider data={staticData} refetch={refetch}>
            <DeviceChecker>
              <div id="anchorage" className={css.app}>
                {currentUser && organization ? (
                  <AnalyticsWrapper
                    user={currentUser}
                    organization={organization as Partial<Organization>}
                  >
                    <InAppSurveyProvider>
                      <AppLayout>
                        <AppAnalytics />
                        {children}
                      </AppLayout>
                    </InAppSurveyProvider>
                  </AnalyticsWrapper>
                ) : (
                  <AppLayout>{children}</AppLayout>
                )}
                <div id="drawer-root" />
                <div id="modal-root" />
              </div>
            </DeviceChecker>
          </StaticDataProvider>
        </QueryParamProvider>
      </FeatureFlagsBEProvider>
    </FeatureFlagsProvider>
  );
};

export default SideNavLayout;
