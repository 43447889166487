import cn from 'classnames';

const FormatUnderlinedIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatUnderlined', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 34C30.63 34 36 28.63 36 22V6H31V22C31 25.87 27.87 29 24 29C20.13 29 17 25.87 17 22V6H12V22C12 28.63 17.37 34 24 34ZM10 38V42H38V38H10Z" />
    </svg>
  );
};

export { FormatUnderlinedIcon };
