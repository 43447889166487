import cn from 'classnames';

const FontDownloadIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FontDownload24px', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M19.86 27H28.14L24 15.96L19.86 27ZM40 4H8C5.8 4 4 5.8 4 8V40C4 42.2 5.8 44 8 44H40C42.2 44 44 42.2 44 40V8C44 5.8 42.2 4 40 4ZM31.9 37L29.62 31H18.34L16.1 37H11.92L22.14 11H25.86L36.08 37H31.9Z" />
    </svg>
  );
};

export { FontDownloadIcon };
