/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { CoreRow, flexRender } from '@tanstack/react-table';

import { ActionListProps } from '../types';

import { CommandItem } from './CommandItem';
import { CommandSeparator } from './CommandSeparator';

type CommandGroupContentProps = {
  mediator: ActionListProps['mediator'];
  itemSize: ActionListProps['itemSize'];
  setClickedOption: (data: CoreRow<unknown>['original']) => void;
};

/**
 * Renders the content of a command group.
 *
 * @param mediator - The mediator object.
 * @param itemSize - The size of each command item.
 * @param setOpen - A function to set the open state of the command group.
 * @param onOpenChange - A function to handle the open state change of the command group.
 * @returns An array of React elements representing the command items and separators.
 */
export const CommandGroupContent = ({
  mediator,
  itemSize,
  setClickedOption,
}: CommandGroupContentProps) => {
  return mediator.getRowModel().rows.map((row) => {
    return row.getVisibleCells().map((cell) => {
      if (row.subRows.length > 0 || (row.original.isSeperator as boolean)) {
        return (
          <CommandSeparator
            key={cell.id}
            size={itemSize}
            state={
              row.getIsSelected()
                ? 'selected'
                : row.getCanSelectSubRows()
                  ? 'separator'
                  : 'default'
            }
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </CommandSeparator>
        );
      }
      return (
        <CommandItem
          key={cell.id}
          size={itemSize}
          disabled={!row.getCanSelect()}
          isSelected={row.getIsSelected()}
          canSelect={row.getCanSelect()}
          isMultiSelect={!!mediator.options.enableMultiRowSelection}
          state={
            row.getIsSelected()
              ? 'selected'
              : !row.getCanSelect()
                ? 'disabled'
                : undefined
          }
          {...{
            title: cell.getValue() as string,
          }}
          onSelect={() => {
            row.toggleSelected();
            setClickedOption(row.original);
          }}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </CommandItem>
      );
    });
  });
};
