import { VaultWalletFieldsFragment } from 'generated/graphql';

export type WalletHasBlockingOperationFields = Pick<
  VaultWalletFieldsFragment,
  'withdrawPossible' | 'conflictingWithdraw'
>;

const walletHasBlockingOperation = (
  wallet: WalletHasBlockingOperationFields | null | undefined,
) => {
  if (!wallet) {
    return false;
  }

  const hasBlockingOperation = Boolean(
    !wallet.withdrawPossible || wallet?.conflictingWithdraw,
  );

  return hasBlockingOperation;
};

export default walletHasBlockingOperation;
