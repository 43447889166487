import cn from 'classnames';

const FormatShapesIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatShapes', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M46 14V2H34V6H14V2H2V14H6V34H2V46H14V42H34V46H46V34H42V14H46ZM6 6H10V10H6V6ZM10 42H6V38H10V42ZM34 38H14V34H10V14H14V10H34V14H38V34H34V38ZM42 42H38V38H42V42ZM38 10V6H42V10H38ZM27.47 28H20.5L19.04 32H15.79L22.59 14H25.4L32.21 32H28.95L27.47 28ZM21.37 25.48H26.59L23.98 17.82L21.37 25.48Z" />
    </svg>
  );
};

export { FormatShapesIcon };
