import { LaunchIcon } from '@latitude/icons';
import cn from 'classnames';

type Props = {
  label: string;
};

export const ExternalLink = ({ label }: Props) => {
  return (
    <div className={cn(['flex', 'w-full', 'items-center', 'justify-between'])}>
      {label}
      <LaunchIcon
        data-testid="launch-icon"
        className={cn([
          'w-sizing-comp-action-list-icon',
          'h-sizing-comp-action-list-icon',
        ])}
      />
    </div>
  );
};
