import cn from 'classnames';

const CloudOffIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CloudOff', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38.71 20.07C37.35 13.19 31.28 8 24 8C21.05 8 18.3 8.87 15.98 10.34L18.9 13.26C20.43 12.47 22.16 12 24 12C30.08 12 35 16.92 35 23V24H38C41.31 24 44 26.69 44 30C44 32.27 42.73 34.22 40.87 35.24L43.77 38.14C46.32 36.33 48 33.37 48 30C48 24.72 43.89 20.44 38.71 20.07ZM6 10.55L11.5 16.03C5.12 16.3 0 21.55 0 28C0 34.63 5.37 40 12 40H35.45L39.45 44L42 41.46L8.55 8L6 10.55ZM15.45 20L31.45 36H12C7.58 36 4 32.42 4 28C4 23.58 7.58 20 12 20H15.45Z" />
    </svg>
  );
};

export { CloudOffIcon };
