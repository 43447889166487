import * as React from 'react';

import { Text } from '@anchorage/common/dist/components';

import css from './styles.module.scss';

type Props = {
  title: React.ReactNode;
  subtitle?: string;
};

const TitleAndSubtitle: React.FC<Props> = ({
  title,
  subtitle,
  ...restProps
}) => {
  return (
    <div {...restProps}>
      <Text size="small">{title}</Text>
      {subtitle && (
        <Text className={css.subtitle} size="small">
          {subtitle}
        </Text>
      )}
    </div>
  );
};

export default TitleAndSubtitle;
