import cn from 'classnames';

const FormatQuoteIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatQuote', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M12 34H18L22 26V14H10V26H16L12 34ZM28 34H34L38 26V14H26V26H32L28 34Z" />
    </svg>
  );
};

export { FormatQuoteIcon };
