import { Text } from '@anchorage/common/dist/components';

import { PolicyQuorumDiff } from 'components/shared/OperationDrawer/utils/vaultMembers';

import { UserFieldsFragment } from 'generated/graphql';

import css from './styles.module.scss';

import UpdatedUsers from '../../shared/UpdatedUsers';
import PolicyDiffSection from './PolicyDiffSection';

type PolicyQuorumProps = {
  first: boolean;
  quorum: PolicyQuorumDiff;
  total: PolicyQuorumDiff;
  description: string;
  quorumName?: string;
  addedUsersList?: UserFieldsFragment[];
  removedUsersList?: UserFieldsFragment[];
  unchangedUsersList?: UserFieldsFragment[];
};

const PolicyQuorum = ({
  policyQuorum,
}: {
  policyQuorum: PolicyQuorumProps;
}) => {
  const {
    addedUsersList,
    removedUsersList,
    unchangedUsersList,
    ...quorumInfo
  } = policyQuorum;

  return (
    <>
      <PolicyDiffSection {...quorumInfo} />
      <div
        data-testid={
          quorumInfo.first
            ? 'approvers-container'
            : 'required-approvers-container'
        }
        className={css.baseQuorumListWrapper}
      >
        <Text size="small">{`Approvers (${
          quorumInfo.total.updated ?? quorumInfo.total.original
        })`}</Text>
        <UpdatedUsers
          added={addedUsersList}
          removed={removedUsersList}
          unchanged={unchangedUsersList}
        />
      </div>
    </>
  );
};

export default PolicyQuorum;
