import { Button } from '@latitude/button';
import cn from 'classnames';
// eslint-disable-next-line import/named
import { sanitize } from 'dompurify';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';

import { useAnalytics } from '@anchorage/analytics';
import { Text } from '@anchorage/common/dist/components';
import { ChevronIcon } from '@anchorage/common/dist/components/Icons';
import {
  Body,
  CloseButton,
  Footer,
  Header,
  Modal,
} from '@anchorage/common/dist/components/ModalV2';
import type { ModalBaseProps } from '@anchorage/common/dist/components/ModalV2/_types';

import type { ImportantProductUpdatesPosts } from 'generated/graphql';

import css from './styles.module.scss';

type ImportantPost = ImportantProductUpdatesPosts.items;
enum ExitModeEnum {
  Done,
  Close,
}

// This is the Modal content width
const SECTION_WIDTH = 528;

export interface ProductUpdatesModalProps extends ModalBaseProps {
  posts: ImportantPost[];
}

const ProductUpdatesModal: FC<ProductUpdatesModalProps> = ({
  posts,
  onClose,
  ...modalProps
}) => {
  // Using 0-based indexes to make things easier
  const [selectedPostIndex, setSelectedPostIndex] = useState<number>(0);
  const slider = useRef<HTMLDivElement>(null!);

  const navigate = useCallback((to = 0) => {
    setSelectedPostIndex(to);
    slider.current?.scrollTo({
      top: 0,
      left: to * SECTION_WIDTH,
      behavior: 'smooth',
    });
  }, []);

  const showCountAndNavigation = posts.length > 1;

  const [openTime, setOpenTime] = useState<Date | null>(null);
  const [postTime, setPostTime] = useState<Date | null>(null);
  const { track } = useAnalytics();

  const trackClose = (exitMode: ExitModeEnum) => {
    const closeTime = new Date();
    const timeDiff =
      closeTime && openTime ? closeTime.getTime() - openTime.getTime() : null;
    if (timeDiff !== null) {
      switch (exitMode) {
        case ExitModeEnum.Done:
          track({
            name: 'product_updates:important_update:done',
            data: { time_spent_ms: timeDiff },
          });
          break;
        case ExitModeEnum.Close:
          track({
            name: 'product_updates:important_update:closed',
            data: { time_spent_ms: timeDiff },
          });
          break;
        default:
          break;
      }
    }
  };
  const trackPost = () => {
    const nextTime = new Date();
    const timeDiff =
      nextTime && postTime ? nextTime.getTime() - postTime.getTime() : null;
    if (timeDiff !== null) {
      track({
        name: 'product_updates:important_update:next',
        data: {
          time_spent_ms: timeDiff,
          post_number: selectedPostIndex,
          post_title: posts[selectedPostIndex]?.title,
        },
      });
    }
    setPostTime(new Date());
    navigate(selectedPostIndex + 1);
  };
  useEffect(() => {
    setOpenTime(new Date());
    setPostTime(new Date());
    track({
      name: 'product_updates:important_update:displayed',
    });
  }, [track]);

  return (
    <Modal {...modalProps} data-testid="product-updates-modal">
      <Header>
        <Text type="heading" size="large">
          Product updates
          {showCountAndNavigation &&
            ` ${selectedPostIndex + 1}/${posts.length}`}
        </Text>
        <CloseButton
          onClick={() => {
            trackClose(ExitModeEnum.Close);
            onClose?.();
          }}
        />
      </Header>
      <Body className={css.modalBody}>
        {posts.length > 0 ? (
          <div className={css.postWrapper} ref={slider}>
            {posts.map((post) => (
              <div key={post.id} className={css.post}>
                <Text type="heading" size="medium" className={css.postTitle}>
                  {post.title}
                </Text>
                <div
                  className={css.postBody}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(post.contentHTML, {
                      USE_PROFILES: { html: true },
                    }),
                  }}
                />
                {post.link && (
                  <a
                    href={post.link}
                    className={css.postLink}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() =>
                      track({
                        name: 'product_updates:important_update:link_clicked',
                        data: {
                          post_number: selectedPostIndex,
                          post_title: posts[selectedPostIndex]?.title,
                        },
                      })
                    }
                  >
                    {
                      // linkText will be "" if not configured, not null, hence || and not ??
                      post.linkText || post.link
                    }
                    <ChevronIcon
                      className={css.chevronIcon}
                      direction="right"
                    />
                  </a>
                )}
              </div>
            ))}
          </div>
        ) : null}
      </Body>
      <Footer className={css.modalFooter}>
        {showCountAndNavigation && (
          <div className={css.paginationWrapper}>
            {posts.map((post, index) => (
              <div
                key={post.id}
                className={cn({
                  [css.paginationItem!]: true,
                  [css.selectedPaginationItem!]: index === selectedPostIndex,
                })}
                onClick={() => navigate(index)}
              />
            ))}
          </div>
        )}
        <div className={css.buttonsWrapper}>
          {selectedPostIndex < posts.length - 1 && (
            <Button onClick={trackPost} variant="primary">
              Next
            </Button>
          )}
          {selectedPostIndex === posts.length - 1 && (
            <Button
              onClick={() => {
                trackClose(ExitModeEnum.Done);
                onClose?.();
              }}
              variant="primary"
            >
              Done
            </Button>
          )}
        </div>
      </Footer>
    </Modal>
  );
};

export default ProductUpdatesModal;
