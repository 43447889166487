import cn from 'classnames';

const BubbleChartIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BubbleChart', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M14.4 35.2C17.9346 35.2 20.8 32.3346 20.8 28.8C20.8 25.2654 17.9346 22.4 14.4 22.4C10.8654 22.4 8 25.2654 8 28.8C8 32.3346 10.8654 35.2 14.4 35.2Z" />
      <path d="M29.6 40C31.8091 40 33.6 38.2091 33.6 36C33.6 33.7909 31.8091 32 29.6 32C27.3909 32 25.6 33.7909 25.6 36C25.6 38.2091 27.3909 40 29.6 40Z" />
      <path d="M30.4 27.2C35.7019 27.2 40 22.9019 40 17.6C40 12.2981 35.7019 8 30.4 8C25.0981 8 20.8 12.2981 20.8 17.6C20.8 22.9019 25.0981 27.2 30.4 27.2Z" />
    </svg>
  );
};

export { BubbleChartIcon };
