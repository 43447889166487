import cn from 'classnames';

const AirplanemodeInactiveIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AirplanemodeInactive', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M26 18V7C26 5.34 24.66 4 23 4C21.34 4 20 5.34 20 7V14.36L35.65 30.01L42 32V28L26 18ZM6 10.55L15.97 20.52L4 28V32L20 27V38L16 41V44L23 42L30 44V41L26 38V30.55L37.45 42L40 39.45L8.55 8L6 10.55Z" />
    </svg>
  );
};

export { AirplanemodeInactiveIcon };
