import cn from 'classnames';

const CameraEnhanceIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CameraEnhance24px', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M26.5 28.5L24 34L21.5 28.5L16 26L21.5 23.5L24 18L26.5 23.5L32 26L26.5 28.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.34 10L18 6H30L33.66 10H40C42.2 10 44 11.8 44 14V38C44 40.2 42.2 42 40 42H8C5.8 42 4 40.2 4 38V14C4 11.8 5.8 10 8 10H14.34ZM14 26C14 31.52 18.48 36 24 36C29.52 36 34 31.52 34 26C34 20.48 29.52 16 24 16C18.48 16 14 20.48 14 26Z"
      />
    </svg>
  );
};

export { CameraEnhanceIcon };
