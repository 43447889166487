import cn from 'classnames';

const LayersClearIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LayersClear', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M39.62 29.98L42 28.13L39.15 25.28L36.77 27.13L39.62 29.98ZM38.73 20.55L42 18L24 4L18.17 8.53L33.92 24.28L38.73 20.55ZM6.55 2L4 4.55L12.44 12.99L6 18L9.26 20.53L24 32L28.19 28.74L31.04 31.59L23.98 37.08L9.24 25.61L6 28.13L24 42.13L33.89 34.43L41.46 42L44 39.45L6.55 2Z" />
    </svg>
  );
};

export { LayersClearIcon };
