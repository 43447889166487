import cn from 'classnames';

const ControlPointIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ControlPoint', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M26 14H22V22H14V26H22V34H26V26H34V22H26V14ZM24 4C12.97 4 4 12.97 4 24C4 35.03 12.97 44 24 44C35.03 44 44 35.03 44 24C44 12.97 35.03 4 24 4ZM24 40C15.18 40 8 32.82 8 24C8 15.18 15.18 8 24 8C32.82 8 40 15.18 40 24C40 32.82 32.82 40 24 40Z" />
    </svg>
  );
};

export { ControlPointIcon };
