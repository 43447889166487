import cn from 'classnames';
import * as React from 'react';

import {
  CancelIcon,
  CompleteIcon,
} from '@anchorage/common/dist/components/Icons';

import type { OperationDrawer } from 'generated/graphql';

import css from './styles.module.scss';

import AvatarWithText from '../../../shared/AvatarWithText';
import SectionRow from '../../../shared/SectionRow';

enum Section {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  WAITING = 'Waiting',
}

type User = Extract<
  OperationDrawer.operation,
  { __typename: 'WithdrawOperation' }
>['endorsements'][number]['user'];

export type UserWithRequired = User & { isRequiredApprover: boolean };

type Props = {
  title: string;
  users: UserWithRequired[];
};
const EndorsementSection = ({ title, users }: Props) => {
  const usersLength = users.length;

  if (usersLength === 0) {
    return null;
  }

  const icon =
    title === Section.APPROVED ? (
      <CompleteIcon className={cn([css.approvalIcon, css.icon])} />
    ) : (
      <CancelIcon className={cn([css.icon, css.rejectionIcon])} />
    );

  return (
    <SectionRow
      data-testid={`${title}-test-id`}
      className={css.endorsersSection}
      title={`${title} | ${usersLength}`}
    >
      {users.map((user: UserWithRequired, idx: number) => (
        <div
          key={`endorser-${user.publicKeyID}-${idx}`}
          className={css.endorsement}
        >
          <AvatarWithText
            className={css.avatar}
            hasBorder={user.isRequiredApprover}
            title={user.name}
            subtitle={
              user.isRequiredApprover ? 'Required Approver' : 'Approver'
            }
          />
          {title !== Section.WAITING ? icon : null}
        </div>
      ))}
    </SectionRow>
  );
};

export default EndorsementSection;
