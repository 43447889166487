import cn from 'classnames';

const LocalConvenienceStoreIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalConvenienceStore', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 14V8H10V14H4V40H20V32H28V40H44V14H38ZM22 20H18V22H22V24H16V18H20V16H16V14H22V20ZM32 24H30V20H26V14H28V18H30V14H32V24Z" />
    </svg>
  );
};

export { LocalConvenienceStoreIcon };
