import cn from 'classnames';

const CodeBlocksIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CodeBlocks', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M16.5 24L20 20.5C20.3 20.2 20.45 19.8333 20.45 19.4C20.45 18.9667 20.3 18.6 20 18.3C19.7 18 19.3361 17.85 18.9083 17.85C18.4805 17.85 18.1111 18 17.8 18.3L13.15 22.95C12.9833 23.1167 12.8667 23.2855 12.8 23.4566C12.7333 23.6278 12.7 23.8111 12.7 24.0067C12.7 24.2022 12.7333 24.3833 12.8 24.55C12.8667 24.7167 12.9833 24.8833 13.15 25.05L17.85 29.75C18.1611 30.05 18.5306 30.2 18.9584 30.2C19.3861 30.2 19.75 30.05 20.05 29.75C20.35 29.45 20.5 29.0833 20.5 28.65C20.5 28.2167 20.35 27.85 20.05 27.55L16.5 24ZM31.5 24L27.95 27.55C27.65 27.85 27.5 28.2167 27.5 28.65C27.5 29.0833 27.65 29.45 27.95 29.75C28.25 30.05 28.6139 30.2 29.0417 30.2C29.4694 30.2 29.8389 30.05 30.15 29.75L34.85 25.05C35.0167 24.8833 35.1333 24.7145 35.2 24.5434C35.2667 24.3722 35.3 24.1889 35.3 23.9934C35.3 23.7978 35.2667 23.6167 35.2 23.45C35.1333 23.2833 35.0167 23.1167 34.85 22.95L30.15 18.25C30.0167 18.0833 29.85 17.9667 29.65 17.9C29.45 17.8333 29.25 17.8 29.05 17.8C28.85 17.8 28.6583 17.8333 28.475 17.9C28.2917 17.9667 28.1222 18.08 27.9667 18.24C27.6556 18.56 27.5 18.9333 27.5 19.36C27.5 19.7867 27.65 20.15 27.95 20.45L31.5 24ZM9 42C8.2 42 7.5 41.7 6.9 41.1C6.3 40.5 6 39.8 6 39V9C6 8.2 6.3 7.5 6.9 6.9C7.5 6.3 8.2 6 9 6H39C39.8 6 40.5 6.3 41.1 6.9C41.7 7.5 42 8.2 42 9V39C42 39.8 41.7 40.5 41.1 41.1C40.5 41.7 39.8 42 39 42H9ZM9 39H39V9H9V39Z" />
    </svg>
  );
};

export { CodeBlocksIcon };
