export const isStringInEnum = <T extends object>(
  enumObj: T,
  value: string | number | null | undefined,
) => {
  return Object.values(enumObj).includes(value as T[keyof T]);
};

// Casts a string to an enum value if the string is a valid enum value.
const castStringToEnum = <EnumType extends object>(
  enumObj: EnumType,
  value: string | null | undefined,
) => {
  if (!value) {
    return null;
  }

  // Handle string enums
  if (isStringInEnum(enumObj, value)) {
    return value as EnumType[keyof EnumType];
  }

  // Handle numeric enums
  const numericValue = Number(value);
  if (!isNaN(numericValue) && isStringInEnum(enumObj, numericValue)) {
    return numericValue as EnumType[keyof EnumType];
  }

  return null;
};

export default castStringToEnum;
