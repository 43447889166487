import cn from 'classnames';

const BlurCircularIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BlurCircular', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M20 18C18.9 18 18 18.9 18 20C18 21.1 18.9 22 20 22C21.1 22 22 21.1 22 20C22 18.9 21.1 18 20 18ZM20 26C18.9 26 18 26.9 18 28C18 29.1 18.9 30 20 30C21.1 30 22 29.1 22 28C22 26.9 21.1 26 20 26ZM14 19C13.45 19 13 19.45 13 20C13 20.55 13.45 21 14 21C14.55 21 15 20.55 15 20C15 19.45 14.55 19 14 19ZM20 33C19.45 33 19 33.45 19 34C19 34.55 19.45 35 20 35C20.55 35 21 34.55 21 34C21 33.45 20.55 33 20 33ZM14 27C13.45 27 13 27.45 13 28C13 28.55 13.45 29 14 29C14.55 29 15 28.55 15 28C15 27.45 14.55 27 14 27ZM20 15C20.55 15 21 14.55 21 14C21 13.45 20.55 13 20 13C19.45 13 19 13.45 19 14C19 14.55 19.45 15 20 15ZM28 18C26.9 18 26 18.9 26 20C26 21.1 26.9 22 28 22C29.1 22 30 21.1 30 20C30 18.9 29.1 18 28 18ZM28 15C28.55 15 29 14.55 29 14C29 13.45 28.55 13 28 13C27.45 13 27 13.45 27 14C27 14.55 27.45 15 28 15ZM34 27C33.45 27 33 27.45 33 28C33 28.55 33.45 29 34 29C34.55 29 35 28.55 35 28C35 27.45 34.55 27 34 27ZM34 19C33.45 19 33 19.45 33 20C33 20.55 33.45 21 34 21C34.55 21 35 20.55 35 20C35 19.45 34.55 19 34 19ZM24 4C12.95 4 4 12.95 4 24C4 35.05 12.95 44 24 44C35.05 44 44 35.05 44 24C44 12.95 35.05 4 24 4ZM24 40C15.16 40 8 32.84 8 24C8 15.16 15.16 8 24 8C32.84 8 40 15.16 40 24C40 32.84 32.84 40 24 40ZM28 33C27.45 33 27 33.45 27 34C27 34.55 27.45 35 28 35C28.55 35 29 34.55 29 34C29 33.45 28.55 33 28 33ZM28 26C26.9 26 26 26.9 26 28C26 29.1 26.9 30 28 30C29.1 30 30 29.1 30 28C30 26.9 29.1 26 28 26Z" />
    </svg>
  );
};

export { BlurCircularIcon };
