import cn from 'classnames';

const ImportantDevicesIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ImportantDevices', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M30 18H23.94L22 12L20.06 18H14L18.94 21.52L17.06 27.34L22 23.74L26.94 27.34L25.06 21.52L30 18ZM30 18H23.94L22 12L20.06 18H14L18.94 21.52L17.06 27.34L22 23.74L26.94 27.34L25.06 21.52L30 18ZM46 22.01L36 22C34.9 22 34 22.9 34 24V42C34 43.1 34.9 44 36 44H46C47.1 44 48 43.1 48 42V24C48 22.9 47.1 22.01 46 22.01ZM46 40H36V26H46V40ZM40 4H4C1.78 4 0 5.78 0 8V32C0 34.2 1.78 36 4 36H18V40H14V44H30V40H26V36H30V32H4V8H40V18H44V8C44 5.78 42.2 4 40 4ZM23.94 18L22 12L20.06 18H14L18.94 21.52L17.06 27.34L22 23.74L26.94 27.34L25.06 21.52L30 18H23.94Z" />
    </svg>
  );
};

export { ImportantDevicesIcon };
