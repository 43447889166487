import * as React from 'react';

import { StandardizedStakingContextProps } from './types';

import { StandardizedStakingContext } from './StandardizedStakingProvider';

const useStandardizedStakingContext = (): StandardizedStakingContextProps =>
  React.useContext(StandardizedStakingContext);

export default useStandardizedStakingContext;
