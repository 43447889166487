import cn from 'classnames';

const ClearIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Clear', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M29.17 16L24 21.17L18.83 16L16 18.83L21.17 24L16 29.17L18.83 32L24 26.83L29.17 32L32 29.17L26.83 24L32 18.83L29.17 16ZM24 4C12.95 4 4 12.95 4 24C4 35.05 12.95 44 24 44C35.05 44 44 35.05 44 24C44 12.95 35.05 4 24 4ZM24 40C15.18 40 8 32.82 8 24C8 15.18 15.18 8 24 8C32.82 8 40 15.18 40 24C40 32.82 32.82 40 24 40Z" />
    </svg>
  );
};

export { ClearIcon };
