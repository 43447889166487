import cn from 'classnames';

const CallSplitIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CallSplit', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M28 8L32.59 12.59L26.83 18.34L29.66 21.17L35.41 15.41L40 20V8H28ZM20 8H8V20L12.59 15.41L22 24.83V40H26V23.17L15.41 12.59L20 8Z" />
    </svg>
  );
};

export { CallSplitIcon };
