import cn from 'classnames';
import * as React from 'react';

import { useDeepCompare } from '@anchorage/common/dist/hooks';

import type {
  AMLQuestionnaireSubmissionFragment,
  AssetTypeID,
} from 'generated/graphql';

import css from './styles.module.scss';

import type { AnswersGroupedByQuestions } from '../../shared/AMLSection/types';

import AMLSection from '../../shared/AMLSection';
import {
  getQuestionsAnswersGrouped,
  normalizeAllQuestionsByPages,
  normalizeAnswersByQuestionId,
} from '../../shared/AMLSection/helpers';
import Address from '../../shared/Address';
import AddressBadges from '../../shared/Address/AddressBadges';
import { FiatBankAccount } from '../../shared/FiatBankAccount';
import SectionRow from '../../shared/SectionRow';
import WidgetWrapper from '../../shared/WidgetWrapper';
import AssetTagSection from './AssetTagSection';

type Props = {
  address: string;
  bankAccountInfo?: string[] | null;
  addressSectionTitle?: string;
  amlSubmission?: AMLQuestionnaireSubmissionFragment | null;
  amlGroupedAnswers?: AnswersGroupedByQuestions[];
  blockchainExplorerURL?: string | null;
  isAnchorageVault?: boolean;
  isTrusted?: boolean;
  destinationNames: string[];
  vaultName?: string | null;
  walletName?: string | null;
  tagValue?: string | null;
  requiresMemo?: boolean;
  assetTypeID: AssetTypeID;
  trustedCounterpartyName?: string;
};

const DestinationWithAML = ({
  addressSectionTitle = 'To',
  address,
  bankAccountInfo,
  amlSubmission,
  amlGroupedAnswers,
  blockchainExplorerURL,
  isAnchorageVault,
  isTrusted,
  destinationNames,
  vaultName,
  walletName,
  tagValue,
  assetTypeID,
  trustedCounterpartyName,
  requiresMemo = false,
}: Props) => {
  // Answers & Questions grouped by questionId in 2 levels based: if the question have trigger or not
  const questionsAnswersGrouped = React.useMemo(
    () => {
      if (amlGroupedAnswers) {
        return amlGroupedAnswers;
      }

      if (!amlSubmission) {
        return [];
      }

      const {
        questionnaire: { questionnaireBody },
        answers,
      } = amlSubmission;
      // Questions normalized by questionId
      const questionsNormalized =
        normalizeAllQuestionsByPages(questionnaireBody);

      // All answers normalized by questionId
      const answersNormalized = normalizeAnswersByQuestionId(answers);

      return getQuestionsAnswersGrouped(questionsNormalized, answersNormalized);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([amlSubmission]),
  );

  return (
    <WidgetWrapper>
      <SectionRow className={css.section} title={addressSectionTitle}>
        {address.length > 0 ? (
          <Address
            destinationNames={destinationNames}
            address={address}
            blockchainExplorerURL={blockchainExplorerURL}
          />
        ) : bankAccountInfo?.length ? (
          <FiatBankAccount bankAccountInfo={bankAccountInfo} />
        ) : (
          walletName || null
        )}
      </SectionRow>
      {tagValue ? (
        <AssetTagSection
          assetTypeID={assetTypeID}
          value={tagValue}
          requiresMemo={requiresMemo}
        />
      ) : null}
      <SectionRow className={cn([css.section, css.badgeSection])} title="">
        <AddressBadges
          isAnchorageVault={isAnchorageVault}
          isTrusted={isTrusted}
          trustedCounterpartyName={trustedCounterpartyName}
        />
      </SectionRow>
      {vaultName ? <SectionRow title="Vault">{vaultName}</SectionRow> : null}
      {questionsAnswersGrouped.length ? (
        <AMLSection
          className={css.topPadding}
          amlGroupedAnswers={questionsAnswersGrouped}
        />
      ) : null}
    </WidgetWrapper>
  );
};

export default DestinationWithAML;
