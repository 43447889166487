import { useFormContext } from '@anchorage/common/dist/components/Form/_reactHookForm';
import { FormInput } from '@anchorage/common/dist/components/Form/components';

import { StandardizedStakeFormValues } from 'components/Vault/shared/Staking/types';

const StakingValidatorInputStep = () => {
  const { control } = useFormContext<StandardizedStakeFormValues>();

  return (
    <FormInput
      control={control}
      name="validatorAddress"
      label="Enter a validator address"
      placeholder="Enter validator address"
      data-testid="staking-validator-input-step"
    />
  );
};
export default StakingValidatorInputStep;
