import { StandardizedStakingStep } from 'generated/graphql';

import AutoCompoundContentPage from '../../AutoCompound/AutoCompoundContentPage';
import AutoCompoundToggleCard from '../../AutoCompound/AutoCompoundToggleCard';
import { StandardizedStakingMode } from '../../constants';

interface Props {
  autoCompoundFormStep: StandardizedStakingStep.AutoCompoundStepInlineFragment;
}

// Form page for the auto-compound step in the standardized staking wizard
const StandardizedAutoCompoundFormPage = ({ autoCompoundFormStep }: Props) => {
  return (
    <div>
      <AutoCompoundContentPage
        subtitle={autoCompoundFormStep.subtitle ?? ''}
        content={autoCompoundFormStep.content ?? []}
        autoCompoundMode={StandardizedStakingMode.ENABLE_AUTO_COMPOUND}
      />
      <AutoCompoundToggleCard />
    </div>
  );
};

export default StandardizedAutoCompoundFormPage;
