import cn from 'classnames';

const ExposureIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Exposure', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M30 34V38H34V34H38V30H34V26H30V30H26V34H30ZM40 4H8C5.79 4 4 5.79 4 8V40C4 42.21 5.79 44 8 44H40C42.21 44 44 42.21 44 40V8C44 5.79 42.21 4 40 4ZM10 10H22V14H10V10ZM40 40H8L40 8V40Z" />
    </svg>
  );
};

export { ExposureIcon };
