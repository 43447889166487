import React, { useCallback } from 'react';

import type { AnchorageComponentHook } from '../../types/hooks';

import { BannerProps } from './_types';

type AdditionalProps = {
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const useBanner: AnchorageComponentHook<AdditionalProps, BannerProps> = (
  defaultProps
): [AdditionalProps & BannerProps, BannerProps] => {
  const { isVisible: initialState = true, ...restProps } = defaultProps;

  const [isVisible, setIsVisible] = React.useState<boolean>(initialState);

  const onClose = useCallback(() => setIsVisible(false), [setIsVisible]);

  const baseProps = {
    onClose,
    isVisible,
    ...restProps,
  };

  return [
    {
      setIsVisible,
      ...baseProps,
    },
    baseProps,
  ];
};

export default useBanner;
