import cn from 'classnames';

const FormatAlignLeftIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatAlignLeft', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M30 30H6V34H30V30ZM30 14H6V18H30V14ZM6 26H42V22H6V26ZM6 42H42V38H6V42ZM6 6V10H42V6H6Z" />
    </svg>
  );
};

export { FormatAlignLeftIcon };
