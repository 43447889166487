import { ApolloError } from '@apollo/client';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useAnalytics } from '@anchorage/analytics';
import { ActionResult } from '@anchorage/analytics/src/lib/constants';
import { FormWizard } from '@anchorage/common/dist/components';
import { useForm } from '@anchorage/common/dist/components/Form/_reactHookForm';
import { yupResolver } from '@anchorage/common/dist/components/Form/_yupResolver';
import { Step } from '@anchorage/common/dist/components/FormWizard/types';
import { useSnackbar } from '@anchorage/common/dist/components/Snackbar';
import {
  UIDeveloperError,
  WrappedError,
  reportError,
} from '@anchorage/common/dist/utils/errors';
import { getGraphQLQueryName } from '@anchorage/frontoffice/utils/getGraphQLQueryName';

import VaultWalletsQuery from 'components/Vault/WalletsDrawer/graphql/VaultWallets.graphql';

import { getStandardizedClaimRewardsFlowPageEventName } from '../utils/mixpanel';

import {
  useCreateTendermintClaimRewardsOneoffMutation,
  useOperationMakeVisibleInOperationsListMutation,
} from 'generated/graphql';

import css from './styles.module.scss';

import { StandardizedClaimRewardsFormValues } from '../../types';

import useStandardizedStakingContext from '../StandardizedStakingContext/useStandardizedStakingContext';
import {
  StandardizedClaimRewardsFlowWizardPage,
  StandardizedStakingMode,
} from '../constants';
import StandardizedClaimRewardsFormPage from './StandardizedClaimRewardsFormPage';
import SSClaimRewardsReviewPage from './StandardizedStakingReviewPage/SSClaimRewardsReviewPage';
import { getStandardizedClaimRewardsFormValidation } from './validation';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  validatorAddress: string;
};

export const StandardizedClaimRewardsWizard: React.FC<Props> = ({
  isOpen,
  onClose,
  validatorAddress,
}) => {
  const { addSnackbar } = useSnackbar();
  const { track } = useAnalytics();

  const { walletWithStakingInfo, walletAsset, assetTypeInfo } =
    useStandardizedStakingContext();

  const vault = walletWithStakingInfo.vault;
  const vaultSubID = vault.vaultID.vaultSubID;

  const resolverSchema = getStandardizedClaimRewardsFormValidation();

  const form = useForm<StandardizedClaimRewardsFormValues>({
    mode: 'onChange',
    defaultValues: {
      amount: '0',
      comment: '',
    },
    resolver: yupResolver(resolverSchema),
  });

  const {
    formState: { isValid },
  } = form;

  const walletName = walletWithStakingInfo.name;

  const trackStakingPage = React.useCallback(
    (initiationPage: StandardizedClaimRewardsFlowWizardPage) => {
      track({
        name: 'standardized_staking:continue:clicked',
        data: {
          initiationPage:
            getStandardizedClaimRewardsFlowPageEventName(initiationPage),
          stakingType: StandardizedStakingMode.CLAIM_REWARDS,
          assetTypeID: assetTypeInfo.assetTypeID,
        },
      });
    },
    [track, assetTypeInfo],
  );

  const [
    prepareClaimRewardsOperationMutation,
    { data: preparedOpData, loading: isLoadingPrepareOp },
  ] = useCreateTendermintClaimRewardsOneoffMutation({
    fetchPolicy: 'no-cache',
    variables: {
      assetSubID: walletAsset.assetID.assetSubID,
      assetTypeID: assetTypeInfo.assetTypeID,
      clientID: uuidv4(),
      validatorAddress,
      vaultSubID: vaultSubID || '',
    },
    onError: (error) => {
      addSnackbar({
        type: 'error',
        text: error.message || 'There was an error previewing your operation.',
        subtext: 'Please try again',
      });
      track({
        name: 'standardized_staking:wizard:flow_completed',
        data: {
          stakingType: StandardizedStakingMode.CLAIM_REWARDS,
          status: ActionResult.FAIL,
          initiationPage: getStandardizedClaimRewardsFlowPageEventName(
            StandardizedClaimRewardsFlowWizardPage.CLAIM_REWARDS,
          ),
          assetTypeID: assetTypeInfo.assetTypeID,
        },
      });
      reportError(
        new WrappedError(
          `There was an error preparing the claim rewards operation`,
          error,
        ),
      );
    },
  });

  const delegateOperationData =
    preparedOpData?.createTendermintClaimRewardsOneoff;
  const preparedOperationID = delegateOperationData?.operationID || '';

  const handleMakeVisibleOpMutationError = React.useCallback(
    (error?: ApolloError | null) => {
      addSnackbar({
        type: 'error',
        text:
          (error && error.message) ||
          'There was an error creating the claim rewards operation',
      });

      track({
        name: 'standardized_staking:wizard:flow_completed',
        data: {
          stakingType: StandardizedStakingMode.CLAIM_REWARDS,
          status: ActionResult.FAIL,
          initiationPage: getStandardizedClaimRewardsFlowPageEventName(
            StandardizedClaimRewardsFlowWizardPage.REVIEW,
          ),
          assetTypeID: assetTypeInfo.assetTypeID,
        },
      });

      if (error) {
        reportError(
          new WrappedError(
            `There was an error creating the claim rewards operation`,
            error,
          ),
        );
      }
    },
    [addSnackbar, track, assetTypeInfo],
  );

  const [
    createClaimRewardsOperation,
    { loading: isCreateClaimRewardsOperationLoading },
  ] = useOperationMakeVisibleInOperationsListMutation({
    onCompleted: (data) => {
      const success = data.operationMakeVisibleInOperationsList;
      if (success) {
        addSnackbar({
          type: 'success',
          text: 'Rewards claimed to wallet',
        });

        onClose();
        form.reset();

        track({
          name: 'standardized_staking:wizard:flow_completed',
          data: {
            stakingType: StandardizedStakingMode.CLAIM_REWARDS,
            status: ActionResult.SUCCESS,
            assetTypeID: assetTypeInfo.assetTypeID,
          },
        });
      } else {
        handleMakeVisibleOpMutationError();
      }
    },
    refetchQueries: [getGraphQLQueryName(VaultWalletsQuery)],
    onError: handleMakeVisibleOpMutationError,
  });

  const handleSubmit = () => {
    if (!preparedOperationID) {
      addSnackbar({
        type: 'error',
        text: 'There was an error creating the claim rewards operation',
      });
      reportError(
        new UIDeveloperError(
          'preparedOperationID is empty or null. Cannot create claim rewards operation.',
        ),
      );
      return;
    }

    trackStakingPage(StandardizedClaimRewardsFlowWizardPage.REVIEW);

    createClaimRewardsOperation({
      variables: { operationID: preparedOperationID },
    });
  };

  const steps: Step<StandardizedClaimRewardsFormValues>[] = [
    {
      title: `Claim rewards`,
      formFieldNames: ['amount'],
      description: walletName,
      element: <StandardizedClaimRewardsFormPage />,
      showProgress: true,
      displayedStepNumber: 1,
      nextBtnProps: {
        children: 'Preview Operation',
        isDisabled: !isValid || isLoadingPrepareOp,
        isLoading: isLoadingPrepareOp,
      },
      backBtnProps: {
        style: {
          display: 'none',
        },
      },
      onNext(goToStepIndex) {
        trackStakingPage(StandardizedClaimRewardsFlowWizardPage.CLAIM_REWARDS);

        prepareClaimRewardsOperationMutation({
          onCompleted: () => {
            goToStepIndex(StandardizedClaimRewardsFlowWizardPage.REVIEW);
          },
        });
      },
    },
    {
      title: 'Preview operation',
      formFieldNames: ['comment'],
      element: <SSClaimRewardsReviewPage data={delegateOperationData} />,
      showProgress: true,
      isSubmitStep: true,
      displayedStepNumber: 2,
      nextBtnProps: {
        children: 'Confirm and submit',
        isDisabled: !isValid || isCreateClaimRewardsOperationLoading,
        isLoading: isCreateClaimRewardsOperationLoading,
      },
      backBtnProps: {
        children: 'Go back',
      },
    },
  ];

  return (
    <>
      <FormWizard<StandardizedClaimRewardsFormValues>
        isOpen={isOpen}
        onClose={() => {
          form.reset();
          onClose();
        }}
        form={form}
        steps={steps}
        onSubmit={handleSubmit}
        modalProps={{
          wrapperClassName: css.modalWrapper,
          allowBackdropClose: true,
          allowEscapeClose: true,
        }}
        startingStep={StandardizedClaimRewardsFlowWizardPage.CLAIM_REWARDS}
        displayedMaxSteps={2}
      />
    </>
  );
};
