import { Text } from '@anchorage/common/dist/components';
import { useAppUser } from '@anchorage/frontoffice/contexts/AppUser';
import { getDisclosurePath } from '@anchorage/frontoffice/utils/routes';

import css from './styles.module.scss';

import { PRICE_DISCLOSURE_ID, PRICING_DISCLOSURE_TEXT } from '../constants';

const PricingDisclosureLinkSection = () => {
  const { isPortoOrg } = useAppUser();

  if (isPortoOrg) {
    return null;
  }

  return (
    <div className={css.feesDisclosure}>
      <Text size="small">{PRICING_DISCLOSURE_TEXT}</Text>
      <Text<'a'>
        type="link"
        size="small"
        tag="a"
        href={getDisclosurePath(PRICE_DISCLOSURE_ID)}
        target="_blank"
        rel="noopener noreferrer"
      >
        Pricing disclosure
      </Text>
    </div>
  );
};

export default PricingDisclosureLinkSection;
