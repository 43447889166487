import cn from 'classnames';

const LoyaltyIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Loyalty', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42.82 23.16L24.83 5.17C24.11 4.45 23.11 4 22 4H8C5.79 4 4 5.79 4 8V22C4 23.11 4.45 24.11 5.18 24.83L23.18 42.83C23.9 43.55 24.9 44 26 44C27.11 44 28.11 43.55 28.83 42.83L42.83 28.83C43.55 28.1 44 27.1 44 26C44 24.89 43.55 23.89 42.82 23.16ZM11 14C9.34 14 8 12.66 8 11C8 9.34 9.34 8 11 8C12.66 8 14 9.34 14 11C14 12.66 12.66 14 11 14ZM34.54 30.54L26 39.08L17.46 30.54C16.56 29.63 16 28.38 16 27C16 24.24 18.24 22 21 22C22.38 22 23.64 22.56 24.54 23.47L26 24.93L27.46 23.47C28.37 22.56 29.62 22 31 22C33.76 22 36 24.24 36 27C36 28.38 35.44 29.63 34.54 30.54Z" />
    </svg>
  );
};

export { LoyaltyIcon };
