import { Text } from '@anchorage/common/dist/components';

import css from './styles.module.css';

type Props = {
  bankAccountInfo: string[] | null | undefined;
};

export const FiatBankAccount = ({ bankAccountInfo }: Props) => {
  if (!bankAccountInfo) {
    return null;
  }

  return (
    <>
      {bankAccountInfo.map((info, index) => {
        return (
          <Text type="body" size="small" key={index} className={css.info}>
            {info}
          </Text>
        );
      })}
    </>
  );
};
