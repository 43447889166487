import Text from '@latitude/text';
import Image from 'next/image';

import { AutoClaimIcon } from '@anchorage/common/dist/components/Icons';

import { StandardizedStakingStep } from 'generated/graphql';

import { AutoCompoundMode, StandardizedStakingMode } from '../../constants';

interface Props {
  autoCompoundMode: AutoCompoundMode;
  subtitle: string;
  content: StandardizedStakingStep.content[];
}

export default function AutoCompoundContentPage({
  subtitle,
  content,
  autoCompoundMode,
}: Props) {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col items-center">
        {autoCompoundMode === StandardizedStakingMode.ENABLE_AUTO_COMPOUND && (
          <AutoClaimIcon className="size-20 pb-4" />
        )}
        <Text variant="headingTiny" className="text-color-ref-text-strong">
          {subtitle}
        </Text>
      </div>
      {content.length > 0 && (
        <div>
          <div className="flex flex-col gap-4">
            {content.map((item, index) => {
              return (
                <div
                  className="border-color-ref-ui-divider-strong flex items-start gap-4 self-stretch border-b pb-4 last:border-0"
                  key={index}
                >
                  <Image
                    className="min-w-6"
                    src={item.iconURL}
                    alt={item.text}
                    width={16}
                    height={16}
                    unoptimized
                  />
                  <Text className="text-color-ref-text-strong text-sm">
                    {item.text}
                  </Text>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
