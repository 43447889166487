import cn from 'classnames';

const BankTransferIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BankTransfer', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M31.05 28.75V21.625H38.1V16.875L47.5 25.1875L38.1 33.5V28.75H31.05ZM28.7 13.7875V16.875H0.5V13.7875L14.6 5L28.7 13.7875ZM12.25 19.25H16.95V31.125H12.25V19.25ZM2.85 19.25H7.55V31.125H2.85V19.25ZM26.35 19.25V25.1875L21.65 29.4625V19.25H26.35ZM17.185 33.5L15.775 34.6875L19.77 38.25H0.5V33.5H17.185ZM35.75 31.125V38.25H28.7V43L19.3 34.6875L28.7 26.375V31.125H35.75Z" />
    </svg>
  );
};

export { BankTransferIcon };
