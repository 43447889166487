import cn from 'classnames';

const LaunchIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Launch', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 38H10V10H24V6H10C7.79 6 6 7.79 6 10V38C6 40.21 7.79 42 10 42H38C40.21 42 42 40.21 42 38V24H38V38ZM28 6V10H35.17L15.51 29.66L18.34 32.49L38 12.83V20H42V6H28Z" />
    </svg>
  );
};

export { LaunchIcon };
