import { Button } from '@latitude/button';
import { FC, useState } from 'react';

import { Loader, Text, TextArea } from '@anchorage/common/dist/components';
import { ChatIcon, EditIcon } from '@anchorage/common/dist/components/Icons';

import css from './styles.module.scss';

type Props = {
  value: string;
  onAdd: (value: string) => void;
  loading?: boolean;
};
const EditableComment: FC<Props> = ({ value, onAdd, loading = false }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [comment, setComment] = useState(value);

  if (isEditMode) {
    return (
      <div className={css.editModeContainer}>
        <TextArea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <div className={css.actionsContainer}>
          <Button
            className={css.primaryColor}
            size="tiny"
            type="ghost"
            onClick={() => {
              setIsEditMode(false);
              setComment(value);
            }}
          >
            Cancel
          </Button>
          <Button
            className={css.button}
            size="tiny"
            onClick={() => {
              onAdd(comment);
              setIsEditMode(false);
            }}
          >
            Add
          </Button>
        </div>
      </div>
    );
  }

  if (!value) {
    return (
      <Button
        className={css.primaryColor}
        type="ghost"
        size="tiny"
        leadingElement={<ChatIcon />}
        onClick={() => setIsEditMode(true)}
      >
        Add comment
      </Button>
    );
  }

  return (
    <div className={css.container}>
      {loading ? (
        <Loader className={css.loader} />
      ) : (
        <Text size="small">{value}</Text>
      )}
      <Button
        className={css.primaryColor}
        size="tiny"
        leadingElement={<EditIcon />}
        type="ghost"
        onClick={() => setIsEditMode(true)}
        disabled={loading}
      >
        Edit
      </Button>
    </div>
  );
};

export default EditableComment;
