import { ComponentProps, useMemo } from 'react';

import type { VaultPolicy } from '@anchorage/authorization';
import { useDeepCompare } from '@anchorage/common/dist/hooks';

import { PolicyQuorumDiff } from 'components/shared/OperationDrawer/utils/vaultMembers';

import { getMemberChanges } from './utils';

import { User } from 'generated/graphql';

import QuorumList from './QuorumList';

type UpdatedPolicyComponentProps = {
  originalPolicy?: VaultPolicy;
  updatedPolicy: VaultPolicy;
  title: string;
  expanded?: boolean;
  analytics?: ComponentProps<typeof QuorumList>['analytics'];
};

type Quorum = {
  first: boolean;
  quorumName: string;
  quorum: PolicyQuorumDiff;
  total: PolicyQuorumDiff;
  members: {
    original: User[];
    updated: User[];
  };
};

const UpdatedPolicyComponent = ({
  originalPolicy,
  updatedPolicy,
  title,
  expanded = false,
  analytics,
}: UpdatedPolicyComponentProps) => {
  const policyQuorums = useMemo(
    () => {
      const { addedUsers, removedUsers, unchangedUsers } = getMemberChanges(
        originalPolicy?.quorumGroup.baseQuorum.members ?? [],
        updatedPolicy.quorumGroup.baseQuorum.members,
      );

      const baseQuorum = {
        first: true,
        quorumName:
          originalPolicy?.quorumGroup?.baseQuorum?.displayName ?? 'Base quorum',
        quorum: {
          original: originalPolicy?.quorumGroup?.baseQuorum?.threshold ?? 0,
          updated: updatedPolicy.quorumGroup.baseQuorum.threshold,
        },
        total: {
          original:
            originalPolicy?.quorumGroup?.baseQuorum?.members.length ?? 0,
          updated: updatedPolicy.quorumGroup.baseQuorum.members.length,
        },
        description: 'Number of approvers to reach quorum',
        addedUsersList: addedUsers,
        removedUsersList: removedUsers,
        unchangedUsersList: unchangedUsers,
      };

      const pairs: {
        [name: string]: Quorum;
      } = {};

      originalPolicy?.quorumGroup.subquorums.forEach((subquorum) => {
        pairs[subquorum.displayName.toLowerCase()] = {
          first: false,
          quorumName: subquorum.displayName,
          quorum: {
            original: subquorum.threshold,
            updated: 0,
          },
          total: {
            original: subquorum.members.length,
            updated: 0,
          },
          members: {
            original: subquorum.members,
            updated: [],
          },
        };
      });

      updatedPolicy.quorumGroup.subquorums.forEach((subquorum) => {
        const name = subquorum.displayName.toLowerCase();
        const old = pairs[name];

        pairs[name] = {
          first: old?.first ?? false,
          quorumName: subquorum.displayName,
          quorum: {
            original: old?.quorum?.original ?? 0,
            updated: subquorum.threshold,
          },
          total: {
            original: old?.total?.original ?? 0,
            updated: subquorum.members.length,
          },
          members: {
            original: old?.members?.original ?? [],
            updated: subquorum.members,
          },
        };
      });

      const subQuorums = Object.values(pairs).map(
        ({ first, quorumName, quorum, total, members }) => {
          const { addedUsers, removedUsers, unchangedUsers } = getMemberChanges(
            members?.original ?? [],
            members?.updated ?? [],
          );
          return {
            first,
            quorumName,
            quorum,
            total,
            description: 'Number of approvers to reach sub-quorum',
            addedUsersList: addedUsers,
            removedUsersList: removedUsers,
            unchangedUsersList: unchangedUsers,
          };
        },
      );

      return [baseQuorum, ...Object.values(subQuorums)];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([
      originalPolicy?.quorumGroup.subquorums,
      updatedPolicy.quorumGroup.subquorums,
    ]),
  );

  const baseQuorum = policyQuorums[0];
  const subQuorums = policyQuorums.slice(1);

  if (!baseQuorum) {
    return null;
  }

  return (
    <QuorumList
      title={title}
      baseQuorum={baseQuorum}
      subQuorums={subQuorums}
      expanded={expanded}
      analytics={analytics}
    />
  );
};

export default UpdatedPolicyComponent;
