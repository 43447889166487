import cn from 'classnames';

const CircleCheckFilledIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Check', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M12 2C6.475 2 2 6.475 2 12c0 5.52 4.475 10 10 10 5.52 0 10-4.48 10-10 0-5.525-4.48-10-10-10zm-2 15l-5-5 1.415-1.415L10 14.17l7.585-7.585L19 8l-9 9z"></path>
    </svg>
  );
};

export { CircleCheckFilledIcon };
