import cn from 'classnames';

const AssignmentIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Assignment', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 6H29.63C28.81 3.68 26.61 2 24 2C21.39 2 19.19 3.68 18.37 6H10C7.79 6 6 7.79 6 10V38C6 40.21 7.79 42 10 42H38C40.21 42 42 40.21 42 38V10C42 7.79 40.21 6 38 6ZM24 6C25.1 6 26 6.89 26 8C26 9.11 25.1 10 24 10C22.9 10 22 9.11 22 8C22 6.89 22.9 6 24 6ZM28 34H14V30H28V34ZM34 26H14V22H34V26ZM34 18H14V14H34V18Z" />
    </svg>
  );
};

export { AssignmentIcon };
