import * as ToastPrimitives from '@radix-ui/react-toast';
import { Dispatch, ReactNode, createContext, useContext } from 'react';

import { Action, useToastReducer } from 'hooks/useToastReducer';

import { ToastsState } from '../types';

type ToasterContextProps = {
  toasts: ToastsState;
  dispatch: Dispatch<Action>;
};

const ToasterContext = createContext<ToasterContextProps>({
  toasts: [],
  dispatch: () => {},
});

/**
 * Provides a context for managing toasts in the application.
 *
 * @component
 * @example
 * ```tsx
 * <ToasterProvider>
 *   <App />
 * </ToasterProvider>
 * ```
 *
 * @param children - The child components to be wrapped by the provider.
 * @returns The provider component.
 */
export const ToasterProvider = ({ children }: { children: ReactNode }) => {
  const value = useToastReducer([]);

  return (
    <ToastPrimitives.Provider swipeDirection="left">
      <ToasterContext.Provider value={value}>
        {children}
      </ToasterContext.Provider>
    </ToastPrimitives.Provider>
  );
};

/**
 * Custom hook that provides access to the ToasterContext.
 * @returns The ToasterContext object.
 */
export function useToasterContext() {
  return useContext<ToasterContextProps>(ToasterContext);
}
