import cn from 'classnames';

const FaceIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Face', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18 23.5C16.62 23.5 15.5 24.62 15.5 26C15.5 27.38 16.62 28.5 18 28.5C19.38 28.5 20.5 27.38 20.5 26C20.5 24.62 19.38 23.5 18 23.5ZM30 23.5C28.62 23.5 27.5 24.62 27.5 26C27.5 27.38 28.62 28.5 30 28.5C31.38 28.5 32.5 27.38 32.5 26C32.5 24.62 31.38 23.5 30 23.5ZM24 4C12.95 4 4 12.95 4 24C4 35.05 12.95 44 24 44C35.05 44 44 35.05 44 24C44 12.95 35.05 4 24 4ZM24 40C15.18 40 8 32.82 8 24C8 23.42 8.04 22.85 8.1 22.29C12.81 20.2 16.57 16.34 18.52 11.55C22.14 16.65 28.09 20 34.83 20C36.38 20 37.89 19.81 39.33 19.47C39.76 20.91 40 22.43 40 24C40 32.82 32.82 40 24 40Z" />
    </svg>
  );
};

export { FaceIcon };
