/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
export enum AssetTypeID {
    AAVE = 'AAVE',
    AAVE_R = 'AAVE_R',
    ACH = 'ACH',
    AJNA = 'AJNA',
    ALI = 'ALI',
    ALICE = 'ALICE',
    ALLO= 'ALLO',
    ALLO_R= 'ALLO_R',
    ALLO_T= 'ALLO_T',
    ALPHA = 'ALPHA',
    ALUSD = 'ALUSD',
    AMP = 'AMP',
    ANC1 = 'ANC1',
    ANC1_R = 'ANC1_R',
    ANC_2_NFT_721 = 'ANC_2_NFT_721',
    ANC_NFT_721 = 'ANC_NFT_721',
    ANGLE = 'ANGLE',
    ANKR = 'ANKR',
    ANT = 'ANT',
    ANY = 'ANY',
    APE = 'APE',
    APT = 'APT',
    APT_R = 'APT_R',
    APT_T = 'APT_T',
    ARCA = 'ARCA',
    ARCA_T = 'ARCA_T',
    ARDN = 'ARDN',
    ASTO = 'ASTO',
    ATOM = 'ATOM',
    ATOM_R = 'ATOM_R',
    ATOM_SR = 'ATOM_SR',
    ATOM_ST = 'ATOM_ST',
    ATOM_T = 'ATOM_T',
    AUCTION = 'AUCTION',
    AUDIO = 'AUDIO',
    AURORA = 'AURORA',
    AUTOGLYPHS = 'AUTOGLYPHS',
    AVA = 'AVA',
    AVG = 'AVG',
    AXL = 'AXL',
    AXL_R = 'AXL_R',
    AXL_T = 'AXL_T',
    AXS = 'AXS',
    AZUKI = 'AZUKI',
    BADGER = 'BADGER',
    BAL = 'BAL',
    BAND = 'BAND',
    BAT = 'BAT',
    BAYC = 'BAYC',
    BCC = 'BCC',
    BCH = 'BCH',
    BCH_R = 'BCH_R',
    BCH_T = 'BCH_T',
    BETA = 'BETA',
    BGB = 'BGB',
    BICO = 'BICO',
    BIT = 'BIT',
    BLD = 'BLD',
    BLD_R = 'BLD_R',
    BLD_T = 'BLD_T',
    BLOCKS = 'BLOCKS',
    BLUR = 'BLUR',
    BNB = 'BNB',
    BNT = 'BNT',
    BOND = 'BOND',
    BREED = 'BREED',
    BSV = 'BSV',
    BSV_R = 'BSV_R',
    BSV_T = 'BSV_T',
    BTC = 'BTC',
    BTC_R = 'BTC_R',
    BTC_T = 'BTC_T',
    BTRST = 'BTRST',
    BTSE = 'BTSE',
    BUSD = 'BUSD',
    C3 = 'C3',
    CARDS = 'CARDS',
    CBAT = 'CBAT',
    CBETH = 'CBETH',
    CCOMP = 'CCOMP',
    CDAI = 'CDAI',
    CDT = 'CDT',
    CEEK = 'CEEK',
    CEL = 'CEL',
    CELR = 'CELR',
    CETH = 'CETH',
    CEUR = 'CEUR',
    CEUR_R = 'CEUR_R',
    CEUR_TA = 'CEUR_TA',
    CEUR_TB = 'CEUR_TB',
    CGLD = 'CGLD',
    CGLD_R = 'CGLD_R',
    CGLD_TA = 'CGLD_TA',
    CGLD_TB = 'CGLD_TB',
    CHR = 'CHR',
    CHSB = 'CHSB',
    CHZ = 'CHZ',
    CLONEX = 'CLONEX',
    COMBO = 'COMBO',
    COMP = 'COMP',
    COMP_DEV = 'COMP_DEV',
    COMP_R = 'COMP_R',
    COMP_T = 'COMP_T',
    COMP_TG = 'COMP_TG',
    COOL = 'COOL',
    CPOOL = 'CPOOL',
    CQT = 'CQT',
    CREAM = 'CREAM',
    CREP = 'CREP',
    CRO = 'CRO',
    CRV = 'CRV',
    CS = 'CS',
    CSO = 'CSO',
    CTSI = 'CTSI',
    CUNI = 'CUNI',
    CUSD = 'CUSD',
    CUSDC = 'CUSDC',
    CUSD_R = 'CUSD_R',
    CUSD_TA = 'CUSD_TA',
    CUSD_TB = 'CUSD_TB',
    CVC = 'CVC',
    CVX = 'CVX',
    CVXCRV = 'CVXCRV',
    CWBTC2 = 'CWBTC2',
    CZRX = 'CZRX',
    DAI = 'DAI',
    DAO = 'DAO',
    DAR = 'DAR',
    DENT = 'DENT',
    DHT = 'DHT',
    DIDA = 'DIDA',
    DKA = 'DKA',
    DODO = 'DODO',
    DOODLE = 'DOODLE',
    DYDX = 'DYDX',
    DYDX_CHAIN = 'DYDX_CHAIN',
    DYDX_CHAIN_R = 'DYDX_CHAIN_R',
    DYDX_CHAIN_T = 'DYDX_CHAIN_T',
    ECO = 'ECO',
    ECOX = 'ECOX',
    ELF = 'ELF',
    ELFI = 'ELFI',
    ELFI_R = 'ELFI_R',
    ELG = 'ELG',
    ELON = 'ELON',
    ENJ = 'ENJ',
    ENS = 'ENS',
    ENV = 'ENV',
    ETH = 'ETH',
    ETHDEV = 'ETHDEV',
    ETHHOL = 'ETHHOL',
    ETHSEP = 'ETHSEP',
    ETHTEST = 'ETHTEST',
    ETH_R = 'ETH_R',
    ETH_TG = 'ETH_TG',
    ETH_TR = 'ETH_TR',
    EUL = 'EUL',
    EUROC = 'EUROC',
    EURS = 'EURS',
    EVMOS = 'EVMOS',
    EVMOS_R = 'EVMOS_R',
    EVMOS_T = 'EVMOS_T',
    FEI = 'FEI',
    FET = 'FET',
    FF = 'FF',
    FIL = 'FIL',
    FIL_R = 'FIL_R',
    FIL_T = 'FIL_T',
    FIRE = 'FIRE',
    FLOKI = 'FLOKI',
    FLOW = 'FLOW',
    FLOW_R = 'FLOW_R',
    FLOW_T = 'FLOW_T',
    FLX = 'FLX',
    FLY = 'FLY',
    FOAM = 'FOAM',
    FORT = 'FORT',
    FORT_R = 'FORT_R',
    FOX = 'FOX',
    FRAX = 'FRAX',
    FST = 'FST',
    FTM = 'FTM',
    FTT = 'FTT',
    FUEL = 'FUEL',
    FUN = 'FUN',
    FWB = 'FWB',
    FX = 'FX',
    FXS = 'FXS',
    GAL = 'GAL',
    GALA = 'GALA',
    GERO = 'GERO',
    GF = 'GF',
    GFI = 'GFI',
    GMT = 'GMT',
    GNO = 'GNO',
    GNT = 'GNT',
    GOHM = 'GOHM',
    GRT = 'GRT',
    GT = 'GT',
    GUSD = 'GUSD',
    GYEN = 'GYEN',
    HASH = 'HASH',
    HASH_R = 'HASH_R',
    HASH_T = 'HASH_T',
    HBOT = 'HBOT',
    HBTC = 'HBTC',
    HEGIC = 'HEGIC',
    HFT = 'HFT',
    HMT = 'HMT',
    HOT = 'HOT',
    HOVERBOARD = 'HOVERBOARD',
    HT = 'HT',
    HUSD = 'HUSD',
    HXRO = 'HXRO',
    IAG = 'IAG',
    IBEUR = 'IBEUR',
    ICH = 'ICH',
    ICHI = 'ICHI',
    IDK = 'IDK',
    ILV = 'ILV',
    IMX = 'IMX',
    INDEX = 'INDEX',
    INDI = 'INDI',
    INJ = 'INJ',
    INSUR = 'INSUR',
    INX = 'INX',
    INX_DEV = 'INX_DEV',
    INX_R = 'INX_R',
    INX_T = 'INX_T',
    INX_TG = 'INX_TG',
    JAVATH = 'JAVATH',
    JETPACK = 'JETPACK',
    KARATE = 'KARATE',
    KEEP = 'KEEP',
    KEEP_T = 'KEEP_T',
    KNC = 'KNC',
    KNCV2 = 'KNCV2',
    KONGZ = 'KONGZ',
    LAND = 'LAND',
    LDO = 'LDO',
    LENS = 'LENS',
    LEO = 'LEO',
    LINK = 'LINK',
    LINKSEP = 'LINKSEP',
    LINK_T = 'LINK_T',
    LMWR = 'LMWR',
    LOKA = 'LOKA',
    LOOKS = 'LOOKS',
    LOOT = 'LOOT',
    LPT = 'LPT',
    LRC = 'LRC',
    LTC = 'LTC',
    LTC_R = 'LTC_R',
    LTC_T = 'LTC_T',
    LUSD = 'LUSD',
    MAGIC = 'MAGIC',
    MANA = 'MANA',
    MANATEST = 'MANATEST',
    MATIC = 'MATIC',
    MATIC_POLYGON = 'MATIC_POLYGON',
    MATIC_POLYGON_R = 'MATIC_POLYGON_R',
    MATIC_POLYGON_T = 'MATIC_POLYGON_T',
    MAYC = 'MAYC',
    MC = 'MC',
    MCB = 'MCB',
    MEEBITS = 'MEEBITS',
    MEM = 'MEM',
    MEM_B = 'MEM_B',
    METRIC = 'METRIC',
    MIR = 'MIR',
    MKR = 'MKR',
    MKRTEST = 'MKRTEST',
    MKR_DEV = 'MKR_DEV',
    MKR_R = 'MKR_R',
    MKR_TG = 'MKR_TG',
    MLBCB = 'MLBCB',
    MLN = 'MLN',
    MOONBIRD = 'MOONBIRD',
    MORPHO = 'MORPHO',
    MPL = 'MPL',
    MPOND = 'MPOND',
    MTA = 'MTA',
    MTL = 'MTL',
    MTS = 'MTS',
    MULTI = 'MULTI',
    MUSE = 'MUSE',
    MVL = 'MVL',
    MX = 'MX',
    MXC = 'MXC',
    MYC = 'MYC',
    MYT = 'MYT',
    MYTH = 'MYTH',
    NEST = 'NEST',
    NEXO = 'NEXO',
    NFTCOM = 'NFTCOM',
    NFTX = 'NFTX',
    NII = 'NII',
    NMR = 'NMR',
    NOTE = 'NOTE',
    NOUN = 'NOUN',
    NU = 'NU',
    NYM = 'NYM',
    OCEAN = 'OCEAN',
    OCMONK = 'OCMONK',
    OGN = 'OGN',
    OHM = 'OHM',
    OKB = 'OKB',
    OLDBLOCKS = 'OLDBLOCKS',
    OMG = 'OMG',
    OMI = 'OMI',
    ONDO = 'ONDO',
    ONE_INCH = 'ONE_INCH',
    OP = 'OP',
    ORBS = 'ORBS',
    OSMO = 'OSMO',
    OSMO_R = 'OSMO_R',
    OSMO_T = 'OSMO_T',
    OTHR = 'OTHR',
    OXT = 'OXT',
    PARTY = 'PARTY',
    PATH = 'PATH',
    PAX = 'PAX',
    PAXG = 'PAXG',
    PDT = 'PDT',
    PEEPS = 'PEEPS',
    PEOPLE = 'PEOPLE',
    PERP = 'PERP',
    PLA = 'PLA',
    PLTC = 'PLTC',
    POLY = 'POLY',
    POND = 'POND',
    POP = 'POP',
    PPT = 'PPT',
    PRIME = 'PRIME',
    PRINTS = 'PRINTS',
    PROM = 'PROM',
    PSP = 'PSP',
    PSTAKE = 'PSTAKE',
    PYR = 'PYR',
    PYUSD = 'PYUSD',
    QF = 'QF',
    QNT = 'QNT',
    QRKS = 'QRKS',
    RAD = 'RAD',
    RARE = 'RARE',
    RARI = 'RARI',
    RBN = 'RBN',
    RCOIN = 'RCOIN',
    REN = 'REN',
    RENBTC = 'RENBTC',
    REP = 'REP',
    REQ = 'REQ',
    REV = 'REV',
    RLY = 'RLY',
    RMO='RMO',
    RMO_R='RMO_R',
    RMO_T='RMO_T',
    RNDR = 'RNDR',
    ROSE = 'ROSE',
    ROSE_R = 'ROSE_R',
    ROSE_T = 'ROSE_T',
    RPL = 'RPL',
    RSR = 'RSR',
    RST = 'RST',
    RSV = 'RSV',
    RUNE = 'RUNE',
    SAFE = 'SAFE',
    SAND = 'SAND',
    SARCO = 'SARCO',
    SBTC = 'SBTC',
    SD = 'SD',
    SDL = 'SDL',
    SEI = 'SEI',
    SEI_R = 'SEI_R',
    SEI_T = 'SEI_T',
    SETH2 = 'SETH2',
    SHIB = 'SHIB',
    SIPHER = 'SIPHER',
    SKL = 'SKL',
    SNT = 'SNT',
    SNX = 'SNX',
    SNXTEST = 'SNXTEST',
    SOL = 'SOL',
    SOL_R = 'SOL_R',
    SOL_TD = 'SOL_TD',
    SPELL = 'SPELL',
    SRM = 'SRM',
    STETH = 'STETH',
    STG = 'STG',
    STKAAVE = 'STKAAVE',
    STORJ = 'STORJ',
    STRK = 'STRK',
    STRP = 'STRP',
    SUDO = 'SUDO',
    SUI = 'SUI',
    SUI_R = 'SUI_R',
    SUI_T = 'SUI_T',
    SUSHI = 'SUSHI',
    SWISE = 'SWISE',
    SXP = 'SXP',
    SYN = 'SYN',
    T = 'T',
    TBTC = 'TBTC',
    TELLER = 'TELLER',
    TEN = 'TEN',
    TIA = 'TIA',
    TIA_R = 'TIA_R',
    TIA_T = 'TIA_T',
    TITAN = 'TITAN',
    TKX = 'TKX',
    TLC = 'TLC',
    TLM = 'TLM',
    TOADZ = 'TOADZ',
    TOKE = 'TOKE',
    TON = 'TON',
    TONCOIN = 'TONCOIN',
    TRAC = 'TRAC',
    TRIBE = 'TRIBE',
    TRIBL = 'TRIBL',
    TRU = 'TRU',
    TUSD = 'TUSD',
    UMA = 'UMA',
    UNI = 'UNI',
    UOS = 'UOS',
    USD = 'USD',
    USD_R = 'USD_R',
    USDC = 'USDC',
    USDC_T = 'USDC_T',
    USDC_TG = 'USDC_TG',
    USDN = 'USDN',
    USDT = 'USDT',
    VFT = 'VFT',
    VGX = 'VGX',
    VLX = 'VLX',
    VVPDT = 'VVPDT',
    WAVES = 'WAVES',
    WAXL = 'WAXL',
    WBTC = 'WBTC',
    WCELO = 'WCELO',
    WCFG = 'WCFG',
    WCUSD = 'WCUSD',
    WETH = 'WETH',
    WEVMOS = 'WEVMOS',
    WEXIT = 'WEXIT',
    WEXOD = 'WEXOD',
    WFIL = 'WFIL',
    WFLOW = 'WFLOW',
    WLUNA = 'WLUNA',
    WOO = 'WOO',
    WOW = 'WOW',
    WPG = 'WPG',
    WPUNKS = 'WPUNKS',
    WRITE = 'WRITE',
    WSTETH = 'WSTETH',
    WSTR = 'WSTR',
    WSTRAX = 'WSTRAX',
    WZEC = 'WZEC',
    XAUT = 'XAUT',
    XAV = 'XAV',
    XCM = 'XCM',
    XCN = 'XCN',
    XDEFI = 'XDEFI',
    XDX = 'XDX',
    XDX_R = 'XDX_R',
    XDX_T = 'XDX_T',
    XDX_TPM = 'XDX_TPM',
    XIDO = 'XIDO',
    XLM = 'XLM',
    XLM_R = 'XLM_R',
    XLM_T = 'XLM_T',
    XMPL = 'XMPL',
    XRP = 'XRP',
    XRP_R = 'XRP_R',
    XRP_T = 'XRP_T',
    XSUSHI = 'XSUSHI',
    XTZ = 'XTZ',
    XTZ_R = 'XTZ_R',
    XTZ_T = 'XTZ_T',
    XTZ_TB = 'XTZ_TB',
    XUS = 'XUS',
    XUS_R = 'XUS_R',
    XUS_T = 'XUS_T',
    XUS_TPM = 'XUS_TPM',
    XYLB = 'XYLB',
    XYO = 'XYO',
    YFI = 'YFI',
    YGG = 'YGG',
    ZEC = 'ZEC',
    ZEC_R = 'ZEC_R',
    ZEC_T = 'ZEC_T',
    ZIG = 'ZIG',
    ZORA = 'ZORA',
    ZRX = 'ZRX',
    ZRXTEST = 'ZRXTEST'
  }
  
/** Enumeration of the possible statuses of an API key. */
export enum APIKeyStatus {
  /** Active indicates the API key is active and can be used. */
  ACTIVE = 'ACTIVE',
  /** Pending indicates the API key will become active after some required action. It cannot be used while pending. */
  PENDING = 'PENDING',
  /** Revoked indicates the API key has been revoked and cannot be used. */
  REVOKED = 'REVOKED',
  /** Unused indicates the API key is active but has not been used. */
  UNUSED = 'UNUSED'
}

/** IP Address family */
export enum AddressFamily {
  /** IPv4 family */
  IPV4 = 'IPV4',
  /** IPv6 family */
  IPV6 = 'IPV6'
}

/** Enum of information that may be provided by institutional affiliates */
export enum AffiliateInformationType {
  /** ACCEPT_INVESTOR_FUNDS */
  ACCEPT_INVESTOR_FUNDS = 'ACCEPT_INVESTOR_FUNDS',
  /** ACCOUNT_ACTIVITY ... */
  ACCOUNT_ACTIVITY = 'ACCOUNT_ACTIVITY',
  /** ACCOUNT_ACTIVITY_BORROWING */
  ACCOUNT_ACTIVITY_BORROWING = 'ACCOUNT_ACTIVITY_BORROWING',
  /** ACCOUNT_ACTIVITY_CUSTODY */
  ACCOUNT_ACTIVITY_CUSTODY = 'ACCOUNT_ACTIVITY_CUSTODY',
  /** ACCOUNT_ACTIVITY_INVESTMENTS ... */
  ACCOUNT_ACTIVITY_INVESTMENTS = 'ACCOUNT_ACTIVITY_INVESTMENTS',
  /** ACCOUNT_ACTIVITY_LENDING */
  ACCOUNT_ACTIVITY_LENDING = 'ACCOUNT_ACTIVITY_LENDING',
  /** ACCOUNT_ACTIVITY_MINING ... */
  ACCOUNT_ACTIVITY_MINING = 'ACCOUNT_ACTIVITY_MINING',
  /** ACCOUNT_ACTIVITY_STAKING */
  ACCOUNT_ACTIVITY_STAKING = 'ACCOUNT_ACTIVITY_STAKING',
  /** ACCOUNT_ACTIVITY_TRADING */
  ACCOUNT_ACTIVITY_TRADING = 'ACCOUNT_ACTIVITY_TRADING',
  /** ACCOUNT_BANK_ACTIVITY */
  ACCOUNT_BANK_ACTIVITY = 'ACCOUNT_BANK_ACTIVITY',
  /** ACCOUNT_BANK_ACTIVITY_CUSTODY */
  ACCOUNT_BANK_ACTIVITY_CUSTODY = 'ACCOUNT_BANK_ACTIVITY_CUSTODY',
  /** ACCOUNT_BANK_ACTIVITY_STAKING */
  ACCOUNT_BANK_ACTIVITY_STAKING = 'ACCOUNT_BANK_ACTIVITY_STAKING',
  /** ACCOUNT_NAME ... */
  ACCOUNT_NAME = 'ACCOUNT_NAME',
  /** ACCOUNT_PURPOSE */
  ACCOUNT_PURPOSE = 'ACCOUNT_PURPOSE',
  /** ACCOUNT_REGISTRATION ... */
  ACCOUNT_REGISTRATION = 'ACCOUNT_REGISTRATION',
  /** ACCOUNT_REGISTRATION_CORPORATION ... */
  ACCOUNT_REGISTRATION_CORPORATION = 'ACCOUNT_REGISTRATION_CORPORATION',
  /** ACCOUNT_REGISTRATION_LLC ... */
  ACCOUNT_REGISTRATION_LLC = 'ACCOUNT_REGISTRATION_LLC',
  /** ACCOUNT_REGISTRATION_OTHER ... */
  ACCOUNT_REGISTRATION_OTHER = 'ACCOUNT_REGISTRATION_OTHER',
  /** ACCOUNT_REGISTRATION_PARTNERSHIP ... */
  ACCOUNT_REGISTRATION_PARTNERSHIP = 'ACCOUNT_REGISTRATION_PARTNERSHIP',
  /** ACCOUNT_REGISTRATION_SOLE_PROPRIETOR ... */
  ACCOUNT_REGISTRATION_SOLE_PROPRIETOR = 'ACCOUNT_REGISTRATION_SOLE_PROPRIETOR',
  /** ACCOUNT_SOURCE_OF_FUNDS ... */
  ACCOUNT_SOURCE_OF_FUNDS = 'ACCOUNT_SOURCE_OF_FUNDS',
  /** ACKNOWLEDGE_EMAIL ... */
  ACKNOWLEDGE_EMAIL = 'ACKNOWLEDGE_EMAIL',
  /** ACKNOWLEDGE_FIRST_NAME ... */
  ACKNOWLEDGE_FIRST_NAME = 'ACKNOWLEDGE_FIRST_NAME',
  /** ACKNOWLEDGE_LAST_NAME ... */
  ACKNOWLEDGE_LAST_NAME = 'ACKNOWLEDGE_LAST_NAME',
  /** ACKNOWLEDGE_MIDDLE_NAME ... */
  ACKNOWLEDGE_MIDDLE_NAME = 'ACKNOWLEDGE_MIDDLE_NAME',
  /** ACKNOWLEDGE_NAME ... */
  ACKNOWLEDGE_NAME = 'ACKNOWLEDGE_NAME',
  /** ACKNOWLEDGE_TITLE ... */
  ACKNOWLEDGE_TITLE = 'ACKNOWLEDGE_TITLE',
  /** ADB_INVESTMENT_MANAGER_AFFILIATE_SUBMISSION_ID ... */
  ADB_INVESTMENT_MANAGER_AFFILIATE_SUBMISSION_ID = 'ADB_INVESTMENT_MANAGER_AFFILIATE_SUBMISSION_ID',
  /** ADB_MANAGED_FUND_AFFILIATE_SUBMISSION_ID ... */
  ADB_MANAGED_FUND_AFFILIATE_SUBMISSION_ID = 'ADB_MANAGED_FUND_AFFILIATE_SUBMISSION_ID',
  /** ADDITIONAL_DOCUMENT */
  ADDITIONAL_DOCUMENT = 'ADDITIONAL_DOCUMENT',
  /** ADDRESS ... */
  ADDRESS = 'ADDRESS',
  /** ADDRESS2 ... */
  ADDRESS2 = 'ADDRESS2',
  /** ADMIN_ISSUE ... */
  ADMIN_ISSUE = 'ADMIN_ISSUE',
  /** ADMIN_ISSUE_CATEGORY ... */
  ADMIN_ISSUE_CATEGORY = 'ADMIN_ISSUE_CATEGORY',
  /** ADMIN_ISSUE_ISSUE_DETAIL ... */
  ADMIN_ISSUE_ISSUE_DETAIL = 'ADMIN_ISSUE_ISSUE_DETAIL',
  /** ADMIN_ISSUE_KYC_ANALYST_COMMENT ... */
  ADMIN_ISSUE_KYC_ANALYST_COMMENT = 'ADMIN_ISSUE_KYC_ANALYST_COMMENT',
  /** ADMIN_ISSUE_VALID_OR_INVALID ... */
  ADMIN_ISSUE_VALID_OR_INVALID = 'ADMIN_ISSUE_VALID_OR_INVALID',
  /** ADS_KYC_AFFILIATE_FORM_SUBMISSION_ID ... */
  ADS_KYC_AFFILIATE_FORM_SUBMISSION_ID = 'ADS_KYC_AFFILIATE_FORM_SUBMISSION_ID',
  /** AFFILIATE_TYPE ... */
  AFFILIATE_TYPE = 'AFFILIATE_TYPE',
  /** AFFILIATE_WEBSITE_URL */
  AFFILIATE_WEBSITE_URL = 'AFFILIATE_WEBSITE_URL',
  /** AIA_AUTHORIZED_PERSON_NAME ... */
  AIA_AUTHORIZED_PERSON_NAME = 'AIA_AUTHORIZED_PERSON_NAME',
  /** AIA_DATE ... */
  AIA_DATE = 'AIA_DATE',
  /** AIA_ELIGIBILITY_CATEGORY ... */
  AIA_ELIGIBILITY_CATEGORY = 'AIA_ELIGIBILITY_CATEGORY',
  /** AIA_FINANCIAL_ASSETS_DOC ... */
  AIA_FINANCIAL_ASSETS_DOC = 'AIA_FINANCIAL_ASSETS_DOC',
  /** AIA_PERSONAL_ASSETS_DOC ... */
  AIA_PERSONAL_ASSETS_DOC = 'AIA_PERSONAL_ASSETS_DOC',
  /** AIA_PERSONAL_INCOME_DOC ... */
  AIA_PERSONAL_INCOME_DOC = 'AIA_PERSONAL_INCOME_DOC',
  /** AIA_SIGNATURE ... */
  AIA_SIGNATURE = 'AIA_SIGNATURE',
  /** AIA_TITLE ... */
  AIA_TITLE = 'AIA_TITLE',
  /** ALTERNATIVE_PAYMENT_METHODS ... */
  ALTERNATIVE_PAYMENT_METHODS = 'ALTERNATIVE_PAYMENT_METHODS',
  /** AML_AUDIT_REPORTS ... */
  AML_AUDIT_REPORTS = 'AML_AUDIT_REPORTS',
  /** AML_ELEMENTE_SUSPICIOUS_ACTIVITY ... */
  AML_ELEMENTE_SUSPICIOUS_ACTIVITY = 'AML_ELEMENTE_SUSPICIOUS_ACTIVITY',
  /** AML_ELEMENT_INDIVIDUALS ... */
  AML_ELEMENT_INDIVIDUALS = 'AML_ELEMENT_INDIVIDUALS',
  /** AML_ELEMENT_ONGOING_SANCTIONS ... */
  AML_ELEMENT_ONGOING_SANCTIONS = 'AML_ELEMENT_ONGOING_SANCTIONS',
  /** AML_ELEMENT_ONGOING_VERIFICATION ... */
  AML_ELEMENT_ONGOING_VERIFICATION = 'AML_ELEMENT_ONGOING_VERIFICATION',
  /** AML_ELEMENT_POLICIES ... */
  AML_ELEMENT_POLICIES = 'AML_ELEMENT_POLICIES',
  /** AML_ELEMENT_RETAIN_RECORDS ... */
  AML_ELEMENT_RETAIN_RECORDS = 'AML_ELEMENT_RETAIN_RECORDS',
  /** AML_ELEMENT_SCREENING_OFAC ... */
  AML_ELEMENT_SCREENING_OFAC = 'AML_ELEMENT_SCREENING_OFAC',
  /** AML_ELEMENT_SCREENING_PEP ... */
  AML_ELEMENT_SCREENING_PEP = 'AML_ELEMENT_SCREENING_PEP',
  /** AML_ELEMENT_TESTING ... */
  AML_ELEMENT_TESTING = 'AML_ELEMENT_TESTING',
  /** AML_EXEMPTION_REASON ... */
  AML_EXEMPTION_REASON = 'AML_EXEMPTION_REASON',
  /** AML_FINDING ... */
  AML_FINDING = 'AML_FINDING',
  /** AML_FINDING_CATEGORY ... */
  AML_FINDING_CATEGORY = 'AML_FINDING_CATEGORY',
  /** AML_FINDING_ISSUE_DETAIL ... */
  AML_FINDING_ISSUE_DETAIL = 'AML_FINDING_ISSUE_DETAIL',
  /** AML_FINDING_KYC_ANALYST_COMMENT ... */
  AML_FINDING_KYC_ANALYST_COMMENT = 'AML_FINDING_KYC_ANALYST_COMMENT',
  /** AML_FINDING_VALID_OR_INVALID ... */
  AML_FINDING_VALID_OR_INVALID = 'AML_FINDING_VALID_OR_INVALID',
  /** AML_KYC_PROGRAM_REVIEW */
  AML_KYC_PROGRAM_REVIEW = 'AML_KYC_PROGRAM_REVIEW',
  /** AML_OFFICER_NAME ... */
  AML_OFFICER_NAME = 'AML_OFFICER_NAME',
  /** AML_POLICIES ... */
  AML_POLICIES = 'AML_POLICIES',
  /** AML_POLICY_DOCUMENT ... */
  AML_POLICY_DOCUMENT = 'AML_POLICY_DOCUMENT',
  /** AML_PROGRAM_COMPONENT ... */
  AML_PROGRAM_COMPONENT = 'AML_PROGRAM_COMPONENT',
  /** AML_PROGRAM_ELEMENTS ... */
  AML_PROGRAM_ELEMENTS = 'AML_PROGRAM_ELEMENTS',
  /** ANCHORAGE_ENTITY_TYPE ... */
  ANCHORAGE_ENTITY_TYPE = 'ANCHORAGE_ENTITY_TYPE',
  /** ANNUAL_REPORT */
  ANNUAL_REPORT = 'ANNUAL_REPORT',
  /** APPOINTMENT_OF_TRUSTEES ... */
  APPOINTMENT_OF_TRUSTEES = 'APPOINTMENT_OF_TRUSTEES',
  /** ARE_AUTHORIZED_SIGNATORIES_APPOINTED ... */
  ARE_AUTHORIZED_SIGNATORIES_APPOINTED = 'ARE_AUTHORIZED_SIGNATORIES_APPOINTED',
  /** ARE_CLIENTS_FATF ... */
  ARE_CLIENTS_FATF = 'ARE_CLIENTS_FATF',
  /** ARTICLES_OF_ASSOCIATION ... */
  ARTICLES_OF_ASSOCIATION = 'ARTICLES_OF_ASSOCIATION',
  /** ASSET_CLASSES_EXPERIENCE ... */
  ASSET_CLASSES_EXPERIENCE = 'ASSET_CLASSES_EXPERIENCE',
  /** ASSET_CLASSES_EXPERIENCE_COMMODITIES ... */
  ASSET_CLASSES_EXPERIENCE_COMMODITIES = 'ASSET_CLASSES_EXPERIENCE_COMMODITIES',
  /** ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS ... */
  ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS = 'ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS',
  /** ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS_MINING */
  ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS_MINING = 'ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS_MINING',
  /** ASSET_CLASSES_EXPERIENCE_EQUITIES ... */
  ASSET_CLASSES_EXPERIENCE_EQUITIES = 'ASSET_CLASSES_EXPERIENCE_EQUITIES',
  /** ASSET_CLASSES_EXPERIENCE_FIXED_INCOME ... */
  ASSET_CLASSES_EXPERIENCE_FIXED_INCOME = 'ASSET_CLASSES_EXPERIENCE_FIXED_INCOME',
  /** ASSET_DEPOSIT_AMOUNT_PER_MONTH ... */
  ASSET_DEPOSIT_AMOUNT_PER_MONTH = 'ASSET_DEPOSIT_AMOUNT_PER_MONTH',
  /** ASSET_DEPOSIT_FREQUENCY_PER_MONTH ... */
  ASSET_DEPOSIT_FREQUENCY_PER_MONTH = 'ASSET_DEPOSIT_FREQUENCY_PER_MONTH',
  /** ASSET_WITHDRAWAL_AMOUNT_PER_MONTH ... */
  ASSET_WITHDRAWAL_AMOUNT_PER_MONTH = 'ASSET_WITHDRAWAL_AMOUNT_PER_MONTH',
  /** ASSET_WITHDRAWAL_FREQUENCY_PER_MONTH ... */
  ASSET_WITHDRAWAL_FREQUENCY_PER_MONTH = 'ASSET_WITHDRAWAL_FREQUENCY_PER_MONTH',
  /** ASSUMED_NAME_CERT ... */
  ASSUMED_NAME_CERT = 'ASSUMED_NAME_CERT',
  /** AUTHORIZED_MSB_AGENTS ... */
  AUTHORIZED_MSB_AGENTS = 'AUTHORIZED_MSB_AGENTS',
  /** AUTHORIZED_PERSONS_GROUP ... */
  AUTHORIZED_PERSONS_GROUP = 'AUTHORIZED_PERSONS_GROUP',
  /** AUTHORIZED_SIGNATORIES_DOCUMENT ... */
  AUTHORIZED_SIGNATORIES_DOCUMENT = 'AUTHORIZED_SIGNATORIES_DOCUMENT',
  /** AUTHORIZED_SIGNATORY_GROUP ... */
  AUTHORIZED_SIGNATORY_GROUP = 'AUTHORIZED_SIGNATORY_GROUP',
  /** AVG_DIGITAL_ASSET_DEPOSIT_VALUE_RANGE ... */
  AVG_DIGITAL_ASSET_DEPOSIT_VALUE_RANGE = 'AVG_DIGITAL_ASSET_DEPOSIT_VALUE_RANGE',
  /** AVG_DIGITAL_ASSET_DEPOSIT_VOLUME_RANGE ... */
  AVG_DIGITAL_ASSET_DEPOSIT_VOLUME_RANGE = 'AVG_DIGITAL_ASSET_DEPOSIT_VOLUME_RANGE',
  /** AVG_DIGITAL_ASSET_WITHDRAWAL_VALUE_RANGE ... */
  AVG_DIGITAL_ASSET_WITHDRAWAL_VALUE_RANGE = 'AVG_DIGITAL_ASSET_WITHDRAWAL_VALUE_RANGE',
  /** AVG_DIGITAL_ASSET_WITHDRAWAL_VOLUME_RANGE ... */
  AVG_DIGITAL_ASSET_WITHDRAWAL_VOLUME_RANGE = 'AVG_DIGITAL_ASSET_WITHDRAWAL_VOLUME_RANGE',
  /** AVG_FIAT_DEPOSIT_VALUE_RANGE ... */
  AVG_FIAT_DEPOSIT_VALUE_RANGE = 'AVG_FIAT_DEPOSIT_VALUE_RANGE',
  /** AVG_FIAT_DEPOSIT_VOLUME_RANGE ... */
  AVG_FIAT_DEPOSIT_VOLUME_RANGE = 'AVG_FIAT_DEPOSIT_VOLUME_RANGE',
  /** AVG_FIAT_WITHDRAWAL_VALUE_RANGE ... */
  AVG_FIAT_WITHDRAWAL_VALUE_RANGE = 'AVG_FIAT_WITHDRAWAL_VALUE_RANGE',
  /** AVG_FIAT_WITHDRAWAL_VOLUME_RANGE ... */
  AVG_FIAT_WITHDRAWAL_VOLUME_RANGE = 'AVG_FIAT_WITHDRAWAL_VOLUME_RANGE',
  /** AVG_TOTAL_TRANSACTION_VOLUME_RANGE ... */
  AVG_TOTAL_TRANSACTION_VOLUME_RANGE = 'AVG_TOTAL_TRANSACTION_VOLUME_RANGE',
  /** BANKING_RELATIONSHIP ... */
  BANKING_RELATIONSHIP = 'BANKING_RELATIONSHIP',
  /** BANKING_RELATIONSHIP_EXPLANATION ... */
  BANKING_RELATIONSHIP_EXPLANATION = 'BANKING_RELATIONSHIP_EXPLANATION',
  /** BENEFICIAL_AND_CONTROL_EXEMPT */
  BENEFICIAL_AND_CONTROL_EXEMPT = 'BENEFICIAL_AND_CONTROL_EXEMPT',
  /** BENEFICIAL_AND_CONTROL_EXEMPT_REASON */
  BENEFICIAL_AND_CONTROL_EXEMPT_REASON = 'BENEFICIAL_AND_CONTROL_EXEMPT_REASON',
  /** BENEFICIAL_OWNERS_REQUIRED */
  BENEFICIAL_OWNERS_REQUIRED = 'BENEFICIAL_OWNERS_REQUIRED',
  /** BENEFICIAL_OWNER_GROUP ... */
  BENEFICIAL_OWNER_GROUP = 'BENEFICIAL_OWNER_GROUP',
  /** BENEFICIARIES_GROUP ... */
  BENEFICIARIES_GROUP = 'BENEFICIARIES_GROUP',
  /** BLOCKCHAIN_TRANSACTION_MONITORING_VENDOR ... */
  BLOCKCHAIN_TRANSACTION_MONITORING_VENDOR = 'BLOCKCHAIN_TRANSACTION_MONITORING_VENDOR',
  /** BOARD_RESOLUTION ... */
  BOARD_RESOLUTION = 'BOARD_RESOLUTION',
  /** BO_AUTHORIZED_PERSON_NAME ... */
  BO_AUTHORIZED_PERSON_NAME = 'BO_AUTHORIZED_PERSON_NAME',
  /** BO_DATE ... */
  BO_DATE = 'BO_DATE',
  /** BO_SIGNATURE ... */
  BO_SIGNATURE = 'BO_SIGNATURE',
  /** BO_TITLE ... */
  BO_TITLE = 'BO_TITLE',
  /** BUSINESS_DESCRIPTION ... */
  BUSINESS_DESCRIPTION = 'BUSINESS_DESCRIPTION',
  /** BUSINESS_ENGAGEMENT_DOCUMENT */
  BUSINESS_ENGAGEMENT_DOCUMENT = 'BUSINESS_ENGAGEMENT_DOCUMENT',
  /** BUSINESS_FUND_PAYMENTS ... */
  BUSINESS_FUND_PAYMENTS = 'BUSINESS_FUND_PAYMENTS',
  /** BUSINESS_REGISTRATION_CERTIFICATE ... */
  BUSINESS_REGISTRATION_CERTIFICATE = 'BUSINESS_REGISTRATION_CERTIFICATE',
  /** BUSINESS_REGISTRATION_NUMBER ... */
  BUSINESS_REGISTRATION_NUMBER = 'BUSINESS_REGISTRATION_NUMBER',
  /** BUSINESS_RESTRICT_WITHDRAWALS ... */
  BUSINESS_RESTRICT_WITHDRAWALS = 'BUSINESS_RESTRICT_WITHDRAWALS',
  /** CEA_SECTION_18 ... */
  CEA_SECTION_18 = 'CEA_SECTION_18',
  /** CERTIFICATE_OF_GOOD_STANDING */
  CERTIFICATE_OF_GOOD_STANDING = 'CERTIFICATE_OF_GOOD_STANDING',
  /** CERTIFICATE_OF_INCORPORATION ... */
  CERTIFICATE_OF_INCORPORATION = 'CERTIFICATE_OF_INCORPORATION',
  /** CERTIFICATE_OF_INCUMBENCY ... */
  CERTIFICATE_OF_INCUMBENCY = 'CERTIFICATE_OF_INCUMBENCY',
  /** CERTIFICATE_OF_REGISTRATION ... */
  CERTIFICATE_OF_REGISTRATION = 'CERTIFICATE_OF_REGISTRATION',
  /** CERTIFY_OF_INFORMATION_PROVIDED ... */
  CERTIFY_OF_INFORMATION_PROVIDED = 'CERTIFY_OF_INFORMATION_PROVIDED',
  /** CIP_VERIFICATION ... */
  CIP_VERIFICATION = 'CIP_VERIFICATION',
  /** CITY */
  CITY = 'CITY',
  /** CLIENT_BASE ... */
  CLIENT_BASE = 'CLIENT_BASE',
  /** CLIENT_BASE_ACCREDITED_INVESTORS ... */
  CLIENT_BASE_ACCREDITED_INVESTORS = 'CLIENT_BASE_ACCREDITED_INVESTORS',
  /** CLIENT_BASE_CRYPTO ... */
  CLIENT_BASE_CRYPTO = 'CLIENT_BASE_CRYPTO',
  /** CLIENT_BASE_FAMILY ... */
  CLIENT_BASE_FAMILY = 'CLIENT_BASE_FAMILY',
  /** CLIENT_BASE_HEDGE_FUND ... */
  CLIENT_BASE_HEDGE_FUND = 'CLIENT_BASE_HEDGE_FUND',
  /** CLIENT_BASE_INSTITUTIONAL_TRUST ... */
  CLIENT_BASE_INSTITUTIONAL_TRUST = 'CLIENT_BASE_INSTITUTIONAL_TRUST',
  /** CLIENT_BASE_NONE ... */
  CLIENT_BASE_NONE = 'CLIENT_BASE_NONE',
  /** CLIENT_BASE_PRIVATE_CAPITAL ... */
  CLIENT_BASE_PRIVATE_CAPITAL = 'CLIENT_BASE_PRIVATE_CAPITAL',
  /** CLIENT_BASE_VENTURE_CAPITAL ... */
  CLIENT_BASE_VENTURE_CAPITAL = 'CLIENT_BASE_VENTURE_CAPITAL',
  /** COMPANY_NAME ... */
  COMPANY_NAME = 'COMPANY_NAME',
  /** CONFIDENTIALITY_OF_OWNERSHIP_EXPLANATION ... */
  CONFIDENTIALITY_OF_OWNERSHIP_EXPLANATION = 'CONFIDENTIALITY_OF_OWNERSHIP_EXPLANATION',
  /** CONSTITUTION_BY_LAWS ... */
  CONSTITUTION_BY_LAWS = 'CONSTITUTION_BY_LAWS',
  /** CONSTITUTION_DOCUMENT ... */
  CONSTITUTION_DOCUMENT = 'CONSTITUTION_DOCUMENT',
  /** CONTROL_PERSON_CIP_VERIFIED ... */
  CONTROL_PERSON_CIP_VERIFIED = 'CONTROL_PERSON_CIP_VERIFIED',
  /** CONTROL_PERSON_FIRST_NAME ... */
  CONTROL_PERSON_FIRST_NAME = 'CONTROL_PERSON_FIRST_NAME',
  /** CONTROL_PERSON_GROUP ... */
  CONTROL_PERSON_GROUP = 'CONTROL_PERSON_GROUP',
  /** CONTROL_PERSON_LAST_NAME ... */
  CONTROL_PERSON_LAST_NAME = 'CONTROL_PERSON_LAST_NAME',
  /** CONTROL_PERSON_MIDDLE_NAME ... */
  CONTROL_PERSON_MIDDLE_NAME = 'CONTROL_PERSON_MIDDLE_NAME',
  /** CONTROL_STRUCTURE ... */
  CONTROL_STRUCTURE = 'CONTROL_STRUCTURE',
  /** COUNTRIES_TO_DO_BUSINESS_IN */
  COUNTRIES_TO_DO_BUSINESS_IN = 'COUNTRIES_TO_DO_BUSINESS_IN',
  /** COUNTRIES_TO_TRANSFER_ASSETS */
  COUNTRIES_TO_TRANSFER_ASSETS = 'COUNTRIES_TO_TRANSFER_ASSETS',
  /** COUNTRY */
  COUNTRY = 'COUNTRY',
  /** COUNTRY_OF_OPERATION ... */
  COUNTRY_OF_OPERATION = 'COUNTRY_OF_OPERATION',
  /** COUNTRY_OF_OPERATION_GROUP ... */
  COUNTRY_OF_OPERATION_GROUP = 'COUNTRY_OF_OPERATION_GROUP',
  /** CURRENCIES_LIST ... */
  CURRENCIES_LIST = 'CURRENCIES_LIST',
  /** DATE_OF_BIRTH ... */
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  /** DBA_NAME ... */
  DBA_NAME = 'DBA_NAME',
  /** Optional DBA or Trade names */
  DBA_NAMES = 'DBA_NAMES',
  /** DECLARATION_OF_TRUST ... */
  DECLARATION_OF_TRUST = 'DECLARATION_OF_TRUST',
  /** DELIVERY_PREFERENCE ... */
  DELIVERY_PREFERENCE = 'DELIVERY_PREFERENCE',
  /** DELIVERY_PREFERENCE_CONTRACT_NOTIFICATIONS_GROUP ... */
  DELIVERY_PREFERENCE_CONTRACT_NOTIFICATIONS_GROUP = 'DELIVERY_PREFERENCE_CONTRACT_NOTIFICATIONS_GROUP',
  /** DELIVERY_PREFERENCE_DEFAULT_GROUP ... */
  DELIVERY_PREFERENCE_DEFAULT_GROUP = 'DELIVERY_PREFERENCE_DEFAULT_GROUP',
  /** DELIVERY_PREFERENCE_DEPOSIT_ATTRIBUTION_GROUP ... */
  DELIVERY_PREFERENCE_DEPOSIT_ATTRIBUTION_GROUP = 'DELIVERY_PREFERENCE_DEPOSIT_ATTRIBUTION_GROUP',
  /** DELIVERY_PREFERENCE_INVOICES_GROUP ... */
  DELIVERY_PREFERENCE_INVOICES_GROUP = 'DELIVERY_PREFERENCE_INVOICES_GROUP',
  /** DELIVERY_PREFERENCE_KYC_INQUIRY_GROUP ... */
  DELIVERY_PREFERENCE_KYC_INQUIRY_GROUP = 'DELIVERY_PREFERENCE_KYC_INQUIRY_GROUP',
  /** DELIVERY_PREFERENCE_OTHER_GROUP ... */
  DELIVERY_PREFERENCE_OTHER_GROUP = 'DELIVERY_PREFERENCE_OTHER_GROUP',
  /** DELIVERY_PREFERENCE_PLATFORM_UPDATES_GROUP ... */
  DELIVERY_PREFERENCE_PLATFORM_UPDATES_GROUP = 'DELIVERY_PREFERENCE_PLATFORM_UPDATES_GROUP',
  /** DELIVERY_PREFERENCE_SETTLEMENT_NETWORK_GROUP ... */
  DELIVERY_PREFERENCE_SETTLEMENT_NETWORK_GROUP = 'DELIVERY_PREFERENCE_SETTLEMENT_NETWORK_GROUP',
  /** DELIVERY_PREFERENCE_TAX_DOCUMENTS_GROUP ... */
  DELIVERY_PREFERENCE_TAX_DOCUMENTS_GROUP = 'DELIVERY_PREFERENCE_TAX_DOCUMENTS_GROUP',
  /** DELIVERY_PREFERENCE_TRADE_CONFIRMATIONS_GROUP ... */
  DELIVERY_PREFERENCE_TRADE_CONFIRMATIONS_GROUP = 'DELIVERY_PREFERENCE_TRADE_CONFIRMATIONS_GROUP',
  /** DEPOSIT_FUNDING_WALLET_ADDRESSES ... */
  DEPOSIT_FUNDING_WALLET_ADDRESSES = 'DEPOSIT_FUNDING_WALLET_ADDRESSES',
  /** DEPOSIT_FUNDING_WALLET_DO_OWN_WALLET ... */
  DEPOSIT_FUNDING_WALLET_DO_OWN_WALLET = 'DEPOSIT_FUNDING_WALLET_DO_OWN_WALLET',
  /** DEPOSIT_FUNDING_WALLET_GROUP ... */
  DEPOSIT_FUNDING_WALLET_GROUP = 'DEPOSIT_FUNDING_WALLET_GROUP',
  /** DEPOSIT_FUNDING_WALLET_ORIGINATOR_COUNTRY ... */
  DEPOSIT_FUNDING_WALLET_ORIGINATOR_COUNTRY = 'DEPOSIT_FUNDING_WALLET_ORIGINATOR_COUNTRY',
  /** DEPOSIT_FUNDING_WALLET_ORIGINATOR_NAME ... */
  DEPOSIT_FUNDING_WALLET_ORIGINATOR_NAME = 'DEPOSIT_FUNDING_WALLET_ORIGINATOR_NAME',
  /** DEPOSIT_FUNDING_WIRE_INSTITUTION ... */
  DEPOSIT_FUNDING_WIRE_INSTITUTION = 'DEPOSIT_FUNDING_WIRE_INSTITUTION',
  /** DIRECTORS */
  DIRECTORS = 'DIRECTORS',
  /** DOCUMENT_LINK ... */
  DOCUMENT_LINK = 'DOCUMENT_LINK',
  /** DOCUMENT_MONEY_TRANSMITTER_LICENSE ... */
  DOCUMENT_MONEY_TRANSMITTER_LICENSE = 'DOCUMENT_MONEY_TRANSMITTER_LICENSE',
  /** DOCUMENT_PREFERENCE ... */
  DOCUMENT_PREFERENCE = 'DOCUMENT_PREFERENCE',
  /** DOCUMENT_PREFERENCE_ADDRESS_DEFAULT_EMAIL ... */
  DOCUMENT_PREFERENCE_ADDRESS_DEFAULT_EMAIL = 'DOCUMENT_PREFERENCE_ADDRESS_DEFAULT_EMAIL',
  /** DOCUMENT_PREFERENCE_ADDRESS_INVOICE ... */
  DOCUMENT_PREFERENCE_ADDRESS_INVOICE = 'DOCUMENT_PREFERENCE_ADDRESS_INVOICE',
  /** DOCUMENT_PREFERENCE_ADDRESS_INVOICE_NAME ... */
  DOCUMENT_PREFERENCE_ADDRESS_INVOICE_NAME = 'DOCUMENT_PREFERENCE_ADDRESS_INVOICE_NAME',
  /** DOCUMENT_PREFERENCE_ADDRESS_KYC_INQUIRY ... */
  DOCUMENT_PREFERENCE_ADDRESS_KYC_INQUIRY = 'DOCUMENT_PREFERENCE_ADDRESS_KYC_INQUIRY',
  /** DOCUMENT_PREFERENCE_ADDRESS_KYC_INQUIRY_NAME ... */
  DOCUMENT_PREFERENCE_ADDRESS_KYC_INQUIRY_NAME = 'DOCUMENT_PREFERENCE_ADDRESS_KYC_INQUIRY_NAME',
  /** DOCUMENT_PREFERENCE_ADDRESS_TAX_DOCUMENTS ... */
  DOCUMENT_PREFERENCE_ADDRESS_TAX_DOCUMENTS = 'DOCUMENT_PREFERENCE_ADDRESS_TAX_DOCUMENTS',
  /** DOCUMENT_PREFERENCE_ADDRESS_TAX_DOCUMENTS_NAME ... */
  DOCUMENT_PREFERENCE_ADDRESS_TAX_DOCUMENTS_NAME = 'DOCUMENT_PREFERENCE_ADDRESS_TAX_DOCUMENTS_NAME',
  /** DOCUMENT_PREFERENCE_ADDRESS_TRADE_CONFIRMATIONS ... */
  DOCUMENT_PREFERENCE_ADDRESS_TRADE_CONFIRMATIONS = 'DOCUMENT_PREFERENCE_ADDRESS_TRADE_CONFIRMATIONS',
  /** DOCUMENT_PREFERENCE_DEPOSIT_ATTRIBUTION ... */
  DOCUMENT_PREFERENCE_DEPOSIT_ATTRIBUTION = 'DOCUMENT_PREFERENCE_DEPOSIT_ATTRIBUTION',
  /** DOCUMENT_PREFERENCE_DEPOSIT_ATTRIBUTION_NAME ... */
  DOCUMENT_PREFERENCE_DEPOSIT_ATTRIBUTION_NAME = 'DOCUMENT_PREFERENCE_DEPOSIT_ATTRIBUTION_NAME',
  /** DOCUMENT_PREFERENCE_PLATFORM_SERVICES_UPDATE ... */
  DOCUMENT_PREFERENCE_PLATFORM_SERVICES_UPDATE = 'DOCUMENT_PREFERENCE_PLATFORM_SERVICES_UPDATE',
  /** DOCUMENT_PREFERENCE_PLATFORM_SERVICES_UPDATE_NAME ... */
  DOCUMENT_PREFERENCE_PLATFORM_SERVICES_UPDATE_NAME = 'DOCUMENT_PREFERENCE_PLATFORM_SERVICES_UPDATE_NAME',
  /** DOCUMENT_TITLE ... */
  DOCUMENT_TITLE = 'DOCUMENT_TITLE',
  /** DOCUMENT_TYPE ... */
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  /** DOC_PREF_ADDRESS_CONTRACTUAL_NOTIFICATIONS ... */
  DOC_PREF_ADDRESS_CONTRACTUAL_NOTIFICATIONS = 'DOC_PREF_ADDRESS_CONTRACTUAL_NOTIFICATIONS',
  /** DOC_PREF_ADDRESS_CONTRACTUAL_NOTIFICATIONS_NAME ... */
  DOC_PREF_ADDRESS_CONTRACTUAL_NOTIFICATIONS_NAME = 'DOC_PREF_ADDRESS_CONTRACTUAL_NOTIFICATIONS_NAME',
  /** DOC_PREF_SETTLEMENT_NETWORK_NOTIFICATIONS ... */
  DOC_PREF_SETTLEMENT_NETWORK_NOTIFICATIONS = 'DOC_PREF_SETTLEMENT_NETWORK_NOTIFICATIONS',
  /** DOC_PREF_SETTLEMENT_NETWORK_NOTIFICATIONS_NAME ... */
  DOC_PREF_SETTLEMENT_NETWORK_NOTIFICATIONS_NAME = 'DOC_PREF_SETTLEMENT_NETWORK_NOTIFICATIONS_NAME',
  /** DONORS_AND_BENEFICIARIES */
  DONORS_AND_BENEFICIARIES = 'DONORS_AND_BENEFICIARIES',
  /** ECP_AUTHORIZED_PERSON_NAME ... */
  ECP_AUTHORIZED_PERSON_NAME = 'ECP_AUTHORIZED_PERSON_NAME',
  /** ECP_CUSTOMER_TYPE ... */
  ECP_CUSTOMER_TYPE = 'ECP_CUSTOMER_TYPE',
  /** ECP_DATE ... */
  ECP_DATE = 'ECP_DATE',
  /** ECP_ORGANIZATION_NAME ... */
  ECP_ORGANIZATION_NAME = 'ECP_ORGANIZATION_NAME',
  /** ECP_TITLE ... */
  ECP_TITLE = 'ECP_TITLE',
  /** EMAIL ... */
  EMAIL = 'EMAIL',
  /** EMPLOYER ... */
  EMPLOYER = 'EMPLOYER',
  /** EMPLOYMENT_STATUS ... */
  EMPLOYMENT_STATUS = 'EMPLOYMENT_STATUS',
  /** ENGAGED_ACTIVITIES_OR_INDUSTRIES ... */
  ENGAGED_ACTIVITIES_OR_INDUSTRIES = 'ENGAGED_ACTIVITIES_OR_INDUSTRIES',
  /** ENTITY_INTERMEDIARY_LAYERS ... */
  ENTITY_INTERMEDIARY_LAYERS = 'ENTITY_INTERMEDIARY_LAYERS',
  /** Full legal entity name */
  ENTITY_NAME = 'ENTITY_NAME',
  /** EXCHANGE ... */
  EXCHANGE = 'EXCHANGE',
  /** EXCHANGE_COUNTRY ... */
  EXCHANGE_COUNTRY = 'EXCHANGE_COUNTRY',
  /** FEIN ... */
  FEIN = 'FEIN',
  /** FIAT_DEPOSIT_FREQUENCY_PER_MONTH */
  FIAT_DEPOSIT_FREQUENCY_PER_MONTH = 'FIAT_DEPOSIT_FREQUENCY_PER_MONTH',
  /** FIAT_DEPOSIT_FREQUENCY_PER_YEAR ... */
  FIAT_DEPOSIT_FREQUENCY_PER_YEAR = 'FIAT_DEPOSIT_FREQUENCY_PER_YEAR',
  /** FIAT_WITHDRAWAL_FREQUENCY ... */
  FIAT_WITHDRAWAL_FREQUENCY = 'FIAT_WITHDRAWAL_FREQUENCY',
  /** FIAT_WITHDRAWAL_FREQUENCY_PER_MONTH */
  FIAT_WITHDRAWAL_FREQUENCY_PER_MONTH = 'FIAT_WITHDRAWAL_FREQUENCY_PER_MONTH',
  /** FINANCIALS_DOCUMENT ... */
  FINANCIALS_DOCUMENT = 'FINANCIALS_DOCUMENT',
  /** FINANCIAL_ACTION_TASK_FORCE ... */
  FINANCIAL_ACTION_TASK_FORCE = 'FINANCIAL_ACTION_TASK_FORCE',
  /** FINANCIAL_ACTION_TASK_FORCE_EXPLANATION ... */
  FINANCIAL_ACTION_TASK_FORCE_EXPLANATION = 'FINANCIAL_ACTION_TASK_FORCE_EXPLANATION',
  /** FINANCIAL_ACTION_TASK_FORCE_FIELDS ... */
  FINANCIAL_ACTION_TASK_FORCE_FIELDS = 'FINANCIAL_ACTION_TASK_FORCE_FIELDS',
  /** FINCEN_STATUS ... */
  FINCEN_STATUS = 'FINCEN_STATUS',
  /** FIRST_NAME ... */
  FIRST_NAME = 'FIRST_NAME',
  /** FOREIGN_BANK_CERTIFICATION */
  FOREIGN_BANK_CERTIFICATION = 'FOREIGN_BANK_CERTIFICATION',
  /** FOREIGN_BANK_CERTIFICATION_ACKNOWLEDGEMENT */
  FOREIGN_BANK_CERTIFICATION_ACKNOWLEDGEMENT = 'FOREIGN_BANK_CERTIFICATION_ACKNOWLEDGEMENT',
  /** FOREIGN_BANK_CERTIFICATION_AUTHORIZED_PERSON */
  FOREIGN_BANK_CERTIFICATION_AUTHORIZED_PERSON = 'FOREIGN_BANK_CERTIFICATION_AUTHORIZED_PERSON',
  /** FOREIGN_CORRESPONDENT_ACCOUNT ... */
  FOREIGN_CORRESPONDENT_ACCOUNT = 'FOREIGN_CORRESPONDENT_ACCOUNT',
  /** FOREIGN_FINANCIAL_INSTITUTION */
  FOREIGN_FINANCIAL_INSTITUTION = 'FOREIGN_FINANCIAL_INSTITUTION',
  /** FORMATION_DOCUMENT */
  FORMATION_DOCUMENT = 'FORMATION_DOCUMENT',
  /** FORM_5500 ... */
  FORM_5500 = 'FORM_5500',
  /** FULL_NAMES_OF_AUTHORIZED_SIGNATORIES ... */
  FULL_NAMES_OF_AUTHORIZED_SIGNATORIES = 'FULL_NAMES_OF_AUTHORIZED_SIGNATORIES',
  /** FUNDING_DEPOSIT_ADDRESS */
  FUNDING_DEPOSIT_ADDRESS = 'FUNDING_DEPOSIT_ADDRESS',
  /** FUNDING_WIRE_INSTITUTION */
  FUNDING_WIRE_INSTITUTION = 'FUNDING_WIRE_INSTITUTION',
  /** FUND_STRUCTURE_CHART ... */
  FUND_STRUCTURE_CHART = 'FUND_STRUCTURE_CHART',
  /** GRANTORS_GROUP ... */
  GRANTORS_GROUP = 'GRANTORS_GROUP',
  /** GROUP_HEADQUARTERS_COUNTRY ... */
  GROUP_HEADQUARTERS_COUNTRY = 'GROUP_HEADQUARTERS_COUNTRY',
  /** HAS_AGENT ... */
  HAS_AGENT = 'HAS_AGENT',
  /** HAS_BENEFICIAL_OWNERS ... */
  HAS_BENEFICIAL_OWNERS = 'HAS_BENEFICIAL_OWNERS',
  /** HAS_BLOCKCHAIN_TRANSACTION_MONITORING ... */
  HAS_BLOCKCHAIN_TRANSACTION_MONITORING = 'HAS_BLOCKCHAIN_TRANSACTION_MONITORING',
  /** HAS_CONFIDENTIALITY_OF_OWNERSHIP ... */
  HAS_CONFIDENTIALITY_OF_OWNERSHIP = 'HAS_CONFIDENTIALITY_OF_OWNERSHIP',
  /** HAS_DBA_NAME ... */
  HAS_DBA_NAME = 'HAS_DBA_NAME',
  /** HAS_INDIRECT_ENTITIES ... */
  HAS_INDIRECT_ENTITIES = 'HAS_INDIRECT_ENTITIES',
  /** HAS_MULTISTATE_MSB_LICENSE ... */
  HAS_MULTISTATE_MSB_LICENSE = 'HAS_MULTISTATE_MSB_LICENSE',
  /** HAS_NON_CUSTODIAL_WALLETS */
  HAS_NON_CUSTODIAL_WALLETS = 'HAS_NON_CUSTODIAL_WALLETS',
  /** HAS_PENSION_ERISA ... */
  HAS_PENSION_ERISA = 'HAS_PENSION_ERISA',
  /** HAS_PROOF_OF_ADDRESS ... */
  HAS_PROOF_OF_ADDRESS = 'HAS_PROOF_OF_ADDRESS',
  /** HAS_PROOF_OF_ADDRESS_CONTEXT ... */
  HAS_PROOF_OF_ADDRESS_CONTEXT = 'HAS_PROOF_OF_ADDRESS_CONTEXT',
  /** HAS_SEC_CFTC_RIA ... */
  HAS_SEC_CFTC_RIA = 'HAS_SEC_CFTC_RIA',
  /** HAS_TOKEN_ASSOCIATED_PROJ_CENTRALIZED_EXCHANGES ... */
  HAS_TOKEN_ASSOCIATED_PROJ_CENTRALIZED_EXCHANGES = 'HAS_TOKEN_ASSOCIATED_PROJ_CENTRALIZED_EXCHANGES',
  /** HAS_TOKEN_BLOCKCHAIN_ANALYTICS ... */
  HAS_TOKEN_BLOCKCHAIN_ANALYTICS = 'HAS_TOKEN_BLOCKCHAIN_ANALYTICS',
  /** HAS_TOKEN_BLOCKCHAIN_ANONYMITY ... */
  HAS_TOKEN_BLOCKCHAIN_ANONYMITY = 'HAS_TOKEN_BLOCKCHAIN_ANONYMITY',
  /** HAS_TOKEN_BLOCKCHAIN_BLOCK_EXPLORER ... */
  HAS_TOKEN_BLOCKCHAIN_BLOCK_EXPLORER = 'HAS_TOKEN_BLOCKCHAIN_BLOCK_EXPLORER',
  /** HAS_TOKEN_BLOCKCHAIN_INDEPENDENT_AUDIT ... */
  HAS_TOKEN_BLOCKCHAIN_INDEPENDENT_AUDIT = 'HAS_TOKEN_BLOCKCHAIN_INDEPENDENT_AUDIT',
  /** HAS_TOKEN_BLOCKCHAIN_SUFFICIENT_LIQUIDITY ... */
  HAS_TOKEN_BLOCKCHAIN_SUFFICIENT_LIQUIDITY = 'HAS_TOKEN_BLOCKCHAIN_SUFFICIENT_LIQUIDITY',
  /** HAS_TOKEN_BLOCKCHANIN_SIGNIFICANT_MARKET_ACTIVITY ... */
  HAS_TOKEN_BLOCKCHANIN_SIGNIFICANT_MARKET_ACTIVITY = 'HAS_TOKEN_BLOCKCHANIN_SIGNIFICANT_MARKET_ACTIVITY',
  /** HAS_US_TAXPAYER ... */
  HAS_US_TAXPAYER = 'HAS_US_TAXPAYER',
  /** HELD_ASSET_CLASSES ... */
  HELD_ASSET_CLASSES = 'HELD_ASSET_CLASSES',
  /** HELD_ASSET_CLASSES_COMMODITIES ... */
  HELD_ASSET_CLASSES_COMMODITIES = 'HELD_ASSET_CLASSES_COMMODITIES',
  /** HELD_ASSET_CLASSES_DIGITAL_ASSETS ... */
  HELD_ASSET_CLASSES_DIGITAL_ASSETS = 'HELD_ASSET_CLASSES_DIGITAL_ASSETS',
  /** HELD_ASSET_CLASSES_EQUITIES ... */
  HELD_ASSET_CLASSES_EQUITIES = 'HELD_ASSET_CLASSES_EQUITIES',
  /** HELD_ASSET_CLASSES_FIXED_INCOME ... */
  HELD_ASSET_CLASSES_FIXED_INCOME = 'HELD_ASSET_CLASSES_FIXED_INCOME',
  /** HIGH_RISK_COUNTRIES ... */
  HIGH_RISK_COUNTRIES = 'HIGH_RISK_COUNTRIES',
  /** HOW_MAINTAIN_AML ... */
  HOW_MAINTAIN_AML = 'HOW_MAINTAIN_AML',
  /** ID_CARD_BACK ... */
  ID_CARD_BACK = 'ID_CARD_BACK',
  /** ID_CARD_FRONT ... */
  ID_CARD_FRONT = 'ID_CARD_FRONT',
  /** INCORPORATION_COUNTRY ... */
  INCORPORATION_COUNTRY = 'INCORPORATION_COUNTRY',
  /** INCORPORATION_DATE ... */
  INCORPORATION_DATE = 'INCORPORATION_DATE',
  /** INDIRECT_OWNERS ... */
  INDIRECT_OWNERS = 'INDIRECT_OWNERS',
  /** INDIVIDUALS_CERTIFICATION */
  INDIVIDUALS_CERTIFICATION = 'INDIVIDUALS_CERTIFICATION',
  /** INDIVIDUALS_CERTIFICATION_CONFIRMATION */
  INDIVIDUALS_CERTIFICATION_CONFIRMATION = 'INDIVIDUALS_CERTIFICATION_CONFIRMATION',
  /** INDIVIDUALS_NUMBER ... */
  INDIVIDUALS_NUMBER = 'INDIVIDUALS_NUMBER',
  /** INITIAL FUNDING */
  INITIAL_FUNDING = 'INITIAL_FUNDING',
  /** INITIAL_FUNDING_WALLET_ADDRESSES ... */
  INITIAL_FUNDING_WALLET_ADDRESSES = 'INITIAL_FUNDING_WALLET_ADDRESSES',
  /** INITIAL_FUNDING_WALLET_DO_OWN_WALLET ... */
  INITIAL_FUNDING_WALLET_DO_OWN_WALLET = 'INITIAL_FUNDING_WALLET_DO_OWN_WALLET',
  /** INITIAL_FUNDING_WALLET_GROUP ... */
  INITIAL_FUNDING_WALLET_GROUP = 'INITIAL_FUNDING_WALLET_GROUP',
  /** INITIAL_FUNDING_WALLET_ORIGINATOR_COUNTRY ... */
  INITIAL_FUNDING_WALLET_ORIGINATOR_COUNTRY = 'INITIAL_FUNDING_WALLET_ORIGINATOR_COUNTRY',
  /** INITIAL_FUNDING_WALLET_ORIGINATOR_NAME ... */
  INITIAL_FUNDING_WALLET_ORIGINATOR_NAME = 'INITIAL_FUNDING_WALLET_ORIGINATOR_NAME',
  /** INVESTABLE_ASSETS ... */
  INVESTABLE_ASSETS = 'INVESTABLE_ASSETS',
  /** INVESTABLE_ASSETS_AT_ANCHORAGE */
  INVESTABLE_ASSETS_AT_ANCHORAGE = 'INVESTABLE_ASSETS_AT_ANCHORAGE',
  /** INVESTABLE_ASSETS_AT_ANCHORAGE_SGD ... */
  INVESTABLE_ASSETS_AT_ANCHORAGE_SGD = 'INVESTABLE_ASSETS_AT_ANCHORAGE_SGD',
  /** INVESTABLE_ASSETS_AT_ANCHORAGE_USD */
  INVESTABLE_ASSETS_AT_ANCHORAGE_USD = 'INVESTABLE_ASSETS_AT_ANCHORAGE_USD',
  /** INVESTABLE_ASSETS_CRYPTO_PERCENTAGE ... */
  INVESTABLE_ASSETS_CRYPTO_PERCENTAGE = 'INVESTABLE_ASSETS_CRYPTO_PERCENTAGE',
  /** INVESTABLE_ASSETS_CUSTODY ... */
  INVESTABLE_ASSETS_CUSTODY = 'INVESTABLE_ASSETS_CUSTODY',
  /** INVESTMENT_ADVISER_REGISTRATION ... */
  INVESTMENT_ADVISER_REGISTRATION = 'INVESTMENT_ADVISER_REGISTRATION',
  /** INVESTMENT_ADVISER_REGISTRATION_IARD ... */
  INVESTMENT_ADVISER_REGISTRATION_IARD = 'INVESTMENT_ADVISER_REGISTRATION_IARD',
  /** INVESTMENT_ADVISER_REGISTRATION_PRIMARY_STATE ... */
  INVESTMENT_ADVISER_REGISTRATION_PRIMARY_STATE = 'INVESTMENT_ADVISER_REGISTRATION_PRIMARY_STATE',
  /** INVESTMENT_ADVISOR_REGISTRATION ... */
  INVESTMENT_ADVISOR_REGISTRATION = 'INVESTMENT_ADVISOR_REGISTRATION',
  /** INVESTMENT_MANAGER_NAME ... */
  INVESTMENT_MANAGER_NAME = 'INVESTMENT_MANAGER_NAME',
  /** IS_BEARER_OR_REGISTERED_SHARES ... */
  IS_BEARER_OR_REGISTERED_SHARES = 'IS_BEARER_OR_REGISTERED_SHARES',
  /** IS_CHARITABLE_ORGANIZATION */
  IS_CHARITABLE_ORGANIZATION = 'IS_CHARITABLE_ORGANIZATION',
  /** IS_CUSTOMER_PRINCIPLE_OR_AGENT ... */
  IS_CUSTOMER_PRINCIPLE_OR_AGENT = 'IS_CUSTOMER_PRINCIPLE_OR_AGENT',
  /** IS_ECP ... */
  IS_ECP = 'IS_ECP',
  /** IS_PUBLIC_COMPANY ... */
  IS_PUBLIC_COMPANY = 'IS_PUBLIC_COMPANY',
  /** IS_REGISTERED_AS_MSB */
  IS_REGISTERED_AS_MSB = 'IS_REGISTERED_AS_MSB',
  /** IS_SUBSIDIARY_PUBLICLY_COMPANY ... */
  IS_SUBSIDIARY_PUBLICLY_COMPANY = 'IS_SUBSIDIARY_PUBLICLY_COMPANY',
  /** IS_TOKEN_BLOCKCHAIN_IN_PRODUCTION ... */
  IS_TOKEN_BLOCKCHAIN_IN_PRODUCTION = 'IS_TOKEN_BLOCKCHAIN_IN_PRODUCTION',
  /** IS_US_CFTC ... */
  IS_US_CFTC = 'IS_US_CFTC',
  /** JURISDICTIONS_TO_CONDUCT_INTERNATIONAL_WIRE */
  JURISDICTIONS_TO_CONDUCT_INTERNATIONAL_WIRE = 'JURISDICTIONS_TO_CONDUCT_INTERNATIONAL_WIRE',
  /** KYC_AFFILIATE_FORM_SUBMISSION_ID ... */
  KYC_AFFILIATE_FORM_SUBMISSION_ID = 'KYC_AFFILIATE_FORM_SUBMISSION_ID',
  /** LAST_NAME ... */
  LAST_NAME = 'LAST_NAME',
  /** LAW_ABIDING_CONFIRMATION ... */
  LAW_ABIDING_CONFIRMATION = 'LAW_ABIDING_CONFIRMATION',
  /** LEGAL_CITY */
  LEGAL_CITY = 'LEGAL_CITY',
  /** LEGAL_COUNTRY */
  LEGAL_COUNTRY = 'LEGAL_COUNTRY',
  /** LEGAL_REGIST_ADDR_DIF_PRINC_PLACE ... */
  LEGAL_REGIST_ADDR_DIF_PRINC_PLACE = 'LEGAL_REGIST_ADDR_DIF_PRINC_PLACE',
  /** LEGAL_STATE */
  LEGAL_STATE = 'LEGAL_STATE',
  /** LEGAL_STREET_ADDRESS */
  LEGAL_STREET_ADDRESS = 'LEGAL_STREET_ADDRESS',
  /** LEGAL_STREET_ADDRESS_SECOND ... */
  LEGAL_STREET_ADDRESS_SECOND = 'LEGAL_STREET_ADDRESS_SECOND',
  /** LEGAL_ZIP_CODE */
  LEGAL_ZIP_CODE = 'LEGAL_ZIP_CODE',
  /** LEGITIMACY_OF_ENTITY_ASSOCIATED ... */
  LEGITIMACY_OF_ENTITY_ASSOCIATED = 'LEGITIMACY_OF_ENTITY_ASSOCIATED',
  /** LICENSED_MSB_STATES ... */
  LICENSED_MSB_STATES = 'LICENSED_MSB_STATES',
  /** LICENSE_DATE_OF_REGISTRATION ... */
  LICENSE_DATE_OF_REGISTRATION = 'LICENSE_DATE_OF_REGISTRATION',
  /** LICENSE_DOCUMENT ... */
  LICENSE_DOCUMENT = 'LICENSE_DOCUMENT',
  /** LICENSE_EVIDENCE_REGISTRATION ... */
  LICENSE_EVIDENCE_REGISTRATION = 'LICENSE_EVIDENCE_REGISTRATION',
  /** LICENSE_REGISTRATION_NO ... */
  LICENSE_REGISTRATION_NO = 'LICENSE_REGISTRATION_NO',
  /** LICENSE_REGULATORY_AUTHORITY ... */
  LICENSE_REGULATORY_AUTHORITY = 'LICENSE_REGULATORY_AUTHORITY',
  /** LICENSE_TYPE ... */
  LICENSE_TYPE = 'LICENSE_TYPE',
  /** LICENSE_VALIDITY_FROM ... */
  LICENSE_VALIDITY_FROM = 'LICENSE_VALIDITY_FROM',
  /** LICENSE_VALIDITY_TO ... */
  LICENSE_VALIDITY_TO = 'LICENSE_VALIDITY_TO',
  /** LIST_OF_ATMS */
  LIST_OF_ATMS = 'LIST_OF_ATMS',
  /** MAILING_ADDRESS ... */
  MAILING_ADDRESS = 'MAILING_ADDRESS',
  /** MAILING_ADDRESS2 ... */
  MAILING_ADDRESS2 = 'MAILING_ADDRESS2',
  /** MAILING_CITY */
  MAILING_CITY = 'MAILING_CITY',
  /** MAILING_COUNTRY */
  MAILING_COUNTRY = 'MAILING_COUNTRY',
  /** MAILING_DIF_PRINCIPAL_PLACE ... */
  MAILING_DIF_PRINCIPAL_PLACE = 'MAILING_DIF_PRINCIPAL_PLACE',
  /** MAILING_STATE */
  MAILING_STATE = 'MAILING_STATE',
  /** MAILING_STREET_ADDRESS */
  MAILING_STREET_ADDRESS = 'MAILING_STREET_ADDRESS',
  /** MAILING_STREET_ADDRESS_SECOND ... */
  MAILING_STREET_ADDRESS_SECOND = 'MAILING_STREET_ADDRESS_SECOND',
  /** MAILING_ZIP_CODE */
  MAILING_ZIP_CODE = 'MAILING_ZIP_CODE',
  /** MAINTAIN_AML ... */
  MAINTAIN_AML = 'MAINTAIN_AML',
  /** MAINTAIN_AML_EVIDENCE_AML_ATTESTATION */
  MAINTAIN_AML_EVIDENCE_AML_ATTESTATION = 'MAINTAIN_AML_EVIDENCE_AML_ATTESTATION',
  /** MAINTAIN_AML_EXPLAIN_IF_NO ... */
  MAINTAIN_AML_EXPLAIN_IF_NO = 'MAINTAIN_AML_EXPLAIN_IF_NO',
  /** MANAGER_KYC_JIRA_LINK ... */
  MANAGER_KYC_JIRA_LINK = 'MANAGER_KYC_JIRA_LINK',
  /** MANAGER_LINKAGE_DOCUMENT ... */
  MANAGER_LINKAGE_DOCUMENT = 'MANAGER_LINKAGE_DOCUMENT',
  /** MANAGER_MEMBER ... */
  MANAGER_MEMBER = 'MANAGER_MEMBER',
  /** MATERIAL_NEWS ... */
  MATERIAL_NEWS = 'MATERIAL_NEWS',
  /** MIDDLE_NAME ... */
  MIDDLE_NAME = 'MIDDLE_NAME',
  /** MINING_COUNTRIES */
  MINING_COUNTRIES = 'MINING_COUNTRIES',
  /** MINING_DIGITAL_ASSETS */
  MINING_DIGITAL_ASSETS = 'MINING_DIGITAL_ASSETS',
  /** MINING_DIGITAL_ASSETS_AMOUNT */
  MINING_DIGITAL_ASSETS_AMOUNT = 'MINING_DIGITAL_ASSETS_AMOUNT',
  /** MINING_HASH_POWER */
  MINING_HASH_POWER = 'MINING_HASH_POWER',
  /** MINING_POWER_CONSUMPTION */
  MINING_POWER_CONSUMPTION = 'MINING_POWER_CONSUMPTION',
  /** MINING_STATES */
  MINING_STATES = 'MINING_STATES',
  /** MSB_AML_PROGRAM_DATE ... */
  MSB_AML_PROGRAM_DATE = 'MSB_AML_PROGRAM_DATE',
  /** MSB_LOCATION ... */
  MSB_LOCATION = 'MSB_LOCATION',
  /** MSB_REGISTRATION_AGENCY */
  MSB_REGISTRATION_AGENCY = 'MSB_REGISTRATION_AGENCY',
  /** MSB_REGISTRATION_DATE ... */
  MSB_REGISTRATION_DATE = 'MSB_REGISTRATION_DATE',
  /** MSB_REGISTRATION_NUMBER */
  MSB_REGISTRATION_NUMBER = 'MSB_REGISTRATION_NUMBER',
  /** MSB_TYPES ... */
  MSB_TYPES = 'MSB_TYPES',
  /** NAME ... */
  NAME = 'NAME',
  /** NATURE_OF_BUSINESS */
  NATURE_OF_BUSINESS = 'NATURE_OF_BUSINESS',
  /** NON_CUSTODIAL_WALLET_ADDRESSES */
  NON_CUSTODIAL_WALLET_ADDRESSES = 'NON_CUSTODIAL_WALLET_ADDRESSES',
  /** NON_MATERIAL_NEWS ... */
  NON_MATERIAL_NEWS = 'NON_MATERIAL_NEWS',
  /** NYC_OFFICE */
  NYC_OFFICE = 'NYC_OFFICE',
  /** OCCUPATION ... */
  OCCUPATION = 'OCCUPATION',
  /** OFFERING_DOCUMENT ... */
  OFFERING_DOCUMENT = 'OFFERING_DOCUMENT',
  /** OFFER_ALTERNATIVE_PAYMENT_METHOD ... */
  OFFER_ALTERNATIVE_PAYMENT_METHOD = 'OFFER_ALTERNATIVE_PAYMENT_METHOD',
  /** OFFER_CRYPTO_TRADE_TPF ... */
  OFFER_CRYPTO_TRADE_TPF = 'OFFER_CRYPTO_TRADE_TPF',
  /** ONBOARDING_API_SUBMISSION_ID */
  ONBOARDING_API_SUBMISSION_ID = 'ONBOARDING_API_SUBMISSION_ID',
  /** ONLY_DEALS_WITH_CLIENTS ... */
  ONLY_DEALS_WITH_CLIENTS = 'ONLY_DEALS_WITH_CLIENTS',
  /** ORGANIZATIONAL_OWNERSHIP_CHART */
  ORGANIZATIONAL_OWNERSHIP_CHART = 'ORGANIZATIONAL_OWNERSHIP_CHART',
  /** ORGANIZATIONAL_STRUCTURE_DOCUMENT */
  ORGANIZATIONAL_STRUCTURE_DOCUMENT = 'ORGANIZATIONAL_STRUCTURE_DOCUMENT',
  /** OUTSOURCED_ENTITY_INFORMATION */
  OUTSOURCED_ENTITY_INFORMATION = 'OUTSOURCED_ENTITY_INFORMATION',
  /** OUTSOURCE_AML_REQUIREMENTS */
  OUTSOURCE_AML_REQUIREMENTS = 'OUTSOURCE_AML_REQUIREMENTS',
  /** OWNERSHIP_CHART ... */
  OWNERSHIP_CHART = 'OWNERSHIP_CHART',
  /** OWNERSHIP_INDIVIDUAL_SSN_OR_PASSPORT_NO ... */
  OWNERSHIP_INDIVIDUAL_SSN_OR_PASSPORT_NO = 'OWNERSHIP_INDIVIDUAL_SSN_OR_PASSPORT_NO',
  /** OWNERSHIP_PERCENTAGE ... */
  OWNERSHIP_PERCENTAGE = 'OWNERSHIP_PERCENTAGE',
  /** PARTNERSHIP_AGREEMENT ... */
  PARTNERSHIP_AGREEMENT = 'PARTNERSHIP_AGREEMENT',
  /** PATRIOT_ACT_CERTIFICATION */
  PATRIOT_ACT_CERTIFICATION = 'PATRIOT_ACT_CERTIFICATION',
  /** PENSION_SUBFUNDS ... */
  PENSION_SUBFUNDS = 'PENSION_SUBFUNDS',
  /** PEP ... */
  PEP = 'PEP',
  /** PEP_INDIVIDUALS */
  PEP_INDIVIDUALS = 'PEP_INDIVIDUALS',
  /** PHONE ... */
  PHONE = 'PHONE',
  /** POOLED_VEHICLE ... */
  POOLED_VEHICLE = 'POOLED_VEHICLE',
  /** PRIMARY_ACCOUNT_OBJECTIVE ... */
  PRIMARY_ACCOUNT_OBJECTIVE = 'PRIMARY_ACCOUNT_OBJECTIVE',
  /** PRIMARY_ACCOUNT_OBJECTIVE_DEADLINE ... */
  PRIMARY_ACCOUNT_OBJECTIVE_DEADLINE = 'PRIMARY_ACCOUNT_OBJECTIVE_DEADLINE',
  /** PRINCIPAL_PLACE_OF_BUSINESS ... */
  PRINCIPAL_PLACE_OF_BUSINESS = 'PRINCIPAL_PLACE_OF_BUSINESS',
  /** PROGRAM_CUSTOMER_EMAIL ... */
  PROGRAM_CUSTOMER_EMAIL = 'PROGRAM_CUSTOMER_EMAIL',
  /** PROGRAM_CUSTOMER_NAME ... */
  PROGRAM_CUSTOMER_NAME = 'PROGRAM_CUSTOMER_NAME',
  /** PROGRAM_SIGNER_EMAIL ... */
  PROGRAM_SIGNER_EMAIL = 'PROGRAM_SIGNER_EMAIL',
  /** PROGRAM_SIGNER_FIRST_NAME ... */
  PROGRAM_SIGNER_FIRST_NAME = 'PROGRAM_SIGNER_FIRST_NAME',
  /** PROGRAM_SIGNER_GROUP ... */
  PROGRAM_SIGNER_GROUP = 'PROGRAM_SIGNER_GROUP',
  /** PROGRAM_SIGNER_LAST_NAME ... */
  PROGRAM_SIGNER_LAST_NAME = 'PROGRAM_SIGNER_LAST_NAME',
  /** PROOF_OF_ADDRESS ... */
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  /** PROOF_OF_IDENTITY ... */
  PROOF_OF_IDENTITY = 'PROOF_OF_IDENTITY',
  /** PUBLICLY_TRADED_STOCK_EXCHANGES ... */
  PUBLICLY_TRADED_STOCK_EXCHANGES = 'PUBLICLY_TRADED_STOCK_EXCHANGES',
  /** QC_ADDITIONAL_NOTES ... */
  QC_ADDITIONAL_NOTES = 'QC_ADDITIONAL_NOTES',
  /** QC_FINAL_CHECK ... */
  QC_FINAL_CHECK = 'QC_FINAL_CHECK',
  /** RECIPIENT_EMAIL ... */
  RECIPIENT_EMAIL = 'RECIPIENT_EMAIL',
  /** RECIPIENT_NAME ... */
  RECIPIENT_NAME = 'RECIPIENT_NAME',
  /** REGISTRATION_AGENCY ... */
  REGISTRATION_AGENCY = 'REGISTRATION_AGENCY',
  /** REGULATED_STATUS */
  REGULATED_STATUS = 'REGULATED_STATUS',
  /** RELATED_PARTY ... */
  RELATED_PARTY = 'RELATED_PARTY',
  /** RELATED_PARTY_GROUP ... */
  RELATED_PARTY_GROUP = 'RELATED_PARTY_GROUP',
  /** RELATED_PARTY_NAME ... */
  RELATED_PARTY_NAME = 'RELATED_PARTY_NAME',
  /** RELATED_PARTY_SCREENED ... */
  RELATED_PARTY_SCREENED = 'RELATED_PARTY_SCREENED',
  /** RELATED_PARTY_TYPE ... */
  RELATED_PARTY_TYPE = 'RELATED_PARTY_TYPE',
  /** RESTRICTED_ACTIVITIES */
  RESTRICTED_ACTIVITIES = 'RESTRICTED_ACTIVITIES',
  /** RESTRICTED_ACTIVITIES_ADULT_ENTERTAINMENT */
  RESTRICTED_ACTIVITIES_ADULT_ENTERTAINMENT = 'RESTRICTED_ACTIVITIES_ADULT_ENTERTAINMENT',
  /** RESTRICTED_ACTIVITIES_DRUGS */
  RESTRICTED_ACTIVITIES_DRUGS = 'RESTRICTED_ACTIVITIES_DRUGS',
  /** RESTRICTED_ACTIVITIES_FIREARMS */
  RESTRICTED_ACTIVITIES_FIREARMS = 'RESTRICTED_ACTIVITIES_FIREARMS',
  /** RESTRICTED_ACTIVITIES_GAMBLING */
  RESTRICTED_ACTIVITIES_GAMBLING = 'RESTRICTED_ACTIVITIES_GAMBLING',
  /** RESTRICTED_ACTIVITIES_MARIJUANA */
  RESTRICTED_ACTIVITIES_MARIJUANA = 'RESTRICTED_ACTIVITIES_MARIJUANA',
  /** RESTRICTED_ACTIVITIES_NONE */
  RESTRICTED_ACTIVITIES_NONE = 'RESTRICTED_ACTIVITIES_NONE',
  /** RESTRICTED_ACTIVITIES_TUMBLING */
  RESTRICTED_ACTIVITIES_TUMBLING = 'RESTRICTED_ACTIVITIES_TUMBLING',
  /** REVENUE_PERCENTAGE ... */
  REVENUE_PERCENTAGE = 'REVENUE_PERCENTAGE',
  /** REVENUE_SHARE_DOMESTIC ... */
  REVENUE_SHARE_DOMESTIC = 'REVENUE_SHARE_DOMESTIC',
  /** RIA_IND_AFFILIATE_ONBOARDING_API_SUBMISSION_ID ... */
  RIA_IND_AFFILIATE_ONBOARDING_API_SUBMISSION_ID = 'RIA_IND_AFFILIATE_ONBOARDING_API_SUBMISSION_ID',
  /** RIA_INST_AFFILIATE_ONBOARDING_API_SUBMISSION_ID ... */
  RIA_INST_AFFILIATE_ONBOARDING_API_SUBMISSION_ID = 'RIA_INST_AFFILIATE_ONBOARDING_API_SUBMISSION_ID',
  /** RP_INCORPORATION_DATE ... */
  RP_INCORPORATION_DATE = 'RP_INCORPORATION_DATE',
  /** RP_INCORPORATION_PLACE ... */
  RP_INCORPORATION_PLACE = 'RP_INCORPORATION_PLACE',
  /** RP_INVESTMENT_ADVISOR_NUMBER ... */
  RP_INVESTMENT_ADVISOR_NUMBER = 'RP_INVESTMENT_ADVISOR_NUMBER',
  /** SERVICE_TYPE_TO_TRANSACT_WITH */
  SERVICE_TYPE_TO_TRANSACT_WITH = 'SERVICE_TYPE_TO_TRANSACT_WITH',
  /** SETTLOR ... */
  SETTLOR = 'SETTLOR',
  /** SETTLORS_GROUP ... */
  SETTLORS_GROUP = 'SETTLORS_GROUP',
  /** SFPF_INDIVIDUALS ... */
  SFPF_INDIVIDUALS = 'SFPF_INDIVIDUALS',
  /** SGD_DEPOSIT_AMOUNT ... */
  SGD_DEPOSIT_AMOUNT = 'SGD_DEPOSIT_AMOUNT',
  /** SGD_WITHDRAWAL_AMOUNT ... */
  SGD_WITHDRAWAL_AMOUNT = 'SGD_WITHDRAWAL_AMOUNT',
  /** SHAREHOLDING_STRUCTURE ... */
  SHAREHOLDING_STRUCTURE = 'SHAREHOLDING_STRUCTURE',
  /** SHARES_HELD_PERCENT ... */
  SHARES_HELD_PERCENT = 'SHARES_HELD_PERCENT',
  /** SIGNATURE ... */
  SIGNATURE = 'SIGNATURE',
  /** SIGNED_TERMS_AGREEMENT_DOCUMENT ... */
  SIGNED_TERMS_AGREEMENT_DOCUMENT = 'SIGNED_TERMS_AGREEMENT_DOCUMENT',
  /** SOURCE_OF_FUNDS ... */
  SOURCE_OF_FUNDS = 'SOURCE_OF_FUNDS',
  /** SOURCE_OF_WEALTH */
  SOURCE_OF_WEALTH = 'SOURCE_OF_WEALTH',
  /** SOURCE_OF_WEALTH_DESCRIPTION ... */
  SOURCE_OF_WEALTH_DESCRIPTION = 'SOURCE_OF_WEALTH_DESCRIPTION',
  /** SPLIT_OF_TOTAL_TRANSACTION ... */
  SPLIT_OF_TOTAL_TRANSACTION = 'SPLIT_OF_TOTAL_TRANSACTION',
  /** SPONSOR_CLIENT_TYPE ... */
  SPONSOR_CLIENT_TYPE = 'SPONSOR_CLIENT_TYPE',
  /** State */
  STATE = 'STATE',
  /** STATEMENTS_PREFERENCE ... */
  STATEMENTS_PREFERENCE = 'STATEMENTS_PREFERENCE',
  /** STREET_ADDRESS */
  STREET_ADDRESS = 'STREET_ADDRESS',
  /** STREET_ADDRESS_SECOND ... */
  STREET_ADDRESS_SECOND = 'STREET_ADDRESS_SECOND',
  /** SUSPICIOUS_ACTIVITY ... */
  SUSPICIOUS_ACTIVITY = 'SUSPICIOUS_ACTIVITY',
  /** TAX_EXEMPTION_CERT ... */
  TAX_EXEMPTION_CERT = 'TAX_EXEMPTION_CERT',
  /** TERMS_AGREEMENT_ID ... */
  TERMS_AGREEMENT_ID = 'TERMS_AGREEMENT_ID',
  /** TICKER_SYMBOL ... */
  TICKER_SYMBOL = 'TICKER_SYMBOL',
  /** TITLE ... */
  TITLE = 'TITLE',
  /** TOKEN_TYPE ... */
  TOKEN_TYPE = 'TOKEN_TYPE',
  /** TOP_FIVE_DEPOSIT_WALLET ... */
  TOP_FIVE_DEPOSIT_WALLET = 'TOP_FIVE_DEPOSIT_WALLET',
  /** TOP_FIVE_DEPOSIT_WALLET_ADDRESS ... */
  TOP_FIVE_DEPOSIT_WALLET_ADDRESS = 'TOP_FIVE_DEPOSIT_WALLET_ADDRESS',
  /** TOP_FIVE_DEPOSIT_WALLET_NAME ... */
  TOP_FIVE_DEPOSIT_WALLET_NAME = 'TOP_FIVE_DEPOSIT_WALLET_NAME',
  /** TOP_FIVE_WITHDRAWAL_WALLET ... */
  TOP_FIVE_WITHDRAWAL_WALLET = 'TOP_FIVE_WITHDRAWAL_WALLET',
  /** TOP_FIVE_WITHDRAWAL_WALLET_ADDRESS ... */
  TOP_FIVE_WITHDRAWAL_WALLET_ADDRESS = 'TOP_FIVE_WITHDRAWAL_WALLET_ADDRESS',
  /** TOP_FIVE_WITHDRAWAL_WALLET_NAME ... */
  TOP_FIVE_WITHDRAWAL_WALLET_NAME = 'TOP_FIVE_WITHDRAWAL_WALLET_NAME',
  /** TOTAL_ASSETS_MANAGED */
  TOTAL_ASSETS_MANAGED = 'TOTAL_ASSETS_MANAGED',
  /** TOTAL_TRANSACTION_VALUE ... */
  TOTAL_TRANSACTION_VALUE = 'TOTAL_TRANSACTION_VALUE',
  /** TOTAL_VALUE_ASSETS_UNDER_CUSTODY ... */
  TOTAL_VALUE_ASSETS_UNDER_CUSTODY = 'TOTAL_VALUE_ASSETS_UNDER_CUSTODY',
  /** TRADING_ACTIVITY_JURISDICTION */
  TRADING_ACTIVITY_JURISDICTION = 'TRADING_ACTIVITY_JURISDICTION',
  /** TRADING_FREQUENCY_PER_MONTH */
  TRADING_FREQUENCY_PER_MONTH = 'TRADING_FREQUENCY_PER_MONTH',
  /** TRANSACTIONS_MONTHLY_IN_SGD ... */
  TRANSACTIONS_MONTHLY_IN_SGD = 'TRANSACTIONS_MONTHLY_IN_SGD',
  /** TRANSACTIONS_MONTHLY_IN_USD */
  TRANSACTIONS_MONTHLY_IN_USD = 'TRANSACTIONS_MONTHLY_IN_USD',
  /** TRANSACTION_FREQUENCY */
  TRANSACTION_FREQUENCY = 'TRANSACTION_FREQUENCY',
  /** TRANSACT_AT_FUND_OR_INVESTMENT_MANAGER_LEVEL ... */
  TRANSACT_AT_FUND_OR_INVESTMENT_MANAGER_LEVEL = 'TRANSACT_AT_FUND_OR_INVESTMENT_MANAGER_LEVEL',
  /** TRANSFERING_METHOD ... */
  TRANSFERING_METHOD = 'TRANSFERING_METHOD',
  /** TRANSFERRING_METHOD */
  TRANSFERRING_METHOD = 'TRANSFERRING_METHOD',
  /** TRANSFERRING_METHOD_DIGITAL_ASSETS */
  TRANSFERRING_METHOD_DIGITAL_ASSETS = 'TRANSFERRING_METHOD_DIGITAL_ASSETS',
  /** TRANSFERRING_METHOD_OTHER ... */
  TRANSFERRING_METHOD_OTHER = 'TRANSFERRING_METHOD_OTHER',
  /** TRANSFERRING_METHOD_SEN */
  TRANSFERRING_METHOD_SEN = 'TRANSFERRING_METHOD_SEN',
  /** TRANSFERRING_METHOD_WIRE */
  TRANSFERRING_METHOD_WIRE = 'TRANSFERRING_METHOD_WIRE',
  /** TRUSTEES_GROUP ... */
  TRUSTEES_GROUP = 'TRUSTEES_GROUP',
  /** TRUSTEE_GROUP ... */
  TRUSTEE_GROUP = 'TRUSTEE_GROUP',
  /** TRUST_AGREEMENT ... */
  TRUST_AGREEMENT = 'TRUST_AGREEMENT',
  /** TYPE_CUSTOMERS ... */
  TYPE_CUSTOMERS = 'TYPE_CUSTOMERS',
  /** TYPE_CUSTOMERS_MSB ... */
  TYPE_CUSTOMERS_MSB = 'TYPE_CUSTOMERS_MSB',
  /** TYPE_OF_BUSINESS ... */
  TYPE_OF_BUSINESS = 'TYPE_OF_BUSINESS',
  /** TYPE_OF_BUSINESS_SECOND ... */
  TYPE_OF_BUSINESS_SECOND = 'TYPE_OF_BUSINESS_SECOND',
  /** TYPE_OF_CUSTOMER */
  TYPE_OF_CUSTOMER = 'TYPE_OF_CUSTOMER',
  /** TYPE_OF_CUSTOMER_OTHER */
  TYPE_OF_CUSTOMER_OTHER = 'TYPE_OF_CUSTOMER_OTHER',
  /** TYPE_OF_CUSTOMER_SECOND ... */
  TYPE_OF_CUSTOMER_SECOND = 'TYPE_OF_CUSTOMER_SECOND',
  /** TYPE_OF_PIV ... */
  TYPE_OF_PIV = 'TYPE_OF_PIV',
  /** ULTIMATE_OWNERSHIP_PERSON ... */
  ULTIMATE_OWNERSHIP_PERSON = 'ULTIMATE_OWNERSHIP_PERSON',
  /** USAGE_OF_TRADING_PROCEEDS */
  USAGE_OF_TRADING_PROCEEDS = 'USAGE_OF_TRADING_PROCEEDS',
  /** USD_DEPOSIT_AMOUNT ... */
  USD_DEPOSIT_AMOUNT = 'USD_DEPOSIT_AMOUNT',
  /** USD_DEPOSIT_AMOUNT_PER_MONTH */
  USD_DEPOSIT_AMOUNT_PER_MONTH = 'USD_DEPOSIT_AMOUNT_PER_MONTH',
  /** USD_WITHDRAWAL_AMOUNT ... */
  USD_WITHDRAWAL_AMOUNT = 'USD_WITHDRAWAL_AMOUNT',
  /** USD_WITHDRAWAL_AMOUNT_PER_MONTH */
  USD_WITHDRAWAL_AMOUNT_PER_MONTH = 'USD_WITHDRAWAL_AMOUNT_PER_MONTH',
  /** VENDORS_AND_COUNTERPARTIES_COUNTRY_LIST */
  VENDORS_AND_COUNTERPARTIES_COUNTRY_LIST = 'VENDORS_AND_COUNTERPARTIES_COUNTRY_LIST',
  /** VENDORS_AND_COUNTERPARTIES_LIST */
  VENDORS_AND_COUNTERPARTIES_LIST = 'VENDORS_AND_COUNTERPARTIES_LIST',
  /** VENDOR_AND_COUNTERPARTY_COUNTRY_LIST ... */
  VENDOR_AND_COUNTERPARTY_COUNTRY_LIST = 'VENDOR_AND_COUNTERPARTY_COUNTRY_LIST',
  /** VENDOR_AND_COUNTERPARTY_LIST ... */
  VENDOR_AND_COUNTERPARTY_LIST = 'VENDOR_AND_COUNTERPARTY_LIST',
  /** VERIFICATION_BENEFICIAL_OWNER_COUNT */
  VERIFICATION_BENEFICIAL_OWNER_COUNT = 'VERIFICATION_BENEFICIAL_OWNER_COUNT',
  /** VERIFICATION_CONTROL_PERSON_COUNT */
  VERIFICATION_CONTROL_PERSON_COUNT = 'VERIFICATION_CONTROL_PERSON_COUNT',
  /** VERIFY_BENEFICIAL_OWNERS ... */
  VERIFY_BENEFICIAL_OWNERS = 'VERIFY_BENEFICIAL_OWNERS',
  /** W8_BEN_FORM ... */
  W8_BEN_FORM = 'W8_BEN_FORM',
  /** W8_OR_W9 ... */
  W8_OR_W9 = 'W8_OR_W9',
  /** W9_BEN_FORM ... */
  W9_BEN_FORM = 'W9_BEN_FORM',
  /** W9_TAX_DOCUMENT ... */
  W9_TAX_DOCUMENT = 'W9_TAX_DOCUMENT',
  /** WHITE_PAPER */
  WHITE_PAPER = 'WHITE_PAPER',
  /** WILL_CONDUCT_INTERNATIONAL_WIRE */
  WILL_CONDUCT_INTERNATIONAL_WIRE = 'WILL_CONDUCT_INTERNATIONAL_WIRE',
  /** WOLFSBERG_QUESTIONNAIRE */
  WOLFSBERG_QUESTIONNAIRE = 'WOLFSBERG_QUESTIONNAIRE',
  /** Zip code */
  ZIP_CODE = 'ZIP_CODE'
}

/** AffiliateStatus represents the status of the affiliate */
export enum AffiliateStatus {
  /** CLOSED represents a closed affiliate */
  CLOSED = 'CLOSED',
  /** OPEN represents an open (non-closed) affiliate */
  OPEN = 'OPEN'
}

/** Specifies the type of affiliate */
export enum AffiliateType {
  /** All other affiliate types. */
  ANCHORAGE_INSTITUTION = 'ANCHORAGE_INSTITUTION',
  /** INVESTMENT_MANAGER is the affiliate type fund managers */
  INVESTMENT_MANAGER = 'INVESTMENT_MANAGER',
  /** MANAGED_FUND is the affiliate type for funds managed by an investment fund */
  MANAGED_FUND = 'MANAGED_FUND',
  /** Affiliate that is an RIA parent affiliate. */
  REGISTERED_INVESTMENT_ADVISOR = 'REGISTERED_INVESTMENT_ADVISOR',
  /** Affiliate that is an RIA individual. */
  RIA_PC_INDIVIDUAL = 'RIA_PC_INDIVIDUAL',
  /** Affiliate that is an RIA institution. */
  RIA_PC_INSTITUTION = 'RIA_PC_INSTITUTION'
}

/** Properties by which AffiliateV2 connections can be ordered. */
export enum AffiliateV2OrderField {
  NAME = 'NAME'
}

/** AggregationFilterType represents the time frame aggregation for a dataset */
export enum AggregationFilterType {
  /** Data will be aggregated per day */
  DAY = 'DAY',
  /** Data will be aggregated per month */
  MONTH = 'MONTH',
  /** Data will be aggregated per week */
  WEEK = 'WEEK'
}

/** AlertStatus are the possible states for compliance alerts */
export enum AlertStatus {
  /** CANCELED an alert that has been canceled */
  CANCELED = 'CANCELED',
  /** ESCALATED_TO_CASE an alert that has been escalated to a case */
  ESCALATED_TO_CASE = 'ESCALATED_TO_CASE',
  /** IN_REVIEW the alert is under review */
  IN_REVIEW = 'IN_REVIEW',
  /** MANAGER_REVIEW -the alert is under management review */
  MANAGER_REVIEW = 'MANAGER_REVIEW',
  /** NEW represents a new alert in Jira */
  NEW = 'NEW',
  /** PENDING represents a pending alert in Jira */
  PENDING = 'PENDING',
  /** QC_REVIEW is a alert where QC is reviewing */
  QC_REVIEW = 'QC_REVIEW',
  /** READY_QC is a alert ready for QC */
  READY_QC = 'READY_QC',
  /** RESOLVED an alert that has resolved */
  RESOLVED = 'RESOLVED'
}

/** AlertType are the possible types of jira alerts */
export enum AlertType {
  /** ADHOC_KYC represents an alert manually created via centralized client view */
  ADHOC_KYC = 'ADHOC_KYC',
  /** ATTRIBUTION_MONITORING_MISMATCH represents the attribution monitoring mismatch alert type */
  ATTRIBUTION_MONITORING_MISMATCH = 'ATTRIBUTION_MONITORING_MISMATCH',
  /** DORMANT_ACCOUNT represents the dormant account TM alert type */
  DORMANT_ACCOUNT = 'DORMANT_ACCOUNT',
  /** FIAT_FLOW_THROUGH represents the dormant account TM alert type */
  FIAT_FLOW_THROUGH = 'FIAT_FLOW_THROUGH',
  /** FIAT_HIGH_RISK_JURISDICTION represents the dormant account TM alert type */
  FIAT_HIGH_RISK_JURISDICTION = 'FIAT_HIGH_RISK_JURISDICTION',
  /** FIAT_HIGH_VALUE represents the dormant account TM alert type */
  FIAT_HIGH_VALUE = 'FIAT_HIGH_VALUE',
  /** FIAT_HIGH_VOLUME represents the dormant account TM alert type */
  FIAT_HIGH_VOLUME = 'FIAT_HIGH_VOLUME',
  /** FIAT_REPORTING_EVASION represents the dormant account TM alert type */
  FIAT_REPORTING_EVASION = 'FIAT_REPORTING_EVASION',
  /** FLOW_THROUGH represents the flow through alert type */
  FLOW_THROUGH = 'FLOW_THROUGH',
  /** FULL_KYC_REVIEW represents and alert generated by crr model, period refresh, initial onboarding, ownership change, or manual review */
  FULL_KYC_REVIEW = 'FULL_KYC_REVIEW',
  /** FUND_CLIENTS represents the fund clients TM alert type */
  FUND_CLIENTS = 'FUND_CLIENTS',
  /** HIGH_RISK_JURISDICTION represents the hrj alert type */
  HIGH_RISK_JURISDICTION = 'HIGH_RISK_JURISDICTION',
  /** HIGH_VOLUME_CLIENTS represents the high volume clients TM alert type */
  HIGH_VOLUME_CLIENTS = 'HIGH_VOLUME_CLIENTS',
  /** HISTORICAL_DEVIATIONS represents the historical deviations TM alert type */
  HISTORICAL_DEVIATIONS = 'HISTORICAL_DEVIATIONS',
  /** HUB_SPOKE_MULTI represents the hub and spoke multi alert type */
  HUB_SPOKE_MULTI = 'HUB_SPOKE_MULTI',
  /** HUB_SPOKE_SINGLE represents the hub and spoke single alert type */
  HUB_SPOKE_SINGLE = 'HUB_SPOKE_SINGLE',
  /** KYC_CRR_UPDATE represents an alert manually created via centralized client view */
  KYC_CRR_UPDATE = 'KYC_CRR_UPDATE',
  /** LOW_ACTIVITY_CLIENTS represents the low activity clients TM alert type */
  LOW_ACTIVITY_CLIENTS = 'LOW_ACTIVITY_CLIENTS',
  /** MINING_CLIENTS represents the mining clients TM alert type */
  MINING_CLIENTS = 'MINING_CLIENTS',
  /** NBFI_CLIENTS represents the nbfi clients TM alert type */
  NBFI_CLIENTS = 'NBFI_CLIENTS',
  /** NEGATIVE_NEWS represents the negative news issue type */
  NEGATIVE_NEWS = 'NEGATIVE_NEWS',
  /** NEGATIVE_NEWS_SCREENING represents the negative news screening alert type */
  NEGATIVE_NEWS_SCREENING = 'NEGATIVE_NEWS_SCREENING',
  /** OFAC_REPORTING represents the ofac reporting issue type */
  OFAC_REPORTING = 'OFAC_REPORTING',
  /** OFAC_REPORTING_SCREENING represents the ofac reporting screening alert type */
  OFAC_REPORTING_SCREENING = 'OFAC_REPORTING_SCREENING',
  /** PEP represents the pep issue type */
  PEP = 'PEP',
  /** PEP_SCREENING represents the pep screening alert type */
  PEP_SCREENING = 'PEP_SCREENING',
  /** REFERRAL represents the referral issue type */
  REFERRAL = 'REFERRAL',
  /** REPORTING_EVASION represents the TM reporting evasion alert type */
  REPORTING_EVASION = 'REPORTING_EVASION',
  /** UNHOSTED_WALLET represents the unhosted wallet alert type */
  UNHOSTED_WALLET = 'UNHOSTED_WALLET'
}

/** Different types of Anchorage Entities */
export enum AnchorageEntityType {
  /** A1 */
  A1 = 'A1',
  /** AURORA */
  AURORA = 'AURORA',
  /** Anchorage Hold */
  HOLD = 'HOLD',
  /** Singapore */
  SINGAPORE = 'SINGAPORE',
  /** Anchorage Trust Company */
  TRUST_COMPANY = 'TRUST_COMPANY'
}

/**
 * AppStatus is the simplified, app-facing state of the operation as it is processed by our system
 * These are not code-gen'd right now but they really should be
 */
export enum AppStatus {
  /** AWAITING_DEPOSIT is when the deposit address is ready and the user needs to deposit funds */
  AWAITING_DEPOSIT = 'AWAITING_DEPOSIT',
  /** CANCELED is the state when the operation has been canceled. */
  CANCELED = 'CANCELED',
  /** COMPLETE is the state when the action is complete */
  COMPLETE = 'COMPLETE',
  /** COMPLETING is when a relevant transaction is on the blockchain but not yet fully confirmed */
  COMPLETING = 'COMPLETING',
  /** EXPIRED is the state when the action has timed out */
  EXPIRED = 'EXPIRED',
  /** FAILED is the state when action could not be completed due to an error */
  FAILED = 'FAILED',
  /** AppStatusFailureCommitted represents an operation that has failed on the blockchain */
  FAILURE_COMMITTED = 'FAILURE_COMMITTED',
  /** GATHERING_APPROVALS is when the user already endorsed the operation but other singatures are being collected */
  GATHERING_APPROVALS = 'GATHERING_APPROVALS',
  /** GENERATING_ADDRESS is when the deposit address is being generated */
  GENERATING_ADDRESS = 'GENERATING_ADDRESS',
  /** IN_REVIEW is when the action is in risk review but can still be canceled */
  IN_REVIEW = 'IN_REVIEW',
  /** NEEDS_APPROVAL is when the action is collecting signatures for quorum */
  NEEDS_APPROVAL = 'NEEDS_APPROVAL',
  /** PREPARING is when the action has been created in the request state but is not ready to be signed */
  PREPARING = 'PREPARING',
  /** PROCESSING is when the organization change is post-risk review but not yet complete */
  PROCESSING = 'PROCESSING',
  /** REJECTED is the state when the customer or risk team has rejected the operation */
  REJECTED = 'REJECTED',
  /** SENDING is when the withdraw is post-risk review but not yet complete */
  SENDING = 'SENDING',
  /** WAITING_FOR_INITIATOR is the state when the action is staged, before being created */
  WAITING_FOR_INITIATOR = 'WAITING_FOR_INITIATOR'
}

/** AssetEnablementStatus is the enablement status of an asset type for an entity or organization */
export enum AssetEnablementStatus {
  /** DISABLED means the asset type is disabled for the entity or organization */
  DISABLED = 'DISABLED',
  /** ENABLED means the asset type is enabled for the entity or organization */
  ENABLED = 'ENABLED',
  /** UNSPECIFIED is the default value for the enum */
  UNSPECIFIED = 'UNSPECIFIED'
}

/** Properties by which AssetReferenceData connections can be ordered. */
export enum AssetReferenceDataOrderField {
  CREATED_AT = 'CREATED_AT',
  RATING = 'RATING'
}

/** AssetReferenceDataRating is enum for the field rating */
export enum AssetReferenceDataRating {
  ASSET_REFERENCE_DATA_RATING_A = 'ASSET_REFERENCE_DATA_RATING_A',
  ASSET_REFERENCE_DATA_RATING_AA = 'ASSET_REFERENCE_DATA_RATING_AA',
  ASSET_REFERENCE_DATA_RATING_AAA = 'ASSET_REFERENCE_DATA_RATING_AAA',
  ASSET_REFERENCE_DATA_RATING_B = 'ASSET_REFERENCE_DATA_RATING_B',
  ASSET_REFERENCE_DATA_RATING_C = 'ASSET_REFERENCE_DATA_RATING_C',
  ASSET_REFERENCE_DATA_RATING_UNSPECIFIED = 'ASSET_REFERENCE_DATA_RATING_UNSPECIFIED'
}

/** Properties by which AssetSpec connections can be ordered. */
export enum AssetSpecOrderField {
  BASE_SYMBOL = 'BASE_SYMBOL',
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  STATUS = 'STATUS',
  SYMBOL = 'SYMBOL',
  TYPE_ID = 'TYPE_ID'
}

/** AssetSpecStatus is enum for the field status */
export enum AssetSpecStatus {
  ASSET_SPEC_STATUS_DISABLED = 'ASSET_SPEC_STATUS_DISABLED',
  ASSET_SPEC_STATUS_ENABLED = 'ASSET_SPEC_STATUS_ENABLED',
  ASSET_SPEC_STATUS_UNSPECIFIED = 'ASSET_SPEC_STATUS_UNSPECIFIED'
}

/** AssetSpecTypeID is enum for the field type_id */
export enum AssetSpecTypeID {
  ASSET_SPEC_TYPE_ID_DIGITAL_ASSET = 'ASSET_SPEC_TYPE_ID_DIGITAL_ASSET',
  ASSET_SPEC_TYPE_ID_MINING_EQUIPMENT = 'ASSET_SPEC_TYPE_ID_MINING_EQUIPMENT',
  ASSET_SPEC_TYPE_ID_SECURITY = 'ASSET_SPEC_TYPE_ID_SECURITY',
  ASSET_SPEC_TYPE_ID_UNSPECIFIED = 'ASSET_SPEC_TYPE_ID_UNSPECIFIED'
}

/** AvailableForPurpose represents available balance for a certain purpose */
export enum AvailableForPurpose {
  /** CLAIM is for claiming from a Vesting Contract */
  CLAIM = 'CLAIM',
  /** DELEGATE is for delegation operation delegate type */
  DELEGATE = 'DELEGATE',
  /** LOCK is for lock operation */
  LOCK = 'LOCK',
  /** UNLOCK is for unlock operation */
  UNLOCK = 'UNLOCK',
  /** VOTE is for vote operation */
  VOTE = 'VOTE',
  /** WITHDRAW is for withdraw operation */
  WITHDRAW = 'WITHDRAW'
}

/** This list loosely corresponds to user types in lib/auth/user.go but groups together into categories. */
export enum BackendUserType {
  /** Corresponds to a user who is going through the KYC process. */
  KYC_USER = 'KYC_USER',
  /** Corresponds to a user who is associated with an existing organization. */
  ORG_USER = 'ORG_USER',
  /** Corresponds to StaffUser type defined in user.go. */
  STAFF = 'STAFF'
}

/** Represents the type of balance of a transaction entry */
export enum BalanceType {
  /** Represents balance that cannot be used (HOLD) */
  HOLD = 'HOLD',
  /** Represents peding balance of a transaction */
  PENDING = 'PENDING',
  /** Represents type POSTED */
  POSTED = 'POSTED'
}

/** Possible states of bluetooth peripherals */
export enum BluetoothPeripheralState {
  /** Peripheral is connected */
  CONNECTED = 'CONNECTED',
  /** Peripheral is connecting */
  CONNECTING = 'CONNECTING',
  /** Peripheral is disconnected */
  DISCONNECTED = 'DISCONNECTED',
  /** Peripheral is disconnecting */
  DISCONNECTING = 'DISCONNECTING',
  /** We failed to parse the status */
  UNKNOWN = 'UNKNOWN'
}

/** Possible bluetooth states */
export enum BluetoothState {
  /** The capability is OFF */
  POWEREDOFF = 'POWEREDOFF',
  /** The capability is ON */
  POWEREDON = 'POWEREDON',
  /** Bluetooth is resetting */
  RESETTING = 'RESETTING',
  /** Not allowed to use this capability */
  UNAUTHORIZED = 'UNAUTHORIZED',
  /** Unknown state of the capability */
  UNKNOWN = 'UNKNOWN',
  /** Device does not support this capability */
  UNSUPPORTED = 'UNSUPPORTED'
}

/** BookType indicates the order type */
export enum BookType {
  /** Buy order type */
  BUY = 'BUY',
  /** Sell order type */
  SELL = 'SELL'
}

/** BulkTrustedDestinationItemErrorLevel is the severity. Warnings are non-blocking, errors are blocking */
export enum BulkTrustedDestinationItemErrorLevel {
  /** ERROR denotes an error */
  ERROR = 'ERROR',
  /** UNKNOWN denotes an unknown error level */
  UNKNOWN = 'UNKNOWN',
  /** WARNING denotes a warning */
  WARNING = 'WARNING'
}

/** BulkTrustedSmartContractItemErrorLevel is the severity. Warnings are non-blocking, errors are blocking */
export enum BulkTrustedSmartContractItemErrorLevel {
  /** ERROR denotes an error. */
  ERROR = 'ERROR',
  /** UNKNOWN denotes an unknown error level. */
  UNKNOWN = 'UNKNOWN',
  /** WARNING denotes a warning. */
  WARNING = 'WARNING'
}

/** Properties by which CCRValuationCollateralDetails connections can be ordered. */
export enum CCRValuationCollateralDetailsOrderField {
  CREATED_AT = 'CREATED_AT'
}

/** Properties by which CCRValuationExposureDetails connections can be ordered. */
export enum CCRValuationExposureDetailsOrderField {
  CREATED_AT = 'CREATED_AT'
}

/** Properties by which CCRValuations connections can be ordered. */
export enum CCRValuationsOrderField {
  CREATED_AT = 'CREATED_AT',
  RISK_STATUS = 'RISK_STATUS',
  VALUATION_TIMESTAMP = 'VALUATION_TIMESTAMP'
}

/** CCRValuationsRiskStatus is enum for the field risk_status */
export enum CCRValuationsRiskStatus {
  RISK_STATUS_HEALTHY = 'RISK_STATUS_HEALTHY',
  RISK_STATUS_LIQUIDATION = 'RISK_STATUS_LIQUIDATION',
  RISK_STATUS_MARGINCALL = 'RISK_STATUS_MARGINCALL',
  RISK_STATUS_NOEXPOSURE = 'RISK_STATUS_NOEXPOSURE',
  RISK_STATUS_PREMARGINCALL = 'RISK_STATUS_PREMARGINCALL',
  RISK_STATUS_UNSPECIFIED = 'RISK_STATUS_UNSPECIFIED'
}

/** CRRScoreOutcome is the model for CRR categorical outcome */
export enum CRRScoreOutcome {
  /** HIGH is the outcome for HIGH risk */
  HIGH = 'HIGH',
  /** LOW is the outcome for LOW risk */
  LOW = 'LOW',
  /** MEDIUM is the outcome for MEDIUM risk */
  MEDIUM = 'MEDIUM'
}

/** CRRSource is the model for CRR source */
export enum CRRSource {
  /**
   * ACCEPTED is the approved CRR either copied from dynamic or
   * overridden by a user
   */
  ACCEPTED = 'ACCEPTED',
  /** DYNAMIC is for Dynamic CRR generated by risk models */
  DYNAMIC = 'DYNAMIC'
}

/** CaseStatus are the possible states for compliance cases */
export enum CaseStatus {
  /** BLOCKED_PROPERTY_OR_REJECTED_TRANSACTIONS_REPORT_FILED is a case where blocked property or rejected tx is filed */
  BLOCKED_PROPERTY_OR_REJECTED_TRANSACTIONS_REPORT_FILED = 'BLOCKED_PROPERTY_OR_REJECTED_TRANSACTIONS_REPORT_FILED',
  /** BLOCKED_PROPERTY_OR_REJECTED_TRANSACTIONS_REPORT_IN_PROGRESS is a case where blocked property or rejected tx is in progress */
  BLOCKED_PROPERTY_OR_REJECTED_TRANSACTIONS_REPORT_IN_PROGRESS = 'BLOCKED_PROPERTY_OR_REJECTED_TRANSACTIONS_REPORT_IN_PROGRESS',
  /** CANCELED the case was canceled */
  CANCELED = 'CANCELED',
  /** CLIENT_APPROVED - Full KYC Review Case */
  CLIENT_APPROVED = 'CLIENT_APPROVED',
  /** ESCALATED_TO_BSAO is a case escalated to BSAO */
  ESCALATED_TO_BSAO = 'ESCALATED_TO_BSAO',
  /** ESCALATED_TO_BSAO_FIRST_CAR is a case escalated to BSAO for the first CAR */
  ESCALATED_TO_BSAO_FIRST_CAR = 'ESCALATED_TO_BSAO_FIRST_CAR',
  /** ESCALATED_TO_BSAO_SACTIONS_OFFICER is a case escalated to BSAO / Sanctions Officer */
  ESCALATED_TO_BSAO_SACTIONS_OFFICER = 'ESCALATED_TO_BSAO_SACTIONS_OFFICER',
  /** ESCALATED_TO_BSAO_SECOND_CAR is a case escalated to BSAO for the second CAR */
  ESCALATED_TO_BSAO_SECOND_CAR = 'ESCALATED_TO_BSAO_SECOND_CAR',
  /** ESCALATED_TO_COMPLIANCE is a case escalated to the compliance team */
  ESCALATED_TO_COMPLIANCE = 'ESCALATED_TO_COMPLIANCE',
  /** FALSE_POSITIVE - NNS, PEP */
  FALSE_POSITIVE = 'FALSE_POSITIVE',
  /** FIRST_CAR_TRIGGERED is a case that triggered it's first CAR */
  FIRST_CAR_TRIGGERED = 'FIRST_CAR_TRIGGERED',
  /** HIGH_RISK_PEP - PEP */
  HIGH_RISK_PEP = 'HIGH_RISK_PEP',
  /** IMMATERIAL_PEP - PEP */
  IMMATERIAL_PEP = 'IMMATERIAL_PEP',
  /** INITIATE_OFFBOARDING_REVIEW - Full KYC Review Case */
  INITIATE_OFFBOARDING_REVIEW = 'INITIATE_OFFBOARDING_REVIEW',
  /** IN_COMPLIANCE_ESCALATION_BSAO_REVIEW is a case in compliance escalation BSAO review */
  IN_COMPLIANCE_ESCALATION_BSAO_REVIEW = 'IN_COMPLIANCE_ESCALATION_BSAO_REVIEW',
  /** IN_COMPLIANCE_ESCALATION_REVIEW is a case in compliance escalation review */
  IN_COMPLIANCE_ESCALATION_REVIEW = 'IN_COMPLIANCE_ESCALATION_REVIEW',
  /** IN_REVIEW the case is under review */
  IN_REVIEW = 'IN_REVIEW',
  /** IN_REVIEW_FIRST_CAR is a case in review for it's first CAR (Continiuos Activity Review) */
  IN_REVIEW_FIRST_CAR = 'IN_REVIEW_FIRST_CAR',
  /** IN_REVIEW_SECOND_CAR is a case where the second CAR is in review */
  IN_REVIEW_SECOND_CAR = 'IN_REVIEW_SECOND_CAR',
  /** MANAGER_REVIEW -the case is under management review */
  MANAGER_REVIEW = 'MANAGER_REVIEW',
  /** MANAGER_REVIEW_FIRST_CAR is a case pending a Manager's review of the first CAR */
  MANAGER_REVIEW_FIRST_CAR = 'MANAGER_REVIEW_FIRST_CAR',
  /** MANAGER_REVIEW_SECOND_CAR is a case where the manager is reviewing the second CAR */
  MANAGER_REVIEW_SECOND_CAR = 'MANAGER_REVIEW_SECOND_CAR',
  /** MATERIAL_HIT - NNS */
  MATERIAL_HIT = 'MATERIAL_HIT',
  /** NEW represents a new case in Jira */
  NEW = 'NEW',
  /** NON_MATERIAL_HIT - NNS */
  NON_MATERIAL_HIT = 'NON_MATERIAL_HIT',
  /** NO_FILING - OFAC Reporting */
  NO_FILING = 'NO_FILING',
  /** PENDING represents a pending case in Jira */
  PENDING = 'PENDING',
  /** PENDING_BSAO_RECOMMENDATION the case is pending a recommendation from BSAO */
  PENDING_BSAO_RECOMMENDATION = 'PENDING_BSAO_RECOMMENDATION',
  /** PENDING_COO_APPROVAL is a case pending COO approval */
  PENDING_COO_APPROVAL = 'PENDING_COO_APPROVAL',
  /** PENDING_FIRST_CAR is a case pending it's first CAR */
  PENDING_FIRST_CAR = 'PENDING_FIRST_CAR',
  /** PENDING_SECOND_CAR is a case pending a second CAR */
  PENDING_SECOND_CAR = 'PENDING_SECOND_CAR',
  /** PEP_FLAG_REMOVED - PEP */
  PEP_FLAG_REMOVED = 'PEP_FLAG_REMOVED',
  /** QC_REVIEW is a case where QC is reviewing */
  QC_REVIEW = 'QC_REVIEW',
  /** READY_FOR_APPROVAL is a case ready for approval */
  READY_FOR_APPROVAL = 'READY_FOR_APPROVAL',
  /** READY_QC is a case ready for QC */
  READY_QC = 'READY_QC',
  /** RESOLVED represents a resolved case */
  RESOLVED = 'RESOLVED',
  /** SAR_FILED the case was resolved with the filing of a SAR */
  SAR_FILED = 'SAR_FILED',
  /** SAR_FILED_FIRST_CAR is a case where the first CAR was filed */
  SAR_FILED_FIRST_CAR = 'SAR_FILED_FIRST_CAR',
  /** SAR_FILED_SECOND_CAR is a case where the SAR is filed for the second CAR */
  SAR_FILED_SECOND_CAR = 'SAR_FILED_SECOND_CAR',
  /** SAR_FILING_IN_PROGRESS is a case where SAR is being filed */
  SAR_FILING_IN_PROGRESS = 'SAR_FILING_IN_PROGRESS',
  /** SAR_FILING_IN_PROGRESS_FIRST_CAR is a case where the first CAR filing is in progress */
  SAR_FILING_IN_PROGRESS_FIRST_CAR = 'SAR_FILING_IN_PROGRESS_FIRST_CAR',
  /** SAR_FILING_IN_PROGRESS_SECOND_CAR is a case where the second CAR filing is in progress */
  SAR_FILING_IN_PROGRESS_SECOND_CAR = 'SAR_FILING_IN_PROGRESS_SECOND_CAR',
  /** SAR_NOT_FILED the case was resolved with the filing of a SAR */
  SAR_NOT_FILED = 'SAR_NOT_FILED',
  /** SAR_NOT_FILED_FIRST_CAR is a case where the first CAR has not been filed */
  SAR_NOT_FILED_FIRST_CAR = 'SAR_NOT_FILED_FIRST_CAR',
  /** SAR_NOT_FILED_SECOND_CAR is a case where no SAR was filed for the second CAR */
  SAR_NOT_FILED_SECOND_CAR = 'SAR_NOT_FILED_SECOND_CAR',
  /** SECOND_CAR_TRIGGERED is a case where the second CAR was triggered */
  SECOND_CAR_TRIGGERED = 'SECOND_CAR_TRIGGERED',
  /** VSD_FILED - OFAC Reporting */
  VSD_FILED = 'VSD_FILED',
  /** VSD_FILING_IN_PROGRESS is a case where VSD is being filed */
  VSD_FILING_IN_PROGRESS = 'VSD_FILING_IN_PROGRESS'
}

/** CaseType are the possible types of jira cases */
export enum CaseType {
  /** FULL_KYC_REVIEW is a case that originates from the CCV dashboard */
  FULL_KYC_REVIEW = 'FULL_KYC_REVIEW',
  /**
   * KYC_CRR_UPDATE is a case that enables users to update previously set
   * autorisk flags (eg PEP, FFI / FCA) and revise Accepted CRR ratings
   * without going through the long process of a Full KYC Review Case
   */
  KYC_CRR_UPDATE = 'KYC_CRR_UPDATE',
  /** NEGATIVE_NEWS represents the negative news case type */
  NEGATIVE_NEWS = 'NEGATIVE_NEWS',
  /** OFAC_REPORTING represents the ofac reporting case type */
  OFAC_REPORTING = 'OFAC_REPORTING',
  /** PEP represents the pep case type */
  PEP = 'PEP',
  /** REFERRAL represents the referral case type */
  REFERRAL = 'REFERRAL',
  /** TRANSACTION_ACTIVITY represents the transaction activity case type */
  TRANSACTION_ACTIVITY = 'TRANSACTION_ACTIVITY'
}

/** Enum of information that may be provided by institutional clients */
export enum ClientInformationType {
  /** ACCOUNT_NAME ... */
  ACCOUNT_NAME = 'ACCOUNT_NAME',
  /** ACCOUNT_REGISTRATION ... */
  ACCOUNT_REGISTRATION = 'ACCOUNT_REGISTRATION',
  /** ACCOUNT_REGISTRATION_CORPORATION ... */
  ACCOUNT_REGISTRATION_CORPORATION = 'ACCOUNT_REGISTRATION_CORPORATION',
  /** ACCOUNT_REGISTRATION_LLC ... */
  ACCOUNT_REGISTRATION_LLC = 'ACCOUNT_REGISTRATION_LLC',
  /** ACCOUNT_REGISTRATION_OTHER ... */
  ACCOUNT_REGISTRATION_OTHER = 'ACCOUNT_REGISTRATION_OTHER',
  /** ACCOUNT_REGISTRATION_PARTNERSHIP ... */
  ACCOUNT_REGISTRATION_PARTNERSHIP = 'ACCOUNT_REGISTRATION_PARTNERSHIP',
  /** ACCOUNT_REGISTRATION_SOLE_PROPRIETOR ... */
  ACCOUNT_REGISTRATION_SOLE_PROPRIETOR = 'ACCOUNT_REGISTRATION_SOLE_PROPRIETOR',
  /** ACKNOWLEDGE_EMAIL ... */
  ACKNOWLEDGE_EMAIL = 'ACKNOWLEDGE_EMAIL',
  /** ACKNOWLEDGE_NAME ... */
  ACKNOWLEDGE_NAME = 'ACKNOWLEDGE_NAME',
  /** ACKNOWLEDGE_TITLE ... */
  ACKNOWLEDGE_TITLE = 'ACKNOWLEDGE_TITLE',
  /** ADDRESS ... */
  ADDRESS = 'ADDRESS',
  /** ADDRESS2 ... */
  ADDRESS2 = 'ADDRESS2',
  /** ADS_KYC_INSTITUTION_FORM_SUBMISSION_ID ... */
  ADS_KYC_INSTITUTION_FORM_SUBMISSION_ID = 'ADS_KYC_INSTITUTION_FORM_SUBMISSION_ID',
  /** ARE_INVESTMENT_ADVISOR ... */
  ARE_INVESTMENT_ADVISOR = 'ARE_INVESTMENT_ADVISOR',
  /** ARE_REGISTERED_MAS ... */
  ARE_REGISTERED_MAS = 'ARE_REGISTERED_MAS',
  /** ASSET_CLASSES_EXPERIENCE_COMMODITIES */
  ASSET_CLASSES_EXPERIENCE_COMMODITIES = 'ASSET_CLASSES_EXPERIENCE_COMMODITIES',
  /** ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS */
  ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS = 'ASSET_CLASSES_EXPERIENCE_DIGITAL_ASSETS',
  /** ASSET_CLASSES_EXPERIENCE_EQUITIES */
  ASSET_CLASSES_EXPERIENCE_EQUITIES = 'ASSET_CLASSES_EXPERIENCE_EQUITIES',
  /** ASSET_CLASSES_EXPERIENCE_FIXED_INCOME */
  ASSET_CLASSES_EXPERIENCE_FIXED_INCOME = 'ASSET_CLASSES_EXPERIENCE_FIXED_INCOME',
  /** BUSINESS_REGISTRATION_NUMBER ... */
  BUSINESS_REGISTRATION_NUMBER = 'BUSINESS_REGISTRATION_NUMBER',
  /** CITY */
  CITY = 'CITY',
  /** CLIENT_BASE_ACCREDITED_INVESTORS */
  CLIENT_BASE_ACCREDITED_INVESTORS = 'CLIENT_BASE_ACCREDITED_INVESTORS',
  /** CLIENT_BASE_CRYPTO */
  CLIENT_BASE_CRYPTO = 'CLIENT_BASE_CRYPTO',
  /** CLIENT_BASE_FAMILY */
  CLIENT_BASE_FAMILY = 'CLIENT_BASE_FAMILY',
  /** CLIENT_BASE_HEDGE_FUND */
  CLIENT_BASE_HEDGE_FUND = 'CLIENT_BASE_HEDGE_FUND',
  /** CLIENT_BASE_INSTITUTIONAL_TRUST */
  CLIENT_BASE_INSTITUTIONAL_TRUST = 'CLIENT_BASE_INSTITUTIONAL_TRUST',
  /** CLIENT_BASE_NONE */
  CLIENT_BASE_NONE = 'CLIENT_BASE_NONE',
  /** CLIENT_BASE_PRIVATE_CAPITAL */
  CLIENT_BASE_PRIVATE_CAPITAL = 'CLIENT_BASE_PRIVATE_CAPITAL',
  /** CLIENT_BASE_VENTURE_CAPITAL */
  CLIENT_BASE_VENTURE_CAPITAL = 'CLIENT_BASE_VENTURE_CAPITAL',
  /** COUNTRY */
  COUNTRY = 'COUNTRY',
  /** DATE_OF_INCORPORATION ... */
  DATE_OF_INCORPORATION = 'DATE_OF_INCORPORATION',
  /** DBA_NAME */
  DBA_NAME = 'DBA_NAME',
  /** Optional DBA or Trade names */
  DBA_NAMES = 'DBA_NAMES',
  /** Full legal entity name */
  ENTITY_NAME = 'ENTITY_NAME',
  /** EXPLAIN_YOU_ARE_NOT_REGISTERED ... */
  EXPLAIN_YOU_ARE_NOT_REGISTERED = 'EXPLAIN_YOU_ARE_NOT_REGISTERED',
  /** FEIN ... */
  FEIN = 'FEIN',
  /** INDIVIDUAL_WEBSITE_URL ... */
  INDIVIDUAL_WEBSITE_URL = 'INDIVIDUAL_WEBSITE_URL',
  /** INVESTABLE_ASSETS */
  INVESTABLE_ASSETS = 'INVESTABLE_ASSETS',
  /** INVESTABLE_ASSETS_CRYPTO_PERCENTAGE */
  INVESTABLE_ASSETS_CRYPTO_PERCENTAGE = 'INVESTABLE_ASSETS_CRYPTO_PERCENTAGE',
  /** INVESTMENT_ADVISER_REGISTRATION ... */
  INVESTMENT_ADVISER_REGISTRATION = 'INVESTMENT_ADVISER_REGISTRATION',
  /** INVESTMENT_ADVISER_REGISTRATION_IARD ... */
  INVESTMENT_ADVISER_REGISTRATION_IARD = 'INVESTMENT_ADVISER_REGISTRATION_IARD',
  /** INVESTMENT_ADVISER_REGISTRATION_IARD_OTHER ... */
  INVESTMENT_ADVISER_REGISTRATION_IARD_OTHER = 'INVESTMENT_ADVISER_REGISTRATION_IARD_OTHER',
  /** INVESTMENT_ADVISER_REGISTRATION_PRIMARY_STATE ... */
  INVESTMENT_ADVISER_REGISTRATION_PRIMARY_STATE = 'INVESTMENT_ADVISER_REGISTRATION_PRIMARY_STATE',
  /** INVESTMENT_ADVISOR_COUNTRY ... */
  INVESTMENT_ADVISOR_COUNTRY = 'INVESTMENT_ADVISOR_COUNTRY',
  /** INVESTMENT_ADVISOR_NAME ... */
  INVESTMENT_ADVISOR_NAME = 'INVESTMENT_ADVISOR_NAME',
  /** INVESTMENT_ADVISOR_NUMBER ... */
  INVESTMENT_ADVISOR_NUMBER = 'INVESTMENT_ADVISOR_NUMBER',
  /** KYC_INSTITUTION_FORM_SUBMISSION_ID ... */
  KYC_INSTITUTION_FORM_SUBMISSION_ID = 'KYC_INSTITUTION_FORM_SUBMISSION_ID',
  /** MAILING_ADDRESS ... */
  MAILING_ADDRESS = 'MAILING_ADDRESS',
  /** MAILING_ADDRESS2 ... */
  MAILING_ADDRESS2 = 'MAILING_ADDRESS2',
  /** PHONE ... */
  PHONE = 'PHONE',
  /** PLACE_OF_INCORPORATION ... */
  PLACE_OF_INCORPORATION = 'PLACE_OF_INCORPORATION',
  /** POOLED_VEHICLE */
  POOLED_VEHICLE = 'POOLED_VEHICLE',
  /** PRIMARY_ACCOUNT_OBJECTIVE */
  PRIMARY_ACCOUNT_OBJECTIVE = 'PRIMARY_ACCOUNT_OBJECTIVE',
  /** REGISTERED_BUSINESS_NAME ... */
  REGISTERED_BUSINESS_NAME = 'REGISTERED_BUSINESS_NAME',
  /** State */
  STATE = 'STATE',
  /** STATEMENTS_PREFERENCE ... */
  STATEMENTS_PREFERENCE = 'STATEMENTS_PREFERENCE',
  /** STREET_ADDRESS */
  STREET_ADDRESS = 'STREET_ADDRESS',
  /** Zip code */
  ZIP_CODE = 'ZIP_CODE'
}

/** Properties by which ClientOrder connections can be ordered. */
export enum ClientOrderOrderField {
  /** order by created_at */
  CREATED_AT = 'CREATED_AT',
  /** order by last_transact_time */
  LAST_TRANSACT_TIME = 'LAST_TRANSACT_TIME',
  /** order by submit_time */
  SUBMIT_TIME = 'SUBMIT_TIME'
}

/** ClientPaymentFlowStatus is the statuses of a payment */
export enum ClientPaymentFlowStatus {
  /** Settlement has been created for this payment */
  INITIATED = 'INITIATED',
  /** Settlement is in progress (settling) */
  IN_PROGRESS = 'IN_PROGRESS',
  /** No settlement has been created for this payment */
  PENDING = 'PENDING',
  /** Settlement has been settled */
  SETTLED = 'SETTLED'
}

/** Client request type */
export enum ClientRequestType {
  /** Deposit request by lending client/member */
  DEPOSIT = 'DEPOSIT',
  /** Withdraw request by lending client/member */
  WITHDRAW = 'WITHDRAW'
}

/** ClientSettlementStatus is the statuses of a settlement */
export enum ClientSettlementStatus {
  /** Settlement has been assigned */
  ASSIGNED = 'ASSIGNED',
  /** Settlement has been canceled */
  CANCELED = 'CANCELED',
  /** Settlement has failed */
  FAILED = 'FAILED',
  /** Settlement has been created */
  PENDING = 'PENDING',
  /** Settlement is in progress (settling) */
  PROCESSING = 'PROCESSING',
  /** Settlement has been settled */
  SETTLED = 'SETTLED'
}

/** ClientSettlementType is the type of a settlement */
export enum ClientSettlementType {
  /** Advisory fee settlement */
  ADVISORY_FEE = 'ADVISORY_FEE',
  /** Custody fee settlement */
  CUSTODY_FEE = 'CUSTODY_FEE',
  /** Management fee settlement */
  MANAGEMENT_FEE = 'MANAGEMENT_FEE',
  /** Model fee settlement */
  MODEL_FEE = 'MODEL_FEE',
  /** Trade settlement */
  TRADE = 'TRADE'
}

/** Statuses that an institutional client can go through */
export enum ClientStatus {
  /** The client has been onboarded */
  COMPLETE = 'COMPLETE',
  /** The client is in draft mode */
  DRAFT = 'DRAFT',
  /** Information is being populated. Check the individual form statuses */
  IN_PROGRESS = 'IN_PROGRESS',
  /** The client is in the process of being onboarded */
  ONBOARDING = 'ONBOARDING',
  /** The client is ready for onboarding */
  READY = 'READY'
}

/** CollateralAccountAccountTypeID is enum for the field account_type_id */
export enum CollateralAccountAccountTypeID {
  ACCOUNT_TYPE_ID_EXTERNAL = 'ACCOUNT_TYPE_ID_EXTERNAL',
  ACCOUNT_TYPE_ID_INTERNAL = 'ACCOUNT_TYPE_ID_INTERNAL',
  ACCOUNT_TYPE_ID_UNSPECIFIED = 'ACCOUNT_TYPE_ID_UNSPECIFIED'
}

/** Properties by which CollateralAccount connections can be ordered. */
export enum CollateralAccountOrderField {
  ACCOUNT_TYPE_ID = 'ACCOUNT_TYPE_ID',
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS'
}

/** CollateralAccountStatus is enum for the field status */
export enum CollateralAccountStatus {
  ACCOUNT_STATUS_ACTIVE = 'ACCOUNT_STATUS_ACTIVE',
  ACCOUNT_STATUS_DELETED = 'ACCOUNT_STATUS_DELETED',
  ACCOUNT_STATUS_INACTIVE = 'ACCOUNT_STATUS_INACTIVE',
  ACCOUNT_STATUS_UNSPECIFIED = 'ACCOUNT_STATUS_UNSPECIFIED'
}

/** CollateralAgreementAgreementType is enum for the field agreement_type */
export enum CollateralAgreementAgreementType {
  COLLATERAL_AGREEMENT_TYPE_MLSA = 'COLLATERAL_AGREEMENT_TYPE_MLSA',
  COLLATERAL_AGREEMENT_TYPE_UNSPECIFIED = 'COLLATERAL_AGREEMENT_TYPE_UNSPECIFIED'
}

/** CollateralAgreementMarginLevel is enum for the field margin_level */
export enum CollateralAgreementMarginLevel {
  COLLATERAL_AGREEMENT_MARGIN_LEVEL_POOL = 'COLLATERAL_AGREEMENT_MARGIN_LEVEL_POOL',
  COLLATERAL_AGREEMENT_MARGIN_LEVEL_TRADE = 'COLLATERAL_AGREEMENT_MARGIN_LEVEL_TRADE',
  COLLATERAL_AGREEMENT_MARGIN_LEVEL_UNSPECIFIED = 'COLLATERAL_AGREEMENT_MARGIN_LEVEL_UNSPECIFIED'
}

/** Properties by which CollateralAgreement connections can be ordered. */
export enum CollateralAgreementOrderField {
  AGREEMENT_TYPE = 'AGREEMENT_TYPE',
  CREATED_AT = 'CREATED_AT',
  MARGIN_LEVEL = 'MARGIN_LEVEL',
  ORIGINATION_DATE = 'ORIGINATION_DATE',
  REFERENCE_PRICE_ALGO = 'REFERENCE_PRICE_ALGO',
  STATUS = 'STATUS'
}

/** CollateralAgreementReferencePriceAlgo is enum for the field reference_price_algo */
export enum CollateralAgreementReferencePriceAlgo {
  COLLATERAL_AGREEMENT_REF_PRICE_ALGO_DIRECT = 'COLLATERAL_AGREEMENT_REF_PRICE_ALGO_DIRECT',
  COLLATERAL_AGREEMENT_REF_PRICE_ALGO_UNSPECIFIED = 'COLLATERAL_AGREEMENT_REF_PRICE_ALGO_UNSPECIFIED',
  COLLATERAL_AGREEMENT_REF_PRICE_ALGO_WATERFALL = 'COLLATERAL_AGREEMENT_REF_PRICE_ALGO_WATERFALL'
}

/** CollateralAgreementStatus is enum for the field status */
export enum CollateralAgreementStatus {
  COLLATERAL_AGREEMENT_STATUS_ACTIVE = 'COLLATERAL_AGREEMENT_STATUS_ACTIVE',
  COLLATERAL_AGREEMENT_STATUS_CANCELED = 'COLLATERAL_AGREEMENT_STATUS_CANCELED',
  COLLATERAL_AGREEMENT_STATUS_DELETED = 'COLLATERAL_AGREEMENT_STATUS_DELETED',
  COLLATERAL_AGREEMENT_STATUS_FINAL_MARGIN = 'COLLATERAL_AGREEMENT_STATUS_FINAL_MARGIN',
  COLLATERAL_AGREEMENT_STATUS_INACTIVE = 'COLLATERAL_AGREEMENT_STATUS_INACTIVE',
  COLLATERAL_AGREEMENT_STATUS_INITIATED = 'COLLATERAL_AGREEMENT_STATUS_INITIATED',
  COLLATERAL_AGREEMENT_STATUS_UNSPECIFIED = 'COLLATERAL_AGREEMENT_STATUS_UNSPECIFIED'
}

/** CollateralCallCallType is enum for the field call_type */
export enum CollateralCallCallType {
  CALL_TYPE_FINAL_MARGIN = 'CALL_TYPE_FINAL_MARGIN',
  CALL_TYPE_INITIAL_MARGIN = 'CALL_TYPE_INITIAL_MARGIN',
  CALL_TYPE_LIQUIDATION = 'CALL_TYPE_LIQUIDATION',
  CALL_TYPE_MARGIN_CALL = 'CALL_TYPE_MARGIN_CALL',
  CALL_TYPE_PRE_LIQUIDATION = 'CALL_TYPE_PRE_LIQUIDATION',
  CALL_TYPE_PRE_MARGIN_CALL = 'CALL_TYPE_PRE_MARGIN_CALL',
  CALL_TYPE_PRE_RETURN = 'CALL_TYPE_PRE_RETURN',
  CALL_TYPE_RETURN = 'CALL_TYPE_RETURN',
  CALL_TYPE_SUBSTITUTION = 'CALL_TYPE_SUBSTITUTION',
  CALL_TYPE_UNSPECIFIED = 'CALL_TYPE_UNSPECIFIED'
}

/** Properties by which CollateralCall connections can be ordered. */
export enum CollateralCallOrderField {
  CALL_TYPE = 'CALL_TYPE',
  CREATED_AT = 'CREATED_AT',
  SETTLEMENT_REASON = 'SETTLEMENT_REASON',
  STATUS = 'STATUS'
}

/** CollateralCallSettlementReason is enum for the field settlement_reason */
export enum CollateralCallSettlementReason {
  SETTLEMENT_REASON_MARKET_MOVE = 'SETTLEMENT_REASON_MARKET_MOVE',
  SETTLEMENT_REASON_NONE = 'SETTLEMENT_REASON_NONE',
  SETTLEMENT_REASON_NOT_SETTLED = 'SETTLEMENT_REASON_NOT_SETTLED',
  SETTLEMENT_REASON_OTHER = 'SETTLEMENT_REASON_OTHER',
  SETTLEMENT_REASON_PAY_DOWN = 'SETTLEMENT_REASON_PAY_DOWN',
  SETTLEMENT_REASON_TOP_UP = 'SETTLEMENT_REASON_TOP_UP',
  SETTLEMENT_REASON_UNSPECIFIED = 'SETTLEMENT_REASON_UNSPECIFIED'
}

/** CollateralCallStatus is enum for the field status */
export enum CollateralCallStatus {
  CALL_STATUS_AGREED = 'CALL_STATUS_AGREED',
  CALL_STATUS_CANCELED = 'CALL_STATUS_CANCELED',
  CALL_STATUS_DELETED = 'CALL_STATUS_DELETED',
  CALL_STATUS_DISPUTED = 'CALL_STATUS_DISPUTED',
  CALL_STATUS_OUTSTANDING = 'CALL_STATUS_OUTSTANDING',
  CALL_STATUS_SETTLED = 'CALL_STATUS_SETTLED',
  CALL_STATUS_SETTLING = 'CALL_STATUS_SETTLING',
  CALL_STATUS_UNSPECIFIED = 'CALL_STATUS_UNSPECIFIED'
}

/** Properties by which CollateralFee connections can be ordered. */
export enum CollateralFeeOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  STATUS = 'STATUS',
  TRANSACTION_TIME = 'TRANSACTION_TIME'
}

/** CollateralFeeStatus is enum for the field status */
export enum CollateralFeeStatus {
  COLLATERAL_FEE_STATUS_ACTIVE = 'COLLATERAL_FEE_STATUS_ACTIVE',
  COLLATERAL_FEE_STATUS_DELETED = 'COLLATERAL_FEE_STATUS_DELETED',
  COLLATERAL_FEE_STATUS_INACTIVE = 'COLLATERAL_FEE_STATUS_INACTIVE',
  COLLATERAL_FEE_STATUS_UNSPECIFIED = 'COLLATERAL_FEE_STATUS_UNSPECIFIED'
}

/** Properties by which CollateralInventory connections can be ordered. */
export enum CollateralInventoryOrderField {
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS',
  TRANSACTION_TIME = 'TRANSACTION_TIME',
  TRANSACTION_TYPE = 'TRANSACTION_TYPE'
}

/** CollateralInventoryStatus is enum for the field status */
export enum CollateralInventoryStatus {
  COLLATERAL_INVENTORY_STATUS_ACTIVE = 'COLLATERAL_INVENTORY_STATUS_ACTIVE',
  COLLATERAL_INVENTORY_STATUS_DELETED = 'COLLATERAL_INVENTORY_STATUS_DELETED',
  COLLATERAL_INVENTORY_STATUS_INACTIVE = 'COLLATERAL_INVENTORY_STATUS_INACTIVE',
  COLLATERAL_INVENTORY_STATUS_UNSPECIFIED = 'COLLATERAL_INVENTORY_STATUS_UNSPECIFIED'
}

/** CollateralInventoryTransactionType is enum for the field transaction_type */
export enum CollateralInventoryTransactionType {
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_COLLATERAL_IN = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_COLLATERAL_IN',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_COLLATERAL_OUT = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_COLLATERAL_OUT',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_DELETED = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_DELETED',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_EXCESS_COLLATERAL = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_EXCESS_COLLATERAL',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_INITIAL_MARGIN = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_INITIAL_MARGIN',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_LIQUIDATION = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_LIQUIDATION',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_MARGIN_CALL = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_MARGIN_CALL',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_REHYPO_OUT = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_REHYPO_OUT',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_REHYPO_RETURN = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_REHYPO_RETURN',
  COLLATERAL_INVENTORY_TRANSACTION_TYPE_UNSPECIFIED = 'COLLATERAL_INVENTORY_TRANSACTION_TYPE_UNSPECIFIED'
}

/** Properties by which CollateralRehypoLedger connections can be ordered. */
export enum CollateralRehypoLedgerOrderField {
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS',
  TRANSACTION_PURPOSE = 'TRANSACTION_PURPOSE',
  TRANSACTION_TIME = 'TRANSACTION_TIME',
  TRANSACTION_TYPE = 'TRANSACTION_TYPE'
}

/** CollateralRehypoLedgerStatus is enum for the field status */
export enum CollateralRehypoLedgerStatus {
  COLLATERAL_REHYPO_LEDGER_STATUS_ACTIVE = 'COLLATERAL_REHYPO_LEDGER_STATUS_ACTIVE',
  COLLATERAL_REHYPO_LEDGER_STATUS_DELETED = 'COLLATERAL_REHYPO_LEDGER_STATUS_DELETED',
  COLLATERAL_REHYPO_LEDGER_STATUS_INACTIVE = 'COLLATERAL_REHYPO_LEDGER_STATUS_INACTIVE',
  COLLATERAL_REHYPO_LEDGER_STATUS_UNSPECIFIED = 'COLLATERAL_REHYPO_LEDGER_STATUS_UNSPECIFIED'
}

/** CollateralRehypoLedgerTransactionPurpose is enum for the field transaction_purpose */
export enum CollateralRehypoLedgerTransactionPurpose {
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_BORROWING = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_BORROWING',
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_INTEREST = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_INTEREST',
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_LENDING = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_LENDING',
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_PROPDESK = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_PROPDESK',
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_UNSPECIFIED = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_PURPOSE_UNSPECIFIED'
}

/** CollateralRehypoLedgerTransactionType is enum for the field transaction_type */
export enum CollateralRehypoLedgerTransactionType {
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_TYPE_TRANSFER_IN = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_TYPE_TRANSFER_IN',
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_TYPE_TRANSFER_OUT = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_TYPE_TRANSFER_OUT',
  COLLATERAL_REHYPO_LEDGER_TRANSACTION_TYPE_UNSPECIFIED = 'COLLATERAL_REHYPO_LEDGER_TRANSACTION_TYPE_UNSPECIFIED'
}

/** Properties by which CollateralSettlement connections can be ordered. */
export enum CollateralSettlementOrderField {
  CREATED_AT = 'CREATED_AT',
  QUANTITY = 'QUANTITY',
  SETTLEMENT_TYPE_ID = 'SETTLEMENT_TYPE_ID',
  STATUS = 'STATUS'
}

/** CollateralSettlementSettlementTypeID is enum for the field settlement_type_id */
export enum CollateralSettlementSettlementTypeID {
  SETTLEMENT_TYPE_DEPOSIT = 'SETTLEMENT_TYPE_DEPOSIT',
  SETTLEMENT_TYPE_PAYMENT = 'SETTLEMENT_TYPE_PAYMENT',
  SETTLEMENT_TYPE_UNSPECIFIED = 'SETTLEMENT_TYPE_UNSPECIFIED'
}

/** CollateralSettlementStatus is enum for the field status */
export enum CollateralSettlementStatus {
  SETTLEMENT_STATUS_CANCELED = 'SETTLEMENT_STATUS_CANCELED',
  SETTLEMENT_STATUS_COMPLETED = 'SETTLEMENT_STATUS_COMPLETED',
  SETTLEMENT_STATUS_INITIATED = 'SETTLEMENT_STATUS_INITIATED',
  SETTLEMENT_STATUS_PENDING = 'SETTLEMENT_STATUS_PENDING',
  SETTLEMENT_STATUS_UNSPECIFIED = 'SETTLEMENT_STATUS_UNSPECIFIED'
}

/** Collateral status */
export enum CollateralStatus {
  /** Active collateral */
  ACTIVE = 'ACTIVE',
  /** Canceled collateral */
  CANCELED = 'CANCELED',
  /** Pending operation */
  PENDING = 'PENDING'
}

/** Collateral transaction type */
export enum CollateralTransactionType {
  /** Deposit collateral */
  DEPOSIT = 'DEPOSIT',
  /** Withdraw collateral */
  WITHDRAW = 'WITHDRAW'
}

/** Possible counterparty kinds */
export enum CounterpartyKind {
  /** Represents a customer */
  CUSTOMER = 'CUSTOMER',
  /** Represents a dealer */
  DEALER = 'DEALER',
  /** Represents an exchange */
  EXCHANGE = 'EXCHANGE',
  /** Represents an invalid kind */
  INVALID = 'INVALID'
}

/** CxlRejReason represents */
export enum CxlRejReason {
  /** CxlRejReasonBroker represents */
  BROKER = 'BROKER',
  /** CxlRejReasonDuplicateClOrdID represents */
  DUPLICATE_CL_ORD_ID = 'DUPLICATE_CL_ORD_ID',
  /** Invalid represents */
  INVALID = 'INVALID',
  /** CxlRejReasonInvalidStrategy represents */
  INVALID_STRATEGY = 'INVALID_STRATEGY',
  /** CxlRejReasonOrderAlreadyInPendingCancelOrPendingReplaceStatus represents */
  ORDER_ALREADY_IN_PENDING_CANCEL_OR_PENDING_REPLACE_STATUS = 'ORDER_ALREADY_IN_PENDING_CANCEL_OR_PENDING_REPLACE_STATUS',
  /** CxlRejReasonOther represents */
  OTHER = 'OTHER',
  /** CxlRejReasonRateLimit represents */
  RATE_LIMIT = 'RATE_LIMIT',
  /** CxlRejReasonStaleRequest represents */
  STALE_REQUEST = 'STALE_REQUEST',
  /** CxlRejReasonTooLateToCancel represents */
  TOO_LATE_TO_CANCEL = 'TOO_LATE_TO_CANCEL',
  /** CxlRejReasonUnknownOrder represents */
  UNKNOWN_ORDER = 'UNKNOWN_ORDER'
}

/** DAppRiskLevel represents the risk level of connecting to a dApp. */
export enum DAppRiskLevel {
  /** HIGH means the risk level is high and the dApp is considered malicious. */
  HIGH = 'HIGH',
  /** LOW means the risk level is low and the dApp is considered safe. */
  LOW = 'LOW',
  /** MEDIUM means the risk level is medium and the dApp is considered somewhat risky. */
  MEDIUM = 'MEDIUM',
  /** UNAVAILABLE means the risk level could not be determined at this time due to an error. */
  UNAVAILABLE = 'UNAVAILABLE',
  /** UNDETERMINED means the risk level could not be determined at this time due lack of data. Since this state is non-informative, no banner should be displayed. */
  UNDETERMINED = 'UNDETERMINED'
}

/** DailyLendingJobRequestType indicates which daily jobs to run */
export enum DailyLendingJobRequestType {
  /** ALL indicates all jobs */
  ALL = 'ALL',
  /** APPROVAL indicates reward approval job */
  APPROVAL = 'APPROVAL',
  /** BORROWER_INTEREST represents a borrower interest job type */
  BORROWER_INTEREST = 'BORROWER_INTEREST',
  /** LATE_FEE represents a late fee job type */
  LATE_FEE = 'LATE_FEE',
  /** LOAN represents a loan job type */
  LOAN = 'LOAN',
  /** REWARDS indicates rewards generation job */
  REWARDS = 'REWARDS',
  /** STAKES indicates stakes update job */
  STAKES = 'STAKES'
}

/** DataSourceDataSourceTypeID is enum for the field data_source_type_id */
export enum DataSourceDataSourceTypeID {
  DATA_SOURCE_TYPE_ASSET_RATINGS = 'DATA_SOURCE_TYPE_ASSET_RATINGS',
  DATA_SOURCE_TYPE_MARKET_DATA = 'DATA_SOURCE_TYPE_MARKET_DATA',
  DATA_SOURCE_TYPE_UNSPECIFIED = 'DATA_SOURCE_TYPE_UNSPECIFIED'
}

/** Properties by which DataSource connections can be ordered. */
export enum DataSourceOrderField {
  CREATED_AT = 'CREATED_AT',
  DATA_SOURCE_TYPE_ID = 'DATA_SOURCE_TYPE_ID',
  NAME = 'NAME',
  STATUS = 'STATUS'
}

/** DataSourceStatus is enum for the field status */
export enum DataSourceStatus {
  DATA_SOURCE_STATUS_DELETED = 'DATA_SOURCE_STATUS_DELETED',
  DATA_SOURCE_STATUS_DISABLED = 'DATA_SOURCE_STATUS_DISABLED',
  DATA_SOURCE_STATUS_ENABLED = 'DATA_SOURCE_STATUS_ENABLED',
  DATA_SOURCE_STATUS_UNSPECIFIED = 'DATA_SOURCE_STATUS_UNSPECIFIED'
}

/** SettlementStatus is the different statuses that a settlement can go through */
export enum DealerSettlementStatus {
  /** Dealer Settlement has been canceled */
  CANCELED = 'CANCELED',
  /** Dealer Settlement has been created */
  PENDING = 'PENDING',
  /** Dealer Settlement has been settled */
  SETTLED = 'SETTLED',
  /** The associated transaction has been initiated */
  TRANSACTION_INITIATED = 'TRANSACTION_INITIATED'
}

/** DealerTradeInitiator represents */
export enum DealerTradeInitiator {
  /** Customer represents */
  CUSTOMER = 'CUSTOMER',
  /** Invalid represents */
  INVALID = 'INVALID',
  /** Trading desk represents */
  TRADING_DESK = 'TRADING_DESK'
}

/** DelegateType is an enum used for delegation, specifically V1 canonical operation */
export enum DelegateType {
  /** DELEGATE is when the delegation operation is bonding funds to a validator. */
  DELEGATE = 'DELEGATE',
  /** DELEGATE_DELEGATION_POOL is when the delegation operation is adding funds to a delegation pool */
  DELEGATE_DELEGATION_POOL = 'DELEGATE_DELEGATION_POOL',
  /** DELEGATE_STAKE_POOL is when the delegation operation is adding funds to a stake pool */
  DELEGATE_STAKE_POOL = 'DELEGATE_STAKE_POOL',
  /** INITIALIZE is when the delegation operation is creating a staking contract/validator */
  INITIALIZE = 'INITIALIZE',
  /** INITIALIZE_DELEGATION_POOL is when the delegation operation is creating a delegation pool */
  INITIALIZE_DELEGATION_POOL = 'INITIALIZE_DELEGATION_POOL',
  /** INITIALIZE_STAKE_POOL is when the delegation operation is creating a stake pool validator */
  INITIALIZE_STAKE_POOL = 'INITIALIZE_STAKE_POOL',
  /** INITIALIZE_VESTING is when the delegation operation is creating a vesting contract */
  INITIALIZE_VESTING = 'INITIALIZE_VESTING',
  /** REDELEGATE is when the delegation operation is moving the delegation to another validator */
  REDELEGATE = 'REDELEGATE',
  /** UNDELEGATE is when the delegation operation is unbonding funds from a validator. Known as 'unbond' in the cosmos docs */
  UNDELEGATE = 'UNDELEGATE',
  /** UNDELEGATE_DELEGATION_POOL is when the delegation operation is unbonding funds from a delegation pool */
  UNDELEGATE_DELEGATION_POOL = 'UNDELEGATE_DELEGATION_POOL',
  /** UNDELEGATE_STAKE_POOL is when the delegation operation is unbonding funds from a stake pool */
  UNDELEGATE_STAKE_POOL = 'UNDELEGATE_STAKE_POOL'
}

/** DepositAttributionGroupingType represents the possible groupings of deposit attribution entries */
export enum DepositAttributionGroupingType {
  /** Group deposit attributions by deposit event operation. The same source address might be present on multiple deposit event operations. */
  BY_OPERATION = 'BY_OPERATION',
  /** Group deposit attributions by source address. A deposit event operation with multiple source addresses will appear on multiple groups. */
  BY_SOURCE_ADDRESS = 'BY_SOURCE_ADDRESS'
}

/** DepositAttributionSortFields are the options for sorting a list of deposit attributions */
export enum DepositAttributionSortFields {
  /** CREATED_AT is the created timestamp of the deposit attribution */
  CREATED_AT = 'CREATED_AT'
}

/** Properties by which DepositAttributionSpamThreshold connections can be ordered. */
export enum DepositAttributionSpamThresholdOrderField {
  ASSET_TYPE_ID = 'ASSET_TYPE_ID',
  CREATED_AT = 'CREATED_AT',
  IS_ENABLE = 'IS_ENABLE',
  THRESHOLD = 'THRESHOLD',
  UPDATED_AT = 'UPDATED_AT'
}

/** DepositAttributionStatus are the possible status of a deposit attribution fragment */
export enum DepositAttributionStatus {
  /** ATTRIBUTED represents deposit attributions that have an originator name and country attributed */
  ATTRIBUTED = 'ATTRIBUTED',
  /** BLOCKED represents deposit attributions where the screening process resulted in insuccess */
  BLOCKED = 'BLOCKED',
  /** INITIATED represents newly created deposit attributions subject to a first attempt using the automatic attribution process */
  INITIATED = 'INITIATED',
  /** NON_ATTRIBUTABLE represents deposit attributions that cannot be attributed to/by any user */
  NON_ATTRIBUTABLE = 'NON_ATTRIBUTABLE',
  /** PENDING  represents deposits that have pending attributions */
  PENDING = 'PENDING',
  /** UNDER_REVIEW represents deposit attributions that have an ongoing review process */
  UNDER_REVIEW = 'UNDER_REVIEW',
  /** UNKNOWN represents an invalid deposit attribution state */
  UNKNOWN = 'UNKNOWN'
}

/** DepositAttributionType are the options for sorting a list of affiliates */
export enum DepositAttributionType {
  /** AUTOMATIC represents deposits automatically attributed */
  AUTOMATIC = 'AUTOMATIC',
  /** CLIENT_API represents deposits attributed via client api */
  CLIENT_API = 'CLIENT_API',
  /** INTERNAL represents deposits automatically attributed due to being internal anchorage addresses */
  INTERNAL = 'INTERNAL',
  /** INTRA_GROUP represents deposits automatically attributed due to being internal anchorage addresses but from a different anchorage entity */
  INTRA_GROUP = 'INTRA_GROUP',
  /** MANUAL_CLIENT represents deposits manually attributed externally by client (org users) */
  MANUAL_CLIENT = 'MANUAL_CLIENT',
  /** MANUAL_STAFF represents deposits manually attributed internally by Anchorage (staff users) */
  MANUAL_STAFF = 'MANUAL_STAFF',
  /** SMART_CONTRACT represents deposits automatically due to an interaction with a trusted smart contract */
  SMART_CONTRACT = 'SMART_CONTRACT',
  /** SPAM represents deposits attributed as spam */
  SPAM = 'SPAM',
  /** SPAM_CLIENT represents deposits attributed as spam by clients */
  SPAM_CLIENT = 'SPAM_CLIENT',
  /** SPAM_CLIENT_API represents deposits attributed as spam by clients via API */
  SPAM_CLIENT_API = 'SPAM_CLIENT_API',
  /** SPAM_STAFF represents deposits attributed as spam by staff */
  SPAM_STAFF = 'SPAM_STAFF',
  /** TRAVEL_RULE_CIPHERTRACE represents deposits automatically attributed using travel rule info from ciphertrace */
  TRAVEL_RULE_CIPHERTRACE = 'TRAVEL_RULE_CIPHERTRACE',
  /** TRAVEL_RULE_TRUST represents deposits automatically attributed using travel rule info from trust */
  TRAVEL_RULE_TRUST = 'TRAVEL_RULE_TRUST',
  /** TRUSTED_SOURCES represents deposits automatically attributed using the address trusted sources */
  TRUSTED_SOURCES = 'TRUSTED_SOURCES',
  /** TRUSTED_SOURCES_CLIENT represents deposits automatically attributed using the client defined trusted sources */
  TRUSTED_SOURCES_CLIENT = 'TRUSTED_SOURCES_CLIENT',
  /** TRUSTED_SOURCES_STAFF represents deposits automatically attributed using the staff defined trusted sources */
  TRUSTED_SOURCES_STAFF = 'TRUSTED_SOURCES_STAFF'
}

/** DepositAttributionWalletType are the options for wallet types for deposit attributions */
export enum DepositAttributionWalletType {
  /** CUSTODIAL represents a wallet where the owner does not have complete control over the wallet as they do not have the private key, a third party does. */
  CUSTODIAL = 'CUSTODIAL',
  /** SELF_HOSTED represents a wallet where the owner has complete control over the wallet as they have the private key. */
  SELF_HOSTED = 'SELF_HOSTED',
  /** UNKNOWN represents an invalid wallet type */
  UNKNOWN = 'UNKNOWN'
}

/**
 * DepositRewardType an Operation represents
 * Subset of DepositType in lib/operation/depositevent/operation.go
 * Only supports types stored on the operation in ExtraData
 * Operations contain reward types, see depositevent.IsRewardType()
 */
export enum DepositRewardType {
  /** ANCHORAGE_DELEGATION_REWARD is a delegation reward from an Anchorage validator */
  ANCHORAGE_DELEGATION_REWARD = 'ANCHORAGE_DELEGATION_REWARD',
  /** DELEGATION_REWARD is a delegation reward */
  DELEGATION_REWARD = 'DELEGATION_REWARD',
  /** NONE is a plain or non-reward deposit */
  NONE = 'NONE',
  /** NON_ANCHORAGE_STAKING_REWARD is a staking reward coming from a non-Anchorage validator (ETH staking) */
  NON_ANCHORAGE_STAKING_REWARD = 'NON_ANCHORAGE_STAKING_REWARD',
  /** STAKING_REWARD is a staking reward */
  STAKING_REWARD = 'STAKING_REWARD'
}

/** Possible deposit screening statuses */
export enum DepositScreeningStatus {
  /** Completed screening */
  COMPLETED = 'COMPLETED',
  /** Screening in progress */
  IN_PROGRESS = 'IN_PROGRESS',
  /** Waiting screening */
  WAITING = 'WAITING'
}

/** Represents the status of a device considering the recent activity of the user */
export enum DeviceStatus {
  /** User has been using the app recently and can perform actions */
  ACTIVE = 'ACTIVE',
  /** User did not use the app for a while, and is at-risk of losing access */
  IDLE = 'IDLE',
  /**
   * User lost device or hasn't used app for a long time.
   * At this stage, user cannot perform any actions inside the organization
   */
  LOST = 'LOST'
}

/** ETHStakingProviderType represents an enum of all the ethereum staking providers */
export enum ETHStakingProviderType {
  /** BLOCKDAEMON provider type for ethereum staking */
  BLOCKDAEMON = 'BLOCKDAEMON',
  /** FIGMENT provider type for ethereum staking */
  FIGMENT = 'FIGMENT',
  /** STAKED provider type for ethereum staking */
  STAKED = 'STAKED',
  /** TWINSTAKE provider type for ethereum staking */
  TWINSTAKE = 'TWINSTAKE'
}

/** Properties by which EligibilityProof connections can be ordered. */
export enum EligibilityProofOrderField {
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS'
}

/** EligibilityProofStatus is enum for the field status */
export enum EligibilityProofStatus {
  COLLATERAL_PROOF_STATUS_ACTIVE = 'COLLATERAL_PROOF_STATUS_ACTIVE',
  COLLATERAL_PROOF_STATUS_DELETED = 'COLLATERAL_PROOF_STATUS_DELETED',
  COLLATERAL_PROOF_STATUS_INACTIVE = 'COLLATERAL_PROOF_STATUS_INACTIVE',
  COLLATERAL_PROOF_STATUS_UNSPECIFIED = 'COLLATERAL_PROOF_STATUS_UNSPECIFIED'
}

/** Properties by which EligibilitySchedule connections can be ordered. */
export enum EligibilityScheduleOrderField {
  CREATED_AT = 'CREATED_AT',
  STATUS = 'STATUS'
}

/** EligibilityScheduleStatus is enum for the field status */
export enum EligibilityScheduleStatus {
  ELIGIBILITY_SCHEDULE_STATUS_ACTIVE = 'ELIGIBILITY_SCHEDULE_STATUS_ACTIVE',
  ELIGIBILITY_SCHEDULE_STATUS_DELETED = 'ELIGIBILITY_SCHEDULE_STATUS_DELETED',
  ELIGIBILITY_SCHEDULE_STATUS_INACTIVE = 'ELIGIBILITY_SCHEDULE_STATUS_INACTIVE',
  ELIGIBILITY_SCHEDULE_STATUS_UNSPECIFIED = 'ELIGIBILITY_SCHEDULE_STATUS_UNSPECIFIED'
}

/** Possible enrollment types */
export enum EnrollType {
  /** The user is joining an existing organization */
  EXISTING_ORG_ENROLL = 'EXISTING_ORG_ENROLL',
  /** The user is joining a new organization */
  NEW_ORG_ENROLL = 'NEW_ORG_ENROLL',
  /** The enrollment will replace the user within its organization, replacing the existing key */
  REPLACEMENT_ENROLL = 'REPLACEMENT_ENROLL'
}

/** Properties by which Enrollment connections can be ordered */
export enum EnrollmentOrderByField {
  /** Order enrollments by creation date */
  CREATED_AT = 'CREATED_AT'
}

/** Possible enrollment statuses */
export enum EnrollmentStatus {
  /** Enrollment completed but the user has not logged in for the first time yet */
  COMPLETE = 'COMPLETE',
  /** Enrollment completed and no further changes can be made to the submitted information */
  LOCKED = 'LOCKED',
  /** The user has not submitted the minimal required information, i.e. name, email and phone number */
  OPEN = 'OPEN',
  /** Enrollment is still underway */
  PENDING = 'PENDING',
  /** Enrollment was rejected */
  REJECTED = 'REJECTED'
}

/** Enrollment status as it should be shown in the user interface */
export enum EnrollmentVisualStatus {
  /** All steps have been completed, but not approved into the organization. */
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  /** Enrollment token has been used, but not all enrollment steps have been completed. */
  REGISTRATION_IN_PROGRESS = 'REGISTRATION_IN_PROGRESS',
  /** Token has been created, but has not been used. */
  TOKEN_CREATED = 'TOKEN_CREATED',
  /** All steps have been completed, but organization has not been seeded yet. */
  WAITING_FOR_ORG_SEEDING = 'WAITING_FOR_ORG_SEEDING'
}

/** EntityStatus is the status of a lending entity */
export enum EntityStatus {
  /** Active is an active lending entity */
  ACTIVE = 'ACTIVE',
  /** Deleted is an Deleted lending entity */
  DELETED = 'DELETED',
  /** Inactive is an inactive lending entity */
  INACTIVE = 'INACTIVE'
}

/** ExecType represents */
export enum ExecType {
  /** Canceled represents */
  CANCELED = 'CANCELED',
  /** CancelRejected represents */
  CANCEL_REJECTED = 'CANCEL_REJECTED',
  /** DoneForDay represents */
  DONE_FOR_DAY = 'DONE_FOR_DAY',
  /** Invalid represents */
  INVALID = 'INVALID',
  /** New represents */
  NEW = 'NEW',
  /** Paused represents */
  PAUSED = 'PAUSED',
  /** PendingResume represents */
  PENDINGVRESUME = 'PENDINGVRESUME',
  /** PendingCancel represents */
  PENDING_CANCEL = 'PENDING_CANCEL',
  /** PendingNew represents */
  PENDING_NEW = 'PENDING_NEW',
  /** PendingPause represents */
  PENDING_PAUSE = 'PENDING_PAUSE',
  /** PendingReplace represents */
  PENDING_REPLACE = 'PENDING_REPLACE',
  /** Rejected represents */
  REJECTED = 'REJECTED',
  /** Replaced represents */
  REPLACED = 'REPLACED',
  /** ReplaceRejected represents */
  REPLACE_REJECTED = 'REPLACE_REJECTED',
  /** Restated represents */
  RESTATED = 'RESTATED',
  /** Resumed represents */
  RESUMED = 'RESUMED',
  /** Started represents */
  STARTED = 'STARTED',
  /** Stopped represents */
  STOPPED = 'STOPPED',
  /** Trade represents */
  TRADE = 'TRADE',
  /** Triggered represents */
  TRIGGERED = 'TRIGGERED'
}

/** Properties by which Exposure connections can be ordered. */
export enum ExposureOrderField {
  CREATED_AT = 'CREATED_AT',
  EXPOSURE = 'EXPOSURE',
  EXPOSURE_USD = 'EXPOSURE_USD',
  SOURCE_SYSTEM = 'SOURCE_SYSTEM',
  STATUS = 'STATUS',
  TRADE_DATE = 'TRADE_DATE',
  TYPE_ID = 'TYPE_ID'
}

/** ExposureSourceSystem is enum for the field source_system */
export enum ExposureSourceSystem {
  EXPOSURE_SOURCE_SYSTEM_COLLATERAL_MANAGER = 'EXPOSURE_SOURCE_SYSTEM_COLLATERAL_MANAGER',
  EXPOSURE_SOURCE_SYSTEM_LOAN_MANAGER = 'EXPOSURE_SOURCE_SYSTEM_LOAN_MANAGER',
  EXPOSURE_SOURCE_SYSTEM_UNSPECIFIED = 'EXPOSURE_SOURCE_SYSTEM_UNSPECIFIED'
}

/** ExposureStatus is enum for the field status */
export enum ExposureStatus {
  EXPOSURE_STATUS_ACTIVE = 'EXPOSURE_STATUS_ACTIVE',
  EXPOSURE_STATUS_DELETED = 'EXPOSURE_STATUS_DELETED',
  EXPOSURE_STATUS_INACTIVE = 'EXPOSURE_STATUS_INACTIVE',
  EXPOSURE_STATUS_UNSPECIFIED = 'EXPOSURE_STATUS_UNSPECIFIED'
}

/** ExposureTypeID is enum for the field type_id */
export enum ExposureTypeID {
  EXPOSURE_TYPE_ID_DECREASE = 'EXPOSURE_TYPE_ID_DECREASE',
  EXPOSURE_TYPE_ID_FINAL_EXPOSURE = 'EXPOSURE_TYPE_ID_FINAL_EXPOSURE',
  EXPOSURE_TYPE_ID_INCREASE = 'EXPOSURE_TYPE_ID_INCREASE',
  EXPOSURE_TYPE_ID_INITIAL_EXPOSURE = 'EXPOSURE_TYPE_ID_INITIAL_EXPOSURE',
  EXPOSURE_TYPE_ID_UNSPECIFIED = 'EXPOSURE_TYPE_ID_UNSPECIFIED'
}

/** Type of external account transaction. */
export enum ExternalAccountTransactionType {
  /** Charged fees */
  FEE = 'FEE',
  /** Amount was from other operation generating a decrement */
  OTHER_DECREMENT = 'OTHER_DECREMENT',
  /** Amount was from other operation generating an increment */
  OTHER_INCREMENT = 'OTHER_INCREMENT',
  /** Amount was pre-deposited. */
  PREDEPOSIT = 'PREDEPOSIT',
  /** Amount was deposited from proceeds */
  PROCEEDS_DEPOSIT = 'PROCEEDS_DEPOSIT',
  /** Amount is being spent on trades */
  SPENDING_ON_TRADES = 'SPENDING_ON_TRADES',
  /** Amount was withdrawed back to the client */
  WITHDRAWAL_BACK_TO_CLIENT = 'WITHDRAWAL_BACK_TO_CLIENT'
}

/** Type of the ExtraParams in an operation's canonical representation. */
export enum ExtraParamsActionType {
  /** For celo governance/referendum vote */
  CELO_VOTE_REFERENDUM = 'CELO_VOTE_REFERENDUM',
  /** For flow delegate */
  FLOW_DELEGATE = 'FLOW_DELEGATE',
  /** For flow undelegate */
  FLOW_UNDELEGATE = 'FLOW_UNDELEGATE',
  /** For maker authorize spender */
  MKR_AUTHORIZE_SPENDER = 'MKR_AUTHORIZE_SPENDER',
  /** For maker executive vote */
  MKR_VOTE_EXECUTIVE = 'MKR_VOTE_EXECUTIVE',
  /** For maker poll vote */
  MKR_VOTE_POLL = 'MKR_VOTE_POLL',
  /** For oasis undelegate */
  OASIS_UNDELEGATE = 'OASIS_UNDELEGATE'
}

/** Sorting options for lending facility yield */
export enum FacilityYieldSortKey {
  /** Sort by Asset type option for lending facility yield */
  ASSET_TYPE = 'ASSET_TYPE',
  /** Sort by Balance option for lending facility yield */
  BALANCE = 'BALANCE'
}

/** FeatureName indicates the types of features that can be enabled */
export enum FeatureName {
  /** Controls the enablement of credit management at the trading account level */
  ACCOUNT_LEVEL_CREDIT_MANAGEMENT = 'ACCOUNT_LEVEL_CREDIT_MANAGEMENT',
  /**
   * Allows client to use features related to external transfers.
   * This relates to the createTransfer APIv2 endpoint, creating a role with
   * TRANSFER permission and external destinations, and the ability to add API
   * public keys to the organization via operation.
   */
  APIV2_EXTERNAL_TRANSFER = 'APIV2_EXTERNAL_TRANSFER',
  /** Obsolete flag, use only to manually clear the flag from the database. */
  APIV2_TRANSFER_PERMISSION = 'APIV2_TRANSFER_PERMISSION',
  /** Enables an organization to use ECDSA signing with APIv2. EdDSA is the default. */
  APIv2_ECDSA_AUTH = 'APIv2_ECDSA_AUTH',
  /** Controls whether settlements are automatically settled. */
  AUTOMATICALLY_SETTLE_SETTLEMENTS = 'AUTOMATICALLY_SETTLE_SETTLEMENTS',
  /**
   * Enable support for withdrawals V2 for Bitcoin-like assets (BTC/LTC). This
   * feature is required to enable concurrent withdrawals for these assets.
   */
  BITCOINLIKE_WITHDRAWAL_V2 = 'BITCOINLIKE_WITHDRAWAL_V2',
  /** Controls the access to the bulk vault creation functionality. */
  BULK_VAULT_CREATION = 'BULK_VAULT_CREATION',
  /** Enable Deposit Attribution API for an org */
  DEPOSIT_ATTRIBUTION_API = 'DEPOSIT_ATTRIBUTION_API',
  /** Enable Deposit Attribution Blocking Screening for an org */
  DEPOSIT_ATTRIBUTION_BLOCKING_SCREENING = 'DEPOSIT_ATTRIBUTION_BLOCKING_SCREENING',
  /** Enable experimental implementations of concurrent withdrawals. */
  EXPERIMENTAL_CONCURRENT_WITHDRAWALS = 'EXPERIMENTAL_CONCURRENT_WITHDRAWALS',
  /** Enable AML enforcement on external transfers. */
  EXTERNAL_TRANSFERS_AML = 'EXTERNAL_TRANSFERS_AML',
  /** Controls the automation of risk review. */
  EXTERNAL_TRANSFER_AUTO_RISK_REVIEW = 'EXTERNAL_TRANSFER_AUTO_RISK_REVIEW',
  /** Enable anchorage internal alerts on client external withdrawals & transfers */
  EXTERNAL_WITHDRAWAL_ALERT = 'EXTERNAL_WITHDRAWAL_ALERT',
  /** Enable use of fiat assets */
  FIAT_ENABLED = 'FIAT_ENABLED',
  /** Controls whether the organization automatically claims its rewards or automatically delegates its rewards */
  FLOW_AUTO_REWARD_DELEGATION_DISABLED = 'FLOW_AUTO_REWARD_DELEGATION_DISABLED',
  /** Enables an organization to use API endpoints for holds. */
  HOLDS = 'HOLDS',
  /**
   * Controls client trades that go through an improved state machine where they
   * are only moved to a final EXECUTED state when all relevant information (eg.
   * final price, commission, etc) is supplied.
   */
  IMPROVED_TRADE_STATE_TRANSITION = 'IMPROVED_TRADE_STATE_TRANSITION',
  /** Controls whether location based authorization is enabled */
  LOCATION_BASED_AUTHORIZATION = 'LOCATION_BASED_AUTHORIZATION',
  /** Enable Onboarding API for an org */
  ONBOARDING_API = 'ONBOARDING_API',
  /** returns only Assets with sub-id zero vs all assets within a vault */
  RETURN_LEGACY_ASSET_SUB_IDS = 'RETURN_LEGACY_ASSET_SUB_IDS',
  /** Enable RIA flat fee enhancement */
  RIA_FLAT_FEE = 'RIA_FLAT_FEE',
  /** Enable risk assessment for smart contracts */
  RISK_SMART_CONTRACTS = 'RISK_SMART_CONTRACTS',
  /** Enable skip Deposit Attribution for testing purposes for a specific org */
  SKIP_DEPOSIT_ATTRIBUTION_FOR_TESTING_PURPOSES = 'SKIP_DEPOSIT_ATTRIBUTION_FOR_TESTING_PURPOSES',
  /** Enable Sub Quorums for an org */
  SUB_QUORUMS = 'SUB_QUORUMS',
  /** Controls the ability to request allocation when executing a quote. */
  TRADING_ALLOCATION = 'TRADING_ALLOCATION',
  /** Enable Trading order API for an org */
  TRADING_MARKET_ORDER = 'TRADING_MARKET_ORDER',
  /** Controls the access to the transfer access requests functionality. */
  TRANSFER_ACCESS_REQUEST = 'TRANSFER_ACCESS_REQUEST',
  /** Enables deposit/withdraw functionality in Client Web Dashboard through vault details page. */
  VAULT_DETAILS_OPERATION = 'VAULT_DETAILS_OPERATION',
  /** Indicates whether wallets apiv2 functionality is enabled */
  WALLETS_APIV2 = 'WALLETS_APIV2',
  /** Kill Siwtch the Wallet API Phase3: wallet_creation and address_provision */
  WALLETS_APIV2_PHASE3_KILL_SWITCH = 'WALLETS_APIV2_PHASE3_KILL_SWITCH'
}

/** FeePeriod are the possible periods of fees */
export enum FeePeriodType {
  /** MONTHLY represents the monthly fee period */
  MONTHLY = 'MONTHLY',
  /** UNKNOWN represents an unknown period */
  UNKNOWN = 'UNKNOWN'
}

/**
 * FeeReservationLevel defines the level at which funds will be reserved as transaction fee.
 * It can be used to cap the max amount transaction fee.
 * Internally the setting is currently translated to different BaseFeeMultiplier values being used.
 * BaseFeeMultiplier controls what multiplier to use when calculating "max base fee".
 * Currently this is only applicable to transaction for ETH and ERC20 tokens.
 */
export enum FeeReservationLevel {
  /**
   * ECONOMY level means to reserve the least funds as transaction fee, thus increasing the chance
   * of the transaction being stuck waiting for actual network fee to come below the reserve.
   * This option is best suited when user wants to wait when actual network fee is high.
   * Note that if actual network fee never becomes low enough, then the transaction could be stuck
   * forever (until canceled.)
   */
  ECONOMY = 'ECONOMY',
  /**
   * GENEROUS level means to reserve the most funds as transaction fee, thus mimizing the chance
   * of the transaction being stuck waiting for actual network fee to come below the reserve.
   * This option is best suited when user still wants to execute the transaction even when network
   * fee is high.
   * Note that actual transaction fee spent is still determined by the network at the time of
   * transaction execution.
   */
  GENEROUS = 'GENEROUS',
  /** MEDIUM level strikes a balance between GENEROUS and ECONOMY. */
  MEDIUM = 'MEDIUM',
  /** UNSPECIFIED means no value specificed, and default behavior will be used. */
  UNSPECIFIED = 'UNSPECIFIED'
}

/** FeeTargetType are the possible types of fee targets */
export enum FeeTargetType {
  /** SUBACCOUNT represents a ledger subacount */
  SUBACCOUNT = 'SUBACCOUNT',
  /** UNKNOWN represents an unknown target */
  UNKNOWN = 'UNKNOWN'
}

/** FeeTypeType are the possible types of fees */
export enum FeeTypeType {
  /** ADVISORY represents the Advisory fee type */
  ADVISORY = 'ADVISORY',
  /** CUSTODY represents the Custody fee type */
  CUSTODY = 'CUSTODY',
  /** MANAGEMENT represents the Management fee type */
  MANAGEMENT = 'MANAGEMENT',
  /** MODEL represents the Model fee type */
  MODEL = 'MODEL',
  /** UNKNOWN represents an unknown fee type */
  UNKNOWN = 'UNKNOWN'
}

/** FlowType represents */
export enum FlowType {
  /** invalid represents */
  INVALID = 'INVALID',
  /** talos_institutional represents */
  TALOS_INSTITUTIONAL = 'TALOS_INSTITUTIONAL',
  /** talos_retail represents */
  TALOS_RETAIL = 'TALOS_RETAIL'
}

/** Statuses that an institutional form can go through */
export enum FormStatus {
  /** All required information was collected and validated */
  COMPLETE = 'COMPLETE',
  /** The client has been created but no communications have been sent out to it */
  DRAFT = 'DRAFT',
  /** The information collection is in progress but not accepted */
  IN_PROGRESS = 'IN_PROGRESS',
  /** The form is under review and cannot be edited */
  IN_REVIEW = 'IN_REVIEW'
}

/** FragmentBindingSortField are the options for sorting a list of fragment bindings. */
export enum FragmentBindingSortField {
  /**
   * DISPLAY_NAME sorts by the fragment bindings display name.
   * The default policy will always be at the top of the list.
   */
  DISPLAY_NAME = 'DISPLAY_NAME'
}

/** GovernanceSetupStatus represents the state of setting up governance for an asset */
export enum GovernanceSetupStatus {
  /** COMPLETE represents the state when voting setup has been completed */
  COMPLETE = 'COMPLETE',
  /** INCOMPLETE represents the state when voting setup has not been started or completed */
  INCOMPLETE = 'INCOMPLETE',
  /** NOT_SUPPORTED is for assets that do not support voting (eg BTC) */
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  /** UNAVAILABLE represents the state when voting setup on the blockchain is impossible */
  UNAVAILABLE = 'UNAVAILABLE'
}

/** GovernanceSetupType represents the type of the setup step */
export enum GovernanceSetupType {
  /** CELO_CREATE_ACCOUNT encodes a CELO_CREATE_ACCOUNT, enabling this asset to lock/unlock, participate in validator voting, and vote on governance proposals */
  CELO_CREATE_ACCOUNT = 'CELO_CREATE_ACCOUNT',
  /** IOU_CHIEF_APPROVAL encodes an approve ERC20 operation for a ETH address to the MKR Chief contract, sent to the IOU contract */
  IOU_CHIEF_APPROVAL = 'IOU_CHIEF_APPROVAL',
  /** LOCK encodes a LOCK operation, binding an amount of this asset for use in voting */
  LOCK = 'LOCK',
  /** MKR_CHIEF_APPROVAL encodes an approve ERC20 operation for a ETH address to the MKR Chief contract, sent to the base MKR contract */
  MKR_CHIEF_APPROVAL = 'MKR_CHIEF_APPROVAL'
}

/** GovernanceStepProgress represents the progress of an individual step for setting up governance */
export enum GovernanceStepProgress {
  /** COMPLETE is when the step is complete */
  COMPLETE = 'COMPLETE',
  /** FAILED is when the step failed for any reason */
  FAILED = 'FAILED',
  /** IN_PROGRESS is when the step is in progress */
  IN_PROGRESS = 'IN_PROGRESS',
  /** NOT_READY is when the step hasn't been initiated but isn't ready yet */
  NOT_READY = 'NOT_READY',
  /** READY_TO_START is when the step hasn't been initiated but is ready */
  READY_TO_START = 'READY_TO_START'
}

/** HoldOwnerType should match entities.HoldOwnerType */
export enum HoldOwnerType {
  /** API_KEY */
  API_KEY = 'API_KEY',
  /** MINT_BURN */
  MINT_BURN = 'MINT_BURN',
  /** OPERATION */
  OPERATION = 'OPERATION',
  /** SETTLEMENT_NETWORK */
  SETTLEMENT_NETWORK = 'SETTLEMENT_NETWORK'
}

/** HoldStatus should match entities.HoldStatus */
export enum HoldStatus {
  /** ACTIVE */
  ACTIVE = 'ACTIVE',
  /** EXECUTING */
  EXECUTING = 'EXECUTING',
  /** EXPIRED */
  EXPIRED = 'EXPIRED',
  /** RELEASED */
  RELEASED = 'RELEASED'
}

/** HoldType represents the types/reasons a hold can occur */
export enum HoldType {
  /** ALL_TIME_REWARDS is the total of all rewards that have been received by this address during participation actions */
  ALL_TIME_REWARDS = 'ALL_TIME_REWARDS',
  /** AML is for when AML checks occur on deposits */
  AML = 'AML',
  /** ATTRIBUTION_BLOCKED is for holds created by deposits that are blocked due to the screening process flag them as being potentially suspicious. */
  ATTRIBUTION_BLOCKED = 'ATTRIBUTION_BLOCKED',
  /** ATTRIBUTION_UNDER_REVIEW  is for holds created by deposits that are under review, that is, waiting for the screening process of the attribution to complete. */
  ATTRIBUTION_UNDER_REVIEW = 'ATTRIBUTION_UNDER_REVIEW',
  /** AVAILABLE is the amount that can be withdrawn from the regular address */
  AVAILABLE = 'AVAILABLE',
  /** DELEGATED_USABLE represents the delegated tokens that are not unvested */
  DELEGATED_USABLE = 'DELEGATED_USABLE',
  /** DELEGATE_AVAILABLE is the amount that can be delegated by this address */
  DELEGATE_AVAILABLE = 'DELEGATE_AVAILABLE',
  /** DELEGATE_VOTE_LOCK is a user-initiated lock of token to a delegate */
  DELEGATE_VOTE_LOCK = 'DELEGATE_VOTE_LOCK',
  /** DELEGATION_REWARDS is the amount of rewards accrued from staking */
  DELEGATION_REWARDS = 'DELEGATION_REWARDS',
  /**
   * EXECUTE_OPERATION indicates a hold applied as the result of a pending operation for this asset. Examples include
   * withdrawals, transfers, and network transaction fees.
   */
  EXECUTE_OPERATION = 'EXECUTE_OPERATION',
  /** LOCK is a user-initiated hold (for example, locking MKR) */
  LOCK = 'LOCK',
  /** MINT_BURN is the amount locked to be used in a mint or burn conversion */
  MINT_BURN = 'MINT_BURN',
  /** NON_ATTRIBUTABLE is for holds created by deposits that cannot be attributed by any user. */
  NON_ATTRIBUTABLE = 'NON_ATTRIBUTABLE',
  /** OFFCHAIN_VESTING is for holds created by offchain vesting to hold funds prior to their vesting time. */
  OFFCHAIN_VESTING = 'OFFCHAIN_VESTING',
  /** PENDING is a balance state right before LOCK, where it exists but it is not accessible */
  PENDING = 'PENDING',
  /** PENDING_ATTRIBUTION is the amount holded due to pending deposit attributions */
  PENDING_ATTRIBUTION = 'PENDING_ATTRIBUTION',
  /** PLACED_BY_API_KEY indicates a hold that was placed by a user via an API client */
  PLACED_BY_API_KEY = 'PLACED_BY_API_KEY',
  /** REDELEGATING */
  REDELEGATING = 'REDELEGATING',
  /** RESTAKING_REWARDS is the amount of rewards accrued from restaking */
  RESTAKING_REWARDS = 'RESTAKING_REWARDS',
  /** SETTLEMENT_NETWORK is the amount locked to be used in a settlement. It's locked right before executing a settlement and unlocked after the settlement is completed. */
  SETTLEMENT_NETWORK = 'SETTLEMENT_NETWORK',
  /** SPAM is the amount holded due to deposits flagged as spam */
  SPAM = 'SPAM',
  /** UNBOND is a user-initiated waiting hold applied for a successful unbonding operation (for example, unbonding cosmos) */
  UNBOND = 'UNBOND',
  /** UNBONDED */
  UNBONDED = 'UNBONDED',
  /** UNVESTED is the opposite of BalanceTypeVested. These funds can't be claimed yet. */
  UNVESTED = 'UNVESTED',
  /** UNVESTED_DELEGATABLE is the unvested balance that is delegatable. */
  UNVESTED_DELEGATABLE = 'UNVESTED_DELEGATABLE',
  /** VESTED applies to vesting contracts and represents the funds that are held in it and have already vested, so they can be claimed (provided they are also unlocked) */
  VESTED = 'VESTED',
  /** VESTING_AVAILABLE is the amount that's in your vesting contract and is available for being claimed */
  VESTING_AVAILABLE = 'VESTING_AVAILABLE',
  /** VESTING_TOTAL is the total balance of the vesting contract if there is one */
  VESTING_TOTAL = 'VESTING_TOTAL',
  /** VESTING_UNLOCKED is the portion of the balance that's in the vesting contract and is not locked */
  VESTING_UNLOCKED = 'VESTING_UNLOCKED',
  /** VOTE_PROXY_LOCK is the amount locked by the vote proxy contract */
  VOTE_PROXY_LOCK = 'VOTE_PROXY_LOCK'
}

/** Sorting options for holdings in portfolios and vaults */
export enum HoldingSortFields {
  /** Sorts by the USD value of each holding with the holding of assettype = USD first */
  USD_FIRST = 'USD_FIRST',
  /** Sort by the USD value of each holding */
  USD_VALUE = 'USD_VALUE'
}

/** IAMAccessLevel is enum for the field accessLevel */
export enum IAMAccessLevel {
  /** User is Approver. Allowed to approve Operations on the Resource. */
  APPROVER = 'APPROVER',
  /** User is Initiator. Allowed to initiate Operations on the Resource. */
  INITIATOR = 'INITIATOR',
  /** User is both Initiator and Approver. Allowed to initiator and approve Operations on the Resource. */
  INITIATOR_AND_APPROVER = 'INITIATOR_AND_APPROVER'
}

/** CategoryType is enum for the field type */
export enum IAMCategoryType {
  ADMINISTRATOR_POLICIES_MANAGEMENT = 'ADMINISTRATOR_POLICIES_MANAGEMENT',
  API_KEY_MANAGEMENT = 'API_KEY_MANAGEMENT',
  API_PERMISSION_GROUP_MANAGEMENT = 'API_PERMISSION_GROUP_MANAGEMENT',
  CONNECTED_APPS_MANAGEMENT = 'CONNECTED_APPS_MANAGEMENT',
  SETTLEMENT_NETWORK_MANAGEMENT = 'SETTLEMENT_NETWORK_MANAGEMENT',
  STAFF_MEMBERS_PERMISSIONS = 'STAFF_MEMBERS_PERMISSIONS',
  TRUSTED_DESTINATIONS_MANAGEMENT = 'TRUSTED_DESTINATIONS_MANAGEMENT',
  TRUSTED_SMART_CONTRACTS_MANAGEMENT = 'TRUSTED_SMART_CONTRACTS_MANAGEMENT',
  UNSPECIFIED = 'UNSPECIFIED',
  USER_ACCESS_MANAGEMENT = 'USER_ACCESS_MANAGEMENT',
  VAULTS_MANAGEMENT = 'VAULTS_MANAGEMENT',
  VAULT_TRANSACTIONS = 'VAULT_TRANSACTIONS'
}

/** Properties by which IAM Member connections can be ordered. */
export enum IAMMemberOrderField {
  NAME = 'NAME'
}

/** IAMPermissionAction is enum for the field action */
export enum IAMPermissionAction {
  API_KEY_CREATE_INITIATE = 'API_KEY_CREATE_INITIATE',
  API_KEY_REVOKE_INITIATE = 'API_KEY_REVOKE_INITIATE',
  API_KEY_UPDATE_INITIATE = 'API_KEY_UPDATE_INITIATE',
  API_KEY_WITHDRAW_INITIATE = 'API_KEY_WITHDRAW_INITIATE',
  BACKOFFICE_SENSITIVE_DATA_ACCESS = 'BACKOFFICE_SENSITIVE_DATA_ACCESS',
  CAN_APPROVE_SELFHOSTED_ADS_WITHDRAWALS_ACCESS = 'CAN_APPROVE_SELFHOSTED_ADS_WITHDRAWALS_ACCESS',
  CLIENT_OPS_ACCESS = 'CLIENT_OPS_ACCESS',
  CLIENT_OPS_DIRECT_EDIT_ACCESS = 'CLIENT_OPS_DIRECT_EDIT_ACCESS',
  CLOSE_CLIENT_ACCOUNT_ACCESS = 'CLOSE_CLIENT_ACCOUNT_ACCESS',
  COMPLIANCE_ALERTS_OPS_ACCESS = 'COMPLIANCE_ALERTS_OPS_ACCESS',
  COMPLIANCE_DOCS_KYC_OPS_ACCESS = 'COMPLIANCE_DOCS_KYC_OPS_ACCESS',
  COMPLIANCE_DOCS_TRANSACTION_ACTIVITY_OPS_ACCESS = 'COMPLIANCE_DOCS_TRANSACTION_ACTIVITY_OPS_ACCESS',
  COMPLIANCE_HOLD_ACCESS = 'COMPLIANCE_HOLD_ACCESS',
  COMPLIANCE_TRUST_ACCESS = 'COMPLIANCE_TRUST_ACCESS',
  CONNECTED_APPS_VIEWERS_ACCESS = 'CONNECTED_APPS_VIEWERS_ACCESS',
  CREATE_VAULT_INITIATE = 'CREATE_VAULT_INITIATE',
  CRR_MODEL_RISK_CONFIG_ACCESS = 'CRR_MODEL_RISK_CONFIG_ACCESS',
  DEPOSIT_INITIATE = 'DEPOSIT_INITIATE',
  DISABLE_VAULT_INITIATE = 'DISABLE_VAULT_INITIATE',
  ENABLE_VAULT_INITIATE = 'ENABLE_VAULT_INITIATE',
  FREEZE_WITHDRAWAL_ACCESS = 'FREEZE_WITHDRAWAL_ACCESS',
  KYC_ALERTS_OPS_ACCESS = 'KYC_ALERTS_OPS_ACCESS',
  LENDING_OPS_ACCESS = 'LENDING_OPS_ACCESS',
  LENDING_OPS_TRANSFERS_ACCESS = 'LENDING_OPS_TRANSFERS_ACCESS',
  MANUAL_DEPOSIT_ATTRIBUTION_ACCESS = 'MANUAL_DEPOSIT_ATTRIBUTION_ACCESS',
  ON_CALL_ACCESS = 'ON_CALL_ACCESS',
  OPERATOR_SELF_ACCESS = 'OPERATOR_SELF_ACCESS',
  ORGANIZATIONS_IAM_VIEW = 'ORGANIZATIONS_IAM_VIEW',
  ORGANIZATIONS_USERS_DOWNLOAD = 'ORGANIZATIONS_USERS_DOWNLOAD',
  ORGANIZATION_POLICIES_VIEW = 'ORGANIZATION_POLICIES_VIEW',
  PERMISSION_GROUP_CREATE_INITIATE = 'PERMISSION_GROUP_CREATE_INITIATE',
  PERMISSION_GROUP_DELETE_INITIATE = 'PERMISSION_GROUP_DELETE_INITIATE',
  PERMISSION_GROUP_UPDATE_INITIATE = 'PERMISSION_GROUP_UPDATE_INITIATE',
  PERSON_KYC_VIEWERS_ACCESS = 'PERSON_KYC_VIEWERS_ACCESS',
  REVIEWER_HOLD_ACCESS = 'REVIEWER_HOLD_ACCESS',
  REVIEWER_TRUST_ACCESS = 'REVIEWER_TRUST_ACCESS',
  RISK_TRADING_LIMITS_ACCESS = 'RISK_TRADING_LIMITS_ACCESS',
  ROLE_CHANGE_INITIATE = 'ROLE_CHANGE_INITIATE',
  SETTLEMENT_NETWORK_PARTICIPANTS_EDIT = 'SETTLEMENT_NETWORK_PARTICIPANTS_EDIT',
  SETTLEMENT_OPS_ACCESS = 'SETTLEMENT_OPS_ACCESS',
  STAKING_INITIATE = 'STAKING_INITIATE',
  TMS_MODEL_RISK_CONFIG_ACCESS = 'TMS_MODEL_RISK_CONFIG_ACCESS',
  TRADING_ACCESS = 'TRADING_ACCESS',
  TRADING_COMPLIANCE_ACCESS = 'TRADING_COMPLIANCE_ACCESS',
  TRADING_OPS_ACCESS = 'TRADING_OPS_ACCESS',
  TRUSTED_DESTINATIONS_ADD_INITIATE = 'TRUSTED_DESTINATIONS_ADD_INITIATE',
  TRUSTED_DESTINATIONS_REMOVE_INITIATE = 'TRUSTED_DESTINATIONS_REMOVE_INITIATE',
  TRUSTED_DESTINATIONS_VIEW = 'TRUSTED_DESTINATIONS_VIEW',
  TRUSTED_SMART_CONTRACTS_ADD_INITIATE = 'TRUSTED_SMART_CONTRACTS_ADD_INITIATE',
  TRUSTED_SMART_CONTRACTS_REMOVE_INITIATE = 'TRUSTED_SMART_CONTRACTS_REMOVE_INITIATE',
  TRUSTED_SMART_CONTRACTS_VIEW = 'TRUSTED_SMART_CONTRACTS_VIEW',
  UNSPECIFIED = 'UNSPECIFIED',
  UPDATE_ORG_POLICIES_INITIATE = 'UPDATE_ORG_POLICIES_INITIATE',
  UPDATE_VAULT_POLICIES_INITIATE = 'UPDATE_VAULT_POLICIES_INITIATE',
  USER_ADD_INITIATE = 'USER_ADD_INITIATE',
  USER_REMOVE_INITIATE = 'USER_REMOVE_INITIATE',
  USER_REPLACE_INITIATE = 'USER_REPLACE_INITIATE',
  VAULTS_DETAILS_EDIT = 'VAULTS_DETAILS_EDIT',
  VAULTS_DETAILS_VIEW = 'VAULTS_DETAILS_VIEW',
  VAULTS_MEMBERS_VIEW = 'VAULTS_MEMBERS_VIEW',
  VAULTS_POLICIES_VIEW = 'VAULTS_POLICIES_VIEW',
  VAULTS_WALLET_CREATE = 'VAULTS_WALLET_CREATE',
  VIEW_CRR_HISTORY_ACCESS = 'VIEW_CRR_HISTORY_ACCESS',
  VOTE_INITIATE = 'VOTE_INITIATE',
  WITHDRAW_INITIATE = 'WITHDRAW_INITIATE',
  WITHDRAW_VIEW = 'WITHDRAW_VIEW'
}

/** PermissionAvailability is enum for the field availability */
export enum IAMPermissionAvailability {
  BASE = 'BASE',
  SPECIFIC = 'SPECIFIC',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** Properties by which Permission connections can be ordered. */
export enum IAMPermissionOrderField {
  DISPLAY_NAME = 'DISPLAY_NAME',
  NAME = 'NAME'
}

/** IAMPermissionResourceType is enum for the field resource_type */
export enum IAMPermissionResourceType {
  ORGANIZATION = 'ORGANIZATION',
  UNDEFINED = 'UNDEFINED',
  VAULT = 'VAULT'
}

/** IAMRoleAvailability is enum for the field availability */
export enum IAMRoleAvailability {
  BASE = 'BASE',
  SPECIFIC = 'SPECIFIC',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** Properties by which Role connections can be ordered. */
export enum IAMRoleOrderField {
  CREATED_AT = 'CREATED_AT',
  DISPLAY_NAME = 'DISPLAY_NAME',
  NAME = 'NAME'
}

/** IAMRoleRoleType is enum for the field role_type */
export enum IAMRoleRoleType {
  CUSTOM = 'CUSTOM',
  SYSTEM = 'SYSTEM',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** IconType is the type of the icon requested */
export enum IconType {
  /** PNG */
  PNG = 'PNG',
  /** SVG */
  SVG = 'SVG'
}

/**
 * IndividualAffiliateRelationship describes the relationship between an individual
 * and a affiliate.
 */
export enum IndividualAffiliateRelationship {
  /** AML_SERVICE_PROVIDER represents an AML Service Provider relation between individual and affiliate */
  AML_SERVICE_PROVIDER = 'AML_SERVICE_PROVIDER',
  /** AUTHORIZED_SIGNATORY represents an authorized signatory relation between individual and affiliate */
  AUTHORIZED_SIGNATORY = 'AUTHORIZED_SIGNATORY',
  /** BENEFICIAL_OWNER represents a Beneficial Owner relation between individual and affiliate */
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
  /** BENEFICIARY represents a Beneficiary relation between individual and affiliate */
  BENEFICIARY = 'BENEFICIARY',
  /** BOARD_DIRECTOR represents a Board Director relation between individual and affiliate */
  BOARD_DIRECTOR = 'BOARD_DIRECTOR',
  /** CONTROL_PERSON represents a Control Person relation between individual and affiliate */
  CONTROL_PERSON = 'CONTROL_PERSON',
  /** FUND_ADMINISTRATOR represents a Fund Administrator relation between individual and affiliate */
  FUND_ADMINISTRATOR = 'FUND_ADMINISTRATOR',
  /** GENERAL_PARTNER represents a General Partner relation between individual and affiliate */
  GENERAL_PARTNER = 'GENERAL_PARTNER',
  /** GRANTOR_DONOR represents a Grantor/Donor/Settlor relation between individual and affiliate */
  GRANTOR_DONOR = 'GRANTOR_DONOR',
  /** INVESTMENT_MANAGER represents a Investment Manager between individual and affiliate */
  INVESTMENT_MANAGER = 'INVESTMENT_MANAGER',
  /** MANAGING_MEMBER represents a Managing Member relation between individual and affiliate */
  MANAGING_MEMBER = 'MANAGING_MEMBER',
  /** OTHER represents another relation between individual and affiliate */
  OTHER = 'OTHER',
  /** PARTICIPATING_MEMBER represents an Authorized User relation between individual and affiliate */
  PARTICIPATING_MEMBER = 'PARTICIPATING_MEMBER',
  /** SENIOR_OFFICER represents a Senior Officer relation between individual and affiliate */
  SENIOR_OFFICER = 'SENIOR_OFFICER',
  /** TRUSTEE_SUCCESSOR_TRUSTEE represents a Trustee/Success Trustee relation between individual and affiliate */
  TRUSTEE_SUCCESSOR_TRUSTEE = 'TRUSTEE_SUCCESSOR_TRUSTEE'
}

/** Individual PII Types */
export enum IndividualInformationType {
  /** App access desired. E.g. Admin, regular, none. */
  ACCESS_TYPE = 'ACCESS_TYPE',
  /** ACKNOWLEDGE_EMAIL... */
  ACKNOWLEDGE_EMAIL = 'ACKNOWLEDGE_EMAIL',
  /** ACKNOWLEDGE_NAME ... */
  ACKNOWLEDGE_NAME = 'ACKNOWLEDGE_NAME',
  /** ACKNOWLEDGE_TITLE... */
  ACKNOWLEDGE_TITLE = 'ACKNOWLEDGE_TITLE',
  /** First part of the address, usually door number and street */
  ADDRESS = 'ADDRESS',
  /** Second part of the address, usually city, state and zip code */
  ADDRESS2 = 'ADDRESS2',
  /** ADDRESS_PROOF ... */
  ADDRESS_PROOF = 'ADDRESS_PROOF',
  /** ADDRESS_PROOF_DOCUMENT ... */
  ADDRESS_PROOF_DOCUMENT = 'ADDRESS_PROOF_DOCUMENT',
  /** ADS_KYC_IND_FORM_SUBMISSION_ID ... */
  ADS_KYC_INDIVIDUAL_FORM_SUBMISSION_ID = 'ADS_KYC_INDIVIDUAL_FORM_SUBMISSION_ID',
  /** CALCUATED_INFO_HASH ... */
  CALCULATED_INFO_HASH = 'CALCULATED_INFO_HASH',
  /** CITIZENSHIP_STATUS ... */
  CITIZENSHIP_STATUS = 'CITIZENSHIP_STATUS',
  /** CITY */
  CITY = 'CITY',
  /** COMPANY_NAME ... */
  COMPANY_NAME = 'COMPANY_NAME',
  /** COUNTRY */
  COUNTRY = 'COUNTRY',
  /** Date of birth */
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  /** Email */
  EMAIL = 'EMAIL',
  /** First name */
  FIRST_NAME = 'FIRST_NAME',
  /** GOVERNMENT_ID */
  GOVERNMENT_ID = 'GOVERNMENT_ID',
  /** HAS_SSN */
  HAS_SSN = 'HAS_SSN',
  /** ID Card back side picture */
  ID_CARD_BACK = 'ID_CARD_BACK',
  /** ID_CARD_BACK_SECOND... */
  ID_CARD_BACK_SECOND = 'ID_CARD_BACK_SECOND',
  /** ID Card front side picture */
  ID_CARD_FRONT = 'ID_CARD_FRONT',
  /** ID_CARD_FRONT_SECOND ... */
  ID_CARD_FRONT_SECOND = 'ID_CARD_FRONT_SECOND',
  /** ID_ISSUING_COUNTRY ... */
  ID_ISSUING_COUNTRY = 'ID_ISSUING_COUNTRY',
  /** ID_ISSUING_COUNTRY_SECOND ... */
  ID_ISSUING_COUNTRY_SECOND = 'ID_ISSUING_COUNTRY_SECOND',
  /** The type of the ID (ex. Passport) */
  ID_TYPE = 'ID_TYPE',
  /** ID_TYPE_SECOND ... */
  ID_TYPE_SECOND = 'ID_TYPE_SECOND',
  /** The type of individual. E.g. beneficial owner, authorized person, participating member */
  INDIVIDUAL_TYPE = 'INDIVIDUAL_TYPE',
  /** IS_MAILING_ADDRESS_DIFFERENT ... */
  IS_MAILING_ADDRESS_DIFFERENT = 'IS_MAILING_ADDRESS_DIFFERENT',
  /** KYC_IND_FORM_SUBMISSION_ID ... */
  KYC_INDIVIDUAL_FORM_SUBMISSION_ID = 'KYC_INDIVIDUAL_FORM_SUBMISSION_ID',
  /** Last name */
  LAST_NAME = 'LAST_NAME',
  /** MAILING_CITY ... */
  MAILING_CITY = 'MAILING_CITY',
  /** MAILING_COUNTRY ... */
  MAILING_COUNTRY = 'MAILING_COUNTRY',
  /** MAILING_STATE ... */
  MAILING_STATE = 'MAILING_STATE',
  /** MAILING_STREET_ADDRESS ... */
  MAILING_STREET_ADDRESS = 'MAILING_STREET_ADDRESS',
  /** MAILING_ZIP_CODE ... */
  MAILING_ZIP_CODE = 'MAILING_ZIP_CODE',
  /** MATERIAL_NEWS */
  MATERIAL_NEWS = 'MATERIAL_NEWS',
  /** Middle name */
  MIDDLE_NAME = 'MIDDLE_NAME',
  /** Name */
  NAME = 'NAME',
  /** NON_MATERIAL_NEWS */
  NON_MATERIAL_NEWS = 'NON_MATERIAL_NEWS',
  /** ONBOARDING_API_SUBMISSION_ID ... */
  ONBOARDING_API_SUBMISSION_ID = 'ONBOARDING_API_SUBMISSION_ID',
  /** PASSPORT_DOCUMENT */
  PASSPORT_DOCUMENT = 'PASSPORT_DOCUMENT',
  /** PEP ... */
  PEP = 'PEP',
  /** PEP_COUNTRY_SERVED ... */
  PEP_COUNTRY_SERVED = 'PEP_COUNTRY_SERVED',
  /** PEP_END_DATE_SERVED ... */
  PEP_END_DATE_SERVED = 'PEP_END_DATE_SERVED',
  /** PEP_ROLE ... */
  PEP_ROLE = 'PEP_ROLE',
  /** PEP_START_DATE_SERVED ... */
  PEP_START_DATE_SERVED = 'PEP_START_DATE_SERVED',
  /** Phone number */
  PHONE = 'PHONE',
  /** PRIMARY_COUNTRY ... */
  PRIMARY_COUNTRY = 'PRIMARY_COUNTRY',
  /** RP_INCORPORATION_DATE ... */
  RP_INCORPORATION_DATE = 'RP_INCORPORATION_DATE',
  /** RP_INCORPORATION_PLACE ... */
  RP_INCORPORATION_PLACE = 'RP_INCORPORATION_PLACE',
  /** RP_INVESTMENT_ADVISOR_NUMBER ... */
  RP_INVESTMENT_ADVISOR_NUMBER = 'RP_INVESTMENT_ADVISOR_NUMBER',
  /** SECOND_COUNTRY ... */
  SECOND_COUNTRY = 'SECOND_COUNTRY',
  /** SETTLOR */
  SETTLOR = 'SETTLOR',
  /** SHARES_HELD_PERCENT ... */
  SHARES_HELD_PERCENT = 'SHARES_HELD_PERCENT',
  /** Social security number */
  SSN = 'SSN',
  /** State */
  STATE = 'STATE',
  /** STREET_ADDRESS */
  STREET_ADDRESS = 'STREET_ADDRESS',
  /** TITLE ... */
  TITLE = 'TITLE',
  /** US_RESIDENCY_STATUS ... */
  US_RESIDENCY_STATUS = 'US_RESIDENCY_STATUS',
  /** Ownership percentage */
  VERIFY_OWNERSHIP_PERCENTAGE = 'VERIFY_OWNERSHIP_PERCENTAGE',
  /** Role */
  VERIFY_ROLE = 'VERIFY_ROLE',
  /** Voice and video recording */
  VOICE_PHRASE = 'VOICE_PHRASE',
  /** Zip code */
  ZIP_CODE = 'ZIP_CODE'
}

/** InstitutionalAffiliateSortFields are the options for sorting a list of affiliates */
export enum InstitutionalAffiliateSortFields {
  /** FORM_STATUS is the status associted to the affiliate's form */
  FORM_STATUS = 'FORM_STATUS',
  /** NAME is the name of the affiliate */
  NAME = 'NAME',
  /** ORG_NAME is the name of the organization associated to the affiliate */
  ORG_NAME = 'ORG_NAME',
  /** PENDING_ATTRIBUTIONS is to sort for affiliates' pending attributions number */
  PENDING_ATTRIBUTIONS = 'PENDING_ATTRIBUTIONS'
}

/** Filter states for cosmetically archived institutional clients */
export enum InstitutionalCosmeticStatus {
  /** Shows active */
  ACTIVE = 'ACTIVE',
  /** Shows all */
  ALL = 'ALL',
  /** Shows only deleted */
  DELETED = 'DELETED'
}

/** Enum of lifecyle stages, for institutional clients/affiliates/individuals */
export enum InstitutionalValueLifecycle {
  /** DRAFT means the value is not yet final, and can be changed */
  DRAFT = 'DRAFT',
  /** HISTORICAL means the value is a version that can no longer be changed in the database, but is not the most-recent version */
  HISTORICAL = 'HISTORICAL',
  /** LATEST means the value is a version that can no longer be changed in the database, and is the most-recent version */
  LATEST = 'LATEST'
}

/** Interest payment entity type for creation */
export enum InterestPaymentCreateIDType {
  /** Creating an interest payment based on a invoice */
  INVOICE = 'INVOICE',
  /** Creating an interest payment based on a loan */
  LOAN = 'LOAN'
}

/**
 * InternalDestinationsAllowed is an enum of choices for allowing internal destinations.
 * It may be expanded in the future to allow more specificity.
 */
export enum InternalDestinationsAllowed {
  /** ALLOW_ALL indicates all internal destinations will be allowed. This includes any vault, any wallet, or other future internal destination. */
  ALLOW_ALL = 'ALLOW_ALL',
  /** NONE indicates no internal destinations will be allowed */
  NONE = 'NONE'
}

/** InternalStatus represents */
export enum InternalStatus {
  /** canceled represents */
  CANCELED = 'CANCELED',
  /** executed represents */
  EXECUTED = 'EXECUTED',
  /** invalid represents */
  INVALID = 'INVALID',
  /** pending represents */
  PENDING = 'PENDING',
  /** rejected represents */
  REJECTED = 'REJECTED',
  /** settled represents */
  SETTLED = 'SETTLED',
  /** settling represents */
  SETTLING = 'SETTLING'
}

/** IssueSource are the possible sources of issues for Referral, PEP, NEGATIVE NEwS and OFAC cases */
export enum IssueSource {
  /** BANKING_PARTNER_RFI is a compliance issue case source */
  BANKING_PARTNER_RFI = 'BANKING_PARTNER_RFI',
  /** SOURCE_314_B is a compliance issue case source */
  BLOCKCHAIN_ANALYTICS = 'BLOCKCHAIN_ANALYTICS',
  /** CORRECTED_AMENDED_SAR is a compliance issue case source */
  CORRECTED_AMENDED_SAR = 'CORRECTED_AMENDED_SAR',
  /** CRR_RISK_FACTOR_CHANGE is a KYC ad hoc issue alert source */
  CRR_RISK_FACTOR_CHANGE = 'CRR_RISK_FACTOR_CHANGE',
  /** DA_BEHAVIORAL is a compliance issue case source */
  DA_BEHAVIORAL = 'DA_BEHAVIORAL',
  /** EMPLOYEE_REFERRAL is a compliance issue case source */
  EMPLOYEE_REFERRAL = 'EMPLOYEE_REFERRAL',
  /** FIAT_BEHAVIORAL is a compliance issue case source */
  FIAT_BEHAVIORAL = 'FIAT_BEHAVIORAL',
  /** MONTHLY_TRANSACTION_REVIEW is a compliance issue case source */
  MONTHLY_TRANSACTION_REVIEW = 'MONTHLY_TRANSACTION_REVIEW',
  /** NNS_ALERT is a compliance issue case source */
  NNS_ALERT = 'NNS_ALERT',
  /** NON_MATERIAL_CLIENT_CHANGE is a KYC ad hoc issue alert source */
  NON_MATERIAL_CLIENT_CHANGE = 'NON_MATERIAL_CLIENT_CHANGE',
  /** OTHER is a KYC ad hoc issue alert source */
  OTHER = 'OTHER',
  /** PEP_ALERT is a compliance issue case source */
  PEP_ALERT = 'PEP_ALERT',
  /** QA_CTMG_IA_FINDING is a KYC ad hoc issue alert source */
  QA_CTMG_IA_FINDING = 'QA_CTMG_IA_FINDING',
  /** SANCTIONS_ALERT is a compliance issue case source */
  SANCTIONS_ALERT = 'SANCTIONS_ALERT',
  /** SOURCE_90_DAY_REVIEW is a compliance issue case source */
  SOURCE_90_DAY_REVIEW = 'SOURCE_90_DAY_REVIEW',
  /** SOURCE_314_A_TRUE_MATCH is a compliance issue case source */
  SOURCE_314_A_TRUE_MATCH = 'SOURCE_314_A_TRUE_MATCH',
  /** SOURCE_314_B is a compliance issue case source */
  SOURCE_314_B = 'SOURCE_314_B'
}

/** KYCEntityRelationship describes the relationship between two kyc entities. */
export enum KYCEntityRelationship {
  /** AML_SERVICE_PROVIDER represents an AML Service Provider relation */
  AML_SERVICE_PROVIDER = 'AML_SERVICE_PROVIDER',
  /** AUTHORIZED_SIGNATORY represents an authorized signatory relation */
  AUTHORIZED_SIGNATORY = 'AUTHORIZED_SIGNATORY',
  /** BENEFICIAL_OWNER represents a Beneficial Owner relation */
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
  /** BENEFICIARY represents a Beneficiary relation */
  BENEFICIARY = 'BENEFICIARY',
  /** BOARD_DIRECTOR represents a Board Director relation */
  BOARD_DIRECTOR = 'BOARD_DIRECTOR',
  /** CONTROL_PERSON represents a Control Person relation */
  CONTROL_PERSON = 'CONTROL_PERSON',
  /** FUND_ADMINISTRATOR represents a Fund Administrator relation */
  FUND_ADMINISTRATOR = 'FUND_ADMINISTRATOR',
  /** GENERAL_PARTNER represents a General Partner relation */
  GENERAL_PARTNER = 'GENERAL_PARTNER',
  /** GRANTOR_DONOR represents a Grantor/Donor/Settlor relation */
  GRANTOR_DONOR = 'GRANTOR_DONOR',
  /** INVESTMENT_MANAGER represents a Investment Manager */
  INVESTMENT_MANAGER = 'INVESTMENT_MANAGER',
  /** MANAGING_MEMBER represents a Managing Member relation */
  MANAGING_MEMBER = 'MANAGING_MEMBER',
  /** OTHER represents another relation */
  OTHER = 'OTHER',
  /** PARTICIPATING_MEMBER represents an Authorized User relation */
  PARTICIPATING_MEMBER = 'PARTICIPATING_MEMBER',
  /** SENIOR_OFFICER represents a Senior Officer relation */
  SENIOR_OFFICER = 'SENIOR_OFFICER',
  /** TRUSTEE_SUCCESSOR_TRUSTEE represents a Trustee/Success Trustee relation */
  TRUSTEE_SUCCESSOR_TRUSTEE = 'TRUSTEE_SUCCESSOR_TRUSTEE'
}

/** KYCEntityType is enum for the kyc entity type */
export enum KYCEntityType {
  /** AFFILIATE */
  AFFILIATE = 'AFFILIATE',
  /** INDIVIDUAL */
  INDIVIDUAL = 'INDIVIDUAL',
  /** RELATED_PARTY */
  RELATED_PARTY = 'RELATED_PARTY',
  /** UNSPECIFIED */
  UNSPECIFIED = 'UNSPECIFIED'
}

/** KYCNavStatusType is enum for the kyc Nav element status type */
export enum KYCNavStatusType {
  /** COMPLETED means all required questions are answered */
  COMPLETED = 'COMPLETED',
  /** ERROR means at least one question has an error */
  ERROR = 'ERROR',
  /** INCOMPLETE means no questions are answered */
  INCOMPLETE = 'INCOMPLETE'
}

/** KYCOptionsList is a reference to list of options to display in the question */
export enum KYCOptionsList {
  /** COUNTRIES is a list of countries */
  COUNTRIES = 'COUNTRIES',
  /** UNSPECIFIED is a default value for the options list */
  UNSPECIFIED = 'UNSPECIFIED'
}

/** Properties by which KYC Process connections can be ordered. */
export enum KYCProcessOrderField {
  ID = 'ID',
  JIRA_CASE_ID = 'JIRA_CASE_ID',
  JIRA_CASE_TYPE = 'JIRA_CASE_TYPE',
  NAME = 'NAME',
  STATE = 'STATE'
}

/** KYCProcessState is enum for the field state */
export enum KYCProcessState {
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  REVIEW = 'REVIEW',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** KYCProcessTriggerActionType is enum for the field type */
export enum KYCProcessTriggerActionType {
  /** KYC Process action type. */
  KYC_PROCESS = 'KYC_PROCESS',
  /** Notification action type. */
  NOTIFICATION = 'NOTIFICATION',
  /** Questionnaire submission action type. */
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  /** Screening action type. */
  SCREENING = 'SCREENING'
}

/** KYCProcessTriggerConditionType is enum for the field KYCProcessCondition type */
export enum KYCProcessTriggerConditionType {
  /** KYC Process action type. */
  KYC_PROCESS = 'KYC_PROCESS',
  /** Questionnaire submission action type. */
  QUESTIONNAIRE = 'QUESTIONNAIRE'
}

/** Properties by which KYCProcessTrigger connections can be ordered. */
export enum KYCProcessTriggerOrderField {
  /** Order by Affiliate type field */
  AFFILIATE_TYPE = 'AFFILIATE_TYPE',
  /** Order by Anchorage entity type field */
  ANCHORAGE_ENTITY_TYPE = 'ANCHORAGE_ENTITY_TYPE',
  /** Order by Current JIRA State field */
  CURRENT_JIRA_STATE = 'CURRENT_JIRA_STATE',
  /** Order by ID field */
  ID = 'ID',
  /** Order by JIRA Case Type field */
  JIRA_CASE_TYPE = 'JIRA_CASE_TYPE',
  /** Order by Previous JIRA State field */
  PREVIOUS_JIRA_STATE = 'PREVIOUS_JIRA_STATE',
  /** Order by Priority */
  PRIORITY = 'PRIORITY'
}

/** Properties by which KYCQuestion connections can be ordered. */
export enum KYCQuestionOrderField {
  /** Order by Created At field */
  CREATED_AT = 'CREATED_AT',
  /** Order by ID field */
  ID = 'ID',
  /** Order by Information Type field */
  INFORMATION_TYPE = 'INFORMATION_TYPE'
}

/** KYCQuestionRuleType describes the validation preformed by the rule */
export enum KYCQuestionRuleType {
  /** ADD_DAYS is a rule that checks if the date answer is within a given number of days from the current date */
  ADD_DAYS = 'ADD_DAYS',
  /** CHECK_SUM is a rule that checks if the sum of the answers for a given question inside a group equals a given value */
  CHECK_SUM = 'CHECK_SUM',
  /** DISTINCT is a rule that checks if a given question inside a group has unique answers */
  DISTINCT = 'DISTINCT',
  /** MAX_DATE is the maximum accepted date in the answer for date type questions */
  MAX_DATE = 'MAX_DATE',
  /** MAX_LENGTH is a maximum amount of characters or items in the answer */
  MAX_LENGTH = 'MAX_LENGTH',
  /** MAX_SUM is a rule that checks if the sum of the answers for a given question inside a group is below a given value */
  MAX_SUM = 'MAX_SUM',
  /** MIN_DATE is the minimum accepted date in the answer for date type questions */
  MIN_DATE = 'MIN_DATE',
  /** MIN_LENGTH is a minimum amount of characters or items in the answer */
  MIN_LENGTH = 'MIN_LENGTH',
  /** MIN_SUM is a rule that checks if the sum of the answers for a given question inside a group is above a given value */
  MIN_SUM = 'MIN_SUM',
  /** REGEX is a rule that contains a regex expression that is validated against the the answer text */
  REGEX = 'REGEX'
}

/** KYCQuestionType is the type of the questionnaire question */
export enum KYCQuestionType {
  /** CARD_SELECT allows the selection of one option in a list displayed as cards */
  CARD_SELECT = 'CARD_SELECT',
  /** CHECKBOX allows for the selection of multiple options in a list, adequate for short lists, may be optional */
  CHECKBOX = 'CHECKBOX',
  /** CHIP allows the selection of one option in a list, adequate for selecting words in short lists */
  CHIP = 'CHIP',
  /** CONFIRMATION is a question type to confirm a section of information */
  CONFIRMATION = 'CONFIRMATION',
  /** DATE is a question picker input type, should be answered with freeform text in the form of MM/DD/YYYY, may be optional */
  DATE = 'DATE',
  /** DROPDOWN allows the selection of one option in a list, adequate for long lists */
  DROPDOWN = 'DROPDOWN',
  /** DROPDOWN_MULTI_SELECT allows for the selection of multiple options in a list, adequate for long lists, may be optional */
  DROPDOWN_MULTI_SELECT = 'DROPDOWN_MULTI_SELECT',
  /** GROUP enables a group of questions to be answered multiple times, may be optional, use MAX_LENGTH/MIN_LENGTH to specify the required number of times to answer the questions, answers must be given ion the answer group */
  GROUP = 'GROUP',
  /** RADIO allows the selection of one option in a list, adequate for short lists */
  RADIO = 'RADIO',
  /** RADIO_GROUP enables a group of questions when the corresponding option is selected, the sub questions are answered as regular questions, may be optional */
  RADIO_GROUP = 'RADIO_GROUP',
  /** SECTION indicates the question is to be handled as a section describing the next questions, cannot receive answers */
  SECTION = 'SECTION',
  /** SUMMARY is also free text input, may be optional */
  SUMMARY = 'SUMMARY',
  /** TEXTFIELD is a free text input, may be optional */
  TEXTFIELD = 'TEXTFIELD',
  /** TEXT_AREA is also free text input, may be optional */
  TEXT_AREA = 'TEXT_AREA',
  /** UPLOAD allows file uploads, may be optional */
  UPLOAD = 'UPLOAD',
  /** VOID is a no-op input type, cannot receive answers */
  VOID = 'VOID'
}

/** KYCQuestionnaireElementOrientation is enum for the KYCQuestionnaireElement orientation */
export enum KYCQuestionnaireElementLayoutOrientation {
  HORIZONTAL = 'HORIZONTAL',
  UNSPECIFIED = 'UNSPECIFIED',
  VERTICAL = 'VERTICAL'
}

/** KYCQuestionnaireElementLayoutType is enum for the KYCQuestionnaireElementLayout type */
export enum KYCQuestionnaireElementLayoutType {
  MODAL = 'MODAL',
  SUB_BLOCK = 'SUB_BLOCK',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** KYCQuestionnaireElementType is enum for the KYCQuestionnaireElement type */
export enum KYCQuestionnaireElementType {
  BLOCK = 'BLOCK',
  NAV = 'NAV',
  QUESTION = 'QUESTION',
  QUESTIONNAIRE = 'QUESTIONNAIRE'
}

/** Properties by which KYCQuestionnaire connections can be ordered. */
export enum KYCQuestionnaireOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  NAME = 'NAME',
  QUESTIONNAIRE_TYPE = 'QUESTIONNAIRE_TYPE'
}

/** Properties by which KYCQuestionnaireSubmission connections can be ordered. */
export enum KYCQuestionnaireSubmissionOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  STATE = 'STATE'
}

/** KYCQuestionnaireSubmissionState is enum for the field state */
export enum KYCQuestionnaireSubmissionState {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  INIT = 'INIT',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_REVIEW = 'IN_REVIEW'
}

/** KYCQuestionnaireType is enum for the field questionnaire_type */
export enum KYCQuestionnaireType {
  KYC_ONBOARDING = 'KYC_ONBOARDING',
  KYC_QC = 'KYC_QC',
  KYC_REVIEW = 'KYC_REVIEW',
  RIA_TERMS = 'RIA_TERMS',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** KYCType specifies different entity types of KYC Information */
export enum KYCType {
  /** AFFILIATE */
  AFFILIATE = 'AFFILIATE',
  /** CLIENT */
  CLIENT = 'CLIENT',
  /** INDIVIDUAL */
  INDIVIDUAL = 'INDIVIDUAL'
}

/** KeyStatus represents the status of a key. */
export enum KeyStatus {
  /** The key will become active after some required action and cannot yet be used. */
  PENDING = 'PENDING',
  /** The key was revoked/removed and cannot be used anymore. */
  REVOKED = 'REVOKED',
  /** The key is ready to be used but hasn't been used yet. */
  UNUSED = 'UNUSED',
  /** The key has been used already. */
  USED = 'USED'
}

/** LendingAccessRequestStatus types */
export enum LendingAccessRequestStatus {
  /** Org has been approved to lend */
  ACCEPTED = 'ACCEPTED',
  /** Org lending request has not been created */
  NOT_CREATED = 'NOT_CREATED',
  /** Org has a pending request */
  PENDING = 'PENDING',
  /** Org has an unknown status */
  UNKNOWN = 'UNKNOWN'
}

/** Lending facility daily APY status */
export enum LendingFacilityDailyAPYStatus {
  /** Final daily APY */
  FINAL = 'FINAL',
  /** Pending daily APY */
  PENDING = 'PENDING'
}

/** Sorting options for lending facility fees */
export enum LendingFacilityFeeSortKey {
  /** Sort by 'Created at' option for lending facility fees */
  CREATED_AT = 'CREATED_AT',
  /** Sort by 'Facility id' option for lending facility fees */
  FACILITY_ID = 'FACILITY_ID',
  /** Sort by 'Fee date' option for lending facility fees */
  FEE_DATE = 'FEE_DATE'
}

/** Lending Facility Members role options */
export enum LendingFacilityMembershipRole {
  /** Borrower role for the lending facility member */
  BORROWER = 'BORROWER',
  /** Both Borrower and Lender role for the lending facility member */
  BOTH = 'BOTH',
  /** Lender role for the lending facility member */
  LENDER = 'LENDER'
}

/** Lending facility members sorting options */
export enum LendingFacilityMembershipSortKey {
  /** Sort by 'Created at' option for lending facility members */
  CREATED_AT = 'CREATED_AT',
  /** Sort by ID option for lending facility members */
  ID = 'ID',
  /** Sort by Status option for lending facility members */
  STATUS = 'STATUS'
}

/** Sorting options for lending facility members stakes */
export enum LendingFacilityMembershipStakeSortKey {
  /** Sort by Date option for lending facility members stakes */
  DATE = 'DATE',
  /** Sort by ID option for lending facility members stakes */
  ID = 'ID'
}

/** Lending Facility Members status options */
export enum LendingFacilityMembershipStatus {
  /** Active status for the lending facility member */
  ACTIVE = 'ACTIVE',
  /** Inactive status for the lending facility member */
  INACTIVE = 'INACTIVE'
}

/** Lending facility risk level */
export enum LendingFacilityRiskLevel {
  /** High risk level for lending facility */
  HIGH = 'HIGH',
  /** Low risk level for lending facility */
  LOW = 'LOW',
  /** Medium risk level for lending facility */
  MEDIUM = 'MEDIUM'
}

/** Lending facilities sorting options */
export enum LendingFacilitySortKey {
  /** Sort by Asset Type option for lending facilities */
  ASSET_TYPE = 'ASSET_TYPE',
  /** Sort by 'Created at' option for lending facilities */
  CREATED_AT = 'CREATED_AT',
  /** Sort by Description option for lending facilities */
  DESCRIPTION = 'DESCRIPTION',
  /** Sort by Fee option for lending facilities */
  FEE = 'FEE',
  /** Sort by ID option for lending facilities */
  ID = 'ID',
  /** Sort by Name option for lending facilities */
  NAME = 'NAME',
  /** Sort by Risk Level option for lending facilities */
  RISK_LEVEL = 'RISK_LEVEL'
}

/** Lending Facility state */
export enum LendingFacilityState {
  /** Active state for lending facility */
  ACTIVE = 'ACTIVE',
  /** Deleted state for lending facility */
  DELETED = 'DELETED',
  /** Inactive state for lending facility */
  INACTIVE = 'INACTIVE'
}

/** Lending facility transaction type */
export enum LendingFacilityTransactionPaymentMethod {
  /** Crypto transaction payment */
  PAYMENT_METHOD_CRYPTO = 'PAYMENT_METHOD_CRYPTO',
  /** Wire transaction payment */
  PAYMENT_METHOD_WIRE = 'PAYMENT_METHOD_WIRE'
}

/** Sorting options for lending facility transaction */
export enum LendingFacilityTransactionSortKey {
  /** Sort by 'Asset type' option for lending facility transaction */
  ASSET_TYPE = 'ASSET_TYPE',
  /** Sort by ID option for lending facility transaction */
  ID = 'ID',
  /** Sort by 'Transaction ID' option for lending facility transaction */
  TRANSACTION_ID = 'TRANSACTION_ID',
  /** Sort by 'Transaction type' option for lending facility transaction */
  TRANSACTION_TYPE = 'TRANSACTION_TYPE'
}

/** Lending facility transaction status */
export enum LendingFacilityTransactionStatus {
  /** Approved status for lending facility transaction */
  APPROVED = 'APPROVED',
  /** Canceled transfer status for lending facility transaction */
  CANCELED = 'CANCELED',
  /** Failed status for lending facility transaction */
  FAILED = 'FAILED',
  /** Pending status for lending facility transaction */
  PENDING = 'PENDING',
  /** Pending transfer status for lending facility transaction */
  PENDING_TRANSFER = 'PENDING_TRANSFER',
  /** Processed status for lending facility transaction */
  PROCESSED = 'PROCESSED',
  /** Rejected transfer status for lending facility transaction */
  REJECTED = 'REJECTED'
}

/** Lending facility transaction type */
export enum LendingFacilityTransactionType {
  /** CLOSEOUT transaction type */
  CLOSEOUT = 'CLOSEOUT',
  /** Deposit transaction type */
  DEPOSIT = 'DEPOSIT',
  /** Interest transaction type */
  INTEREST = 'INTEREST',
  /** INTEREST_WITHDRAWAL transaction type */
  INTEREST_WITHDRAWAL = 'INTEREST_WITHDRAWAL',
  /** REINVESTMENT transaction type */
  REINVESTMENT = 'REINVESTMENT',
  /** Withdrawal transaction type */
  WITHDRAWAL = 'WITHDRAWAL'
}

/** LendingInvoiceStatus represents the statuses of lending invoice */
export enum LendingInvoiceStatus {
  /** Invoice that was either overpaid or partially settled when a new invoice was generated. The remaing balance was carried to the new invoice */
  CARRIED_FORWARD = 'CARRIED_FORWARD',
  /** Amount paid was greater than due amount. Difference will be credited in the next invoice */
  OVERPAID = 'OVERPAID',
  /** Partially paid */
  PARTIALLY_SETTLED = 'PARTIALLY_SETTLED',
  /** Paid */
  SETTLED = 'SETTLED',
  /** Missing payment */
  UNSETTLED = 'UNSETTLED'
}

/** LendingJobHistoryState indicates the state of a job */
export enum LendingJobHistoryState {
  /** COMPLETED represents a completed job state */
  COMPLETED = 'COMPLETED',
  /** FAILED represents a failed job state */
  FAILED = 'FAILED',
  /** PROCESSING represents a processing job state */
  PROCESSING = 'PROCESSING',
  /** REVERTED represents a reverted job state */
  REVERTED = 'REVERTED',
  /** SCHEDULED represents a scheduled job state */
  SCHEDULED = 'SCHEDULED'
}

/** LendingJobHistoryType indicates the type of a job */
export enum LendingJobHistoryType {
  /** BORROWER_INTEREST represents a borrower interest job type */
  BORROWER_INTEREST = 'BORROWER_INTEREST',
  /** BORROWER_INVOICE represents a invoice job type */
  BORROWER_INVOICE = 'BORROWER_INVOICE',
  /** DAILY_ALL represents a daily combined (rewards and stakes) job history record type */
  DAILY_ALL = 'DAILY_ALL',
  /** DAILY_REWARD represents a daily reward job history record type */
  DAILY_REWARD = 'DAILY_REWARD',
  /** DAILY_STAKE represents a daily stake job history record type */
  DAILY_STAKE = 'DAILY_STAKE',
  /** LATE_FEE represents a late fee job type */
  LATE_FEE = 'LATE_FEE',
  /** LOAN represents a loan job type */
  LOAN = 'LOAN',
  /** MONTHLY represents a monthly job history record type */
  MONTHLY = 'MONTHLY',
  /** REVERT represents a job that reverts other jobs */
  REVERT = 'REVERT',
  /** REWARDS_APPROVAL represents a rewards approval job history record type */
  REWARDS_APPROVAL = 'REWARDS_APPROVAL'
}

/** LendingSide is the entity type */
export enum LendingSide {
  /** BORROWER is lending entity type borrower */
  BORROWER = 'BORROWER',
  /** BOTH is lending entity type both lender and borrower */
  BOTH = 'BOTH',
  /** LENDER is lending entity type lender */
  LENDER = 'LENDER'
}

/** Transfer requests sorting options */
export enum LendingTransferRequestSortKey {
  /** Sort by 'Approved at' option for transfer requests */
  APPROVED_AT = 'APPROVED_AT',
  /** Sort by 'Created at' option for transfer requests */
  CREATED_AT = 'CREATED_AT',
  /** Sort by ID option for transfer requests */
  ID = 'ID'
}

export enum LiquidationInstructionType {
  COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_AT_END_CURE = 'COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_AT_END_CURE',
  COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_MARGIN_CALL_ONLY = 'COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_MARGIN_CALL_ONLY',
  COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_NONE = 'COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_NONE',
  COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_PRIORITY = 'COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_PRIORITY',
  COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_UNSPECIFIED = 'COLLATERAL_AGREEMENT_LIQUIDATION_TYPE_UNSPECIFIED'
}

/** Approval type for Loan */
export enum LoanApprovalType {
  /** Pre approved type */
  PRE_APPROVED = 'PRE_APPROVED',
  /** Three approvals required */
  THREE_APPROVALS = 'THREE_APPROVALS'
}

/** Loan term status */
export enum LoanTermStatus {
  /** Loan active */
  ACTIVE = 'ACTIVE',
  /** Loan canceled */
  CANCELED = 'CANCELED',
  /** Loan paid/closed */
  CLOSED = 'CLOSED',
  /** Awaiting collateral */
  COLLATERAL_PENDING = 'COLLATERAL_PENDING',
  /** Loan denied */
  DENIED = 'DENIED',
  /** Pending approval */
  PENDING = 'PENDING',
  /** Approved by risk */
  RISK_APPROVED = 'RISK_APPROVED'
}

/** Loan types */
export enum LoanType {
  /** Regular default loan */
  DEFAULT = 'DEFAULT',
  /** FCM loan */
  FCM = 'FCM',
  /** Filecoin loan */
  FILECOIN = 'FILECOIN',
  /** Mining loan */
  MINING_LEASE = 'MINING_LEASE',
  /** Share backed loan */
  SHARE_BACKED = 'SHARE_BACKED'
}

/** This list should match the list in lib/std/logger/logger.go */
export enum LoggerComponent {
  /** component defined in logger.go */
  AUTHORIZATION = 'AUTHORIZATION',
  /** component defined in logger.go */
  BILLING = 'BILLING',
  /** component defined in logger.go */
  BLOCKCHAIN = 'BLOCKCHAIN',
  /** component defined in logger.go */
  CLIENT_ENTITIES = 'CLIENT_ENTITIES',
  /** component defined in logger.go */
  COLLATERAL = 'COLLATERAL',
  /** component defined in logger.go */
  COMPLIANCE_ALERTS = 'COMPLIANCE_ALERTS',
  /** component defined in logger.go */
  CUSTODY = 'CUSTODY',
  /** component defined in logger.go */
  DEPOSIT_ATTRIBUTION = 'DEPOSIT_ATTRIBUTION',
  /** component defined in logger.go */
  IDENTITY = 'IDENTITY',
  /** component defined in logger.go */
  KYC = 'KYC',
  /** component defined in logger.go */
  LENDING = 'LENDING',
  /** component defined in logger.go */
  NOTIFICATION = 'NOTIFICATION',
  /** component defined in logger.go */
  ORGANIZATION = 'ORGANIZATION',
  /** component defined in logger.go */
  REPORTING = 'REPORTING',
  /** component defined in logger.go */
  RISK_REVIEW = 'RISK_REVIEW',
  /** component defined in logger.go */
  SETTLEMENT_NETWORK = 'SETTLEMENT_NETWORK',
  /** component defined in logger.go */
  TAX = 'TAX',
  /** component defined in logger.go */
  TRADING = 'TRADING'
}

/** Method indicates the fx rate used in RFQ */
export enum Method {
  /** Direct method for FX rate calculation */
  DIRECT = 'DIRECT',
  /** Synthetic method for FX rate calculation */
  SYNTHETIC = 'SYNTHETIC'
}

/** MonthlyLendingJobRequestType indicates which monthly jobs to run */
export enum MonthlyLendingJobRequestType {
  /** BORROWER_INVOICE indicates borrower invoice job */
  BORROWER_INVOICE = 'BORROWER_INVOICE',
  /** REINVESTMENT indicates lender reward reinvestment job */
  REINVESTMENT = 'REINVESTMENT'
}

/** NoticeType is an Enum representing the severity of a Notice. */
export enum NoticeType {
  /** Error notice */
  ERROR = 'ERROR',
  /** Information notice */
  INFO = 'INFO',
  /** Warning notice */
  WARNING = 'WARNING'
}

/** OffchainVestingScheduleStatus is the offchain vesting schedule status */
export enum OffchainVestingScheduleStatus {
  /** Currently vesting */
  ACTIVE = 'ACTIVE',
  /** Vesting canceled */
  CANCELED = 'CANCELED',
  /** Fully vested */
  COMPLETE = 'COMPLETE',
  /** Vesting schedule not yet created */
  DRAFT = 'DRAFT'
}

/** OnboardingStatus signals the step for onboarding of the StagedUser */
export enum OnboardingStatus {
  /** COMPLETE is the last onboarding step */
  COMPLETE = 'COMPLETE',
  /** COMPLETE_WITH_EXEMPTED_PIIS is the last step if PIIs were exempted by the team */
  COMPLETE_WITH_EXEMPTED_PIIS = 'COMPLETE_WITH_EXEMPTED_PIIS',
  /** PIIS is the second step of onboarding: waiting for PIIs */
  PIIS = 'PIIS',
  /** QRSCAN is the first step of onboarding: waiting for QR code scan */
  QRSCAN = 'QRSCAN'
}

/** OneOffParamType determines the type of the value for a one-off operation parameter */
export enum OneOffParamType {
  /** A string representation of an Amount in JSON */
  AMOUNT = 'AMOUNT',
  /** A string representation of an Amount as a decimal string */
  AMOUNT_DECIMAL = 'AMOUNT_DECIMAL',
  /** schema.AssetTypeAddress, marshaled to JSON */
  ASSET_TYPE_ADDRESS = 'ASSET_TYPE_ADDRESS',
  /** A normal string address */
  ASSET_TYPE_ADDRESS_STRING = 'ASSET_TYPE_ADDRESS_STRING',
  /** human readable boolean value */
  BOOLEAN = 'BOOLEAN',
  /** hex encoded binary */
  BYTES = 'BYTES',
  /** An Edgeware address used for signaling where airdropped edgeware assets should be sent */
  EDGEWARE_PUBLIC_KEY = 'EDGEWARE_PUBLIC_KEY',
  /** A string representation of a SignedAddress in JSON */
  SIGNED_ADDRESS = 'SIGNED_ADDRESS',
  /** uint256, represented as an ascii number */
  UINT256 = 'UINT256'
}

/** OneOffType is the type of one-off operation */
export enum OneOffType {
  /** Setup Governance for AAVE */
  AAVE_GOVERNANCE_SETUP = 'AAVE_GOVERNANCE_SETUP',
  /** Setup Governance for AAVE by type */
  AAVE_GOVERNANCE_SETUP_BY_TYPE = 'AAVE_GOVERNANCE_SETUP_BY_TYPE',
  /** Increases lockup period of staked assets to enable voting */
  APTOS_INCREASE_LOCKUP_PERIOD = 'APTOS_INCREASE_LOCKUP_PERIOD',
  /** Rotate consensus key for aptos account */
  APTOS_ROTATE_CONSENSUS_KEY = 'APTOS_ROTATE_CONSENSUS_KEY',
  /** Change beneficiary address of vesting contract for Aptos account */
  APTOS_SET_BENEFICIARY = 'APTOS_SET_BENEFICIARY',
  /** Change operator address for Aptos account */
  APTOS_SET_OPERATOR = 'APTOS_SET_OPERATOR',
  /** Change voter address for Aptos account */
  APTOS_SET_VOTER = 'APTOS_SET_VOTER',
  /** Update Operator Commission */
  APTOS_UPDATE_OPERATOR_COMMISSION = 'APTOS_UPDATE_OPERATOR_COMMISSION',
  /** Withdraw unlocked tokens from vesting contract */
  APTOS_VESTING_ADMIN_WITHDRAW = 'APTOS_VESTING_ADMIN_WITHDRAW',
  /** Set a management role on a vesting contract */
  APTOS_VESTING_SET_MANAGEMENT_ROLE = 'APTOS_VESTING_SET_MANAGEMENT_ROLE',
  /** Terminate a vesting contract */
  APTOS_VESTING_TERMINATE_CONTRACT = 'APTOS_VESTING_TERMINATE_CONTRACT',
  /** Withdraw already unlocked funds */
  APTOS_WITHDRAW_UNLOCKED_STAKE = 'APTOS_WITHDRAW_UNLOCKED_STAKE',
  /** Migrate an aragon v1 address to aragon v2 */
  ARAGON_MIGRATE_V1_TO_V2 = 'ARAGON_MIGRATE_V1_TO_V2',
  /** Request the legacy augur contract to approve the conversion */
  AUGUR_APPROVE_MIGRATION = 'AUGUR_APPROVE_MIGRATION',
  /** Migrate an already approved augur address to Augur v2 */
  AUGUR_MIGRATE_V1_TO_V2 = 'AUGUR_MIGRATE_V1_TO_V2',
  /** Authorizes a key to be used for participation in Celo's lightweight identity protocol */
  AUTHORIZE_ATTESTATION_SIGNER = 'AUTHORIZE_ATTESTATION_SIGNER',
  /** Authorizes a key to participate in BFT consensus, and register and manage a Validator or Validator Group */
  AUTHORIZE_VALIDATOR_SIGNER = 'AUTHORIZE_VALIDATOR_SIGNER',
  /** Authorizes a key for validator key rotation */
  AUTHORIZE_VALIDATOR_SIGNER_WITH_KEYS = 'AUTHORIZE_VALIDATOR_SIGNER_WITH_KEYS',
  /** Authorizes another key to sign on our behalf for the purposes of voting on the blockchain */
  AUTHORIZE_VOTE_SIGNER = 'AUTHORIZE_VOTE_SIGNER',
  /** AUTO_COMPOUND is a one-off operation that automatically compounds the rewards of a staking asset */
  AUTO_COMPOUND = 'AUTO_COMPOUND',
  /** Votes for Celo Validator groups may be activated once a new epoch begins */
  CELO_ACTIVATE = 'CELO_ACTIVATE',
  /** Moves celo token funds from the vesting contract to our address */
  CELO_CLAIM_TOKEN = 'CELO_CLAIM_TOKEN',
  /** Creates an account on the blockchain that's used for participation */
  CELO_CREATE_ACCOUNT = 'CELO_CREATE_ACCOUNT',
  /** Cancels an in-progress unlocking and re-locks some of the gold from it */
  CELO_RELOCK = 'CELO_RELOCK',
  /** Revokes pending votes that are not active */
  CELO_REVOKE_PENDING = 'CELO_REVOKE_PENDING',
  /** Change the expiration setting on a ReleaseGold contract */
  CELO_SET_CAN_EXPIRE = 'CELO_SET_CAN_EXPIRE',
  /** Set the Metadata URL of an Account or ReleaseGold contract */
  CELO_SET_METADATA_URL = 'CELO_SET_METADATA_URL',
  /** Set the name of an Account or ReleaseGold contract */
  CELO_SET_NAME = 'CELO_SET_NAME',
  /** Withdraws recently unlocked funds from the LockGold contract into our vesting contract */
  CELO_WITHDRAW_LOCKED = 'CELO_WITHDRAW_LOCKED',
  /** Moves funds from the vesting contract into our address */
  CLAIM_VESTED = 'CLAIM_VESTED',
  /** Setup Governance for COMP */
  COMP_GOVERNANCE_SETUP = 'COMP_GOVERNANCE_SETUP',
  /** dYdX Bridge v3 to v4 tokens */
  DYDX_BRIDGE_CHAINS = 'DYDX_BRIDGE_CHAINS',
  /** Setup Governance for dYdX */
  DYDX_GOVERNANCE_SETUP = 'DYDX_GOVERNANCE_SETUP',
  /** Setup Governance for dYdX by type */
  DYDX_GOVERNANCE_SETUP_BY_TYPE = 'DYDX_GOVERNANCE_SETUP_BY_TYPE',
  /** Edgeware signaling for the one time airdrop signaling on Ethereum, expired September 1st, 2019 */
  EDGEWARE_SIGNALING = 'EDGEWARE_SIGNALING',
  /** Filecoin add signer to multisig actor */
  FILECOIN_ADD_SIGNER = 'FILECOIN_ADD_SIGNER',
  /** Filecoin miner change of ownership address */
  FILECOIN_MINER_CHANGE_OWNER_ADDRESS = 'FILECOIN_MINER_CHANGE_OWNER_ADDRESS',
  /** Confirm change to worker address for FIL miners */
  FILECOIN_MINER_CONFIRM_WORKER_ADDRESS_CHANGE = 'FILECOIN_MINER_CONFIRM_WORKER_ADDRESS_CHANGE',
  /** Change worker and control addresses for FIL miners */
  FILECOIN_MINER_ROTATE_WORKER_AND_CONTROL_ADDRESSES = 'FILECOIN_MINER_ROTATE_WORKER_AND_CONTROL_ADDRESSES',
  /** Terminates sectors for FIL miners */
  FILECOIN_MINER_TERMINATE_SECTORS = 'FILECOIN_MINER_TERMINATE_SECTORS',
  /** Filecoin withdrawal miner balance */
  FILECOIN_MINER_WITHDRAW_BALANCE = 'FILECOIN_MINER_WITHDRAW_BALANCE',
  /** Moves funds from the signing contract to our address */
  FILECOIN_PROPOSE = 'FILECOIN_PROPOSE',
  /** Filecoin remove signer from multisig actor */
  FILECOIN_REMOVE_SIGNER = 'FILECOIN_REMOVE_SIGNER',
  /** Filecoin swap signer within multisig actor */
  FILECOIN_SWAP_SIGNER = 'FILECOIN_SWAP_SIGNER',
  /** Flow claim unstaked */
  FLOW_CLAIM_UNSTAKED = 'FLOW_CLAIM_UNSTAKED',
  /** Flow claim vested */
  FLOW_CLAIM_VESTED = 'FLOW_CLAIM_VESTED',
  /** Create new Flow Account */
  FLOW_CREATE_ACCOUNT = 'FLOW_CREATE_ACCOUNT',
  /** Flow delegate rewards */
  FLOW_DELEGATE_REWARDS = 'FLOW_DELEGATE_REWARDS',
  /** Set up Flow Staking Collection */
  FLOW_SETUP_STAKING_COLLECTION = 'FLOW_SETUP_STAKING_COLLECTION',
  /** Flow stake unstaked */
  FLOW_STAKE_UNSTAKED = 'FLOW_STAKE_UNSTAKED',
  /** Flow claim rewards */
  FLOW_WITHDRAW_REWARDS = 'FLOW_WITHDRAW_REWARDS',
  /** Moves KEEP Token funds from the vesting contract into our address */
  KEEP_CLAIM_VESTED = 'KEEP_CLAIM_VESTED',
  /** Locks funds for participation */
  LOCK = 'LOCK',
  /** Unlock maker on behalf of the client */
  MAKER_V1_1_UNLOCK = 'MAKER_V1_1_UNLOCK',
  /** Approve the vote delegate contract to move MKR tokens */
  MAKER_VOTE_DELEGATE_APPROVE = 'MAKER_VOTE_DELEGATE_APPROVE',
  /** Approve the vote delegate contract to move IOU tokens */
  MAKER_VOTE_DELEGATE_APPROVE_IOU = 'MAKER_VOTE_DELEGATE_APPROVE_IOU',
  /** Free assets from Maker Vote Delegate */
  MAKER_VOTE_DELEGATE_FREE = 'MAKER_VOTE_DELEGATE_FREE',
  /** Lock assets to Maker Vote Delegate */
  MAKER_VOTE_DELEGATE_LOCK = 'MAKER_VOTE_DELEGATE_LOCK',
  /** Approve the vote proxy contract to transfer to lock */
  MAKER_VOTE_PROXY_APPROVE = 'MAKER_VOTE_PROXY_APPROVE',
  /** Initiate Link with an External Address through the Maker Vote Proxy */
  MAKER_VOTE_PROXY_FACTORY_INITIATE_LINK = 'MAKER_VOTE_PROXY_FACTORY_INITIATE_LINK',
  /** Setup Governance for NOTE */
  NOTE_GOVERNANCE_SETUP = 'NOTE_GOVERNANCE_SETUP',
  /** Amend the commission schedule on the Oasis blockchain */
  OASIS_AMEND_COMMISSION_SCHEDULE = 'OASIS_AMEND_COMMISSION_SCHEDULE',
  /** Register an entity on the Oasis blockchain */
  OASIS_REGISTER_ENTITY = 'OASIS_REGISTER_ENTITY',
  /** Create Solana nonce accounts */
  SOLANA_CREATE_NONCE_ACCOUNT = 'SOLANA_CREATE_NONCE_ACCOUNT',
  /** Withdraw from Solana stake account */
  SOLANA_WITHDRAW_STAKE = 'SOLANA_WITHDRAW_STAKE',
  /** Deploy a new starknet account using UDC */
  STARKNET_UDC_DEPLOY_ACCOUNT = 'STARKNET_UDC_DEPLOY_ACCOUNT',
  /** Setup Governance for staked AAVE */
  STKAAVE_GOVERNANCE_SETUP = 'STKAAVE_GOVERNANCE_SETUP',
  /** Setup Governance for staked AAVE by type */
  STKAAVE_GOVERNANCE_SETUP_BY_TYPE = 'STKAAVE_GOVERNANCE_SETUP_BY_TYPE',
  /** Claim rewards of tendermint assets */
  TENDERMINT_CLAIM_REWARDS = 'TENDERMINT_CLAIM_REWARDS',
  /** Tezos sweep moves funds from a KT1 address to a tz1 address for the same asset. This is part of the babylon migration of Oct 2019. */
  TEZOS_SWEEP = 'TEZOS_SWEEP',
  /** Setup Governance for UNI */
  UNI_GOVERNANCE_SETUP = 'UNI_GOVERNANCE_SETUP',
  /** Unlocks locked funds */
  UNLOCK = 'UNLOCK',
  /** DEPRECATED USE DYDX_BRIDGE_CHAINS: WETH dYdX Bridge v3 to v4 tokens */
  WETHDYDX_BRIDGE_CHAINS = 'WETHDYDX_BRIDGE_CHAINS',
  /** Setup Governance for Wrapped Ethereum dYdX */
  WETHDYDX_GOVERNANCE_SETUP = 'WETHDYDX_GOVERNANCE_SETUP',
  /** Setup Governance for Wrapped Ethereum dYdX by type */
  WETHDYDX_GOVERNANCE_SETUP_BY_TYPE = 'WETHDYDX_GOVERNANCE_SETUP_BY_TYPE'
}

/** The Action an Operation represents */
export enum OperationAction {
  /** AUTHORIZE_SPENDER is a request to authorize an address to transfer an amount on this asset's behalf */
  AUTHORIZE_SPENDER = 'AUTHORIZE_SPENDER',
  /** BALANCE_ADJUSTMENT is a balance adjustment */
  BALANCE_ADJUSTMENT = 'BALANCE_ADJUSTMENT',
  /** CREATE_VAULT is a request to create a new vault */
  CREATE_VAULT = 'CREATE_VAULT',
  /** DELEGATE is a request to delegate vault assets to be staked, baked, inflation pool, etc. */
  DELEGATE = 'DELEGATE',
  /** DEPOSIT is a request for an address at which assets may be deposited */
  DEPOSIT = 'DEPOSIT',
  /** DEPOSIT_EVENT is an observed deposit */
  DEPOSIT_EVENT = 'DEPOSIT_EVENT',
  /** GENERIC is an action to arbitrary operations */
  GENERIC = 'GENERIC',
  /** GENERIC_ORGANIZATION_CHANGE is an action to arbitrary operations */
  GENERIC_ORGANIZATION_CHANGE = 'GENERIC_ORGANIZATION_CHANGE',
  /** LOCK is a request to lock funds to be used as part of governance, defi, etc. */
  LOCK = 'LOCK',
  /** OFFCHAIN_OPERATION is an action to perform offchain operation such as Snapshot Voting */
  OFFCHAIN_OPERATION = 'OFFCHAIN_OPERATION',
  /** ONE_OFF is a one-off operation action */
  ONE_OFF = 'ONE_OFF',
  /** ORGANIZATION_ADD is a request to create a new customer */
  ORGANIZATION_ADD = 'ORGANIZATION_ADD',
  /** ORGANIZATION_CHANGE represents a group of changes to the organization */
  ORGANIZATION_CHANGE = 'ORGANIZATION_CHANGE',
  /** ORGANIZATION_POLICY_CHANGE is a request to change an organization's policy */
  ORGANIZATION_POLICY_CHANGE = 'ORGANIZATION_POLICY_CHANGE',
  /** ORGANIZATION_POLICY_CHANGES is a request to change multiple organization's policies */
  ORGANIZATION_POLICY_CHANGES = 'ORGANIZATION_POLICY_CHANGES',
  /** ORGANIZATION_RECOVERY is a request to recover an org */
  ORGANIZATION_RECOVERY = 'ORGANIZATION_RECOVERY',
  /** ROLE_CHANGE is a request to change a role belonging to an organization */
  ROLE_CHANGE = 'ROLE_CHANGE',
  /** SIGN_TERMS_AND_CONDITIONS is an action to sign terms and conditions for offchain interactions - like for Dapps */
  SIGN_TERMS_AND_CONDITIONS = 'SIGN_TERMS_AND_CONDITIONS',
  /** TRANSFER is a request to transfer funds via API */
  TRANSFER = 'TRANSFER',
  /** TRUSTED_DESTINATION_ADD is a request to add a new trusted destination */
  TRUSTED_DESTINATION_ADD = 'TRUSTED_DESTINATION_ADD',
  /** TRUSTED_DESTINATION_REMOVE is a request to remove an existing trusted destination */
  TRUSTED_DESTINATION_REMOVE = 'TRUSTED_DESTINATION_REMOVE',
  /** TRUSTED_SMART_CONTRACT_ADD is a request to add a new trusted smart contract */
  TRUSTED_SMART_CONTRACT_ADD = 'TRUSTED_SMART_CONTRACT_ADD',
  /** TRUSTED_SMART_CONTRACT_REMOVE is a request to remove an existing trusted smart contract */
  TRUSTED_SMART_CONTRACT_REMOVE = 'TRUSTED_SMART_CONTRACT_REMOVE',
  /** UNLOCK is a request to unlock funds */
  UNLOCK = 'UNLOCK',
  /** USER_ADD is a request to add a user */
  USER_ADD = 'USER_ADD',
  /** USER_ADD_AND_POLICIES is a request to add an user and add it to policies */
  USER_ADD_AND_POLICIES = 'USER_ADD_AND_POLICIES',
  /** USER_REMOVE is a request to remove a user */
  USER_REMOVE = 'USER_REMOVE',
  /** USER_REMOVE_AND_POLICIES is a request to remove an user from the organization and its policies */
  USER_REMOVE_AND_POLICIES = 'USER_REMOVE_AND_POLICIES',
  /** USER_REPLACE is a request to replace a user within an org */
  USER_REPLACE = 'USER_REPLACE',
  /** VAULT_DISABLE is a request to disable a vault */
  VAULT_DISABLE = 'VAULT_DISABLE',
  /** VAULT_POLICY_CHANGE is a request to change a vault's policy */
  VAULT_POLICY_CHANGE = 'VAULT_POLICY_CHANGE',
  /** VAULT_POLICY_CHANGES is a request to change multiple vault's policies */
  VAULT_POLICY_CHANGES = 'VAULT_POLICY_CHANGES',
  /** VOTE is a request to vote */
  VOTE = 'VOTE',
  /** WITHDRAW is a request to withdraw assets */
  WITHDRAW = 'WITHDRAW'
}

/** Possible decisions available in response to an operation */
export enum OperationDecision {
  /** Approve the operation */
  APPROVE = 'APPROVE',
  /** Initiate the operation, but do not approve it */
  INITIATE = 'INITIATE',
  /** Reject the operation */
  REJECT = 'REJECT'
}

/** OperationSortFields are the options for sorting a list of operations */
export enum OperationSortFields {
  /** CREATED_AT is the time at which the operation was initiated */
  CREATED_AT = 'CREATED_AT',
  /** END_TIME is the time at which the operation was ended */
  END_TIME = 'END_TIME',
  /** STATE is the current state of the operation */
  STATE = 'STATE'
}

/**
 * OperationState is the state of the operation as it is processed by our system
 * These are not code-gen'd right now but they really should be
 */
export enum OperationState {
  /** ADDRESS_AVAILABLE is the state when an address has been generated by the HSM and we are waiting for a deposit */
  ADDRESS_AVAILABLE = 'ADDRESS_AVAILABLE',
  /** ADDRESS_REQUEST is the state when an address has been requested from the HSM, but it's not available yet */
  ADDRESS_REQUEST = 'ADDRESS_REQUEST',
  /**
   * BROADCASTABLE is the state when the transaction is ready for blockchain service to broadcast.
   * When a transaction is in this state and before it's moved to BROADCASTING state, if cancelation request
   * is received, the transaction can be successfully canceled.
   */
  BROADCASTABLE = 'BROADCASTABLE',
  /**
   * OperationStateBroadcasted is the state when the blockchain service *has* broadcasted the transaction,
   * but scanner hasn't detected the transaction on-chain yet.
   */
  BROADCASTED = 'BROADCASTED',
  /**
   * BROADCASTING is the state when the blockchain service is broadcasting the transaction.
   * When a transaction is in this state, user can still attempt to send a cancelation request - but
   * the request would be ignored unless the broadcasting encounters network congestion, in which case
   * the transaction would be moved to BROADCASTABLE state, allow cancelation to succeed.
   */
  BROADCASTING = 'BROADCASTING',
  /** CANCELED is the state when the operation has been canceled. */
  CANCELED = 'CANCELED',
  /** COMPLETE is the state when the action is complete */
  COMPLETE = 'COMPLETE',
  /**
   *  DEPOSIT_EVENT_ATTRIBUTION_REQUIRED is the state a deposit event has if it is already confirmed on the blockchain
   * but requires more information about the sources of the deposit (only appliable to regular deposits for ADB)
   */
  DEPOSIT_EVENT_ATTRIBUTION_REQUIRED = 'DEPOSIT_EVENT_ATTRIBUTION_REQUIRED',
  /** DEPOSIT_EVENT_DETECTED is the state a deposit event is created in when we detect it on the blockchain */
  DEPOSIT_EVENT_DETECTED = 'DEPOSIT_EVENT_DETECTED',
  /** ERRORED is the state when the action has experienced a fatal error */
  ERRORED = 'ERRORED',
  /** EXPIRED is the state when the action has timed out */
  EXPIRED = 'EXPIRED',
  /** FAILURE_COMMITTED means that the transaction was a failure on the blockchain and maybe only the fee was consumed */
  FAILURE_COMMITTED = 'FAILURE_COMMITTED',
  /** GAS_PENDING is the state when the operation is waiting for a deposit from the gas station */
  GAS_PENDING = 'GAS_PENDING',
  /** INITIATOR_EXPIRED is the state when initiator has not timely approved the operation, causing it auto-expired. */
  INITIATOR_EXPIRED = 'INITIATOR_EXPIRED',
  /** INITIATOR_PREPARED is the state when we ask the initiator of the operation to accept it before others process it, but, unlike in INITIATOR_REQUIRED, this state allows any number of operations to be created at the same time and the first one to be endorsed wins. */
  INITIATOR_PREPARED = 'INITIATOR_PREPARED',
  /** INITIATOR_REJECTED is the state operations go into if the initiator rejected them. The app is not expected to show these because they might be operations that were never endorsed in the first place and don't matter. */
  INITIATOR_REJECTED = 'INITIATOR_REJECTED',
  /** INITIATOR_REQUIRED is the state when we ask the initiator of the operation to accept it before others process it */
  INITIATOR_REQUIRED = 'INITIATOR_REQUIRED',
  /** OFFCHAIN_OPERATION_SIGNING is the state when the HSM is in the process of signing the OffChainOperation */
  OFFCHAIN_OPERATION_SIGNING = 'OFFCHAIN_OPERATION_SIGNING',
  /** ORGANIZATION_CHANGE_REQUEST is the state a organization change operation is in as soon as a user requests it, before it's processed by us */
  ORGANIZATION_CHANGE_REQUEST = 'ORGANIZATION_CHANGE_REQUEST',
  /** ORGANIZATION_SIGNING is the state when the HSM is in the process of signing the organization */
  ORGANIZATION_SIGNING = 'ORGANIZATION_SIGNING',
  /** ORG_CREATION_SIGNING is the state when the HSM is in  the process of signing a new organization */
  ORG_CREATION_SIGNING = 'ORG_CREATION_SIGNING',
  /** ORG_RISK_RECON is the state when we are performing risk recon but are not done gathering info yet for new orgs */
  ORG_RISK_RECON = 'ORG_RISK_RECON',
  /** QR_CODE_SCANNING is the state when we are waiting for users to scan the organization recovery QR Codes. */
  QR_CODE_SCANNING = 'QR_CODE_SCANNING',
  /** RISK_PENDING is the state when all risk data is ready and the risk team has to review the operation */
  RISK_PENDING = 'RISK_PENDING',
  /** RISK_RECON is the state when we are performing risk recon but are not done gathering info yet */
  RISK_RECON = 'RISK_RECON',
  /** RISK_REJECTED is the state when the risk team has rejected the operation */
  RISK_REJECTED = 'RISK_REJECTED',
  /** SIGS_FAILED is the state when a user has denied or canceled an operation */
  SIGS_FAILED = 'SIGS_FAILED',
  /** SIGS_REQUIRED is the state when we ask users to confirm an operation */
  SIGS_REQUIRED = 'SIGS_REQUIRED',
  /** TRANSACTION_REQUEST is the state a transaction operation is in as soon as a user requests it, before it's processed by us */
  TRANSACTION_REQUEST = 'TRANSACTION_REQUEST',
  /** TRANSACTION_SIGNING is the state when the HSM is in  the process of signing the transaction */
  TRANSACTION_SIGNING = 'TRANSACTION_SIGNING'
}

/** OrdRejReason represents */
export enum OrdRejReason {
  /** RejReasonBrokerOption represents */
  BROKER_OPTION = 'BROKER_OPTION',
  /** RejReasonDuplicateOrder represents */
  DUPLICATE_ORDER = 'DUPLICATE_ORDER',
  /** RejReasonExchangeClosed represents */
  EXCHANGE_CLOSED = 'EXCHANGE_CLOSED',
  /** RejReasonForceCancel represents */
  FORCE_CANCEL = 'FORCE_CANCEL',
  /** RejReasonImmediateOrderDidNotCross represents */
  IMMEDIATE_ORDER_DID_NOT_CROSS = 'IMMEDIATE_ORDER_DID_NOT_CROSS',
  /** RejReasonInternalError represents */
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  /** Invalid represents */
  INVALID = 'INVALID',
  /** RejReasonInvalidStrategy represents */
  INVALID_STRATEGY = 'INVALID_STRATEGY',
  /** RejReasonOrderExceedsLimit represents */
  ORDER_EXCEEDS_LIMIT = 'ORDER_EXCEEDS_LIMIT',
  /** RejReasonPostOnlyOrderWouldCross represents */
  POST_ONLY_ORDER_WOULD_CROSS = 'POST_ONLY_ORDER_WOULD_CROSS',
  /** RejReasonQuoteExpired represents */
  QUOTE_EXPIRED = 'QUOTE_EXPIRED',
  /** RejReasonQuoteRequestTimeout represents */
  QUOTE_REQUEST_TIMEOUT = 'QUOTE_REQUEST_TIMEOUT',
  /** RejReasonRateLimit represents */
  RATE_LIMIT = 'RATE_LIMIT',
  /** RejReasonStaleRequest represents */
  STALE_REQUEST = 'STALE_REQUEST',
  /** RejReasonTooLateToEnter represents */
  TOO_LATE_TO_ENTER = 'TOO_LATE_TO_ENTER',
  /** RejReasonUnknownMarket represents */
  UNKNOWN_MARKET = 'UNKNOWN_MARKET',
  /** RejReasonUnknownOrder represents */
  UNKNOWN_ORDER = 'UNKNOWN_ORDER',
  /** RejReasonUnknownSymbol represents */
  UNKNOWN_SYMBOL = 'UNKNOWN_SYMBOL'
}

/** OrdStatus represents */
export enum OrdStatus {
  /** OrdStatusCanceled represents */
  CANCELED = 'CANCELED',
  /** OrdStatusDoneForDay represents */
  DONE_FOR_DAY = 'DONE_FOR_DAY',
  /** OrdStatusFilled represents */
  FILLED = 'FILLED',
  /** Invalid represents */
  INVALID = 'INVALID',
  /** OrdStatusNew represents */
  NEW = 'NEW',
  /** OrdStatusPartiallyFilled represents */
  PARTIALLY_FILLED = 'PARTIALLY_FILLED',
  /** OrdStatusPendingCancel represents */
  PENDING_CANCEL = 'PENDING_CANCEL',
  /** OrdStatusPendingNew represents */
  PENDING_NEW = 'PENDING_NEW',
  /** OrdStatusPendingReplace represents */
  PENDING_REPLACE = 'PENDING_REPLACE',
  /** OrdStatusRejected represents */
  REJECTED = 'REJECTED'
}

/** OrdType represents */
export enum OrdType {
  /** Invalid represents */
  INVALID = 'INVALID',
  /** OrdTypeLimit represents */
  LIMIT = 'LIMIT',
  /** OrdTypeLimitAllIn represents */
  LIMIT_ALL_IN = 'LIMIT_ALL_IN',
  /** OrdTypeMarket represents */
  MARKET = 'MARKET',
  /** OrdTypeRFQ represents */
  RFQ = 'RFQ'
}

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  /** Specifies an ascending order for a given `orderBy` argument. */
  ASC = 'ASC',
  /** Specifies a descending order for a given `orderBy` argument. */
  DESC = 'DESC'
}

/** OrganizationModuleModuleType is enum for the field module_type */
export enum OrganizationModuleModuleType {
  BASE = 'BASE',
  SPECIFIC = 'SPECIFIC',
  UNSPECIFIED = 'UNSPECIFIED'
}

/** OrganizationPurposeType is enum for the field purpose */
export enum OrganizationPurpose {
  /** An organization containing asset information */
  ASSET_SUPPORT = 'ASSET_SUPPORT',
  /** An organization containing quorum recovery information */
  QUORUM_RECOVERY = 'QUORUM_RECOVERY'
}

/** Trading availability for an organization */
export enum OrganizationTradingStatus {
  /** Trading disabled */
  DISABLED = 'DISABLED',
  /** Trading enabled */
  ENABLED = 'ENABLED'
}

/** PII Information Types */
export enum PIIInformationType {
  /** App access desired. E.g. Admin, regular, none. */
  ACCESS_TYPE = 'ACCESS_TYPE',
  /** First part of the address, usually door number and street */
  ADDRESS = 'ADDRESS',
  /** Second part of the address, usually city, state and zip code */
  ADDRESS2 = 'ADDRESS2',
  /** CITY */
  CITY = 'CITY',
  /** Country */
  COUNTRY = 'COUNTRY',
  /** Date of birth */
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  /** Email */
  EMAIL = 'EMAIL',
  /** First Name */
  FIRST_NAME = 'FIRST_NAME',
  /** ID Card back side picture */
  ID_CARD_BACK = 'ID_CARD_BACK',
  /** ID Card front side picture */
  ID_CARD_FRONT = 'ID_CARD_FRONT',
  /** The type of individual. E.g. beneficial owner, authorized person, participating member */
  INDIVIDUAL_TYPE = 'INDIVIDUAL_TYPE',
  /** Last Name */
  LAST_NAME = 'LAST_NAME',
  /** Name */
  NAME = 'NAME',
  /** Phone number */
  PHONE = 'PHONE',
  /** Social security number */
  SSN = 'SSN',
  /** State */
  STATE = 'STATE',
  /** Voice and video recording */
  VOICE_PHRASE = 'VOICE_PHRASE',
  /** Zip code */
  ZIP_CODE = 'ZIP_CODE'
}

/** Rejection reasons for PII entries */
export enum PIIRequirementReason {
  /** The image is too blurry */
  BLURRY = 'BLURRY',
  /** The submitted value was inconclusive */
  INCONCLUSIVE = 'INCONCLUSIVE',
  /** The submitted value is invalid */
  INVALID = 'INVALID',
  /** The information type is missing */
  MISSING = 'MISSING',
  /** Other */
  OTHER = 'OTHER'
}

/** Properties by which Party connections can be ordered. */
export enum PartyOrderField {
  ACCOUNT_ID = 'ACCOUNT_ID',
  ACCOUNT_NAME = 'ACCOUNT_NAME',
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  ORG_ID = 'ORG_ID',
  ORG_NAME = 'ORG_NAME',
  STATUS = 'STATUS',
  TYPE_ID = 'TYPE_ID'
}

/** PartyStatus is enum for the field status */
export enum PartyStatus {
  PARTY_STATUS_DELETED = 'PARTY_STATUS_DELETED',
  PARTY_STATUS_DISABLED = 'PARTY_STATUS_DISABLED',
  PARTY_STATUS_ENABLED = 'PARTY_STATUS_ENABLED',
  PARTY_STATUS_UNSPECIFIED = 'PARTY_STATUS_UNSPECIFIED'
}

/** PartyTypeID is enum for the field type_id */
export enum PartyTypeID {
  PARTY_TYPE_ID_COLLATERAL_MANAGER = 'PARTY_TYPE_ID_COLLATERAL_MANAGER',
  PARTY_TYPE_ID_PLEDGER = 'PARTY_TYPE_ID_PLEDGER',
  PARTY_TYPE_ID_SECURED_PARTY = 'PARTY_TYPE_ID_SECURED_PARTY',
  PARTY_TYPE_ID_UNSPECIFIED = 'PARTY_TYPE_ID_UNSPECIFIED'
}

/** Indicates the state of the person data quality checks */
export enum PersonDataQualityChecksState {
  /** Person data quality checks have been completed successfully */
  COMPLETE = 'COMPLETE',
  /** There was an error while running the person data quality */
  ERROR = 'ERROR',
  /** Person data quality checks have not started */
  NOT_STARTED = 'NOT_STARTED',
  /** Person data quality checks are still running */
  RUNNING = 'RUNNING'
}

/** Properties by which Person connections can be ordered */
export enum PersonOrderByField {
  /** Order Persons by email */
  EMAIL = 'EMAIL',
  /** Order Persons by name */
  NAME = 'NAME'
}

/**
 * Enumerates the different possible policy actions
 * This enum is not exaustive compared to schema.Action since there are actions that cannot
 * actually be protected by a policy, e.g. a deposit
 */
export enum PolicyActionType {
  /** request to create a new vault */
  CREATE_VAULT = 'CREATE_VAULT',
  /** request for an address at which assets may be deposited */
  DEPOSIT = 'DEPOSIT',
  /** request to create a new generic organization change */
  GENERIC_ORGANIZATION_CHANGE = 'GENERIC_ORGANIZATION_CHANGE',
  /** Used for actions that have no governing policy. */
  NONE = 'NONE',
  /** request to create a new offchain operation */
  OFFCHAIN_OPERATION_ADD = 'OFFCHAIN_OPERATION_ADD',
  /** request to change an organization */
  ORGANIZATION_CHANGE = 'ORGANIZATION_CHANGE',
  /** request to change an organization's policy */
  ORGANIZATION_POLICY_CHANGE = 'ORGANIZATION_POLICY_CHANGE',
  /** request to change an organization fragment binding */
  ORGANIZATION_POLICY_FRAGMENT_BINDING_CHANGE = 'ORGANIZATION_POLICY_FRAGMENT_BINDING_CHANGE',
  /** request to change an organization role binding */
  ORGANIZATION_POLICY_ROLE_BINDING_CHANGE = 'ORGANIZATION_POLICY_ROLE_BINDING_CHANGE',
  /** request to rename an organization */
  ORGANIZATION_RENAME = 'ORGANIZATION_RENAME',
  /** request to add a quorum recovery entry */
  QUORUM_RECOVERY_ADD = 'QUORUM_RECOVERY_ADD',
  /** request to remove a quorum recovery entry */
  QUORUM_RECOVERY_REMOVE = 'QUORUM_RECOVERY_REMOVE',
  /** request to create, update or delete a role */
  ROLE_CHANGE = 'ROLE_CHANGE',
  /** request to add a new trusted destination */
  TRUSTED_DESTINATION_ADD = 'TRUSTED_DESTINATION_ADD',
  /** request to remove an existing trusted destination */
  TRUSTED_DESTINATION_REMOVE = 'TRUSTED_DESTINATION_REMOVE',
  /** request to add a new trusted smart contract */
  TRUSTED_SMART_CONTRACT_ADD = 'TRUSTED_SMART_CONTRACT_ADD',
  /** request to remove an existing trusted smart contract */
  TRUSTED_SMART_CONTRACT_REMOVE = 'TRUSTED_SMART_CONTRACT_REMOVE',
  /** request to add a user */
  USER_ADD = 'USER_ADD',
  /** request to remove a user */
  USER_REMOVE = 'USER_REMOVE',
  /** request to change a vault's policy */
  VAULT_POLICY_CHANGE = 'VAULT_POLICY_CHANGE',
  /** request to change a vault fragment binding */
  VAULT_POLICY_FRAGMENT_BINDING_CHANGE = 'VAULT_POLICY_FRAGMENT_BINDING_CHANGE',
  /** request to change a vault role binding */
  VAULT_POLICY_ROLE_BINDING_CHANGE = 'VAULT_POLICY_ROLE_BINDING_CHANGE',
  /** request to move funds out of a vault */
  WITHDRAW = 'WITHDRAW'
}

/**
 * PolicySourceOfTruth is the current source of truth for policies.
 * As organizations move from governance policies to policy containers they move from POLICY_SOT_LEGACY_EXCLUSIVE -> POLICY_SOT_CONTAINER.
 */
export enum PolicySourceOfTruth {
  /** Policy is defined and enforced by policy containers for this resource. */
  POLICY_SOT_CONTAINER = 'POLICY_SOT_CONTAINER',
  /**
   * Policy is defined and enforce by the legacy governance policies for this resource.
   * Policy Containers are derived from the legacy governance policies.
   */
  POLICY_SOT_LEGACY = 'POLICY_SOT_LEGACY',
  /** Policy is defined and enforced by legacy governance policies for this resource. */
  POLICY_SOT_LEGACY_EXCLUSIVE = 'POLICY_SOT_LEGACY_EXCLUSIVE'
}

/** Possible policy status */
export enum PolicyStatus {
  /** At least one quorum has as many users as the minimum required */
  AT_RISK = 'AT_RISK',
  /** All quorums are healthy */
  GOOD = 'GOOD',
  /** At least one quorum cannot be achieved */
  LOST = 'LOST',
  /** At least one quorum is reaching the minimum required users for quorum */
  NEEDS_ATTENTION = 'NEEDS_ATTENTION'
}

/** Type of lending pool */
export enum PoolType {
  /** Accelerator program pool type */
  ACCELERATOR = 'ACCELERATOR',
  /** Conservative pool type */
  CONSERVATIVE = 'CONSERVATIVE',
  /** Moderate pool type */
  MODERATE = 'MODERATE',
  /** Fixed term pool type */
  TERM = 'TERM'
}

/** Properties by which PriceReferenceData connections can be ordered. */
export enum PriceReferenceDataOrderField {
  CREATED_AT = 'CREATED_AT',
  PRICE = 'PRICE',
  VERSION = 'VERSION'
}

/** ProductUpdatesCategory represents the level of importance a product update can be identified with for notifying the user */
export enum ProductUpdatesCategory {
  /** IMPORTANT marks items with a high level of importance */
  IMPORTANT = 'IMPORTANT'
}

/** PromoterType is the different types of actors that can cause a trade state change */
export enum PromoterType {
  /** The state change was promoted by an API user */
  API_USER = 'API_USER',
  /** The state change was promoted by the customer experience team */
  RISK_REVIEWER = 'RISK_REVIEWER',
  /** The state change was promoted by the automated trading system */
  TRADING_SYSTEM = 'TRADING_SYSTEM',
  /** The state change promoter is unknown */
  UNKNOWN = 'UNKNOWN',
  /** The state change was promoted by the end user */
  USER = 'USER'
}

/** ProposalStage represents where the proposal is in its life cycle */
export enum ProposalStage {
  /** APPROVAL is when the designated approvers can approve the proposal */
  APPROVAL = 'APPROVAL',
  /** EXECUTION is when a proposal has been approved and is ready for execution */
  EXECUTION = 'EXECUTION',
  /** EXPIRATION is when a proposal has timed out without enough support */
  EXPIRATION = 'EXPIRATION',
  /** NONE is when nothing has happened */
  NONE = 'NONE',
  /** QUEUED is when a proposal is on the queue and waiting to be activated */
  QUEUED = 'QUEUED',
  /** REFERENDUM is when a proposal is ready to be voted on by the general community */
  REFERENDUM = 'REFERENDUM'
}

/** ProposalVoteStatus represents the state of a proposal, and whether it's voted on, in progress, or not */
export enum ProposalVoteStatus {
  /** COMPLETE is the state when the proposal has been voted on */
  COMPLETE = 'COMPLETE',
  /** NONE is the state when the proposal has not been voted on */
  NONE = 'NONE',
  /** PENDING is the state when the proposal is in progress but has not complete (because of an operation in progress) */
  PENDING = 'PENDING'
}

/** QuestionnaireQuestionRuleType describes the validation preformed by the rule */
export enum QuestionnaireQuestionRuleType {
  /** ADD_DAYS is a rule that checks if the date answer is within a given number of days from the current date */
  ADD_DAYS = 'ADD_DAYS',
  /** CHECK_SUM is a rule that checks if the sum of the answers for a given question inside a group equals a given value */
  CHECK_SUM = 'CHECK_SUM',
  /** DISTINCT is a rule that checks if a given question inside a group has unique answers */
  DISTINCT = 'DISTINCT',
  /** MAX_DATE is the maximum accepted date in the answer for date type questions */
  MAX_DATE = 'MAX_DATE',
  /** MAX_LENGTH is a maximum amount of characters or items in the answer */
  MAX_LENGTH = 'MAX_LENGTH',
  /** MAX_SUM is a rule that checks if the sum of the answers for a given question inside a group is below a given value */
  MAX_SUM = 'MAX_SUM',
  /** MIN_DATE is the minimum accepted date in the answer for date type questions */
  MIN_DATE = 'MIN_DATE',
  /** MIN_LENGTH is a minimum amount of characters or items in the answer */
  MIN_LENGTH = 'MIN_LENGTH',
  /** MIN_SUM is a rule that checks if the sum of the answers for a given question inside a group is above a given value */
  MIN_SUM = 'MIN_SUM',
  /** REGEX is a rule that contains a regex expression that is validated against the the answer text */
  REGEX = 'REGEX'
}

/** QuestionnaireQuestionType is the type of the questionnaire question */
export enum QuestionnaireQuestionType {
  /** CHECKBOX allows for the selection of multiple options in a list, adequate for short lists, may be optional */
  CHECKBOX = 'CHECKBOX',
  /** CHIP allows the selection of one option in a list, adequate for selecting words in short lists */
  CHIP = 'CHIP',
  /** DATE is a question picker input type, should be answered with freeform text in the form of MM/DD/YYYY, may be optional */
  DATE = 'DATE',
  /** DROPDOWN allows the selection of one option in a list, adequate for long lists */
  DROPDOWN = 'DROPDOWN',
  /** DROPDOWN_MULTI_SELECT allows for the selection of multiple options in a list, adequate for long lists, may be optional */
  DROPDOWN_MULTI_SELECT = 'DROPDOWN_MULTI_SELECT',
  /** GROUP enables a group of questions to be answered multiple times, may be optional, use MAX_LENGTH/MIN_LENGTH to specify the required number of times to answer the questions, answers must be given ion the answer group */
  GROUP = 'GROUP',
  /** RADIO allows the selection of one option in a list, adequate for short lists */
  RADIO = 'RADIO',
  /** RADIO_GROUP enables a group of questions when the corresponding option is selected, the sub questions are answered as regular questions, may be optional */
  RADIO_GROUP = 'RADIO_GROUP',
  /** SECTION indicates the question is to be handled as a section describing the next questions, cannot receive answers */
  SECTION = 'SECTION',
  /** TEXTFIELD is a free text input, may be optional */
  TEXTFIELD = 'TEXTFIELD',
  /** TEXT_AREA is also free text input, may be optional */
  TEXT_AREA = 'TEXT_AREA',
  /** UPLOAD allows file uploads, may be optional */
  UPLOAD = 'UPLOAD',
  /** VOID is a no-op input type, cannot receive answers */
  VOID = 'VOID'
}

/** The strictness of a quorum to be met in order to fulfill a policy fragment. */
export enum QuorumRequirement {
  /**
   * The quorum must be met in order to fulfill the policy fragment.
   * There exists no mechanism that can override or bypass the quorum.
   */
  QUORUM_REQUIREMENT_STRICT = 'QUORUM_REQUIREMENT_STRICT',
  /**
   * The quorum in optional in order to fulfill the policy framgment.
   * There exists mechanisms that can override the quorum requirement.
   */
  QUORUM_REQUIREMENT_WEAK = 'QUORUM_REQUIREMENT_WEAK'
}

/** Possible quote sides */
export enum QuoteSide {
  /** Buying base asset type */
  BUY = 'BUY',
  /** Selling base asset type */
  SELL = 'SELL',
  /** Both buying and selling base asset type */
  TWOWAY = 'TWOWAY'
}

/** The different statuses that a quote can go through */
export enum QuoteStatus {
  /** The code was executed, meaning that it is complete and executed */
  EXECUTED = 'EXECUTED',
  /** The quote is open and awaiting trading or cancellation */
  OPEN = 'OPEN',
  /** The quote was rejected */
  REJECTED = 'REJECTED'
}

/** status of the strategy */
export enum RebalanceStrategyStatus {
  /** The strategy is active and will be executed */
  ACTIVE = 'ACTIVE',
  /** The strategy is inactive and will not be executed */
  INACTIVE = 'INACTIVE',
  /** The strategy is paused and will not be executed */
  PAUSED = 'PAUSED'
}

/** ReportDataFilterFilterType is enum for the field filter_type */
export enum ReportDataFilterFilterType {
  FILTER_TYPE_DATE_POINT = 'FILTER_TYPE_DATE_POINT',
  FILTER_TYPE_DATE_RANGE = 'FILTER_TYPE_DATE_RANGE',
  FILTER_TYPE_MULTI_SELECT = 'FILTER_TYPE_MULTI_SELECT',
  FILTER_TYPE_SINGLE_SELECT = 'FILTER_TYPE_SINGLE_SELECT',
  FILTER_TYPE_UNSPECIFIED = 'FILTER_TYPE_UNSPECIFIED'
}

/** ReportFileFileFormat is enum for the field file_format */
export enum ReportFileFileFormat {
  FILE_FORMAT_CSV = 'FILE_FORMAT_CSV',
  FILE_FORMAT_UNSPECIFIED = 'FILE_FORMAT_UNSPECIFIED'
}

/** Properties by which Report connections can be ordered. */
export enum ReportOrderField {
  CREATED_AT = 'CREATED_AT'
}

/** ReportReportStatus is enum for the field report_status */
export enum ReportReportStatus {
  REPORT_STATUS_FAILED = 'REPORT_STATUS_FAILED',
  REPORT_STATUS_GENERATED = 'REPORT_STATUS_GENERATED',
  REPORT_STATUS_GENERATING_FILE = 'REPORT_STATUS_GENERATING_FILE',
  REPORT_STATUS_PENDING = 'REPORT_STATUS_PENDING',
  REPORT_STATUS_STARTED = 'REPORT_STATUS_STARTED',
  REPORT_STATUS_UNSPECIFIED = 'REPORT_STATUS_UNSPECIFIED'
}

/** ReportScheduleDataFilterFilterType is enum for the field filter_type */
export enum ReportScheduleDataFilterFilterType {
  FILTER_TYPE_DATE_POINT = 'FILTER_TYPE_DATE_POINT',
  FILTER_TYPE_DATE_RANGE = 'FILTER_TYPE_DATE_RANGE',
  FILTER_TYPE_MULTI_SELECT = 'FILTER_TYPE_MULTI_SELECT',
  FILTER_TYPE_SINGLE_SELECT = 'FILTER_TYPE_SINGLE_SELECT',
  FILTER_TYPE_UNSPECIFIED = 'FILTER_TYPE_UNSPECIFIED'
}

/** ReportScheduleFileFormatsFileFormat is enum for the field file_format */
export enum ReportScheduleFileFormatsFileFormat {
  FILE_FORMAT_CSV = 'FILE_FORMAT_CSV',
  FILE_FORMAT_UNSPECIFIED = 'FILE_FORMAT_UNSPECIFIED'
}

/** ReportScheduleMainFilterFilterType is enum for the field filter_type */
export enum ReportScheduleMainFilterFilterType {
  FILTER_TYPE_DATE_POINT = 'FILTER_TYPE_DATE_POINT',
  FILTER_TYPE_DATE_RANGE = 'FILTER_TYPE_DATE_RANGE',
  FILTER_TYPE_MULTI_SELECT = 'FILTER_TYPE_MULTI_SELECT',
  FILTER_TYPE_SINGLE_SELECT = 'FILTER_TYPE_SINGLE_SELECT',
  FILTER_TYPE_UNSPECIFIED = 'FILTER_TYPE_UNSPECIFIED'
}

/** ReportScheduleMainFilterScheduleFilterPoint is enum for the field schedule_filter_point */
export enum ReportScheduleMainFilterScheduleFilterPoint {
  POINT_END_OF_LAST_MONTH = 'POINT_END_OF_LAST_MONTH',
  POINT_END_OF_LAST_QUARTER = 'POINT_END_OF_LAST_QUARTER',
  POINT_END_OF_LAST_WEEK = 'POINT_END_OF_LAST_WEEK',
  POINT_END_OF_LAST_YEAR = 'POINT_END_OF_LAST_YEAR',
  POINT_UNSPECIFIED = 'POINT_UNSPECIFIED',
  POINT_YESTERDAY = 'POINT_YESTERDAY'
}

/** ReportScheduleMainFilterScheduleFilterRange is enum for the field schedule_filter_range */
export enum ReportScheduleMainFilterScheduleFilterRange {
  RANGE_LAST_MONTH = 'RANGE_LAST_MONTH',
  RANGE_LAST_QUARTER = 'RANGE_LAST_QUARTER',
  RANGE_LAST_WEEK = 'RANGE_LAST_WEEK',
  RANGE_LAST_YEAR = 'RANGE_LAST_YEAR',
  RANGE_MONTH_TO_DATE = 'RANGE_MONTH_TO_DATE',
  RANGE_QUARTER_TO_DATE = 'RANGE_QUARTER_TO_DATE',
  RANGE_UNSPECIFIED = 'RANGE_UNSPECIFIED',
  RANGE_WEEK_TO_DATE = 'RANGE_WEEK_TO_DATE',
  RANGE_YEAR_TO_DATE = 'RANGE_YEAR_TO_DATE',
  RANGE_YESTERDAY = 'RANGE_YESTERDAY'
}

/** Properties by which ReportSchedule connections can be ordered. */
export enum ReportScheduleOrderField {
  CREATED_AT = 'CREATED_AT'
}

/** ReportScheduleScheduleType is enum for the field schedule_type */
export enum ReportScheduleScheduleType {
  SCHEDULE_ANNUALLY = 'SCHEDULE_ANNUALLY',
  SCHEDULE_DAILY = 'SCHEDULE_DAILY',
  SCHEDULE_MONTHLY = 'SCHEDULE_MONTHLY',
  SCHEDULE_QUARTERLY = 'SCHEDULE_QUARTERLY',
  SCHEDULE_UNSPECIFIED = 'SCHEDULE_UNSPECIFIED',
  SCHEDULE_WEEKLY = 'SCHEDULE_WEEKLY'
}

/**
 * ResponseStatusCode is similar to HTTP response status code.
 * It indicates whether a request has been successfully received, completed, failed, etc.
 */
export enum ResponseStatusCode {
  /**
   * ACCEPTED means the request has been successfully received but not yet processed.
   * Note that the processing of the request could still fail.
   */
  ACCEPTED = 'ACCEPTED',
  /** OK means a request has been processed, to completion, successfully. */
  OK = 'OK'
}

/** RestrictionEntityType represents the entity type for a restriction */
export enum RestrictionEntityType {
  /** Indicates entity is an account */
  ACCOUNT = 'ACCOUNT',
  /** Indicates entity is an affiliate */
  AFFILIATE = 'AFFILIATE'
}

/** Indicates the type of restriction */
export enum RestrictionType {
  /** Restriction controls account closure */
  CLOSE = 'CLOSE',
  /** Indicates the restriction controls withdrawal */
  FREEZE_WITHDRAWAL = 'FREEZE_WITHDRAWAL'
}

/** Type of RiskAnalysis */
export enum RiskAnalysisType {
  /** Check Alerts */
  ALERT_CHECK = 'ALERT_CHECK',
  /** Beneficiary Counterparty Information */
  AML_BENEFICIARY_SCREENING = 'AML_BENEFICIARY_SCREENING',
  /** Originator Counterparty Information */
  AML_ORIGINATOR_SCREENING = 'AML_ORIGINATOR_SCREENING',
  /** Check Counterparty Information */
  COUNTERPARTY_CHECK = 'COUNTERPARTY_CHECK',
  /** High Risk Jurisdictions countries check */
  HRJ_CHECK = 'HRJ_CHECK',
  /** Screen Wallet */
  WALLET_SCREENING = 'WALLET_SCREENING'
}

/** Vendor for RiskAnalysis */
export enum RiskAnalysisVendor {
  /** Analysis from LexisNexis */
  LEXIS_NEXIS = 'LEXIS_NEXIS',
  /** Analysis from TRM */
  TRM = 'TRM'
}

/** Risk user approval groups */
export enum RiskApprovalGroupType {
  /** Used when the user belongs to the Client Operations team */
  CLIENT_OPS = 'CLIENT_OPS',
  /** Used when the user belongs to the Client Operations that allows to direct edit kyc information */
  CLIENT_OPS_DIRECT_EDIT = 'CLIENT_OPS_DIRECT_EDIT',
  /** Used when the user belongs to the Compliance Hold approval group */
  COMPLIANCE_HOLD = 'COMPLIANCE_HOLD',
  /** Used when the user belongs to the Compliance Trust approval group */
  COMPLIANCE_TRUST = 'COMPLIANCE_TRUST',
  /** Used when the user belongs to the Hold approval group */
  HOLD = 'HOLD',
  /** Used when the user belongs to the Trading team */
  TRADING = 'TRADING',
  /** Used when the user belongs to the Trading Compliance team */
  TRADING_COMPLIANCE = 'TRADING_COMPLIANCE',
  /** Used when the user belongs to the Trading Limits approval group */
  TRADING_LIMITS = 'TRADING_LIMITS',
  /** Used when the user belongs to the Trust approval group */
  TRUST = 'TRUST'
}

/** Vendor for RiskAnalysis */
export enum RiskRequestStatus {
  /** RiskAnalysis has been completed */
  COMPLETE = 'COMPLETE',
  /** RiskAnalysis has failed */
  FAILED = 'FAILED',
  /** RiskAnalysis is being requested */
  PENDING = 'PENDING',
  /** RiskAnalysis has being queued */
  QUEUED = 'QUEUED',
  /** AssetType or Operation for AssetType not Supported */
  UNSUPPORTED = 'UNSUPPORTED'
}

/** The type of back office review that must be performed by Anchorage in prior to execution of a scoped action. */
export enum RiskReviewRequirement {
  /** Manual risk review is not required by Anchorage prior to execution of an action. */
  RISK_REVIEW_BYPASSED = 'RISK_REVIEW_BYPASSED',
  /** Manual risk review must be performed by Anchorage prior to execution of an action. */
  RISK_REVIEW_REQUIRED = 'RISK_REVIEW_REQUIRED'
}

/** RoleAction represents the name of an action that can be performed with this level of permission */
export enum RoleAction {
  /** AUTHORIZE_SETTLEMENTS gives the ability to authorize settlements involving vaults that have this permission. This has the same effect as iOS endorsements on the operation for authorizing the settlement. */
  AUTHORIZE_SETTLEMENTS = 'AUTHORIZE_SETTLEMENTS',
  /** CONFIGURE_WEBHOOKS gives the ability to configure webhook endpoints and their respective subscriptions */
  CONFIGURE_WEBHOOKS = 'CONFIGURE_WEBHOOKS',
  /** CREATE_DEPOSIT_ADDRESS is the ability to generate and retrieve deposit addresses within a vault */
  CREATE_DEPOSIT_ADDRESS = 'CREATE_DEPOSIT_ADDRESS',
  /** DEPOSIT_ATTRIBUTION is the ability to use deposit attribution via API */
  DEPOSIT_ATTRIBUTION = 'DEPOSIT_ATTRIBUTION',
  /**
   * No longer used but kept for backwards compatibility as some orgs may still
   * have this permission.
   */
  DIEM_PREBURN = 'DIEM_PREBURN',
  /** EXTERNAL_LOAN_WRITE is the ability to originate loans through API */
  EXTERNAL_LOAN_WRITE = 'EXTERNAL_LOAN_WRITE',
  /** FACILITY_ONLY is the ability to read lending facility data */
  FACILITY_ONLY = 'FACILITY_ONLY',
  /** HOLD is the ability to create holds on assets in a vault */
  HOLD = 'HOLD',
  /** INITIATE_WITHDRAWAL is the ability to initiate a withdraw from a vault */
  INITIATE_WITHDRAWAL = 'INITIATE_WITHDRAWAL',
  /** INTERNAL_TRANSFER is the ability to transfer assets from one Anchorage vault to another vault */
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER',
  /** LENDER_READ is the ability to read lender account data */
  LENDER_READ = 'LENDER_READ',
  /** ONBOARDING is the ability to manage onboarding data */
  ONBOARDING = 'ONBOARDING',
  /** PROPOSE_ACCEPT_SETTLEMENTS gives the ability to propose or accept settlements involving vaults that have this permission. They still have to be authorized separately through the API or app. */
  PROPOSE_ACCEPT_SETTLEMENTS = 'PROPOSE_ACCEPT_SETTLEMENTS',
  /** READ is read access to vault and account metadata */
  READ = 'READ',
  /** READ_DEPOSIT_ATTRIBUTION is the ability to read deposit attribution data via API */
  READ_DEPOSIT_ATTRIBUTION = 'READ_DEPOSIT_ATTRIBUTION',
  /** READ_SUBACCOUNT is the ability to read data from the subaccount system */
  READ_SUBACCOUNT = 'READ_SUBACCOUNT',
  /** READ_TRADE is the ability to read all trade data */
  READ_TRADE = 'READ_TRADE',
  /** STAKE gives the ability to stake and unstake assets */
  STAKE = 'STAKE',
  /** SUBACCOUNT is the ability to use subaccount system to manage positions */
  SUBACCOUNT = 'SUBACCOUNT',
  /** TRADE is the ability to execute trades and read trade data created by the API key */
  TRADE = 'TRADE',
  /** TRANSFER is the ability to transfer assets */
  TRANSFER = 'TRANSFER'
}

/** RoleChangeType describes the type of role change that the operation is performing */
export enum RoleChangeType {
  /** CREATE indicates a new role is being created */
  CREATE = 'CREATE',
  /** DELETE indicates an existing role is being deleted */
  DELETE = 'DELETE',
  /** UPDATE indicates an existing role is being updated */
  UPDATE = 'UPDATE'
}

/** RolePermissionChangeType indicates the type of change occurring for a permission */
export enum RolePermissionChangeType {
  /** CREATE is used when a new permission is being added that didn't exist before. */
  CREATE = 'CREATE',
  /** DELETE is used when an existing permission will no longer exist. */
  DELETE = 'DELETE',
  /** UNCHANGED is used when viewing an existing permission that is not being changed. */
  UNCHANGED = 'UNCHANGED',
  /** UPDATE is used when an existing permission has one or more parameters being changed. */
  UPDATE = 'UPDATE'
}

/** RolePermissionTransferType represents the type of transfer permission the change refers */
export enum RolePermissionTransferType {
  /** EXTERNAL_TRANSFER is the type of transfer permission associated with external transfers */
  EXTERNAL_TRANSFER = 'EXTERNAL_TRANSFER',
  /** INTERNAL_TRANSFERs is the type of transfer permission associated with internal transfers */
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER'
}

/** SNParticipantTier is a way to specify what set of features a participant has access to */
export enum SNParticipantTier {
  /** Advanced participants have access to two-way settlements as long as both parties are advanced. */
  ADVANCED = 'ADVANCED',
  /** Basic participants have access only to one-way settlements. */
  BASIC = 'BASIC'
}

/** A state for settlements. It's a high level description of what's happening or has happened to the settlement. */
export enum SNSettlementState {
  /** The settlement is waiting for the acceptor to accept it. */
  ACTION_NEEDED = 'ACTION_NEEDED',
  /** The settlement was executed successfully. */
  EXECUTED = 'EXECUTED',
  /** The settlement is being executed. */
  EXECUTING = 'EXECUTING',
  /** The settlement failed to execute. */
  FAILED = 'FAILED',
  /** The settlement was rejected by one of the participants. */
  REJECTED = 'REJECTED',
  /** The participants took too long to accept the settlement. */
  TIMED_OUT = 'TIMED_OUT'
}

/** A state filter for settlements. Each value here can correspond to multiple states. */
export enum SNSettlementStateFilter {
  /** The settlement is waiting for the acceptor to accept it. */
  ACTION_NEEDED = 'ACTION_NEEDED',
  /** The settlement has completed with success or failure or was canceled or timed out somehow. */
  COMPLETED = 'COMPLETED',
  /** The settlement is being executed. */
  EXECUTING = 'EXECUTING',
  /** Return settlements in all states that are not considered completed as definde by the COMPLETED filter. */
  NOT_COMPLETED = 'NOT_COMPLETED'
}

/** SettingType represents the possible settings */
export enum SettingType {
  /** Contractual notifications document delivery preference */
  DOCUMENT_DELIVERY_CONTRACTUAL_NOTIFICATIONS = 'DOCUMENT_DELIVERY_CONTRACTUAL_NOTIFICATIONS',
  /** Deposit attribution document delivery preference */
  DOCUMENT_DELIVERY_DEPOSIT_ATTRIBUTIONS = 'DOCUMENT_DELIVERY_DEPOSIT_ATTRIBUTIONS',
  /** Deposit invoice document delivery preference */
  DOCUMENT_DELIVERY_INVOICE = 'DOCUMENT_DELIVERY_INVOICE',
  /** KYC inquiry document delivery preference */
  DOCUMENT_DELIVERY_KYC_INQUIRY = 'DOCUMENT_DELIVERY_KYC_INQUIRY',
  /** Platform services update document delivery preference */
  DOCUMENT_DELIVERY_PLATFORM_SERVICES_UPDATE = 'DOCUMENT_DELIVERY_PLATFORM_SERVICES_UPDATE',
  /** Settlement Network notifications document delivery preference */
  DOCUMENT_DELIVERY_SETTLEMENT_NETWORK_NOTIFICATIONS = 'DOCUMENT_DELIVERY_SETTLEMENT_NETWORK_NOTIFICATIONS',
  /** Tax documents document delivery preference */
  DOCUMENT_DELIVERY_TAX_DOCUMENTS = 'DOCUMENT_DELIVERY_TAX_DOCUMENTS',
  /** Trade confirmations document delivery preference */
  DOCUMENT_DELIVERY_TRADE_CONFIRMATIONS = 'DOCUMENT_DELIVERY_TRADE_CONFIRMATIONS'
}

/** SettingValueType represents the possible value types for a setting */
export enum SettingValueType {
  /** A setting of the boolean type */
  BOOLEAN = 'BOOLEAN',
  /** A setting of the date type */
  DATE = 'DATE',
  /** A setting of the json type */
  JSON = 'JSON',
  /** A setting of the numeric type */
  NUMERIC = 'NUMERIC',
  /** A setting of the string type */
  STRING = 'STRING'
}

/** SmartContractRiskLevel represents the risk level of interacting with a smart contract. */
export enum SmartContractRiskLevel {
  /** HIGH means the risk level is high and the smart contract is considered malicious. */
  HIGH = 'HIGH',
  /** LOW means the risk level is low and the smart contract is considered safe. */
  LOW = 'LOW',
  /** MEDIUM means the risk level is medium and the smart contract is considered somewhat risky. */
  MEDIUM = 'MEDIUM',
  /** UNAVAILABLE means the risk level could not be determined at this time due to an error. */
  UNAVAILABLE = 'UNAVAILABLE',
  /** UNDETERMINED means the risk level could not be determined at this time due lack of data. */
  UNDETERMINED = 'UNDETERMINED'
}

/**
 * StakingPositionState represents the different states that staked funds can be in.
 * Not all protocols will have all states.
 */
export enum StakingPositionState {
  /** Assets are available to claim */
  CLAIMABLE_REWARDS = 'CLAIMABLE_REWARDS',
  /** Assets are restaking */
  RESTAKING = 'RESTAKING',
  /** Assets are staked */
  STAKED = 'STAKED',
  /** Assets are being staked */
  STAKING = 'STAKING',
  /** Assets are unstaking */
  UNSTAKING = 'UNSTAKING'
}

/** StakingStepInputType represents whether the amount input is quantifying an amount of an asset or a share. */
export enum StakingStepInputType {
  /** An asset amount input type */
  ASSET = 'ASSET',
  /** A shares amount input type */
  SHARES = 'SHARES'
}

/** StakingStepNumberType represents whether the amount input is an absolute number or a percentage. */
export enum StakingStepNumberType {
  /** An absolute number (ie. 0, 10, 420, 13.37) */
  ABSOLUTE = 'ABSOLUTE',
  /** A percentage number, from 0 to 100, inclusive */
  PERCENTAGE = 'PERCENTAGE'
}

/** StandingInstructionStateType provides existing states for a standing instruction */
export enum StandingInstructionStateType {
  /** Active, has been approved */
  ACTIVE = 'ACTIVE',
  /** Disabled */
  DISABLED = 'DISABLED',
  /** Pending, is waiting of approval */
  PENDING = 'PENDING'
}

/** StateType indicates how the state show be displayed to the user */
export enum StateType {
  /** This is a successful end state */
  COMPLETE = 'COMPLETE',
  /** This is a failed end state */
  FAILED = 'FAILED',
  /** This state hasn't been reached yet */
  FUTURE = 'FUTURE',
  /** This is a regular past state */
  PAST = 'PAST',
  /** This is the current state unless the current state is COMPLETE or FAILED */
  PRESENT = 'PRESENT'
}

/** Statement/BalanceSheet Report/Transaction History Report generation status */
export enum StatementGenerationStatus {
  /** Statement/BalanceSheet Report/Transaction History Report generation workflow has completed successfully. */
  COMPLETED = 'COMPLETED',
  /** Statement/BalanceSheet Report/Transaction History Report generation workflow has completed with failure. */
  FAILED = 'FAILED',
  /** Statement/BalanceSheet Report/Transaction History Report generation workflow has been started. */
  IN_PROGRESS = 'IN_PROGRESS',
  /** Statement/BalanceSheet Report/Transaction History Report generation workflow has been requested (but not yet started). */
  REQUESTED = 'REQUESTED',
  /**
   * UNKNOWN is a status reserved when the service would have to panic otherwise.
   * Don't use this status if returning an error is possible.
   * Don't use this status if returning any other status is more appropriate.
   */
  UNKNOWN = 'UNKNOWN'
}

/** StatementType represents the statement types we produce */
export enum StatementType {
  /** Daily Balance Report */
  BALANCE_REPORT = 'BALANCE_REPORT',
  /** Daily Balance Report by Vaults */
  BALANCE_REPORT_VAULT = 'BALANCE_REPORT_VAULT',
  /** Daily Balance Report by Vault Addresses */
  BALANCE_REPORT_VAULT_ADDRESS = 'BALANCE_REPORT_VAULT_ADDRESS',
  /** Brokerage statement */
  BROKERAGE_STATEMENT = 'BROKERAGE_STATEMENT',
  /** Collateral statement */
  COLLATERAL_STATEMENT = 'COLLATERAL_STATEMENT',
  /** Dummy statement for testing */
  DUMMY_STATEMENT = 'DUMMY_STATEMENT',
  /** Monthly statement for ETFs */
  ETF_MONTHLY_STATEMENT = 'ETF_MONTHLY_STATEMENT',
  /** Lending statement */
  LENDING_STATEMENT = 'LENDING_STATEMENT',
  /** Monthly statement for fiat */
  MONTHLY_FIAT_STATEMENT = 'MONTHLY_FIAT_STATEMENT',
  /** Monthly prefunded statement */
  MONTHLY_PREFUNDED_STATEMENT = 'MONTHLY_PREFUNDED_STATEMENT',
  /** Monthly statement */
  MONTHLY_STATEMENT = 'MONTHLY_STATEMENT',
  /** Monthly statement for program customer */
  PROGRAM_CUSTOMER_MONTHLY_STATEMENT = 'PROGRAM_CUSTOMER_MONTHLY_STATEMENT',
  /** Monthly statement for RIAs */
  RIA_MONTHLY_STATEMENT = 'RIA_MONTHLY_STATEMENT',
  /** Transaction history report (aka Activities) */
  TRANSACTION_HISTORY = 'TRANSACTION_HISTORY',
  /** deprecated - use TRANSACTION_HISTORY */
  TRANSACTION_REPORT = 'TRANSACTION_REPORT'
}

/** Subaccounts organizations types */
export enum SubaccountOrganizationType {
  /** Organization is an ETF issuer */
  ETF_ISSUER = 'ETF_ISSUER',
  /** Organization is a SMA */
  SMA = 'SMA'
}

/** Represents the different types of subaccount */
export enum SubaccountType {
  /** This subaccount is used for ETF Funds */
  ETF_FUNDS = 'ETF_FUNDS',
  /** This subaccount used for the FBO Customer Fees */
  FBO_CUSTOMER_FEES = 'FBO_CUSTOMER_FEES',
  /** This subaccount is used to keep track of Gas Fees */
  GAS_FEES = 'GAS_FEES',
  /** This subaccount is used as Payable and Receivables of an RIA */
  PAYABLES_RECEIVABLES = 'PAYABLES_RECEIVABLES',
  /** This subaccount is a Program Customer */
  PROGRAM_CUSTOMER = 'PROGRAM_CUSTOMER',
  /** This subaccount is used for unassigned Funds */
  UNASSIGNED_FUNDS = 'UNASSIGNED_FUNDS'
}

/** TMSRuleEntityType represents the anchorage entity type of TMS rule */
export enum TMSRuleEntityType {
  /** SINGAPORE rules for monitoring Anchorage Digital Singapore */
  SINGAPORE = 'SINGAPORE',
  /** TRUST_COMPANY rules for monitoring Anchorage Digital Bank */
  TRUST_COMPANY = 'TRUST_COMPANY'
}

/** TMSRuleType represents the type of TMS rule */
export enum TMSRuleGroup {
  /** rules monitoring the accuracy of transaction attribution data */
  ATTRIBUTION_MONITORING = 'ATTRIBUTION_MONITORING',
  /** transaction monitoring rules targeting specific behavior based on customer type */
  CUSTOMER_TYPE = 'CUSTOMER_TYPE',
  /** behavioral rules for monitoring digital asset transactions */
  DIGITAL_ASSET = 'DIGITAL_ASSET',
  /** behavioral rules for monitoring fiat transactions */
  FIAT = 'FIAT'
}

/** TMSRuleID represents the name of a TMS rule */
export enum TMSRuleName {
  /** The rule identifies a customer's activity following a prolonged period without a transaction */
  DORMANT_ACCOUNT = 'DORMANT_ACCOUNT',
  /** The rule identifies the risk of a customer immediately washing funds to obscure their source of funds */
  FLOWTHROUGH_OF_FUNDS = 'FLOWTHROUGH_OF_FUNDS',
  /** The rule identifies deviations from the activity expected of Funds */
  FUND = 'FUND',
  /**
   * The rule is intended to separate customers which transact in a meaningfully
   * different way, when compared to the rest of the customer population
   */
  HIGH_VOLUME_CUSTOMERS = 'HIGH_VOLUME_CUSTOMERS',
  /** The rule identifies deviations from a customer’s historical activity */
  HISTORICAL_PROFILE_DEVIATION = 'HISTORICAL_PROFILE_DEVIATION',
  /** The rule identifies deviations from the activity expected of customer types that have low expected activity */
  LOW_ACTIVITY_CUSTOMERS = 'LOW_ACTIVITY_CUSTOMERS',
  /** The rule identifies deviations from the activity expected of Miners */
  MINING = 'MINING',
  /**
   * The rule identifies a group of customers sending funds to a potential hub
   * which could indicate hidden relationships or layering of funds
   */
  MULTIPLE_ORIGINATORS = 'MULTIPLE_ORIGINATORS',
  /** The rule identifies devidations from the activity expected of Non-Banking-Financial-Institutions */
  NBFI = 'NBFI',
  /**
   * Detects transaction activity that avoids detection of the Travel Rule
   * by breaking down larger transactions into multiple small transactions
   */
  REPORTING_EVASION = 'REPORTING_EVASION',
  /**
   * The rule identifies a customer sending funds to a number of counterparties thus
   * acting as a potential hub which could indicate hidden relationships or layering of funds
   */
  SINGLE_ORIGINATOR = 'SINGLE_ORIGINATOR'
}

/** TimeInForce represents */
export enum TimeInForce {
  /** TimeInForceDay represents */
  DAY = 'DAY',
  /** TimeInForceFillAndKill represents */
  FILL_AND_KILL = 'FILL_AND_KILL',
  /** TimeInForceFillOrKill represents */
  FILL_OR_KILL = 'FILL_OR_KILL',
  /** TimeInForceGoodTillCancel represents */
  GOOD_TILL_CANCEL = 'GOOD_TILL_CANCEL',
  /** Invalid represents */
  INVALID = 'INVALID'
}

/** Possible trade capacities */
export enum TradeCapacity {
  /** Anchorage acted as Agent for Customer */
  AGENT_FOR_CUSTOMER = 'AGENT_FOR_CUSTOMER',
  /** Anchorage acted as Agent for Customer and a Third Party */
  AGENT_FOR_CUSTOMER_AND_3RD_PARTY = 'AGENT_FOR_CUSTOMER_AND_3RD_PARTY',
  /** Anchorage acted in other capacity */
  OTHER = 'OTHER',
  /** Anchorage acted as Principal */
  PRINCIPAL = 'PRINCIPAL'
}

/** Possible trade sides */
export enum TradeSide {
  /** Buying base asset type */
  BUY = 'BUY',
  /** Selling base asset type */
  SELL = 'SELL'
}

/** TradeSource represents one of possible channels where the trade might have originated from */
export enum TradeSource {
  /** ADVANCED represents a trade placed through the advanced dashboard */
  ADVANCED = 'ADVANCED',
  /** API represents a trade placed through the Anchorage API */
  API = 'API',
  /** MANUAL represents a manually booked trade */
  MANUAL = 'MANUAL',
  /** UI represents a trade placed through the Anchorage UI */
  UI = 'UI',
  /** UNSPECIFIED represents an unknown source */
  UNSPECIFIED = 'UNSPECIFIED',
  /**
   * WL represents a trade placed through the white label
   * @deprecated Use ADVANCED instead
   */
  WL = 'WL'
}

/** TradeStatus is the different statuses that a trade can go through */
export enum TradeStatus {
  /** The trade has been allocated by the Desk */
  ALLOCATED = 'ALLOCATED',
  /** The trade was canceled by the Anchorage trading team due to client legal/financial problems */
  CANCELED = 'CANCELED',
  /** The trade was executed by the OTC desk and Anchorage is awaiting customer deposit */
  EXECUTED = 'EXECUTED',
  /** Customer has placed a trade request */
  PENDING = 'PENDING',
  /** The PENDING_ALLOCATION trade is in a dormant state in which it's waiting to be allocated */
  PENDING_ALLOCATION = 'PENDING_ALLOCATION',
  /** The trade was rejected by the OTC desk */
  REJECTED = 'REJECTED',
  /** The broker received funds and assets were sent to the customer */
  SETTLED = 'SETTLED',
  /** Anchorage has received customer deposit and is in the process of settling with the OTC desk */
  SETTLING = 'SETTLING'
}

/** TradeStrategy represents */
export enum TradeStrategy {
  /** TradeStrategyAggressivePegged represents */
  AGGRESSIVE_PEGGED = 'AGGRESSIVE_PEGGED',
  /** TradeStrategyEmpty represents */
  EMPTY = 'EMPTY',
  /** TradeStrategyFullAmount represents */
  FULL_AMOUNT = 'FULL_AMOUNT',
  /** TradeStrategyIceberg represents */
  ICEBERG = 'ICEBERG',
  /** Invalid represents */
  INVALID = 'INVALID',
  /** TradeStrategyLimit represents */
  LIMIT = 'LIMIT',
  /** TradeStrategyManual represents */
  MANUAL = 'MANUAL',
  /** TradeStrategyMarket represents */
  MARKET = 'MARKET',
  /** TradeStrategyMarketTWAP represents */
  MARKET_TWAP = 'MARKET_TWAP',
  /** TradeStrategyMultilegTWAP represents */
  MULTI_LEG_TWAP = 'MULTI_LEG_TWAP',
  /** TradeStrategyPegged represents */
  PEGGED = 'PEGGED',
  /** TradeStrategyPercentOfVolume represents */
  PERCENT_OF_VOLUME = 'PERCENT_OF_VOLUME',
  /** TradeStrategyPostOnly represents */
  POST_ONLY = 'POST_ONLY',
  /** TradeStrategySteadyPace represents */
  STEADY_PACE = 'STEADY_PACE',
  /** TradeStrategyStopLimit represents */
  STOP_LIMIT = 'STOP_LIMIT',
  /** TradeStrategyTakeProfitLimit represents */
  TAKE_PROFIT_LIMIT = 'TAKE_PROFIT_LIMIT',
  /** TradeStrategyTimeSliced represents */
  TIME_SLICED = 'TIME_SLICED',
  /** TradeStrategyTWAP represents */
  TWAP = 'TWAP',
  /** TradeStrategyUnknown represents */
  UNKNOWN = 'UNKNOWN'
}

/** Types of trading permissions */
export enum TradingPermissionType {
  /** Advanced trading features */
  ADVANCED = 'ADVANCED',
  /** Basic trading features */
  BASIC = 'BASIC'
}

/** Values of the trading permissions */
export enum TradingPermissionValue {
  /** Permission is disabled */
  DISABLED = 'DISABLED',
  /** Permission is enabled */
  ENABLED = 'ENABLED'
}

/** The type of transaction request. */
export enum TransactionRequestType {
  /** JSONRPC is a JSON-RPC transaction request. */
  JSONRPC = 'JSONRPC'
}

/** Quick determination of risk. Unless "DANGER", usually transactions still warrant further investigation */
export enum TransactionRiskScore {
  /** Transaction corresponds to a known bad cluster */
  DANGER = 'DANGER',
  /** Manual review is required for this transaction as the system was not able to determine the risk */
  MANUAL_CHECK_REQUIRED = 'MANUAL_CHECK_REQUIRED',
  /** Transaction corresponds to a known safe address cluster */
  SAFE = 'SAFE',
  /** We didn't get actionable risk information about this transaction */
  UNKNOWN = 'UNKNOWN'
}

/** Represents the status of a Transaction */
export enum TransactionStatus {
  /** Represents a Cancelled Transaction (CANCELLED) */
  CANCELED = 'CANCELED',
  /** Represents an on-going Transaction (PENDING) */
  PENDING = 'PENDING',
  /** Represents a final Transaction (POSTED) */
  POSTED = 'POSTED'
}

/** Represents the different type of transactions within the ledger */
export enum TransactionType {
  /** Represents an Advisory Fee */
  ADVISORY_FEE = 'ADVISORY_FEE',
  /** Represents a Custody Fee */
  CUSTODY_FEE = 'CUSTODY_FEE',
  /** Represents a Deposit */
  DEPOSIT = 'DEPOSIT',
  /** Represents a Deposit Sweep */
  DEPOSIT_SWEEP = 'DEPOSIT_SWEEP',
  /** Represents a Fee settlement */
  FEE_SETTLEMENT = 'FEE_SETTLEMENT',
  /** Represents a Gas Fill */
  GAS_FILL = 'GAS_FILL',
  /** Represents a Managment Fee */
  MANAGEMENT_FEE = 'MANAGEMENT_FEE',
  /** Represents a Model Fee */
  MODEL_FEE = 'MODEL_FEE',
  /** Represents return of funds */
  RETURN = 'RETURN',
  /** Represents a Subaccount Transfer */
  SUBACCOUNT_TRANSFER = 'SUBACCOUNT_TRANSFER',
  /** Represents a trade */
  TRADE = 'TRADE',
  /** Represents a Trade Settlement */
  TRADE_SETTLEMENT = 'TRADE_SETTLEMENT',
  /** Represents transfer */
  TRANSFER = 'TRANSFER',
  /** Represents a Withdrawal */
  WITHDRAWAL = 'WITHDRAWAL'
}

/** Transfer Access Request Domains */
export enum TransferAccessRequestDomain {
  /** Brokerage transfer access request domain */
  BROKERAGE = 'BROKERAGE',
  /** Financing transfer access request domain */
  FINANCING = 'FINANCING',
  /** Gas station transfer access request domain */
  GAS_STATION = 'GAS_STATION',
  /** Settlement network transfer access request domain */
  SETTLEMENT_NETWORK = 'SETTLEMENT_NETWORK'
}

/** Transfer Access Request statuses */
export enum TransferAccessRequestStatus {
  /** API key creation was canceled */
  API_KEY_CANCELED = 'API_KEY_CANCELED',
  /** API key was created */
  API_KEY_COMPLETE = 'API_KEY_COMPLETE',
  /** API key is being created */
  API_KEY_PENDING = 'API_KEY_PENDING',
  /** API key was revoked */
  API_KEY_REVOKED = 'API_KEY_REVOKED',
  /** The transfer access request request has not been initiated */
  NOT_REQUESTED = 'NOT_REQUESTED',
  /** Permission group is canceled */
  PERMISSION_GROUP_CANCELED = 'PERMISSION_GROUP_CANCELED',
  /** Permission group is created */
  PERMISSION_GROUP_COMPLETE = 'PERMISSION_GROUP_COMPLETE',
  /** Permission group is deleted */
  PERMISSION_GROUP_DELETED = 'PERMISSION_GROUP_DELETED',
  /** Permission group is being created */
  PERMISSION_GROUP_PENDING = 'PERMISSION_GROUP_PENDING'
}

/** TransferRequestSourceType is the source type of a transfer request */
export enum TransferRequestSourceType {
  /** Client is a transfer request submitted by a Client */
  CLIENT = 'CLIENT',
  /** Internal is a transfer request submitted by Lending Ops in Financing Dashboard */
  INTERNAL = 'INTERNAL'
}

/** TransferRequestStatus is the status of a transfer request */
export enum TransferRequestStatus {
  /** Approved is a approved transfer request */
  APPROVED = 'APPROVED',
  /** Canceled is a canceled transfer request */
  CANCELED = 'CANCELED',
  /** Completed is a completed transfer request */
  COMPLETED = 'COMPLETED',
  /** Failed is a failed transfer request */
  FAILED = 'FAILED',
  /** Pending is a pending transfer request */
  PENDING = 'PENDING',
  /** Rejected is a rejected transfer request */
  REJECTED = 'REJECTED'
}

/** TransferRequestType is the type of a transfer request */
export enum TransferRequestType {
  /** Deposit is a deposit transfer request */
  DEPOSIT = 'DEPOSIT',
  /** Withdrawal is a withdrawal transfer request */
  WITHDRAWAL = 'WITHDRAWAL'
}

/** Represents the status of the Travel Rule request */
export enum TravelRuleStatus {
  /** Travel Rule transmission failed */
  API_FAILURE = 'API_FAILURE',
  /** Travel Rule transmission is pending */
  PENDING = 'PENDING',
  /** Travel Rule info was transmitted manually */
  SENT_MANUALLY = 'SENT_MANUALLY',
  /** Travel Rule info was transmitted automatically */
  SENT_VIA_API = 'SENT_VIA_API'
}

/** By which field to sort TrustedSmartContract's. */
export enum TrustedSmartContractSortingField {
  /** Sort by the name of trusted smart contracts. */
  NAME = 'NAME'
}

/** Trusted Sources Freeze Reason */
export enum TrustedSourceDisableReason {
  /** Frozen due to being in compliance review */
  COMPLIANCE_REVIEW = 'COMPLIANCE_REVIEW',
  /** Frozen due to detecting a confliting attribution */
  CONFLICTING_ATTRIBUTION = 'CONFLICTING_ATTRIBUTION',
  /** Frozen due to detecting a incoherent attribution */
  INCOHERENT_ATTRIBUTION = 'INCOHERENT_ATTRIBUTION',
  /** Frozen due to being an omnibus account */
  OMNIBUS_ACCOUNT = 'OMNIBUS_ACCOUNT'
}

/** Trusted Sources Status */
export enum TrustedSourceStatus {
  /** Disabled */
  DISABLED = 'DISABLED',
  /** Enable */
  ENABLED = 'ENABLED'
}

/** TrustedSourceWalletType are the options for wallet types on trusted sources */
export enum TrustedSourceWalletType {
  /** CUSTODIAL represents a wallet where the owner does not have complete control over the wallet as they do not have the private key, a third party does. */
  CUSTODIAL = 'CUSTODIAL',
  /** SELF_HOSTED represents a wallet where the owner has complete control over the wallet as they have the private key. */
  SELF_HOSTED = 'SELF_HOSTED'
}

/** UnsignedOperationDetailsDividerStyle indicates the style of the divider. */
export enum UnsignedOperationDetailsDividerStyle {
  /** A thin divider. */
  THIN = 'THIN'
}

/**
 * This type enumerates the different types of quorum membership that a key can
 * have.
 */
export enum UserKeyQuorumMembershipType {
  /** The key is a member of at least one organization-level quorum. */
  ORGANIZATION = 'ORGANIZATION',
  /** The key is a member of at least one vault-level quorum. */
  VAULT = 'VAULT'
}

/** UserSortField are the options for sorting a list of users. */
export enum UserSortField {
  /** NAME sorts by the users' display names */
  NAME = 'NAME'
}

/** UserStatus represents the overall status of a user. */
export enum UserStatus {
  /** User has at least one key that can be used to login/perform actions. */
  ACTIVE = 'ACTIVE',
  /**
   * User was marked as blocked by Anchorage and no key can be used to login/perform
   * actions. This state is reversible and the user might be unblocked by Anchorage
   * or removed by other members of the organization.
   */
  BLOCKED = 'BLOCKED',
  /** User does not have keys that can be used to login/perform actions. */
  INOPERATIVE = 'INOPERATIVE'
}

/** Represents the type of a User */
export enum UserType {
  /** User who belongs to one or more Organization's quorums/policies */
  ADMIN = 'ADMIN',
  /** User who belongs to the brokereage desk team */
  BROKERAGE_DESK = 'BROKERAGE_DESK',
  /** User who belongs to one or Vault quorums/policie */
  MEMBER = 'MEMBER',
  /** This user object is not a user */
  NOT_A_USER = 'NOT_A_USER',
  /** This user key was removed. */
  REMOVED = 'REMOVED',
  /** User who doesn't belong to any quorum/policy */
  VIEW_ONLY = 'VIEW_ONLY'
}

/** Sorting options for vaults */
export enum VaultSortField {
  /** Sort by the VaultSubID */
  VAULT_SUB_ID = 'VAULT_SUB_ID'
}

/** Possible vault states */
export enum VaultState {
  /** The vault is disabled and holds no funds. */
  DISABLED_WITHOUT_ASSETS = 'DISABLED_WITHOUT_ASSETS',
  /** The vault is disabled but holds funds. */
  DISABLED_WITH_ASSETS = 'DISABLED_WITH_ASSETS',
  /** The vault is enabled. It may or may not hold funds. */
  ENABLED = 'ENABLED',
  /**
   * The vault is enabled but holds no funds
   * @deprecated Use ENABLED
   */
  ENABLED_WITHOUT_ASSETS = 'ENABLED_WITHOUT_ASSETS',
  /**
   * The vault is enabled and holds funds.
   * @deprecated Use ENABLED
   */
  ENABLED_WITH_ASSETS = 'ENABLED_WITH_ASSETS'
}

/** VestingSchedule sets fuzzy time periods for when an auto vesting transaction should happen. */
export enum VestingSchedule {
  /** DAILY tries to complete a transaction daily. */
  DAILY = 'DAILY',
  /** END_OF_MONTH tries to complete a transaction at the end of the month. */
  END_OF_MONTH = 'END_OF_MONTH',
  /** NONE denotes a vesting contract without an auto schedule. This must be done manually. */
  NONE = 'NONE',
  /** START_OF_WEEK tries to complete a transaction at the start of week. */
  START_OF_WEEK = 'START_OF_WEEK',
  /** START_OF_WEEK_AND_END_OF_MONTH tries to complete a transaction once at the start of week and end of month. */
  START_OF_WEEK_AND_END_OF_MONTH = 'START_OF_WEEK_AND_END_OF_MONTH'
}

/** VisibleForPurpose indicate if the balance should be visible for given purpose. */
export enum VisibleForPurpose {
  /** This gives a overview of the account balances. Used currently by the valult page in the app. */
  OVERVIEW = 'OVERVIEW'
}

/** VoiceBiometricsStatus is the result from the voice biometrics API */
export enum VoiceBiometricsStatus {
  /** ACCEPTED means the voice passed authentication */
  ACCEPTED = 'ACCEPTED',
  /**
   * ENROLLED means there was not enough data to authenticate the voice so the sample was used
   * for training and the voice should be checked
   */
  ENROLLED = 'ENROLLED',
  /** ENROLLMENT_PAUSED means the voice service is not accepting new users at the moment and could not authenticate */
  ENROLLMENT_PAUSED = 'ENROLLMENT_PAUSED',
  /** ERRORED means the voice data was not able to be checked and requires attention */
  ERRORED = 'ERRORED',
  /** FAILED means the voice failed authentication and requires attention */
  FAILED = 'FAILED'
}

/** VoteOption is the generic enum for governance votes */
export enum VoteOption {
  /** Abstain from vote */
  ABSTAIN = 'ABSTAIN',
  /** Vote no */
  NO = 'NO',
  /** Vote yes */
  YES = 'YES'
}

/** VoteType differentiates between the various types of votes possible */
export enum VoteType {
  /** A vote directly results in the change in behavior of a token. Used by Maker */
  EXECUTIVE = 'EXECUTIVE',
  /** A poll has no direct effect on the behavior of a token. Used by Maker */
  POLL = 'POLL',
  /** A referendum vote is for Celo governance */
  REFERENDUM = 'REFERENDUM'
}

/**
 * WalletConnectSessionSortField defines the fields to sort a list of WalletConnectSession's by.
 * The enums must each match (case-insensitive) a column in the `walletconnect_dapp_connections` table.
 */
export enum WalletConnectSessionSortField {
  /** ESTABLISHED_AT uses `walletconnect_dapp_connections` table's `established_at` for sorting. */
  ESTABLISHED_AT = 'ESTABLISHED_AT'
}

/** WalletConnect connection/session statuses */
export enum WalletConnectSessionStatus {
  /** connection/session is established */
  ESTABLISHED = 'ESTABLISHED',
  /** connection/session is pending approval */
  PENDING = 'PENDING',
  /** connection/session is terminated */
  TERMINATED = 'TERMINATED',
  /** connection/session is terminating */
  TERMINATING = 'TERMINATING'
}

/** WalletKeyBackupStrategy can be either unset or one of the values below */
export enum WalletKeyBackupStrategy {
  /** ADMIN_KEYS_QUORUM is described in the Porto spec https://www.notion.so/anchorage/Porto-Control-key-key-export-features-d9b433d754234d60a75884007b859477 */
  ADMIN_KEYS_QUORUM = 'ADMIN_KEYS_QUORUM',
  /**
   * WALLET_RECOVERY_KEY strategy, encrypts all wallet keys with a key that's exportable.
   * This allows users to manage their own keys.
   */
  WALLET_RECOVERY_KEY = 'WALLET_RECOVERY_KEY'
}

/** Wallet key PDF export channels */
export enum WalletKeyExportChannel {
  /** local download - browser download(web) / share tab(IOS) */
  DOWNLOAD = 'DOWNLOAD',
  /** email delivery */
  EMAIL = 'EMAIL',
  /** unknown export channel */
  UNKNOWN = 'UNKNOWN'
}

/** WalletSortField provides options for sorting wallets */
export enum WalletSortField {
  /**
   * BALANCE_IN_USD is the balance of the wallet in USD.
   * NOTE: this is not yet implemented in backend.
   */
  BALANCE_IN_USD = 'BALANCE_IN_USD',
  /** CREATED_AT sorts wallets by (in this order): created at timestamp, network ID, asset type ID, wallet name, asset sub ID. */
  CREATED_AT = 'CREATED_AT',
  /** NAME sorts wallets by (in this order): wallet name, network ID, asset type ID, asset sub ID. */
  NAME = 'NAME',
  /**
   * QUANTITY sorts wallet by (in this order): token quantity, network ID, asset type ID, wallet name, asset sub ID.
   * If a wallet contains multiple asset types, only asset with max quantity in the wallet is used for sorting.
   */
  QUANTITY = 'QUANTITY'
}

/** WidgetInteraction indicates the action a user took on a widget */
export enum WidgetInteraction {
  /** A confirmed action has the CTA button clicked on. */
  CONFIRMED = 'CONFIRMED',
  /** A dismissed action was suppressed */
  DISMISSED = 'DISMISSED'
}

/** WidgetType indicates how the front-end should render this widget (e.g. banner, modal) */
export enum WidgetType {
  /** A horizontal banner. Banners cannot be suppressed. */
  HORIZONTAL_BANNER = 'HORIZONTAL_BANNER',
  /** A modal widget takes the full screen and can turn into a carousel */
  MODAL = 'MODAL'
}
