import { ApolloError } from '@apollo/client';
import * as React from 'react';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useAnalytics } from '@anchorage/analytics';
import { ActionResult } from '@anchorage/analytics/src/lib/constants';
import { FormWizard } from '@anchorage/common/dist/components';
import { useForm } from '@anchorage/common/dist/components/Form/_reactHookForm';
import { yupResolver } from '@anchorage/common/dist/components/Form/_yupResolver';
import { Step } from '@anchorage/common/dist/components/FormWizard/types';
import { useSnackbar } from '@anchorage/common/dist/components/Snackbar';
import {
  UIDeveloperError,
  WrappedError,
  reportError,
} from '@anchorage/common/dist/utils/errors';
import { getGraphQLQueryName } from '@anchorage/frontoffice/utils/getGraphQLQueryName';
import { useLocalStorage } from '@anchorage/hooks';

import VaultWalletsQuery from 'components/Vault/WalletsDrawer/graphql/VaultWallets.graphql';
import EndorseActionModal from 'components/shared/EndorseActionModal';

import getStakingPositionByValidatorAddress from '../utils/getStakingPositionByValidatorAddress';
import { getStandardizedUnstakeFlowPageEventName } from '../utils/mixpanel';

import {
  DelegateOperation,
  DelegateType,
  OperationAction,
  useOperationMakeVisibleInOperationsListMutation,
  usePrepareDelegationMutation,
} from 'generated/graphql';

import css from './styles.module.scss';

import { StandardizedUnstakeFormValues } from '../../types';

import UnstakingIntroPage from '../../ETHStakingWizard/UnstakingIntroPage';
import useStandardizedStakingContext from '../StandardizedStakingContext/useStandardizedStakingContext';
import {
  StandardizedStakingMode,
  StandardizedUnstakeFlowWizardPage,
} from '../constants';
import SSStakeUnstakeReviewPage from './StandardizedStakingReviewPage/SSStakeUnstakeReviewPage';
import StandardizedUnstakeFormPage from './StandardizedUnstakeFormPage';
import { getStandardizedUnstakeFormValidation } from './validation';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  validatorAddress: string;
};

export const StandardizedUnstakeWizard: React.FC<Props> = ({
  isOpen,
  onClose,
  validatorAddress,
}) => {
  const { addSnackbar } = useSnackbar();
  const { track } = useAnalytics();

  const { walletWithStakingInfo, walletAsset, assetTypeInfo } =
    useStandardizedStakingContext();

  const vault = walletWithStakingInfo.vault;
  const vaultSubID = vault.vaultID.vaultSubID;

  const [operationIDToEndorse, setOperationIDToEndorse] = React.useState('');

  const unstakeFormSteps = useMemo(() => {
    const position = getStakingPositionByValidatorAddress(
      walletAsset.staking?.positions ?? [],
      validatorAddress,
    );
    return position?.unstakeForm?.steps ?? [];
  }, [walletAsset.staking?.positions, validatorAddress]);

  const resolverSchema = useMemo(() => {
    return getStandardizedUnstakeFormValidation({
      stakingSteps: unstakeFormSteps,
    });
  }, [unstakeFormSteps]);

  const form = useForm<StandardizedUnstakeFormValues>({
    mode: 'onChange',
    defaultValues: {
      amount: '0',
      comment: '',
    },
    resolver: yupResolver(resolverSchema),
  });

  const {
    getValues,
    formState: { isValid },
  } = form;

  const assetAbbreviation = assetTypeInfo.abbreviation;
  const walletName = walletWithStakingInfo.name;

  const [hasHidUnstakeIntro] = useLocalStorage('hideUnstakeIntro', false);

  const startingStep = useMemo(() => {
    if (!hasHidUnstakeIntro) {
      return StandardizedUnstakeFlowWizardPage.INTRO;
    }

    return StandardizedUnstakeFlowWizardPage.UNSTAKE;
  }, [hasHidUnstakeIntro]);

  const trackStakingPage = React.useCallback(
    (initiationPage: StandardizedUnstakeFlowWizardPage) => {
      track({
        name: 'standardized_staking:continue:clicked',
        data: {
          initiationPage:
            getStandardizedUnstakeFlowPageEventName(initiationPage),
          stakingType: StandardizedStakingMode.UNSTAKE,
          assetTypeID: assetTypeInfo.assetTypeID,
        },
      });
    },
    [track, assetTypeInfo],
  );

  const [
    prepareDelegationMutation,
    { data: preparedOpData, loading: isLoadingPrepareOp },
  ] = usePrepareDelegationMutation({
    fetchPolicy: 'no-cache',
    variables: {
      amount: getValues('amount')?.toString(),
      assetSubID: walletAsset.assetID.assetSubID,
      assetTypeID: assetTypeInfo.assetTypeID,
      clientID: uuidv4(),
      delegateType: DelegateType.UNDELEGATE,
      destination: validatorAddress,
      vaultSubID,
    },
    onError: (error) => {
      addSnackbar({
        type: 'error',
        text: error.message || 'There was an error previewing your operation.',
        subtext: 'Please try again',
      });
      track({
        name: 'standardized_staking:wizard:flow_completed',
        data: {
          stakingType: StandardizedStakingMode.UNSTAKE,
          initiationPage: getStandardizedUnstakeFlowPageEventName(
            StandardizedUnstakeFlowWizardPage.UNSTAKE,
          ),
          status: ActionResult.FAIL,
          assetTypeID: assetTypeInfo.assetTypeID,
        },
      });
      reportError(
        new WrappedError(
          `There was an error preparing the unstake operation`,
          error,
        ),
      );
    },
  });

  const delegateOperationData =
    preparedOpData?.prepareDelegation as DelegateOperation;
  const preparedOperationID = delegateOperationData?.operationID || '';

  const handleMakeVisibleOpMutationError = React.useCallback(
    (error?: ApolloError | null) => {
      addSnackbar({
        type: 'error',
        text:
          (error && error.message) ||
          'There was an error creating the unstake operation',
      });

      track({
        name: 'standardized_staking:wizard:flow_completed',
        data: {
          stakingType: StandardizedStakingMode.UNSTAKE,
          initiationPage: getStandardizedUnstakeFlowPageEventName(
            StandardizedUnstakeFlowWizardPage.REVIEW,
          ),
          status: ActionResult.FAIL,
          assetTypeID: assetTypeInfo.assetTypeID,
        },
      });
      if (error) {
        reportError(
          new WrappedError(
            `There was an error creating the unstake operation`,
            error,
          ),
        );
      }
    },
    [addSnackbar, track, assetTypeInfo],
  );

  const [createUnstakeOperation, { loading: isCreateUnstakeOperationLoading }] =
    useOperationMakeVisibleInOperationsListMutation({
      onCompleted: (data) => {
        const success = data.operationMakeVisibleInOperationsList;
        if (success) {
          setOperationIDToEndorse(preparedOperationID);
          track({
            name: 'standardized_staking:wizard:flow_completed',
            data: {
              stakingType: StandardizedStakingMode.UNSTAKE,
              status: ActionResult.SUCCESS,
              assetTypeID: assetTypeInfo.assetTypeID,
            },
          });
        } else {
          handleMakeVisibleOpMutationError();
        }
      },
      refetchQueries: [getGraphQLQueryName(VaultWalletsQuery)],
      onError: handleMakeVisibleOpMutationError,
    });

  const handleSubmit = () => {
    if (!preparedOperationID) {
      addSnackbar({
        type: 'error',
        text: 'There was an error creating the unstake operation',
      });
      reportError(
        new UIDeveloperError(
          'preparedOperationID is empty or null. Cannot create unstake operation.',
        ),
      );
      return;
    }

    trackStakingPage(StandardizedUnstakeFlowWizardPage.REVIEW);

    createUnstakeOperation({
      variables: { operationID: preparedOperationID },
    });
  };

  const steps: Step<StandardizedUnstakeFormValues>[] = [
    {
      title: `Unstake ${assetAbbreviation}`,
      element: <UnstakingIntroPage />,
      nextBtnProps: {
        children: 'Yes, unstake',
      },
      backBtnProps: {
        style: {
          display: 'none',
        },
      },
      showProgress: false,
      onNext(goToStepIndex) {
        trackStakingPage(StandardizedUnstakeFlowWizardPage.INTRO);
        goToStepIndex(StandardizedUnstakeFlowWizardPage.UNSTAKE);
      },
    },
    {
      title: `Unstake ${assetAbbreviation}`,
      description: walletName,
      formFieldNames: ['amount'],
      element: (
        <StandardizedUnstakeFormPage unstakeFormSteps={unstakeFormSteps} />
      ),
      showProgress: true,
      displayedStepNumber: 1,
      nextBtnProps: {
        children: 'Preview Operation',
        isDisabled: !isValid || isLoadingPrepareOp,
        isLoading: isLoadingPrepareOp,
      },
      backBtnProps: {
        style: {
          display: 'none',
        },
      },
      onNext(goToStepIndex) {
        trackStakingPage(StandardizedUnstakeFlowWizardPage.UNSTAKE);

        prepareDelegationMutation({
          onCompleted: () => {
            goToStepIndex(StandardizedUnstakeFlowWizardPage.REVIEW);
          },
        });
      },
    },
    {
      title: 'Preview operation',
      formFieldNames: ['comment'],
      element: (
        <SSStakeUnstakeReviewPage data={preparedOpData?.prepareDelegation} />
      ),
      showProgress: true,
      isSubmitStep: true,
      displayedStepNumber: 2,
      nextBtnProps: {
        children: 'Confirm and submit for approval',
        isDisabled: !isValid || isCreateUnstakeOperationLoading,
        isLoading: isCreateUnstakeOperationLoading,
      },
      backBtnProps: {
        children: 'Go back',
      },
    },
  ];

  return (
    <>
      <FormWizard<StandardizedUnstakeFormValues>
        isOpen={isOpen}
        onClose={() => {
          form.reset();
          onClose();
        }}
        form={form}
        steps={steps}
        onSubmit={handleSubmit}
        modalProps={{
          wrapperClassName: css.modalWrapper,
          allowBackdropClose: true,
          allowEscapeClose: true,
        }}
        startingStep={startingStep}
        displayedMaxSteps={2}
      />
      {operationIDToEndorse ? (
        <EndorseActionModal
          isVisible={!!operationIDToEndorse}
          onClose={() => {
            setOperationIDToEndorse('');
            onClose();
            form.reset();
          }}
          operationID={operationIDToEndorse}
          operationAction={OperationAction.GENERIC}
        />
      ) : null}
    </>
  );
};
