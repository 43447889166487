import cn from 'classnames';

const Filter2Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Filter2', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6 10H2V42C2 44.21 3.79 46 6 46H38V42H6V10ZM42 2H14C11.79 2 10 3.79 10 6V34C10 36.21 11.79 38 14 38H42C44.21 38 46 36.21 46 34V6C46 3.79 44.21 2 42 2ZM42 34H14V6H42V34ZM34 26H26V22H30C32.21 22 34 20.21 34 18V14C34 11.79 32.21 10 30 10H22V14H30V18H26C23.79 18 22 19.79 22 22V30H34V26Z" />
    </svg>
  );
};

export { Filter2Icon };
