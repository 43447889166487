import cn from 'classnames';

const LocalDiningIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalDining', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M16.2 26.69L21.86 21.03L7.82999 7.00001C4.70999 10.12 4.70999 15.19 7.82999 18.31L16.2 26.69ZM29.77 23.06C32.82 24.49 37.13 23.48 40.31 20.3C44.14 16.47 44.87 11 41.94 8.07001C39 5.14 33.52 5.87001 29.7 9.70001C26.52 12.88 25.52 17.19 26.94 20.24C22.49 24.68 7.40999 39.76 7.40999 39.76L10.24 42.59L24 28.83L37.76 42.59L40.59 39.76L26.83 26L29.77 23.06Z" />
    </svg>
  );
};

export { LocalDiningIcon };
