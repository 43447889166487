import cn from 'classnames';

const OperatorsIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Operators', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M5.08642 6.86178V8.26603H6.08642V6.86178H7.49055V5.86178H6.08642V4.45747H5.08642V5.86178H3.68199V6.86178H5.08642Z" />
      <path d="M10.3743 13.0541L13.6739 9.75444L14.381 10.4615L11.0814 13.7612L10.3743 13.0541Z" />
      <path d="M14.0962 13.0092C14.0962 13.315 13.8484 13.5629 13.5426 13.5629C13.2368 13.5629 12.989 13.315 12.989 13.0092C12.989 12.7034 13.2368 12.4556 13.5426 12.4556C13.8484 12.4556 14.0962 12.7034 14.0962 13.0092Z" />
      <path d="M11.1057 9.88696C10.7999 9.88696 10.552 10.1348 10.552 10.4406C10.552 10.7464 10.7999 10.9943 11.1057 10.9943C11.4114 10.9943 11.6593 10.7464 11.6593 10.4406C11.6593 10.1348 11.4114 9.88696 11.1057 9.88696Z" />
      <path d="M7.49055 11.4321H3.68199V10.4321H7.49055V11.4321Z" />
      <path d="M14.3461 6.86178H10.5376V5.86178H14.3461V6.86178Z" />
      <path d="M7.49055 13.7172H3.68199V12.7172H7.49055V13.7172Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.06549 0.000244141C0.925021 0.000244141 0.000488281 0.924775 0.000488281 2.06524V15.9344C0.000488281 17.0749 0.92502 17.9994 2.06549 17.9994H15.9347C17.0751 17.9994 17.9997 17.0749 17.9997 15.9344V2.06524C17.9997 0.924777 17.0751 0.000244141 15.9347 0.000244141H2.06549ZM1.38049 2.06524C1.38049 1.68693 1.68717 1.38024 2.06549 1.38024H15.9347C16.313 1.38024 16.6197 1.68693 16.6197 2.06524V15.9344C16.6197 16.3127 16.313 16.6194 15.9347 16.6194H2.06549C1.68717 16.6194 1.38049 16.3127 1.38049 15.9344V2.06524Z"
      />
    </svg>
  );
};

export { OperatorsIcon };
