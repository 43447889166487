import cn from 'classnames';

const LocalAtmIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalAtm', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M22 34H26V32H28C29.1 32 30 31.1 30 30V24C30 22.9 29.1 22 28 22H22V20H30V16H26V14H22V16H20C18.9 16 18 16.9 18 18V24C18 25.1 18.9 26 20 26H26V28H18V32H22V34ZM40 8H8C5.79 8 4.02 9.79 4.02 12L4 36C4 38.21 5.79 40 8 40H40C42.21 40 44 38.21 44 36V12C44 9.79 42.21 8 40 8ZM40 36H8V12H40V36Z" />
    </svg>
  );
};

export { LocalAtmIcon };
