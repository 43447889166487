import cn from 'classnames';

const LockOutlineIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LockOutline', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 34C26.2 34 28 32.2 28 30C28 27.8 26.2 26 24 26C21.8 26 20 27.8 20 30C20 32.2 21.8 34 24 34ZM36 16H34V12C34 6.48 29.52 2 24 2C18.48 2 14 6.48 14 12V16H12C9.8 16 8 17.8 8 20V40C8 42.2 9.8 44 12 44H36C38.2 44 40 42.2 40 40V20C40 17.8 38.2 16 36 16ZM17.8 12C17.8 8.58 20.58 5.8 24 5.8C27.42 5.8 30.2 8.58 30.2 12V16H17.8V12ZM36 40H12V20H36V40Z" />
    </svg>
  );
};

export { LockOutlineIcon };
