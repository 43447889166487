import cn from 'classnames';

const CameraRearIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CameraRear', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M20 40H10V44H20V48L26 42L20 36V40ZM28 40V44H38V40H28ZM34 0H14C11.79 0 10 1.79 10 4V32C10 34.21 11.79 36 14 36H34C36.21 36 38 34.21 38 32V4C38 1.79 36.21 0 34 0ZM23.99 12C21.78 12 20 10.21 20 8C20 5.79 21.78 4 23.99 4C26.2 4 27.99 5.79 27.99 8C27.99 10.21 26.2 12 23.99 12Z" />
    </svg>
  );
};

export { CameraRearIcon };
