import cn from 'classnames';

const CodeIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Code', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18.8 33.2L9.7 24L18.9 14.8L16 12L4 24L16 36L18.8 33.2ZM29.2 33.2L38.4 24L29.2 14.8L32 12L44 24L32 36L29.2 33.2Z" />
    </svg>
  );
};

export { CodeIcon };
