import cn from 'classnames';

const ContentCutIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ContentCut', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M19.28 15.28C19.73 14.28 20 13.17 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C13.17 20 14.28 19.73 15.28 19.28L20 24L15.28 28.72C14.28 28.27 13.17 28 12 28C7.58 28 4 31.58 4 36C4 40.42 7.58 44 12 44C16.42 44 20 40.42 20 36C20 34.83 19.73 33.72 19.28 32.72L24 28L38 42H44V40L19.28 15.28ZM12 16C9.79 16 8 14.21 8 12C8 9.79 9.79 8 12 8C14.21 8 16 9.79 16 12C16 14.21 14.21 16 12 16ZM12 40C9.79 40 8 38.21 8 36C8 33.79 9.79 32 12 32C14.21 32 16 33.79 16 36C16 38.21 14.21 40 12 40ZM24 25C23.45 25 23 24.55 23 24C23 23.45 23.45 23 24 23C24.55 23 25 23.45 25 24C25 24.55 24.55 25 24 25ZM38 6L26 18L30 22L44 8V6H38Z" />
    </svg>
  );
};

export { ContentCutIcon };
