import cn from 'classnames';

const BluetoothIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Bluetooth', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M35.41 15.41L24 4H22V19.17L12.83 10L10 12.83L21.17 24L10 35.17L12.83 38L22 28.83V44H24L35.41 32.59L26.83 24L35.41 15.41ZM26 11.66L29.76 15.42L26 19.17V11.66ZM29.76 32.59L26 36.34V28.82L29.76 32.59Z" />
    </svg>
  );
};

export { BluetoothIcon };
