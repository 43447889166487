import cn from 'classnames';

const CameraFrontIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CameraFront', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M20 40H10V44H20V48L26 42L20 36V40ZM28 40V44H38V40H28ZM24 16C26.21 16 28 14.21 28 12C28 9.79 26.21 8 24 8C21.79 8 20.01 9.79 20.01 12C20.02 14.21 21.79 16 24 16ZM34 0H14C11.79 0 10 1.79 10 4V32C10 34.21 11.79 36 14 36H34C36.21 36 38 34.21 38 32V4C38 1.79 36.21 0 34 0ZM14 4H34V25C34 21.67 27.33 20 24 20C20.67 20 14 21.67 14 25V4Z" />
    </svg>
  );
};

export { CameraFrontIcon };
