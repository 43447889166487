import { transformAddresses } from '../../../../utils/mapAddressToWallets';

import type { OperationDrawer } from 'generated/graphql';

import AddressInfo from '../../../widgets/AddressInfo';
import Asset from '../../../widgets/Asset';

type DepositOperationType = Extract<
  OperationDrawer.operation,
  { __typename: 'DepositOperation' }
>;

type Props = {
  operation: DepositOperationType;
};

const Deposit = ({ operation }: Props) => {
  const {
    asset: { assetTypeInfo, vault: receivingVault },
    maybeReceivingBlockchainAddress,
  } = operation;

  const { account } = receivingVault;

  return (
    <>
      <Asset {...assetTypeInfo} />
      <AddressInfo
        addresses={transformAddresses(maybeReceivingBlockchainAddress)}
        addressSectionTitle="In"
        vaultName={receivingVault.name}
        blockchainExplorerURL={
          maybeReceivingBlockchainAddress?.blockchainExplorerURL
        }
        accountName={account?.displayName}
      />
    </>
  );
};

export default Deposit;
