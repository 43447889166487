import cn from 'classnames';

const FindInPageIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FindInPage', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 39.17V16L28 4H12C9.79 4 8.02 5.79 8.02 8L8 40C8 42.21 9.77 44 11.98 44H36C36.89 44 37.71 43.7 38.37 43.2L29.5 34.33C27.93 35.38 26.04 36 24 36C18.48 36 14 31.52 14 26C14 20.48 18.48 16 24 16C29.52 16 34 20.48 34 26C34 28.04 33.38 29.93 32.34 31.51L40 39.17ZM18 26C18 29.31 20.69 32 24 32C27.31 32 30 29.31 30 26C30 22.69 27.31 20 24 20C20.69 20 18 22.69 18 26Z" />
    </svg>
  );
};

export { FindInPageIcon };
