import cn from 'classnames';

const DirectionsBoatIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DirectionsBoat', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 42C37.22 42 34.44 41.06 32 39.35C27.12 42.77 20.88 42.77 16 39.35C13.56 41.06 10.78 42 8 42H4V46H8C10.75 46 13.48 45.31 16 44.01C21.04 46.6 26.96 46.6 32 44.01C34.52 45.3 37.25 46 40 46H44V42H40ZM7.89 38H8C11.2 38 14.05 36.24 16 34C17.95 36.24 20.8 38 24 38C27.2 38 30.05 36.24 32 34C33.96 36.24 36.79 38 40 38H40.11L43.9 24.63C44.07 24.12 44.02 23.56 43.78 23.08C43.53 22.6 43.1 22.24 42.58 22.09L40 21.24V12C40 9.79 38.21 8 36 8H30V2H18V8H12C9.79 8 8 9.79 8 12V21.24L5.43 22.08C4.91 22.24 4.48 22.59 4.23 23.07C3.98 23.55 3.94 24.11 4.11 24.62L7.89 38ZM12 12H36V19.93L24 16L12 19.93V12Z" />
    </svg>
  );
};

export { DirectionsBoatIcon };
