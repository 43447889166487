import cn from 'classnames';

const FilterFramesIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FilterFrames', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 8H32L24 0L16 8H8C5.79 8 4 9.79 4 12V40C4 42.21 5.79 44 8 44H40C42.21 44 44 42.21 44 40V12C44 9.79 42.21 8 40 8ZM40 40H8V12H17.03L24.07 5L31.03 12H40V40ZM36 16H12V36H36" />
    </svg>
  );
};

export { FilterFramesIcon };
