import { cva } from 'class-variance-authority';

export const alertBannerVariants = cva(
  [
    'flex',
    'flex-col',
    'flex-start',
    'self-stretch',
    'border-t-2',
    'bg-color-ref-ui-background-container',
    'w-full',
  ],
  {
    variants: {
      variant: {
        info: 'border-color-ref-ui-status-info',
        success: 'border-color-ref-ui-status-success',
        warning: 'border-color-ref-ui-status-warning',
        error: 'border-color-ref-ui-status-danger',
      },
    },
    defaultVariants: {
      variant: 'info',
    },
  },
);
