import cn from 'classnames';

const CopyIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Copy', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g id="product_final_copy_24px">
        <g id="Icon">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 6.00098C5 5.17255 5.67157 4.50098 6.5 4.50098H13C13.8284 4.50098 14.5 5.17255 14.5 6.00098V12.501C14.5 13.3294 13.8284 14.001 13 14.001H6.5C5.67157 14.001 5 13.3294 5 12.501V6.00098ZM6.5 5.50098C6.22386 5.50098 6 5.72483 6 6.00098V12.501C6 12.7771 6.22386 13.001 6.5 13.001H13C13.2761 13.001 13.5 12.7771 13.5 12.501V6.00098C13.5 5.72483 13.2761 5.50098 13 5.50098H6.5Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 3.50098C2.5 2.67255 3.17157 2.00098 4 2.00098H11.5C12.0523 2.00098 12.5 2.44869 12.5 3.00098H4C3.72386 3.00098 3.5 3.22483 3.5 3.50098V12.001C2.94772 12.001 2.5 11.5533 2.5 11.001V3.50098Z"
          />
        </g>
      </g>
    </svg>
  );
};

export { CopyIcon };
