import cn from 'classnames';

const HourglassFullIcon = (props: SVGProps): JSX.Element => {
  const { title = 'HourglassFull', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M32 4H12V15H12.02L12 15.02L20.98 24L12 32.98L12.02 33H12V44H36V33H35.98L36 32.98L27.02 24L36 15.02L35.98 15H36V4H32Z" />
    </svg>
  );
};

export { HourglassFullIcon };
