import cn from 'classnames';

const AcUnitIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AcUnit', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M44 22H35.66L42.14 15.51L39.31 12.69L30 22H26V18L35.31 8.69L32.49 5.86L26 12.34V4H22V12.34L15.51 5.86L12.69 8.69L22 18V22H18L8.69 12.69L5.86 15.51L12.34 22H4V26H12.34L5.86 32.49L8.69 35.31L18 26H22V30L12.69 39.31L15.51 42.14L22 35.66V44H26V35.66L32.49 42.14L35.31 39.31L26 30V26H30L39.31 35.31L42.14 32.49L35.66 26H44V22Z" />
    </svg>
  );
};

export { AcUnitIcon };
