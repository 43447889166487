import { usePathname } from 'next/navigation';
import { useReportWebVitals } from 'next/web-vitals';
import React from 'react';

import { useAnalytics } from '@anchorage/analytics';

import { stripIDsFromPath } from './utils';

const AppAnalytics: React.FC = () => {
  const { track } = useAnalytics();
  const pathname = usePathname();

  const cleanPathname = stripIDsFromPath(pathname);

  useReportWebVitals((metric) => {
    const properties = {
      pathname: cleanPathname,
      value: metric.value,
      navigationType: metric.navigationType,
      rating: metric.rating,
    };

    switch (metric.name) {
      case 'FCP': {
        track({
          name: 'client_dashboard:web_vitals:first_contentful_paint',
          data: properties,
        });
        break;
      }
      case 'LCP': {
        track({
          name: 'client_dashboard:web_vitals:largest_contentful_paint',
          data: properties,
        });
        break;
      }
      default:
        break;
    }
  });

  React.useEffect(() => {
    track({
      name: 'navigation:client_dashboard:page_view',
      data: {
        pathname: cleanPathname,
      },
    });
  }, [track, cleanPathname]);

  React.useEffect(() => {
    track({
      name: 'navigation:client_dashboard:app_loaded',
    });
  }, [track]);

  return <></>;
};

export default AppAnalytics;
