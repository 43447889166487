import { Button } from '@latitude/button';
import React, { useCallback, useMemo } from 'react';

import { Text } from '@anchorage/common/dist/components';
import {
  FormProvider,
  useForm,
} from '@anchorage/common/dist/components/Form/_reactHookForm';
import { FormWizardProvider } from '@anchorage/common/dist/components/FormWizard/FormWizardProvider';
import {
  FormWizardContent,
  FormWizardProgressBtns,
} from '@anchorage/common/dist/components/FormWizard/components';
import {
  AnchorageLogoIcon,
  CloseIcon,
} from '@anchorage/common/dist/components/Icons';

import useInAppSurvey from 'hooks/useInAppSurvey/useInAppSurvey';

import css from './styles.module.scss';

import { createFormSteps } from './helpers';

const InAppSurvey: React.FC = () => {
  const {
    surveySteps,
    answerSurvey,
    closeSurvey,
    isSurveyOpen,
    defaultValues,
  } = useInAppSurvey();
  const form = useForm<Record<string, any>>({
    mode: 'onChange',
    defaultValues,
  });

  const onClose = useCallback(() => {
    closeSurvey();
  }, [closeSurvey]);

  const onSubmit = useCallback(
    (data: any) => {
      answerSurvey(data);
    },
    [answerSurvey],
  );

  const formSteps = useMemo(() => {
    return createFormSteps({
      steps: surveySteps,
      onSubmit: () => onSubmit(form.getValues()),
      onClose,
    });
  }, [surveySteps, onClose, form, onSubmit]);

  return isSurveyOpen ? (
    <div className={css.surveyContainer}>
      <div className={css.surveyHeader}>
        <AnchorageLogoIcon className={css.surveyLogo} />
        <div className={css.surveyHeaderText}>
          <Text size="small">Anchorage Digital</Text>
          <Text size="tiny">Your opinion matters to us</Text>
        </div>
        <Button
          type="ghost"
          leadingElement={<CloseIcon />}
          className={css.surveyClose}
          onClick={onClose}
        />
      </div>
      <FormProvider {...form}>
        <FormWizardProvider
          onSubmit={onSubmit}
          steps={formSteps}
          formFieldObject={form.getValues()}
        >
          <FormWizardContent />
          <FormWizardProgressBtns className={css.surveyBtns} />
        </FormWizardProvider>
      </FormProvider>
    </div>
  ) : null;
};

export default InAppSurvey;
