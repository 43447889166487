import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

import { useAnalytics } from '@anchorage/analytics';
import {
  UIDeveloperError,
  reportError,
} from '@anchorage/common/dist/utils/errors';

import { LOGIN_PATH } from '../../../utils/routes';

import { useProductUpdatesFeedQuery } from '../../../generated/graphql';

import type {
  ProductUpdates,
  SetProductUpdatesAsRead,
  UpdateProductUpdatesOpenState,
} from './types';

export const productUpdatesInitialState: ProductUpdates = {
  isOpen: false,
  count: 0,
  hasUpdates: false,
  isLoading: false,
  hasError: false,
  updateProductUpdatesOpenState: () => {},
  setProductUpdatesAsRead: () => {},
};

export const useProductUpdatesState = (): ProductUpdates => {
  const { track } = useAnalytics();
  const { pathname } = useRouter();

  const [isProductUpdatesOpen, setIsProductUpdatesOpen] = useState(false);
  const [productUpdatesCount, setProductUpdatesCount] = useState(0);
  const [isRead, setIsRead] = useState(false);

  const skipForLoginPage = pathname === LOGIN_PATH;

  const { loading, error, data } = useProductUpdatesFeedQuery({
    skip: skipForLoginPage,
    onCompleted: (data) => {
      setProductUpdatesCount(data.productUpdates.unreadCount || 0);
    },
  });

  const updateProductUpdatesOpenState: UpdateProductUpdatesOpenState =
    useCallback(
      (isOpen) => {
        setIsProductUpdatesOpen(isOpen);
        isOpen &&
          track({
            name: 'product_updates:drawer:opened',
          });
      },
      [track],
    );

  const setProductUpdatesAsRead: SetProductUpdatesAsRead = useCallback(() => {
    setIsRead(true);
  }, []);

  if (error) {
    reportError(
      new UIDeveloperError(
        `Unable to show product updates. Error: ${error.message}`,
      ),
    );
  }

  return {
    isOpen: isProductUpdatesOpen,
    count: productUpdatesCount,
    hasUpdates: productUpdatesCount > 0 && !isRead,
    isLoading: loading,
    feedURL: data?.productUpdates.feedURL,
    hasError: !!error,
    updateProductUpdatesOpenState,
    setProductUpdatesAsRead,
  };
};
