import cn from 'classnames';

const FilterCenterFocusIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FilterCenterFocus', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M10 30H6V38C6 40.21 7.79 42 10 42H18V38H10V30ZM10 10H18V6H10C7.79 6 6 7.79 6 10V18H10V10ZM38 6H30V10H38V18H42V10C42 7.79 40.21 6 38 6ZM38 38H30V42H38C40.21 42 42 40.21 42 38V30H38V38ZM24 18C20.69 18 18 20.69 18 24C18 27.31 20.69 30 24 30C27.31 30 30 27.31 30 24C30 20.69 27.31 18 24 18Z" />
    </svg>
  );
};

export { FilterCenterFocusIcon };
