import cn from 'classnames';

const DeviceHubIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DeviceHub24px', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M34 32L26 24V17.64C28.32 16.8 30 14.6 30 12C30 8.68 27.32 6 24 6C20.68 6 18 8.68 18 12C18 14.6 19.68 16.8 22 17.64V24L14 32H6V42H16V35.9L24 27.5L32 35.9V42H42V32H34Z" />
    </svg>
  );
};

export { DeviceHubIcon };
