import { Modal } from '@latitude/modal';
import { useMemo } from 'react';

import getAutoCompoundPrimaryButtonTitle from '../../utils/getAutoCompoundPrimaryButtonTitle';

import { StandardizedStakingStep } from 'generated/graphql';

import { AutoCompoundMode, StandardizedStakingMode } from '../../constants';
import AutoCompoundContentPage from '../AutoCompoundContentPage';
import useUpdateAutoCompound from '../useUpdateAutoCompound';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  autoCompoundMode: AutoCompoundMode | null;
  autoCompoundFormStep?: StandardizedStakingStep.AutoCompoundStepInlineFragment;
}

const AutoCompoundModal = ({
  isOpen,
  onClose,
  autoCompoundMode,
  autoCompoundFormStep,
}: Props) => {
  const isEnableAutoCompoundMode =
    autoCompoundMode === StandardizedStakingMode.ENABLE_AUTO_COMPOUND;

  const [updateAutoCompoundMutation, { loading: isLoading }] =
    useUpdateAutoCompound({
      onCompleted: onClose,
      showSnackbarNotifications: true,
      newAutoCompoundSettingValue: isEnableAutoCompoundMode,
      analyticsEventName: 'standardized_staking:wizard:flow_completed',
    });

  const primaryButtonTitle = useMemo(() => {
    return getAutoCompoundPrimaryButtonTitle({
      isEnableAutoCompoundMode,
      autoCompoundFormStep,
    });
  }, [isEnableAutoCompoundMode, autoCompoundFormStep]);

  if (!autoCompoundMode || !autoCompoundFormStep) {
    return null;
  }

  return (
    <Modal
      size="medium"
      title={autoCompoundFormStep.title}
      isOpen={isOpen}
      onClose={onClose}
      leadingAction={{
        label: 'Cancel',
        onClick: onClose,
        disabled: isLoading,
      }}
      primaryAction={{
        label: primaryButtonTitle,
        onClick: () => {
          updateAutoCompoundMutation();
        },
        loading: isLoading,
        disabled: isLoading,
      }}
    >
      <AutoCompoundContentPage
        subtitle={autoCompoundFormStep.subtitle ?? ''}
        content={autoCompoundFormStep.content}
        autoCompoundMode={autoCompoundMode}
      />
    </Modal>
  );
};

export default AutoCompoundModal;
