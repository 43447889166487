import cn from 'classnames';

const ChromeReaderModeIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ChromeReaderMode', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M26 24H40V27H26V24ZM26 19H40V22H26V19ZM26 29H40V32H26V29ZM42 8H6C3.8 8 2 9.8 2 12V38C2 40.2 3.8 42 6 42H42C44.2 42 46 40.2 46 38V12C46 9.8 44.2 8 42 8ZM42 38H24V12H42V38Z" />
    </svg>
  );
};

export { ChromeReaderModeIcon };
