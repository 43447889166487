// Utils
import cn from 'classnames';
import React from 'react';

import commonCss from '../../common.css';

// Styles
import css from '../index.css';

const CollectionEmptyPlaceholder: React.FC = () => (
  <div className={css.collectionPlaceholderContainer}>
    <div className={css.collectionPlaceholderHeader}>
      <div>
        <div
          className={cn([
            css.collectionTitlePlaceholder,
            commonCss.mediumShimmerAnimation,
          ])}
        />
        <div
          className={cn([
            css.collectionSubtextPlaceholder,
            commonCss.mediumShimmerAnimation,
          ])}
        />
      </div>
      <div
        className={cn([
          css.collectionNftCountPlaceholder,
          commonCss.mediumShimmerAnimation,
        ])}
      />
    </div>
    <div
      className={cn([
        css.collectionImagePlaceholder,
        commonCss.mediumShimmerAnimation,
      ])}
    />
  </div>
);

export default CollectionEmptyPlaceholder;
