import cn from 'classnames';

const ArchiveIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Archive', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M41.09 10.45L38.32 7.09C37.76 6.43 36.93 6 36 6H12C11.07 6 10.24 6.43 9.69 7.09L6.92 10.45C6.34 11.15 6 12.03 6 13V38C6 40.21 7.79 42 10 42H38C40.21 42 42 40.21 42 38V13C42 12.03 41.66 11.15 41.09 10.45ZM24 35L13 24H20V20H28V24H35L24 35ZM10.25 10L11.88 8H35.88L37.75 10H10.25Z" />
    </svg>
  );
};

export { ArchiveIcon };
