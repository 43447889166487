import cn from 'classnames';

const Filter9PlusIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Filter9Plus', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6 10H2V42C2 44.21 3.79 46 6 46H38V42H6V10ZM28 24V16C28 13.79 26.21 12 24 12H22C19.79 12 18 13.79 18 16V18C18 20.21 19.79 22 22 22H24V24H18V28H24C26.21 28 28 26.21 28 24ZM22 18V16H24V18H22ZM42 2H14C11.79 2 10 3.79 10 6V34C10 36.21 11.79 38 14 38H42C44.21 38 46 36.21 46 34V6C46 3.79 44.21 2 42 2ZM42 18H38V14H34V18H30V22H34V26H38V22H42V34H14V6H42V18Z" />
    </svg>
  );
};

export { Filter9PlusIcon };
