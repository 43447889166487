import cn from 'classnames';

const ClearIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Clear', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M9.7224 5.33429L7.99906 7.05762L6.27573 5.33429L5.3324 6.27762L7.05573 8.00096L5.3324 9.72429L6.27573 10.6676L7.99906 8.94429L9.7224 10.6676L10.6657 9.72429L8.9424 8.00096L10.6657 6.27762L9.7224 5.33429ZM7.99906 1.33429C4.31573 1.33429 1.3324 4.31762 1.3324 8.00096C1.3324 11.6843 4.31573 14.6676 7.99906 14.6676C11.6824 14.6676 14.6657 11.6843 14.6657 8.00096C14.6657 4.31762 11.6824 1.33429 7.99906 1.33429ZM7.99906 13.3343C5.05906 13.3343 2.66573 10.941 2.66573 8.00096C2.66573 5.06096 5.05906 2.66762 7.99906 2.66762C10.9391 2.66762 13.3324 5.06096 13.3324 8.00096C13.3324 10.941 10.9391 13.3343 7.99906 13.3343Z" />
    </svg>
  );
};

export { ClearIcon };
