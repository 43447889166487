import cn from 'classnames';

const AddLocationIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AddLocation', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 4C16.28 4 10 10.28 10 18C10 28.5 24 44 24 44C24 44 38 28.5 38 18C38 10.28 31.72 4 24 4ZM32 20H26V26H22V20H16V16H22V10H26V16H32V20Z" />
    </svg>
  );
};

export { AddLocationIcon };
