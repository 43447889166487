import { type ReactNode, createContext } from 'react';

export type FeatureMap = Record<string, boolean>;

export const FeatureFlagsBEContext =
  createContext<Partial<FeatureMap | null>>(null);

export const FeatureFlagsBEProvider = ({
  features,
  children,
}: {
  features: { name: string }[];
  children: ReactNode;
}) => {
  const featureMap = features.reduce<FeatureMap>((acc, { name }) => {
    acc[name] = true;

    return acc;
  }, {});

  return (
    <FeatureFlagsBEContext.Provider value={featureMap}>
      {children}
    </FeatureFlagsBEContext.Provider>
  );
};
