import cn from 'classnames';

const LocalPizzaIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalPizza', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 4C16.87 4 10.45 7.09 6.02002 12L24 44L41.99 12.01C37.56 7.09 31.14 4 24 4ZM14 14C14 11.79 15.79 10 18 10C20.21 10 22 11.79 22 14C22 16.21 20.21 18 18 18C15.79 18 14 16.21 14 14ZM24 30C21.79 30 20 28.21 20 26C20 23.79 21.79 22 24 22C26.21 22 28 23.79 28 26C28 28.21 26.21 30 24 30Z" />
    </svg>
  );
};

export { LocalPizzaIcon };
