import css from './styles.module.scss';

import type { RoleChanges } from '../../types';

import SectionRow from '../../../../shared/SectionRow';
import WidgetWrapper from '../../../../shared/WidgetWrapper';
import UpdatedIAMRoleResourceAssignmentsAccordion from './UpdatedIAMRoleResourceAssignmentsAccordion';
import UpdatedIAMRoleResourceAssignmentsTitle from './UpdatedIAMRoleResourceAssignmentsTitle';

export const UpdatedIAMRoleResourceAssignments = ({
  changes,
}: {
  changes: RoleChanges['changes'];
}) => {
  return (
    <WidgetWrapper>
      <SectionRow title="Assigned as">
        <div className={css.contentWrapper}>
          {changes.map((roleChange) => (
            <div key={roleChange.roleSnapshot.id}>
              {roleChange.resources.length === 1 ? (
                <UpdatedIAMRoleResourceAssignmentsTitle
                  roleChange={roleChange}
                  expanded={false}
                />
              ) : (
                <UpdatedIAMRoleResourceAssignmentsAccordion
                  roleChange={roleChange}
                />
              )}
            </div>
          ))}
        </div>
      </SectionRow>
    </WidgetWrapper>
  );
};
