import cn from 'classnames';

const BlurLinearIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BlurLinear', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M10 35C11.66 35 13 33.66 13 32C13 30.34 11.66 29 10 29C8.34 29 7 30.34 7 32C7 33.66 8.34 35 10 35ZM18 26C19.1 26 20 25.1 20 24C20 22.9 19.1 22 18 22C16.9 22 16 22.9 16 24C16 25.1 16.9 26 18 26ZM18 18C19.1 18 20 17.1 20 16C20 14.9 19.1 14 18 14C16.9 14 16 14.9 16 16C16 17.1 16.9 18 18 18ZM6 42H42V38H6V42ZM10 19C11.66 19 13 17.66 13 16C13 14.34 11.66 13 10 13C8.34 13 7 14.34 7 16C7 17.66 8.34 19 10 19ZM10 27C11.66 27 13 25.66 13 24C13 22.34 11.66 21 10 21C8.34 21 7 22.34 7 24C7 25.66 8.34 27 10 27ZM18 34C19.1 34 20 33.1 20 32C20 30.9 19.1 30 18 30C16.9 30 16 30.9 16 32C16 33.1 16.9 34 18 34ZM34 33C34.55 33 35 32.55 35 32C35 31.45 34.55 31 34 31C33.45 31 33 31.45 33 32C33 32.55 33.45 33 34 33ZM6 6V10H42V6H6ZM34 17C34.55 17 35 16.55 35 16C35 15.45 34.55 15 34 15C33.45 15 33 15.45 33 16C33 16.55 33.45 17 34 17ZM34 25C34.55 25 35 24.55 35 24C35 23.45 34.55 23 34 23C33.45 23 33 23.45 33 24C33 24.55 33.45 25 34 25ZM26 18C27.1 18 28 17.1 28 16C28 14.9 27.1 14 26 14C24.9 14 24 14.9 24 16C24 17.1 24.9 18 26 18ZM26 26C27.1 26 28 25.1 28 24C28 22.9 27.1 22 26 22C24.9 22 24 22.9 24 24C24 25.1 24.9 26 26 26ZM26 34C27.1 34 28 33.1 28 32C28 30.9 27.1 30 26 30C24.9 30 24 30.9 24 32C24 33.1 24.9 34 26 34Z" />
    </svg>
  );
};

export { BlurLinearIcon };
