import cn from 'classnames';

const ExposurePlus2Icon = (props: SVGProps): JSX.Element => {
  const { title = 'ExposurePlus2', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M32.09 32.58L37.82 26.45C38.57 25.66 39.26 24.88 39.9 24.1C40.53 23.32 41.08 22.54 41.54 21.77C42 20.99 42.36 20.22 42.61 19.44C42.87 18.66 43 17.87 43 17.07C43 16 42.82 15.03 42.46 14.15C42.1 13.28 41.58 12.53 40.89 11.92C40.2 11.31 39.36 10.84 38.36 10.5C37.36 10.17 36.22 10 34.94 10C33.56 10 32.32 10.21 31.24 10.64C30.16 11.07 29.25 11.65 28.51 12.39C27.77 13.13 27.21 14 26.83 14.99C26.47 15.93 26.29 16.94 26.27 18H30.55C30.56 17.38 30.64 16.79 30.81 16.26C30.99 15.68 31.26 15.18 31.62 14.76C31.98 14.34 32.43 14.02 32.97 13.78C33.52 13.55 34.16 13.43 34.9 13.43C35.51 13.43 36.05 13.53 36.52 13.74C36.99 13.95 37.39 14.23 37.71 14.59C38.03 14.95 38.28 15.39 38.45 15.88C38.62 16.38 38.7 16.92 38.7 17.51C38.7 17.94 38.64 18.38 38.53 18.81C38.42 19.24 38.23 19.71 37.95 20.21C37.67 20.71 37.3 21.26 36.84 21.87C36.38 22.47 35.79 23.16 35.09 23.94L26.74 33.05V36H44V32.58H32.09ZM16 14H12V22H4V26H12V34H16V26H24V22H16V14Z" />
    </svg>
  );
};

export { ExposurePlus2Icon };
