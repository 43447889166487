import cn from 'classnames';

const LocalLibraryIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalLibrary', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 23.09C19.27 18.7 12.96 16 6 16V38C12.96 38 19.27 40.7 24 45.09C28.73 40.7 35.04 38 42 38V16C35.04 16 28.73 18.7 24 23.09ZM24 16C27.31 16 30 13.31 30 10C30 6.69 27.31 4 24 4C20.69 4 18 6.69 18 10C18 13.31 20.69 16 24 16Z" />
    </svg>
  );
};

export { LocalLibraryIcon };
