import cn from 'classnames';
import { ROLE_ACTIONS_TITLES } from 'constants/operations';

import { Text } from '@anchorage/common/dist/components';

import { ChangeRoleStatus } from 'components/shared/OperationDrawer/utils/permissions';
import type { UpdatedPermissions } from 'components/shared/OperationDrawer/utils/permissions';

import { RoleAction } from 'generated/graphql';
import type { RoleChangeType } from 'generated/graphql';

import css from './styles.module.scss';

type Props = {
  action: keyof typeof RoleAction;
  actionPermissions: UpdatedPermissions | undefined;
  roleChangeType: RoleChangeType;
};

const ActionSection = ({
  action,
  actionPermissions,
  roleChangeType,
  ...restProps
}: Props) => {
  if (!actionPermissions) {
    return null;
  }

  const { actionStatus, updates } = actionPermissions;
  const isUpdateOperation = roleChangeType === 'UPDATE';
  const actionDisplayName = ROLE_ACTIONS_TITLES[action];
  const actionDisplayClass = cn({
    [css[actionStatus]!]:
      isUpdateOperation && actionStatus !== ChangeRoleStatus.ORIGINAL,
    [css.actionOriginal!]:
      isUpdateOperation && actionStatus === ChangeRoleStatus.ORIGINAL,
  });

  return (
    <div className={css.actionSection} {...restProps}>
      <Text className={actionDisplayClass} size="small">
        {actionDisplayName}
      </Text>
      {updates.map(({ name, vaultStatus }) => {
        const classes = cn({
          [css.vaults!]: true,
          [css.original!]: !isUpdateOperation,
          [css[vaultStatus]!]: isUpdateOperation,
        });
        return (
          <Text key={`${action}-${name}`} size="small" className={classes}>
            {name}
          </Text>
        );
      })}
    </div>
  );
};

export default ActionSection;
