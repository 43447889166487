import cn from 'classnames';

const AirportShuttleIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AirportShuttle', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M34 10H6C3.79 10 2 11.79 2 14V32H6C6 35.31 8.69 38 12 38C15.31 38 18 35.31 18 32H29C29 35.31 31.69 38 35 38C38.31 38 41 35.31 41 32H46V22L34 10ZM6 22V14H14V22H6ZM12 35C10.34 35 9 33.66 9 32C9 30.34 10.34 29 12 29C13.66 29 15 30.34 15 32C15 33.66 13.66 35 12 35ZM26 22H18V14H26V22ZM35 35C33.34 35 32 33.66 32 32C32 30.34 33.34 29 35 29C36.66 29 38 30.34 38 32C38 33.66 36.66 35 35 35ZM30 22V14H32L40 22H30Z" />
    </svg>
  );
};

export { AirportShuttleIcon };
