import { Text } from '@anchorage/common/dist/components';
import { StarCircleIcon } from '@anchorage/common/dist/components/Icons';
import { NormalizedSignedAddressField } from '@anchorage/vsp';

import css from './styles.module.scss';

import { AddressDetail } from '../../../Address/AddressBadges';

export const AddressRow = ({
  field,
}: {
  field: NormalizedSignedAddressField;
}) => {
  return (
    <>
      <Text size="small" className={css.label}>
        {field.label}
      </Text>
      <div className={css.addressContent}>
        <Text>{field.name}</Text>
        <Text size="small">{field.address}</Text>
        <Text size="small">{field.memo ?? ''}</Text>
        <Text size="small">{field.assetLabel ?? ''}</Text>
        {/* example badgeText: "Trusted smart contract" */}
        {field.badgeText && (
          <AddressDetail description={field.badgeText} Icon={StarCircleIcon} />
        )}
      </div>
    </>
  );
};
