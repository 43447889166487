import cn from 'classnames';

const ArrowUpwardIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ArrowUpward', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M8 24L10.83 26.83L22 15.66V40H26V15.66L37.17 26.83L40 24L24 7.99996L8 24Z" />
    </svg>
  );
};

export { ArrowUpwardIcon };
