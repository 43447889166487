import { ROLE_ACTIONS_TITLES } from 'constants/operations';
import { format } from 'date-fns';

import { formatEnum } from '@anchorage/common/dist/utils/format';

import { APIKeyStatus, RoleAction } from 'generated/graphql';

const { ACTIVE, PENDING, REVOKED } = APIKeyStatus;

// Given a role action enum, return the "human-readable" string for it
export const formatRoleActionEnum = (roleActionEnum: RoleAction): string =>
  ROLE_ACTIONS_TITLES[roleActionEnum] ?? formatEnum(roleActionEnum);

export const formatApiKeyEnum = (apiKeyEnum: APIKeyStatus): string => {
  switch (apiKeyEnum) {
    case ACTIVE:
      return 'Active';
    case PENDING:
      return 'Inactive - pending approval';
    case REVOKED:
      return 'Revoked';
    default:
      return formatEnum(apiKeyEnum);
  }
};

// TODO Andre 04/21/2021 - Temporary function to remove dollar sign from
// displayValue. Should be removed when possible.
export const removeCurrencySymbolFromDisplayValue = (
  displayValue: string,
): string => {
  return displayValue.replace('$', '');
};

export const formatDateObject = (date: Date): string => {
  return format(date, 'MMM d, yyyy');
};

export const formateDateObjectWithTime = (date: Date): string => {
  return format(date, 'MMM d, yyyy, h:mm a');
};

export const formatUnixTimestampToDate = (nanoseconds: string) => {
  const date = new Date(parseInt(nanoseconds) / 1000000);
  return format(date, 'MMM d, yyyy');
};

export const formatAccountNumber = (accountNumber: string): string => {
  return `(•••${accountNumber.substring(accountNumber.length - 4)})`;
};
