import cn from 'classnames';

const CropSquareIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CropSquare', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M36 8H12C9.79 8 8 9.79 8 12V36C8 38.21 9.79 40 12 40H36C38.21 40 40 38.21 40 36V12C40 9.79 38.21 8 36 8ZM36 36H12V12H36V36Z" />
    </svg>
  );
};

export { CropSquareIcon };
