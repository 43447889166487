import cn from 'classnames';

const AccountBalanceWalletIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AccountBalanceWallet', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 36V38C42 40.21 40.21 42 38 42H10C7.79 42 6 40.21 6 38V10C6 7.79 7.79 6 10 6H38C40.21 6 42 7.79 42 10V12H24C21.79 12 20 13.79 20 16V32C20 34.21 21.79 36 24 36H42ZM24 32H44V16H24V32ZM32 27C30.34 27 29 25.66 29 24C29 22.34 30.34 21 32 21C33.66 21 35 22.34 35 24C35 25.66 33.66 27 32 27Z" />
    </svg>
  );
};

export { AccountBalanceWalletIcon };
