import cn from 'classnames';

const HowToVoteIcon = (props: SVGProps): JSX.Element => {
  const { title = 'HowToVote', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M36 25.9998H34.64L30.64 29.9998H34.46L38 33.9998H10L13.56 29.9998H17.66L13.66 25.9998H12L6 31.9998V39.9998C6 42.1998 7.78 43.9998 9.98 43.9998H38C40.2 43.9998 42 42.2198 42 39.9998V31.9998L36 25.9998ZM34 15.8998L24.1 25.7998L17.02 18.7198L26.92 8.81984L34 15.8998ZM25.52 4.57984L12.78 17.3198C12 18.0998 12 19.3598 12.78 20.1398L22.68 30.0398C23.46 30.8198 24.72 30.8198 25.5 30.0398L38.22 17.3198C39 16.5398 39 15.2798 38.22 14.4998L28.32 4.59984C27.56 3.79984 26.3 3.79984 25.52 4.57984Z" />
    </svg>
  );
};

export { HowToVoteIcon };
