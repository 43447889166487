import {
  getPoliciesFromOperation,
  getPolicyInfoFromEnforcedPolicy,
  isFragmentUpdatedBindingNotInternalFilterFn,
  legacyPoliciesFilterFn,
  vaultFragmentHasUpdatedBindingFilterFn,
} from './utils';

import type { Maybe } from '../generated/graphql';
import { PolicySourceOfTruth } from '../generated/graphql';

import type {
  OperationWithPolicy,
  OperationWithPolicyUpdates,
  OperationWithUpdatedOrgAndVaultPolicies,
  OperationWithVaultPolicyContainerUpdates,
  VaultOperationWithUpdatedPolicies,
} from '../types';

import { Policy } from './Policy';
import { PolicyContainer } from './PolicyContainer';
import { PolicyContainerUpdate } from './PolicyContainerUpdate';
import { VaultPolicyContainerUpdate } from './VaultPolicyContainerUpdate';

export class OperationPolicies {
  readonly sourceOfTruth: PolicySourceOfTruth;
  private readonly op: OperationWithPolicyUpdates;
  readonly policies: Policy[];
  readonly policyContainer: PolicyContainer;
  readonly updatedVaultPolicies: VaultPolicyContainerUpdate | null;
  readonly updatedOrgPolicies: PolicyContainerUpdate | null;

  constructor(readonly operation: OperationWithPolicy) {
    this.op = operation as OperationWithPolicyUpdates;
    this.sourceOfTruth =
      this.op.operationInfo.organization?.policyInfo?.sourceOfTruth ||
      PolicySourceOfTruth.POLICY_SOT_CONTAINER;
    this.policyContainer = this.getPolicyContainer();
    this.policies = this.getPolicies() ?? [];
    this.updatedVaultPolicies = this.getUpdatedVaultPolicies();
    this.updatedOrgPolicies = this.getUpdatedOrgPolicies();
  }

  private getPolicies() {
    if (this.op.__typename !== 'CreateVaultOperation') {
      return;
    }
    const op = this.op;

    if (!op.policies) {
      return;
    }

    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER: {
        return op.vaultPolicyContainerUpdate?.fragmentBindingUpdates
          ?.filter(vaultFragmentHasUpdatedBindingFilterFn)
          .filter(isFragmentUpdatedBindingNotInternalFilterFn)
          .map(
            (fragment) =>
              new Policy(
                PolicySourceOfTruth.POLICY_SOT_CONTAINER,
                fragment.updatedBinding,
              ),
          );
      }
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
        return op.policies
          .filter(legacyPoliciesFilterFn)
          .map(
            (policy) =>
              new Policy(PolicySourceOfTruth.POLICY_SOT_LEGACY, policy),
          );
    }
  }

  private getPolicyContainer() {
    return new PolicyContainer(
      this.sourceOfTruth,
      getPoliciesFromOperation(this.op),
      getPolicyInfoFromEnforcedPolicy(this.op.enforcedPolicy),
    );
  }

  private getUpdatedVaultPolicies(): Maybe<VaultPolicyContainerUpdate> {
    const opsWithVaultUpdates = [
      'AddUserAndPoliciesOperation',
      'CreateVaultOperation',
      'DisableVaultOperation',
      'RemoveUserAndPoliciesOperation',
      'ReplaceUserOperation',
      'UpdateVaultPoliciesOperation',
    ];
    if (!opsWithVaultUpdates.includes(this.op.__typename)) {
      return null;
    }

    const op = this.op;

    return new VaultPolicyContainerUpdate(
      this.sourceOfTruth,
      (op as OperationWithUpdatedOrgAndVaultPolicies).updatedVaultPolicies ||
        (op as VaultOperationWithUpdatedPolicies).updatedPolicies ||
        [],
      (
        op as OperationWithVaultPolicyContainerUpdates
      ).vaultPolicyContainerUpdate,
    );
  }

  private getUpdatedOrgPolicies(): Maybe<PolicyContainerUpdate> {
    const opsWithOrgUpdates = [
      'AddUserAndPoliciesOperation',
      'RemoveUserAndPoliciesOperation',
      'ReplaceUserOperation',
      'UpdateOrgPoliciesOperation',
    ];
    if (!opsWithOrgUpdates.includes(this.op.__typename)) {
      return null;
    }

    const updatedOrgPolicies =
      this.op.__typename === 'UpdateOrgPoliciesOperation'
        ? this.op.updatedPolicies
        : (this.op as OperationWithUpdatedOrgAndVaultPolicies)
            .updatedOrgPolicies;

    const op = this.op as OperationWithUpdatedOrgAndVaultPolicies;

    return new PolicyContainerUpdate(
      this.sourceOfTruth,
      updatedOrgPolicies,
      op.policyContainerUpdate,
    );
  }
}
