import * as React from 'react';

import { OperationImplementations } from 'generated/graphql';
import type { OperationDrawer } from 'generated/graphql';

import Comment from '../../widgets/Comment';
import Endorsers from '../../widgets/Endorsers';
import InitiatedBy from '../../widgets/InitiatedBy';
import ProgressTracker from '../../widgets/ProgressTracker';

const { TransferOperation, OffChainOperation } = OperationImplementations;

type OperationWithEndorsements = Extract<
  OperationDrawer.operation,
  | { __typename: 'AddUserAndPoliciesOperation' }
  | { __typename: 'AddTrustedDestinationOperation' }
  | { __typename: 'ChangeRoleOperation' }
  | { __typename: 'CreateVaultOperation' }
  | { __typename: 'DelegateOperation' }
  | { __typename: 'DisableVaultOperation' }
  | { __typename: 'OffChainOperation' }
  | { __typename: 'RemoveTrustedDestinationOperation' }
  | { __typename: 'RemoveUserAndPoliciesOperation' }
  | { __typename: 'UpdateVaultPoliciesOperation' }
  | { __typename: 'UpdateOrgPoliciesOperation' }
  | { __typename: 'VoteOperation' }
  | { __typename: 'WithdrawOperation' }
  | { __typename: 'ReplaceUserOperation' }
  | { __typename: 'TransferOperation' }
  | { __typename: 'OffChainOperation' }
>;

type Props = {
  operation: OperationDrawer.operation;
};

const getInititiator = (operation: OperationDrawer.operation) => {
  if (operation.__typename === TransferOperation) {
    return { initiatorKey: operation.operationInfo.initiatorV2 };
  }
  return { initiator: operation.operationInfo.initiator };
};

const OperationTab = ({ operation }: Props) => {
  const { description, simpleOperationStateDiagram, __typename } = operation;

  const shouldDisplayDescription = React.useMemo(
    () => __typename !== OffChainOperation && description,
    [description, __typename],
  );

  return (
    <>
      <InitiatedBy {...getInititiator(operation)} />
      {shouldDisplayDescription && <Comment comment={description} />}
      <ProgressTracker stateDiagram={simpleOperationStateDiagram} />{' '}
      {(operation as OperationWithEndorsements).endorsements && (
        <Endorsers
          operation={operation}
          endorsements={(operation as OperationWithEndorsements).endorsements}
        />
      )}
    </>
  );
};

export default OperationTab;
