import cn from 'classnames';

const LooksTwoIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LooksTwo', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 6H10C7.79 6 6 7.79 6 10V38C6 40.21 7.79 42 10 42H38C40.21 42 42 40.21 42 38V10C42 7.79 40.21 6 38 6ZM30 22C30 24.21 28.21 26 26 26H22V30H30V34H18V26C18 23.79 19.79 22 22 22H26V18H18V14H26C28.21 14 30 15.79 30 18V22Z" />
    </svg>
  );
};

export { LooksTwoIcon };
