import { StandardizedStakingStepFragment } from 'generated/graphql';

import useStandardizedStakingContext from '../../StandardizedStakingContext/useStandardizedStakingContext';
import ValidatorAddressReadOnlyInput from '../../ValidatorAddressReadOnlyInput';
import GenericStakingStep from '../StandardizedStakingFormPage/StakingSteps/GenericStakingStep';

interface Props {
  unstakeFormSteps: StandardizedStakingStepFragment[];
}

const StandardizedUnstakeFormPage = ({ unstakeFormSteps }: Props) => {
  const { validatorAddress } = useStandardizedStakingContext();

  return (
    <div className="*:mt-6">
      <ValidatorAddressReadOnlyInput validatorAddress={validatorAddress} />
      {unstakeFormSteps.map((step, index) => {
        return <GenericStakingStep step={step} key={index} />;
      })}
    </div>
  );
};

export default StandardizedUnstakeFormPage;
