import cn from 'classnames';

const Filter3Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Filter3', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 2H14C11.79 2 10 3.79 10 6V34C10 36.21 11.79 38 14 38H42C44.21 38 46 36.21 46 34V6C46 3.79 44.21 2 42 2ZM42 34H14V6H42V34ZM6 10H2V42C2 44.21 3.79 46 6 46H38V42H6V10ZM34 26V23C34 21.34 32.66 20 31 20C32.66 20 34 18.66 34 17V14C34 11.79 32.21 10 30 10H22V14H30V18H26V22H30V26H22V30H30C32.21 30 34 28.21 34 26Z" />
    </svg>
  );
};

export { Filter3Icon };
