import cn from 'classnames';

const FilterTiltShiftIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FilterTiltShift', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M22 8.14001V4.10001C17.98 4.50001 14.32 6.10001 11.35 8.52001L14.2 11.37C16.42 9.66001 19.08 8.50001 22 8.14001ZM36.65 8.52001C33.69 6.10001 30.02 4.50001 26 4.10001V8.14001C28.92 8.51001 31.58 9.66001 33.8 11.38L36.65 8.52001ZM39.86 22H43.9C43.5 17.98 41.9 14.32 39.48 11.35L36.63 14.2C38.34 16.42 39.5 19.08 39.86 22ZM11.38 14.2L8.52998 11.35C6.09998 14.32 4.49998 17.98 4.09998 22H8.13998C8.50998 19.08 9.65998 16.42 11.38 14.2ZM8.13998 26H4.09998C4.49998 30.02 6.09998 33.68 8.51998 36.65L11.37 33.8C9.65998 31.58 8.50998 28.91 8.13998 26ZM30 24C30 20.69 27.31 18 24 18C20.69 18 18 20.69 18 24C18 27.31 20.69 30 24 30C27.31 30 30 27.31 30 24ZM36.62 33.79L39.47 36.64C41.9 33.68 43.5 30.02 43.9 26H39.86C39.5 28.91 38.34 31.58 36.62 33.79ZM26 39.86V43.9C30.02 43.5 33.68 41.9 36.65 39.48L33.8 36.63C31.58 38.34 28.92 39.49 26 39.86ZM11.35 39.48C14.32 41.9 17.98 43.5 22 43.9V39.86C19.08 39.49 16.42 38.34 14.2 36.62L11.35 39.48Z" />
    </svg>
  );
};

export { FilterTiltShiftIcon };
