import cn from 'classnames';

const BrandingWatermarkIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BrandingWatermark', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 6H6C3.8 6 2 7.8 2 10V38C2 40.2 3.8 42 6 42H42C44.2 42 46 40.2 46 38V10C46 7.8 44.2 6 42 6ZM42 38H24V26H42V38Z" />
    </svg>
  );
};

export { BrandingWatermarkIcon };
