import {
  StakingStepImplementations,
  StandardizedStakingStep,
  StandardizedStakingStepFragment,
} from 'generated/graphql';

// Assuming we will only have one AutoCompoundStep step in a stake flow
const getAutoCompoundStep = (
  stakingSteps: StandardizedStakingStepFragment[],
) => {
  const autoCompoundStep = stakingSteps.find(
    (step) => step.__typename === StakingStepImplementations.AutoCompoundStep,
  ) as StandardizedStakingStep.AutoCompoundStepInlineFragment | undefined;

  return autoCompoundStep;
};

export default getAutoCompoundStep;
