import cn from 'classnames';

const LocalDrinkIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalDrink', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6 4L10.03 40.47C10.26 42.46 11.95 44 14 44H34C36.05 44 37.74 42.46 37.97 40.47L42 4H6ZM24 38C20.69 38 18 35.31 18 32C18 28 24 21.2 24 21.2C24 21.2 30 28 30 32C30 35.31 27.31 38 24 38ZM36.65 16H11.35L10.47 8H37.54L36.65 16Z" />
    </svg>
  );
};

export { LocalDrinkIcon };
