import cn from 'classnames';

const LinearScaleIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LinearScale', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M39 19C36.95 19 35.19 20.23 34.42 22H28.58C27.81 20.23 26.05 19 24 19C21.95 19 20.19 20.23 19.42 22H13.58C12.81 20.23 11.05 19 9 19C6.23 19 4 21.23 4 24C4 26.77 6.23 29 9 29C11.05 29 12.81 27.77 13.58 26H19.42C20.19 27.77 21.95 29 24 29C26.05 29 27.81 27.77 28.58 26H34.42C35.19 27.77 36.95 29 39 29C41.77 29 44 26.77 44 24C44 21.23 41.77 19 39 19Z" />
    </svg>
  );
};

export { LinearScaleIcon };
