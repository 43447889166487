import { Avatar } from '@latitude/avatar';
import { Button } from '@latitude/button';
import { Content, Root, Trigger } from '@latitude/popover';
import { Text } from '@latitude/text';
import cn from 'classnames';
import { useState } from 'react';

import { useAppUser } from '@anchorage/frontoffice/contexts/AppUser';
import { useUserSessionManager } from '@anchorage/frontoffice/hooks/useUserSessionManager';

export const User = () => {
  const { currentUser } = useAppUser() || {};
  const { logoutUser } = useUserSessionManager();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen} modal>
      <Trigger asChild>
        <Avatar
          size="xsmall"
          type="letter"
          text={currentUser?.secureName}
          className="cursor-pointer"
        />
      </Trigger>
      <Content
        align="end"
        className={cn([
          'flex',
          'flex-col',
          'gap-spacing-comp-action-list-medium-vertical',
        ])}
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <div>
          <Text>{currentUser?.secureName}</Text>
          <Text
            variant="captionRegular"
            className={cn(['text-color-ref-text-muted'])}
          >
            {currentUser?.email}
          </Text>
        </div>
        <Button
          variant="neutral"
          type="filled"
          size="small"
          onClick={logoutUser}
        >
          Log out
        </Button>
      </Content>
    </Root>
  );
};
