import { Text, Tooltip } from '@anchorage/common/dist/components';
import { InfoOutlineIcon } from '@anchorage/common/dist/components/Icons';

import css from './styles.module.scss';

const PriceTimeTooltip = () => {
  const toolTipText = (
    <Text size="small" type="body">
      Price at time of transaction
    </Text>
  );

  return (
    <Tooltip
      background="light"
      title={toolTipText}
      className={css.tooltipWrapper}
    >
      <InfoOutlineIcon className={css.tooltipIcon} />
    </Tooltip>
  );
};

export default PriceTimeTooltip;
