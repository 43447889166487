import cn from 'classnames';

const Brightness4Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Brightness4', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 17.37V8H30.63L24 1.37L17.37 8H8V17.37L1.37 24L8 30.63V40H17.37L24 46.63L30.63 40H40V30.63L46.63 24L40 17.37ZM24 36C22.21 36 20.52 35.6 19 34.9C23.13 33 26 28.84 26 24C26 19.16 23.13 15 19 13.1C20.52 12.4 22.21 12 24 12C30.63 12 36 17.37 36 24C36 30.63 30.63 36 24 36Z" />
    </svg>
  );
};

export { Brightness4Icon };
