import cn from 'classnames';

const BluetoothDisabledIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BluetoothDisabled', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M26 11.83L29.76 15.59L26.56 18.79L29.39 21.62L35.42 15.59L24 4.17004H22V14.23L26 18.23V11.83ZM10.83 8.17004L8 11L21.17 24.17L10 35.34L12.83 38.17L22 29V44.17H24L32.59 35.58L37.18 40.17L40 37.34L10.83 8.17004ZM26 36.51V29L29.76 32.76L26 36.51Z" />
    </svg>
  );
};

export { BluetoothDisabledIcon };
