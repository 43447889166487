import cn from 'classnames';

const AutorenewIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Autorenew', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 12V18L32 10L24 2V8C15.16 8 8 15.16 8 24C8 27.14 8.92 30.05 10.48 32.52L13.4 29.6C12.51 27.93 12 26.03 12 24C12 17.37 17.37 12 24 12ZM37.52 15.48L34.6 18.4C35.49 20.07 36 21.97 36 24C36 30.63 30.63 36 24 36V30L16 38L24 46V40C32.84 40 40 32.84 40 24C40 20.86 39.08 17.95 37.52 15.48Z" />
    </svg>
  );
};

export { AutorenewIcon };
