import cn from 'classnames';

const EditIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Edit', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6 34.5V42H13.5L35.63 19.87L28.13 12.37L6 34.5ZM41.41 14.09C42.19 13.31 42.19 12.04 41.41 11.26L36.74 6.59C35.96 5.81 34.69 5.81 33.91 6.59L30.25 10.25L37.75 17.75L41.41 14.09Z" />
    </svg>
  );
};

export { EditIcon };
