import cn from 'classnames';

const FaceIDIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FaceID-48px', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M16 10V6H10C7.8 6 6 7.8 6 10V16H10V10H16Z" />
      <path d="M32 10H38V16H42V10C42 7.8 40.22 6 38 6H32V10Z" />
      <path d="M32 38V42H38C40.22 42 42 40.2 42 38V32H38V38H32Z" />
      <path d="M16 38V42H10C7.8 42 6 40.2 6 38V32H10V38H16Z" />
      <path d="M18 30H30C30 32.2091 28.2091 34 26 34H22C19.7909 34 18 32.2091 18 30Z" />
      <path d="M22 16V26C24.2091 26 26 24.2091 26 22V16H22Z" />
      <path d="M15.5 21C16.8807 21 18 19.8807 18 18.5C18 17.1193 16.8807 16 15.5 16C14.1193 16 13 17.1193 13 18.5C13 19.8807 14.1193 21 15.5 21Z" />
      <path d="M35 18.5C35 19.8807 33.8807 21 32.5 21C31.1193 21 30 19.8807 30 18.5C30 17.1193 31.1193 16 32.5 16C33.8807 16 35 17.1193 35 18.5Z" />
    </svg>
  );
};

export { FaceIDIcon };
