import { Input } from '@latitude/input';
import cn from 'classnames';
import { Command as CommandPrimitive } from 'cmdk';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

/**
 * A custom input component for commands.
 *
 * @component CommandInput
 * @param {string} className - The CSS class name for the component.
 * @param {string} inputSize - The size of the input.
 * @param {React.Ref} ref - The ref object for the component.
 * @param {object} props - Additional props for the component.
 * @returns {JSX.Element} - The rendered CommandInput component.
 */
export const CommandInput = forwardRef<
  ElementRef<typeof CommandPrimitive.Input>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Input> &
    ComponentPropsWithoutRef<typeof Input>
>(({ className, inputSize, ...props }, ref) => (
  <div
    className={cn(
      'mb-spacing-comp-action-list-medium-vertical sticky top-0',
      className,
    )}
  >
    <CommandPrimitive.Input ref={ref} {...props} asChild>
      <Input data-testid="command-input" inputSize={inputSize} search />
    </CommandPrimitive.Input>
  </div>
));

CommandInput.displayName = CommandPrimitive.Input.displayName;
