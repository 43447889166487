import React, { useEffect } from 'react';
// Hooks
import { useFormContext, useWatch } from 'react-hook-form';

// Components
import { CheckboxInput } from '../../../../components/Checkbox/elements';
import { CheckboxInputStatus } from '../../../../components/Checkbox/types';

import { useDeepCompare } from '../../../../hooks';

// Types
import type { PendingAttributionByOperationProps } from './_types';

import PendingAttributionsTable from '../PendingAttributionsTable';

const FORM_KEY_NAME = 'byOperation';

const PendingAttributionsByOperation = (
  {
    operations,
    onClickOperationId,
    isDisabled = false,
  }: PendingAttributionByOperationProps,
) => {
  const [attributionCheckboxStatus, setAttributionCheckboxStatus] =
    React.useState<CheckboxInputStatus>(CheckboxInputStatus.DEFAULT);

  const { setValue, reset, control } = useFormContext<{
    byOperation: Record<
      string,
      {
        checkbox: boolean | undefined;
        addresses: string[];
      }
    >;
  }>();
  const { byOperation } = useWatch({
    control,
  });

  useEffect(() => {
    const checkboxes = Object.values(byOperation ?? {});

    if (checkboxes.every((t) => t && t.checkbox)) {
      setAttributionCheckboxStatus(CheckboxInputStatus.CHECKED);
      return;
    }

    if (checkboxes.some((t) => t && t.checkbox)) {
      setAttributionCheckboxStatus(CheckboxInputStatus.INDETERMINATE);
      return;
    }

    setAttributionCheckboxStatus(CheckboxInputStatus.DEFAULT);
  }, [byOperation]);

  const tableOperations = React.useMemo(
    () =>
      operations.map((operation) => {
        const fieldName =
          `${FORM_KEY_NAME}.${operation.operationId}.checkbox` as `${typeof FORM_KEY_NAME}.${string}.checkbox`;

        return {
          ...operation,
          checkbox: {
            name: fieldName,
          },
        };
      }),
    useDeepCompare([operations]),
  );

  const onChangeAddressCheckbox = React.useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setAttributionCheckboxStatus(
        checked ? CheckboxInputStatus.CHECKED : CheckboxInputStatus.DEFAULT,
      );

      // set the children
      tableOperations.forEach(({ checkbox }) => {
        setValue(checkbox.name, checked);
      });
    },
    useDeepCompare([tableOperations, setAttributionCheckboxStatus]),
  );

  React.useEffect(() => {
    const defaultValues = operations.reduce(
      (acc, operation) => ({
        ...acc,
        [operation.operationId]: {
          checkbox: undefined,
          addresses: operation.addresses,
        },
      }),
      {},
    );
    reset({ byOperation: defaultValues });
    setAttributionCheckboxStatus(CheckboxInputStatus.DEFAULT);
  }, useDeepCompare(operations));

  return (
    <PendingAttributionsTable
      headerCheckbox={
        <CheckboxInput
          indeterminate={
            attributionCheckboxStatus === CheckboxInputStatus.INDETERMINATE
          }
          checked={attributionCheckboxStatus === CheckboxInputStatus.CHECKED}
          onChange={onChangeAddressCheckbox}
          data-testid="check-deposit-attribution"
          disabled={isDisabled}
        />
      }
      onClickOperationId={onClickOperationId}
      data={tableOperations}
      isDisabled={isDisabled}
    />
  );
};

export default PendingAttributionsByOperation;
