import classnames from 'classnames';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import css from '../styles.module.scss';

import { MenuItemProps } from '../types';

import { getDeployPRSubdirectory, isItemSelected } from '../helpers';

const MenuItem = ({
  testId = '',
  Icon,
  label,
  route,
  className = '',
  extra,
  onClick,
  appPrefix,
}: MenuItemProps) => {
  const currentPath = usePathname();

  const isSelected = route
    ? isItemSelected({
        currentPath,
        route,
        appPrefix,
      })
    : false;

  const hasIcon = !!Icon;

  const classes = classnames({
    [css.menuItem!]: true,
    [css.selected!]: isSelected,
    [css.noIcon!]: !hasIcon,
    [className]: !!className,
  });

  const iconTestId = `menu-item-icon-${testId}`;
  const labelTestId = `menu-item-label-${testId}`;

  if (!route) {
    return (
      <div className={classes} data-testid={testId}>
        {Icon && (
          <Icon className={css.icon} data-testid={iconTestId} title={label} />
        )}
        <div className={css.label} data-testid={labelTestId}>
          {label}
        </div>
        {extra}
      </div>
    );
  }

  const isMicroFrontendApp = appPrefix && route.startsWith(appPrefix);
  const deployPrSubdir = getDeployPRSubdirectory();

  const to = isMicroFrontendApp
    ? {
        // Pass along the current query parameters
        pathname: `${route}`.replace(appPrefix, ''),
      }
    : `${window.location.origin}${deployPrSubdir}${route}`;

  const linkElement = (
    <Link
      className={classes}
      data-testid={testId}
      href={to}
      data-is-selected={isSelected}
      {...(onClick && { onClick })}
    >
      {Icon && (
        <Icon className={css.icon} data-testid={iconTestId} title={label} />
      )}
      <div className={css.label} data-testid={labelTestId}>
        {label}
      </div>
      {extra}
    </Link>
  );

  return linkElement;
};

export default MenuItem;
