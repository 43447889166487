import { Card } from '@latitude/card';
import Text from '@latitude/text';

import { WalletStakingDetails } from 'generated/graphql';

interface Props {
  amount: WalletStakingDetails.totalClaimableRewards | undefined | null;
}

const UnclaimedRewardsCard = ({ amount }: Props) => {
  const displayValue = amount?.displayValue ?? '0';

  return (
    <Card className="py-4">
      <div className="flex justify-between">
        <Text>Available rewards to claim</Text>
        <Text>
          {displayValue} {amount?.assetTypeInfo.abbreviation}
        </Text>
      </div>
    </Card>
  );
};

export default UnclaimedRewardsCard;
