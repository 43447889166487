import cn from 'classnames';

const BeenhereIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Beenhere', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 2H10C7.79 2 6.02 3.79 6.02 6L6 31.87C6 33.25 6.7 34.47 7.76 35.19L23.99 46L40.22 35.19C41.28 34.47 41.98 33.25 41.98 31.87L42 6C42 3.79 40.21 2 38 2ZM20 32L10 22L12.83 19.17L20 26.34L35.17 11.17L38 14L20 32Z" />
    </svg>
  );
};

export { BeenhereIcon };
