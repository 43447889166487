import cn from 'classnames';

const BaselineWorkOutlineIcon = (props: SVGProps): JSX.Element => {
  const {
    title = 'Baseline-workOutline-24px',
    className,
    ...restProps
  } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 12V8H20V12H28ZM8 16V38H40V16H8ZM40 12C42.22 12 44 13.78 44 16V38C44 40.22 42.22 42 40 42H8C5.78 42 4 40.22 4 38L4.02 16C4.02 13.78 5.78 12 8 12H16V8C16 5.78 17.78 4 20 4H28C30.22 4 32 5.78 32 8V12H40Z"
      />
    </svg>
  );
};

export { BaselineWorkOutlineIcon };
