import cn from 'classnames';

const ImportExportIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ImportExport', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18 6L10 13.98H16V28H20V13.98H26L18 6ZM32 34.02V20H28V34.02H22L30 42L38 34.02H32Z" />
    </svg>
  );
};

export { ImportExportIcon };
