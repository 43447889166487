import { Input } from '@latitude/input';
import Text from '@latitude/text';

import { AssetIcon } from '@anchorage/common/dist/components';

import { type AmountListStep } from 'generated/graphql';

interface Props {
  step: AmountListStep;
}

/*
AmountListStep allows the user to see multiple amounts on one screen without being able to edit them.
*/

const StakingAmountListStep = (props: Props) => {
  const { step } = props;
  return (
    <div data-testid="staking-amount-list-step">
      <Text variant="headingTiny" className="text-color-ref-text-strong">
        Claim amount
      </Text>
      <Text variant="labelMedium" className="mb-4">
        The maximum amount will be claimed per asset
      </Text>
      {step.amounts.map((amount, index) => {
        return (
          <div key={index} className="mb-4">
            <Text variant="labelMedium" className="text-color-ref-text-strong">
              {`Amount for ${amount.assetTypeID} | ${amount.assetTypeInfo.name}`}
            </Text>
            <Input
              inputSize="small"
              classNames={{
                leadingElement: 'flex items-center justify-center',
              }}
              leadingElement={
                <AssetIcon
                  {...amount.assetTypeInfo}
                  style={{ width: '16px', height: '16px' }}
                />
              }
              value={amount.displayValue}
              readOnly
            />
            <Text variant="labelMedium">{`~$${amount.valueInUSD}`}</Text>
          </div>
        );
      })}
    </div>
  );
};
export default StakingAmountListStep;
