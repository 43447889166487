import { ComponentProps, useCallback } from 'react';

import { EventName, useAnalytics } from '@anchorage/analytics';
import { Accordion, Text } from '@anchorage/common/dist/components';
import {
  AccordionHeader,
  AccordionPanel,
} from '@anchorage/common/dist/components/Accordion';
import SimpleBanner from '@anchorage/common/dist/components/Banner/SimpleBanner';
import { useDeepCompare } from '@anchorage/common/dist/hooks';

import css from './styles.module.scss';

import PolicyQuorum from './PolicyQuorum';

const QuorumList = ({
  title,
  baseQuorum,
  subQuorums,
  expanded,
  isSameAsDefault = false,
  analytics,
}: {
  title: string;
  baseQuorum: ComponentProps<typeof PolicyQuorum>['policyQuorum'];
  subQuorums: ComponentProps<typeof PolicyQuorum>['policyQuorum'][];
  expanded: boolean;
  isSameAsDefault?: boolean;
  analytics?: {
    name: EventName;
    [x: string]: any;
  };
}) => {
  const { track } = useAnalytics();
  const accordionHeaderClickHandler = useCallback(
    ({ isExpanded, itemId }: { isExpanded: boolean; itemId: string }) => {
      if (!analytics) return;
      const { name, ...data } = analytics;
      track({
        name: name,
        data: {
          ...data,
          isExpanded,
          itemId,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([analytics, track]),
  );

  return (
    <div className={css.policyWrapper}>
      <Accordion expanded={expanded}>
        <AccordionHeader onClick={accordionHeaderClickHandler}>
          <Text>{title}</Text>
        </AccordionHeader>
        <AccordionPanel>
          <div className={css.policyContentWrapper}>
            {isSameAsDefault && (
              <SimpleBanner
                title="This policy will follow the Default policy"
                type="info"
              />
            )}
            {baseQuorum && <PolicyQuorum policyQuorum={baseQuorum} />}
            {subQuorums.length > 0 && (
              <div className={css.subQuorumList}>
                <Text className={css.subQuorumsTitle}>Sub-quorums</Text>
                <div className={css.subQuorumsListContent}>
                  {subQuorums.map(({ quorumName, ...policyQuorum }) => {
                    return (
                      <Accordion key={quorumName}>
                        <AccordionHeader onClick={accordionHeaderClickHandler}>
                          <Text>{`${quorumName} (${
                            policyQuorum.total.updated ??
                            policyQuorum.total.original
                          })`}</Text>
                        </AccordionHeader>
                        <AccordionPanel>
                          <PolicyQuorum policyQuorum={policyQuorum} />
                        </AccordionPanel>
                      </Accordion>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </AccordionPanel>
      </Accordion>
    </div>
  );
};

export default QuorumList;
