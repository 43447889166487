import { ServerError, ServerParseError } from '@apollo/client';
import { includes } from 'lodash';

import { reportError } from '@anchorage/common/dist/utils/errors';
import {
  EMAIL_VERIFICATION_PATH,
  LOGIN_PATH,
} from '@anchorage/frontoffice/utils/routes';

import replaceUrl from 'utils/navigation';

export const handleNetworkError = ({
  error,
}: {
  error: Error | ServerParseError | ServerError;
}) => {
  // Do not report 'Failed to fetch' errors, since this indicates
  // local network connectivity issues (ie. the user's internet is down or the
  // request is blocked, not that the server is down).
  //
  // The client dashboard is not HA yet, so there are some server disconnect
  // errors we can expect that we won't report. The uptime reporting will handle
  // this failure
  if ('statusCode' in error) {
    const status = error.statusCode;

    if (status === 401) {
      // If at any point the user is unauthenticated, send them to the login page.
      // NOTE: Accessing the router's location without the context of the
      // React wrapper is possible here through window.location because
      // we use BrowserRouter

      const pathname = window.location.pathname;
      if (pathname === EMAIL_VERIFICATION_PATH) {
        // Email verification does not require auth
        // This page is only visible for Aurora orgs
        // TODO Luis AUR-395: check also the hostname once we have Aurora app running in separate domain
        return;
      }

      if (!pathname.startsWith(LOGIN_PATH)) {
        // Send the user to the login page with a query parameter that will track
        // where they were so we can redirect them back to the page they
        // attempted to view after successful login
        // example of href: https://anchoragelogin.com/
        const loginPath = `${LOGIN_PATH}?ref=${pathname}`;
        replaceUrl(loginPath);
      }

      return;
    }

    const serverDisconnectStatuses = [499, 502, 503, 504];
    const isServerDisconnectError = includes(serverDisconnectStatuses, status);
    if (isServerDisconnectError) {
      return;
    }
  }

  const isFailedToFetch = error && error.toString().includes('Failed to fetch');
  if (isFailedToFetch) {
    return;
  }

  // Report all other errors
  reportError(error);
};
