import cn from 'classnames';

const ChatBubbleIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ChatBubble', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 4H8C5.79 4 4 5.79 4 8V44L12 36H40C42.21 36 44 34.21 44 32V8C44 5.79 42.21 4 40 4Z" />
    </svg>
  );
};

export { ChatBubbleIcon };
