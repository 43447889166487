import cn from 'classnames';

const FolderSpecialIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FolderSpecial', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 12H24L20 8H8C5.8 8 4 9.8 4 12V36C4 38.2 5.8 40 8 40H40C42.2 40 44 38.2 44 36V16C44 13.8 42.2 12 40 12ZM35.87 34L30 30.56L24.13 34L25.69 27.34L20.51 22.86L27.34 22.27L30 16L32.67 22.28L39.5 22.87L34.32 27.35L35.87 34Z" />
    </svg>
  );
};

export { FolderSpecialIcon };
