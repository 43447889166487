import cn from 'classnames';

const BrightnessAutoIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BrightnessAuto', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M21.7001 25.3H26.3001L24.0001 18L21.7001 25.3ZM40.0001 17.37V8H30.6301L24.0001 1.37L17.3701 8H8.00012V17.37L1.37012 24L8.00012 30.63V40H17.3701L24.0001 46.63L30.6301 40H40.0001V30.63L46.6301 24L40.0001 17.37ZM28.6001 32L27.2001 28H20.8001L19.4001 32H15.6001L22.0001 14H26.0001L32.4001 32H28.6001Z" />
    </svg>
  );
};

export { BrightnessAutoIcon };
