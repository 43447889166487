import cn from 'classnames';

const HdrWeakIcon = (props: SVGProps): JSX.Element => {
  const { title = 'HdrWeak', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M10 16C5.58 16 2 19.58 2 24C2 28.42 5.58 32 10 32C14.42 32 18 28.42 18 24C18 19.58 14.42 16 10 16ZM34 12C27.37 12 22 17.37 22 24C22 30.63 27.37 36 34 36C40.63 36 46 30.63 46 24C46 17.37 40.63 12 34 12ZM34 32C29.58 32 26 28.42 26 24C26 19.58 29.58 16 34 16C38.42 16 42 19.58 42 24C42 28.42 38.42 32 34 32Z" />
    </svg>
  );
};

export { HdrWeakIcon };
