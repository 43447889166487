import { ComponentProps } from 'react';

import { Accordion } from '@anchorage/common/dist/components';
import {
  AccordionHeader,
  AccordionPanel,
} from '@anchorage/common/dist/components/Accordion';

import css from './styles.module.scss';

import UpdatedUsers from '../../../../shared/UpdatedUsers';

const UpdatedIAMRoleChangesDiffAccordion = ({
  title,
  added,
  removed,
}: {
  title: string;
  added: ComponentProps<typeof UpdatedUsers>['added'];
  removed: ComponentProps<typeof UpdatedUsers>['removed'];
}) => {
  return (
    <Accordion>
      <AccordionHeader className={css.updatedIAMRoleAccordionHeader}>
        {title}
      </AccordionHeader>
      <AccordionPanel>
        <UpdatedUsers added={added} removed={removed} />
      </AccordionPanel>
    </Accordion>
  );
};

export default UpdatedIAMRoleChangesDiffAccordion;
