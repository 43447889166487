import cn from 'classnames';

const DoNotDisturbAltIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DoNotDisturbAlt', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 4C13 4 4 13 4 24C4 35 13 44 24 44C35 44 44 35 44 24C44 13 35 4 24 4ZM8 24C8 15.2 15.2 8 24 8C27.7 8 31.1 9.3 33.8 11.4L11.4 33.8C9.3 31.1 8 27.7 8 24ZM24 40C20.3 40 16.9 38.7 14.2 36.6L36.6 14.2C38.7 16.9 40 20.3 40 24C40 32.8 32.8 40 24 40Z" />
    </svg>
  );
};

export { DoNotDisturbAltIcon };
