import { Button } from '@latitude/button';
import Text from '@latitude/text';
import { Tooltip } from '@latitude/tooltip';
import { MouseEvent, useCallback, useEffect } from 'react';

import { useFormContext } from '@anchorage/common/dist/components/Form/_reactHookForm';
import { FormInput } from '@anchorage/common/dist/components/Form/components';
import { InfoOutlineIcon } from '@anchorage/common/dist/components/Icons';
import {
  UIDeveloperError,
  reportError,
} from '@anchorage/common/dist/utils/errors';

import { type StandardizedStakeFormValues } from 'components/Vault/shared/Staking/types';

import {
  type AmountInputStep,
  StakingStepInputType,
  StakingStepNumberType,
} from 'generated/graphql';

import useStandardizedStakingContext from '../../../StandardizedStakingContext/useStandardizedStakingContext';
import { StandardizedStakingMode } from '../../../constants';

interface Props {
  step: AmountInputStep;
}

const getInputLabel = (stakingMode: StandardizedStakingMode | null) => {
  switch (stakingMode) {
    case StandardizedStakingMode.STAKE:
      return 'Stake amount';
    case StandardizedStakingMode.UNSTAKE:
      return 'How much do you want to unstake?';
    case StandardizedStakingMode.CLAIM_REWARDS:
      return 'Claim amount';
    default:
      return 'Stake amount';
  }
};

const getInfoIconLabel = (
  stakingMode: StandardizedStakingMode | null,
  maxAmount: string,
  abbreviation: string,
) => {
  const defaultStakeLabel = `Max available: ${maxAmount} ${abbreviation}`;
  switch (stakingMode) {
    case StandardizedStakingMode.STAKE:
      return defaultStakeLabel;
    case StandardizedStakingMode.UNSTAKE:
      return `Staked amount: ${maxAmount} ${abbreviation}`;
    case StandardizedStakingMode.CLAIM_REWARDS:
      return 'The max amount available will be claimed automatically';
    default:
      return defaultStakeLabel;
  }
};

const StakingAmountInputStep = ({ step }: Props) => {
  const {
    control,
    setValue,
    trigger,
    resetField,
    formState: { dirtyFields },
  } = useFormContext<StandardizedStakeFormValues>();

  const { standardizedStakingMode, assetTypeInfo } =
    useStandardizedStakingContext();

  const inputLabel = getInputLabel(standardizedStakingMode);

  const maxAmount = step.maxAmount;

  const onMaxButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      if (!maxAmount) {
        return;
      }

      setValue('amount', maxAmount);
      trigger('amount');
    },
    [setValue, trigger, maxAmount],
  );

  useEffect(() => {
    // if the user has manually entered an amount, it will be dirty
    const isAmountDirty = dirtyFields.amount;

    // Since the form default values are dynamic
    // We might only know the maxAmount at runtime when we open the modal
    // So, we need to reset the field to the new maxAmount
    // if the user hasn't entered an amount yet
    if (!isAmountDirty) {
      resetField('amount', { defaultValue: maxAmount || '0' });
    }

    if (step.inputType !== StakingStepInputType.ASSET) {
      reportError(
        new UIDeveloperError(
          `Unsupported staking step input type: ${step.inputType}`,
        ),
      );
    }
    if (step.numberType !== StakingStepNumberType.ABSOLUTE) {
      reportError(
        new UIDeveloperError(
          `Unsupported staking step number type: ${step.numberType}`,
        ),
      );
    }
  }, [step, resetField, maxAmount, dirtyFields]);

  // iOS only supports ASSET input type
  if (step.inputType !== StakingStepInputType.ASSET) {
    return null;
  }

  // iOS only supports ABSOLUTE number type
  if (step.numberType !== StakingStepNumberType.ABSOLUTE) {
    return null;
  }

  return (
    <div>
      <FormInput
        control={control}
        name="amount"
        label={inputLabel}
        placeholder="Enter amount"
        type="number"
        data-testid="staking-amount-input-step"
        disabled={!step.editable}
      />
      {maxAmount && (
        <div className="flex items-center justify-between pt-2">
          <Tooltip text="Represents the available balance minus a small reserve for transaction fees">
            <span className="flex items-center gap-2">
              {standardizedStakingMode === StandardizedStakingMode.STAKE && (
                <InfoOutlineIcon className="fill-color-ref-ui-icon-muted size-4" />
              )}
              <Text variant="metricSmall">
                {getInfoIconLabel(
                  standardizedStakingMode,
                  maxAmount,
                  assetTypeInfo.abbreviation,
                )}
              </Text>
            </span>
          </Tooltip>
          {step.editable && (
            <Button type="ghost" size="tiny" onClick={onMaxButtonClick}>
              Set max
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
export default StakingAmountInputStep;
