import type { DocumentNode } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';

import { Drawer, Text } from '@anchorage/common/dist/components';
import { useSnackbar } from '@anchorage/common/dist/components/Snackbar';
import { isAlreadyTrustedSourceError } from '@anchorage/common/dist/utils/errors';
import { PendingAttributionsDrawerContent } from '@anchorage/common/dist/widgets/PendingAttributions';
import type { AttributionPair } from '@anchorage/common/dist/widgets/PendingAttributions/PendingAttributionsCard/_types';
import { useFeatureFlagsContext } from '@anchorage/feature-flags';
import { FEATURE_FLAGS } from '@anchorage/frontoffice/constants/split';

import useInAppSurvey from 'hooks/useInAppSurvey/useInAppSurvey';
import useStaticDataContext from 'hooks/useStaticDataContext';
import useUnattributedDeposits from 'hooks/useUnattributedDeposits';

import { usePerformManualDepositAttributionsMutation } from 'generated/graphql';

import DepositAttributionsSurveyStep from './DepositAttributionSurveyStep';

const DepositAttributionsDrawer = ({
  addresses,
  onClose,
  isDrawerOpen,
  attributionPairs,
  refetchQuery,
}: {
  addresses: string[];
  onClose: () => void;
  isDrawerOpen: boolean;
  attributionPairs: AttributionPair[];
  refetchQuery: DocumentNode;
}) => {
  const { useIsFeatureActive } = useFeatureFlagsContext();
  const [isInAppSurveyActive] = useIsFeatureActive(
    FEATURE_FLAGS.CD_IN_APP_SURVEY_DEPOSIT_ATTRIBUTIONS,
  );
  const { addSnackbar } = useSnackbar();
  const { updateTotals: updateTotal } = useUnattributedDeposits(null);
  const { countries } = useStaticDataContext();
  const [hasTrustedSourceError, setHasTrustedSourceError] = useState(false);
  const { openSurvey } = useInAppSurvey();

  const availableCountries = useMemo(() => {
    return (
      countries?.map(({ name, code }) => ({
        label: name,
        value: code,
      })) || []
    );
  }, [countries]);

  const onErrorPerformAttribution = useCallback(
    (error: any) => {
      if (isAlreadyTrustedSourceError(error?.message || '')) {
        setHasTrustedSourceError(true);
      } else {
        setHasTrustedSourceError(false);
        addSnackbar({
          type: 'error',
          text:
            error?.message || 'Sorry, something went wrong. Please try again.',
        });
      }
    },
    [addSnackbar],
  );

  const [attributeDeposit, { loading: isMutationLoading }] =
    usePerformManualDepositAttributionsMutation({
      refetchQueries: [refetchQuery],
      awaitRefetchQueries: true,
      onCompleted: ({ performManualDepositAttributions }) => {
        if (performManualDepositAttributions) {
          addSnackbar({
            type: 'success',
            text: 'Deposit attribution complete',
          });
          onClose();
          updateTotal();
          isInAppSurveyActive &&
            openSurvey({
              defaultFormValues: {
                attributeInApp: false,
              },
              steps: [
                {
                  formFieldNames: ['attributeInApp'],
                  element: <DepositAttributionsSurveyStep />,
                },
              ],
              surveyName: 'depositAttributions',
              eventsPrefix: 'deposit_attributions:survey',
            });
        } else {
          onErrorPerformAttribution('');
        }
      },
      onError: onErrorPerformAttribution,
    });

  const onSubmit = useCallback(
    (data: any) => {
      attributeDeposit({
        variables: {
          attributions: { ...data, attributionPairs },
        },
      });
    },
    [attributeDeposit, attributionPairs],
  );

  return (
    <>
      <Drawer
        open={isDrawerOpen}
        drawerStyles={{
          body: {
            padding: '36px 24px',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          },
        }}
        width={632}
        onClose={onClose}
        title={<Text type="heading">Attribute deposit</Text>}
      >
        <PendingAttributionsDrawerContent
          addresses={addresses.filter((address) => !!address)}
          availableCountries={availableCountries}
          onClose={onClose}
          onSubmit={onSubmit}
          isLoading={isMutationLoading}
          isAlreadyTrustedSource={hasTrustedSourceError}
        />
      </Drawer>
    </>
  );
};

export default DepositAttributionsDrawer;
