import cn from 'classnames';

const CallMissedIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CallMissed', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M39.17 14L24 29.17L12.83 18H22V14H6V30H10V20.83L24 34.83L42 16.83L39.17 14Z" />
    </svg>
  );
};

export { CallMissedIcon };
