import { useCallback, useState } from 'react';

// Define the context for managing modals
export type ModalControls = [
  boolean,
  { (value: boolean): void; open(): void; close(): void },
];

const useModal = (initialState: boolean): ModalControls => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(initialState);

  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

  // Function to set the modal state
  const setter = (value: boolean) => {
    setIsModalOpen(value);
  };

  // Function to open the modal
  setter.open = () => {
    openModal();
  };

  // Function to close the modal
  setter.close = () => {
    closeModal();
  };

  return [isModalOpen, setter];
};

export default useModal;
