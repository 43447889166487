import cn from 'classnames';

const InboxIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Inbox', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 6H9.98C7.77 6 6.02 7.79 6.02 10L6 38C6 40.21 7.77 42 9.98 42H38C40.21 42 42 40.21 42 38V10C42 7.79 40.21 6 38 6ZM38 30H30C30 33.31 27.31 36 24 36C20.69 36 18 33.31 18 30H9.98V10H38V30Z" />
    </svg>
  );
};

export { InboxIcon };
