import cn from 'classnames';

const DeveloperModeIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DeveloperMode', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M14 10.17H34V14.17H38V6.17004C38 3.96004 36.21 2.19004 34 2.19004L14 2.17004C11.79 2.17004 10 3.96004 10 6.17004V14.17H14V10.17ZM30.83 33.34L40 24.17L30.83 15L28 17.83L34.34 24.17L28 30.52L30.83 33.34ZM20 30.52L13.66 24.18L20 17.83L17.17 15L8 24.17L17.17 33.34L20 30.52ZM34 38.17H14V34.17H10V42.17C10 44.38 11.79 46.17 14 46.17H34C36.21 46.17 38 44.38 38 42.17V34.17H34V38.17Z" />
    </svg>
  );
};

export { DeveloperModeIcon };
