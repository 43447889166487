// Utils
import cn from 'classnames';
import React from 'react';

// Components
import { Text } from '../../../components';
import { AnchorageLogoIcon } from '../../../components/Icons';

// Styles
import css from './index.css';

// Types
import type { TableEmptyStateProps } from './_types';

// Constants
import { DEFAULT_SUBTITLE, DEFAULT_TITLE } from './_constants';

const AnchorageEmptyNFT = () => (
  <div className={css.emptyNFT}>
    <AnchorageLogoIcon className={css.logoIcon} />
  </div>
);

const TableEmptyState: React.FC<TableEmptyStateProps> = (
  {
    title = DEFAULT_TITLE,
    subtitle = DEFAULT_SUBTITLE,
    link,
    linkText = 'Learn More',
    className = '',
    ...restProps
  },
) => (
  <div
    className={cn({
      [css.emptyState]: true,
      [className]: Boolean(className),
    })}
    data-testid="table-empty-state"
    {...restProps}
  >
    <div className={css.imageContainer}>
      <AnchorageEmptyNFT />
      <AnchorageEmptyNFT />
      <AnchorageEmptyNFT />
      <AnchorageEmptyNFT />
    </div>
    <div className={css.titleContainer}>
      <Text className={css.title}>{title}</Text>
      <Text className={css.subtitle} size="small">
        {subtitle || DEFAULT_SUBTITLE}
      </Text>
    </div>
    {link && (
      <a
        className={css.learnMoreLink}
        target="_blank"
        rel="noopener noreferrer"
        href={link}
      >
        <Text className={css.learnMoreText}>{linkText}</Text>
      </a>
    )}
  </div>
);

export default TableEmptyState;
