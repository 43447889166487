import cn from 'classnames';

const DonutLargeIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DonutLarge', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M22 10.16V4C12 5 4 13.62 4 24C4 34.38 12 43 22 44V37.84C16 36.88 10 31.04 10 24C10 16.96 16 11.12 22 10.16ZM37.94 22H44C43.06 12 36 4.94 26 4V10.16C32 11.02 37.08 16 37.94 22ZM26 37.84V44C36 43.06 43.06 36 44 26H37.94C37.08 32 32 36.98 26 37.84Z" />
    </svg>
  );
};

export { DonutLargeIcon };
