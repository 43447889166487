import { StringParam, useQueryParams } from 'use-query-params';

import { OPERATION_QUERY_STRING } from '@anchorage/frontoffice/utils/routes';

import OperationDrawer from 'components/shared/OperationDrawer';

type UseOperationDrawer = {
  component: JSX.Element;
  setter: (x: string) => void;
};

const useOperationDrawer = (): UseOperationDrawer => {
  const [{ op_id: operationID = '' }, setQueryParams] = useQueryParams({
    [OPERATION_QUERY_STRING]: StringParam,
  });

  return {
    component: (
      <OperationDrawer
        isOpen={!!operationID}
        onClose={() =>
          setQueryParams({
            [OPERATION_QUERY_STRING]: undefined,
          })
        }
        operationID={operationID || ''}
      />
    ),
    setter: (id: string) =>
      setQueryParams({
        [OPERATION_QUERY_STRING]: id,
      }),
  };
};

export default useOperationDrawer;
