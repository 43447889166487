import cn from 'classnames';

const CommentIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Comment', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M43.98 8C43.98 5.79 42.21 4 40 4H8C5.79 4 4 5.79 4 8V32C4 34.21 5.79 36 8 36H36L44 44L43.98 8ZM36 28H12V24H36V28ZM36 22H12V18H36V22ZM36 16H12V12H36V16Z" />
    </svg>
  );
};

export { CommentIcon };
