import _capitalize from 'lodash/capitalize';
import type { FC } from 'react';

import { OperationPolicies } from '@anchorage/authorization';

import { OperationDecision } from 'generated/graphql';
import type {
  EndorsementFields,
  EndorsementFieldsFragment,
  OperationDrawer,
} from 'generated/graphql';

import { APIEndorsementMap } from './types';

import WidgetWrapper from '../../shared/WidgetWrapper';
import APIEndorsementSection from './APIEndorsementSection';
import EndorsementSection from './EndorsementSection';

type User = EndorsementFields.user;
type Operation = OperationDrawer.operation;

const { APPROVE, REJECT } = OperationDecision;

type Quorum = {
  displayName: string;
  members: User[];
  threshold: number;
};

type EndorsementMap = {
  approved: { [displayName: string]: Quorum };
  rejected: { [displayName: string]: Quorum };
  waiting: { [displayName: string]: Quorum };
};

type Props = {
  operation: Operation;
  endorsements: EndorsementFieldsFragment[];
};

const Endorsers: FC<Props> = ({
  operation,
  endorsements: tempEndorsements,
}) => {
  const opPolicies = new OperationPolicies(
    operation as ConstructorParameters<typeof OperationPolicies>[0],
  );
  const policy = opPolicies.policyContainer.policies[0];
  const quorums =
    policy &&
    [policy.quorumGroup.baseQuorum].concat(policy.quorumGroup.subquorums);

  const endorsements: { [user: string]: OperationDecision[] } = {};
  tempEndorsements.forEach((endorsement) => {
    const user = endorsement.user;
    if (!user) {
      return;
    }

    const { publicKeyID } = user;
    if (!endorsements[publicKeyID]) {
      endorsements[publicKeyID] = [];
    }

    endorsements[publicKeyID]?.push(endorsement.decision);
  });

  const endorsementsMap: EndorsementMap = {
    approved: {},
    rejected: {},
    waiting: {},
  };
  function insertUserIntoEndorsementMap(
    user: User,
    quorum: Quorum,
    map: EndorsementMap['waiting'],
  ) {
    const { displayName, threshold } = quorum;
    if (!map[displayName]) {
      map[displayName] = {
        displayName: displayName,
        members: [],
        threshold: threshold,
      };
    }

    map[displayName]?.members.push(user);
  }

  quorums?.forEach((quorum) => {
    quorum.members.forEach((member) => {
      const decisions = endorsements[member.publicKeyID];
      if (!decisions) {
        insertUserIntoEndorsementMap(member, quorum, endorsementsMap.waiting);
        return;
      }

      decisions.forEach((endorsement) => {
        switch (endorsement) {
          case APPROVE:
            insertUserIntoEndorsementMap(
              member,
              quorum,
              endorsementsMap.approved,
            );
            break;

          case REJECT:
            insertUserIntoEndorsementMap(
              member,
              quorum,
              endorsementsMap.rejected,
            );
        }
      });
    });
  });

  const apiEndorsements: APIEndorsementMap = tempEndorsements
    .filter(
      (endorsement) =>
        endorsement.key && endorsement.key.__typename === 'APIKeyV2',
    )
    .reduce<APIEndorsementMap>(
      (acc: APIEndorsementMap, endorsement): APIEndorsementMap => {
        if (endorsement.key?.__typename !== 'APIKeyV2') {
          return acc;
        }

        switch (endorsement.decision) {
          case OperationDecision.APPROVE:
            acc.accepted.push(endorsement.key);
            break;
          case OperationDecision.REJECT:
            acc.rejected.push(endorsement.key);
            break;
          default:
            break;
        }

        return acc;
      },
      { accepted: [], rejected: [] },
    );

  return (
    <>
      <APIEndorsementSection
        accepted={apiEndorsements.accepted}
        rejected={apiEndorsements.rejected}
      />
      <WidgetWrapper>
        {Object.keys(endorsementsMap).map((section) => (
          <EndorsementSection
            key={section}
            title={_capitalize(section)}
            quorums={Object.values(
              endorsementsMap[section as keyof EndorsementMap],
            )}
          />
        ))}
      </WidgetWrapper>
    </>
  );
};

export default Endorsers;
