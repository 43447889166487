import { getSplitEnvOptions } from './app';

// treatment is the default value
// on - default active if not configured in split.io
// off - default inactive if not configured set in split.io
const FEATURE_FLAG_DEFAULTS = {
  CD_ACCESS_MANAGEMENT: { treatment: 'off', config: {} },
  CD_ETH_STAKING: { treatment: 'off', config: {} },
  CD_FLEXIBLE_ORG_POLICIES: { treatment: 'off', config: {} },
  CD_IN_APP_SURVEY_DEPOSIT_ATTRIBUTIONS: { treatment: 'off', config: {} },
  CD_MULTIPLE_SUBQUORUMS: { treatment: 'off', config: {} },
  CD_ONBOARDING_API: { treatment: 'off', config: {} },
  CD_PERMISSIONS_M3_1: { treatment: 'off', config: {} },
  CD_PERMISSIONS_M3_2: { treatment: 'off', config: {} },
  CD_SUBACCOUNTING_ENABLED: { treatment: 'off', config: {} },
  CD_WITHDRAW_HIDDEN_ASSETS: { treatment: 'off', config: {} },
  CD_REFRESH_ACTIVITY_FILTERS: { treatment: 'off', config: {} },
  CD_NEW_MAX_WIDTH: { treatment: 'off', config: {} },
  PORTO_REPORTS_TAB: { treatment: 'off', config: {} },
  PORTO_SIGNUP_PAGE: { treatment: 'off', config: {} },
  CD_HIDE_ALLOWED_ADDRESSES: { treatment: 'off', config: {} },
  CD_INCREMENTAL_LOADING: { treatment: 'off', config: {} },
  CD_WEBHOOKS_PAGE: { treatment: 'off', config: {} },
  CD_CR_VLT: { treatment: 'off', config: {} },
  CD_AP_DR_1: { treatment: 'off', config: {} },
  CD_SHOW_API_v1: { treatment: 'off', config: {} },
  CD_MINT_BURN_CONVERT: { treatment: 'off', config: {} },
  CD_MINT_BURN_PYUSD: { treatment: 'off', config: {} },
  CD_MRKT_WDGTS: { treatment: 'off', config: {} },
  // ADB/ADS Standardized Staking
  CD_STANDARDIZED_STAKING: { treatment: 'off', config: {} },
  // Porto Standardized Staking
  CD_SS_PRT: { treatment: 'off', config: {} },
  CD_WTB_1: { treatment: 'off', config: {} },
  CD_STK_API: { treatment: 'off', config: {} },
  CD_ADD_WI: { treatment: 'off', config: {} },
  CD_WFM_1: { treatment: 'off', config: {} },
};

export type FeatureFlags = keyof typeof FEATURE_FLAG_DEFAULTS;

export const FEATURE_FLAGS: { [FeatureFlag in FeatureFlags]: FeatureFlag } =
  Object.keys(FEATURE_FLAG_DEFAULTS).reduce(
    (acc, featureFlag) => {
      return { ...acc, [featureFlag]: featureFlag };
    },
    {} as { [FeatureFlag in FeatureFlags]: FeatureFlag },
  );

export const SPLIT_OPTIONS = {
  defaults: FEATURE_FLAG_DEFAULTS,
  options: {
    ...getSplitEnvOptions(),
    project: 'frontoffice',
    flagType: 'organization',
    debug: false,
  },
};
