import cn from 'classnames';

const AirlineSeatFlatAngledIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AirlineSeatFlatAngled', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M44.51 28.57L43.13 32.34L18.4 23.42L22.55 12.11L39.67 18.29C43.87 19.8 46.04 24.41 44.51 28.57ZM3 24.27L16 28.96V38H32V34.74L41.05 38L42.43 34.23L4.38 20.5L3 24.27ZM14.59 20.41C17.58 18.98 18.84 15.4 17.41 12.41C15.98 9.42001 12.4 8.16001 9.41 9.59001C6.42 11.02 5.16 14.6 6.59 17.59C8.02 20.58 11.6 21.84 14.59 20.41Z" />
    </svg>
  );
};

export { AirlineSeatFlatAngledIcon };
