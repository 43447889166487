import cn from 'classnames';

const Forward5Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Forward5', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M8 26C8 34.8 15.2 42 24 42C32.8 42 40 34.8 40 26H36C36 32.6 30.6 38 24 38C17.4 38 12 32.6 12 26C12 19.4 17.4 14 24 14V22L34 12L24 2V10C15.2 10 8 17.2 8 26ZM21.4 27.8L21.9 23.5H26.7V24.9H23.3L23.1 26.7C23.2 26.7 23.2 26.6 23.3 26.6C23.4 26.6 23.5 26.5 23.6 26.5C23.7 26.5 23.8 26.4 24 26.4H24.4C24.8 26.4 25.2 26.5 25.5 26.6C25.8 26.7 26.1 26.9 26.3 27.2C26.5 27.5 26.7 27.7 26.8 28.1C26.9 28.5 27 28.9 27 29.3C27 29.7 26.9 30 26.8 30.4C26.7 30.8 26.5 31 26.3 31.3C26.1 31.6 25.8 31.8 25.4 31.9C25 32 24.6 32.1 24.1 32.1C23.7 32.1 23.4 32 23 31.9C22.6 31.8 22.4 31.6 22.1 31.4C21.8 31.2 21.6 30.9 21.5 30.6C21.4 30.3 21.2 29.9 21.2 29.5H22.9C22.9 29.9 23.1 30.1 23.3 30.3C23.5 30.5 23.8 30.6 24.1 30.6C24.3 30.6 24.5 30.6 24.6 30.5C24.7 30.4 24.9 30.3 25 30.2C25.1 30.1 25.2 29.9 25.2 29.7C25.2 29.5 25.3 29.3 25.3 29.1C25.3 28.9 25.3 28.7 25.2 28.5C25.1 28.3 25.1 28.2 24.9 28C24.7 27.8 24.6 27.8 24.5 27.7C24.4 27.6 24.1 27.6 23.9 27.6H23.5C23.5 27.6 23.3 27.7 23.2 27.7C23.1 27.7 23 27.9 22.9 28L22.7 28.2L21.4 27.8Z" />
    </svg>
  );
};

export { Forward5Icon };
