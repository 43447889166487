import css from './styles.module.css';

type Props = {
  className?: string;
};

const LoadingDots = ({ className = '' }: Props) => {
  return (
    <span className={className}>
      <span className={css.dot}>.</span>
      <span className={css.dot}>.</span>
      <span className={css.dot}>.</span>
    </span>
  );
};

export default LoadingDots;
