// Components
// Types
import type { OperationDrawer } from 'generated/graphql';

import TitleAndSubtitle from '../../../shared/TitleAndSubtitle';

type VoteOperation = Extract<
  OperationDrawer.operation,
  { __typename: 'VoteOperation' }
>;

type Props = {
  operation: VoteOperation;
};

function MakerGovernancePoll({ operation }: Props) {
  const { details, votingPower } = operation;

  if (!details || !('makerPoll' in details) || !votingPower) {
    return null;
  }

  const { currentVote, title } = details.makerPoll;
  const currentVoteDetails = details.makerPoll.options.find(
    ({ optionID }) => optionID === (currentVote && currentVote.optionID),
  );

  if (!currentVoteDetails) {
    return null;
  }

  return (
    <TitleAndSubtitle
      title={`"${currentVoteDetails?.description}" on "${title}"`}
      subtitle="Governance Poll"
    />
  );
}

export default MakerGovernancePoll;
