import * as ToastPrimitives from '@radix-ui/react-toast';
import cn from 'classnames';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

/**
 * Represents a Toast action component.
 *
 * @component
 * @example
 * ```tsx
 * <ToastAction onClick={handleClick} className="custom-class" />
 * ```
 */
export const ToastAction = forwardRef<
  ElementRef<typeof ToastPrimitives.Action>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cn(['inline-flex', 'items-center', 'justify-center'], className)}
    {...props}
  />
));
ToastAction.displayName = ToastPrimitives.Action.displayName;
