// Utils
import cn from 'classnames';
import pluralize from 'pluralize';
import React from 'react';

// Components
import { Button, InputV2, Tooltip } from '../../../components';

// Hooks
import { useDebounce } from '../../../hooks';
import useInput from '../../../components/InputV2/useInput';

// Styles
import css from './index.css';

// Types
import { PendingAttributionsActionsProps } from './_types';

const PendingAttributionsActions = ({
  className = '',
  initialSearchTerm = '',
  isManualSpamEnabled = false,
  nrOfSelectedDeposits,
  onChangeSearch,
  onClickAttribute,
  onClickFlagAsSpam,
  searchPlaceholder,
  statusMessage,
}: PendingAttributionsActionsProps) => {
  const [{ value }, inputProps] = useInput({
    containerClassName: css.search,
    placeholder: searchPlaceholder,
    type: 'search',
    value: initialSearchTerm,
  });

  useDebounce(value as string, 500, { onChange: onChangeSearch });

  const classes = cn({
    [css.pendingAttributionsActions]: true,
    [className]: !!className,
  });

  return (
    <div className={classes}>
      <InputV2 {...inputProps} />
      {!!statusMessage && (
        <div className={css.status} data-testid="status-message">
          {statusMessage}
        </div>
      )}
      {nrOfSelectedDeposits > 0 ? (
        <div className={css.actionsContainer}>
        {isManualSpamEnabled && onClickFlagAsSpam && (
            <Tooltip title="Flag as spam unrecognized deposits">
              <Button onClick={onClickFlagAsSpam} type="destructive">
                Flag as spam
              </Button>
            </Tooltip>
          )
        }
        <Button onClick={onClickAttribute} type="secondary">
          {`Attribute ${pluralize('deposit', nrOfSelectedDeposits)}`}
        </Button>
        </div>
      ) : null}
    </div>
  );
};

export default PendingAttributionsActions;
