import cn from 'classnames';

const BatteryUnknownIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BatteryUnknown', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M31.33 8H28V4H20V8H16.67C15.19 8 14 9.19 14 10.67V41.34C14 42.81 15.19 44.01 16.67 44.01H31.34C32.81 44.01 34.01 42.82 34.01 41.34V10.67C34 9.19 32.81 8 31.33 8ZM25.9 35.9H22.1V32.1H25.9V35.9ZM28.6 25.38C28.6 25.38 27.84 26.22 27.26 26.8C26.29 27.77 25.6 29.09 25.6 30H22.4C22.4 28.34 23.32 26.95 24.26 26.01L26.12 24.12C26.66 23.58 27 22.83 27 22C27 20.34 25.66 19 24 19C22.34 19 21 20.34 21 22H18C18 18.69 20.69 16 24 16C27.31 16 30 18.69 30 22C30 23.32 29.47 24.52 28.6 25.38Z" />
    </svg>
  );
};

export { BatteryUnknownIcon };
