import { StakingPositionState, WalletStakingDetails } from 'generated/graphql';

const getPositionAmout = (
  stakeAmoutState: WalletStakingDetails.stakeAmountStates[] | null | undefined,
  stateType: StakingPositionState,
) => {
  if (!stakeAmoutState) {
    return undefined;
  }

  const [amount] = stakeAmoutState.filter(
    (stakeAmount) => stakeAmount.type === stateType,
  );

  return amount;
};

export default getPositionAmout;
