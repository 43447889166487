import { Badge } from '@latitude/badge';
import cn from 'classnames';
import type { FC, HTMLAttributes } from 'react';

// Component that displays a badge when the app is running in a local environment
// Useful for developers to know when they are working in a local environment vs development or production
const LocalEnvBadge: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  const isLocalEnv = process.env.NODE_ENV !== 'production';

  if (!isLocalEnv) {
    return null;
  }

  return (
    <Badge
      className={cn([className, 'absolute', 'right-4'])}
      leadingElement={null}
      trailingElement={null}
      priority="high"
      variant="warning"
    >
      Local Env
    </Badge>
  );
};

export default LocalEnvBadge;
