import { DateFormat } from './types';

const FORMATS: Record<DateFormat, string> = {
  default: 'MM/dd/yyyy hh:mma',
  minutes: 'MM/dd/yyyy hh:mma',
  days: 'MM/dd/yyyy',
  year: 'yyyy',
  shortMonth: 'MMM d, yyyy',
  seconds: 'MMM d, yyyy H:mm:ss',
  longMinutes: 'MMM d, yyyy h:mmaaa',
  businessDays: 'yyyy-MM-dd',
  seconds12h: 'MMM d, yyyy h:mm:ss a',
};

/**
 * Hello this is a description
 * @param formatStr - Human readable descriptor for date format.
 * @example
 * ```js
 *   const format = getDateFormat('minutes');
 * ```
 * @description
 *  Default formating is `MM/dd/yyyy hh:mma`
 *  - `days`: MM/dd/yyyy,
 *  - `year`: yyyy,
 *  - `shortMonth`: MMM d, yyyy,
 *  - `seconds`: MMM d, yyyy H:mm:ss,
 */
export default function getDateFormat(formatStr?: DateFormat): string {
  return formatStr ? FORMATS[formatStr] : FORMATS.default;
}
