import { AssetTypeID } from 'generated/graphql';

export const DISCLOSURE_IDS: { [x in keyof typeof AssetTypeID]?: string } = {
  [AssetTypeID.CGLD]: 'celo_voting',
  [AssetTypeID.CEUR]: 'celo_voting',
  [AssetTypeID.MKR]: 'maker_voting',
  [AssetTypeID.MKR_R]: 'maker_voting',
  [AssetTypeID.ROSE]: 'oasis_delegation',
  [AssetTypeID.XTZ]: 'tezos_delegation',
  [AssetTypeID.FLOW]: 'flow_delegation',
};
