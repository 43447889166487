/* Apollo Query Network Statuses */

// https://github.com/apollographql/apollo-client/blob/master/packages/apollo-client/src/core/networkStatus.ts

export const STATUS_FIRST_LOAD = 1;
export const STATUS_SET_VARIABLES = 2;
export const STATUS_FETCH_MORE = 3;
export const STATUS_POLL = 6;

/* Poll Intervals */

export const MINUTE_INTERVAL = 60000;
export const HALF_MINUTE_INTERVAL = 30000;
export const SLOW_POLL_INTERVAL = 10000;
export const MEDIUM_POLL_INTERVAL = 6000;
export const FAST_POLL_INTERVAL = 4000;
