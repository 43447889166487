import _capitalize from 'lodash/capitalize';
import { useCallback } from 'react';
import type { FC } from 'react';

import { OperationPolicies } from '@anchorage/authorization';
import { Text } from '@anchorage/common/dist/components';

import { OperationDecision } from 'generated/graphql';
import type {
  EndorsementFields,
  EndorsementFieldsFragment,
  OperationDrawer,
} from 'generated/graphql';

import css from './styles.module.scss';

import WidgetWrapper from '../../shared/WidgetWrapper';
import EndorsementSection from './EndorsementSection';
import type { UserWithRequired } from './EndorsementSection';

type User = EndorsementFields.user;
type Operation = OperationDrawer.operation;

const { APPROVE, REJECT } = OperationDecision;

type EndorsementMap = {
  approved: UserWithRequired[];
  rejected: UserWithRequired[];
  waiting: UserWithRequired[];
};

type Props = {
  operation: Operation;
  endorsements: EndorsementFieldsFragment[];
};

const Endorsers: FC<Props> = ({ operation, endorsements }) => {
  const opPolicies = new OperationPolicies(
    operation as ConstructorParameters<typeof OperationPolicies>[0],
  );
  const policy = opPolicies.policyContainer.policies[0];
  const publicKeyIDs: Set<string> = new Set([]);
  const effectiveRequiredUsers = policy?.requiredUsersQuorumMembers || [];
  const requiredUserKeyIds = effectiveRequiredUsers.map(
    ({ publicKeyID }) => publicKeyID,
  );

  const requiredSignatures = policy?.requiredUsersNecessaryEndorsements;
  const showRequiredApproversText = Boolean(
    requiredSignatures && requiredSignatures > 0,
  );
  const requiredApproversText = `${requiredSignatures} out of ${effectiveRequiredUsers.length} required users are necessary for this operation to be approved.`;

  const createUserWithRequired = useCallback(
    (user: User) => ({
      ...user,
      isRequiredApprover: requiredUserKeyIds.includes(user.publicKeyID),
    }),
    [requiredUserKeyIds],
  );

  const endorsementsMap: EndorsementMap = endorsements.reduce<EndorsementMap>(
    (acc, endorsement: EndorsementFieldsFragment) => {
      if (endorsement.user) {
        const user = createUserWithRequired(endorsement.user);
        publicKeyIDs.add(user.publicKeyID);
        if (endorsement.decision === APPROVE) {
          acc.approved = acc.approved.concat(user);
        } else if (endorsement.decision === REJECT) {
          acc.rejected = acc.rejected.concat(user);
        }
      }
      return acc;
    },
    { approved: [], rejected: [], waiting: [] },
  );

  policy?.quorumMembers
    .filter((user) => !publicKeyIDs.has(user.publicKeyID))
    .forEach((user) =>
      endorsementsMap.waiting.push(createUserWithRequired(user)),
    );

  return (
    <>
      {showRequiredApproversText && (
        <Text size="small" type="body" className={css.requiredApprovers}>
          {requiredApproversText}
        </Text>
      )}
      <WidgetWrapper>
        {Object.keys(endorsementsMap).map((section) => (
          <EndorsementSection
            key={section}
            title={_capitalize(section)}
            users={endorsementsMap[section as keyof EndorsementMap]}
          />
        ))}
      </WidgetWrapper>
    </>
  );
};

export default Endorsers;
