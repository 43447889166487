import cn from 'classnames';

const CenterFocusStrongIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CenterFocusStrong', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 16C19.58 16 16 19.58 16 24C16 28.42 19.58 32 24 32C28.42 32 32 28.42 32 24C32 19.58 28.42 16 24 16ZM10 30H6V38C6 40.21 7.79 42 10 42H18V38H10V30ZM10 10H18V6H10C7.79 6 6 7.79 6 10V18H10V10ZM38 6H30V10H38V18H42V10C42 7.79 40.21 6 38 6ZM38 38H30V42H38C40.21 42 42 40.21 42 38V30H38V38Z" />
    </svg>
  );
};

export { CenterFocusStrongIcon };
