import { ApolloClient } from '@apollo/client';
import { TopBar } from '@features/top-bar';
import cn from 'classnames';
import * as React from 'react';

import { useFeatureFlagsContext } from '@anchorage/feature-flags';
import { SideNav } from '@anchorage/frontoffice/components/SideNav';
import { FEATURE_FLAGS } from '@anchorage/frontoffice/constants/split';
import { useAppSharedComponents } from '@anchorage/frontoffice/contexts/AppSharedComponents';
import { useAppUser } from '@anchorage/frontoffice/contexts/AppUser';
import { useUserSessionManager } from '@anchorage/frontoffice/hooks/useUserSessionManager';

import ImportantUpdatesWrapper from 'components/ProductUpdates/ImportantProductUpdates/ImportantUpdatesWrapper';
import ProductUpdatesDrawer from 'components/ProductUpdates/ProductUpdatesDrawer';

import useHasOrgLevelPermissions from 'hooks/useHasOrgLevelPermissions';
import useOperationDrawer from 'hooks/useOperationDrawer';
import useUnattributedDeposits from 'hooks/useUnattributedDeposits';

import { IAMPermissionAction } from 'generated/graphql';

import css from './styles.module.scss';

import PortoAcknowledgeModal from './PortoAcknowledgeModal';

type Props = React.PropsWithChildren & {
  client?: ApolloClient<unknown>;
};

const AppLayout: React.FC<Props> = ({ children, client }) => {
  const { useIsFeatureActive } = useFeatureFlagsContext();
  const [shouldUseNewMaxWidth] = useIsFeatureActive(
    FEATURE_FLAGS.CD_REFRESH_ACTIVITY_FILTERS,
  );
  const [isWebTopBarActive] = useIsFeatureActive(FEATURE_FLAGS.CD_WTB_1);

  const { logoutUser } = useUserSessionManager(client);

  const {
    productUpdates: {
      isOpen,
      feedURL,
      updateProductUpdatesOpenState: setIsOpen,
      setProductUpdatesAsRead,
    },
  } = useAppSharedComponents();

  const { currentUser, organization, isPortoOrg, isAdmin } = useAppUser();
  const { component: OperationDrawer } = useOperationDrawer();

  const [hasOrgViewPermissions, hasConnectedAppsViewPermissions] =
    useHasOrgLevelPermissions([
      IAMPermissionAction.ORGANIZATIONS_IAM_VIEW,
      IAMPermissionAction.CONNECTED_APPS_VIEWERS_ACCESS,
    ]);
  const { total: totalPendingAttributions } = useUnattributedDeposits(null);

  return (
    <>
      <div className={css.layout} data-testid="app-layout" id="content-root">
        <SideNav
          organization={{
            name: organization.name,
            operator: organization.operator,
            organizationKeyID: organization.organizationKeyID,
          }}
          user={{
            addedTime: currentUser.addedTime,
            isUserAdmin: isAdmin,
            secureName: currentUser.secureName,
            userEmail: currentUser.email,
          }}
          permissions={{
            hasConnectedAppsViewPermissions: !!hasConnectedAppsViewPermissions,
            hasOrgViewPermissions: !!hasOrgViewPermissions,
          }}
          totalPendingAttributions={totalPendingAttributions}
        />
        <div className={css.contentWrapper}>
          {isWebTopBarActive && <TopBar />}
          <div
            className={cn(css.content, {
              [css.legacyMaxWidth as string]: !shouldUseNewMaxWidth,
            })}
          >
            {children}
            {OperationDrawer}
          </div>
        </div>
      </div>
      <ImportantUpdatesWrapper />
      <ProductUpdatesDrawer
        feedURL={feedURL}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onFeedLoaded={() => {
          setProductUpdatesAsRead();
        }}
      />
      {isPortoOrg ? <PortoAcknowledgeModal handleLogout={logoutUser} /> : null}
    </>
  );
};

export default AppLayout;
