/**
 * Get Current UTC date
 *
 * @param date
 */
export default function getCurrentUTCDate(currentDate: Date): Date {
  return new Date(
    currentDate.getTime() + currentDate.getTimezoneOffset() * 60 * 1000
  );
}
