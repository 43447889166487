import cn from 'classnames';
import * as React from 'react';

import { Avatar } from '@anchorage/common/dist/components';

import css from './styles.module.scss';

import TitleAndSubtitle from '../TitleAndSubtitle';

type Props = React.ComponentPropsWithoutRef<'div'> & {
  title: string;
  subtitle?: string;
  hasBorder?: boolean;
};
const AvatarWithText: React.FC<Props> = ({
  title,
  subtitle,
  hasBorder = false,
  className,
  ...restProps
}) => {
  return (
    <div className={cn([css.root, className])} {...restProps}>
      <Avatar hasBorder={hasBorder} title={title} name={title} />
      <div className={css.textContainer}>
        <TitleAndSubtitle title={title} subtitle={subtitle} />
      </div>
    </div>
  );
};

export default AvatarWithText;
