import cn from 'classnames';

const FitnessCenterIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FitnessCenter', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M41.14 29.71L44 26.86L41.14 24L34 31.14L16.86 14L24 6.86L21.14 4L18.29 6.86L15.43 4L11.14 8.29L8.29 5.43L5.43 8.29L8.29 11.14L4 15.43L6.86 18.29L4 21.14L6.86 24L14 16.86L31.14 34L24 41.14L26.86 44L29.71 41.14L32.57 44L36.86 39.71L39.71 42.57L42.57 39.71L39.71 36.86L44 32.57L41.14 29.71Z" />
    </svg>
  );
};

export { FitnessCenterIcon };
