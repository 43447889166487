import cn from 'classnames';

const LineStyleIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LineStyle', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6 32H16V28H6V32ZM19 32H29V28H19V32ZM32 32H42V28H32V32ZM6 40H10V36H6V40ZM14 40H18V36H14V40ZM22 40H26V36H22V40ZM30 40H34V36H30V40ZM38 40H42V36H38V40ZM6 24H22V20H6V24ZM26 24H42V20H26V24ZM6 8V16H42V8H6Z" />
    </svg>
  );
};

export { LineStyleIcon };
