import cn from 'classnames';
import * as React from 'react';

import { Text } from '@anchorage/common/dist/components';

import css from './styles.module.scss';

type Props = Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> & {
  title: React.ReactNode;
};

const SectionRow: React.FC<Props> = ({
  title,
  className = '',
  children,
  ...restProps
}) => {
  return (
    <div className={cn([css.root, className])} {...restProps}>
      <div className={css.titleContainer}>
        {React.isValidElement(title) ? (
          <div className={css.title}>{title}</div>
        ) : (
          <Text size="small" className={css.title}>
            {title}
          </Text>
        )}
      </div>
      <div className={css.contentContainer}>{children}</div>
    </div>
  );
};

export default SectionRow;
