import cn from 'classnames';

const LocalCafeIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalCafe', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 6H8V26C8 30.42 11.58 34 16 34H28C32.42 34 36 30.42 36 26V20H40C42.21 20 44 18.21 44 16V10C44 7.79 42.21 6 40 6ZM40 16H36V10H40V16ZM4 42H40V38H4V42Z" />
    </svg>
  );
};

export { LocalCafeIcon };
