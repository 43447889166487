import cn from 'classnames';

const AccessAlarmIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AccessAlarm', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M44 11.44L34.81 3.72997L32.24 6.78997L41.43 14.5L44 11.44ZM15.76 6.77997L13.19 3.71997L4 11.43L6.57 14.49L15.76 6.77997ZM25 16H22V28L31.49 33.71L33 31.24L25 26.5V16ZM23.99 7.99997C14.04 7.99997 6 16.06 6 26C6 35.94 14.04 44 23.99 44C33.94 44 42 35.94 42 26C42 16.06 33.94 7.99997 23.99 7.99997ZM24 40C16.27 40 10 33.73 10 26C10 18.27 16.27 12 24 12C31.73 12 38 18.27 38 26C38 33.73 31.74 40 24 40Z" />
    </svg>
  );
};

export { AccessAlarmIcon };
