import { Switch, Text } from '@anchorage/common/dist/components';
import type { AllFeatureFlags } from '@anchorage/feature-flags';
import { DEFAULT_VALUES } from '@anchorage/feature-flags';

import css from './styles.module.scss';

const FeatureFlagsList = ({
  flagsInProvider,
  flagsInLocalStorage,
  toggleFlag,
}: {
  flagsInProvider: AllFeatureFlags;
  flagsInLocalStorage: AllFeatureFlags;
  toggleFlag: (name: string) => void;
}) => {
  const flagsToRender = { ...flagsInProvider, ...flagsInLocalStorage };
  const hasFeatureFlags = Object.keys(flagsInProvider).length !== 0;
  if (!hasFeatureFlags) {
    return <Text>There are currently no feature flags in use</Text>;
  }

  return (
    <>
      {Object.entries(flagsToRender).reduce((acc, [name, state]) => {
        if (!flagsInProvider[name]) {
          return acc;
        }
        const isDefaultEnabled =
          flagsInProvider[name]!.treatment === (DEFAULT_VALUES.ON as string);
        const hasFlagChanged = flagsInLocalStorage[name] !== undefined;
        const element = (
          <div key={name} className={css.featureFlag}>
            <div className={css.flagNameContainer}>
              <Text key="body-medium">{name}</Text>
              {hasFlagChanged && (
                <Text size="tiny" className={css.defaultText}>
                  Default:{' '}
                  {isDefaultEnabled ? (
                    <Text className={css.enabled} size="tiny">
                      Enabled
                    </Text>
                  ) : (
                    <Text className={css.disabled} size="tiny">
                      Disabled
                    </Text>
                  )}
                </Text>
              )}
            </div>
            <Switch
              checked={state.treatment === (DEFAULT_VALUES.ON as string)}
              onChange={() => toggleFlag(name)}
            />
          </div>
        );
        acc.push(element);
        return acc;
      }, [] as JSX.Element[])}
    </>
  );
};

export default FeatureFlagsList;
