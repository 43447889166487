import cn from 'classnames';

const AirplanemodeActiveIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AirplanemodeActive', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 32V28L26 18V7C26 5.34 24.66 4 23 4C21.34 4 20 5.34 20 7V18L4 28V32L20 27V38L16 41V44L23 42L30 44V41L26 38V27L42 32Z" />
    </svg>
  );
};

export { AirplanemodeActiveIcon };
