import { useMemo } from 'react';

import getStakingPositionByValidatorAddress from '../../utils/getStakingPositionByValidatorAddress';

import useStandardizedStakingContext from '../../StandardizedStakingContext/useStandardizedStakingContext';
import ValidatorAddressReadOnlyInput from '../../ValidatorAddressReadOnlyInput';
import GenericStakingStep from '../StandardizedStakingFormPage/StakingSteps/GenericStakingStep';

const StandardizedClaimRewardsFormPage = () => {
  const { walletAsset, validatorAddress } = useStandardizedStakingContext();

  const claimFormSteps = useMemo(() => {
    const position = getStakingPositionByValidatorAddress(
      walletAsset.staking?.positions ?? [],
      validatorAddress,
    );
    return position?.claimRewardsForm?.steps ?? [];
  }, [walletAsset.staking?.positions, validatorAddress]);

  return (
    <div className="*:mt-6">
      <ValidatorAddressReadOnlyInput validatorAddress={validatorAddress} />
      {claimFormSteps.map((step, index) => {
        return <GenericStakingStep step={step} key={index} />;
      })}
    </div>
  );
};

export default StandardizedClaimRewardsFormPage;
