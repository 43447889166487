import cn from 'classnames';

const BaselineWorkOffIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Baseline-workOff24px', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M46 43.48L43.08 40.56L14.42 11.9L6.49998 3.97998L3.97998 6.49998L9.37998 11.9H8.09998C5.87998 11.9 4.11998 13.68 4.11998 15.9L4.09998 37.9C4.09998 40.12 5.87998 41.9 8.09998 41.9H39.38L43.48 46L46 43.48ZM44 15.9C44.1 13.68 42.32 11.9 40.1 12H32.1V7.89998C32.1 5.67998 30.32 3.89998 28.1 3.99998H20.1C17.88 3.89998 16.1 5.67998 16.1 7.89998V8.53998L44 36.54V15.9ZM28.1 12H20V7.89998H28.1V12Z" />
    </svg>
  );
};

export { BaselineWorkOffIcon };
