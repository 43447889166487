import { Text } from '@anchorage/common/dist/components';
import { NormalizedSignedAmountField } from '@anchorage/vsp';

export const AmountRow = ({
  field,
}: {
  field: NormalizedSignedAmountField;
}) => {
  // @ts-ignore
  const amount = field.amount;
  return (
    <Text size="tiny">
      {amount ? `${amount} ${field.abbreviation}` : field.fallbackText}
    </Text>
  );
};
