import cn from 'classnames';

const Filter6Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Filter6', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6 10H2V42C2 44.21 3.79 46 6 46H38V42H6V10ZM42 2H14C11.79 2 10 3.79 10 6V34C10 36.21 11.79 38 14 38H42C44.21 38 46 36.21 46 34V6C46 3.79 44.21 2 42 2ZM42 34H14V6H42V34ZM26 30H30C32.21 30 34 28.21 34 26V22C34 19.79 32.21 18 30 18H26V14H34V10H26C23.79 10 22 11.79 22 14V26C22 28.21 23.79 30 26 30ZM26 22H30V26H26V22Z" />
    </svg>
  );
};

export { Filter6Icon };
