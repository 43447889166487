import cn from 'classnames';
import React, { useCallback } from 'react';

import { useFormContext } from '@anchorage/common/dist/components/Form/_reactHookForm';
import { useSnackbar } from '@anchorage/common/dist/components/Snackbar';

import EditableComment from 'components/shared/EditableComment';
import SectionRow from 'components/shared/OperationDrawer/components/shared/SectionRow';
import WidgetWrapper from 'components/shared/OperationDrawer/components/shared/WidgetWrapper';
import Comment from 'components/shared/OperationDrawer/components/widgets/Comment';

import { useEditOperationDescriptionMutation } from 'generated/graphql';

import css from './styles.module.scss';

import { CommentFormValue } from './types';

type Props = {
  description: string;
  descriptionEditable: boolean;
  hasTopBorder: boolean;
  operationID: string;
};

export const EditCommentSection = ({
  description,
  descriptionEditable,
  hasTopBorder,
  operationID,
}: Props) => {
  const { addSnackbar } = useSnackbar();

  const [editOperationDescription, { loading: editOpLoading }] =
    useEditOperationDescriptionMutation();

  const { setValue, watch } = useFormContext<CommentFormValue>();

  const comment = watch('comment');

  const handleOnAddComment = useCallback(
    (value: string) => {
      if (!operationID) {
        return;
      }
      editOperationDescription({
        variables: {
          operationID,
          message: value,
        },
        onCompleted: () => {
          setValue('comment', value);
        },
        onError: (e) => {
          addSnackbar({
            type: 'error',
            text:
              e.message ||
              'There was an error adding your comment. Please try again.',
          });
        },
      });
    },
    [setValue, operationID, addSnackbar, editOperationDescription],
  );

  const wrapperClasses = cn({
    [css.commentWrapperTopBorder!]: hasTopBorder,
    [css.commentWrapperNoTopBorder!]: !hasTopBorder,
  });

  return (
    <WidgetWrapper className={wrapperClasses}>
      <SectionRow title="Comment">
        {descriptionEditable ? (
          <EditableComment
            value={comment}
            onAdd={handleOnAddComment}
            loading={editOpLoading}
          />
        ) : (
          <Comment comment={description} />
        )}
      </SectionRow>
    </WidgetWrapper>
  );
};
