import React from 'react';

// Types
import { BannerActions, SimpleBannerProps } from './_types';

// Components
import Banner from './index';
// Hooks
import useBanner from './useBanner';

const SimpleBanner: React.FC<SimpleBannerProps> = (props) => {
  const [, bannerProps] = useBanner(props);

  return <Banner {...bannerProps} />;
};

export default SimpleBanner;
export { BannerActions };
