import cn from 'classnames';

const HdIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Hd24px', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 6H10C7.78 6 6 7.8 6 10V38C6 40.2 7.78 42 10 42H38C40.2 42 42 40.2 42 38V10C42 7.8 40.2 6 38 6ZM22 30H19V26H15V30H12V18H15V23H19V18H22V30ZM26 18H34C35.1 18 36 18.9 36 20V28C36 29.1 35.1 30 34 30H26V18ZM29 27H33V21H29V27Z" />
    </svg>
  );
};

export { HdIcon };
