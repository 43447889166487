import cn from 'classnames';

const LocalParkingIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalParking', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M26 6H12V42H20V30H26C32.63 30 38 24.63 38 18C38 11.37 32.63 6 26 6ZM26.4 22H20V14H26.4C28.61 14 30.4 15.79 30.4 18C30.4 20.21 28.61 22 26.4 22Z" />
    </svg>
  );
};

export { LocalParkingIcon };
