import cn from 'classnames';

const HttpsIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Https', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M36 16H34V12C34 6.48 29.52 2 24 2C18.48 2 14 6.48 14 12V16H12C9.79 16 8 17.79 8 20V40C8 42.21 9.79 44 12 44H36C38.21 44 40 42.21 40 40V20C40 17.79 38.21 16 36 16ZM24 34C21.79 34 20 32.21 20 30C20 27.79 21.79 26 24 26C26.21 26 28 27.79 28 30C28 32.21 26.21 34 24 34ZM30.2 16H17.8V12C17.8 8.58 20.58 5.8 24 5.8C27.42 5.8 30.2 8.58 30.2 12V16Z" />
    </svg>
  );
};

export { HttpsIcon };
