import cn from 'classnames';
import * as React from 'react';

import LoadingDots from 'components/shared/LoadingDots';

import css from './styles.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement>;

function LoadingPage({ className = '', ...restProps }: Props) {
  const classes = cn({
    [css.loader!]: true,
    [className]: Boolean(className),
  });

  return (
    <div className={classes} {...restProps}>
      <LoadingDots />
    </div>
  );
}

export default LoadingPage;
