import { cva } from 'class-variance-authority';

export const bodyVariants = cva([
  'overflow-y-auto',
  'flex-1',
  'text-color-ref-text-string',
  'text-ref-web-body-medium-font-size',
  'font-ref-web-body-medium-font-weight',
  'font-ref-web-body-medium-font-family',
  'tracking-font-ref-web-body-medium-letter-spacing',
  'px-spacing-sys-default-16',
  'pt-spacing-sys-default-16',
]);

export const footerVariants = cva(
  [
    'flex',
    'justify-between',
    'min-h-sizing-sys-size-64',
    'p-spacing-sys-default-16',
  ],
  {
    variants: {
      removeFooterSeparator: {
        true: '',
        false: 'border-t-border-sys-1 border-color-ref-ui-divider-strong',
      },
    },
    defaultVariants: {
      removeFooterSeparator: false,
    },
  },
);
