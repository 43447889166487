import cn from 'classnames';

const AddAPhotoIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AddAPhoto', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6 8V2H10V8H16V12H10V18H6V12H0V8H6ZM12 20V14H18V8H32L35.66 12H42C44.2 12 46 13.8 46 16V40C46 42.2 44.2 44 42 44H10C7.8 44 6 42.2 6 40V20H12ZM26 38C31.52 38 36 33.52 36 28C36 22.48 31.52 18 26 18C20.48 18 16 22.48 16 28C16 33.52 20.48 38 26 38ZM19.6 28C19.6 31.54 22.46 34.4 26 34.4C29.54 34.4 32.4 31.54 32.4 28C32.4 24.46 29.54 21.6 26 21.6C22.46 21.6 19.6 24.46 19.6 28Z" />
    </svg>
  );
};

export { AddAPhotoIcon };
