import { cva } from 'class-variance-authority';

export const avatarVariants = cva(
  [
    'flex',
    'items-center',
    'justify-center',
    'rounded-radius-comp-avatar',
    'box-content',
  ],
  {
    variants: {
      size: {
        tiny: [
          'w-sizing-comp-avatar-24',
          'h-sizing-comp-avatar-24',
          '[&>svg]:w-sizing-sys-size-16',
          '[&>svg]:h-sizing-sys-size-16',
        ],
        xsmall: [
          'w-sizing-comp-avatar-32',
          'h-sizing-comp-avatar-32',
          '[&>svg]:w-sizing-sys-size-16',
          '[&>svg]:h-sizing-sys-size-16',
        ],
        small: [
          'w-sizing-comp-avatar-40',
          'h-sizing-comp-avatar-40',
          '[&>svg]:w-sizing-sys-size-24',
          '[&>svg]:h-sizing-sys-size-24',
        ],
        medium: [
          'w-sizing-comp-avatar-48',
          'h-sizing-comp-avatar-48',
          '[&>svg]:w-sizing-sys-size-32',
          '[&>svg]:h-sizing-sys-size-32',
        ],
        large: [
          'w-sizing-comp-avatar-56',
          'h-sizing-comp-avatar-56',
          '[&>svg]:w-sizing-sys-size-40',
          '[&>svg]:h-sizing-sys-size-40',
        ],
        huge: [
          'w-sizing-comp-avatar-96',
          'h-sizing-comp-avatar-96',
          '[&>svg]:w-sizing-sys-size-64',
          '[&>svg]:h-sizing-sys-size-64',
        ],
      },
      variant: {
        strong: ['bg-color-comp-avatar-strong-container'],
        muted: ['bg-color-comp-avatar-muted-container'],
      },
      type: {
        letter: [],
        image: ['bg-cover', 'bg-center', 'bg-no-repeat'],
        icon: [],
      },
      border: {
        true: [],
        false: [],
      },
      halo: {
        true: [],
        false: [],
      },
    },
    compoundVariants: [
      {
        border: false,
        halo: false,
        className: ['shadow-none'],
      },
      {
        border: true,
        halo: false,
        className: ['shadow-[0_0_0_2px_var(--colorCompAvatarBorder)]'],
      },
      {
        border: false,
        halo: true,
        className: [
          'shadow-[0_0_0_2px_var(--colorCompAvatarBorder),0_0_0_4px_var(--colorCompAvatarHaloInformative)]',
        ],
      },
      {
        border: true,
        halo: true,
        className: [
          'shadow-[0_0_0_2px_var(--colorCompAvatarBorder),0_0_0_4px_var(--colorCompAvatarHaloInformative)]',
        ],
      },
    ],
    defaultVariants: {
      variant: 'strong',
      size: 'small',
      type: 'letter',
    },
  },
);
