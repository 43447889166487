import getTime from 'date-fns/getTime';

import { UIDeveloperError, reportError } from '../errors';

/**
 * TODO: Add Description
 * TODO: Implement Errors back
 * @param date
 */
export default function convertDateToNanos(date: number | Date): string {
  // Given a date, get the timestamp in millis
  const timestampMillis = getTime(date);
  // Cast to nanoseconds
  const timestampNanos = timestampMillis * 1000000;
  if (Number.isNaN(Number(timestampNanos))) {
    reportError(
      new UIDeveloperError(
        `Unsupported date format in convertDateToNanos: ${date.toString()}`
      )
    );
  }
  return String(timestampNanos);
}
