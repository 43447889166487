import cn from 'classnames';

const DoNotDisturbOffIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DoNotDisturbOff', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M34 22V26H31.08L40.44 35.36C42.68 32.14 44 28.22 44 24C44 12.96 35.04 4 24 4C19.78 4 15.86 5.32 12.64 7.56L27.08 22H34ZM4.54 4.54L2 7.08L7.56 12.64C5.32 15.86 4 19.78 4 24C4 35.04 12.96 44 24 44C28.22 44 32.14 42.68 35.36 40.44L40.92 46L43.46 43.46L22 22L4.54 4.54ZM14 26V22H16.92L20.92 26H14Z" />
    </svg>
  );
};

export { DoNotDisturbOffIcon };
