import {
  getPolicyFromVaultPolicyUpdate,
  isFragmentOriginalBindingNotInternalFilterFn,
  isFragmentUpdatedBindingNotInternalFilterFn,
  vaultFragmentHasOriginalBindingFilterFn,
  vaultFragmentHasUpdatedBindingFilterFn,
  vaultFragmentHasVaultFilterFn,
  vaultPolicyMapHasOriginalPolicyFilterFn,
  vaultPolicyMapOriginalPolicyisNotDepositFilterFn,
} from './utils';

import type { VaultPolicyContainerUpdate as IVaultPolicyContainerUpdate } from '../generated/graphql';
import { PolicySourceOfTruth } from '../generated/graphql';

import type { BareVaultPolicyUpdate } from '../types';

import { VaultPolicy } from './VaultPolicy';

export class VaultPolicyContainerUpdate {
  readonly original: VaultPolicy[];
  readonly updated: VaultPolicy[];

  constructor(
    private sourceOfTruth: PolicySourceOfTruth,
    private vaultPolicyUpdates?: Partial<BareVaultPolicyUpdate>[],
    private vaultPolicyContainerUpdates?: IVaultPolicyContainerUpdate | null,
  ) {
    this.original = this.getOriginal() || [];
    this.updated = this.getUpdated() || [];
  }

  private getUpdated() {
    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return this.vaultPolicyContainerUpdates?.fragmentBindingUpdates
          ?.filter(vaultFragmentHasUpdatedBindingFilterFn)
          .filter(isFragmentUpdatedBindingNotInternalFilterFn)
          .filter(vaultFragmentHasVaultFilterFn)
          .map(
            (fragment) =>
              new VaultPolicy(
                PolicySourceOfTruth.POLICY_SOT_CONTAINER,
                fragment.updatedBinding,
                fragment.vaultID.vault,
              ),
          );
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
        return this.vaultPolicyUpdates
          ?.filter(vaultPolicyMapHasOriginalPolicyFilterFn)
          .filter(vaultPolicyMapOriginalPolicyisNotDepositFilterFn)
          .map(
            (update) =>
              new VaultPolicy(
                PolicySourceOfTruth.POLICY_SOT_LEGACY,
                getPolicyFromVaultPolicyUpdate(update),
                update?.originalPolicy.vault,
              ),
          );
    }
  }

  private getOriginal() {
    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return this.vaultPolicyContainerUpdates?.fragmentBindingUpdates
          ?.filter(vaultFragmentHasOriginalBindingFilterFn)
          .filter(isFragmentOriginalBindingNotInternalFilterFn)
          .filter(vaultFragmentHasVaultFilterFn)
          .map(
            (fragment) =>
              new VaultPolicy(
                PolicySourceOfTruth.POLICY_SOT_CONTAINER,
                fragment.originalBinding,
                fragment.vaultID.vault,
              ),
          );
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
        return this.vaultPolicyUpdates
          ?.filter(vaultPolicyMapHasOriginalPolicyFilterFn)
          .filter(vaultPolicyMapOriginalPolicyisNotDepositFilterFn)
          .map(
            (update) =>
              new VaultPolicy(
                PolicySourceOfTruth.POLICY_SOT_LEGACY,
                update?.originalPolicy,
                update?.originalPolicy.vault,
              ),
          );
    }
  }
}
