import cn from 'classnames';

const FindReplaceIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FindReplace', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M22 12C24.76 12 27.26 13.12 29.07 14.93L24 20H36V8L31.9 12.1C29.37 9.57 25.87 8 22 8C14.95 8 9.13 13.22 8.16 20H12.2C13.13 15.44 17.16 12 22 12ZM33.28 30.27C34.61 28.46 35.51 26.32 35.84 24H31.8C30.87 28.56 26.84 32 22 32C19.24 32 16.74 30.88 14.93 29.07L20 24H8V36L12.1 31.9C14.63 34.43 18.13 36 22 36C25.1 36 27.96 34.98 30.28 33.27L40 42.98L42.98 40L33.28 30.27Z" />
    </svg>
  );
};

export { FindReplaceIcon };
