import cn from 'classnames';

const AndroidIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Android', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_6_10750)">
        <path d="M12 36.0025C12 37.1025 12.9 38.0025 14 38.0025H16V45.0025C16 46.6625 17.34 48.0025 19 48.0025C20.66 48.0025 22 46.6625 22 45.0025V38.0025H26V45.0025C26 46.6625 27.34 48.0025 29 48.0025C30.66 48.0025 32 46.6625 32 45.0025V38.0025H34C35.1 38.0025 36 37.1025 36 36.0025V16.0025H12V36.0025ZM7 16.0025C5.34 16.0025 4 17.3425 4 19.0025V33.0025C4 34.6625 5.34 36.0025 7 36.0025C8.66 36.0025 10 34.6625 10 33.0025V19.0025C10 17.3425 8.66 16.0025 7 16.0025ZM41 16.0025C39.34 16.0025 38 17.3425 38 19.0025V33.0025C38 34.6625 39.34 36.0025 41 36.0025C42.66 36.0025 44 34.6625 44 33.0025V19.0025C44 17.3425 42.66 16.0025 41 16.0025ZM31.06 4.3225L33.67 1.7125C34.06 1.3225 34.06 0.6925 33.67 0.3025C33.28 -0.0875 32.65 -0.0875 32.26 0.3025L29.3 3.2525C27.7 2.4625 25.91 2.0025 24 2.0025C22.08 2.0025 20.28 2.4625 18.67 3.2625L15.7 0.2925C15.31 -0.0975 14.68 -0.0975 14.29 0.2925C13.9 0.6825 13.9 1.3125 14.29 1.7025L16.91 4.3225C13.94 6.5125 12 10.0325 12 14.0025H36C36 10.0225 34.05 6.5025 31.06 4.3225ZM20 10.0025H18V8.0025H20V10.0025ZM30 10.0025H28V8.0025H30V10.0025Z" />
      </g>
      <defs>
        <clipPath id="clip0_6_10750">
          <rect width="48" height="48" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { AndroidIcon };
