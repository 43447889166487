import { Button } from '@latitude/button';

import { Text } from '@anchorage/common/dist/components';
import {
  Modal,
  Body as ModalBody,
  CloseButton as ModalCloseButton,
  Footer as ModalFooter,
  Header as ModalHeader,
} from '@anchorage/common/dist/components/ModalV2';

import css from './styles.module.scss';

type Props = {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
};

const DepositAttributionsSpamModal = ({
  isLoading,
  isOpen,
  onClose,
  onSave,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      allowBackdropClose
      allowEscapeClose
    >
      <ModalHeader>
        Flag deposit as spam?
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody className={css.modalBody}>
        <Text>
          {`You're about to flag this deposit as spam. This means that you don't
          recognize it, and the funds won't be received. Are you sure?`}
        </Text>
        <Text className={css.mailTo}>
          {`If you're not sure how to proceed, `}
          <Text<'a'>
            type="link"
            tag="a"
            href="mailto:accountexecutive@anchorage.com "
            target="_blank"
            rel="noopener noreferrer"
          >
            contact us
          </Text>
          .
        </Text>
      </ModalBody>
      <ModalFooter className={css.modalFooter}>
        <Button type="ghost" onClick={onClose}>
          Cancel
        </Button>
        <Button loading={isLoading} variant="danger" onClick={onSave}>
          Yes, flag as spam
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DepositAttributionsSpamModal;
