import {
  UIDeveloperError,
  reportError,
} from '@anchorage/common/dist/utils/errors';

import {
  DepositAttributionStatus,
  DepositEventOperation,
  OperationDrawer,
  OperationState,
} from 'generated/graphql';

export enum StatusBadge {
  default,
  failure,
  success,
  processing,
}

export const getOperationStatusColor = (
  operationState: OperationState,
): keyof typeof StatusBadge => {
  switch (operationState) {
    case OperationState.COMPLETE:
      return 'success';

    case OperationState.FAILURE_COMMITTED:
    case OperationState.EXPIRED:
    case OperationState.RISK_REJECTED:
    case OperationState.SIGS_FAILED:
    case OperationState.INITIATOR_REJECTED:
    case OperationState.INITIATOR_EXPIRED:
    case OperationState.ERRORED:
      return 'failure';

    case OperationState.RISK_PENDING:
    case OperationState.RISK_RECON:
    case OperationState.ORG_RISK_RECON:
    case OperationState.SIGS_REQUIRED:
      return 'processing';

    case OperationState.ADDRESS_AVAILABLE:
    case OperationState.ADDRESS_REQUEST:
    case OperationState.BROADCASTING:
    case OperationState.INITIATOR_REQUIRED:
    case OperationState.INITIATOR_PREPARED:
    case OperationState.TRANSACTION_REQUEST:
    case OperationState.ORGANIZATION_CHANGE_REQUEST:
    case OperationState.TRANSACTION_SIGNING:
    case OperationState.ORGANIZATION_SIGNING:
    case OperationState.ORG_CREATION_SIGNING:
    case OperationState.DEPOSIT_EVENT_DETECTED:
      return 'default';

    default:
      reportError(
        new UIDeveloperError(
          `Unhandled operationState "${operationState}" in operation header`,
        ),
      );
      return 'default';
  }
};

export const operationStatusNote = (
  operation: OperationDrawer.operation | DepositEventOperation,
) => {
  // All attributions have the same status and type
  const attribution = (operation as DepositEventOperation).attributions?.[0];
  const status = attribution?.attributionStatus;
  const type = attribution?.attributionType;

  if (status === DepositAttributionStatus.NON_ATTRIBUTABLE) {
    return 'Not attributable';
  } else if (status === DepositAttributionStatus.INITIATED) {
    return 'Attempting auto attribution';
  } else if (status === DepositAttributionStatus.PENDING) {
    return 'Pending attribution';
  } else if (status === DepositAttributionStatus.UNDER_REVIEW) {
    return 'Reviewing attribution';
  } else if (status === DepositAttributionStatus.BLOCKED) {
    return 'Funds blocked';
  } else if (
    status === DepositAttributionStatus.ATTRIBUTED &&
    type?.includes('SPAM')
  ) {
    return 'Flagged as spam';
  }

  return '';
};
