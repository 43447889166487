import cn from 'classnames';

const DashboardIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Dashboard', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M25.5 18V7.5C25.5 7.075 25.6438 6.71875 25.9312 6.43125C26.2188 6.14375 26.575 6 27 6H40.5C40.925 6 41.2812 6.14375 41.5688 6.43125C41.8563 6.71875 42 7.075 42 7.5V18C42 18.425 41.8563 18.7812 41.5688 19.0688C41.2812 19.3562 40.925 19.5 40.5 19.5H27C26.575 19.5 26.2188 19.3562 25.9312 19.0688C25.6438 18.7812 25.5 18.425 25.5 18ZM6 24V7.5C6 7.075 6.14375 6.71875 6.43125 6.43125C6.71875 6.14375 7.075 6 7.5 6H21C21.425 6 21.7812 6.14375 22.0688 6.43125C22.3563 6.71875 22.5 7.075 22.5 7.5V24C22.5 24.425 22.3563 24.7812 22.0688 25.0688C21.7812 25.3563 21.425 25.5 21 25.5H7.5C7.075 25.5 6.71875 25.3563 6.43125 25.0688C6.14375 24.7812 6 24.425 6 24ZM25.5 40.5V24C25.5 23.575 25.6438 23.2188 25.9312 22.9312C26.2188 22.6438 26.575 22.5 27 22.5H40.5C40.925 22.5 41.2812 22.6438 41.5688 22.9312C41.8563 23.2188 42 23.575 42 24V40.5C42 40.925 41.8563 41.2812 41.5688 41.5688C41.2812 41.8563 40.925 42 40.5 42H27C26.575 42 26.2188 41.8563 25.9312 41.5688C25.6438 41.2812 25.5 40.925 25.5 40.5ZM6 40.5V30C6 29.575 6.14375 29.2188 6.43125 28.9312C6.71875 28.6438 7.075 28.5 7.5 28.5H21C21.425 28.5 21.7812 28.6438 22.0688 28.9312C22.3563 29.2188 22.5 29.575 22.5 30V40.5C22.5 40.925 22.3563 41.2812 22.0688 41.5688C21.7812 41.8563 21.425 42 21 42H7.5C7.075 42 6.71875 41.8563 6.43125 41.5688C6.14375 41.2812 6 40.925 6 40.5ZM9 22.5H19.5V9H9V22.5ZM28.5 39H39V25.5H28.5V39ZM28.5 16.5H39V9H28.5V16.5ZM9 39H19.5V31.5H9V39Z" />
    </svg>
  );
};

export { DashboardIcon };
