import cn from 'classnames';

const FormatListNumberedIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatListNumbered', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M4 34H8V35H6V37H8V38H4V40H10V32H4V34ZM6 16H8V8H4V10H6V16ZM4 22H7.6L4 26.2V28H10V26H6.4L10 21.8V20H4V22ZM14 10V14H42V10H14ZM14 38H42V34H14V38ZM14 26H42V22H14V26Z" />
    </svg>
  );
};

export { FormatListNumberedIcon };
