import cn from 'classnames';

const AirlineSeatLegroomExtraIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AirlineSeatLegroomExtra', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M8 24V6H4V24C4 29.52 8.48 34 14 34H26V30H14C10.69 30 8 27.31 8 24ZM45.66 34.47C44.91 33.03 43.08 32.53 41.6 33.21L39.41 34.21L32.58 20.26C31.91 18.89 30.52 18.02 29 18.02L22 18V6H10V22C10 25.31 12.69 28 16 28H30L36.82 42L44.25 38.59C45.8 37.88 46.46 36.01 45.66 34.47Z" />
    </svg>
  );
};

export { AirlineSeatLegroomExtraIcon };
