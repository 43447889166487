import cn from 'classnames';

const KeyboardHideIcon = (props: SVGProps): JSX.Element => {
  const { title = 'KeyboardHide', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 6H8C5.79 6 4.02 7.79 4.02 10L4 30C4 32.21 5.79 34 8 34H40C42.21 34 44 32.21 44 30V10C44 7.79 42.21 6 40 6ZM22 12H26V16H22V12ZM22 18H26V22H22V18ZM16 12H20V16H16V12ZM16 18H20V22H16V18ZM14 22H10V18H14V22ZM14 16H10V12H14V16ZM32 30H16V26H32V30ZM32 22H28V18H32V22ZM32 16H28V12H32V16ZM38 22H34V18H38V22ZM38 16H34V12H38V16ZM24 46L32 38H16L24 46Z" />
    </svg>
  );
};

export { KeyboardHideIcon };
