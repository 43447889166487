import cn from 'classnames';

const DockIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Dock', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M16 46H32V42H16V46ZM32 2.02L16 2C13.79 2 12 3.79 12 6V34C12 36.21 13.79 38 16 38H32C34.21 38 36 36.21 36 34V6C36 3.79 34.21 2.02 32 2.02ZM32 30H16V10H32V30Z" />
    </svg>
  );
};

export { DockIcon };
