import cn from 'classnames';

const KeyboardArrowRightIcon = (props: SVGProps): JSX.Element => {
  const { title = 'KeyboardArrowRight', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M17.1699 32.92L26.3399 23.75L17.1699 14.58L19.9999 11.75L31.9999 23.75L19.9999 35.75L17.1699 32.92Z" />
    </svg>
  );
};

export { KeyboardArrowRightIcon };
