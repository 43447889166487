import cn from 'classnames';

const DirectionsBusIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DirectionsBus', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M8 32C8 33.77 8.78 35.34 10 36.44V40C10 41.1 10.9 42 12 42H14C15.11 42 16 41.1 16 40V38H32V40C32 41.1 32.89 42 34 42H36C37.1 42 38 41.1 38 40V36.44C39.22 35.34 40 33.77 40 32V12C40 5 32.84 4 24 4C15.16 4 8 5 8 12V32ZM15 34C13.34 34 12 32.66 12 31C12 29.34 13.34 28 15 28C16.66 28 18 29.34 18 31C18 32.66 16.66 34 15 34ZM33 34C31.34 34 30 32.66 30 31C30 29.34 31.34 28 33 28C34.66 28 36 29.34 36 31C36 32.66 34.66 34 33 34ZM36 22H12V12H36V22Z" />
    </svg>
  );
};

export { DirectionsBusIcon };
