import cn from 'classnames';

const DateRangeIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DateRange', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18 22H14V26H18V22ZM26 22H22V26H26V22ZM34 22H30V26H34V22ZM38 8H36V4H32V8H16V4H12V8H10C7.78 8 6.02 9.8 6.02 12L6 40C6 42.2 7.78 44 10 44H38C40.2 44 42 42.2 42 40V12C42 9.8 40.2 8 38 8ZM38 40H10V18H38V40Z" />
    </svg>
  );
};

export { DateRangeIcon };
