import cn from 'classnames';

const CircleConfirmIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CircleConfirm', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M28 37.1717L21.4142 30.586L18.5858 33.4144L25.1716 40.0002C26.7337 41.5623 29.2663 41.5623 30.8284 40.0002L45.4142 25.4144L42.5858 22.586L28 37.1717Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 8C18.7452 8 8 18.7452 8 32C8 45.2548 18.7452 56 32 56C45.2548 56 56 45.2548 56 32C56 18.7452 45.2548 8 32 8ZM12 32C12 20.9543 20.9543 12 32 12C43.0457 12 52 20.9543 52 32C52 43.0457 43.0457 52 32 52C20.9543 52 12 43.0457 12 32Z"
      />
    </svg>
  );
};

export { CircleConfirmIcon };
