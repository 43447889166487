import classnames from 'classnames';
import * as React from 'react';

import { Button, Text, Tooltip } from '@anchorage/common/dist/components';
import { CopyIcon } from '@anchorage/common/dist/components/Icons';
import { useSnackbar } from '@anchorage/common/dist/components/Snackbar';
import { Position } from '@anchorage/common/dist/components/Tooltip/_types';
import { useCopyToClipboard } from '@anchorage/common/dist/hooks';

import css from './styles.module.scss';

type Props = React.ComponentPropsWithoutRef<'div'> & {
  successText?: string;
  textToCopy: string | number | null;
  tooltipPosition?: Position;
  iconClassName?: string;
};

const CopyToClipboard = ({
  children,
  // eslint-disable-next-line react/prop-types
  className = '',
  successText = 'Copied to clipboard!',
  textToCopy,
  tooltipPosition = 'bottom',
  iconClassName = '',
  ...restProps
}: Props) => {
  const { addSnackbar } = useSnackbar();
  const [copyToClipboard] = useCopyToClipboard(700);
  const classes = classnames({
    [css.copyWrapper!]: true,
    [className]: Boolean(className),
  });

  const iconClasses = classnames({
    [css.icon!]: true,
    [iconClassName]: Boolean(iconClassName),
  });

  const onCopy = (e: React.MouseEvent) => {
    e.stopPropagation();

    copyToClipboard(`${textToCopy}`);
    addSnackbar({
      type: 'success',
      text: successText,
      duration: 1000,
      'data-testid': 'copy-snackbar',
    });
  };

  return (
    <div className={classes} {...restProps}>
      <Tooltip
        background="light"
        position={tooltipPosition}
        title="Copy to clipboard"
      >
        {children ? (
          <Text onClick={onCopy} size="medium" type="body" className={css.text}>
            <>{children}</>
          </Text>
        ) : (
          <Button
            Icon={CopyIcon}
            onClick={onCopy}
            type="tertiary"
            className={iconClasses}
          />
        )}
      </Tooltip>
    </div>
  );
};

export default CopyToClipboard;
