import cn from 'classnames';

const BusinessCenterIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BusinessCenter', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M20 32V30H6.02001L6.00001 38C6.00001 40.22 7.78001 42 10 42H38C40.22 42 42 40.22 42 38V30H28V32H20ZM40 14H31.98V10L27.98 6H19.98L16 10V14H8.01001C5.80001 14 4.01001 15.79 4.01001 18V24C4.01001 26.21 5.80001 28 8.01001 28H20.01V24H28.01V28H40.01C42.22 28 44.01 26.21 44.01 24V18C44 15.79 42.21 14 40 14ZM27.99 14H19.99V10H27.99V14Z" />
    </svg>
  );
};

export { BusinessCenterIcon };
