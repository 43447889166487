import type { ApolloError } from '@apollo/client';
import { useRouter } from 'next/router';
import { createContext, useContext } from 'react';

import { UIDeveloperError } from '@anchorage/common/dist/utils/errors';

import { LOGIN_PATH } from '../../utils/routes';
import useIsPortoApp from '../../utils/useIsPortoApp';

import {
  AppUserQuery,
  UserType,
  useAppUserQuery,
} from '../../generated/graphql';

import { ORG_OPERATOR_TYPES } from '../../constants/app';

export type { AppUserQuery };

export type AppUser = AppUserQuery & {
  isAdmin: boolean;
  isPortoOrg: boolean;
  isLoading: boolean;
  error: ApolloError | undefined;
};

export const AppUserContext = createContext<AppUser | Record<string, never>>(
  {},
);

export const AppUser = ({ children }: { children: JSX.Element }) => {
  const { pathname } = useRouter();

  const skipForLoginPage = pathname === LOGIN_PATH;

  const {
    data,
    loading: isLoading,
    error,
  } = useAppUserQuery({
    skip: skipForLoginPage,
  });
  const isAdmin: boolean = data?.currentUser.userType === UserType.ADMIN;
  const isPortoApp = useIsPortoApp();

  return (
    <AppUserContext.Provider
      value={
        data
          ? {
              ...data,
              isAdmin,
              isPortoOrg:
                isPortoApp ||
                data.organization.operator ===
                  (ORG_OPERATOR_TYPES.SELF as string),
              isLoading,
              error,
            }
          : {}
      }
    >
      {children}
    </AppUserContext.Provider>
  );
};

export const useAppUser = () => {
  const appUser = useContext(AppUserContext);

  if (!appUser) {
    throw new UIDeveloperError(
      'You must wrap your components in a AppUser provider',
    );
  }

  return appUser;
};
