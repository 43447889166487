import cn from 'classnames';

const Looks6Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Looks6', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M22 30H26V26H22V30ZM38 6H10C7.79 6 6 7.79 6 10V38C6 40.21 7.79 42 10 42H38C40.21 42 42 40.21 42 38V10C42 7.79 40.21 6 38 6ZM30 18H22V22H26C28.21 22 30 23.79 30 26V30C30 32.21 28.21 34 26 34H22C19.79 34 18 32.21 18 30V18C18 15.79 19.79 14 22 14H30V18Z" />
    </svg>
  );
};

export { Looks6Icon };
