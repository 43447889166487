// This import needs to happen before any component.
// It contains tailwind's normalize wich will override
// component's behaviour if imported after them.
import 'styles/globals.scss';

import { Toaster, ToasterProvider } from '@latitude/toast';
import { TooltipProvider } from '@latitude/tooltip';
import NextAdapterPages from 'next-query-params/pages';
import type { AppProps } from 'next/app';
import { Inter, Roboto_Mono } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { QueryParamProvider } from 'use-query-params';

import { NonceProvider } from '@anchorage/common/dist/components/SelectV2';
import { SnackbarProvider } from '@anchorage/common/dist/components/Snackbar';
import { reportError } from '@anchorage/common/dist/utils/errors';
import FadingTransition from '@anchorage/frontoffice/components/FadingTransition';
import { AppSharedComponents } from '@anchorage/frontoffice/contexts/AppSharedComponents';
import { AppUser } from '@anchorage/frontoffice/contexts/AppUser';
import useIsPortoApp from '@anchorage/frontoffice/utils/useIsPortoApp';
import usePortoTheme from '@anchorage/frontoffice/utils/usePortoTheme';
import { LocalStorageProvider } from '@anchorage/hooks';

import ApolloClientProvider from 'components/ApolloClientProvider';
import { AppModals } from 'components/AppModals';
import NoSideNavLayout from 'components/layouts/App/NoSideNavLayout';
import SideNavLayout from 'components/layouts/App/SideNavLayout';

// Load the error reporter and initialize it
import type { SentryEnvironments } from 'utils/sentry';
import initSentry from 'utils/sentry';

import { CSP } from './_document';

export const NO_SIDE_NAV_ROUTES = ['/login', '/verify-email'];

interface EnvType {
  SENTRY_ENVIRONMENT?: SentryEnvironments;
  SENTRY_DSN?: string;
  SENTRY_RELEASE?: string;
}

type AppWindow = Window & {
  env?: EnvType;
};

// https://nextjs.org/docs/app/api-reference/components/font#css-variables
// Levering next/font to avoid CSP issues when downloading google fonts
const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
  preload: true,
  adjustFontFallback: false,
});
const roboto = Roboto_Mono({
  subsets: ['latin'],
  display: 'swap',
  preload: true,
  adjustFontFallback: false,
});

function MyApp({ Component, pageProps }: AppProps) {
  // Use the layout defined at the page level, if available
  // @ts-ignore TODO Luis use types from source/js/anchorage/apps/backoffice/src/types.ts
  const getLayout = Component.getLayout || ((page) => page);

  const isPorto = useIsPortoApp();
  usePortoTheme({
    active: isPorto,
  });

  const appWindow =
    typeof window !== 'undefined' ? (window as AppWindow) : null;
  const appWindowEnv = appWindow && appWindow.env ? appWindow.env : null;

  const [skipSSG, setSkipSSG] = useState(true);
  const [sentryInitialized, setSentryInitialized] = useState(false);
  const { basePath, pathname } = useRouter();
  const SelectedSideNav = NO_SIDE_NAV_ROUTES.some((route) =>
    pathname.includes(route),
  )
    ? NoSideNavLayout
    : SideNavLayout;

  useEffect(() => {
    if (sentryInitialized || !appWindowEnv) return;

    const sentryDsn = appWindowEnv.SENTRY_DSN;
    const sentryRelease = appWindowEnv.SENTRY_RELEASE;
    const sentryEnvironment = appWindowEnv.SENTRY_ENVIRONMENT;

    initSentry({
      dsn: sentryDsn,
      release: sentryRelease,
      environment: sentryEnvironment,
    });

    setSentryInitialized(true);
  }, [sentryInitialized, appWindowEnv]);

  useEffect(() => {
    setSkipSSG(false);
  }, [skipSSG]);

  const faviconPath = useMemo(() => {
    return `${basePath}/${isPorto ? 'portoFavicon.ico' : 'favicon.ico'}`;
  }, [isPorto, basePath]);

  //* Set the CSS variables for font families
  // Using useEffect ensures styles are set dynamically after mount, allows reactivity,
  // prevents SSR issues, provides control over timing, and improves performance.
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--font-inter',
      inter.style.fontFamily,
    );
    document.documentElement.style.setProperty(
      '--font-roboto-mono',
      roboto.style.fontFamily,
    );
  }, []);

  return (
    <>
      <NonceProvider nonce={CSP.nonce} cacheKey="anchor-client-dash">
        <LocalStorageProvider errorHandler={reportError}>
          <Head>
            <link id="favicon" rel="shortcut icon" href={faviconPath} />
          </Head>
          <ApolloClientProvider>
            <AppSharedComponents>
              <SnackbarProvider>
                <ToasterProvider>
                  <TooltipProvider delayDuration={300} skipDelayDuration={500}>
                    <QueryParamProvider adapter={NextAdapterPages}>
                      <AppUser>
                        <SelectedSideNav>
                          <FadingTransition>
                            {!skipSSG && (
                              <>
                                {getLayout(<Component {...pageProps} />)}
                                <AppModals />
                                <Toaster />
                              </>
                            )}
                          </FadingTransition>
                        </SelectedSideNav>
                      </AppUser>
                    </QueryParamProvider>
                  </TooltipProvider>
                </ToasterProvider>
              </SnackbarProvider>
            </AppSharedComponents>
          </ApolloClientProvider>
        </LocalStorageProvider>
      </NonceProvider>
    </>
  );
}

export default MyApp;
