import cn from 'classnames';

const BorderClearIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BorderClear', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M14 10H18V6H14V10ZM14 26H18V22H14V26ZM14 42H18V38H14V42ZM22 34H26V30H22V34ZM22 42H26V38H22V42ZM6 42H10V38H6V42ZM6 34H10V30H6V34ZM6 26H10V22H6V26ZM6 18H10V14H6V18ZM6 10H10V6H6V10ZM22 26H26V22H22V26ZM38 34H42V30H38V34ZM38 26H42V22H38V26ZM38 42H42V38H38V42ZM38 18H42V14H38V18ZM22 18H26V14H22V18ZM38 6V10H42V6H38ZM22 10H26V6H22V10ZM30 42H34V38H30V42ZM30 26H34V22H30V26ZM30 10H34V6H30V10Z" />
    </svg>
  );
};

export { BorderClearIcon };
