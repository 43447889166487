import { transformAddresses } from '../../../../utils/mapAddressToWallets';

import type { OperationDrawer } from 'generated/graphql';

import AddressInfo from '../../../widgets/AddressInfo';
import AmountAndFees from '../../../widgets/AmountAndFees';
import DisclosureSection from '../../../widgets/DisclosureSection';
import VoteFor from '../../../widgets/VoteFor';

type VoteOperationType = Extract<
  OperationDrawer.operation,
  { __typename: 'VoteOperation' }
>;

type Props = {
  operation: VoteOperationType;
};

const VoteOperation = ({ operation }: Props) => {
  const {
    asset: {
      assetTypeInfo: { assetTypeID },
      vault,
    },
    account,
    finalFee,
    operationInfo: { displayTitle },
    votingPower,
    sendingBlockchainAddresses,
  } = operation;

  return (
    <>
      <VoteFor operation={operation} />
      <AddressInfo
        addressSectionTitle={
          displayTitle.toLowerCase().includes('withdraw')
            ? 'Withdraw vote from'
            : 'Vote from'
        }
        addresses={transformAddresses(sendingBlockchainAddresses)}
        vaultName={vault.name}
        accountName={account?.displayName}
      />
      <AmountAndFees
        amount={votingPower}
        amountTitle="Vote amount"
        fee={finalFee}
      />
      <DisclosureSection assetTypeID={assetTypeID} />
    </>
  );
};

export default VoteOperation;
