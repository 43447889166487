import cn from 'classnames';

const ColorizeIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Colorize', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M41.41 11.26L36.74 6.59C35.96 5.81 34.69 5.81 33.91 6.59L27.66 12.84L23.83 9L21 11.83L23.84 14.67L6 32.5V42H15.5L33.34 24.16L36.17 27L39 24.17L35.16 20.33L41.41 14.08C42.2 13.3 42.2 12.04 41.41 11.26ZM13.84 38L10 34.16L26.13 18.03L29.97 21.87L13.84 38Z" />
    </svg>
  );
};

export { ColorizeIcon };
