import { COPYRIGHT } from 'constants/compliance';

import {
  AnchorageDigitalLogoIcon,
  DesktopIcon,
  PortoLogoIcon,
} from '@anchorage/common/dist/components/Icons';
import DisclosureText from '@anchorage/frontoffice/components/DisclosureText';
import useIsPortoApp from '@anchorage/frontoffice/utils/useIsPortoApp';

import css from './styles.module.scss';

import { BODY_TEXT_ADB, BODY_TEXT_PORTO, TITLE } from './constants';

const MobilePage = () => {
  const isPorto = useIsPortoApp();
  return (
    <div className={css.mobilePage}>
      <div className={css.container}>
        <header className={css.header}>
          {isPorto ? (
            <PortoLogoIcon className={css.logo} />
          ) : (
            <AnchorageDigitalLogoIcon className={css.logo} />
          )}
        </header>
        <DesktopIcon />
        <div className={css.title}>{TITLE}</div>
        <div className={css.body}>
          {isPorto ? BODY_TEXT_PORTO : BODY_TEXT_ADB}
        </div>
      </div>
      <DisclosureText testId="login-page-disclosure" className={css.disclosure}>
        {COPYRIGHT}
      </DisclosureText>
    </div>
  );
};

export default MobilePage;
