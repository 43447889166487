import * as React from 'react';

import type { AnswersGroupedByQuestions } from './types';

import SectionRow from '../SectionRow';

type Props = React.ComponentPropsWithoutRef<'div'> & {
  amlGroupedAnswers: AnswersGroupedByQuestions[];
};

const AMLSection = ({ amlGroupedAnswers, ...restProps }: Props) => {
  return (
    <div {...restProps}>
      {amlGroupedAnswers.map(
        ({ currentAnswerText, questionTitle, group, questionId }) => (
          <React.Fragment key={questionId}>
            <SectionRow title={questionTitle}>{currentAnswerText}</SectionRow>
            {group?.map((groupItem) => (
              <SectionRow
                key={groupItem.questionTitle}
                title={groupItem.questionTitle}
              >
                {groupItem.currentAnswerText}
              </SectionRow>
            ))}
          </React.Fragment>
        ),
      )}
    </div>
  );
};

export default AMLSection;
