import {
  TRUSTED_SOURCE_WALLET_TYPE_LABEL,
  getDepositAttributionLabel,
} from 'constants/operations';
import React, { useCallback } from 'react';

import { Text } from '@anchorage/common/dist/components';

import useStaticDataContext from 'hooks/useStaticDataContext';

import {
  type DepositAttribution,
  DepositAttributionStatus,
} from 'generated/graphql';

import css from './styles.module.scss';

import SectionRow from '../../shared/SectionRow';
import WidgetWrapper from '../../shared/WidgetWrapper';

const Attribution = ({
  attribution,
}: {
  attribution: DepositAttribution.attributions;
}) => {
  const { countries } = useStaticDataContext();

  const attributionCountryName = useCallback(
    (attributionCountryCode?: string) =>
      countries.find((country) => country.code === attributionCountryCode)
        ?.name || attributionCountryCode,
    [countries],
  );

  if (
    !attribution ||
    attribution.attributionStatus === DepositAttributionStatus.PENDING ||
    attribution.attributionStatus === DepositAttributionStatus.INITIATED
  ) {
    return null;
  }

  const isSpam = attribution.attributionType?.includes('SPAM');
  const isNonAttributable =
    attribution.attributionStatus === DepositAttributionStatus.NON_ATTRIBUTABLE;

  return (
    <WidgetWrapper>
      <SectionRow title="Attribution" className={css.attributionType}>
        <Text size="small">
          {getDepositAttributionLabel({
            status: attribution.attributionStatus,
            type: attribution.attributionType!,
          }) || '-'}
        </Text>
      </SectionRow>
      {isSpam || isNonAttributable ? null : (
        <>
          <SectionRow title="Originator">
            <div
              key={`${attribution.originator?.originatorName}-${attribution.originator?.originatorCountry}`}
            >
              <Text size="small">{attribution.originator?.originatorName}</Text>
              <Text size="small" className={css.attributionCountry}>
                {attributionCountryName(
                  attribution.originator?.originatorCountry,
                )}
              </Text>
            </div>
          </SectionRow>
          {attribution.walletType ? (
            <SectionRow title="Wallet type">
              <Text size="small">
                {
                  TRUSTED_SOURCE_WALLET_TYPE_LABEL[
                    attribution.walletType as keyof typeof TRUSTED_SOURCE_WALLET_TYPE_LABEL
                  ]
                }
              </Text>
            </SectionRow>
          ) : null}
        </>
      )}
    </WidgetWrapper>
  );
};

export default Attribution;
