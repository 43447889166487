import Text from '@latitude/text';

interface ErrorMessageProps {
  title: string;
  subtitle: string;
}

export default function ErrorMessage({ title, subtitle }: ErrorMessageProps) {
  return (
    <div>
      <Text>{title}</Text>
      <Text variant="captionRegular">{subtitle}</Text>
    </div>
  );
}
