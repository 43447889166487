import useStaticDataContext from 'hooks/useStaticDataContext';

import { AssetTypeID } from 'generated/graphql';

interface Props {
  assetTypeID: AssetTypeID | string;
}

// Get standardized staking support status from static asset data
const useStaticAssetStandardizedStakingSupport = ({ assetTypeID }: Props) => {
  const { assetInfoMap } = useStaticDataContext();

  const assetTypeInfo = assetInfoMap[assetTypeID];
  const staticAssetStakingSupport = assetTypeInfo?.standardizedStakingInfo;

  return { staticAssetStakingSupport, assetTypeInfo };
};

export default useStaticAssetStandardizedStakingSupport;
