import cn from 'classnames';

const AlbumIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Album', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 4C12.95 4 4 12.95 4 24C4 35.05 12.95 44 24 44C35.05 44 44 35.05 44 24C44 12.95 35.05 4 24 4ZM24 33C19.03 33 15 28.97 15 24C15 19.03 19.03 15 24 15C28.97 15 33 19.03 33 24C33 28.97 28.97 33 24 33ZM24 22C22.9 22 22 22.9 22 24C22 25.1 22.9 26 24 26C25.1 26 26 25.1 26 24C26 22.9 25.1 22 24 22Z" />
    </svg>
  );
};

export { AlbumIcon };
