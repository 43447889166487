import cn from 'classnames';
import * as React from 'react';

import css from './styles.module.scss';

type Props = React.ComponentPropsWithoutRef<'div'> & {
  Icon?: React.ElementType;
};

const ErrorWrapper = ({ Icon, className, children }: Props) => {
  return (
    <div className={cn([css.root, className])}>
      {Icon && <Icon className={css.icon} />}
      {children}
    </div>
  );
};

export default ErrorWrapper;
