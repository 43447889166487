import cn from 'classnames';

const AddAlertIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AddAlert', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M20.02 41.02C20.02 43.22 21.8 45 24 45C26.2 45 27.98 43.22 27.98 41.02H20.02ZM37.76 32.64V21C37.76 14.5 33.26 9.06 27.18 7.62V6.18C27.18 4.42 25.76 3 24 3C22.24 3 20.82 4.42 20.82 6.18V7.62C14.74 9.06 10.24 14.5 10.24 21V32.64L6 36.88V39H42V36.88L37.76 32.64ZM32 25.01H26V31.01H22V25.01H16V21H22V15H26V21H32V25.01Z" />
    </svg>
  );
};

export { AddAlertIcon };
