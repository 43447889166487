import cn from 'classnames';

const FolderSharedIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FolderShared', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 12H24L20 8H8C5.79 8 4.02 9.79 4.02 12L4 36C4 38.21 5.79 40 8 40H40C42.21 40 44 38.21 44 36V16C44 13.79 42.21 12 40 12ZM30 18C32.21 18 34 19.79 34 22C34 24.21 32.21 26 30 26C27.79 26 26 24.21 26 22C26 19.79 27.79 18 30 18ZM38 34H22V32C22 29.33 27.33 28 30 28C32.67 28 38 29.33 38 32V34Z" />
    </svg>
  );
};

export { FolderSharedIcon };
