import cn from 'classnames';

import type { WireData } from 'generated/graphql';

import css from './styles.module.scss';

import Address from '../../shared/Address';
import SectionRow from '../../shared/SectionRow';
import WidgetWrapper from '../../shared/WidgetWrapper';

type WalletName = string;

type Props = {
  addresses: Record<string, WalletName[]>;
  addressSectionTitle?: string;
  blockchainExplorerURL?: string | null;
  isAnchorageVault?: boolean;
  isTrusted?: boolean;
  vaultName?: string;
  accountName?: string;
  walletName?: string;
  trustedCounterpartyName?: string;
  widgetWrapperClassName?: string;
  wireData?: WireData | null;
};

const AddressInfo = ({
  addressSectionTitle = 'From',
  addresses,
  blockchainExplorerURL,
  isAnchorageVault,
  isTrusted,
  vaultName,
  accountName,
  walletName,
  trustedCounterpartyName,
  widgetWrapperClassName,
  wireData,
}: Props) => {
  const classes = cn({
    [css.addressSection!]: vaultName || accountName,
  });

  return (
    <WidgetWrapper className={widgetWrapperClassName}>
      <SectionRow className={classes} title={addressSectionTitle}>
        {Object.keys(addresses).length === 0
          ? walletName
            ? walletName
            : wireData
              ? wireData.name
              : '-'
          : Object.entries(addresses).map(([address, wallets]) => (
              <Address
                key={address}
                address={address}
                isAnchorageVault={isAnchorageVault}
                isTrusted={isTrusted}
                destinationNames={wallets}
                blockchainExplorerURL={blockchainExplorerURL}
              />
            ))}
      </SectionRow>

      {vaultName ? <SectionRow title="Vault">{vaultName}</SectionRow> : null}
      {accountName ? (
        <SectionRow title="Account">{accountName}</SectionRow>
      ) : null}
      {trustedCounterpartyName ? (
        <SectionRow title="Trusted counterparty">
          {trustedCounterpartyName}
        </SectionRow>
      ) : null}
      {wireData ? (
        <>
          <SectionRow title="Originator Account">
            {wireData.bankName} ({wireData.accountNumber})
          </SectionRow>
          <SectionRow title="IMAD">{wireData.fedID}</SectionRow>
        </>
      ) : null}
    </WidgetWrapper>
  );
};

export default AddressInfo;
