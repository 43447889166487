import cn from 'classnames';

const BatteryAlertIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BatteryAlert', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_6_9034)">
        <path d="M31.33 8H28V4H20V8H16.67C15.19 8 14 9.19 14 10.67V41.34C14 42.81 15.19 44.01 16.67 44.01H31.34C32.81 44.01 34.01 42.82 34.01 41.34V10.67C34 9.19 32.81 8 31.33 8ZM26 36H22V32H26V36ZM26 28H22V18H26V28Z" />
      </g>
      <defs>
        <clipPath id="clip0_6_9034">
          <rect width="48" height="48" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { BatteryAlertIcon };
