import cn from 'classnames';

const BrightnessHighIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BrightnessHigh', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40.0001 17.37V8H30.6301L24.0001 1.37L17.3701 8H8.00012V17.37L1.37012 24L8.00012 30.63V40H17.3701L24.0001 46.63L30.6301 40H40.0001V30.63L46.6301 24L40.0001 17.37ZM24.0001 36C17.3701 36 12.0001 30.63 12.0001 24C12.0001 17.37 17.3701 12 24.0001 12C30.6301 12 36.0001 17.37 36.0001 24C36.0001 30.63 30.6301 36 24.0001 36ZM24.0001 16C19.5801 16 16.0001 19.58 16.0001 24C16.0001 28.42 19.5801 32 24.0001 32C28.4201 32 32.0001 28.42 32.0001 24C32.0001 19.58 28.4201 16 24.0001 16Z" />
    </svg>
  );
};

export { BrightnessHighIcon };
