import React from 'react';

import { Text } from '@anchorage/common/dist/components';
import { useFormContext } from '@anchorage/common/dist/components/Form/_reactHookForm';
import { FormRadioButtonGroup } from '@anchorage/common/dist/components/Form/components';

import css from './styles.module.scss';

import imageUrl from '../../../../images/pending-attributions.svg';

const DepositAttributionSurveyStep: React.FC = () => {
  const { control } = useFormContext();
  return (
    <>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={imageUrl.src}
        className={css.surveyImage}
        alt="pending attributions mobile"
      />
      <div className={css.surveyQuestionWrapper}>
        <Text className={css.surveyQuestion}>
          Would you be interested in attributing deposits in the Anchorage
          Digital iOS app in the future?
        </Text>
        <Text size="small" className={css.surveySubtext}>
          Your response will help us assess the demand for this as a potential
          mobile feature.
        </Text>
        <FormRadioButtonGroup
          control={control}
          name="attributeInApp"
          rules={{ required: true }}
          options={[
            {
              label: 'Yes',
              value: 'yes',
            },
            {
              label: 'No',
              value: 'no',
            },
          ]}
        />
      </div>
    </>
  );
};

export default DepositAttributionSurveyStep;
