import cn from 'classnames';

const ChildFriendlyIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ChildFriendly', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M26 4V20H42C42 11.16 34.84 4 26 4ZM38.65 31.78C40.74 29.07 42 25.69 42 22H12.88L10.98 18H4V22H8.45C8.45 22 12.23 30.14 12.69 30.83C10.49 32.02 9 34.33 9 37C9 40.87 12.13 44 16 44C19.53 44 22.43 41.39 22.92 38H27.08C27.57 41.39 30.47 44 34 44C37.87 44 41 40.87 41 37C41 34.92 40.09 33.06 38.65 31.78ZM16 40C14.34 40 13 38.66 13 37C13 35.34 14.34 34 16 34C17.66 34 19 35.34 19 37C19 38.66 17.66 40 16 40ZM34 40C32.34 40 31 38.66 31 37C31 35.34 32.34 34 34 34C35.66 34 37 35.34 37 37C37 38.66 35.66 40 34 40Z" />
    </svg>
  );
};

export { ChildFriendlyIcon };
