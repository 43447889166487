import { IAMPermissionAction, VaultDetails } from 'generated/graphql';

interface Props {
  vaultIam: VaultDetails.iam | null | undefined;
  permissions: IAMPermissionAction[];
}

const hasVaultLevelPermissions = ({ vaultIam, permissions }: Props) => {
  return permissions.map((permission) => {
    return hasVaultLevelPermission(vaultIam, permission);
  });
};

const hasVaultLevelPermission = (
  vaultIam: VaultDetails.iam | null | undefined,
  permission: IAMPermissionAction,
) => {
  return (
    vaultIam?.permissions.edges
      ?.filter((edge) => edge && edge.node)
      .map((edge) => edge!.node!.action)
      .includes(permission) ?? false
  );
};

export default hasVaultLevelPermissions;
