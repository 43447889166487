import cn from 'classnames';

const CenterFocusWeakIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CenterFocusWeak', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M10 30H6V38C6 40.21 7.79 42 10 42H18V38H10V30ZM10 10H18V6H10C7.79 6 6 7.79 6 10V18H10V10ZM38 6H30V10H38V18H42V10C42 7.79 40.21 6 38 6ZM38 38H30V42H38C40.21 42 42 40.21 42 38V30H38V38ZM24 16C19.58 16 16 19.58 16 24C16 28.42 19.58 32 24 32C28.42 32 32 28.42 32 24C32 19.58 28.42 16 24 16ZM24 28C21.79 28 20 26.21 20 24C20 21.79 21.79 20 24 20C26.21 20 28 21.79 28 24C28 26.21 26.21 28 24 28Z" />
    </svg>
  );
};

export { CenterFocusWeakIcon };
