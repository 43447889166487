import cn from 'classnames';

const LayersIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Layers', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M23.99 37.08L9.24 25.61L6 28.13L24 42.13L42 28.13L38.74 25.6L23.99 37.08ZM24 32L38.73 20.55L42 18L24 4L6 18L9.26 20.53L24 32Z" />
    </svg>
  );
};

export { LayersIcon };
