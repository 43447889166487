import { Card } from '@latitude/card';
import { Switch } from '@latitude/switch';
import Text from '@latitude/text';
import { useState } from 'react';

import { getGraphQLQueryName } from '@anchorage/frontoffice/utils/getGraphQLQueryName';

import { AutoCompoundEnabledDocument } from 'generated/graphql';

import useStandardizedStakingContext from '../../StandardizedStakingContext/useStandardizedStakingContext';
import useUpdateAutoCompound from '../useUpdateAutoCompound';

const AutoCompoundToggleCard = () => {
  const { autoCompoundEnabled: currentAutoCompoundValue } =
    useStandardizedStakingContext();
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);

  const [
    updateAutoCompoundMutation,
    { loading: isLoadingAutoCompoundMutation },
  ] = useUpdateAutoCompound({
    showSnackbarNotifications: false,
    newAutoCompoundSettingValue: !currentAutoCompoundValue,
    analyticsEventName: 'standardized_staking:claim_rewards:toggled',
    onCompleted: () => {
      setShowErrorBanner(false);
    },
    onError: () => {
      setShowErrorBanner(true);
    },
  });

  return (
    <>
      <Card className="bg-color-ref-ui-surface-container py-4">
        <div className="flex justify-between">
          <div className="flex flex-col">
            <Text variant="bodyBold" className="text-color-ref-text-strong">
              Start auto-claim & stake rewards
            </Text>
            {showErrorBanner && (
              <Text className="text-color-ref-ui-status-danger">
                Error updating auto-claim & stake rewards. Please try again.
              </Text>
            )}
          </div>
          <Switch
            disabled={isLoadingAutoCompoundMutation}
            checked={currentAutoCompoundValue}
            onCheckedChange={() => {
              setShowErrorBanner(false);
              updateAutoCompoundMutation({
                awaitRefetchQueries: true,
                // override the default refetchQueries to avoid BE return autoCompoundFormStep as null
                // that will cause the AutoCompound step to not render in stake flow wizard
                refetchQueries: [
                  getGraphQLQueryName(AutoCompoundEnabledDocument),
                ],
              });
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default AutoCompoundToggleCard;
