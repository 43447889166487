import cn from 'classnames';

const GestureIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Gesture', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M9.18992 13.77C10.5899 12.34 11.9899 11.06 12.6199 11.32C13.6099 11.73 12.5999 13.4 12.0199 14.37C11.5199 15.21 6.29992 22.14 6.29992 26.99C6.29992 29.55 7.24992 31.67 8.98992 32.95C10.4899 34.06 12.4599 34.4 14.2699 33.87C16.4099 33.24 18.1699 31.08 20.3899 28.34C22.8099 25.36 26.0499 21.46 28.5499 21.46C31.8099 21.46 31.8499 23.48 32.0699 25.05C24.4899 26.35 21.2999 32.4 21.2999 35.81C21.2999 39.22 24.1799 42 27.7099 42C30.9599 42 36.2999 39.34 37.0899 29.8H41.9999V24.8H37.0599C36.7599 21.5 34.8799 16.41 28.9999 16.41C24.4999 16.41 20.6299 20.23 19.1299 22.1C17.9699 23.55 15.0199 27.05 14.5599 27.55C14.0499 28.14 13.2099 29.23 12.3299 29.23C11.4399 29.23 10.8999 27.56 11.5999 25.38C12.2999 23.19 14.3999 19.66 15.2999 18.35C16.8699 16.07 17.8899 14.5 17.8899 11.79C17.8899 7.39001 14.6099 6.01001 12.8699 6.01001C10.2299 6.01001 7.92992 8.01001 7.41992 8.52001C6.70992 9.24001 6.10992 9.83001 5.66992 10.37L9.18992 13.77ZM27.7699 37.11C27.1499 37.11 26.2999 36.59 26.2999 35.66C26.2999 34.46 27.7499 31.26 32.0499 30.13C31.4299 35.52 29.1699 37.11 27.7699 37.11Z" />
    </svg>
  );
};

export { GestureIcon };
