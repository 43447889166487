import cn from 'classnames';

const FirstPageIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FirstPage', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M36.82 33.18L27.64 24L36.82 14.82L34 12L22 24L34 36L36.82 33.18ZM12 12H16V36H12V12Z" />
    </svg>
  );
};

export { FirstPageIcon };
