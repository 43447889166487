import cn from 'classnames';

const GTranslateIcon = (props: SVGProps): JSX.Element => {
  const { title = 'GTranslate', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 10H21.76L20 4H8C5.8 4 4 5.8 4 8V34C4 36.2 5.8 38 8 38H22L24 44H40C42.2 44 44 42.2 44 40V14C44 11.8 42.2 10 40 10ZM14.33 29.17C9.82 29.17 6.16 25.5 6.16 21C6.16 16.5 9.83 12.83 14.33 12.83C16.41 12.83 18.3 13.57 19.8 14.96L19.93 15.09L17.49 17.45L17.37 17.34C16.8 16.8 15.81 16.17 14.33 16.17C11.71 16.17 9.58 18.34 9.58 21.01C9.58 23.68 11.71 25.85 14.33 25.85C17.07 25.85 18.26 24.1 18.58 22.93H14.16V19.83H22.06L22.09 19.97C22.17 20.39 22.2 20.76 22.2 21.18C22.19 25.89 18.96 29.17 14.33 29.17ZM26.4 25.77C27.07 26.97 27.88 28.12 28.78 29.17L27.71 30.23L26.4 25.77ZM27.94 24.23H25.96L25.35 22.15H33.34C33.34 22.15 32.66 24.78 30.22 27.62C29.15 26.39 28.41 25.19 27.94 24.23ZM42 40C42 41.1 41.1 42 40 42H26L30 38L28.37 32.47L30.21 30.63L35.58 36L37.04 34.54L31.63 29.17C33.43 27.1 34.83 24.67 35.46 22.16H38V20.08H30.73V18H28.65V20.08H24.73L22.35 12H40C41.1 12 42 12.9 42 14V40Z" />
    </svg>
  );
};

export { GTranslateIcon };
