export const SCOPE_DISPLAY_NAMES: { [S: string]: string } = {
  CREATE_VAULT: 'Create Vault',
  DEPOSIT: 'Deposit',
  ORGANIZATION_POLICY_CHANGE: 'Change Organization Policy',
  ROLE_CHANGE: 'Change Role',
  TRUSTED_DESTINATION_ADD: 'Add Trusted Destination',
  TRUSTED_DESTINATION_REMOVE: 'Remove Trusted Destination',
  USER_ADD: 'Add User',
  USER_REMOVE: 'Remove User',
  VAULT_POLICY_CHANGE: 'Change Vault Policy',
  WITHDRAW: 'Default',
  ORGANIZATION_POLICY_ROLE_BINDING_CHANGE: 'Change Organization Role Binding',
  ORGANIZATION_POLICY_FRAGMENT_BINDING_CHANGE:
    'Change Organization Fragment Binding',
  VAULT_POLICY_ROLE_BINDING_CHANGE: 'Change Vault Role Binding',
  VAULT_POLICY_FRAGMENT_BINDING_CHANGE: 'Change Vault Fragment Binding',
};
