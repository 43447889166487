import cn from 'classnames';

const Calendar = (props: SVGProps): JSX.Element => {
  const { title = 'Calendar', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M9 13.5V12H6V13.5H9Z" />
      <path d="M9 15.75V17.25H6V15.75H9Z" />
      <path d="M13.5 12H10.5V13.5H13.5V12Z" />
      <path d="M10.5 15.75H13.5V17.25H10.5V15.75Z" />
      <path d="M18 13.5V12H15V13.5H18Z" />
      <path d="M15 17.25V15.75H18V17.25H15Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 3H18.75C19.9926 3 21 4.00736 21 5.25V18.75C21 19.9926 19.9926 21 18.75 21H5.25C4.00736 21 3 19.9926 3 18.75V5.25C3 4.00736 4.00736 3 5.25 3H7.5V1.5H9V3H15V1.5H16.5V3ZM5.25 4.5H7.5V6H9V4.5H15V6H16.5V4.5H18.75C19.1642 4.5 19.5 4.83579 19.5 5.25V18.75C19.5 19.1642 19.1642 19.5 18.75 19.5H5.25C4.83579 19.5 4.5 19.1642 4.5 18.75V5.25C4.5 4.83579 4.83579 4.5 5.25 4.5Z"
      />
    </svg>
  );
};

export { Calendar };
