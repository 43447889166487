import cn from 'classnames';

const ClearAllIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ClearAll', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M10 26H38V22H10V26ZM6 34H34V30H6V34ZM14 14V18H42V14H14Z" />
    </svg>
  );
};

export { ClearAllIcon };
