import {
  fragmentHasOriginalBindingFilterFn,
  fragmentHasUpdatedBindingFilterFn,
  getPolicyFromPolicyUpdate,
  isFragmentOriginalBindingNotInternalFilterFn,
  isFragmentUpdatedBindingNotInternalFilterFn,
  policyMapHasOriginalPolicyFilterFn,
  policyMapOriginalPolicyisNotDepositFilterFn,
} from './utils';

import type {
  PolicyContainerUpdate as IPolicyContainerUpdate,
  Maybe,
} from '../generated/graphql';
import { PolicySourceOfTruth } from '../generated/graphql';

import type { BarePolicyUpdate } from '../types';

import { Policy } from './Policy';

export class PolicyContainerUpdate {
  readonly original: Policy[];
  readonly updated: Policy[];

  constructor(
    private sourceOfTruth: PolicySourceOfTruth,
    private policyUpdates?: Partial<BarePolicyUpdate>[],
    private policyContainerUpdates?: Maybe<IPolicyContainerUpdate>,
  ) {
    this.original = this.getOriginal() || [];
    this.updated = this.getUpdated() || [];
  }

  private getUpdated() {
    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER: {
        return this.policyContainerUpdates?.fragmentBindingUpdates
          ?.filter(fragmentHasUpdatedBindingFilterFn)
          .filter(isFragmentUpdatedBindingNotInternalFilterFn)
          .map(
            (fragment) =>
              new Policy(
                PolicySourceOfTruth.POLICY_SOT_CONTAINER,
                fragment.updatedBinding,
              ),
          );
      }
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
        return this.policyUpdates
          ?.filter(policyMapHasOriginalPolicyFilterFn)
          .filter(policyMapOriginalPolicyisNotDepositFilterFn)
          .map(
            (update) =>
              new Policy(
                PolicySourceOfTruth.POLICY_SOT_LEGACY,
                getPolicyFromPolicyUpdate(update),
              ),
          );
    }
  }

  private getOriginal() {
    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER: {
        return this.policyContainerUpdates?.fragmentBindingUpdates
          ?.filter(fragmentHasOriginalBindingFilterFn)
          .filter(isFragmentOriginalBindingNotInternalFilterFn)
          .map(
            (fragment) =>
              new Policy(
                PolicySourceOfTruth.POLICY_SOT_CONTAINER,
                fragment.originalBinding,
              ),
          );
      }
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
        return this.policyUpdates
          ?.filter(policyMapHasOriginalPolicyFilterFn)
          .filter(policyMapOriginalPolicyisNotDepositFilterFn)
          .map(
            (update) =>
              new Policy(
                PolicySourceOfTruth.POLICY_SOT_LEGACY,
                update.originalPolicy,
              ),
          );
    }
  }
}
