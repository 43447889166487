import {
  UIDeveloperError,
  reportError,
} from '@anchorage/common/dist/utils/errors';

import type {
  Answer,
  AnswerQuestionToShow,
  AnswerValues,
  AnswersGroupedByQuestions,
  AnswersNormalizedByQuestionId,
  QuestionsNormalized,
} from './types';

import type { Option, Page, Question } from './backendTypes';

/**
 * questions is given by an array of {{Question}}
 ** to be more easy to access this func wil return questions by IDs - gathered in dictionaries.
 ** on a question with options, if the original object has {FreeformOptionID} this should be added on options:
 ** - this represent a option for the user add a custom input
 *
 *  @example
 *  return
 *  {
 *    byId: ['question1','question2'],
 *    ids: {
 *     question1: Question
 *     question2: Question
 *    }
 *  }
 */
const normalizeQuestionsById = (questions: Question[]): QuestionsNormalized =>
  questions.reduce(
    (prevQuestionsNormalized: QuestionsNormalized, questionData: Question) => {
      // Removes answers that do not have questions w/ how the questionnaire is designed
      if (!questionData) {
        return prevQuestionsNormalized;
      }

      const { QuestionID, Options = [], ...restQuestion } = questionData;
      return {
        ...prevQuestionsNormalized,
        ids: [...prevQuestionsNormalized.ids, QuestionID],
        byId: {
          ...prevQuestionsNormalized.byId,
          [QuestionID]: {
            ...restQuestion,
            QuestionID,
            Options,
          },
        },
      };
    },
    {
      ids: [],
      byId: {},
    },
  );

/**
 * the questionnaireBody is given by a string
 * this func will
 * * parse the body to a object of type {Page[]}
 * * get all question from each page
 * * sent to {@link normalizeQuestionsById}
 */
export const normalizeAllQuestionsByPages = (
  questionnaireBody: string,
): QuestionsNormalized => {
  try {
    // parse the body to a object of type {Page[]}
    const pages = JSON.parse(questionnaireBody) as Page[];

    // get all question from each page
    const questions = pages.reduce<Question[]>(
      (acc, page) => acc.concat(page.Questions),
      [],
    );
    return normalizeQuestionsById(questions);
  } catch (error) {
    reportError(new UIDeveloperError('Error parsing questionnaireBody json'));
    return normalizeQuestionsById([]);
  }
};

/**
 * will sort answers by {createdAt} from the most recent to older
 */
const sortAnswersByCreatedTime = (answers: Answer[]): Answer[] =>
  answers.sort(
    (a, b) =>
      (b.createdAt as unknown as number) - (a.createdAt as unknown as number),
  );

/**
 * Return all answers normalize by questionId & organize answers by createdAt
 *
 * @example
 * // return
 * // {
 * //   questionsId: ['question1'],
 * //   byQuestionId: {
 * //    question1: {
 * //      currentAnswer: {Answer},
 * //      allAnswers: {Answer[]},
 * //     },
 * //   }
 * // }
 */
export const normalizeAnswersByQuestionId = (
  answers: Answer[],
): AnswersNormalizedByQuestionId =>
  answers.reduce(
    (prev: AnswersNormalizedByQuestionId, current: Answer) => {
      if (prev.byQuestionId[current.questionID]) {
        const allAnswersSorted = sortAnswersByCreatedTime([
          ...(prev.byQuestionId[current.questionID]?.allAnswers || []),
          current,
        ]);

        const {
          0: { ...currentAnswer },
        } = allAnswersSorted;

        return {
          ...prev,
          byQuestionId: {
            ...prev.byQuestionId,
            [current.questionID]: {
              currentAnswer,
              allAnswers: allAnswersSorted,
            },
          },
        };
      }
      return {
        ...prev,
        questionsId: [...prev.questionsId, current.questionID],
        byQuestionId: {
          ...prev.byQuestionId,
          [current.questionID]: {
            currentAnswer: current,
            allAnswers: [current],
          },
        },
      };
    },
    { questionsId: [], byQuestionId: {} },
  );

/**
 * return the text to show to the user based on options already selected and on freeformText
 */
export const getTextAnswer = (
  answer?: AnswerValues | null,
  options: Option[] = [],
): string => {
  if (!answer) {
    return '';
  }

  // if have any {freeformText} this is what should be shown to the user
  if (answer.freeformText.length) {
    return answer.freeformText;
  }

  if (answer.options.length) {
    // will get the text for the option id selected by the user
    return options
      ?.filter(({ OptionID }) => (answer?.options.indexOf(OptionID) ?? -1) >= 0)
      .map(({ OptionText }) => OptionText)
      .toString();
  }

  // should have or a freeformText or a option select this is only a fallback
  return '';
};

/**
 * util to sorted an array based on an second array
 *
 * @param arrayToSort
 * @param sortedArray
 * @returns arraySorted
 */
const sortArray = (arrayToSort: string[], sortedArray: string[]): string[] =>
  arrayToSort.sort((a, b) => sortedArray.indexOf(a) - sortedArray.indexOf(b));

/**
 * This helper convert the question, the current and the previous answer in text ready to show in some component
 */
const convertQuestionAndAnswerToShow = ({
  question,
  currentAnswer,
}: {
  question: Question;
  currentAnswer: Answer;
}): AnswerQuestionToShow | null => {
  // Removes answers that do not have questions w/ how the questionnaire is designed
  if (!question) {
    return null;
  }

  const {
    QuestionTag,
    QuestionText,
    Options,
    PlaceholderText,
    QuestionID: questionId,
  } = question;

  return {
    questionId,
    questionTitle: PlaceholderText || QuestionTag || QuestionText,
    currentAnswerText: getTextAnswer(currentAnswer.answerData, Options),
  };
};

/**
 *
 * given the {questions} and the {answers}
 * this function will:
 * * sort {questionId} of {answersNormalized} based on {questionsNormalized.questionsId}
 * * find the text for questions and for answers @see {@link getTextAnswer}
 * * group answers in 2 levels based on - if the question for that answers:
 * * - don't have some trigger: it means that question is a main question
 * * - has some trigger - it means that question is not a main question and need to find which main question belongs
 *
 * @example
 *  return
 *  [
 *     {
 *       questionId: '6c8b4728eafae958076a00173d984a14',
 *       questionTitle: 'Where is this address held?',
 *       currentAnswer: 'Financial institution',
 *       group: [
 *         {
 *           questionId: 'd4b319a4f8efe1d84b883eac6c32acfa',
 *           questionTitle: 'Institution name',
 *           currentAnswer: 'Alameda Research',
 *         },
 *         {
 *           questionId: 'e4bfc1ca1f46f059b14d6983033094b3',
 *           questionTitle: 'Institution country',
 *           currentAnswer: 'Hong Kong',
 *         },
 *       ],
 *     }
 *  ]
 */
export const getQuestionsAnswersGrouped = (
  questions: QuestionsNormalized,
  answers: AnswersNormalizedByQuestionId,
): AnswersGroupedByQuestions[] => {
  // sort {questionId} of {answersNormalized} based on {questionsNormalized.questionsId}
  const sortedQuestionsIdOfAnswers = sortArray(
    answers.questionsId,
    questions.ids,
  );
  const checkTriggerOptionsForQuestionIdInAnswers = (
    questionId: string,
    triggersOptionsId: string[][],
  ): boolean => {
    const optionsOfAnswerByQuestionId =
      answers.byQuestionId[questionId]?.currentAnswer.answerData?.options;

    return !!triggersOptionsId?.some((triggerOption) =>
      triggerOption.every((triggerOptionId) =>
        optionsOfAnswerByQuestionId?.every(
          (optionId) => optionId === triggerOptionId,
        ),
      ),
    );
  };

  return sortedQuestionsIdOfAnswers.reduce(
    (previousNormalized: AnswersGroupedByQuestions[], questionId) => {
      const answer = answers.byQuestionId[questionId];
      if (!answer) {
        return previousNormalized;
      }
      const { currentAnswer } = answer;

      const question = questions.byId[questionId];

      if (!question) {
        return previousNormalized;
      }
      const { TriggerOptionID: triggersOptionsId } = question;

      // find the text for answers
      const questionAndAnswersToShow = convertQuestionAndAnswerToShow({
        question,
        currentAnswer,
      });

      // group answers in 2 levels:
      //
      // - if the question for that answers don't have some trigger
      /// it means that question is a main question
      //
      // - if the question for that answers has some trigger
      /// it means that question is not a main question and need to find which main question belongs

      if (!triggersOptionsId?.[0]?.length) {
        if (questionAndAnswersToShow) {
          // this question is a main question
          return [...previousNormalized, questionAndAnswersToShow];
        }
        return previousNormalized;
      }

      /// we need to find which main question belongs
      const indexForGroup = previousNormalized.findIndex(
        (prevNormalized) =>
          checkTriggerOptionsForQuestionIdInAnswers(
            prevNormalized.questionId,
            triggersOptionsId,
          ) ||
          (prevNormalized.group?.findIndex((group) =>
            checkTriggerOptionsForQuestionIdInAnswers(
              group.questionId,
              triggersOptionsId,
            ),
          ) ?? -1) >= 0,
      );

      const prevNormalizedGroup = previousNormalized[indexForGroup];
      if (indexForGroup >= 0 && prevNormalizedGroup) {
        if (questionAndAnswersToShow) {
          prevNormalizedGroup.group = [
            ...(previousNormalized[indexForGroup]?.group ?? []),
            questionAndAnswersToShow,
          ];
        } else {
          prevNormalizedGroup.group = [...(prevNormalizedGroup?.group ?? [])];
        }
      }

      return previousNormalized;
    },
    [],
  );
};
