import cn from 'classnames';

const FormatLineSpacingIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatLineSpacing', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M12 14H17L10 7L3 14H8V34H3L10 41L17 34H12V14ZM20 10V14H44V10H20ZM20 38H44V34H20V38ZM20 26H44V22H20V26Z" />
    </svg>
  );
};

export { FormatLineSpacingIcon };
