import cn from 'classnames';

const BorderBottomIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BorderBottom', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18 22H14V26H18V22ZM26 30H22V34H26V30ZM18 6H14V10H18V6ZM26 22H22V26H26V22ZM10 6H6V10H10V6ZM26 14H22V18H26V14ZM34 22H30V26H34V22ZM26 6H22V10H26V6ZM34 6H30V10H34V6ZM38 26H42V22H38V26ZM38 34H42V30H38V34ZM10 14H6V18H10V14ZM38 6V10H42V6H38ZM38 18H42V14H38V18ZM10 22H6V26H10V22ZM6 42H42V38H6V42ZM10 30H6V34H10V30Z" />
    </svg>
  );
};

export { BorderBottomIcon };
