// Utils
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { components } from 'react-select-v5';
import type { SingleValue } from 'react-select-v5';

// Components
import { SelectV2 } from '../../../../components';

// Styles
import css from '../index.css';

// Types
import type { NFTSortOption, NFTSortSelectProps } from '../_types';

import { NFTContentNFTViews, NFTSorting } from '../../_constants';
// Constants
import { COLLECTION_SORT_OPTIONS, SORT_OPTIONS } from '../_constants';
import SelectOption from './SelectOption';
import SelectValueContainer from './SelectValueContainer';

const NFTSortSelect: React.FC<NFTSortSelectProps> = ({
  nftViewType,
  onSortChange,
}) => {
  const [selected, setSelected] = useState(SORT_OPTIONS[0]);

  useEffect(() => {
    setSelected(SORT_OPTIONS[0]);
  }, [nftViewType]);

  return (
    <SelectV2<NFTSortOption, false>
      isSearchable={false}
      className={css.select}
      styles={{
        control: () => ({
          backgroundColor: 'rgba(0, 0, 0, 0) !important',
          display: 'flex',
        }),
      }}
      maxMenuHeight={600}
      components={{
        Option: SelectOption,
        ValueContainer: SelectValueContainer,
        // eslint-disable-next-line react/display-name
        Menu: (props) => (
          <components.Menu
            {...props}
            className={cn({
              [css.customCollectionMenu]:
                nftViewType === NFTContentNFTViews.COLLECTION,
              [css.customNftMenu]: nftViewType === NFTContentNFTViews.SINGLE,
            })}
          />
        ),
      }}
      onChange={(newValue: SingleValue<NFTSortOption>) => {
        setSelected(newValue ?? SORT_OPTIONS[0]);
        onSortChange(newValue?.value ?? NFTSorting.ALPHABETICAL_ASC);
      }}
      options={
        nftViewType === NFTContentNFTViews.COLLECTION
          ? [...SORT_OPTIONS.concat(COLLECTION_SORT_OPTIONS)]
          : SORT_OPTIONS
      }
      value={selected}
    />
  );
};

export default NFTSortSelect;
