import { StaticDataContext } from 'context/StaticData';
import { useContext } from 'react';

const useStaticDataContext = () => {
  const data = useContext(StaticDataContext);
  if (!data) {
    throw new Error(
      `There was an error retrieving data from StaticDataContext`,
    );
  }
  return data;
};

export default useStaticDataContext;
