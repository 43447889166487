import { transformAddresses } from '../../../../utils/mapAddressToWallets';

import type { OperationDrawer } from 'generated/graphql';

import AddressInfo from '../../../widgets/AddressInfo';
import AmountAndFees from '../../../widgets/AmountAndFees';
import DisclosureSection from '../../../widgets/DisclosureSection';
import Transaction from '../../../widgets/Transaction';

export type LockOperationType = Extract<
  OperationDrawer.operation,
  { __typename: 'LockOperation' }
>;

export type UnlockOperationType = Extract<
  OperationDrawer.operation,
  { __typename: 'UnlockOperation' }
>;

type Props = {
  operation: LockOperationType | UnlockOperationType;
};

const LockUnlockOperation = ({ operation }: Props) => {
  const {
    amount,
    asset: { vault: receivingVault },
    finalFee,
    maybeTransactionID,
    maybeViewTransactionLink,
    sendingBlockchainAddresses,
  } = operation;

  const { account } = receivingVault;
  const sendingAddress = sendingBlockchainAddresses.length
    ? sendingBlockchainAddresses[0]
    : null;
  return (
    <>
      <AmountAndFees
        amount={amount}
        fee={finalFee}
        disclosureTitle="Max fee disclosure"
      />
      <AddressInfo
        addresses={transformAddresses(sendingBlockchainAddresses)}
        vaultName={receivingVault.name}
        blockchainExplorerURL={sendingAddress?.blockchainExplorerURL}
        accountName={account?.displayName}
      />
      <Transaction
        transactionID={maybeTransactionID}
        transactionLink={maybeViewTransactionLink}
      />
      <DisclosureSection assetTypeID={amount.assetTypeID} />
    </>
  );
};

export default LockUnlockOperation;
