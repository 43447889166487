import type { AddressInfoFieldsFragment } from 'generated/graphql';

export function transformSingleAddress(address: string | null) {
  const addressToWalletsMap: Record<string, string[]> = {};
  if (address) {
    addressToWalletsMap[address] = [];
  }

  return addressToWalletsMap;
}

export const transformAddresses = (
  blockchainAddresses:
    | AddressInfoFieldsFragment
    | AddressInfoFieldsFragment[]
    | null,
) => {
  const addressToWalletsMap: Record<string, string[]> = {};

  if (!blockchainAddresses) {
    return addressToWalletsMap;
  }

  if (!Array.isArray(blockchainAddresses)) {
    blockchainAddresses = [blockchainAddresses];
  }

  blockchainAddresses.forEach((blockchainAddress) => {
    const wallets = blockchainAddress.managedAddress
      ? blockchainAddress.managedAddress.wallets.map((wallet) => wallet.name)
      : [];
    addressToWalletsMap[blockchainAddress.address] = wallets;
  });

  return addressToWalletsMap;
};

export function truncateAddress(address: string, truncateLength = 10) {
  if (address.length < truncateLength) return address;

  const firstDigits = address.substring(0, 4);
  const lastDigits = address.substring(address.length - 4);

  return `${firstDigits} ... ${lastDigits}`;
}
