import cn from 'classnames';

const LocalMallIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalMall', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 12H34C34 6.48 29.52 2 24 2C18.48 2 14 6.48 14 12H10C7.79 12 6.02 13.79 6.02 16L6 40C6 42.21 7.79 44 10 44H38C40.21 44 42 42.21 42 40V16C42 13.79 40.21 12 38 12ZM24 6C27.31 6 30 8.69 30 12H18C18 8.69 20.69 6 24 6ZM24 26C18.48 26 14 21.52 14 16H18C18 19.31 20.69 22 24 22C27.31 22 30 19.31 30 16H34C34 21.52 29.52 26 24 26Z" />
    </svg>
  );
};

export { LocalMallIcon };
