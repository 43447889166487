// Components
// Types
import type { OperationDrawer } from 'generated/graphql';

import TitleAndSubtitle from '../../../shared/TitleAndSubtitle';

type VoteOperation = Extract<
  OperationDrawer.operation,
  { __typename: 'VoteOperation' }
>;

type Props = {
  operation: VoteOperation;
};

const MakerExecutiveVote = ({ operation }: Props) => {
  const { makerBallot } = operation;
  const { proposals = [] } = makerBallot || {};

  const proposal = proposals[0];

  if (proposal?.title) {
    return (
      <TitleAndSubtitle title={proposal.title} subtitle="Executive Vote" />
    );
  }

  return null;
};

export default MakerExecutiveVote;
