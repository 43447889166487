import { AssetIcon, Text } from '@anchorage/common/dist/components';

import type { AssetTypeInfoFieldsFragment } from 'generated/graphql';

import css from './styles.module.scss';

import SectionRow from '../../shared/SectionRow';
import WidgetWrapper from '../../shared/WidgetWrapper';

type AssetTypeInfoWithoutNetwork = Omit<
  Partial<AssetTypeInfoFieldsFragment>,
  'networkInfo'
> & {
  // Require iconURL and name
  iconURL: string;
  name: string;
};

const Asset = (assetTypeInfo: AssetTypeInfoWithoutNetwork) => (
  <WidgetWrapper>
    <SectionRow title="Asset">
      <div className={css.container}>
        <AssetIcon data-testid="amount-asset-icon" {...assetTypeInfo} />
        <Text className={css.asset} size="small">
          {assetTypeInfo.abbreviation} | {assetTypeInfo.name}
        </Text>
      </div>
    </SectionRow>
  </WidgetWrapper>
);

export default Asset;
