import cn from 'classnames';

const EuroSymbolIcon = (props: SVGProps): JSX.Element => {
  const { title = 'EuroSymbol', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M30 37C24.99 37 20.64 34.16 18.47 30H30V26H17.17C17.07 25.35 17 24.68 17 24C17 23.32 17.07 22.65 17.17 22H30V18H18.47C20.64 13.84 24.98 11 30 11C33.23 11 36.18 12.18 38.45 14.13L42 10.6C38.82 7.75 34.61 6 30 6C22.17 6 15.52 11.01 13.05 18H6V22H12.12C12.04 22.66 12 23.32 12 24C12 24.68 12.04 25.34 12.12 26H6V30H13.05C15.52 36.99 22.17 42 30 42C34.61 42 38.82 40.25 42 37.4L38.45 33.86C36.18 35.81 33.23 37 30 37Z" />
    </svg>
  );
};

export { EuroSymbolIcon };
