import cn from 'classnames';

const CachedIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Cached', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 16L30 24H36C36 30.63 30.63 36 24 36C21.97 36 20.07 35.49 18.39 34.61L15.47 37.53C17.95 39.08 20.86 40 24 40C32.84 40 40 32.84 40 24H46L38 16ZM12 24C12 17.37 17.37 12 24 12C26.03 12 27.93 12.51 29.61 13.39L32.53 10.47C30.05 8.92 27.14 8 24 8C15.16 8 8 15.16 8 24H2L10 32L18 24H12Z" />
    </svg>
  );
};

export { CachedIcon };
