import { cva } from 'class-variance-authority';

export const buttonVariants = cva(
  [
    'inline-flex',
    'items-center',
    'justify-center',
    'whitespace-nowrap',
    'rounded-radius-comp-button-squared',
    'focus-visible:outline',
    'focus-visible:outline-offset-2',
    'focus-visible:outline-2',
    'focus-visible:outline-color-ref-ui-focus-default',
    'data-[loading="true"]:pointer-events-none',
    '[&>svg]:fill-current',
    // common lib overrides are giving the button a default border on focus
    'focus:outline-none',
  ],
  {
    variants: {
      size: {
        tiny: [
          'font-comp-button-tiny-font-family',
          'font-comp-button-tiny-font-weight',
          'text-comp-button-tiny-font-size',
          'tracking-font-comp-button-tiny-letter-spacing',
          'leading-comp-button-tiny-line-height',
          'py-spacing-comp-button-tiny-vertical',
          'px-spacing-comp-button-tiny-horizontal',
          'min-w-sizing-comp-button-tiny-min-width',
          'h-sizing-sys-size-24',
        ],
        small: [
          'font-comp-button-small-font-family',
          'font-comp-button-small-font-weight',
          'text-comp-button-small-font-size',
          'tracking-font-comp-button-small-letter-spacing',
          'leading-comp-button-small-line-height',
          'py-spacing-comp-button-small-vertical',
          'px-spacing-comp-button-small-horizontal',
          'min-w-sizing-comp-button-small-min-width',
          'h-sizing-sys-size-32',
        ],
        medium: [
          'font-comp-button-medium-font-family',
          'font-comp-button-medium-font-weight',
          'text-comp-button-medium-font-size',
          'tracking-font-comp-button-medium-letter-spacing',
          'leading-comp-button-medium-line-height',
          'py-spacing-comp-button-medium-vertical',
          'px-spacing-comp-button-medium-horizontal',
          'min-w-sizing-comp-button-medium-min-width',
          'h-sizing-sys-size-40',
        ],
      },
      disabled: {
        true: ['pointer-events-none'],
        false: '',
      },
      variant: {
        primary: '',
        success: '',
        danger: '',
        neutral: '',
        strong: '',
        inverted: '',
      },
      // we subtract the border width from the width to account for the border
      type: {
        filled: '',
        outline: 'border-border-comp-button',
        ghost: '',
      },
      selected: { true: '', false: '' },
    },
    compoundVariants: [
      {
        variant: 'primary',
        type: 'filled',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-primary-solid-container-enabled',
          'hover:bg-color-comp-button-primary-solid-container-hover',
          'active:bg-color-comp-button-primary-solid-container-pressed',

          'text-color-comp-button-primary-solid-on-container-enabled',
          'hover:text-color-comp-button-primary-solid-on-container-hover',
          'active:text-color-comp-button-primary-solid-on-container-pressed',
        ],
      },
      {
        variant: 'primary',
        type: 'filled',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-primary-solid-container-disabled',
          'text-color-comp-button-primary-solid-on-container-disabled',
        ],
      },
      {
        variant: 'primary',
        type: 'filled',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-primary-solid-container-selected',
          'text-color-comp-button-primary-solid-on-container-selected',
        ],
      },
      {
        variant: 'primary',
        type: 'outline',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-primary-plain-container-enabled',
          'hover:bg-color-comp-button-primary-plain-container-hover',
          'active:bg-color-comp-button-primary-plain-container-pressed',

          'text-color-comp-button-primary-plain-on-container-enabled',
          'hover:text-color-comp-button-primary-plain-on-container-hover',
          'active:text-color-comp-button-primary-plain-on-container-pressed',

          'border-color-comp-button-primary-plain-border-enabled',
          'hover:border-color-comp-button-primary-plain-border-hover',
          'active:border-color-comp-button-primary-plain-border-pressed',
        ],
      },
      {
        variant: 'primary',
        type: 'outline',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-primary-plain-container-disabled',
          'text-color-comp-button-primary-plain-on-container-disabled',
          'border-color-comp-button-primary-plain-border-disabled',
        ],
      },
      {
        variant: 'primary',
        type: 'outline',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-primary-plain-container-selected',
          'text-color-comp-button-primary-plain-on-container-selected',
          'border-color-comp-button-primary-plain-border-selected',
        ],
      },
      {
        variant: 'primary',
        type: 'ghost',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-primary-plain-container-enabled',
          'hover:bg-color-comp-button-primary-plain-container-hover',
          'active:bg-color-comp-button-primary-plain-container-pressed',

          'text-color-comp-button-primary-plain-on-container-enabled',
          'hover:text-color-comp-button-primary-plain-on-container-hover',
          'active:text-color-comp-button-primary-plain-on-container-pressed',
        ],
      },
      {
        variant: 'primary',
        type: 'ghost',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-primary-plain-container-disabled',
          'text-color-comp-button-primary-plain-on-container-disabled',
        ],
      },
      {
        variant: 'primary',
        type: 'ghost',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-primary-plain-container-selected',
          'text-color-comp-button-primary-plain-on-container-selected',
        ],
      },
      {
        variant: 'success',
        type: 'filled',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-success-solid-container-enabled',
          'hover:bg-color-comp-button-success-solid-container-hover',
          'active:bg-color-comp-button-success-solid-container-pressed',

          'text-color-comp-button-success-solid-on-container-enabled',
          'hover:text-color-comp-button-success-solid-on-container-hover',
          'active:text-color-comp-button-success-solid-on-container-pressed',
        ],
      },
      {
        variant: 'success',
        type: 'filled',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-success-solid-container-disabled',
          'text-color-comp-button-success-solid-on-container-disabled',
        ],
      },
      {
        variant: 'success',
        type: 'filled',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-success-solid-container-selected',
          'text-color-comp-button-success-solid-on-container-selected',
        ],
      },
      {
        variant: 'success',
        type: 'outline',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-success-plain-container-enabled',
          'hover:bg-color-comp-button-success-plain-container-hover',
          'active:bg-color-comp-button-success-plain-container-pressed',

          'text-color-comp-button-success-plain-on-container-enabled',
          'hover:text-color-comp-button-success-plain-on-container-hover',
          'active:text-color-comp-button-success-plain-on-container-pressed',

          'border-color-comp-button-success-plain-border-enabled',
          'hover:border-color-comp-button-success-plain-border-hover',
          'active:border-color-comp-button-success-plain-border-pressed',
        ],
      },
      {
        variant: 'success',
        type: 'outline',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-success-plain-container-disabled',
          'text-color-comp-button-success-plain-on-container-disabled',
          'border-color-comp-button-success-plain-border-disabled',
        ],
      },
      {
        variant: 'success',
        type: 'outline',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-success-plain-container-selected',
          'text-color-comp-button-success-plain-on-container-selected',
          'border-color-comp-button-success-plain-border-selected',
        ],
      },
      {
        variant: 'success',
        type: 'ghost',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-success-plain-container-enabled',
          'hover:bg-color-comp-button-success-plain-container-hover',
          'active:bg-color-comp-button-success-plain-container-pressed',

          'text-color-comp-button-success-plain-on-container-enabled',
          'hover:text-color-comp-button-success-plain-on-container-hover',
          'active:text-color-comp-button-success-plain-on-container-pressed',
        ],
      },
      {
        variant: 'success',
        type: 'ghost',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-success-plain-container-disabled',
          'text-color-comp-button-success-plain-on-container-disabled',
        ],
      },
      {
        variant: 'success',
        type: 'ghost',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-success-plain-container-selected',
          'text-color-comp-button-success-plain-on-container-selected',
        ],
      },
      {
        variant: 'danger',
        type: 'filled',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-danger-solid-container-enabled',
          'hover:bg-color-comp-button-danger-solid-container-hover',
          'active:bg-color-comp-button-danger-solid-container-pressed',

          'text-color-comp-button-danger-solid-on-container-enabled',
          'hover:text-color-comp-button-danger-solid-on-container-hover',
          'active:text-color-comp-button-danger-solid-on-container-pressed',
        ],
      },
      {
        variant: 'danger',
        type: 'filled',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-danger-solid-container-disabled',
          'text-color-comp-button-danger-solid-on-container-disabled',
        ],
      },
      {
        variant: 'danger',
        type: 'filled',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-danger-solid-container-selected',
          'text-color-comp-button-danger-solid-on-container-selected',
        ],
      },
      {
        variant: 'danger',
        type: 'outline',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-danger-plain-container-enabled',
          'hover:bg-color-comp-button-danger-plain-container-hover',
          'active:bg-color-comp-button-danger-plain-container-pressed',

          'text-color-comp-button-danger-plain-on-container-enabled',
          'hover:text-color-comp-button-danger-plain-on-container-hover',
          'active:text-color-comp-button-danger-plain-on-container-pressed',

          'border-color-comp-button-danger-plain-border-enabled',
          'hover:border-color-comp-button-danger-plain-border-hover',
          'active:border-color-comp-button-danger-plain-border-pressed',
        ],
      },
      {
        variant: 'danger',
        type: 'outline',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-danger-plain-container-disabled',
          'text-color-comp-button-danger-plain-on-container-disabled',
          'border-color-comp-button-danger-plain-border-disabled',
        ],
      },
      {
        variant: 'danger',
        type: 'outline',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-danger-plain-container-selected',
          'text-color-comp-button-danger-plain-on-container-selected',
          'border-color-comp-button-danger-plain-border-selected',
        ],
      },
      {
        variant: 'danger',
        type: 'ghost',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-danger-plain-container-enabled',
          'hover:bg-color-comp-button-danger-plain-container-hover',
          'active:bg-color-comp-button-danger-plain-container-pressed',

          'text-color-comp-button-danger-plain-on-container-enabled',
          'hover:text-color-comp-button-danger-plain-on-container-hover',
          'active:text-color-comp-button-danger-plain-on-container-pressed',
        ],
      },
      {
        variant: 'danger',
        type: 'ghost',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-danger-plain-container-disabled',
          'text-color-comp-button-danger-plain-on-container-disabled',
        ],
      },
      {
        variant: 'danger',
        type: 'ghost',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-danger-plain-container-selected',
          'text-color-comp-button-danger-plain-on-container-selected',
        ],
      },
      {
        variant: 'neutral',
        type: 'filled',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-neutral-solid-container-enabled',
          'hover:bg-color-comp-button-neutral-solid-container-hover',
          'active:bg-color-comp-button-neutral-solid-container-pressed',

          'text-color-comp-button-neutral-solid-on-container-enabled',
          'hover:text-color-comp-button-neutral-solid-on-container-hover',
          'active:text-color-comp-button-neutral-solid-on-container-pressed',
        ],
      },
      {
        variant: 'neutral',
        type: 'filled',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-neutral-solid-container-disabled',
          'text-color-comp-button-neutral-solid-on-container-disabled',
        ],
      },
      {
        variant: 'neutral',
        type: 'filled',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-neutral-solid-container-selected',
          'text-color-comp-button-neutral-solid-on-container-selected',
        ],
      },
      {
        variant: 'neutral',
        type: 'outline',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-neutral-plain-container-enabled',
          'hover:bg-color-comp-button-neutral-plain-container-hover',
          'active:bg-color-comp-button-neutral-plain-container-pressed',

          'text-color-comp-button-neutral-plain-on-container-enabled',
          'hover:text-color-comp-button-neutral-plain-on-container-hover',
          'active:text-color-comp-button-neutral-plain-on-container-pressed',

          'border-color-comp-button-neutral-plain-border-enabled',
          'hover:border-color-comp-button-neutral-plain-border-hover',
          'active:border-color-comp-button-neutral-plain-border-pressed',
        ],
      },
      {
        variant: 'neutral',
        type: 'outline',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-neutral-plain-container-disabled',
          'text-color-comp-button-neutral-plain-on-container-disabled',
          'border-color-comp-button-neutral-plain-border-disabled',
        ],
      },
      {
        variant: 'neutral',
        type: 'outline',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-neutral-plain-container-selected',
          'text-color-comp-button-neutral-plain-on-container-selected',
          'border-color-comp-button-neutral-plain-border-selected',
        ],
      },
      {
        variant: 'neutral',
        type: 'ghost',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-neutral-plain-container-enabled',
          'hover:bg-color-comp-button-neutral-plain-container-hover',
          'active:bg-color-comp-button-neutral-plain-container-pressed',

          'text-color-comp-button-neutral-plain-on-container-enabled',
          'hover:text-color-comp-button-neutral-plain-on-container-hover',
          'active:text-color-comp-button-neutral-plain-on-container-pressed',
        ],
      },
      {
        variant: 'neutral',
        type: 'ghost',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-neutral-plain-container-disabled',
          'text-color-comp-button-neutral-plain-on-container-disabled',
        ],
      },
      {
        variant: 'neutral',
        type: 'ghost',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-neutral-plain-container-selected',
          'text-color-comp-button-neutral-plain-on-container-selected',
        ],
      },
      {
        variant: 'strong',
        type: 'filled',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-strong-solid-container-enabled',
          'hover:bg-color-comp-button-strong-solid-container-hover',
          'active:bg-color-comp-button-strong-solid-container-pressed',

          'text-color-comp-button-strong-solid-on-container-enabled',
          'hover:text-color-comp-button-strong-solid-on-container-hover',
          'active:text-color-comp-button-strong-solid-on-container-pressed',
        ],
      },
      {
        variant: 'strong',
        type: 'filled',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-strong-solid-container-disabled',
          'text-color-comp-button-strong-solid-on-container-disabled',
        ],
      },
      {
        variant: 'strong',
        type: 'filled',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-strong-solid-container-selected',
          'text-color-comp-button-strong-solid-on-container-selected',
        ],
      },
      {
        variant: 'strong',
        type: 'outline',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-strong-plain-container-enabled',
          'hover:bg-color-comp-button-strong-plain-container-hover',
          'active:bg-color-comp-button-strong-plain-container-pressed',

          'text-color-comp-button-strong-plain-on-container-enabled',
          'hover:text-color-comp-button-strong-plain-on-container-hover',
          'active:text-color-comp-button-strong-plain-on-container-pressed',

          'border-color-comp-button-strong-plain-border-enabled',
          'hover:border-color-comp-button-strong-plain-border-hover',
          'active:border-color-comp-button-strong-plain-border-pressed',
        ],
      },
      {
        variant: 'strong',
        type: 'outline',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-strong-plain-container-disabled',
          'text-color-comp-button-strong-plain-on-container-disabled',
          'border-color-comp-button-strong-plain-border-disabled',
        ],
      },
      {
        variant: 'strong',
        type: 'outline',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-strong-plain-container-selected',
          'text-color-comp-button-strong-plain-on-container-selected',
          'border-color-comp-button-strong-plain-border-selected',
        ],
      },
      {
        variant: 'strong',
        type: 'ghost',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-strong-plain-container-enabled',
          'hover:bg-color-comp-button-strong-plain-container-hover',
          'active:bg-color-comp-button-strong-plain-container-pressed',

          'text-color-comp-button-strong-plain-on-container-enabled',
          'hover:text-color-comp-button-strong-plain-on-container-hover',
          'active:text-color-comp-button-strong-plain-on-container-pressed',
        ],
      },
      {
        variant: 'strong',
        type: 'ghost',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-strong-plain-container-disabled',
          'text-color-comp-button-strong-plain-on-container-disabled',
        ],
      },
      {
        variant: 'strong',
        type: 'ghost',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-strong-plain-container-selected',
          'text-color-comp-button-strong-plain-on-container-selected',
        ],
      },
      {
        variant: 'inverted',
        type: 'filled',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-inverted-solid-container-enabled',
          'hover:bg-color-comp-button-inverted-solid-container-hover',
          'active:bg-color-comp-button-inverted-solid-container-pressed',

          'text-color-comp-button-inverted-solid-on-container-enabled',
          'hover:text-color-comp-button-inverted-solid-on-container-hover',
          'active:text-color-comp-button-inverted-solid-on-container-pressed',
        ],
      },
      {
        variant: 'inverted',
        type: 'filled',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-inverted-solid-container-disabled',
          'text-color-comp-button-inverted-solid-on-container-disabled',
        ],
      },
      {
        variant: 'inverted',
        type: 'filled',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-inverted-solid-container-selected',
          'text-color-comp-button-inverted-solid-on-container-selected',
        ],
      },
      {
        variant: 'inverted',
        type: 'outline',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-inverted-plain-container-enabled',
          'hover:bg-color-comp-button-inverted-plain-container-hover',
          'active:bg-color-comp-button-inverted-plain-container-pressed',

          'text-color-comp-button-inverted-plain-on-container-enabled',
          'hover:text-color-comp-button-inverted-plain-on-container-hover',
          'active:text-color-comp-button-inverted-plain-on-container-pressed',

          'border-color-comp-button-inverted-plain-border-enabled',
          'hover:border-color-comp-button-inverted-plain-border-hover',
          'active:border-color-comp-button-inverted-plain-border-pressed',
        ],
      },
      {
        variant: 'inverted',
        type: 'outline',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-inverted-plain-container-disabled',
          'text-color-comp-button-inverted-plain-on-container-disabled',
          'border-color-comp-button-inverted-plain-border-disabled',
        ],
      },
      {
        variant: 'inverted',
        type: 'outline',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-inverted-plain-container-selected',
          'text-color-comp-button-inverted-plain-on-container-selected',
          'border-color-comp-button-inverted-plain-border-selected',
        ],
      },
      {
        variant: 'inverted',
        type: 'ghost',
        disabled: false,
        selected: false,
        className: [
          'bg-color-comp-button-inverted-plain-container-enabled',
          'hover:bg-color-comp-button-inverted-plain-container-hover',
          'active:bg-color-comp-button-inverted-plain-container-pressed',

          'text-color-comp-button-inverted-plain-on-container-enabled',
          'hover:text-color-comp-button-inverted-plain-on-container-hover',
          'active:text-color-comp-button-inverted-plain-on-container-pressed',
        ],
      },
      {
        variant: 'inverted',
        type: 'ghost',
        disabled: true,
        selected: false,
        className: [
          'bg-color-comp-button-inverted-plain-container-disabled',
          'text-color-comp-button-inverted-plain-on-container-disabled',
        ],
      },
      {
        variant: 'inverted',
        type: 'ghost',
        disabled: false,
        selected: true,
        className: [
          'bg-color-comp-button-inverted-plain-container-selected',
          'text-color-comp-button-inverted-plain-on-container-selected',
        ],
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'small',
      type: 'filled',
      disabled: false,
      selected: false,
    },
  },
);
