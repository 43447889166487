import cn from 'classnames';

const KeyboardVoiceIcon = (props: SVGProps): JSX.Element => {
  const { title = 'KeyboardVoice', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 30C27.31 30 29.98 27.31 29.98 24L30 12C30 8.68 27.32 6 24 6C20.69 6 18 8.68 18 12V24C18 27.31 20.69 30 24 30ZM34.6 24C34.6 30 29.53 34.2 24 34.2C18.48 34.2 13.4 30 13.4 24H10C10 30.83 15.44 36.47 22 37.44V44H26V37.44C32.56 36.47 38 30.83 38 24H34.6Z" />
    </svg>
  );
};

export { KeyboardVoiceIcon };
