import cn from 'classnames';

const BankTransferInIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BankTransferIn', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M0.5 30.5714V23.5H7.55V18.7857L16.95 27.0357L7.55 35.2857V30.5714H0.5ZM47.5 15.7214V18.7857H19.3V15.7214L33.4 7L47.5 15.7214ZM19.3 35.2857H47.5V40H19.3V35.2857ZM31.05 21.1429H35.75V32.9286H31.05V21.1429ZM21.65 21.1429H26.35V32.9286H21.65V21.1429ZM40.45 21.1429H45.15V32.9286H40.45V21.1429Z" />
    </svg>
  );
};

export { BankTransferInIcon };
