import cn from 'classnames';

const AnchorageDigitalCircleIcon = (props: SVGProps): JSX.Element => {
  const {
    title = 'AnchorageDigital-circle-48px',
    className,
    ...restProps
  } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44ZM17.1236 15.0685L23.9991 19.4727L30.8756 15.0685L23.9991 10.6661L17.1236 15.0685ZM17.1236 33.0034L23.9991 37.4067L30.8756 33.0034L23.9991 28.6001L17.1236 33.0034ZM14.2104 31.1405L7.33389 26.7372L14.2104 22.3339L21.0859 26.7372L14.2104 31.1405ZM26.9142 26.7372L33.7907 31.1405L40.6671 26.7372L33.7907 22.3339L26.9142 26.7372Z"
      />
    </svg>
  );
};

export { AnchorageDigitalCircleIcon };
