import cn from 'classnames';

const Battery20Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Battery20', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M14 34V41.33C14 42.8 15.19 44 16.67 44H31.34C32.81 44 34.01 42.81 34.01 41.33V34H14Z" />
      <path
        opacity="0.3"
        d="M34 10.67C34 9.19 32.81 8 31.33 8H28V4H20V8H16.67C15.19 8 14 9.19 14 10.67V34H34V10.67Z"
      />
    </svg>
  );
};

export { Battery20Icon };
