import cn from 'classnames';

const ContentPasteIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ContentPaste', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 4H29.63C28.81 1.68 26.61 0 24 0C21.39 0 19.19 1.68 18.37 4H10C7.79 4 6 5.79 6 8V40C6 42.21 7.79 44 10 44H38C40.21 44 42 42.21 42 40V8C42 5.79 40.21 4 38 4ZM24 4C25.1 4 26 4.89 26 6C26 7.11 25.1 8 24 8C22.9 8 22 7.11 22 6C22 4.89 22.9 4 24 4ZM38 40H10V8H14V14H34V8H38V40Z" />
    </svg>
  );
};

export { ContentPasteIcon };
