/* eslint-disable react/display-name */
import React from 'react';
// Components
import { TableVirtuoso } from 'react-virtuoso';

import { TableHead } from '../../../components/TableV2';
import EmptyPlaceholder from './_components/EmptyPlaceholder';
import NFTCollectionHeaders from './_components/NFTCollectionHeaders';
import NFTHeaders from './_components/NFTHeaders';
import NFTRowCells from './_components/NFTRowCells';

// Styles
import css from './index.css';

import type { NFTTableProps } from './_types';

// Types & Constants
import { NFTContentNFTViews } from '../_constants';

const NFTTable: React.FC<NFTTableProps> = (
  {
    data,
    endReached,
    isLoading,
    contentViewType,
    overscan = {
      main: 30,
      reverse: 30,
    },
    ...restProps
  },
) => (
  <div className={css.table} data-testid="nft-table">
    <TableVirtuoso
      endReached={endReached}
      data={data}
      overscan={overscan}
      increaseViewportBy={30}
      components={{
        TableHead: (props) => (
          <TableHead {...props} className={css.tableHeaderRow} />
        ),
        ScrollSeekPlaceholder: EmptyPlaceholder,
      }}
      fixedHeaderContent={
        contentViewType === NFTContentNFTViews.COLLECTION
          ? () => <NFTCollectionHeaders />
          : () => <NFTHeaders />
      }
      itemContent={(index, nft) => (
        <>
          <NFTRowCells
            key={`${nft.title}-${index}`}
            data={nft}
            contentViewType={contentViewType}
            showIsLoading={index === data.length - 1 && isLoading}
          />
        </>
      )}
      {...restProps}
    />
  </div>
);

export default NFTTable;
