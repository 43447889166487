import Text from '@latitude/text';

import DecimalText from '@anchorage/frontoffice/components/DecimalText';

import { WalletStakingDetails } from 'generated/graphql';

import css from './styles.module.scss';

interface Props {
  amount: WalletStakingDetails.amount | undefined | null;
  assetTypeInfoAbbreviation: string;
}

const TotalStaked = ({ amount, assetTypeInfoAbbreviation }: Props) => {
  const displayValue = amount?.displayValue ?? '0';
  const displayValueInUSD = amount?.displayValueInUSD ?? '$0';

  return (
    <div className={css.totalStakedContainer}>
      <Text variant="headingTiny">Total Staked</Text>
      <DecimalText size="medium" type="metric">
        {`${displayValue} ${assetTypeInfoAbbreviation}`}
      </DecimalText>
      <DecimalText size="small" className={css.usdValue}>
        {displayValueInUSD}
      </DecimalText>
    </div>
  );
};

export default TotalStaked;
