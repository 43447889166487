import type { ExtraParamsFieldsFragment } from 'generated/graphql';

// // Helper function that takes an object of extraparams, finds the
// // destination key & returns data on the destination tag.
export const getExtraParamsTagValue = (
  extraParams: Partial<ExtraParamsFieldsFragment> | null,
) => {
  if (!extraParams) {
    return null;
  }

  for (const paramInfo of Object.values(extraParams)) {
    if (paramInfo !== null && typeof paramInfo === 'object') {
      const key = Object.keys(paramInfo).find((key) => key !== '__typename');

      if (key) {
        //@ts-ignore - Element implicitly has an 'any' type
        //because expression of type 'string' can't be used to index type
        return paramInfo[key];
      }
    }
  }
  return null;
};
