import _sortBy from 'lodash/sortBy';
import { ComponentProps, useCallback, useMemo } from 'react';

import type { Policy } from '@anchorage/authorization';
import { useDeepCompare } from '@anchorage/common/dist/hooks';

import {
  AddUserAndPoliciesOperation,
  CreateVaultOperation,
  DisableVaultOperation,
  OperationImplementations,
  RemoveUserAndPoliciesOperation,
  UpdateOrgPoliciesOperation,
  UpdateVaultPoliciesOperation,
} from 'generated/graphql';

import QuorumList from './QuorumList';

type OperationType =
  | CreateVaultOperation['__typename']
  | DisableVaultOperation['__typename']
  | UpdateOrgPoliciesOperation['__typename']
  | UpdateVaultPoliciesOperation['__typename']
  | AddUserAndPoliciesOperation['__typename']
  | RemoveUserAndPoliciesOperation['__typename'];

const PolicyComponent = ({
  policy,
  isSameAsDefault = false,
  operationType,
  analytics,
}: {
  policy: Policy;
  isSameAsDefault?: boolean;
  operationType: OperationType;
  analytics?: ComponentProps<typeof QuorumList>['analytics'];
}) => {
  const getState = useCallback(() => {
    if (operationType === OperationImplementations.CreateVaultOperation) {
      return 'added';
    }
    return 'unchanged';
  }, [operationType]);

  const policyQuorums = useMemo(
    () => {
      const baseQuorum = {
        first: true,
        quorumName: policy.quorumGroup?.baseQuorum?.displayName ?? '',
        quorum: {
          original: policy.quorumGroup?.baseQuorum?.threshold ?? 0,
          updated: 0,
        },
        total: {
          original: policy.quorumGroup?.baseQuorum.members.length ?? 0,
          updated: 0,
        },
        description: 'Number of approvers to reach quorum',
        [`${getState()}UsersList`]: _sortBy(
          policy.quorumGroup.baseQuorum.members,
          'name',
        ),
      };

      const subQuorums =
        policy.quorumGroup?.subquorums?.map((subQuorum) => ({
          first: false,
          quorumName: subQuorum.displayName || '',
          quorum: {
            original: subQuorum.threshold,
            updated: 0,
          },
          total: {
            original: subQuorum.members.length,
            updated: 0,
          },
          description: 'Number of approvers to reach sub-quorum',
          [`${getState()}UsersList`]: _sortBy(subQuorum.members, 'name'),
        })) ?? [];

      return [baseQuorum, ...subQuorums];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([getState, policy]),
  );

  const baseQuorum = policyQuorums[0];
  const subQuorums = policyQuorums.slice(1);

  if (!baseQuorum) {
    return null;
  }

  return (
    <QuorumList
      title={policy.displayName}
      baseQuorum={baseQuorum}
      subQuorums={subQuorums}
      expanded={true}
      isSameAsDefault={isSameAsDefault}
      analytics={analytics}
    />
  );
};

export default PolicyComponent;
