import * as React from 'react';

import { FeatureFlagProvider } from '@anchorage/feature-flags';

import { SPLIT_OPTIONS } from '../../constants/split';

export const FeatureFlagsContext = React.createContext({});

type Props = {
  children: JSX.Element;
  organizationKeyID?: string;
  email?: string;
  policySourceOfTruth?: string;
};

function FeatureFlagsProvider({
  children,
  organizationKeyID = 'NA',
  email = 'NA',
  policySourceOfTruth = '',
}: Props) {
  return (
    <FeatureFlagProvider
      baseAttributes={{
        orgID: organizationKeyID,
        email,
        policy_source_of_truth: policySourceOfTruth,
      }}
      defaults={SPLIT_OPTIONS.defaults}
      options={{
        ...SPLIT_OPTIONS.options,
        trafficType: 'organization',
        trafficKey: organizationKeyID,
      }}
    >
      {children}
    </FeatureFlagProvider>
  );
}

export default FeatureFlagsProvider;
