import cn from 'classnames';

const BaselineToggleOnIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Baseline-toggleOn-24px', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M34 14H14C8.48 14 4 18.48 4 24C4 29.52 8.48 34 14 34H34C39.52 34 44 29.52 44 24C44 18.48 39.52 14 34 14ZM34 30C30.68 30 28 27.32 28 24C28 20.68 30.68 18 34 18C37.32 18 40 20.68 40 24C40 27.32 37.32 30 34 30Z" />
    </svg>
  );
};

export { BaselineToggleOnIcon };
