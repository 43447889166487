import cn from 'classnames';

const LinkedCameraIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LinkedCamera', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 34.4C27.5346 34.4 30.4 31.5346 30.4 28C30.4 24.4654 27.5346 21.6 24 21.6C20.4654 21.6 17.6 24.4654 17.6 28C17.6 31.5346 20.4654 34.4 24 34.4Z" />
      <path d="M24 18C29.52 18 34 22.48 34 28C34 33.52 29.52 38 24 38C18.48 38 14 33.52 14 28C14 22.48 18.48 18 24 18Z" />
      <path d="M32 6.66C37.16 6.66 41.34 10.84 41.34 16H44C44 9.38 38.62 4 32 4V6.66ZM32 12C34.22 12 36 13.78 36 16H38.66C38.66 12.32 35.68 9.34 32 9.34V12Z" />
      <path d="M34 18C34 15.78 32.22 14 30 14V8H18L14.34 12H8C5.8 12 4 13.8 4 16V40C4 42.2 5.8 44 8 44H40C42.2 44 44 42.2 44 40V18H34ZM24 38C18.48 38 14 33.52 14 28C14 22.48 18.48 18 24 18C29.52 18 34 22.48 34 28C34 33.52 29.52 38 24 38Z" />
    </svg>
  );
};

export { LinkedCameraIcon };
