import { useMemo } from 'react';

import { Drawer, Loader } from '@anchorage/common/dist/components';
import {
  UIDeveloperError,
  reportError,
} from '@anchorage/common/dist/utils/errors';
import { FAST_POLL_INTERVAL } from '@anchorage/frontoffice/constants/apollo';
import usePollingQueryV2 from '@anchorage/frontoffice/hooks/usePollingQueryV2';

import DrawerError from './components/DrawerError';
import Header from './components/Header';
import OperationWrapper from './components/OperationWrapper';

import { OperationContext } from './hooks/useOperationContext';

import {
  type OperationDrawer as OperationDrawerNamespace,
  OperationDrawerQuery,
  OperationDrawerQueryVariables,
  useOperationDrawerQuery,
} from 'generated/graphql';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  operationID: string;
};

type AddOrganizationOperation = Extract<
  OperationDrawerNamespace.operation,
  { __typename: 'AddOrganizationOperation' }
>;

type Operation = Exclude<
  OperationDrawerNamespace.operation,
  AddOrganizationOperation
>;

const OperationDrawer = ({ operationID, isOpen, onClose }: Props) => {
  const variables = {
    operationID,
  };

  const hasOperationID = operationID && operationID !== '';

  const { loading, error, data } = usePollingQueryV2<
    OperationDrawerQuery,
    OperationDrawerQueryVariables
  >(useOperationDrawerQuery, {
    variables,
    skip: !hasOperationID || !isOpen,
    pollInterval: FAST_POLL_INTERVAL,
  });

  const content = useMemo(() => {
    if (!hasOperationID || !isOpen) {
      return <></>;
    }

    if (loading) {
      return <Loader title="operation-detail-loader" />;
    }

    if (error || !data || !data.operation) {
      reportError(
        new UIDeveloperError(
          `Error on receiving operation data in OperationDrawer`,
        ),
      );
      return <DrawerError />;
    }

    return <OperationWrapper operation={data.operation as Operation} />;
  }, [data, loading, error, isOpen, hasOperationID]);

  return (
    <OperationContext.Provider
      value={
        data?.operation
          ? { operation: data?.operation as Operation }
          : undefined
      }
    >
      <Drawer
        width={750}
        onClose={onClose}
        open={isOpen}
        noTitle={!data?.operation}
        title={<Header />}
      >
        {content}
      </Drawer>
    </OperationContext.Provider>
  );
};

export default OperationDrawer;
