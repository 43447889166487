import cn from 'classnames';

const HdrOffIcon = (props: SVGProps): JSX.Element => {
  const { title = 'HdrOff', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M35 30V26H37.3L39 30H42L40.2 25.8C41.3 25.3 42 24.3 42 23V21C42 19.3 40.7 18 39 18H32V27.8L34.2 30H35ZM35 21H39V23H35V21ZM26 21V21.8L29 24.8V21C29 19.3 27.7 18 26 18H22.2L25.2 21H26ZM19 19L4.89999 4.89999L2.79999 6.99999L13.8 18H13V22H8.99999V18H5.99999V30H8.99999V25H13V30H16V20.2L19 23.2V30H25.8L41 45.2L43.1 43.1L19 19Z" />
    </svg>
  );
};

export { HdrOffIcon };
