import cn from 'classnames';

const AudiotrackIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Audiotrack', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 6V24.55C23.06 24.22 22.06 24 21 24C16.03 24 12 28.03 12 33C12 37.97 16.03 42 21 42C25.63 42 29.4 38.49 29.9 34H30V12H38V6H24Z" />
    </svg>
  );
};

export { AudiotrackIcon };
