const CircleErrorFilledIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Error', ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      fill="none"
      viewBox="0 0 17 16"
      {...restProps}
    >
      <title>{title}</title>
      <g>
        <path
          fill="#E8646F"
          d="M8.6 1.333a6.667 6.667 0 100 13.334 6.667 6.667 0 000-13.334zm.667 10H7.933V10h1.334v1.333zm0-2.666H7.933v-4h1.334v4z"
        ></path>
      </g>
    </svg>
  );
};

export { CircleErrorFilledIcon };
