import cn from 'classnames';

const ExposureNeg1Icon = (props: SVGProps): JSX.Element => {
  const { title = 'ExposureNeg1', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M8 22V26H24V22H8ZM38 36H34V14.75L28 16.8V13.4L37.4 10H38V36Z" />
    </svg>
  );
};

export { ExposureNeg1Icon };
