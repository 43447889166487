import cn from 'classnames';

const CheckIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Check', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18.0001 32.3401L9.66008 24.0001L6.83008 26.8301L18.0001 38.0001L42.0001 14.0001L39.1701 11.1701L18.0001 32.3401Z" />
    </svg>
  );
};

export { CheckIcon };
