import cn from 'classnames';

const LocationSearchingIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocationSearching', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M41.88 22.17C40.96 13.83 34.34 7.21004 26 6.29004V2.17004H22V6.29004C13.66 7.21004 7.04 13.83 6.12 22.17H2V26.17H6.12C7.04 34.51 13.66 41.13 22 42.05V46.17H26V42.05C34.34 41.13 40.96 34.51 41.88 26.17H46V22.17H41.88ZM24 38.17C16.27 38.17 10 31.9 10 24.17C10 16.44 16.27 10.17 24 10.17C31.73 10.17 38 16.44 38 24.17C38 31.9 31.73 38.17 24 38.17Z" />
    </svg>
  );
};

export { LocationSearchingIcon };
