import cn from 'classnames';

const InputIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Input', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 6.02002H6C3.79 6.02002 2 7.81002 2 10.02V18H6V9.98002H42V38.04H6V30H2V38.02C2 40.23 3.79 41.98 6 41.98H42C44.21 41.98 46 40.22 46 38.02V10.02C46 7.81002 44.21 6.02002 42 6.02002ZM22 32L30 24L22 16V22H2V26H22V32Z" />
    </svg>
  );
};

export { InputIcon };
