import cn from 'classnames';

const AirlineSeatFlatIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AirlineSeatFlat', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M44 22V26H18V14H36C40.42 14 44 17.58 44 22ZM4 28V32H16V36H32V32H44V28H4ZM14.29 24.2C16.61 21.83 16.57 18.03 14.2 15.72C11.83 13.4 8.03 13.44 5.72 15.81C3.4 18.18 3.44 21.98 5.81 24.29C8.17 26.61 11.97 26.57 14.29 24.2Z" />
    </svg>
  );
};

export { AirlineSeatFlatIcon };
