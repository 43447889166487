import { createContext, useCallback, useMemo } from 'react';

import { useStandardizedStakingModals } from 'hooks/useStandardizedStakingModals';

import getAutoCompoundStep from '../utils/getAutoCompoundStep';
import hasVaultLevelPermissions from 'utils/permissions/hasVaultLevelPermissions';

import {
  IAMPermissionAction,
  useAutoCompoundEnabledQuery,
} from 'generated/graphql';

import { ProviderProps, StandardizedStakingContextProps } from './types';

import AutoCompoundModal from '../AutoCompound/AutoCompoundModal';
import StandardizedStakingDetailsModal from '../StandardizedStakingDetailsModal';
import { StandardizedClaimRewardsWizard } from '../StandardizedStakingWizard/StandardizedClaimRewardsWizard';
import { StandardizedStakeWizard } from '../StandardizedStakingWizard/StandardizedStakeWizard';
import { StandardizedUnstakeWizard } from '../StandardizedStakingWizard/StandardizedUnstakeWizard';
import { AutoCompoundMode, StandardizedStakingMode } from '../constants';

export const StandardizedStakingContext =
  createContext<StandardizedStakingContextProps>(
    {} as StandardizedStakingContextProps,
  );

const StandardizedStakingProvider = ({
  walletWithStakingInfo,
  assetTypeInfo,
  standardizedStakingMode,
  validatorAddress,
}: ProviderProps) => {
  const walletID = walletWithStakingInfo.walletID;

  // StandardizedStakingProviderWrapper already guarantes asset and staking are not null
  const asset = walletWithStakingInfo.assets[0]!;
  const standardizedStakingInfo = asset.staking!;
  const vault = walletWithStakingInfo.vault;

  const { openStakeDetailsModal, clearStakingQueryParams } =
    useStandardizedStakingModals();

  const { data: autoCompoundSettingsData } = useAutoCompoundEnabledQuery({
    variables: {
      walletId: walletID,
    },
    skip: !standardizedStakingMode,
  });

  const autoCompoundFormStep = getAutoCompoundStep(
    standardizedStakingInfo.autoCompoundForm?.steps ?? [],
  );

  const autoCompoundEnabled = useMemo(() => {
    const wallet = autoCompoundSettingsData?.wallets.edges[0];
    const walletAsset = wallet?.node.assets[0];
    return walletAsset?.staking?.autoCompoundEnabled ?? false;
  }, [autoCompoundSettingsData]);

  const resetStandardizedStaking = useCallback(() => {
    clearStakingQueryParams();
  }, [clearStakingQueryParams]);

  const isAutoCompoundMode =
    standardizedStakingMode === StandardizedStakingMode.ENABLE_AUTO_COMPOUND ||
    standardizedStakingMode === StandardizedStakingMode.DISABLE_AUTO_COMPOUND;

  const resetAndReturnToDetailsView = () => {
    openStakeDetailsModal({
      walletID,
      assetTypeID: assetTypeInfo.assetTypeID,
    });
  };

  const [hasStakingPermission] = useMemo(() => {
    return hasVaultLevelPermissions({
      vaultIam: vault.iam,
      permissions: [IAMPermissionAction.STAKING_INITIATE],
    });
  }, [vault.iam]);

  return (
    <StandardizedStakingContext.Provider
      value={{
        standardizedStakingInfo,
        validatorAddress,
        standardizedStakingMode,
        autoCompoundEnabled,
        assetTypeInfo,
        walletWithStakingInfo,
        walletAsset: asset,
        hasStakingPermission: hasStakingPermission === true,
      }}
    >
      <StandardizedStakingDetailsModal
        isOpen={
          standardizedStakingMode === StandardizedStakingMode.VIEW_DETAILS
        }
        onClose={() => {
          standardizedStakingMode === StandardizedStakingMode.VIEW_DETAILS &&
            resetStandardizedStaking();
        }}
        walletId={walletID}
      />

      <StandardizedStakeWizard
        isOpen={standardizedStakingMode === StandardizedStakingMode.STAKE}
        onClose={() => resetStandardizedStaking()}
        disclosureID={standardizedStakingInfo.disclosureID}
      />
      <StandardizedUnstakeWizard
        isOpen={
          standardizedStakingMode === StandardizedStakingMode.UNSTAKE &&
          !!validatorAddress
        }
        onClose={resetAndReturnToDetailsView}
        validatorAddress={validatorAddress}
      />
      <StandardizedClaimRewardsWizard
        isOpen={
          standardizedStakingMode === StandardizedStakingMode.CLAIM_REWARDS &&
          !!validatorAddress
        }
        onClose={resetAndReturnToDetailsView}
        validatorAddress={validatorAddress}
      />

      {/* Can be used for Enabling or Disabling auto compound  */}
      <AutoCompoundModal
        isOpen={isAutoCompoundMode}
        autoCompoundMode={standardizedStakingMode as AutoCompoundMode}
        autoCompoundFormStep={autoCompoundFormStep}
        onClose={resetAndReturnToDetailsView}
      />
    </StandardizedStakingContext.Provider>
  );
};

export default StandardizedStakingProvider;
