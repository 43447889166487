import { getHours } from 'date-fns';

import { convertDateToNanos } from '@anchorage/common/dist/utils/dates';

type Props = {
  addedTime?: string;
  firstDayMessage?: string;
};

const getUserGreeting = ({
  addedTime,
  firstDayMessage = 'Welcome to Anchorage Digital',
}: Props) => {
  let isFirstDay = false;
  const now = new Date(Date.now());
  if (addedTime) {
    const addedNanos = Number(addedTime);
    const nowNanos = Number(convertDateToNanos(now));

    // 1 day in nanoseconds
    // 24 hours * 60 min * 60 s * 10^9 ns
    const oneDayNanos = 24 * 60 * 60 * 1000000000;

    if (nowNanos - addedNanos < oneDayNanos) {
      isFirstDay = true;
    }
  }

  if (isFirstDay) {
    return firstDayMessage;
  }

  const hourOfDay = getHours(now);

  if (hourOfDay < 12) {
    return 'Good morning';
  } else if (hourOfDay < 18) {
    return 'Good afternoon';
  } else if (hourOfDay <= 24) {
    return 'Good evening';
  }

  return "Happy you're here";
};

export default getUserGreeting;
