import cn from 'classnames';

const LeakRemoveIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LeakRemove', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M20 6H16C16 6.73 15.91 7.44 15.76 8.13L18.95 11.32C19.62 9.67 20 7.88 20 6ZM6 8.55L11.68 14.23C10.06 15.34 8.11 16 6 16V20C9.22 20 12.17 18.9 14.53 17.08L17.38 19.93C14.29 22.47 10.32 24 6 24V28C11.43 28 16.39 26.03 20.22 22.77L25.23 27.78C21.97 31.61 20 36.57 20 42H24C24 37.68 25.53 33.71 28.07 30.61L30.92 33.46C29.1 35.83 28 38.78 28 42H32C32 39.89 32.66 37.94 33.78 36.32L39.46 42L42 39.45L8.55 6L6 8.55ZM28 6H24C24 9 23.25 11.83 21.95 14.32L24.88 17.25C26.85 13.95 28 10.12 28 6ZM39.87 32.24C40.56 32.09 41.27 32 42 32V28C40.12 28 38.33 28.38 36.69 29.05L39.87 32.24ZM30.75 23.12L33.68 26.05C36.17 24.75 39 24 42 24V20C37.88 20 34.05 21.15 30.75 23.12Z" />
    </svg>
  );
};

export { LeakRemoveIcon };
