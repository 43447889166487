import cn from 'classnames';

const GolfCourseIcon = (props: SVGProps): JSX.Element => {
  const { title = 'GolfCourse', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M39 36C37.34 36 36 37.34 36 39C36 40.66 37.34 42 39 42C40.66 42 42 40.66 42 39C42 37.34 40.66 36 39 36ZM34 11.85L18 4V40H14V36.54C10.42 37.23 8 38.52 8 40C8 42.21 13.37 44 20 44C26.63 44 32 42.21 32 40C32 38.02 27.67 36.38 22 36.06V17.96L34 11.85Z" />
    </svg>
  );
};

export { GolfCourseIcon };
