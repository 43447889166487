import React from 'react';

export default function HourglassIcon(props: SVGProps): JSX.Element {
  const { title = "Hourglass", ...restProps } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 229 206"
      fill="none"
      {...restProps}
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_956_10972)">
        <path
          d="M8.67 1.38501H220.12C224.21 1.38501 227.54 4.70501 227.54 8.80501V204.615H1.25V8.80501C1.25 4.70501 4.57 1.38501 8.67 1.38501Z"
          stroke="#4183C5"
          strokeWidth="2.5"
          strokeMiterlimit="10"
        />
        <path
          d="M181.53 21.275C183.662 21.275 185.39 19.5468 185.39 17.415C185.39 15.2832 183.662 13.555 181.53 13.555C179.398 13.555 177.67 15.2832 177.67 17.415C177.67 19.5468 179.398 21.275 181.53 21.275Z"
          fill="white"
        />
        <path
          d="M194.95 21.275C197.082 21.275 198.81 19.5468 198.81 17.415C198.81 15.2832 197.082 13.555 194.95 13.555C192.818 13.555 191.09 15.2832 191.09 17.415C191.09 19.5468 192.818 21.275 194.95 21.275Z"
          fill="white"
        />
        <path
          d="M208.37 21.275C210.502 21.275 212.23 19.5468 212.23 17.415C212.23 15.2832 210.502 13.555 208.37 13.555C206.238 13.555 204.51 15.2832 204.51 17.415C204.51 19.5468 206.238 21.275 208.37 21.275Z"
          fill="white"
        />
        <path
          d="M97 37.825C97 37.825 75.96 69.585 106.86 96.155C106.86 96.155 113.34 101.695 111.77 106.105C110.61 111.115 101.71 110.785 101.71 110.785C60.99 112.145 57.49 150.075 57.49 150.075"
          stroke="white"
          strokeWidth="2.5"
          strokeMiterlimit="10"
        />
        <path
          d="M168.26 62.915C168.26 62.915 164.77 100.845 124.04 102.205C124.04 102.205 115.52 102.475 113.98 106.885C111.75 111.515 118.89 116.835 118.89 116.835C149.79 143.405 128.75 175.165 128.75 175.165"
          stroke="white"
          strokeWidth="2.5"
          strokeMiterlimit="10"
        />
        <path
          d="M135.44 176.724L51.3655 147.133L49.3934 152.737L133.468 182.327L135.44 176.724Z"
          fill="#4183C5"
        />
        <path
          d="M176.429 59.9654L92.3542 30.3746L90.3822 35.9777L174.457 65.5685L176.429 59.9654Z"
          fill="#4183C5"
        />
        <path
          d="M94.1001 79.985C94.1001 79.985 110.12 99.925 144.95 97.265"
          stroke="white"
          strokeWidth="2.5"
          strokeMiterlimit="10"
        />
        <path
          d="M59.05 143.135C59.05 143.135 61.5 139.445 75.85 143.415C90.2 147.385 98.6601 145.405 101.58 136.505C104.5 127.605 111.75 106.205 111.75 106.205"
          stroke="white"
          strokeWidth="2.5"
          strokeMiterlimit="10"
        />
        <path
          d="M113.75 107.465C113.75 107.465 103.12 136.985 104.28 142.915C105.44 148.845 113.63 151.015 119.07 150.885C124.51 150.755 133.04 151.835 135.29 156.035"
          stroke="white"
          strokeWidth="2.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_956_10972">
          <rect
            width="228.78"
            height="205.73"
            fill="white"
            transform="translate(0 0.13501)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
