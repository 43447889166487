import {
  AssetTagType,
  DESTINATION_TAG_ASSETS,
  READABLE_ASSET_TAG,
} from 'constants/assets';

import Text from '@anchorage/common/dist/components/Text';
import CopyToClipboard from '@anchorage/frontoffice/components/CopyToClipboard';

import type { AssetTypeID } from 'generated/graphql';

import css from './styles.module.scss';

import SectionRow from '../../shared/SectionRow';

type Props = {
  value: string;
  assetTypeID: AssetTypeID;
  requiresMemo: boolean;
};

const AssetTagSection = ({ value, assetTypeID, requiresMemo }: Props) => {
  if (!requiresMemo) {
    return null;
  }

  const title = DESTINATION_TAG_ASSETS.includes(assetTypeID)
    ? READABLE_ASSET_TAG[AssetTagType.DESTINATION_TAG]
    : READABLE_ASSET_TAG[AssetTagType.MEMO];
  return (
    <SectionRow className={css.section} title={title}>
      <div className={css.destinationSectionContent}>
        <Text className={css.destinationText} size="small" type="mono">
          {value}
        </Text>
        <CopyToClipboard
          className={css.showOnHover}
          successText={`"${value}" copied`}
          textToCopy={value}
          tooltipPosition="left"
        />
      </div>
    </SectionRow>
  );
};

export default AssetTagSection;
