import cn from 'classnames';

const GridOffIcon = (props: SVGProps): JSX.Element => {
  const { title = 'GridOff', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_6_8401)">
        <path d="M16 7.99999V10.91L20 14.91V7.99999H28V16H21.09L25.09 20H28V22.91L32 26.91V20H40V28H33.09L37.09 32H40V34.91L44 38.91V7.99999C44 5.78999 42.21 3.99999 40 3.99999H9.09L13.09 7.99999H16ZM32 7.99999H40V16H32V7.99999ZM2.55 2.54999L0 5.08999L4 9.08999V40C4 42.21 5.79 44 8 44H38.91L42.91 48L45.45 45.45L2.55 2.54999ZM20 25.09L22.91 28H20V25.09ZM8 13.09L10.91 16H8V13.09ZM16 40H8V32H16V40ZM16 28H8V20H14.91L16 21.09V28ZM28 40H20V32H26.91L28 33.09V40ZM32 40V37.09L34.91 40H32Z" />
      </g>
      <defs>
        <clipPath id="clip0_6_8401">
          <rect width="48" height="48" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { GridOffIcon };
