import cn from 'classnames';
// Utils
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Components
import { Button } from '../../../components';
import Tabs, { Tab } from '../../../components/Tabs';

// Types
import { DepositAttributionWalletType } from '../../../types/graphql';

// Styles
import css from './index.css';

import PendingAttributionsAddresses from './PendingAttributionsAddresses';
import PendingAttributionsDetails from './PendingAttributionsDetails';

interface DepositOriginator {
  originatorName: string;
  originatorCountry: string;
}

export interface AttributeDepositForm {
  originator: DepositOriginator;
  notes?: string;
  trustedSource: boolean;
  disclaimer?: boolean;
  walletType: DepositAttributionWalletType;
}

export interface CountryOption {
  label: string;
  value: string;
}

const PendingAttributionsDrawerContent = ({
  addresses = [],
  allowNotes,
  availableCountries,
  defaultValues,
  disableWhenSpam = false,
  isAlreadyTrustedSource = false,
  isLoading,
  onClose,
  onSubmit,
  unflagAsSpam,
}: {
  addresses: string[];
  allowNotes?: boolean;
  availableCountries: CountryOption[];
  defaultValues?: Partial<AttributeDepositForm>;
  disableWhenSpam?: boolean;
  isAlreadyTrustedSource?: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (data: AttributeDepositForm) => void;
  unflagAsSpam?: () => void;
}) => {
  const notesDefaultValue = allowNotes
    ? { notes: defaultValues?.notes ?? '' }
    : undefined;
  const methods = useForm<AttributeDepositForm>({
    mode: 'onChange',
    defaultValues: {
      originator: defaultValues?.originator ?? {
        originatorName: '',
        originatorCountry: '',
      },
      trustedSource: !defaultValues,
      disclaimer: false,
      walletType: defaultValues?.walletType,
      ...notesDefaultValue,
    },
  });

  const tabs = useMemo(
    () => {
      if (addresses.length > 0) {
        return [
          {
            id: 'pending-attributions-details',
            text: 'Details',
          },
          {
            id: 'pending-attributions-addresses',
            text: `${pluralize('Address', addresses.length)} (${addresses.length})`,
          },
        ]
      }
      return [
        {
          id: 'pending-attributions-details',
          text: 'Details',
        },
      ]
    },
    [addresses.length]
  );
  const [selectedTab, setSelectedTab] = useState(tabs[0]?.id);

  const detailsClasses = cn({
    [css.detailsHidden]: selectedTab === 'pending-attributions-addresses',
  });

  useEffect(() => {
    setSelectedTab(tabs[0]?.id);
  },[setSelectedTab, tabs]);

  const isValidWhenSpam =
    disableWhenSpam
    && methods.getValues('disclaimer')
    && allowNotes
    && methods.getValues('notes') !== '';

  const isFormValid = isValidWhenSpam || methods.formState.isValid;

  const onFormSubmit = useCallback(
    (data: AttributeDepositForm) => {
      const finalData = {
        originator: data.originator,
        notes: data.notes,
        trustedSource: addresses.length > 0 ? data.trustedSource : false,
        walletType: data.walletType,
      };
      onSubmit(finalData);
    },
    [onSubmit]
  );

  return (
    <>
      <Tabs width="228px" label="deposit attributions" className={css.tabs}>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            selected={selectedTab === tab.id}
            onClick={() => setSelectedTab(tab.id)}
          >
            {tab.text}
          </Tab>
        ))}
      </Tabs>
      <form
        onSubmit={methods.handleSubmit(onFormSubmit)}
        className={css.form}
        autoComplete="off"
      >
        <FormProvider {...methods}>
          <div className={detailsClasses}>
            <PendingAttributionsDetails
              allowNotes={allowNotes}
              availableCountries={availableCountries}
              disableWhenSpam={disableWhenSpam}
              hasMultipleAddresses={addresses.length > 1}
              hasAddresses={addresses.length > 0}
              isAlreadyTrustedSource={isAlreadyTrustedSource}
              isEditMode={!!defaultValues}
              unflagAsSpam={unflagAsSpam}
            />
          </div>
          {selectedTab === 'pending-attributions-addresses' && (
            <PendingAttributionsAddresses addresses={addresses} />
          )}
          <div className={css.detailsButtons}>
            <Button onClick={onClose} type="tertiary">
              Cancel
            </Button>
            <Button
              htmlType="submit"
              isLoading={isLoading}
              disabled={!isFormValid}
            >
              {defaultValues ? 'Save' : 'Attribute deposit'}
            </Button>
          </div>
        </FormProvider>
      </form>
    </>
  );
};

export default PendingAttributionsDrawerContent;
