import cn from 'classnames';

const GridOnIcon = (props: SVGProps): JSX.Element => {
  const { title = 'GridOn', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 4H8C5.79 4 4 5.79 4 8V40C4 42.21 5.79 44 8 44H40C42.21 44 44 42.21 44 40V8C44 5.79 42.21 4 40 4ZM16 40H8V32H16V40ZM16 28H8V20H16V28ZM16 16H8V8H16V16ZM28 40H20V32H28V40ZM28 28H20V20H28V28ZM28 16H20V8H28V16ZM40 40H32V32H40V40ZM40 28H32V20H40V28ZM40 16H32V8H40V16Z" />
    </svg>
  );
};

export { GridOnIcon };
