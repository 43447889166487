import cn from 'classnames';

const DeleteForeverIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DeleteForever', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M12 38C12 40.2 13.8 42 16 42H32C34.2 42 36 40.2 36 38V14H12V38ZM16.93 23.76L19.76 20.93L24 25.17L28.24 20.93L31.07 23.76L26.83 28L31.07 32.24L28.24 35.07L24 30.83L19.76 35.07L16.93 32.24L21.17 28L16.93 23.76ZM31 8L29 6H19L17 8H10V12H38V8H31Z" />
    </svg>
  );
};

export { DeleteForeverIcon };
