import cn from 'classnames';

const RefreshIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Refresh', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path
        id="Icon"
        d="M11.7803 1.7207L14.7803 4.7207C15.0732 5.0136 15.0732 5.48847 14.7803 5.78136L11.7803 8.78136L10.7197 7.7207L12.4394 6.00097H12C10.6119 6.00097 9.26669 6.48229 8.19365 7.36291C7.12061 8.24353 6.38611 9.46897 6.1153 10.8304C5.84449 12.1919 6.05412 13.6051 6.70848 14.8294C7.36284 16.0536 8.42144 17.013 9.70391 17.5443C10.9864 18.0755 12.4134 18.1456 13.7417 17.7426C15.0701 17.3397 16.2176 16.4886 16.9888 15.3344C17.76 14.1802 18.1072 12.7943 17.9711 11.4129C17.8351 10.0314 17.2242 8.73989 16.2427 7.75833L17.3033 6.69767C18.5303 7.92462 19.2938 9.53904 19.4639 11.2658C19.634 12.9927 19.2 14.725 18.236 16.1678C17.272 17.6105 15.8376 18.6743 14.1771 19.178C12.5167 19.6817 10.733 19.5941 9.12989 18.9301C7.5268 18.2661 6.20355 17.0667 5.3856 15.5364C4.56765 14.0062 4.30561 12.2396 4.64412 10.5378C4.98264 8.83597 5.90076 7.30417 7.24206 6.2034C8.58336 5.10262 10.2648 4.50097 12 4.50097H12.4393L10.7197 2.78136L11.7803 1.7207Z"
      />
    </svg>
  );
};

export { RefreshIcon };
