import cn from 'classnames';

const HdrStrongIcon = (props: SVGProps): JSX.Element => {
  const { title = 'HdrStrong', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M34 12C27.37 12 22 17.37 22 24C22 30.63 27.37 36 34 36C40.63 36 46 30.63 46 24C46 17.37 40.63 12 34 12ZM10 16C5.58 16 2 19.58 2 24C2 28.42 5.58 32 10 32C14.42 32 18 28.42 18 24C18 19.58 14.42 16 10 16ZM10 28C7.79 28 6 26.21 6 24C6 21.79 7.79 20 10 20C12.21 20 14 21.79 14 24C14 26.21 12.21 28 10 28Z" />
    </svg>
  );
};

export { HdrStrongIcon };
