import cn from 'classnames';
// Utils
import pluralize from 'pluralize';
import React, { useMemo } from 'react';
// Hooks
import { FormProvider, useForm, useWatch } from 'react-hook-form';



import { Card, Loader, Text } from '../../../components';
import { CompleteOutlineIcon, ErrorIcon, SearchIcon } from '../../../components/Icons';
import { Pagination } from '../../../components/TableV2';



import { useDeepCompare } from '../../../hooks';



import { countByOperation, getPairsOperationAddress, transformPendingAttributionsDataByOperation } from './utils';



// Styles
import css from './index.css';



// Types
import type { PendingAttributionsCardProps, PendingAttributionsForm, PendingAttributionsFormField } from './_types';



// Components
import PendingAttributionsActions from '../PendingAttributionsActions';
import PendingAttributionsList from '../PendingAttributionsList';


const ErrorMessage = () => (
  <div className={css.errorContainer}>
    <ErrorIcon className={css.errorIcon} />
    <Text type="heading" size="small">
      It was not possible to get the deposit attributions data
    </Text>
    <Text size="small">Please reload and try again</Text>
  </div>
);

const EmptyState = () => (
  <Card className={cn([css.card, css.emptyNoResultsCard])}>
    <CompleteOutlineIcon className={css.emptyNoResultsIcon} />
    <div>
      <Text type="heading" size="small" className={css.emptyNoResultsTitle}>
        No pending deposit attributions
      </Text>
      <Text type="body" size="small" className={css.emptyNoResultsText}>
        Deposits that can’t be attributed automatically will appear here
      </Text>
    </div>
  </Card>
);

const NoResults = () => (
  <div className={css.emptyNoResultsCard}>
    <SearchIcon className={css.emptyNoResultsIcon} />
    <div>
      <Text type="heading" size="small" className={css.emptyNoResultsTitle}>
        No results found
      </Text>
      <Text type="body" size="small" className={css.emptyNoResultsText}>
        Try adjusting or clearing filters
      </Text>
    </div>
  </div>
);

const PendingAttributionsCard = ({
  hasError,
  hasSearch = false,
  initialSearchTerm = '',
  isLoading,
  isManualSpamEnabled = false,
  onClickAttribute,
  onClickFlagAsSpam,
  onClickOperationId,
  onSearch,
  pendingAttributionsData,
  searchPlaceholder,
  tablePagination,
  isDisabled = false,
}: PendingAttributionsCardProps) => {
  const methods = useForm<PendingAttributionsForm>({
    defaultValues: {
      byOperation: {},
    }
  });

  const listData = useMemo(
    () => transformPendingAttributionsDataByOperation(pendingAttributionsData),
    [useDeepCompare(pendingAttributionsData)]
  );

  const checkboxes: Record<string, PendingAttributionsFormField> = useWatch({
    name: 'byOperation',
    control: methods.control,
  });

  // TODO: after we know what we need to send to the BE, we might not need these counters
  // because we might already have the data.
  const selectedCounter = useMemo(
    () => countByOperation(checkboxes),
    [useDeepCompare(checkboxes)]
  );

  const attributionPairs = useMemo(
    () => getPairsOperationAddress(checkboxes),
    [useDeepCompare(checkboxes)]
  );

  const statusMessage =
    selectedCounter.addresses || selectedCounter.operations ? (
      <>
      {selectedCounter.addresses ? (
        <>
          <Text size="small">
            {`${selectedCounter.addresses} ${pluralize(
              'address',
              selectedCounter.addresses
            )}`}
          </Text>
          <span className={css.divider} />
        </>
      ) : null}
        <Text size="small">
          {`${selectedCounter.operations} ${pluralize(
            'operation',
            selectedCounter.operations
          )} `}
        </Text>
      </>
    ) : (
      ''
    );

  const isEmptyState =
    !hasSearch && !isLoading && !hasError && !listData.length;

  if (isEmptyState) {
    return <EmptyState />;
  }

  return (
    <Card className={css.card}>
      <FormProvider {...methods}>
        <form>
          <PendingAttributionsActions
            initialSearchTerm={initialSearchTerm}
            onChangeSearch={onSearch}
            statusMessage={statusMessage}
            onClickAttribute={() => onClickAttribute(attributionPairs)}
            onClickFlagAsSpam={() =>
              onClickFlagAsSpam?.(
                attributionPairs.map(
                  (attributionPair) => attributionPair.operationID
                )
              )
            }
            className={css.actions}
            nrOfSelectedDeposits={selectedCounter.operations}
            searchPlaceholder={searchPlaceholder}
            isManualSpamEnabled={isManualSpamEnabled}
          />
          {isLoading && <Loader />}
          {hasError && !isLoading && <ErrorMessage />}
          {hasSearch && !isLoading && !hasError && !listData.length && (
            <NoResults />
          )}
          {!isLoading && !hasError && !!listData.length && (
            <PendingAttributionsList
              onClickOperationId={onClickOperationId}
              pendingAttributions={listData}
              isDisabled={isDisabled}
            />
          )}
          <Pagination
            {...tablePagination}
            hasData={!!listData}
            isLoading={isLoading}
          />
        </form>
      </FormProvider>
    </Card>
  );
};

export default PendingAttributionsCard;
