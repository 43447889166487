import React from 'react';

import { useDeepCompare } from '@anchorage/common/dist/hooks';
import { normalizeCanonicalOperationPayload } from '@anchorage/vsp';

import type { OperationDrawer } from 'generated/graphql';

import CanonicalPayloadSection from '../../../shared/CanonicalPayloadSection';
import DAppBanner from './DAppBanner';

type OffChainOperationType = Extract<
  OperationDrawer.operation,
  { __typename: 'OffChainOperation' }
>;

interface OffChainOperationProps {
  operation: OffChainOperationType;
}

const OffChainOperation: React.FC<OffChainOperationProps> = ({ operation }) => {
  const {
    operationInfo: { canonicalOperationPayload },
  } = operation;

  const { fields } = normalizeCanonicalOperationPayload({
    canonicalOperationPayload: canonicalOperationPayload ?? '',
  });

  const dappID = React.useMemo(
    //@ts-ignore
    () => fields.find((field) => field.label === 'App')?.fallbackText,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([fields]),
  );

  return (
    <>
      {dappID ? <DAppBanner dappID={dappID} /> : null}
      {canonicalOperationPayload && (
        <CanonicalPayloadSection
          canonicalOperationPayload={canonicalOperationPayload}
        />
      )}
    </>
  );
};

export default OffChainOperation;
