import { noop } from 'lodash';
import debounce from 'lodash/debounce';
import numeral from 'numeral';
import React from 'react';

// Components
import { ButtonGroup, InputV2, Text } from '../../../components';
// Hooks & Utils
import useButtonGroup from '../../../components/ButtonGroup/useButtonGroup';

// Styles
import css from './index.css';

// Types
import type { NFTPageHeaderProps } from './_types';

import { NFTContentNFTViews, NFTContentViews } from '../_constants';
// Constants
import { CONTENT_VIEW_BTNS, NFT_CONTENT_NFT_VIEW_BTNS } from './_constants';
import NFTSortSelect from './elements/NFTSortSelect';

const NFTPageHeader: React.FC<NFTPageHeaderProps> = ({
  metadata,
  title,
  onViewChange = noop,
  onSearchChange = noop,
  onNFTViewChange = noop,
  onSortChange = noop,
  isNFTViewToggleEnabled = true,
  isSearchable = true,
  isSortable = true,
}) => {
  const onChangeHandler = debounce(onSearchChange, 400);

  const [, viewTypeButtonGroup] = useButtonGroup({
    onChange: onViewChange,
    defaultValue: NFTContentViews.GRID,
  });

  const [{ selectedButton: nftViewType }, nftViewTypeButtonGroup] =
    useButtonGroup({
      onChange: onNFTViewChange,
      defaultValue: NFTContentNFTViews.SINGLE,
    });

  return (
    <div className={css.headerContainer} data-testid="nft-page-header">
      {title && (
        <Text type="heading" size="huge" className={css.title}>
          {title}
        </Text>
      )}
      <div className={css.metadataContainer}>
        <div className={css.metadata}>
          <Text type="heading" size="huge" className={css.metaStat}>
            {numeral(metadata.itemNumber).format('0,0')}
          </Text>
          <Text size="medium" className={css.metaLabel}>
            ITEMS
          </Text>
        </div>
        {metadata.collectionNumber && (
          <div className={css.metadata}>
            <Text type="heading" size="huge" className={css.metaStat}>
              {numeral(metadata.collectionNumber).format('0,0')}
            </Text>
            <Text size="medium" className={css.metaLabel}>
              COLLECTIONS
            </Text>
          </div>
        )}
        <div className={css.metadata}>
          <Text type="heading" size="huge" className={css.metaStat}>
            ~${numeral(metadata.estimatedValue).format('0,0.00')}
          </Text>
          <Text size="medium" className={css.metaLabel}>
            ESTIMATED VALUE
          </Text>
        </div>
        {metadata.vaultNumber && (
          <div className={css.metadata}>
            <Text type="heading" size="huge" className={css.metaStat}>
              {numeral(metadata.vaultNumber).format('0,0')}
            </Text>
            <Text size="medium" className={css.metaLabel}>
              VAULTS
            </Text>
          </div>
        )}
      </div>
      <>
        {isSearchable ? (
          <div className={css.searchContainer}>
            <InputV2
              placeholder="Search NFTs"
              type="search"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeHandler(e.target.value);
              }}
            />
          </div>
        ) : null}
      </>
      <div className={css.controlsContainer}>
        <ButtonGroup
          buttons={CONTENT_VIEW_BTNS}
          {...viewTypeButtonGroup}
          buttonProps={{
            className: css.formatViewButton,
          }}
        />
        <div className={css.contentControls}>
          {isNFTViewToggleEnabled ? (
            <ButtonGroup
              buttons={NFT_CONTENT_NFT_VIEW_BTNS}
              buttonProps={{
                className: css.nftViewButton,
              }}
              {...nftViewTypeButtonGroup}
            />
          ) : null}
          {isSortable ? (
            <NFTSortSelect
              nftViewType={nftViewType ?? NFTContentNFTViews.SINGLE}
              onSortChange={onSortChange}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NFTPageHeader;
