import { formatAccountNumber } from 'utils/format';

import { OperationDrawerReceivingStandingInstructionFieldsFragment } from 'generated/graphql';

export const getBankAccountInfo = (
  receivingStandingInstruction: OperationDrawerReceivingStandingInstructionFieldsFragment | null,
): string[] => {
  if (!receivingStandingInstruction) {
    return [];
  }

  const {
    standingInstruction: { beneficiaryName, fiName, accountNumber },
  } = receivingStandingInstruction;

  return [beneficiaryName, `${fiName} ${formatAccountNumber(accountNumber)}`];
};
