import {
  StakingStepImplementations,
  StandardizedStakingStep,
  StandardizedStakingStepFragment,
} from 'generated/graphql';

// Assuming we will only have one ValidatorInputStep step in a stake flow
const getValidatorInputStep = (
  stakingSteps: StandardizedStakingStepFragment[],
) => {
  const validatorInputStep = stakingSteps.find(
    (step) => step.__typename === StakingStepImplementations.ValidatorInputStep,
  ) as StandardizedStakingStep.ValidatorInputStepInlineFragment | undefined;

  return validatorInputStep;
};

export default getValidatorInputStep;
