import cn from 'classnames';

const DirectionsRailwayIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DirectionsRailway', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M8 31C8 34.87 11.14 38 15 38L12 41V42H36V41L33 38C36.87 38 40 34.87 40 31V10C40 3 32.84 2 24 2C15.16 2 8 3 8 10V31ZM24 34C21.79 34 20 32.21 20 30C20 27.79 21.79 26 24 26C26.21 26 28 27.79 28 30C28 32.21 26.21 34 24 34ZM36 20H12V10H36V20Z" />
    </svg>
  );
};

export { DirectionsRailwayIcon };
