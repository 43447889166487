import * as React from 'react';

import type { OperationDrawer } from 'generated/graphql';

type OperationContextProps = {
  operation: OperationDrawer.operation;
};

export const OperationContext = React.createContext<
  OperationContextProps | undefined
>(undefined);

const useOperationContext = (): OperationContextProps | undefined =>
  React.useContext(OperationContext);

export default useOperationContext;
