import React from 'react';

// Styles
import css from '../index.css';

// Types
import type { NFTItemContentProps } from '../_types';

// Components
import NFTCard from '../../NFTCard';

const SingleNFTItemContent: React.FC<NFTItemContentProps> = ({
  index,
  data,
}) => (
  <div
    data-testid="nft-item-content"
    key={`${data.title}-${index}`}
    className={css.singleItem}
  >
    <NFTCard {...data} />
  </div>
);

export default SingleNFTItemContent;
