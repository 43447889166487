import cn from 'classnames';

const LocalCarWashIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalCarWash', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M34 9.99998C35.66 9.99998 37 8.65998 37 6.99998C37 4.99998 34 1.59998 34 1.59998C34 1.59998 31 4.99998 31 6.99998C31 8.65998 32.34 9.99998 34 9.99998ZM24 9.99998C25.66 9.99998 27 8.65998 27 6.99998C27 4.99998 24 1.59998 24 1.59998C24 1.59998 21 4.99998 21 6.99998C21 8.65998 22.34 9.99998 24 9.99998ZM14 9.99998C15.66 9.99998 17 8.65998 17 6.99998C17 4.99998 14 1.59998 14 1.59998C14 1.59998 11 4.99998 11 6.99998C11 8.65998 12.34 9.99998 14 9.99998ZM37.84 16.02C37.43 14.84 36.31 14 35 14H13C11.69 14 10.57 14.84 10.16 16.02L6 28V44C6 45.1 6.9 46 8 46H10C11.11 46 12 45.1 12 44V42H36V44C36 45.1 36.9 46 38 46H40C41.11 46 42 45.1 42 44V28L37.84 16.02ZM13 36C11.34 36 10 34.66 10 33C10 31.34 11.34 30 13 30C14.66 30 16 31.34 16 33C16 34.66 14.66 36 13 36ZM35 36C33.34 36 32 34.66 32 33C32 31.34 33.34 30 35 30C36.66 30 38 31.34 38 33C38 34.66 36.66 36 35 36ZM10 26L13 17H35L38 26H10Z" />
    </svg>
  );
};

export { LocalCarWashIcon };
