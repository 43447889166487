import {
  ActionList,
  createColumnHelper,
  useActionList,
} from '@latitude/action-list';
import { Button } from '@latitude/button';
import { CircleHelpIcon } from '@latitude/icons';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import { useAnalytics } from '@anchorage/analytics';
import { useAppSharedComponents } from '@anchorage/frontoffice/contexts/AppSharedComponents';
import { useAppUser } from '@anchorage/frontoffice/contexts/AppUser';
import { LEGAL_DISCLOSURES_PATH } from '@anchorage/frontoffice/utils/routes';

import { ExternalLink, WithCounter } from './components';

import { openURLInNewPage } from './helpers';

enum MenuItems {
  'WHATS_NEW' = 'What’s new',
  'LEGAL_DISCLOSURES' = 'Legal disclosures',
  'USER_GUIDE' = 'User guide',
  'CONTACT_US' = 'Contact us',
}

const ITEMS_WITH_COUNTER = ['WHATS_NEW'];
const ITEMS_WITH_EXTERNAL_LINKS = ['USER_GUIDE', 'CONTACT_US'];

const userGuideURL = '/v1/docs';
const contactUsURL = 'mailto:accountexecutive@anchorage.com';
const contactUSPortoURL = 'mailto:help@porto.xyz';

type Option = { id: string; label: string };

export const HelpMenu = () => {
  const { track } = useAnalytics();
  const { push } = useRouter();
  const { isPortoOrg } = useAppUser() || {};

  const {
    productUpdates: {
      isOpen,
      updateProductUpdatesOpenState,
      count,
      hasUpdates,
    },
  } = useAppSharedComponents();

  const menuOptions: Option[] = useMemo(() => {
    return Object.entries(MenuItems).map(([id, label]) => ({ id, label }));
  }, []);

  const columnHelper = createColumnHelper<Option>();

  const mediator = useActionList({
    data: menuOptions,
    columns: [
      columnHelper.accessor('label', {
        cell: (props) => {
          const id = props.row.original.id;
          const label = `${props.renderValue()}`;

          if (ITEMS_WITH_COUNTER.includes(id)) {
            return (
              <WithCounter
                label={label}
                counter={hasUpdates ? count : undefined}
              />
            );
          }

          if (ITEMS_WITH_EXTERNAL_LINKS.includes(id)) {
            return <ExternalLink label={label} />;
          }

          return label;
        },
      }),
    ],
  });

  const onHandleSelect = useCallback(
    (menuItem: [Option]) => {
      // we don't want to have the selection logic, so deselecting the option selected
      mediator.resetRowSelection();

      const [item] = menuItem;

      track({
        name: 'navigation:client_dashboard:topbar_helpmenu_item_clicked',
        data: {
          label: item.label,
        },
      });

      if (item.id === 'WHATS_NEW') {
        updateProductUpdatesOpenState(!isOpen);
        return;
      }

      if (item.id === 'LEGAL_DISCLOSURES') {
        push(LEGAL_DISCLOSURES_PATH);
        return;
      }

      if (item.id === 'USER_GUIDE') {
        openURLInNewPage(userGuideURL);
        return;
      }

      if (item.id === 'CONTACT_US') {
        openURLInNewPage(isPortoOrg ? contactUSPortoURL : contactUsURL);
        return;
      }
    },
    [push, updateProductUpdatesOpenState, track, isOpen, mediator, isPortoOrg],
  );

  return (
    <ActionList
      itemSize="medium"
      mediator={mediator}
      withSearch={false}
      onSelect={(item) => {
        onHandleSelect(item as [Option]);
      }}
      popoverContentProps={{ align: 'end' }}
    >
      <Button
        size="small"
        variant="strong"
        type="ghost"
        signal={hasUpdates ? { status: 'informational' } : undefined}
        leadingElement={<CircleHelpIcon title="Help" />}
      />
    </ActionList>
  );
};
