import cn from 'classnames';

const GradientIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Gradient', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M22 18H26V22H22V18ZM18 22H22V26H18V22ZM26 22H30V26H26V22ZM30 18H34V22H30V18ZM14 18H18V22H14V18ZM38 6H10C7.8 6 6 7.8 6 10V38C6 40.2 7.8 42 10 42H38C40.2 42 42 40.2 42 38V10C42 7.8 40.2 6 38 6ZM18 36H14V32H18V36ZM26 36H22V32H26V36ZM34 36H30V32H34V36ZM38 22H34V26H38V30H34V26H30V30H26V26H22V30H18V26H14V30H10V26H14V22H10V10H38V22Z" />
    </svg>
  );
};

export { GradientIcon };
