import cn from 'classnames';

const BorderLeftIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BorderLeft', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M22 42H26V38H22V42ZM22 34H26V30H22V34ZM22 10H26V6H22V10ZM22 18H26V14H22V18ZM22 26H26V22H22V26ZM14 42H18V38H14V42ZM14 10H18V6H14V10ZM14 26H18V22H14V26ZM6 42H10V6H6V42ZM38 18H42V14H38V18ZM30 42H34V38H30V42ZM38 34H42V30H38V34ZM38 6V10H42V6H38ZM38 26H42V22H38V26ZM38 42H42V38H38V42ZM30 26H34V22H30V26ZM30 10H34V6H30V10Z" />
    </svg>
  );
};

export { BorderLeftIcon };
