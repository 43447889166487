import cn from 'classnames';

const BrokenImageIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BrokenImage', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 10V23.2L36 17.2L28 25.2L20 17.2L12 25.2L6 19.2V10C6 7.8 7.8 6 10 6H38C40.2 6 42 7.8 42 10ZM36 22.8L42 28.8V38C42 40.2 40.2 42 38 42H10C7.8 42 6 40.2 6 38V24.8L12 30.8L20 22.8L28 30.8L36 22.8Z" />
    </svg>
  );
};

export { BrokenImageIcon };
