import cn from 'classnames';

const FormatListBulletedIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatListBulleted', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M8 21C6.34 21 5 22.34 5 24C5 25.66 6.34 27 8 27C9.66 27 11 25.66 11 24C11 22.34 9.66 21 8 21ZM8 9C6.34 9 5 10.34 5 12C5 13.66 6.34 15 8 15C9.66 15 11 13.66 11 12C11 10.34 9.66 9 8 9ZM8 33C6.33 33 5 34.35 5 36C5 37.65 6.35 39 8 39C9.65 39 11 37.65 11 36C11 34.35 9.67 33 8 33ZM14 38H42V34H14V38ZM14 26H42V22H14V26ZM14 10V14H42V10H14Z" />
    </svg>
  );
};

export { FormatListBulletedIcon };
