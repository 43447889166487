import { OperationPolicies } from '@anchorage/authorization';
import { useFeatureFlagsContext } from '@anchorage/feature-flags';
import { FEATURE_FLAGS } from '@anchorage/frontoffice/constants/split';

import type { OperationDrawer } from 'generated/graphql';

import WidgetWrapper from '../../../shared/WidgetWrapper';
import { UpdatedIAMRoleUsers } from '../../../widgets/IAMRoleChanges';
import { OperationDetailPolicyWidget } from '../../../widgets/Policies/OperationDetailPolicyWidget';
import VaultMetaData from '../../../widgets/VaultMetaData';

type DisableVaultOperationType = Extract<
  OperationDrawer.operation,
  { __typename: 'DisableVaultOperation' }
>;

type Props = {
  operation: DisableVaultOperationType;
};

const DisableVaultOperation = ({ operation }: Props) => {
  const { useIsFeatureActive } = useFeatureFlagsContext();
  const [isIamRoleChangesEnabled] = useIsFeatureActive(
    FEATURE_FLAGS.CD_PERMISSIONS_M3_2,
  );
  const opPolicies = new OperationPolicies(operation);
  const originalPolicies = opPolicies.updatedVaultPolicies?.original ?? [];
  const vault = originalPolicies[0]?.vault;

  return (
    <>
      {vault && <VaultMetaData vault={vault} />}
      <OperationDetailPolicyWidget
        policies={opPolicies}
        type={operation.__typename}
      />
      <WidgetWrapper>
        {isIamRoleChangesEnabled &&
          (operation.roleChanges?.changes.length ?? 0) > 0 && (
            <UpdatedIAMRoleUsers
              changes={operation.roleChanges?.changes ?? []}
            />
          )}
      </WidgetWrapper>
    </>
  );
};

export default DisableVaultOperation;
