import cn from 'classnames';

const ControlPointDuplicateIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ControlPointDuplicate', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M32 16H28V22H22V26H28V32H32V26H38V22H32V16ZM4 24C4 18.42 7.29 13.61 12.02 11.36V7.05C5.03 9.51 0 16.17 0 24C0 31.83 5.03 38.49 12.02 40.95V36.64C7.29 34.39 4 29.58 4 24ZM30 6C20.07 6 12 14.07 12 24C12 33.93 20.07 42 30 42C39.93 42 48 33.93 48 24C48 14.07 39.93 6 30 6ZM30 38C22.28 38 16 31.72 16 24C16 16.28 22.28 10 30 10C37.72 10 44 16.28 44 24C44 31.72 37.72 38 30 38Z" />
    </svg>
  );
};

export { ControlPointDuplicateIcon };
