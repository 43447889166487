import cn from 'classnames';

const LeakAddIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LeakAdd', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M12 6H6V12C9.31 12 12 9.31 12 6ZM28 6H24C24 15.94 15.94 24 6 24V28C18.15 28 28 18.15 28 6ZM20 6H16C16 11.52 11.52 16 6 16V20C13.73 20 20 13.73 20 6ZM20 42H24C24 32.06 32.06 24 42 24V20C29.85 20 20 29.85 20 42ZM36 42H42V36C38.69 36 36 38.69 36 42ZM28 42H32C32 36.48 36.48 32 42 32V28C34.27 28 28 34.27 28 42Z" />
    </svg>
  );
};

export { LeakAddIcon };
