import React from 'react';

import usePollingQuery from '../../hooks/usePollingQueryV2';

import {
  GetUncompletedSettlementsQuery,
  GetUncompletedSettlementsQueryVariables,
  useGetUncompletedSettlementsQuery,
} from '../../generated/graphql';

import css from './styles.module.scss';

import Pill from '../Pill';

const SettlementNotification: React.FC = () => {
  const { data } = usePollingQuery<
    GetUncompletedSettlementsQuery,
    GetUncompletedSettlementsQueryVariables
  >(useGetUncompletedSettlementsQuery);

  return (
    <div className={css.container}>
      {data && data.snSettlements.pageInfo.totalResults ? (
        <Pill>{data.snSettlements.pageInfo.totalResults}</Pill>
      ) : null}
    </div>
  );
};

export default SettlementNotification;
