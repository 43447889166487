import cn from 'classnames';

const FilterHdrIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FilterHdr', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M28 12L20.5 22L26.2 29.6L23 32C19.62 27.5 14 20 14 20L2 36H46L28 12Z" />
    </svg>
  );
};

export { FilterHdrIcon };
