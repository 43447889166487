import cn from 'classnames';

const AirplayIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Airplay', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 6H6C3.8 6 2 7.8 2 10V34C2 36.2 3.8 38 6 38H14V34H6V10H42V34H34V38H42C44.2 38 46 36.2 46 34V10C46 7.8 44.2 6 42 6ZM12 44H36L24 32L12 44Z" />
    </svg>
  );
};

export { AirplayIcon };
