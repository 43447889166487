import cn from 'classnames';

const BackspaceIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Backspace', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M44 6H14C12.62 6 11.53 6.7 10.81 7.76L0 23.99L10.81 40.22C11.53 41.28 12.62 42 14 42H44C46.21 42 48 40.21 48 38V10C48 7.79 46.21 6 44 6ZM38 31.17L35.17 34L28 26.83L20.83 34L18 31.17L25.17 24L18 16.83L20.83 14L28 21.17L35.17 14L38 16.83L30.83 24L38 31.17Z" />
    </svg>
  );
};

export { BackspaceIcon };
