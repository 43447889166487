import cn from 'classnames';

const Crop32Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Crop32', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 8H10C7.79 8 6 9.79 6 12V36C6 38.21 7.79 40 10 40H38C40.21 40 42 38.21 42 36V12C42 9.79 40.21 8 38 8ZM38 36H10V12H38V36Z" />
    </svg>
  );
};

export { Crop32Icon };
