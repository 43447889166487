import {
  StakingStepImplementations,
  StandardizedStakingStep,
  StandardizedStakingStepFragment,
} from 'generated/graphql';

// Assuming we will only have one AmountInputStep step in a stake flow
const getAmountInputStep = (
  stakingSteps: StandardizedStakingStepFragment[],
) => {
  const amountInputStep = stakingSteps.find(
    (step) => step.__typename === StakingStepImplementations.AmountInputStep,
  ) as StandardizedStakingStep.AmountInputStepInlineFragment | undefined;

  return amountInputStep;
};

export default getAmountInputStep;
