import cn from 'classnames';

const EventSeatIcon = (props: SVGProps): JSX.Element => {
  const { title = 'EventSeat', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M8 36V42H14V36H34V42H40V30H8V36ZM38 20H44V26H38V20ZM4 20H10V26H4V20ZM34 26H14V10C14 7.79 15.79 6 18 6H30C32.21 6 34 7.79 34 10V26Z" />
    </svg>
  );
};

export { EventSeatIcon };
