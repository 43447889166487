import * as React from 'react';

import { Text } from '@anchorage/common/dist/components';

import css from './styles.module.scss';

import SectionRow from '../../shared/SectionRow';
import WidgetWrapper from '../../shared/WidgetWrapper';

type Props = {
  vault: {
    name: string;
    description: string;
    account: { displayName: string } | null;
  };
};

const VaultMetaData = ({ vault }: Props) => {
  const { name, description, account } = vault;
  return (
    <WidgetWrapper className={css.container}>
      <SectionRow title="Vault name">
        <Text size="small">{name}</Text>
      </SectionRow>
      <SectionRow title="Vault description">
        <Text size="small">{description}</Text>
      </SectionRow>
      {account && (
        <SectionRow title="Account">
          <Text size="small">{account.displayName}</Text>
        </SectionRow>
      )}
    </WidgetWrapper>
  );
};

export default VaultMetaData;
