import BigNumber from 'bignumber.js';

import * as yup from '@anchorage/common/dist/components/Form/_yup';

import getAmountInputStep from '../utils/getAmountInputStep';
import getValidatorInputStep from '../utils/getValidatorInputStep';

import { StandardizedStakingStepFragment } from 'generated/graphql';

type Props = {
  stakingSteps: StandardizedStakingStepFragment[];
};

// Assuming we will only have one ValidatorInputStep step in a stake flow
const getValidatorInputStepValidation = (
  stakingSteps: StandardizedStakingStepFragment[],
) => {
  const validatorInputStep = getValidatorInputStep(stakingSteps);

  const validatorAddressRegex = validatorInputStep?.addressRegex ?? '';
  return {
    validatorAddress: yup
      .string()
      .required('Validator address is required')
      .matches(new RegExp(validatorAddressRegex), {
        message: 'Validator address is not valid',
        excludeEmptyString: true,
      }),
  };
};

// Assuming we will only have one AmountInputStep step in a stake flow
const getAmountInputStepValidation = (
  stakingSteps: StandardizedStakingStepFragment[],
) => {
  const amountInputStep = getAmountInputStep(stakingSteps);

  const minAmount = amountInputStep?.minAmount ?? '0';
  const maxAmount = amountInputStep?.maxAmount ?? '0';

  return {
    amount: yup
      .string()
      .required('Amount is required')
      .test((value, ctx) => {
        if (!value) {
          return ctx.createError({ message: 'The amount must be a number' });
        }

        const bigNumberValue = BigNumber(value);

        if (bigNumberValue.isNaN()) {
          return ctx.createError({ message: 'Amount must be a number' });
        }

        if (bigNumberValue.isNegative()) {
          return ctx.createError({
            message: 'Amount must be a positive number',
          });
        }

        if (bigNumberValue.isZero()) {
          return ctx.createError({
            message: 'Amount must be a positive number',
          });
        }

        if (bigNumberValue.isLessThan(BigNumber(minAmount))) {
          return ctx.createError({
            message: `Amount is less than minimum amount: ${minAmount}`,
          });
        }

        if (bigNumberValue.isGreaterThan(BigNumber(maxAmount))) {
          return ctx.createError({
            message: `Amount exceeds maximum amount: ${maxAmount}`,
          });
        }

        return true;
      }),
  };
};

export const getStandardizedStakeFormValidation = ({
  stakingSteps,
}: Props): yup.ObjectSchema<any> => {
  return yup.object({
    ...getValidatorInputStepValidation(stakingSteps),
    ...getAmountInputStepValidation(stakingSteps),
    comment: yup.string().optional(),
  });
};

export const getStandardizedUnstakeFormValidation = ({
  stakingSteps,
}: Props): yup.ObjectSchema<any> => {
  return yup.object({
    ...getAmountInputStepValidation(stakingSteps),
    comment: yup.string().optional(),
  });
};

export const getStandardizedClaimRewardsFormValidation =
  (): yup.ObjectSchema<any> => {
    return yup.object({
      // Amount input field is not editable in the claim rewards flow
      amount: yup.string().required('Amount is required'),
      comment: yup.string().optional(),
    });
  };
