import cn from 'classnames';

const AddShoppingCartIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AddShoppingCart', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M22 19H26V13H32V9H26V3H22V9H16V13H22V19ZM14 37C11.79 37 10.02 38.79 10.02 41C10.02 43.21 11.79 45 14 45C16.21 45 18 43.21 18 41C18 38.79 16.21 37 14 37ZM34 37C31.79 37 30.02 38.79 30.02 41C30.02 43.21 31.79 45 34 45C36.21 45 38 43.21 38 41C38 38.79 36.21 37 34 37ZM14.35 30.5C14.35 30.41 14.37 30.33 14.41 30.26L16.21 27H31.11C32.61 27 33.92 26.17 34.61 24.94L42.33 10.92L38.83 9H38.82L36.61 13L31.1 23H17.07L16.81 22.46L12.32 13L10.42 9L8.53 5H2V9H6L13.2 24.17L10.49 29.07C10.18 29.65 10 30.3 10 31C10 33.21 11.79 35 14 35H38V31H14.85C14.57 31 14.35 30.78 14.35 30.5Z" />
    </svg>
  );
};

export { AddShoppingCartIcon };
