import { ComponentProps } from 'react';

import css from './styles.module.scss';

import UpdatedUsersRow from './UpdatedUsersRow';

const UpdatedUsers = ({
  added,
  removed,
  unchanged,
}: {
  added?: ComponentProps<typeof UpdatedUsersRow>['users'];
  removed?: ComponentProps<typeof UpdatedUsersRow>['users'];
  unchanged?: ComponentProps<typeof UpdatedUsersRow>['users'];
}) => {
  return (
    <div className={css.rowsWrapper} data-testid="updated-users-list">
      {added?.[0] && (
        <UpdatedUsersRow users={added} title={'Added'} type={'add'} />
      )}
      {removed?.[0] && (
        <UpdatedUsersRow users={removed} title={'Removed'} type={'remove'} />
      )}
      {unchanged?.[0] && (
        <UpdatedUsersRow
          users={unchanged}
          title={'No changes'}
          type={'unchanged'}
        />
      )}
    </div>
  );
};

export default UpdatedUsers;
