import cn from 'classnames';

const ExploreIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Explore', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 21.8C22.79 21.8 21.8 22.79 21.8 24C21.8 25.21 22.79 26.2 24 26.2C25.22 26.2 26.2 25.21 26.2 24C26.2 22.79 25.22 21.8 24 21.8ZM24 4C12.95 4 4 12.95 4 24C4 35.04 12.95 44 24 44C35.05 44 44 35.04 44 24C44 12.95 35.05 4 24 4ZM28.38 28.38L12 36L19.62 19.62L36 12L28.38 28.38Z" />
    </svg>
  );
};

export { ExploreIcon };
