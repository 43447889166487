import cn from 'classnames';

const LocalBarIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalBar', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 10V6H6V10L22 28V38H12V42H36V38H26V28L42 10ZM14.87 14L11.31 10H36.69L33.13 14H14.87Z" />
    </svg>
  );
};

export { LocalBarIcon };
