import cn from 'classnames';

const DevicesIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Devices', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M8 12H44V8H8C5.79 8 4 9.79 4 12V34H0V40H28V34H8V12ZM46 16H34C32.9 16 32 16.9 32 18V38C32 39.1 32.9 40 34 40H46C47.1 40 48 39.1 48 38V18C48 16.9 47.1 16 46 16ZM44 34H36V20H44V34Z" />
    </svg>
  );
};

export { DevicesIcon };
