import cn from 'classnames';

const FilterDramaIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FilterDrama', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38.71 20.07C37.35 13.19 31.28 8 24 8C18.22 8 13.22 11.28 10.71 16.07C4.69 16.71 0 21.81 0 28C0 34.63 5.37 40 12 40H38C43.52 40 48 35.52 48 30C48 24.72 43.9 20.44 38.71 20.07ZM38 36H12C7.59 36 4 32.41 4 28C4 23.59 7.59 20 12 20C16.41 20 20 23.59 20 28H24C24 22.48 20.27 17.85 15.2 16.45C17.21 13.76 20.4 12 24 12C30.07 12 35 16.93 35 23V24H38C41.31 24 44 26.69 44 30C44 33.31 41.31 36 38 36Z" />
    </svg>
  );
};

export { FilterDramaIcon };
