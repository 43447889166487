/* eslint-disable @typescript-eslint/no-explicit-any */
import { animated, useTransition } from '@react-spring/web';
import * as React from 'react';

import { Loader } from '@anchorage/common/dist/components';

import css from './styles.module.scss';

const FadingTransition = ({
  children,
  data = true,
}: {
  data?: any;
  children: React.ReactNode;
}) => {
  const transitions = useTransition(data, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { display: 'none' },
  });

  return (
    <React.Suspense fallback={<Loader className={css.loader} />}>
      {transitions((props) => (
        <animated.div style={props}>{children}</animated.div>
      ))}
    </React.Suspense>
  );
};

export default FadingTransition;
