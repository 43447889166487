import cn from 'classnames';

const CircleHelpIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CircleHelp', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M11.25 14.25V13.2578C11.25 12.2029 11.846 11.2385 12.7896 10.7667C13.2206 10.5512 13.5 10.0971 13.5 9.60542C13.5 8.85851 12.8928 8.25 12.1641 8.25H12C11.1716 8.25 10.5 8.92157 10.5 9.75V10.5H9V9.75C9 8.09315 10.3431 6.75 12 6.75H12.1641C13.7394 6.75 15 8.04834 15 9.60542C15 10.6555 14.4083 11.6344 13.4604 12.1083C13.025 12.326 12.75 12.771 12.75 13.2578V14.25H11.25Z" />
      <path d="M11.25 15.75V17.25H12.75V15.75H11.25Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5Z"
      />
    </svg>
  );
};

export { CircleHelpIcon };
