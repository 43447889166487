import cn from 'classnames';

const AccountCircleIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AccountCircle', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 4C12.95 4 4 12.95 4 24C4 35.05 12.95 44 24 44C35.05 44 44 35.05 44 24C44 12.95 35.05 4 24 4ZM24 10C27.31 10 30 12.69 30 16C30 19.32 27.31 22 24 22C20.69 22 18 19.32 18 16C18 12.69 20.69 10 24 10ZM24 38.4C18.99 38.4 14.59 35.84 12 31.96C12.05 27.99 20.01 25.8 24 25.8C27.99 25.8 35.94 27.99 36 31.96C33.41 35.84 29.01 38.4 24 38.4Z" />
    </svg>
  );
};

export { AccountCircleIcon };
