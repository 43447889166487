import cn from 'classnames';

const LocalGroceryStoreIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalGroceryStore', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M14 36C11.79 36 10.02 37.79 10.02 40C10.02 42.21 11.79 44 14 44C16.21 44 18 42.21 18 40C18 37.79 16.21 36 14 36ZM2 4V8H6L13.19 23.17L10.49 28.07C10.18 28.65 10 29.3 10 30C10 32.21 11.79 34 14 34H38V30H14.85C14.57 30 14.35 29.78 14.35 29.5C14.35 29.41 14.37 29.33 14.41 29.26L16.2 26H31.1C32.6 26 33.91 25.17 34.6 23.94L41.75 10.96C41.91 10.68 42 10.35 42 10C42 8.89 41.1 8 40 8H10.43L8.53 4H2ZM34 36C31.79 36 30.02 37.79 30.02 40C30.02 42.21 31.79 44 34 44C36.21 44 38 42.21 38 40C38 37.79 36.21 36 34 36Z" />
    </svg>
  );
};

export { LocalGroceryStoreIcon };
