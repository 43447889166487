import cn from 'classnames';

const KeyboardArrowLeftIcon = (props: SVGProps): JSX.Element => {
  const { title = 'KeyboardArrowLeft', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M30.83 32.67L21.66 23.5L30.83 14.33L28 11.5L16 23.5L28 35.5L30.83 32.67Z" />
    </svg>
  );
};

export { KeyboardArrowLeftIcon };
