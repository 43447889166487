import cn from 'classnames';

const DesktopMacIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DesktopMac', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 4H6C3.79 4 2 5.79 2 8V32C2 34.21 3.79 36 6 36H20L16 42V44H32V42L28 36H42C44.21 36 46 34.21 46 32V8C46 5.79 44.21 4 42 4ZM42 28H6V8H42V28Z" />
    </svg>
  );
};

export { DesktopMacIcon };
