import cn from 'classnames';

const ForumIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Forum', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 12H38V30H12V34C12 35.1 12.9 36 14 36H36L44 44V14C44 12.9 43.1 12 42 12ZM34 24V6C34 4.9 33.1 4 32 4H6C4.9 4 4 4.9 4 6V34L12 26H32C33.1 26 34 25.1 34 24Z" />
    </svg>
  );
};

export { ForumIcon };
