import cn from 'classnames';

const KitchenIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Kitchen', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M36 4.02L12 4C9.79 4 8 5.79 8 8V40C8 42.21 9.79 44 12 44H36C38.21 44 40 42.21 40 40V8C40 5.79 38.21 4.02 36 4.02ZM36 40H12V21.95H36V40ZM36 18H12V8H36V18ZM16 10H20V16H16V10ZM16 24H20V34H16V24Z" />
    </svg>
  );
};

export { KitchenIcon };
