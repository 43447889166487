import { Drawer, Text } from '@anchorage/common/dist/components';
import { OnCloseDrawerFn } from '@anchorage/common/dist/components/Drawer/types';
import { HyperlinkIcon } from '@anchorage/common/dist/components/Icons';
import {
  DEFAULT_VALUES,
  useFeatureFlagsContext,
} from '@anchorage/feature-flags';

import css from './styles.module.scss';

import FeatureFlagsHeader from './FeatureFlagsHeader';
import FeatureFlagsList from './FeatureFlagsList';

type DevToolDrawerProps = {
  isOpen: boolean;
  organizationKeyID: string;
  onClose: OnCloseDrawerFn;
};

const DevToolsDrawer = (props: DevToolDrawerProps) => {
  const { isOpen, onClose, organizationKeyID, ...restProps } = props;
  const { useAllFlags, flagsInLocalStorage, setFlagsInLocalStorage } =
    useFeatureFlagsContext();

  const flagsWithoutLocalOverrides = useAllFlags({
    disableLocalOverrides: true,
  });

  const toggleFeatureFlagState = (name: string) => {
    if (!(name in flagsWithoutLocalOverrides)) {
      return;
    }

    const newState = {
      ...(flagsInLocalStorage || {}),
      [name]: {
        treatment:
          (flagsInLocalStorage[name] || flagsWithoutLocalOverrides[name]!)
            .treatment !== (DEFAULT_VALUES.ON as string)
            ? DEFAULT_VALUES.ON
            : DEFAULT_VALUES.OFF,
        config: flagsWithoutLocalOverrides[name]!.config,
      },
    };

    setFlagsInLocalStorage(newState);
  };

  const resetHandler = () => setFlagsInLocalStorage({});

  return (
    <Drawer onClose={onClose} open={isOpen} {...restProps}>
      <Text type="heading" size="huge">
        Developer Tools
      </Text>
      <div className={css.devToolsContainer}>
        <Text size="small" className={css.orgID}>
          Organization key ID: {organizationKeyID}
        </Text>
        <div className={css.devTool}>
          <FeatureFlagsHeader
            flagsInLocalStorage={flagsInLocalStorage}
            resetHandler={resetHandler}
          />
          <Text type="body" size="small">
            These are only local overrides for the feature flags. To update
            feature flags in real environments, please go to:{' '}
            <Text<'a'>
              href="https://app.split.io/"
              target="_blank"
              type="link"
              tag="a"
              size="small"
              className={css.linkContainer}
            >
              Split.io console
              <HyperlinkIcon className={css.icon} />
            </Text>
          </Text>
          <div className={css.featureFlagContainer}>
            <FeatureFlagsList
              flagsInProvider={flagsWithoutLocalOverrides}
              flagsInLocalStorage={flagsInLocalStorage}
              toggleFlag={toggleFeatureFlagState}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default DevToolsDrawer;
