import React from 'react';
// Components
import { components } from 'react-select-v5';
// Types & Constants
import type { OptionProps } from 'react-select-v5';

import { Text } from '../../../../components';

// Styles
import css from '../index.css';

import type { NFTSortOption } from '../_types';

import { SORT_ICON_MAPPING } from '../_constants';

const SelectOption: React.FC<OptionProps<NFTSortOption, false>> = ({
  data,
  ...restProps
}) => {
  const Icon = SORT_ICON_MAPPING[data.value];

  return (
    <components.Option data={data} {...restProps}>
      <div className={css.iconOption}>
        <Icon className={css.sortIcon} />
        <Text>{data.label}</Text>
      </div>
    </components.Option>
  );
};

export default SelectOption;
