// Utils
import cn from 'classnames';
import React from 'react';

import { Loader, Text } from '../../../../components';
// Components
import { TableCell } from '../../../../components/TableV2';

// Styles
import css from '../index.css';

// Types
import type { NFTRowCellsProps } from '../_types';

import ImageMosaic from '../../ImageMosaic';
import NFTImage from '../../NFTImage';
// Constants
import { FlexDirection, NFTContentNFTViews } from '../../_constants';

const NFTRowCells: React.FC<NFTRowCellsProps> = ({
  data,
  contentViewType,
  showIsLoading,
}) => {
  if (showIsLoading) {
    return (
      <TableCell colSpan={5}>
        <Loader className={css.loaderIcon} />
      </TableCell>
    );
  }
  const { images, onClick, title, subtext, priceInUSD, addedAt, quantity } =
    data;

  const imageClasses = cn({
    [css.nftImage]: true,
    [images[0].className ?? '']: Boolean(images[0].className),
  });

  const overlay = React.useMemo(
    () => ({
      hasOverlay: images.length > 4,
      overlayLimit: 4,
      overlayText: `+${images.length - 4}`,
    }),
    [images.length]
  );

  return (
    <>
      <TableCell className={cn([css.tableCell, css.nftCell])} onClick={onClick}>
        <div className={css.nameImageContainer}>
          <div className={css.imageMosaicContainer}>
            {contentViewType === NFTContentNFTViews.SINGLE ? (
              <NFTImage
                loading="lazy"
                fallbackClassName={css.individualFallbackImg}
                {...images[0]}
                className={imageClasses}
                alt={images[0].alt}
              />
            ) : (
              <ImageMosaic
                images={images}
                overlay={overlay}
                numOfSubContainers={images.length > 1 ? 2 : 1}
                containerDirection={
                  images.length > 3 ? FlexDirection.COLUMN : FlexDirection.ROW
                }
                subContainerDirection={
                  images.length <= 3 ? FlexDirection.COLUMN : FlexDirection.ROW
                }
              />
            )}
          </div>
          <div className={css.textContainer}>
            <Text>{title}</Text>
            {subtext ? (
              <Text size="small" className={css.subtext}>
                {subtext}
              </Text>
            ) : null}
          </div>
        </div>
      </TableCell>
      <TableCell
        onClick={onClick}
        className={cn([css.tableCell, css.dateCell])}
      >
        {addedAt ?? '-'}
      </TableCell>
      {contentViewType === NFTContentNFTViews.COLLECTION ? (
        <TableCell
          onClick={onClick}
          className={cn([css.tableCell, css.dateCell])}
        >
          {quantity}
        </TableCell>
      ) : null}
      <TableCell
        onClick={onClick}
        className={cn([css.tableCell, css.valueCell])}
      >
        ~{priceInUSD}
      </TableCell>
    </>
  );
};

export default NFTRowCells;
