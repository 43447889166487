import cn from 'classnames';

const AirlineSeatReclineNormalIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AirlineSeatReclineNormal', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M15.17 10.83C13.61 9.27 13.61 6.74 15.17 5.17C16.73 3.61 19.26 3.61 20.83 5.17C22.39 6.73 22.39 9.26 20.83 10.83C19.27 12.39 16.73 12.39 15.17 10.83ZM12 32V14H8V32C8 37.52 12.48 42 18 42H30V38H18C14.69 38 12 35.31 12 32ZM40 40.13L29.87 30H23V22.64C25.79 24.94 30.21 26.95 34 26.96V22.64C30.68 22.68 26.78 20.9 24.66 18.56L21.86 15.46C21.47 15.03 21 14.7 20.49 14.45C19.9 14.16 19.24 13.99 18.56 13.99H18.51C16.02 14 14 16.01 14 18.5V30C14 33.31 16.69 36 20 36H30.13L37.13 43L40 40.13Z" />
    </svg>
  );
};

export { AirlineSeatReclineNormalIcon };
