// Utils
import cn from 'classnames';
import React from 'react';

import commonCss from '../common.css';

// Components
import { NoImageIcon } from '../../../components/Icons';

// Styles
import css from './index.css';

// Types
import type { NFTImage as NFTImageType } from '../_types';

const NFTImage = ({
  src,
  className = '',
  fallbackClassName = '',
  alt,
  includeShimmer = true,
  ...restProps
}: NFTImageType) => {
  const [hasError, setHasError] = React.useState(false);

  const fallBackImgClasses = cn({
    [css.fallbackImage]: true,
    [className]: Boolean(className),
    [fallbackClassName]: Boolean(fallbackClassName),
  });

  if (!src || hasError) {
    return <NoImageIcon className={fallBackImgClasses} />;
  }

  const imgClasses = cn({
    [commonCss.shimmerAnimation]: includeShimmer,
    [className]: Boolean(className),
  });

  return (
    <img
      className={imgClasses}
      src={src}
      {...restProps}
      alt={alt}
      onError={() => {
        setHasError(true);
      }}
    />
  );
};

export default NFTImage;
