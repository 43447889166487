import { Text } from '@anchorage/common/dist/components';
import { ChevronIcon } from '@anchorage/common/dist/components/Icons';
import type {
  NormalizedSignedPreviewLayout,
  NormalizedSignedTextField,
  NormalizedUnsignedTextField,
} from '@anchorage/vsp';
import { FieldType } from '@anchorage/vsp';

import css from './styles.module.scss';

import { TextRow } from '../TextRow';
import { UnknownRow } from '../UnknownRow';

type Props = {
  field: NormalizedSignedPreviewLayout;
  isReviewed?: boolean;
};

function PreviewLayoutRow({ field, isReviewed }: Props) {
  const { title, subtitle, fallbackText, condensed } = field;
  return (
    <div key={`${title}_${subtitle}`} className={css.row}>
      <div className={css.rowContent}>
        <div className={css.rowContainer}>
          {!isReviewed && <div className={css.badge} />}
          <Text type="heading" size="small">
            {title || fallbackText}
          </Text>
        </div>
        {subtitle ? (
          <Text className={css.grayText} size="tiny">
            {subtitle}
          </Text>
        ) : null}
        {condensed.fields.map((field, index: number) => {
          switch (field?.type) {
            case FieldType.SignedText:
              return (
                <TextRow
                  key={index}
                  field={field as NormalizedSignedTextField}
                />
              );
            default:
              return (
                <UnknownRow
                  key={index}
                  field={field as NormalizedUnsignedTextField}
                />
              );
          }
        })}
      </div>
      <ChevronIcon className={css.menuButtonIcon} direction="right" />
    </div>
  );
}

export default PreviewLayoutRow;
