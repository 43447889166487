import cn from 'classnames';

const AspectRatioIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AspectRatio', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 24H34V30H28V34H38V24ZM14 18H20V14H10V24H14V18ZM42 6H6C3.79 6 2 7.79 2 10V38C2 40.21 3.79 42 6 42H42C44.21 42 46 40.21 46 38V10C46 7.79 44.21 6 42 6ZM42 38.03H6V9.97H42V38.03Z" />
    </svg>
  );
};

export { AspectRatioIcon };
