import cn from 'classnames';

const AccessAlarmsIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AccessAlarms', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M44 11.5L34.8 3.79995L32.2 6.89995L41.4 14.6L44 11.5ZM15.7 6.79995L13.1 3.69995L4 11.5L6.6 14.6L15.7 6.79995ZM25 16H22V28L31.5 33.7L33 31.2L25 26.5V16ZM24 7.99995C14 7.99995 6 16.1 6 26C6 35.8999 14 44 24 44C33.9 44 42 35.8999 42 26C42 16.1 33.9 7.99995 24 7.99995ZM24 40C16.3 40 10 33.7 10 26C10 18.3 16.3 12 24 12C31.7 12 38 18.3 38 26C38 33.7999 31.7 40 24 40Z" />
    </svg>
  );
};

export { AccessAlarmsIcon };
