// Utils & hooks
import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

// Components
import { Text } from '../../../components';

import { useDeepCompare } from '../../../hooks';

// Styles
import css from './index.css';

// Types
import type {
  ImageElementProps,
  ImageMosaicProps,
  NFTImageIndex,
} from './_types';

import NFTImage from '../NFTImage';
// Constants
import { FlexDirection } from '../_constants';

const ANIMATION_DELAY = 0.4;

const ImageElement: React.FC<ImageElementProps> = ({
  image,
  hasOverlay,
  overlayText,
  isLast,
  index,
}) => {
  const wrapperClasses = cn({
    [css.tile]: true,
    [css.hoverTile]: Boolean(image.onClick),
  });

  const imageClasses = cn({
    [css.image]: true,
    [image.className ?? '']: Boolean(image.className),
  });

  const style = React.useMemo(
    () => ({
      animationDelay: `${String(ANIMATION_DELAY * index)}s`,
    }),
    [index]
  );

  return (
    <div
      className={wrapperClasses}
      style={style}
      key={`${image.src ?? ''}-${image.imageIndex}`}
    >
      {isLast && hasOverlay ? (
        <Text className={css.overlay}>{overlayText}</Text>
      ) : null}
      <NFTImage
        loading="lazy"
        className={imageClasses}
        fallbackClassName={css.fallbackImg}
        {...image}
        alt={image.alt}
      />
    </div>
  );
};

const ImageMosaic: React.FC<ImageMosaicProps> = ({
  overlay: { hasOverlay, overlayText = '', overlayLimit = Infinity },
  images,
  numOfSubContainers,
  containerDirection = 'row',
  subContainerDirection = 'row',
}) => {
  const threshold = 1 / numOfSubContainers;
  const [subContainers, setSubContainers] = useState<NFTImageIndex[][]>(
    Array(numOfSubContainers)
  );

  const extraStyles = useMemo(() => {
    const percentDimension = `${Math.floor(100 / numOfSubContainers)}%`;

    if (subContainerDirection === FlexDirection.COLUMN) {
      return { minWidth: percentDimension };
    }
    return { minHeight: percentDimension };
  }, [subContainerDirection, numOfSubContainers]);

  useEffect(() => {
    const newSubContainers: NFTImageIndex[][] = Array(numOfSubContainers);

    images
      .slice(0, overlayLimit)
      .reverse()
      .forEach((image, index) => {
        const actualImgLength = Math.min(images.length, overlayLimit);
        const subContainerIndex = Math.floor(
          index / actualImgLength / threshold
        );

        if (!newSubContainers[subContainerIndex]) {
          newSubContainers[subContainerIndex] = [];
        }

        newSubContainers[subContainerIndex].push({
          ...image,
          imageIndex: index,
        });
      });

    setSubContainers(newSubContainers);
  }, useDeepCompare([images, numOfSubContainers, overlayLimit]));

  return (
    <div
      className={cn({
        [css.container]: true,
        [css.column]: containerDirection === FlexDirection.COLUMN,
      })}
    >
      {subContainers
        .slice()
        .reverse()
        .map((subImages, index) => (
          <div
            style={extraStyles}
            className={cn({
              [css.subcontainer]: true,
              [css.column]: subContainerDirection === FlexDirection.COLUMN,
            })}
            key={`subContainer-${index}`}
            data-testid={`subContainer-${index}`}
          >
            {subImages
              .slice()
              .reverse()
              .map((image, imgIndex) => (
                <ImageElement
                  key={image.imageIndex}
                  image={image}
                  hasOverlay={hasOverlay}
                  overlayText={overlayText}
                  index={
                    containerDirection === FlexDirection.COLUMN
                      ? imgIndex
                      : index
                  }
                  isLast={
                    index === subContainers.length - 1
                    && imgIndex === subImages.length - 1
                  }
                />
              ))}
          </div>
        ))}
    </div>
  );
};

export default ImageMosaic;
