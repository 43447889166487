import cn from 'classnames';

const GroupAddIcon = (props: SVGProps): JSX.Element => {
  const { title = 'GroupAdd', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M16 20H10V14H6V20H0V24H6V30H10V24H16V20ZM36 22C39.31 22 41.98 19.31 41.98 16C41.98 12.69 39.31 10 36 10C35.36 10 34.75 10.1 34.17 10.29C35.3 11.91 35.98 13.88 35.98 16C35.98 18.12 35.3 20.09 34.17 21.71C34.75 21.9 35.36 22 36 22ZM26 22C29.31 22 31.98 19.31 31.98 16C31.98 12.69 29.31 10 26 10C22.69 10 20 12.69 20 16C20 19.31 22.69 22 26 22ZM39.24 26.32C40.9 27.77 42 29.64 42 32V36H48V32C48 28.92 43.25 27.03 39.24 26.32ZM26 26C22 26 14 28 14 32V36H38V32C38 28 30 26 26 26Z" />
    </svg>
  );
};

export { GroupAddIcon };
