import cn from 'classnames';

const LiveHelpIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LiveHelp', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 4H10C7.79 4 6 5.79 6 8V36C6 38.21 7.79 40 10 40H18L24 46L30 40H38C40.21 40 42 38.21 42 36V8C42 5.79 40.21 4 38 4ZM26 36H22V32H26V36ZM30.13 20.51L28.34 22.35C26.9 23.79 26 25 26 28H22V27C22 24.79 22.9 22.79 24.34 21.34L26.83 18.82C27.55 18.1 28 17.1 28 16C28 13.79 26.21 12 24 12C21.79 12 20 13.79 20 16H16C16 11.58 19.58 8 24 8C28.42 8 32 11.58 32 16C32 17.76 31.29 19.35 30.13 20.51Z" />
    </svg>
  );
};

export { LiveHelpIcon };
