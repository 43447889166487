import React from 'react';

import { Text } from '@anchorage/common/dist/components';

import css from './styles.module.scss';

import PolicyLabel from './PolicyLabel';

type Props = {
  first?: boolean;
  approvalFraction: string | React.ReactNode;
  quorumName: string;
  description: string;
};
const OriginalQuorum = ({
  first = false,
  approvalFraction,
  quorumName,
  description,
}: Props) => {
  return (
    <div className={first ? css.topSpacing : css.originalQuorum}>
      {quorumName && (
        <Text className={css.originalQuorumTitle}>{quorumName}</Text>
      )}
      <Text size="small">{approvalFraction}</Text>
      <PolicyLabel label={description} />
    </div>
  );
};

export default OriginalQuorum;
