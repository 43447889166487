import cn from 'classnames';

const KeyboardBackspaceIcon = (props: SVGProps): JSX.Element => {
  const { title = 'KeyboardBackspace', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 22H13.66L20.83 14.83L18 12L6 24L18 36L20.83 33.17L13.66 26H42V22Z" />
    </svg>
  );
};

export { KeyboardBackspaceIcon };
