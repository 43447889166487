import { Text } from '@latitude/text';
import cn from 'classnames';
import { ElementRef, forwardRef } from 'react';
import { avatarVariants, textVariants } from 'variants';

import type { AvatarProps, AvatarSize, AvatarType, AvatarVariant } from 'types';

import { getInitials } from './helpers';

const Avatar = forwardRef<ElementRef<'div'>, AvatarProps>(
  (
    {
      size = 'small',
      variant = 'strong',
      type = 'letter',
      text = '',
      imageSrc,
      icon,
      border = false,
      halo = false,
      className,
      ...rest
    },
    ref,
  ) => {
    return (
      <div
        {...rest}
        ref={ref}
        className={cn([
          className,
          avatarVariants({ size, variant, type, border, halo }),
        ])}
        style={{
          backgroundImage:
            type === 'image' && imageSrc ? `url(${imageSrc})` : undefined,
        }}
        data-testid="avatar-container"
      >
        {type === 'letter' && (
          <Text className={cn(textVariants({ variant, size }))}>
            {getInitials(text)}
          </Text>
        )}

        {type === 'icon' && icon}
      </div>
    );
  },
);

Avatar.displayName = 'Avatar';

export {
  Avatar,
  avatarVariants,
  type AvatarProps,
  type AvatarVariant,
  type AvatarSize,
  type AvatarType,
};
