import { Button } from '@latitude/button';

import { Text } from '@anchorage/common/dist/components';
import { ErrorOutlineIcon } from '@anchorage/common/dist/components/Icons';

import css from './styles.module.scss';

import ErrorWrapper from '../shared/ErrorWrapper';

const DrawerError = () => {
  return (
    <ErrorWrapper className={css.root} Icon={ErrorOutlineIcon}>
      <Text className={css.title}>Error loading activity</Text>
      <Text className={css.text} size="small">
        We are having issues loading the activity details and are working on a
        fix.
      </Text>
      <Text className={css.text} size="small">
        Please try refreshing your page or again later.
      </Text>
      <Button className={css.button} onClick={() => window.location.reload()}>
        Refresh page
      </Button>
    </ErrorWrapper>
  );
};

export default DrawerError;
