import { Text } from '@anchorage/common/dist/components';

import { PolicyQuorumDiff } from 'components/shared/OperationDrawer/utils/vaultMembers';

import css from './styles.module.scss';

import OriginalQuorum from './OriginalQuorum';

const formatDisplayValue = (quorum: number, total: number): string =>
  total > 0 ? `${quorum} of ${total}` : 'None';

type Props = {
  first?: boolean;
  description: string;
  quorumName?: string;
  quorum: PolicyQuorumDiff;
  total?: PolicyQuorumDiff;
};

const PolicyDiffSection = ({
  first = false,
  quorum: { original: originalQuorum, updated: updatedQuorum },
  total,
  quorumName = '',
  description,
}: Props) => {
  let originalDisplayValue;
  let updatedDisplayValue;

  if (total) {
    originalDisplayValue =
      total.original > 0
        ? formatDisplayValue(originalQuorum, total.original)
        : 'None';

    if (!updatedQuorum && !total.updated) {
      return (
        <OriginalQuorum
          first={first}
          quorumName={quorumName}
          description={description}
          approvalFraction={
            <div className={css.diffWrapper}>
              <Text type="metric" size="medium">
                {originalDisplayValue}
              </Text>
            </div>
          }
        />
      );
    }

    const quorumValue = updatedQuorum ?? originalQuorum;
    const totalValue = total.updated ?? total.original;
    updatedDisplayValue =
      totalValue > 0 ? `${quorumValue} of ${totalValue}` : 'None';
  } else if (updatedQuorum) {
    originalDisplayValue = originalQuorum;
    updatedDisplayValue = updatedQuorum;
  } else {
    return (
      <OriginalQuorum
        first={first}
        quorumName={quorumName}
        description={description}
        approvalFraction={
          <div className={css.diffWrapper}>
            <Text type="metric" size="medium">
              {originalQuorum.toString()}
            </Text>
          </div>
        }
      />
    );
  }
  return (
    <OriginalQuorum
      first={first}
      quorumName={quorumName}
      description={description}
      approvalFraction={
        <div className={css.diffWrapper}>
          <Text type="metric" className={css.updatedDiff} size="medium">
            {updatedDisplayValue}
          </Text>
          <Text type="metric" className={css.originalDiff} size="medium">
            {originalDisplayValue}
          </Text>
        </div>
      }
    />
  );
};

export default PolicyDiffSection;
