import cn from 'classnames';

const LoopIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Loop', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 8V2L16 10L24 18V12C30.63 12 36 17.37 36 24C36 26.03 35.49 27.93 34.61 29.61L37.53 32.53C39.08 30.05 40 27.14 40 24C40 15.16 32.84 8 24 8ZM24 36C17.37 36 12 30.63 12 24C12 21.97 12.51 20.07 13.39 18.39L10.47 15.47C8.92 17.95 8 20.86 8 24C8 32.84 15.16 40 24 40V46L32 38L24 30V36Z" />
    </svg>
  );
};

export { LoopIcon };
