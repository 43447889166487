import { Button, Text } from '@anchorage/common/dist/components';
import type { AllFeatureFlags } from '@anchorage/feature-flags';

import css from './styles.module.scss';

const FeatureFlagsHeader = ({
  flagsInLocalStorage,
  resetHandler,
}: {
  flagsInLocalStorage: AllFeatureFlags;
  resetHandler: () => void;
}) => {
  const hasOverrides = Object.keys(flagsInLocalStorage).length > 0;

  return (
    <div className={css.headingContainer}>
      <Text size="medium" type="heading">
        Feature Flags
      </Text>
      {hasOverrides && (
        <Button type="secondary" onClick={resetHandler}>
          Reset Overrides
        </Button>
      )}
    </div>
  );
};

export default FeatureFlagsHeader;
