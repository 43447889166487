import useStandardizedStakingContext from '../../StandardizedStakingContext/useStandardizedStakingContext';
import GenericStakingStep from './StakingSteps/GenericStakingStep';

const StandardizedStakingFormPage = () => {
  const { walletAsset } = useStandardizedStakingContext();

  const stakeFormSteps = walletAsset?.staking?.stakeForm?.steps ?? [];

  return (
    <div className="*:mt-6">
      {stakeFormSteps.map((step, index) => {
        return <GenericStakingStep step={step} key={index} />;
      })}
    </div>
  );
};

export default StandardizedStakingFormPage;
