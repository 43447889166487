import cn from 'classnames';

const FlightTakeoffIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FlightTakeoff', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M4.99994 37.9999H42.9999V41.9999H4.99994V37.9999ZM44.1399 19.2699C43.7099 17.6699 42.0699 16.7199 40.4699 17.1499L29.8399 19.9999L16.0399 7.12988L12.1799 8.16988L20.4599 22.5199L10.5199 25.1799L6.58994 22.0899L3.68994 22.8699L7.32994 29.1799L8.85994 31.8299L12.0699 30.9699L22.6999 28.1199L31.3899 25.7899L42.0199 22.9399C43.6199 22.5099 44.5699 20.8699 44.1399 19.2699Z" />
    </svg>
  );
};

export { FlightTakeoffIcon };
