import Text from '@latitude/text';

type Props = {
  enabled: boolean;
};

export const AutoCompoundStatusIndicator = ({ enabled }: Props) => {
  const bgColor = enabled
    ? 'bg-color-sys-algae-green-50'
    : 'bg-color-sys-iron-grey-60';

  return (
    <div className="flex items-center">
      <div className={`${bgColor} mr-2 size-2 rounded-full`}></div>
      <Text>{enabled ? 'Active' : 'Inactive'}</Text>
    </div>
  );
};
