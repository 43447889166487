import { READABLE_USER_TYPES } from 'constants/operations';
import * as React from 'react';

import { useFeatureFlagsContext } from '@anchorage/feature-flags';
import { FEATURE_FLAGS } from '@anchorage/frontoffice/constants/split';

import { UserType } from 'generated/graphql';

import AvatarWithText from '../../shared/AvatarWithText';
import SectionRow from '../../shared/SectionRow';
import WidgetWrapper from '../../shared/WidgetWrapper';

type Props = {
  name: string | null;
  role: keyof typeof UserType;
};

const User: React.FC<Props> = ({ name, role }) => {
  const { useIsFeatureActive } = useFeatureFlagsContext();
  const [isPermissionsM3_1Active] = useIsFeatureActive(
    FEATURE_FLAGS.CD_PERMISSIONS_M3_1,
  );

  return (
    <WidgetWrapper>
      <SectionRow title="User">
        <AvatarWithText
          title={name || 'Unknown name'}
          subtitle={isPermissionsM3_1Active ? '' : READABLE_USER_TYPES[role]}
        />
      </SectionRow>
    </WidgetWrapper>
  );
};

export default User;
