import cn from 'classnames';

export const DownloadIcon = ({
  title = 'Download',
  className,
  ...restProps
}: SVGProps): JSX.Element => (
  <svg
    className={cn('fill-current', className)}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    {...restProps}
  >
    <title>{title}</title>
    <path d="M17.4167 8.25H13.75V2.75H8.25001V8.25H4.58334L11 14.6667L17.4167 8.25ZM4.58334 16.5V18.3333H17.4167V16.5H4.58334Z" />
  </svg>
);
