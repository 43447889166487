import cn from 'classnames';

const FlashAutoIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FlashAuto', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6 4V28H12V46L26 22H18L26 4H6ZM38 4H34L27.6 22H31.4L32.8 18H39.2L40.6 22H44.4L38 4ZM33.7 15.3L36 8L38.3 15.3H33.7Z" />
    </svg>
  );
};

export { FlashAutoIcon };
