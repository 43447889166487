import cn from 'classnames';

import type { Direction, SVGProps } from '../types';

const DIRECTION_MAPPING: Record<Direction, string> = {
  left: 'rotate-0',
  right: 'rotate-180',
  up: 'rotate-90',
  down: '-rotate-90',
};

type ChevronProps = SVGProps & { direction?: Direction };

const ChevronIcon = (props: ChevronProps): JSX.Element => {
  const {
    title = 'Chevron',
    direction = 'left',
    className,
    ...restProps
  } = props;

  return (
    <svg
      className={cn('fill-current', DIRECTION_MAPPING[direction], className)}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M15.415 16.335L10.83 11.75l4.585-4.585L14 5.75l-6 6 6 6 1.415-1.415z"></path>
    </svg>
  );
};

export { ChevronIcon };
