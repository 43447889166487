/*
 * Replaces current URL without changing history
 * path examples:
     - /frontoffice/dashboard
 */
const replaceUrl = (path: string) => {
  const locationOrigin = window.location.origin;
  const fullHref = `${locationOrigin}${path}`;
  window.location.replace(fullHref);
};

export default replaceUrl;
