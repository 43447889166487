import cn from 'classnames';

const FiberNewIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FiberNew', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 8H8C5.79 8 4.02 9.79 4.02 12L4 36C4 38.21 5.79 40 8 40H40C42.21 40 44 38.21 44 36V12C44 9.79 42.21 8 40 8ZM17 30H14.6L9.5 23V30H7V18H9.5L14.5 25V18H17V30ZM27 20.51H22V22.75H27V25.26H22V27.49H27V30H19V18H27V20.51ZM41 28C41 29.1 40.1 30 39 30H31C29.9 30 29 29.1 29 28V18H31.5V27.01H33.75V19.99H36.25V27.01H38.5V18H41V28Z" />
    </svg>
  );
};

export { FiberNewIcon };
