import { useEffect, useState } from 'react';
import type { FC } from 'react';

import { useAnalytics } from '@anchorage/analytics';
import { Drawer, Loader, Text } from '@anchorage/common/dist/components';

import css from './styles.module.css';

type ProductUpdatesProps = {
  isOpen: boolean;
  feedURL?: string;
  onClose: () => void;
  onFeedLoaded: () => void;
};

const ProductUpdates: FC<ProductUpdatesProps> = ({
  isOpen,
  feedURL,
  onFeedLoaded,
  onClose,
}) => {
  const { track } = useAnalytics();
  const [isFeedLoading, setIsFeedLoading] = useState(true);
  const [openTime, setOpenTime] = useState<Date | null>(null);
  const onOpen = () => {
    setOpenTime(new Date());
  };

  const handleOnClose = () => {
    onClose();
    const closeTime = new Date();
    const timeDiff =
      closeTime && openTime ? closeTime.getTime() - openTime.getTime() : null;
    if (timeDiff !== null) {
      track({
        name: 'product_updates:drawer:closed',
        data: { time_spent_ms: timeDiff },
      });
    }
  };

  useEffect(() => {
    if (!isFeedLoading) {
      onFeedLoaded();
    }
  }, [isFeedLoading, onFeedLoaded]);
  return (
    <Drawer
      open={isOpen}
      onOpen={onOpen}
      onClose={handleOnClose}
      drawerStyles={{
        body: {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
      }}
      title={
        <Text type="heading" size="medium">
          Product updates
        </Text>
      }
    >
      {isFeedLoading && <Loader title="Product Updates Loading" />}
      {feedURL && (
        <iframe
          className={css.beamer}
          title="Notifications from Anchorage Digital"
          onLoad={() => setIsFeedLoading(false)}
          sandbox="allow-scripts allow-popups allow-same-origin"
          src={feedURL}
        />
      )}
    </Drawer>
  );
};

export default ProductUpdates;
