import cn from 'classnames';

const FolderOpenIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FolderOpen', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 12H24L20 8H8C5.79 8 4.02 9.79 4.02 12L4 36C4 38.21 5.79 40 8 40H40C42.21 40 44 38.21 44 36V16C44 13.79 42.21 12 40 12ZM40 36H8V16H40V36Z" />
    </svg>
  );
};

export { FolderOpenIcon };
