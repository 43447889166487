import cn from 'classnames';

const BugReportIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BugReport', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 16H34.38C33.48 14.44 32.24 13.09 30.75 12.08L34 8.83L31.17 6L26.82 10.35C25.92 10.13 24.97 10 24 10C23.03 10 22.08 10.13 21.18 10.35L16.83 6L14 8.83L17.25 12.08C15.76 13.09 14.52 14.44 13.62 16H8V20H12.18C12.07 20.65 12 21.32 12 22V24H8V28H12V30C12 30.68 12.07 31.35 12.18 32H8V36H13.62C15.69 39.58 19.56 42 24 42C28.44 42 32.31 39.58 34.38 36H40V32H35.82C35.93 31.35 36 30.68 36 30V28H40V24H36V22C36 21.32 35.93 20.65 35.82 20H40V16ZM28 32H20V28H28V32ZM28 24H20V20H28V24Z" />
    </svg>
  );
};

export { BugReportIcon };
