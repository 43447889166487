import cn from 'classnames';

const ContactPhoneIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ContactPhone', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M44 6H4C1.79 6 0 7.79 0 10V38C0 40.21 1.79 42 4 42H44C46.21 42 47.98 40.21 47.98 38L48 10C48 7.79 46.21 6 44 6ZM16 12C19.31 12 22 14.69 22 18C22 21.32 19.31 24 16 24C12.69 24 10 21.32 10 18C10 14.69 12.69 12 16 12ZM28 36H4V34C4 30 12 27.8 16 27.8C20 27.8 28 30 28 34V36ZM35.7 28H38.98L42 32L38.01 35.99C35.4 34.03 33.45 31.24 32.55 28C32.2 26.72 32 25.39 32 24C32 22.61 32.2 21.28 32.56 20C33.45 16.76 35.4 13.97 38.02 12.01L42 16L38.98 20H35.7C35.26 21.25 35 22.6 35 24C35 25.4 35.25 26.75 35.7 28Z" />
    </svg>
  );
};

export { ContactPhoneIcon };
