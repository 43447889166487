import noop from 'lodash/noop';
import Mixpanel from 'mixpanel-browser';
import type { ReactNode } from 'react';
import { createContext, useCallback, useMemo } from 'react';

import { AnalyticsEvent, Organization, User } from '../types';

export type AnalyticsProviderProps = {
  user: Partial<User>;
  organization?: Partial<Organization>;
  children: ReactNode;
  options: {
    enabled: boolean;
    token: string;
    debug?: boolean;
  };
};

type AnalyticsContextProps = {
  track: (event: AnalyticsEvent) => void;
  timeEvent: (eventName: string) => void;
};

export const AnalyticsContext = createContext<AnalyticsContextProps>({
  track: noop,
  timeEvent: noop,
});

export const AnalyticsProvider = ({
  user,
  organization,
  options,
  children,
}: AnalyticsProviderProps) => {
  const isMixpanelTrackingEnabled = useMemo(
    () => options.enabled ?? false,
    [options.enabled],
  );
  const shouldSendEventsToMixpanel = useMemo(
    () => isMixpanelTrackingEnabled && user.publicKeyID !== undefined,
    [isMixpanelTrackingEnabled, user.publicKeyID],
  );
  const organizationId = useMemo(
    () => organization?.organizationKeyID,
    [organization?.organizationKeyID],
  );

  const organizationOperator = organization?.operator;

  if (shouldSendEventsToMixpanel) {
    //https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelset_config
    Mixpanel.init(options.token, {
      disable_persistence: true,
      debug: options.debug ?? false,
    });
    Mixpanel.identify(user.publicKeyID);
  }

  const trackFn = useCallback(
    (event: AnalyticsEvent) => {
      if (shouldSendEventsToMixpanel) {
        Mixpanel.track(event.name, {
          ...(organizationId && { organization: organizationId }),
          ...(user.userID && { userID: user.userID }),
          ...(user.userType && { userType: user.userType }),
          organizationOperator,
          ...(event.data ?? {}),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationId, organizationOperator, shouldSendEventsToMixpanel],
  );

  const timeEventFn = useCallback(
    (eventName: string) => {
      if (shouldSendEventsToMixpanel) {
        Mixpanel.time_event(eventName);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldSendEventsToMixpanel],
  );

  const providerValue = {
    track: trackFn,
    timeEvent: timeEventFn,
  };

  return (
    <AnalyticsContext.Provider value={providerValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};
