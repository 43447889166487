import cn from 'classnames';

const FlipToFrontIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FlipToFront', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6 26H10V22H6V26ZM6 34H10V30H6V34ZM10 42V38H6C6 40.21 7.79 42 10 42ZM6 18H10V14H6V18ZM30 42H34V38H30V42ZM38 6H18C15.79 6 14 7.79 14 10V30C14 32.21 15.79 34 18 34H38C40.21 34 42 32.21 42 30V10C42 7.79 40.21 6 38 6ZM38 30H18V10H38V30ZM22 42H26V38H22V42ZM14 42H18V38H14V42Z" />
    </svg>
  );
};

export { FlipToFrontIcon };
