import type { PolicySourceOfTruth } from '../generated/graphql';

import type { BarePolicy, BarePolicyFragmentBinding } from '../types';

import { BasePolicy } from './BasePolicy';

export class Policy extends BasePolicy {
  constructor(
    sourceOfTruth: PolicySourceOfTruth,
    rawPolicy: BarePolicy | BarePolicyFragmentBinding,
  ) {
    super(sourceOfTruth, rawPolicy);
  }
}
