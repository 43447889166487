import cn from 'classnames';

const BackupIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Backup', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38.71 20.07C37.35 13.19 31.28 8 24 8C18.22 8 13.21 11.28 10.7 16.07C4.69 16.72 0 21.81 0 28C0 34.63 5.37 40 12 40H38C43.52 40 48 35.52 48 30C48 24.72 43.89 20.44 38.71 20.07ZM28 26V34H20V26H14L24 16L34 26H28Z" />
    </svg>
  );
};

export { BackupIcon };
