import cn from 'classnames';

const HelpIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Help', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 4C12.95 4 4 12.95 4 24C4 35.05 12.95 44 24 44C35.05 44 44 35.05 44 24C44 12.95 35.05 4 24 4ZM26 38H22V34H26V38ZM30.13 22.51L28.34 24.35C26.9 25.79 26 27 26 30H22V29C22 26.79 22.9 24.79 24.34 23.34L26.83 20.82C27.55 20.1 28 19.1 28 18C28 15.79 26.21 14 24 14C21.79 14 20 15.79 20 18H16C16 13.58 19.58 10 24 10C28.42 10 32 13.58 32 18C32 19.76 31.29 21.35 30.13 22.51Z" />
    </svg>
  );
};

export { HelpIcon };
