import cn from 'classnames';

const FullscreenExitIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FullscreenExit', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M10 32H16V38H20V28H10V32ZM16 16H10V20H20V10H16V16ZM28 38H32V32H38V28H28V38ZM32 16V10H28V20H38V16H32Z" />
    </svg>
  );
};

export { FullscreenExitIcon };
