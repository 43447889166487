import cn from 'classnames';

const KeyboardTabIcon = (props: SVGProps): JSX.Element => {
  const { title = 'KeyboardTab', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M23.17 14.83L30.34 22H2V26H30.34L23.17 33.17L26 36L38 24L26 12L23.17 14.83ZM40 12V36H44V12H40Z" />
    </svg>
  );
};

export { KeyboardTabIcon };
