import cn from 'classnames';

const EditLocationIcon = (props: SVGProps): JSX.Element => {
  const { title = 'EditLocation', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 4C16.28 4 10 10.28 10 18C10 28.5 24 44 24 44C24 44 38 28.5 38 18C38 10.28 31.72 4 24 4ZM20.88 24H18V21.12L24.7 14.44L27.56 17.3L20.88 24ZM29.78 15.1L28.38 16.5L25.5 13.62L26.9 12.22C27.2 11.92 27.68 11.92 27.98 12.22L29.78 14.02C30.08 14.32 30.08 14.8 29.78 15.1Z" />
    </svg>
  );
};

export { EditLocationIcon };
