import cn from 'classnames';

const HealingIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Healing', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M35.46 24.04L43.42 16.08C44.2 15.3 44.2 14.03 43.42 13.25L34.75 4.58C33.97 3.8 32.7 3.8 31.92 4.58L23.96 12.54L16 4.59C15.61 4.2 15.1 4 14.59 4C14.08 4 13.57 4.2 13.18 4.59L4.50004 13.26C3.72004 14.04 3.72004 15.31 4.50004 16.09L12.46 24.05L4.50004 32C3.72004 32.78 3.72004 34.05 4.50004 34.83L13.17 43.5C13.95 44.28 15.22 44.28 16 43.5L23.96 35.54L31.92 43.5C32.31 43.89 32.82 44.09 33.33 44.09C33.84 44.09 34.35 43.89 34.74 43.5L43.41 34.83C44.19 34.05 44.19 32.78 43.41 32L35.46 24.04ZM24 18C25.1 18 26 18.9 26 20C26 21.1 25.1 22 24 22C22.9 22 22 21.1 22 20C22 18.9 22.9 18 24 18ZM14.58 21.92L7.33004 14.67L14.59 7.41L21.84 14.66L14.58 21.92ZM20 26C18.9 26 18 25.1 18 24C18 22.9 18.9 22 20 22C21.1 22 22 22.9 22 24C22 25.1 21.1 26 20 26ZM24 30C22.9 30 22 29.1 22 28C22 26.9 22.9 26 24 26C25.1 26 26 26.9 26 28C26 29.1 25.1 30 24 30ZM28 22C29.1 22 30 22.9 30 24C30 25.1 29.1 26 28 26C26.9 26 26 25.1 26 24C26 22.9 26.9 22 28 22ZM33.33 40.67L26.08 33.42L33.34 26.16L40.59 33.41L33.33 40.67Z" />
    </svg>
  );
};

export { HealingIcon };
