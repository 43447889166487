import cn from 'classnames';

const CardMembershipIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CardMembership', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 4H8C5.79 4 4 5.79 4 8V30C4 32.21 5.79 34 8 34H16V44L24 40L32 44V34H40C42.21 34 44 32.21 44 30V8C44 5.79 42.21 4 40 4ZM40 30H8V26H40V30ZM40 20H8V8H40V20Z" />
    </svg>
  );
};

export { CardMembershipIcon };
