import cn from 'classnames';

const DriveEtaIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DriveEta', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M37.84 10.02C37.43 8.84 36.31 8 35 8H13C11.69 8 10.57 8.84 10.16 10.02L6 22V38C6 39.1 6.9 40 8 40H10C11.11 40 12 39.1 12 38V36H36V38C36 39.1 36.9 40 38 40H40C41.11 40 42 39.1 42 38V22L37.84 10.02ZM13 30C11.34 30 10 28.66 10 27C10 25.34 11.34 24 13 24C14.66 24 16 25.34 16 27C16 28.66 14.66 30 13 30ZM35 30C33.34 30 32 28.66 32 27C32 25.34 33.34 24 35 24C36.66 24 38 25.34 38 27C38 28.66 36.66 30 35 30ZM10 20L13 11H35L38 20H10Z" />
    </svg>
  );
};

export { DriveEtaIcon };
