import cn from 'classnames';

const ImportContactsIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ImportContacts', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 10C39.79 9.3 37.34 9 35 9C31.1 9 26.9 9.8 24 12C21.1 9.8 16.9 9 13 9C9.1 9 4.9 9.8 2 12V41.3C2 41.8 2.5 42.3 3 42.3C3.2 42.3 3.3 42.2 3.5 42.2C6.2 40.9 10.1 40 13 40C16.9 40 21.1 40.8 24 43C26.7 41.3 31.6 40 35 40C38.3 40 41.7 40.6 44.5 42.1C44.7 42.2 44.8 42.2 45 42.2C45.5 42.2 46 41.7 46 41.2V12C44.8 11.1 43.5 10.5 42 10ZM42 37C39.8 36.3 37.4 36 35 36C31.6 36 26.7 37.3 24 39V16C26.7 14.3 31.6 13 35 13C37.4 13 39.8 13.3 42 14V37Z" />
    </svg>
  );
};

export { ImportContactsIcon };
