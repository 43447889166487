import cn from 'classnames';

const ConfirmationNumberIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ConfirmationNumber', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M44 20V12C44 9.79 42.21 8 40 8H8C5.79 8 4.02 9.79 4.02 12V20C6.22 20.01 8 21.8 8 24C8 26.21 6.21 28 4.01 28L4 36C4 38.21 5.79 40 8 40H40C42.21 40 44 38.21 44 36V28C41.79 28 40 26.21 40 24C40 21.79 41.79 20 44 20ZM26 35H22V31H26V35ZM26 26H22V22H26V26ZM26 17H22V13H26V17Z" />
    </svg>
  );
};

export { ConfirmationNumberIcon };
