import cn from 'classnames';

const Looks3Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Looks3', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38.01 6H10.01C7.80001 6 6.01001 7.79 6.01001 10V38C6.01001 40.21 7.80001 42 10.01 42H38.01C40.22 42 42.01 40.21 42.01 38V10C42.01 7.79 40.22 6 38.01 6ZM30.01 21C30.01 22.66 28.67 24 27.01 24C28.67 24 30.01 25.34 30.01 27V30C30.01 32.21 28.22 34 26.01 34H18.01V30H26.01V26H22.01V22H26.01V18H18.01V14H26.01C28.22 14 30.01 15.79 30.01 18V21Z" />
    </svg>
  );
};

export { Looks3Icon };
