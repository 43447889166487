import cn from 'classnames';

const CollectionsIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Collections', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M44 32V8C44 5.79 42.21 4 40 4H16C13.79 4 12 5.79 12 8V32C12 34.21 13.79 36 16 36H40C42.21 36 44 34.21 44 32ZM22 24L26.06 29.42L32 22L40 32H16L22 24ZM4 12V40C4 42.21 5.79 44 8 44H36V40H8V12H4Z" />
    </svg>
  );
};

export { CollectionsIcon };
