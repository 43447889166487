import { transformAddresses } from '../../../../utils/mapAddressToWallets';

import type { OperationDrawer } from 'generated/graphql';

import AddressInfo from '../../../widgets/AddressInfo';
import AmountAndFees from '../../../widgets/AmountAndFees';
import DisclosureSection from '../../../widgets/DisclosureSection';
import Transaction from '../../../widgets/Transaction';

type AuthorizeSpenderOperation = Extract<
  OperationDrawer.operation,
  { __typename: 'AuthorizeSpenderOperation' }
>;

type Props = {
  operation: AuthorizeSpenderOperation;
};

const AuthorizeSpender = ({ operation }: Props) => {
  const {
    asset: { vault: receivingVault },
    finalFee,
    maybeTransactionID,
    sendingBlockchainAddresses,
    maybeViewTransactionLink,
  } = operation;

  const { account } = receivingVault;
  const sendingAddress = sendingBlockchainAddresses.length
    ? sendingBlockchainAddresses[0]
    : null;

  return (
    <>
      <AddressInfo
        addressSectionTitle="Setup for"
        addresses={transformAddresses(sendingBlockchainAddresses)}
        vaultName={receivingVault.name}
        blockchainExplorerURL={sendingAddress?.blockchainExplorerURL}
        accountName={account?.displayName}
      />
      <AmountAndFees fee={finalFee} disclosureTitle="Max fee disclosure" />
      <Transaction
        transactionID={maybeTransactionID}
        transactionLink={maybeViewTransactionLink}
      />
      {finalFee && <DisclosureSection assetTypeID={finalFee.assetTypeID} />}
    </>
  );
};

export default AuthorizeSpender;
