import cn from 'classnames';

const CampaignIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Campaign', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 25.5001C37.5667 25.5001 37.2083 25.3584 36.925 25.0751C36.6417 24.7917 36.5 24.4334 36.5 24.0001C36.5 23.5667 36.6417 23.2084 36.925 22.9251C37.2083 22.6417 37.5667 22.5001 38 22.5001H42.5C42.9333 22.5001 43.2917 22.6417 43.575 22.9251C43.8583 23.2084 44 23.5667 44 24.0001C44 24.4334 43.8583 24.7917 43.575 25.0751C43.2917 25.3584 42.9333 25.5001 42.5 25.5001H38ZM37.8 39.1001L34.15 36.4001C33.8167 36.1334 33.6167 35.8001 33.55 35.4001C33.4833 35.0001 33.5833 34.6334 33.85 34.3001C34.1167 33.9667 34.45 33.7667 34.85 33.7001C35.25 33.6334 35.6167 33.7334 35.95 34.0001L39.6 36.7001C39.9333 36.9667 40.1333 37.3001 40.2 37.7001C40.2667 38.1001 40.1667 38.4667 39.9 38.8001C39.6333 39.1334 39.3 39.3334 38.9 39.4001C38.5 39.4667 38.1333 39.3667 37.8 39.1001ZM36.1 13.9501C35.7667 14.2167 35.4 14.3167 35 14.2501C34.6 14.1834 34.2667 13.9834 34 13.6501C33.7333 13.3167 33.6333 12.9501 33.7 12.5501C33.7667 12.1501 33.9667 11.8167 34.3 11.5501L37.8 8.90008C38.1333 8.63341 38.5 8.53341 38.9 8.60008C39.3 8.66674 39.6333 8.86674 39.9 9.20008C40.1667 9.53341 40.2667 9.90008 40.2 10.3001C40.1333 10.7001 39.9333 11.0334 39.6 11.3001L36.1 13.9501ZM10.5 38.0001V30.0001H7C6.16667 30.0001 5.45833 29.7084 4.875 29.1251C4.29167 28.5417 4 27.8334 4 27.0001V21.0001C4 20.1667 4.29167 19.4584 4.875 18.8751C5.45833 18.2917 6.16667 18.0001 7 18.0001H16L23.75 13.3501C24.25 13.0501 24.75 13.0501 25.25 13.3501C25.75 13.6501 26 14.0834 26 14.6501V33.3501C26 33.9167 25.75 34.3501 25.25 34.6501C24.75 34.9501 24.25 34.9501 23.75 34.6501L16 30.0001H13.5V38.0001H10.5ZM28 30.7001V17.3001C28.9 18.1001 29.625 19.0751 30.175 20.2251C30.725 21.3751 31 22.6334 31 24.0001C31 25.3667 30.725 26.6251 30.175 27.7751C29.625 28.9251 28.9 29.9001 28 30.7001ZM23 30.7001V17.3001L16.8 21.0001H7V27.0001H16.8L23 30.7001Z" />
    </svg>
  );
};

export { CampaignIcon };
