import type {
  AssetTypeID,
  AssetTypeInfoFieldsFragment,
} from 'generated/graphql';

import type { AssetNetworkIDMap } from './index';

export function alphabeticalSortAssetID(
  a: AssetTypeInfoFieldsFragment,
  b: AssetTypeInfoFieldsFragment,
) {
  return a.assetTypeID.toUpperCase() < b.assetTypeID.toUpperCase() ? -1 : 1;
}

interface GetAssetNetworkIDProps {
  assetTypeID: AssetTypeID | null | undefined;
  assetNetworkIDMap: AssetNetworkIDMap;
}

export function getAssetNetworkID({
  assetTypeID,
  assetNetworkIDMap,
}: GetAssetNetworkIDProps) {
  if (!assetTypeID) {
    return '';
  }
  return assetNetworkIDMap[assetTypeID]?.networkID ?? '';
}
