import cn from 'classnames';

const FullscreenIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Fullscreen', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M14 28H10V38H20V34H14V28ZM10 20H14V14H20V10H10V20ZM34 34H28V38H38V28H34V34ZM28 10V14H34V20H38V10H28Z" />
    </svg>
  );
};

export { FullscreenIcon };
