import type { OperationDrawer } from 'generated/graphql';

import Permissions from '../../../widgets/Permissions';
import TextSection from '../../../widgets/TextSection';

type ChangeRoleOperationType = Extract<
  OperationDrawer.operation,
  { __typename: 'ChangeRoleOperation' }
>;

type Props = {
  operation: ChangeRoleOperationType;
};

const ChangeRoleOperation = ({ operation }: Props) => {
  const {
    role,
    type,
    operationInfo: { organization },
  } = operation;

  return (
    <>
      <TextSection content={role.name} title="Permission group name" />
      <Permissions
        organization={organization}
        role={role}
        roleChangeType={type}
      />
    </>
  );
};

export default ChangeRoleOperation;
