import cn from 'classnames';

const BurstModeIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BurstMode', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M2 10H6V38H2V10ZM10 10H14V38H10V10ZM44 10H20C18.9 10 18 10.9 18 12V36C18 37.1 18.9 38 20 38H44C45.1 38 46 37.1 46 36V12C46 10.9 45.1 10 44 10ZM22 34L27 27.7L30.57 32L35.57 25.56L42 34H22Z" />
    </svg>
  );
};

export { BurstModeIcon };
