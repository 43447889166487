import cn from 'classnames';
import type { FC, HTMLAttributes } from 'react';

import { HelpMenu, OrgInfo, PageName, User } from './components';

export const TopBar: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...rest
}) => {
  return (
    <div className={cn(['h-sizing-sys-size-56'])}>
      <header
        {...rest}
        className={cn([
          className,
          'fixed',
          'z-50',
          // This calculation is to remove the scrollbars width if it exists 🔨
          // https://stackoverflow.com/a/34884924
          'w-[calc(100vw-(100vw-100%)-var(--sidenav-width))]',
          'h-sizing-sys-size-56',
          'bg-color-ref-ui-background-base',
          'border-b-2',
          'border-b-color-ref-ui-surface-container',
          'px-spacing-sys-xx-large-40',
          'py-spacing-sys-xsmall-8',
          'flex',
          'items-center',
          'justify-between',
        ])}
        data-testid="top-bar"
      >
        <PageName />
        <div
          className={cn([
            'flex',
            'gap-x-sizing-sys-size-12',
            'ml-sizing-sys-size-8',
          ])}
        >
          <OrgInfo />
          <HelpMenu />
          <User />
        </div>
      </header>
    </div>
  );
};
