import css from './styles.module.scss';

import type { RoleChanges } from '../../types';

import SectionRow from '../../../../shared/SectionRow';
import WidgetWrapper from '../../../../shared/WidgetWrapper';
import UpdatedIAMRoleUsersAccordion from './UpdatedIAMRoleUsersAccordion';

export const UpdatedIAMRoleUsers = ({
  changes,
}: {
  changes: RoleChanges['changes'];
}) => {
  return (
    <WidgetWrapper>
      <SectionRow title="Role changes">
        <div className={css.accordionWrapper}>
          {changes.map((roleChange) => {
            const mapUserPredicate = (
              user:
                | (typeof roleChange)['added'][number]
                | (typeof roleChange)['removed'][number],
            ) => ({
              name: user.actorInfo.displayName,
            });
            const added = roleChange.added.map(mapUserPredicate);
            const removed = roleChange.removed.map(mapUserPredicate);
            return (
              <div key={roleChange.roleSnapshot.id}>
                <UpdatedIAMRoleUsersAccordion
                  title={roleChange.roleSnapshot.name}
                  added={added}
                  removed={removed}
                />
              </div>
            );
          })}
        </div>
      </SectionRow>
    </WidgetWrapper>
  );
};
