/**
 * Get local date from UTC date
 *
 * @param date
 */
export default function toLocalFromUTCDate(currentDate: Date): Date {
  return new Date(
    currentDate.getTime() - currentDate.getTimezoneOffset() * 60 * 1000
  );
}
