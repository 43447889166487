import * as React from 'react';

import { Text } from '@anchorage/common/dist/components';

import SectionRow from '../../shared/SectionRow';
import WidgetWrapper from '../../shared/WidgetWrapper';

type Props = {
  content: React.ReactNode;
  title: string;
};

const TextSection = ({ content, title }: Props) => {
  return (
    <WidgetWrapper>
      <SectionRow title={title}>
        <Text size="small">{content}</Text>
      </SectionRow>
    </WidgetWrapper>
  );
};

export default TextSection;
