import React from 'react';

import useIsPortoApp from '../../../utils/useIsPortoApp';

import css from './styles.module.scss';

import { CurrentUserProps } from './types';

import getUserGreeting from './getUserGreeting';

const CurrentUser: React.FC<CurrentUserProps> = ({
  orgName,
  userName,
  addedTime,
}) => {
  const isPorto = useIsPortoApp();
  const greeting = `${getUserGreeting({
    addedTime: addedTime || '',
    firstDayMessage: isPorto
      ? 'Welcome to Porto'
      : 'Welcome to Anchorage Digital',
  })},`;

  return (
    <div className={css.currentUserSection} data-testid="current-user-section">
      {userName && (
        <div className={css.greetingAndAbbreviation}>
          {greeting && <div className={css.greeting}>{greeting}</div>}
          <div className={css.userName} data-testid="current-user-name">
            {userName}
          </div>
        </div>
      )}
      {orgName && (
        <div
          className={css.orgName}
          data-testid="current-org-name"
          title="Organization Name"
        >
          {orgName}
        </div>
      )}
    </div>
  );
};

export default CurrentUser;
