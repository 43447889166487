/* eslint-disable react/display-name */
// Utils
import cn from 'classnames';
import React from 'react';
// Components
import { VirtuosoGrid } from 'react-virtuoso';

import { Loader } from '../../../components';

// Styles
import css from './index.css';

// Types
import type { GridDataProps } from './_types';

const NFTGrid = ({
  isLoading = false,
  overscan = {
    main: 30,
    reverse: 30,
  },
  itemClassName = '',
  listClassName = '',
  data,
  components,
  ...restProps
}: GridDataProps) => {
  if (!data) {
    return null;
  }

  const itemClasses = cn({
    [css.itemContainerRoot]: true,
    [itemClassName]: Boolean(itemClassName),
  });

  const listClasses = cn({
    [css.listContainerRoot]: true,
    [listClassName]: Boolean(listClassName),
  });

  return (
    <VirtuosoGrid
      data-testid="nft-grid"
      overscan={overscan}
      data={data}
      listClassName={listClasses}
      itemClassName={itemClasses}
      components={{
        ...components,
        Footer: () => {
          if (isLoading) {
            return (
              <div className={css.footer}>
                <Loader />
              </div>
            );
          }
          return null;
        },
      }}
      {...restProps}
    />
  );
};

NFTGrid.displayName = 'NFTGrid';

export default NFTGrid;
