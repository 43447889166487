import cn from 'classnames';

const DoneAllIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DoneAll', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M36 13.9999L33.17 11.1699L20.49 23.8599L23.32 26.6899L36 13.9999ZM44.49 11.1699L23.31 32.3399L14.97 23.9999L12.14 26.8299L23.31 37.9999L47.31 13.9999L44.49 11.1699ZM0.829956 26.8299L12 37.9999L14.83 35.1699L3.65996 23.9999L0.829956 26.8299Z" />
    </svg>
  );
};

export { DoneAllIcon };
