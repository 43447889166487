import cn from 'classnames';

const BorderStyleIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BorderStyle', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M30 42H34V38H30V42ZM38 42H42V38H38V42ZM14 42H18V38H14V42ZM22 42H26V38H22V42ZM38 34H42V30H38V34ZM38 26H42V22H38V26ZM6 6V42H10V10H42V6H6ZM38 18H42V14H38V18Z" />
    </svg>
  );
};

export { BorderStyleIcon };
