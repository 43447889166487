import cn from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

import { Loader, Text } from '@anchorage/common/dist/components';
import {
  CheckIcon,
  InfoOutlineIcon,
} from '@anchorage/common/dist/components/Icons';
import { useDeepCompare } from '@anchorage/hooks';

import {
  OperationAction,
  OperationState,
  useGetOperationStatusQuery,
} from 'generated/graphql';

import css from './styles.module.scss';

import { OP_BUFFER_TIMEOUT } from './constants';

const { SIGS_REQUIRED, INITIATOR_REJECTED, INITIATOR_REQUIRED, SIGS_FAILED } =
  OperationState;

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onCloseNavigateRoute?: string;
  operationID: string;
  operationAction:
    | OperationAction.ROLE_CHANGE
    | OperationAction.USER_ADD_AND_POLICIES
    | OperationAction.TRUSTED_DESTINATION_ADD
    | OperationAction.TRUSTED_DESTINATION_REMOVE
    | OperationAction.GENERIC_ORGANIZATION_CHANGE
    | OperationAction.WITHDRAW
    | OperationAction.GENERIC
    | OperationAction.OFFCHAIN_OPERATION;
};

const EndorseActionModalContent: React.FC<Props> = ({
  isVisible,
  onClose,
  onCloseNavigateRoute,
  operationAction,
  operationID,
}) => {
  // When the modal first opens, the operation is still being created creating
  // and we do not want to show the error message until the buffer has passed
  const [isBufferActive, setIsBufferActive] = React.useState(true);
  const router = useRouter();

  React.useEffect(() => {
    setTimeout(() => setIsBufferActive(false), OP_BUFFER_TIMEOUT);
  }, []);

  const { data, loading } = useGetOperationStatusQuery({
    pollInterval: isVisible ? 2000 : 0,
    variables: {
      operationID,
    },
  });

  const isQueryLoading = loading;
  const isOperationWaiting =
    // @ts-ignore
    (data?.operation?.action === operationAction &&
      // @ts-ignore
      data.operation?.requiresDecision) ||
    // generic withdrawals don't have a requiresDecision field
    data?.operation?.operationState === INITIATOR_REQUIRED;
  const isWaiting: boolean =
    isQueryLoading || isOperationWaiting || isBufferActive;
  const operationState = data?.operation?.operationState;
  const isEndorsedByInitiator = operationState === SIGS_REQUIRED;
  const initiatorRejected =
    operationState === INITIATOR_REJECTED || operationState === SIGS_FAILED;

  const delayedModalClose = React.useCallback(
    () => {
      setTimeout(() => {
        onClose();
        if (
          operationAction === OperationAction.ROLE_CHANGE &&
          onCloseNavigateRoute
        ) {
          router.push(onCloseNavigateRoute);
        }
      }, 3000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([onClose, operationAction, onCloseNavigateRoute, router]),
  );

  const status = React.useMemo(
    () => {
      if (isWaiting) {
        const classes = cn([css.status, css.waiting]);
        return (
          <div
            data-testid="endorsement-modal-status-waiting"
            className={classes}
          >
            <Loader /> Waiting for endorsement...
          </div>
        );
      } else if (isEndorsedByInitiator) {
        delayedModalClose();

        return (
          <Text
            data-testid="endorsement-modal-status-success"
            className={cn([css.status, css.success])}
          >
            <CheckIcon className={css.checkIcon} />
            Endorsement complete!
          </Text>
        );
      } else if (initiatorRejected) {
        delayedModalClose();

        return (
          <Text
            className={cn([css.status, css.error])}
            data-testid="endorsement-modal-status-initiator-rejected"
          >
            <InfoOutlineIcon className={css.errorIcon} /> Operation rejected
          </Text>
        );
      }

      delayedModalClose();
      return (
        <Text
          data-testid="endorsement-modal-status-error"
          className={cn([css.status, css.error])}
        >
          <InfoOutlineIcon className={css.errorIcon} /> We couldn&apos;t process
          your operation. Please try submitting again.
        </Text>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([
      isWaiting,
      isEndorsedByInitiator,
      initiatorRejected,
      delayedModalClose,
    ]),
  );

  return (
    <Text className={css.endorseModalText}>
      <Text className={css.text}>
        To process this operation, open your app and complete the biometric
        endorsement.
      </Text>
      <Text className={css.text}>
        Please endorse the operation within 24 hours, otherwise the operation
        will expire.
      </Text>
      <Text className={css.text}>
        Closing this window won&apos;t affect the status of this operation.
      </Text>
      {status}
    </Text>
  );
};

export default EndorseActionModalContent;
