import cn from 'classnames';

const CancelIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Cancel', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 4.00004C12.95 4.00004 4 12.95 4 24C4 35.05 12.95 44 24 44C35.05 44 44 35.05 44 24C44 12.95 35.05 4.00004 24 4.00004ZM34 31.17L31.17 34L24 26.83L16.83 34L14 31.17L21.17 24L14 16.83L16.83 14L24 21.17L31.17 14L34 16.83L26.83 24L34 31.17Z" />
    </svg>
  );
};

export { CancelIcon };
