import cn from 'classnames';

const LocalFloristIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalFlorist', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 44C33.94 44 42 35.94 42 26C32.06 26 24 34.06 24 44ZM11.21 20.5C11.21 23.26 13.45 25.5 16.21 25.5C17.26 25.5 18.24 25.17 19.04 24.62L19 25C19 27.76 21.24 30 24 30C26.76 30 29 27.76 29 25L28.96 24.62C29.77 25.18 30.74 25.5 31.79 25.5C34.55 25.5 36.79 23.26 36.79 20.5C36.79 18.51 35.62 16.8 33.93 16C35.61 15.2 36.79 13.49 36.79 11.5C36.79 8.74 34.55 6.5 31.79 6.5C30.74 6.5 29.76 6.83 28.96 7.38L29 7C29 4.24 26.76 2 24 2C21.24 2 19 4.24 19 7L19.04 7.38C18.23 6.82 17.26 6.5 16.21 6.5C13.45 6.5 11.21 8.74 11.21 11.5C11.21 13.49 12.38 15.2 14.07 16C12.38 16.8 11.21 18.51 11.21 20.5ZM24 11C26.76 11 29 13.24 29 16C29 18.76 26.76 21 24 21C21.24 21 19 18.76 19 16C19 13.24 21.24 11 24 11ZM6 26C6 35.94 14.06 44 24 44C24 34.06 15.94 26 6 26Z" />
    </svg>
  );
};

export { LocalFloristIcon };
