import type { Column } from 'react-table';

import {
  CellAccount,
  CellAmount,
  CellCheckbox,
  CellOperation,
  CellOperationDate,
  CellVaultName,
} from './_components/Cells';

import type { PendingAttributionRow } from './_types';

export enum PENDING_ATTRIBUTIONS_TABLE_COLUMN_NAMES {
  OPERATION_DATE = 'DATE',
  ACCOUNT = 'ACCOUNT',
  AMOUNT = 'AMOUNT',
  VAULT_NAME = 'VAULT NAME',
  OPERATION = 'OPERATION DETAILS',
}

export const PENDING_ATTRIBUTIONS_COLUMNS: Column<PendingAttributionRow>[] = [
  {
    Cell: CellCheckbox as any,
    id: 'check-attribution',
  },
  {
    Header: PENDING_ATTRIBUTIONS_TABLE_COLUMN_NAMES.OPERATION_DATE,
    Cell: CellOperationDate as any,
  },
  {
    Header: PENDING_ATTRIBUTIONS_TABLE_COLUMN_NAMES.AMOUNT,
    Cell: CellAmount as any,
  },
  {
    Header: PENDING_ATTRIBUTIONS_TABLE_COLUMN_NAMES.VAULT_NAME,
    Cell: CellVaultName as any,
  },
  {
    Header: PENDING_ATTRIBUTIONS_TABLE_COLUMN_NAMES.OPERATION,
    Cell: CellOperation as any,
  },
];

export const PENDING_ATTRIBUTIONS_COLUMNS_WITH_ACCOUNT = [
  ...PENDING_ATTRIBUTIONS_COLUMNS.slice(0, 2),
  {
    Header: PENDING_ATTRIBUTIONS_TABLE_COLUMN_NAMES.ACCOUNT,
    Cell: CellAccount,
  },
  ...PENDING_ATTRIBUTIONS_COLUMNS.slice(2),
];
