import cn from 'classnames';

const IsoIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Iso', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 6H10C7.79 6 6 7.79 6 10V38C6 40.21 7.79 42 10 42H38C40.21 42 42 40.21 42 38V10C42 7.79 40.21 6 38 6ZM11 15H15V11H18V15H22V18H18V22H15V18H11V15ZM38 38H10L38 10V38ZM34 34V31H24V34H34Z" />
    </svg>
  );
};

export { IsoIcon };
