import cn from 'classnames';

const LaptopChromebookIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LaptopChromebook', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M44 36V6H4V36H0V40H48V36H44ZM28 36H20V34H28V36ZM40 30H8V10H40V30Z" />
    </svg>
  );
};

export { LaptopChromebookIcon };
