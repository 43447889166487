import type { OperationDrawer } from 'generated/graphql';

import User from '../../../widgets/User';

type ReplaceUserOperationType = Extract<
  OperationDrawer.operation,
  { __typename: 'ReplaceUserOperation' }
>;

type Props = {
  operation: ReplaceUserOperationType;
};

const ReplaceUserOperation = ({ operation }: Props) => {
  const {
    newUser: { name, userType },
  } = operation;

  return <User name={name} role={userType} />;
};

export default ReplaceUserOperation;
