import cn from 'classnames';

const FilterIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Filter', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M31.93 20.57L26.43 27.65L22.5 22.93L17 30H39L31.93 20.57ZM6 10H2V42C2 44.21 3.79 46 6 46H38V42H6V10ZM42 2H14C11.79 2 10 3.79 10 6V34C10 36.21 11.79 38 14 38H42C44.21 38 46 36.21 46 34V6C46 3.79 44.21 2 42 2ZM42 34H14V6H42V34Z" />
    </svg>
  );
};

export { FilterIcon };
