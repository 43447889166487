import { Text } from '@anchorage/common/dist/components';
import { NormalizedUnsignedTextField } from '@anchorage/vsp';

import css from './styles.module.scss';

export const UnknownRow = ({
  field,
}: {
  field: NormalizedUnsignedTextField;
}) => {
  return (
    <>
      {field?.label && (
        <Text size="small" className={css.label}>
          {field.label}
        </Text>
      )}
      <Text size="small">{field.text ?? field?.fallbackText}</Text>
    </>
  );
};
