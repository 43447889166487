import cn from 'classnames';

const DeveloperBoardIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DeveloperBoard', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M44 18V14H40V10C40 7.8 38.2 6 36 6H8C5.8 6 4 7.8 4 10V38C4 40.2 5.8 42 8 42H36C38.2 42 40 40.2 40 38V34H44V30H40V26H44V22H40V18H44ZM36 38H8V10H36V38ZM12 26H22V34H12V26ZM24 14H32V20H24V14ZM12 14H22V24H12V14ZM24 22H32V34H24V22Z" />
    </svg>
  );
};

export { DeveloperBoardIcon };
