import cn from 'classnames';

const IndeterminateCheckBoxIcon = (props: SVGProps): JSX.Element => {
  const {
    title = 'IndeterminateCheckBox24px',
    className,
    ...restProps
  } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 6H10C7.8 6 6 7.8 6 10V38C6 40.2 7.8 42 10 42H38C40.2 42 42 40.2 42 38V10C42 7.8 40.2 6 38 6ZM34 26H14V22H34V26Z" />
    </svg>
  );
};

export { IndeterminateCheckBoxIcon };
