import type { HEX } from 'types';

export enum BrandThemeEnum {
  Dark = 'dark',
  Light = 'light',
}

// Dark brand theme uses the white version of the icon and vice versa
export const BrandThemeColors: {
  [key in BrandThemeEnum]: HEX;
} = {
  [BrandThemeEnum.Dark]: '#FFFFFF',
  [BrandThemeEnum.Light]: '#000000',
};
