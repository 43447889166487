import cn from 'classnames';

const DirectionsBikeIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DirectionsBike', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M31 11C33.2 11 35 9.2 35 7C35 4.8 33.2 3 31 3C28.8 3 27 4.8 27 7C27 9.2 28.8 11 31 11ZM10 24C4.5 24 0 28.5 0 34C0 39.5 4.5 44 10 44C15.5 44 20 39.5 20 34C20 28.5 15.5 24 10 24ZM10 41C6.1 41 3 37.9 3 34C3 30.1 6.1 27 10 27C13.9 27 17 30.1 17 34C17 37.9 13.9 41 10 41ZM21.6 21.1L26.3 16.3L27.8 17.8C30.3 20.4 33.8 21.9 37.9 21.9V17.9C34.9 17.9 32.4 16.8 30.6 15L26.7 11.2C26.1 10.5 25.1 10 24 10C22.9 10 21.9 10.4 21.2 11.2L15.7 16.7C15 17.4 14.5 18.4 14.5 19.5C14.5 20.6 15 21.6 15.7 22.4L22 28V38H26V25.5L21.6 21.1ZM38 24C32.5 24 28 28.5 28 34C28 39.5 32.5 44 38 44C43.5 44 48 39.5 48 34C48 28.5 43.5 24 38 24ZM38 41C34.1 41 31 37.9 31 34C31 30.1 34.1 27 38 27C41.9 27 45 30.1 45 34C45 37.9 41.9 41 38 41Z" />
    </svg>
  );
};

export { DirectionsBikeIcon };
