export const ETH_DISCLOSURE_ID = 'ethereum_staking';
export const PRICE_DISCLOSURE_ID = 'price';
export const PRICING_DISCLOSURE_TEXT =
  'Max fees are set aside for the transaction. Any excess unused balances will automatically be returned to you.';
export const STAKING_NO_PERMISSION =
  'You need admin access to stake this asset';
export const STAKING_EMPTY_WALLET = 'Cannot stake from empty wallet.';

export const ETH_FIRST_TIME_STAKING_TITLE =
  'You can now lock up ETH to participate in block proposals on the network, and collect rewards.';

export const ETH_FIRST_TIME_STAKING_SUBTITLE =
  'Anchorage Digital collects a 10% fee from the rewards collected.';

// TO-DO: Update the link to the published staking guide
export const ETH_STAKING_GUIDE_LINK =
  'https://docs.google.com/document/d/1zgYBYoAGpP9KUd_uHiRURzPBdm_JjyfVO_0ovnwHOxQ/edit';

// TO-DO: Update the link to the published staking guide
export const STANDARDIZED_STAKING_GUIDE_LINK =
  'https://docs.google.com/document/d/1zgYBYoAGpP9KUd_uHiRURzPBdm_JjyfVO_0ovnwHOxQ/edit';

export const STANDARD_FIRST_TIME_STAKING_SUBTITLE =
  'Anchorage Digital collects a percentage fee from the rewards collected.';

export const BLOCKING_OPERATION_IN_PROGRESS =
  'You have a pending operation on this wallet. Please complete it before staking.';

export const TOOLTIP_NO_ERROR = 'Stake your assets from this wallet';

export const STAKING_ERROR_MESSAGE_TITLE = 'Staking information is unavailable';

export const STAKING_ERROR_MESSAGE_SUBTITLE =
  'Sorry, it seems something went wrong on our end. Please try again or perform a hard refresh on your browser page.';
