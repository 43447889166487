import cn from 'classnames';
import _set from 'lodash/set';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Banner, Text, Tooltip } from '../../../components';
import { CheckboxLabel } from '../../../components/Checkbox/elements';
import {
  FormCheckbox,
  FormInput,
  FormRadioButtonGroup,
  FormSelect,
  FormTextArea,
} from '../../../components/Form/components';
import { ErrorIcon, InfoOutlineIcon } from '../../../components/Icons';
import { Label } from '../../../components/InputV2/elements';

import { DepositAttributionWalletType } from '../../../types/graphql';

import css from './index.css';

import type { AttributeDepositForm, CountryOption } from '.';

const PendingAttributionsDetails = (
  {
    allowNotes = false,
    availableCountries,
    disableWhenSpam,
    hasMultipleAddresses,
    isAlreadyTrustedSource,
    isEditMode,
    unflagAsSpam,
    hasAddresses,
  }: {
    allowNotes?: boolean;
    availableCountries: CountryOption[];
    disableWhenSpam: boolean;
    hasMultipleAddresses: boolean;
    isAlreadyTrustedSource: boolean;
    isEditMode: boolean;
    unflagAsSpam?: () => void;
    hasAddresses: boolean;
  },
) => {
  const { control, trigger, watch, formState } =
    useFormContext<AttributeDepositForm>();

  // Because we want to validate the form onBlur and not onChange, we need to manually trigger
  // the validation of the last field onChange (because the user might
  // not blur the field)
  const disclaimerValue = watch('disclaimer');
  useEffect(() => {
    if (disclaimerValue !== undefined) {
      trigger('disclaimer');
    }
  }, [disclaimerValue]);

  useEffect(() => {
    trigger('trustedSource');
    // we need to set the field as touched, so the error shows, if it exists. this is because this field is filled by default
    _set(formState.touchedFields, 'trustedSource', true);
  }, [formState.touchedFields]);

  const options = useMemo(
    () => [
      {
        label: 'Custodial wallet',
        value: DepositAttributionWalletType.CUSTODIAL,
        className: css.detailsRadioButtonLabels,
        extraContent: (
          <Text size="tiny" className={css.detailsRadioButton}>
            The wallet owner does not have complete control over the wallet as
            they do not have the private key; a third party does. This key is
            needed to conduct transfers. Examples of custodial wallets include:
            Binance, Coinbase, Kraken, Bitgo, and Fireblocks.
          </Text>
        ),
        disabled: disableWhenSpam,
      },
      {
        label: 'Self-hosted wallet',
        value: DepositAttributionWalletType.SELF_HOSTED,
        className: css.detailsRadioButtonLabels,
        extraContent: (
          <Text size="tiny" className={css.detailsRadioButtonLast}>
            The wallet owner has complete control over the wallet as they have
            the private key. Examples of self-hosted wallets include: Metamask,
            Trust Wallet, Ledger Nano X, Trezor One, Electrum, and Exodus.
          </Text>
        ),
        disabled: disableWhenSpam,
      },
    ],
    [disableWhenSpam],
  );

  const detailsCheckboxMandatoryClasses = cn({
    [css.detailsCheckboxMandatory]: true,
    [css.detailsCheckboxMandatorySolo]: isEditMode,
    [css.detailsCheckboxMandatorySoloWithBanner]:
      isAlreadyTrustedSource && isEditMode,
  });

  return (
    <>
      {isAlreadyTrustedSource && isEditMode && (
        <Banner
          className={css.detailsBanner}
          title="Editing these details will not edit trusted sources"
        >
          <Text size="small">
            These changes will be applied only to this attribution, to update
            future deposits edit the trusted source as well
          </Text>
        </Banner>
      )}
      {disableWhenSpam && (
        <Banner
          actions={[
            {
              text: 'Unflag as spam',
              onClick: unflagAsSpam,
            },
          ]}
          className={css.detailsBanner}
          title="This deposit as flagged as spam"
        />
      )}
      <div
        className={cn({
          [css.formDetails!]: true,
          [css.formDetailsHeight]: !disableWhenSpam,
        })}
      >
        <Text type="heading" size="small" className={css.detailsTitle}>
          Wallet type
        </Text>
        <FormRadioButtonGroup
          name="walletType"
          control={control}
          options={options}
          rules={{
            required: disableWhenSpam ? false : 'An option is required',
          }}
        />
        <div className={css.detailsDivider} />
        <Text type="heading" size="small">
          Originator details
        </Text>
        <div className={css.detailsOriginator}>
          <div className={css.detailsInputWrapper}>
            <Label htmlFor="originator-name" size="small">
              Name <span className={css.inputRequired}> * </span>
            </Label>
            <FormInput
              id="originator-name"
              control={control}
              rules={{
                required: disableWhenSpam ? false : 'Name is required',
              }}
              disabled={disableWhenSpam}
              name="originator.originatorName"
            />
          </div>
          <div className={css.detailsInputWrapper}>
            <Label htmlFor="originator-country" size="small">
              Country <span className={css.inputRequired}> * </span>
            </Label>
            <FormSelect
              isSearchable
              id="originator-country"
              aria-label="Country *"
              control={control}
              options={availableCountries}
              rules={{
                required: disableWhenSpam ? false : 'Country is required',
                onChange: () => trigger(`originator.originatorCountry`),
              }}
              isDisabled={disableWhenSpam}
              name="originator.originatorCountry"
            />
          </div>
        </div>
        {allowNotes && (
          <>
            <div className={css.detailsDivider} />
            <Text type="heading" size="small">
              Notes
            </Text>
            <FormTextArea
              control={control}
              name="notes"
              label="Additional information (optional)"
              className={css.detailsNotes}
            />
          </>
        )}
        {!isEditMode && hasAddresses && (
          <div className={css.detailsCheckboxWrapper}>
            <FormCheckbox
              id="trustedSource"
              control={control}
              name="trustedSource"
              label=""
              rules={{
                validate: (input) => !input || !isAlreadyTrustedSource,
              }}
            />
            <CheckboxLabel htmlFor="trustedSource">
              Add originator to trusted sources
              <Tooltip
                background="light"
                title={
                  <>
                    <Text size="small" className={css.detailsTooltipTitle}>
                      Add details to trusted sources
                    </Text>
                    <Text size="small" className={css.detailsTooltipText}>
                      We will try to attribute the deposit automatically next
                      time if the deposit comes from the same address(es)
                    </Text>
                  </>
                }
              >
                <InfoOutlineIcon className={css.detailsCheckboxIcon} />
              </Tooltip>
            </CheckboxLabel>
            {isAlreadyTrustedSource ? (
              <Text size="tiny" className={css.detailsCheckboxWrapperError}>
                <ErrorIcon className={css.detailsCheckboxWrapperErrorIcon} />
                {hasMultipleAddresses
                  ? 'At least one of the addresses is already a trusted source'
                  : 'Address is already a trusted source'}
              </Text>
            ) : null}
          </div>
        )}
        <div className={detailsCheckboxMandatoryClasses}>
          <FormCheckbox
            id="disclaimer"
            control={control}
            name="disclaimer"
            label="I certify that the information provided above is complete and
            correct to the best of my knowledge"
            rules={{
              required: 'This field is required',
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PendingAttributionsDetails;
