// Utils
import cn from 'classnames';
import React from 'react';

// Components
import {
  Modal,
  Body as ModalBody,
  CloseButton as ModalCloseButton,
  Wrapper as ModalWrapper,
} from '../../../components/ModalV2';

// Styles
import css from './index.css';

// Types
import type { ExpandedImageModalProps } from './_types';

import NFTImage from '../NFTImage';

const ExpandedImageModal: React.FC<ExpandedImageModalProps> = ({
  isOpen,
  image,
  onClose,
}) => {
  const { className: imageClassName = '', ...imageRestProps } = image;

  const imageClassees = cn({
    [css.expandedImage]: true,
    [imageClassName]: Boolean(imageClassName),
  });

  return (
    <Modal
      className={css.modalBackdrop}
      isOpen={isOpen}
      onClose={onClose}
      allowBackdropClose
      allowEscapeClose
      hasCustomWrapper
    >
      <ModalWrapper className={css.modalWrapper}>
        <ModalBody>
          <ModalCloseButton className={css.modalButton} />
          <NFTImage
            className={imageClassees}
            fallbackClassName={css.fallBackImg}
            {...imageRestProps}
            alt={image.alt}
            includeShimmer={false}
          />
        </ModalBody>
      </ModalWrapper>
    </Modal>
  );
};

export default ExpandedImageModal;
