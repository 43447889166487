import cn from 'classnames';

const SquareIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Square', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M19 5V19H5V5H19ZM19 3H5C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V5C21 3.895 20.105 3 19 3Z" />
    </svg>
  );
};

export { SquareIcon };
