import { Text } from '@anchorage/common/dist/components';
import type {
  Props as TextProps,
  TextSizes,
  TextTypes,
} from '@anchorage/common/dist/components/Text';

type Props = Omit<TextProps, 'size' | 'type' | 'children'> & {
  size?: Extract<TextSizes, 'large' | 'medium' | 'small'>;
  type?: Extract<TextTypes, 'mono' | 'metric'>;
  children: string;
};

const DecimalText = ({
  children = '',
  size = 'medium',
  type,
  ...restProps
}: Props) => {
  const [number, decimal] = children.split('.');
  let innerTextSize: TextSizes = 'tiny';

  if (size === 'large') {
    innerTextSize = 'medium';
  }

  if (size === 'medium') {
    innerTextSize = 'small';
  }

  if (size === 'small') {
    innerTextSize = 'tiny';
  }

  return (
    <Text
      style={{ whiteSpace: 'nowrap' }}
      type={type}
      size={size}
      {...restProps}
    >
      {number}
      {decimal && (
        <Text
          style={{ display: 'inline-block' }}
          type={type}
          size={innerTextSize}
        >
          .{decimal}
        </Text>
      )}
    </Text>
  );
};

export default DecimalText;
