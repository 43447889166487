import cn from 'classnames';

const AccountBoxIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AccountBox', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6 10V38C6 40.21 7.79 42 10 42H38C40.21 42 42 40.21 42 38V10C42 7.79 40.21 6 38 6H10C7.79 6 6 7.79 6 10ZM30 18C30 21.32 27.31 24 24 24C20.69 24 18 21.32 18 18C18 14.69 20.69 12 24 12C27.31 12 30 14.69 30 18ZM12 34C12 30 20 27.8 24 27.8C28 27.8 36 30 36 34V36H12V34Z" />
    </svg>
  );
};

export { AccountBoxIcon };
