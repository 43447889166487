import { Checkbox } from '@latitude/checkbox';

import { Text } from '@anchorage/common/dist/components';
import {
  ClockIcon,
  GiftIcon,
  NewReleaseIcon,
  WalletIcon,
} from '@anchorage/common/dist/components/Icons';
import { useLocalStorage } from '@anchorage/hooks';

import css from './styles.module.scss';

export default function UnstakingIntroPage() {
  const [unstakeIntroHidden, setHideUnstakeIntro] = useLocalStorage(
    'hideUnstakeIntro',
    false,
  );

  return (
    <div className={css.mainContainer}>
      <div className={css.topIconAndHeader}>
        <NewReleaseIcon className={css.newReleaseIcon} />
        <Text size="large" className={css.unstakedHeader}>
          Are you sure you want to unstake?
        </Text>
      </div>

      <div className={css.infoSections}>
        <div className={css.infoSectionRow}>
          <ClockIcon className={css.infoIcon} />
          <Text size="medium">
            Unstaked assets may be subject to a protocol-enforced unbonding
            period before they become available.
          </Text>
        </div>

        <div className={css.infoSectionRow}>
          <WalletIcon className={css.infoIcon} />
          <Text size="medium">
            Unstaking will remove your assets from the staking pool and may
            result in a loss of rewards.
          </Text>
        </div>

        <div className={css.infoSectionRowNoBorder}>
          <GiftIcon className={css.infoIcon} />
          <Text size="medium">
            Any unclaimed rewards will be automatically claimed when unstaking.
          </Text>
        </div>

        <div className={css.checkboxRow}>
          <Checkbox onClick={() => setHideUnstakeIntro(!unstakeIntroHidden)} />
          <Text className={css.checkboxLabel} size="small">
            Do not show this message again.
          </Text>
        </div>
      </div>
    </div>
  );
}
