import cn from 'classnames';

const DirectionsSubwayIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DirectionsSubway', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 4C15.16 4 8 5 8 12V31C8 34.87 11.14 38 15 38L12 41V42H36V41L33 38C36.87 38 40 34.87 40 31V12C40 5 32.84 4 24 4ZM15 34C13.34 34 12 32.66 12 31C12 29.34 13.34 28 15 28C16.66 28 18 29.34 18 31C18 32.66 16.66 34 15 34ZM22 22H12V12H22V22ZM33 34C31.34 34 30 32.66 30 31C30 29.34 31.34 28 33 28C34.66 28 36 29.34 36 31C36 32.66 34.66 34 33 34ZM36 22H26V12H36V22Z" />
    </svg>
  );
};

export { DirectionsSubwayIcon };
