import useEventListener from '@use-it/event-listener';
import cn from 'classnames';
// Utils
import { debounce, noop } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

// Components
import { Card } from '../../../components';
import CollectionEmptyPlaceholder from './_components/CollectionEmptyPlaceholder';
import CollectionNFTItemContent from './_components/CollectionNFTItemContent';
import SingleEmptyPlaceholder from './_components/SingleEmptyPlaceholder';
import SingleNFTItemContent from './_components/SingleNFTItemContent';

// Styles
import css from './index.css';

import type { NFTData } from '../_types';
import type { NFTListingPageProps } from './_types';

import NFTGrid from '../NFTGrid';
import NFTPageHeader from '../NFTPageHeader';
import NFTTable from '../NFTTable';
import TableEmptyState from '../TableEmptyState';
import TableSearchState from '../TableSearchState';
// Types & Constants
import { NFTContentNFTViews, NFTContentViews } from '../_constants';

const MAX_INDIVIDUAL_ITEMS = 6;
const MIN_INDIVIDUAL_WIDTH = 168;
const MIN_INDIVIDUAL_ITEMS = 3;

const MAX_COLLECTION_ITEMS = 4;
const MIN_COLLECTION_WIDTH = 312;
const MIN_COLLECTION_ITEMS = 2;

const CARD_PADDING = 48;
const SMALL_CARD_PADDING = 32;
const GRID_GAP = 16;

const calculateCardWidth = (
  ref: React.RefObject<HTMLDivElement>,
  minWidth: number,
  maxItems: number,
  minItems: number
): number => {
  if (!ref.current) {
    return minWidth;
  }

  let finalItemWidth = minWidth;

  const width = ref.current.clientWidth;
  // card padding changes based on window size
  const paddingToUse =
    window.innerWidth < 1024 ? SMALL_CARD_PADDING : CARD_PADDING;

  // What year is this?
  for (let i = maxItems; i >= minItems; i -= 1) {
    const widthWithoutPadding = width - (i - 1) * GRID_GAP - paddingToUse;
    const itemWidth = Math.floor(widthWithoutPadding / i);

    if (itemWidth > minWidth) {
      finalItemWidth = itemWidth;
      break;
    }
  }

  return finalItemWidth;
};

const NFTListingPage: React.FC<
  NFTListingPageProps & React.ComponentPropsWithoutRef<'div'>
> = ({
  className = '',
  data,
  initialNFTViewType = NFTContentNFTViews.SINGLE,
  overscan = {
    main: 30,
    reverse: 30,
  },
  headerProps: {
    onSearchChange = noop,
    onNFTViewChange = noop,
    onSortChange = noop,
    onViewChange = noop,
    ...restProps
  },
  searchByOptions,
  emptyStateProps = {},
  endReached,
  isLoading,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [formatViewType, setFormatViewType] = useState<NFTContentViews>(
    NFTContentViews.GRID
  );
  const [nftViewType, setNftViewType] =
    useState<NFTContentNFTViews>(initialNFTViewType);

  const [itemContentWidth, setItemContentWidth] =
    useState<number>(MIN_INDIVIDUAL_WIDTH);

  const debouncedResize = useCallback(
    debounce(() => {
      setItemContentWidth(
        nftViewType === NFTContentNFTViews.SINGLE
          ? calculateCardWidth(
              ref,
              MIN_INDIVIDUAL_WIDTH,
              MAX_INDIVIDUAL_ITEMS,
              MIN_INDIVIDUAL_ITEMS
            )
          : calculateCardWidth(
              ref,
              MIN_COLLECTION_WIDTH,
              MAX_COLLECTION_ITEMS,
              MIN_COLLECTION_ITEMS
            )
      );
    }, 500),
    [nftViewType]
  );

  useEventListener('resize', debouncedResize, window);

  useEffect(() => {
    setItemContentWidth(
      nftViewType === NFTContentNFTViews.SINGLE
        ? calculateCardWidth(
            ref,
            MIN_INDIVIDUAL_WIDTH,
            MAX_INDIVIDUAL_ITEMS,
            MIN_INDIVIDUAL_ITEMS
          )
        : calculateCardWidth(
            ref,
            MIN_COLLECTION_WIDTH,
            MAX_COLLECTION_ITEMS,
            MIN_COLLECTION_ITEMS
          )
    );
  }, [nftViewType]);

  const handleSearchChange = useCallback(
    (newSearchTerm: string) => {
      setSearchTerm(newSearchTerm);
      onSearchChange?.(newSearchTerm);
    },
    [onSearchChange]
  );

  const handleViewChange = useCallback(
    (newView: NFTContentViews) => {
      setFormatViewType(newView);
      onViewChange(newView);
    },
    [onViewChange]
  );

  const handleNFTViewChange = useCallback(
    (newView: NFTContentNFTViews) => {
      setNftViewType(newView);
      onNFTViewChange?.(newView);
    },
    [onNFTViewChange]
  );

  const cardClasses = cn({
    [css.card]: true,
    [className]: Boolean(className),
  });

  if (!data.length && !searchTerm) {
    return (
      <Card className={cardClasses} ref={ref}>
        <TableEmptyState {...emptyStateProps} />
      </Card>
    );
  }

  return (
    <Card className={cardClasses} ref={ref}>
      <NFTPageHeader
        {...restProps}
        onNFTViewChange={handleNFTViewChange}
        onSearchChange={handleSearchChange}
        onSortChange={onSortChange}
        onViewChange={handleViewChange}
      />
      <div className={css.bodyContainer}>
        {!data.length && searchTerm ? (
          <TableSearchState searchByOptions={searchByOptions} hasNoResults />
        ) : null}
        {data.length ? (
          <div className={css.contentContainer}>
            {formatViewType === NFTContentViews.GRID && (
              <NFTGrid
                data={data}
                overscan={overscan}
                components={{
                  ScrollSeekPlaceholder:
                    nftViewType === NFTContentNFTViews.COLLECTION
                      ? CollectionEmptyPlaceholder
                      : SingleEmptyPlaceholder,
                }}
                itemClassName={css.itemContainerRoot}
                itemContent={
                  nftViewType === NFTContentNFTViews.COLLECTION
                    ? (index: number, nftData: NFTData) => (
                        <div style={{ width: itemContentWidth }}>
                          <CollectionNFTItemContent
                            data={nftData}
                            index={index}
                          />
                        </div>
                      )
                    : (index: number, nftData: NFTData) => (
                        <div style={{ width: itemContentWidth }}>
                          <SingleNFTItemContent data={nftData} index={index} />
                        </div>
                      )
                }
                endReached={endReached}
                isLoading={isLoading}
              />
            )}
            {formatViewType === NFTContentViews.TABLE && (
              <NFTTable
                overscan={overscan}
                data={data}
                isLoading={isLoading}
                contentViewType={nftViewType}
                endReached={endReached}
              />
            )}
          </div>
        ) : null}
      </div>
    </Card>
  );
};

export default NFTListingPage;
