import classnames from 'classnames';
import React, { FunctionComponentElement as FCE, ReactNode } from 'react';

import css from './index.css';

import Avatar, { CommonProps as CommonAvatarProps } from './Avatar';

type Props = {
  children?: ReactNode;
  className?: string;
  [key: string]: any;
};

export default function AvatarGroup({
  children,
  className = '',
  ...restProps
}: Props): FCE<Props> {
  const classNames = classnames({
    [className]: Boolean(className),
    [css.avatarGroupWrapper]: true,
  });

  return (
    <div className={classNames} {...restProps} data-testid="avatar-group">
      {children}
    </div>
  );
}

type HookReturn = {
  avatars: Array<any>;
  overflows: Array<any>;
  OverflowAvatar: React.FC<CommonAvatarProps>;
  component: React.FC<CommonAvatarProps>;
};

type HookProps = {
  list?: Array<any>;
  max?: number;
  showOverflowAvatar?: boolean;
  overflowProps?: CommonAvatarProps;
};

export function useAvatarGroup({
  list = [],
  max = 0,
  showOverflowAvatar = false,
  overflowProps = {},
}: HookProps): HookReturn {
  let avatars = list;
  let overflows = [];

  if (max && list.length > max) {
    avatars = list.slice(0, max - 1);
    overflows = list.slice(max - 1);
  }
  const overflowsBy = overflows.length;

  function OverflowAvatar(props: CommonAvatarProps): FCE<CommonAvatarProps> {
    return overflowsBy ? (
      <Avatar count={overflowsBy} data-testid="overflow-avatar" {...props} />
    ) : (
      <></>
    );
  }

  function AG({ children, ...restProps }: Props): FCE<CommonAvatarProps> {
    return (
      <AvatarGroup {...restProps}>
        {children}
        {showOverflowAvatar && <OverflowAvatar {...overflowProps} />}
      </AvatarGroup>
    );
  }

  return {
    avatars,
    overflows,
    OverflowAvatar,
    component: AG,
  };
}
