import { Modal } from '@latitude/modal';
import { Text } from '@latitude/text';
import { usePathname } from 'next/navigation';

import { useLocalStorage } from '@anchorage/hooks';

type Props = {
  handleLogout: () => void;
};

const PortoAcknowledgeModal = ({ handleLogout }: Props) => {
  const pathname = usePathname();
  const [portoLoginAcknowledged, setPortoLoginAcknowledged] = useLocalStorage(
    'portoLoginAcknowledged',
    false,
  );

  const isTermsAndConditionsPage = pathname.includes('/legal-and-policies');
  return (
    <Modal
      isOpen={!portoLoginAcknowledged && !isTermsAndConditionsPage}
      title="Welcome to Porto"
      onClose={handleLogout}
      primaryAction={{
        label: 'Get started',
        onClick: () => {
          setPortoLoginAcknowledged(true);
        },
      }}
      leadingAction={{
        label: 'Go back',
        onClick: handleLogout,
      }}
    >
      <div className="*:mt-6">
        <Text className="!mt-0 ">
          Thank you for choosing our platform for your self-custody needs. With
          Porto, you can:
        </Text>
        <ul className="mx-4 list-disc">
          <Text asChild>
            <li>
              Safely export and backup your private keys to ensure secure access
              and control over your digital assets
            </li>
          </Text>
          <Text asChild>
            <li>
              Connect to any decentralized application (dApp) through
              WalletConnect, enabling you to sign and interact with smart
              contracts on the Ethereum network
            </li>
          </Text>
          <Text>
            <li>
              Interact with all ERC-20 tokens: deposit ERC20 assets and perform
              smart contract interactions with Ethereum assets
            </li>
          </Text>
        </ul>
        <div>
          <Text variant="bodyBold">Ensuring the security of your wallet</Text>
        </div>
        <Text>
          Every action with your wallet, such as interacting with smart
          contracts, involves some level of risk. To ensure maximum security,
          store your private keys safely offline and avoid sharing them with
          others.
        </Text>
        <Text>
          To learn more, please review our disclosures and{' '}
          <a className="underline" href={'/legal-and-policies'}>
            terms and conditions
          </a>
          .
        </Text>
      </div>
    </Modal>
  );
};

export default PortoAcknowledgeModal;
