import { getExtraParamsTagValue } from 'components/shared/OperationDrawer/utils/destinationTag';

import { transformAddresses } from '../../../../utils/mapAddressToWallets';

import type {
  OperationDrawer,
  OperationDrawerReceivingWallet,
} from 'generated/graphql';

import AddressInfo from '../../../widgets/AddressInfo';
import AmountAndFees from '../../../widgets/AmountAndFees';
import { AssetInfo } from '../../../widgets/AssetInfo';
import DestinationWithAML from '../../../widgets/DestinationWithAML';
import TextSection from '../../../widgets/TextSection';
import Transaction from '../../../widgets/Transaction';
import { getBankAccountInfo } from './helpers';

type WithdrawOperationType = Extract<
  OperationDrawer.operation,
  { __typename: 'WithdrawOperation' }
>;

type ReceivingAddressInfo = WithdrawOperationType['receivingAddressInfo'];
type ManagedAddress =
  WithdrawOperationType['receivingBlockchainAddress2']['blockchainAddress']['managedAddress'];

type Props = {
  operation: WithdrawOperationType;
};

export const getDestinationNames = (
  managedAddress: ManagedAddress | null,
  receivingAddressInfo: ReceivingAddressInfo | null,
  receivingWallet: OperationDrawerReceivingWallet.fragment | null,
) => {
  if (receivingAddressInfo?.name) {
    return [receivingAddressInfo.name];
  }

  if (managedAddress) {
    return managedAddress.wallets.map((wallet) => wallet.name);
  }

  if (receivingWallet) {
    return [receivingWallet.name];
  }

  return [];
};

const WithdrawOperation = ({ operation }: Props) => {
  const {
    maybeViewTransactionLink,
    amlRecord,
    amountAtEndTime,
    useGasStation,
    asset: {
      assetTypeInfo: {
        networkInfo,
        requiresMemo,
        assetTypeID,
        isFiat,
        abbreviation,
        name: assetName,
      },
      vault,
      wallet,
    },
    extraParams,
    finalFeeAtEndTime,
    maxFeeAtEndTime,
    receivingAddressInfo,
    maybeTransactionID,
    receivingBlockchainAddress2: {
      blockchainAddress: { blockchainExplorerURL, managedAddress, address },
      trustedCounterparty,
    },
    receivingStandingInstruction,
    sendingBlockchainAddresses,
    receivingWallet,
  } = operation;

  const destinationNames = getDestinationNames(
    managedAddress,
    receivingAddressInfo,
    receivingWallet,
  );
  const networkName = networkInfo?.name;
  const destinationProps = {
    address,
    bankAccountInfo: getBankAccountInfo(receivingStandingInstruction),
    amlSubmission: amlRecord?.submission,
    blockchainExplorerURL,
    isAnchorageVault: !!managedAddress || !!receivingWallet,
    isTrusted:
      receivingAddressInfo?.isTrustedDestination ||
      !!receivingStandingInstruction?.standingInstruction.standingInstructionID,
    destinationNames,
    requiresMemo,
    tagValue: getExtraParamsTagValue(extraParams),
    assetTypeID,
    trustedCounterpartyName: trustedCounterparty?.counterparty.name,
  };

  return (
    <>
      <AmountAndFees
        amount={amountAtEndTime}
        fee={finalFeeAtEndTime ?? maxFeeAtEndTime}
        tokenInfo={amountAtEndTime.tokenInfo}
        usesGasStation={useGasStation}
        hasPriceTimeTooltip
      />
      {!isFiat && networkName && (
        <TextSection content={networkName} title="Network" />
      )}
      {isFiat && (
        <AssetInfo abbreviation={abbreviation} assetName={assetName} />
      )}
      <AddressInfo
        addressSectionTitle="From"
        walletName={wallet.name}
        addresses={transformAddresses(sendingBlockchainAddresses)}
        vaultName={vault.name}
        accountName={vault.account?.displayName}
      />
      <DestinationWithAML {...destinationProps} />
      <Transaction
        transactionLink={maybeViewTransactionLink}
        transactionID={maybeTransactionID}
      />
    </>
  );
};

export default WithdrawOperation;
