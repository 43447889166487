import { cva } from 'class-variance-authority';

export const iconVariants = cva(
  ['w-sizing-sys-size-24', 'h-sizing-sys-size-24'],
  {
    variants: {
      variant: {
        info: '[&>svg]:fill-color-ref-ui-icon-strong',
        success: '[&>svg]:fill-color-ref-ui-status-success',
        warning: '[&>svg]:fill-color-ref-ui-status-warning',
        error: '[&>svg]:fill-color-ref-ui-status-danger',
      },
    },
  },
);
