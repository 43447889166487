import React, { useEffect, useMemo, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import { Text } from '@anchorage/common/dist/components';
import SimpleBanner from '@anchorage/common/dist/components/Banner/SimpleBanner';

import css from './styles.module.scss';

const DisclosurePage: React.FC<{
  disclosureTitle: string;
  disclosureContent: string;
  handleMarkDisclosureAsRead?: () => void;
}> = ({ disclosureTitle, disclosureContent, handleMarkDisclosureAsRead }) => {
  const cleanDisclsoureContent = useMemo(
    () => disclosureContent.replace(/<br>/g, ''),
    [disclosureContent],
  );

  const modalRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    if (modalRef.current) {
      // ScrollTop: Measures how far the page has been scrolled from the top
      // ClientHeight: Measures the height of the visible content of the page
      // ScrollHeight: Measures the height of the entire content of the page (even parts that aren't visible)
      const { scrollTop, clientHeight, scrollHeight } = modalRef.current;
      // Note: 10 is to account for slight differences in how browsers calculate scroll positions for different screen sizes
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;

      if (isAtBottom && handleMarkDisclosureAsRead) {
        handleMarkDisclosureAsRead();
      }
    }
  };

  useEffect(() => {
    // Stores the actual element in the DOM representing the disclosure page in the form wizard
    const modalElement = modalRef.current;
    if (modalElement && handleMarkDisclosureAsRead) {
      modalElement.addEventListener('scroll', handleScroll);

      return () => {
        modalElement.removeEventListener('scroll', handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleScroll, handleMarkDisclosureAsRead]);

  return (
    <div
      className={css.disclosureRoot}
      ref={modalRef}
      data-testid="disclosure-page"
    >
      <SimpleBanner
        type="info"
        title="Please read and scroll to the bottom to accept the terms."
        className={css.banner}
      />

      <Text title="disclosure-title" type="heading" className={css.title}>
        <ReactMarkdown className="markdown">{disclosureTitle}</ReactMarkdown>
      </Text>

      <Text
        title="disclosure-content"
        size="small"
        className={css.markdownContent}
      >
        <ReactMarkdown className="markdown">
          {cleanDisclsoureContent}
        </ReactMarkdown>
      </Text>
    </div>
  );
};

export default DisclosurePage;
