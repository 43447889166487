import cn from 'classnames';

const CollectionsBookmarkIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CollectionsBookmark24px', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M8 12H4V40C4 42.2 5.8 44 8 44H36V40H8V12Z" />
      <path d="M40 4H16C13.8 4 12 5.8 12 8V32C12 34.2 13.8 36 16 36H40C42.2 36 44 34.2 44 32V8C44 5.8 42.2 4 40 4ZM40 24L35 21L30 24V8H40V24Z" />
    </svg>
  );
};

export { CollectionsBookmarkIcon };
