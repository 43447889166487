import React from 'react';

// Components
import { TableCell, TableRow } from '../../../../components/TableV2';

// Styles
import css from '../index.css';

const NFTCollectionHeaders: React.FC = () => (
  <TableRow data-testid="nft-collection-headers">
    <TableCell className={css.nftCell} type="head">
      Collection
    </TableCell>
    <TableCell className={css.dateCell} type="head">
      Last Updated
    </TableCell>
    <TableCell className={css.quantityCell} type="head">
      Quantity
    </TableCell>
    <TableCell className={css.valueCell} type="head">
      Approx. Balance
    </TableCell>
  </TableRow>
);

export default NFTCollectionHeaders;
