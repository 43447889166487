import * as React from 'react';

import type { OperationDrawer } from 'generated/graphql';

import { OPERATION_COMPONENTS, OPERATION_TABS } from '../../constants';
import OperationTabsAndContent from '../OperationTabsAndContent';
import OperationTab from '../operationPages/OperationTab';

type Props = React.ComponentPropsWithoutRef<'div'> & {
  operation: OperationDrawer.operation;
};

const OperationPage = ({ operation }: Props) => {
  const { __typename: operationType } = operation;

  const OperationDetails = OPERATION_COMPONENTS[
    operationType
  ] as React.ElementType;

  const tabContents = {
    [OPERATION_TABS.DETAILS]: <OperationDetails operation={operation} />,
    [OPERATION_TABS.OPERATION]: <OperationTab operation={operation} />,
  };

  return <OperationTabsAndContent tabContents={tabContents} />;
};

const OperationWrapper = ({ operation, ...restProps }: Props) => {
  return (
    <div data-testid="org-operation-wrapper" {...restProps}>
      <OperationPage operation={operation} />
    </div>
  );
};

export default OperationWrapper;
