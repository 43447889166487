import cn from 'classnames';

const DonutSmallIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DonutSmall', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M22 18.32V4C12 5 4 13.58 4 24C4 34.42 12 43 22 44V29.68C20 28.86 18 26.64 18 24C18 21.36 20 19.14 22 18.32ZM29.72 22H44C43.04 12.5 36 4.94 26 4V18.32C28 18.92 29.04 20.28 29.72 22ZM26 29.68V44C36 43.06 43.04 35.5 44 26H29.72C29.04 27.72 28 29.08 26 29.68Z" />
    </svg>
  );
};

export { DonutSmallIcon };
