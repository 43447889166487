import { useSearchParams } from 'next/navigation';
import * as React from 'react';

import { WrappedError } from '@anchorage/common/dist/utils/errors';

import useStaticAssetStandardizedStakingSupport from 'hooks/useStaticAssetStandardizedStakingSupport';

import castStringToEnum from 'utils/castStringToEnum';

import {
  WalletWithStakingInfo,
  useWalletWithStakingInfoQuery,
} from 'generated/graphql';

import {
  StandardizedStakingMode,
  StandardizedStakingQueryParams,
} from '../constants';
import StandardizedStakingProvider from './StandardizedStakingProvider';

const StandardizedStakingProviderWrapper = () => {
  const searchParams = useSearchParams();
  const walletID = searchParams.get(StandardizedStakingQueryParams.WALLET_ID);
  const assetTypeID = searchParams.get(
    StandardizedStakingQueryParams.ASSETTYPE_ID,
  );
  const validatorAddress =
    searchParams.get(StandardizedStakingQueryParams.VALIDATOR_ADDRESS) ?? '';

  const stakeModeString = searchParams.get(
    StandardizedStakingQueryParams.STAKE_MODE,
  );
  const standardizedStakingMode = castStringToEnum(
    StandardizedStakingMode,
    stakeModeString,
  );

  // Get standardized staking support status from static asset data
  const { staticAssetStakingSupport, assetTypeInfo } =
    useStaticAssetStandardizedStakingSupport({
      assetTypeID: assetTypeID ?? '',
    });

  const assetSupportsStandardizedStaking =
    staticAssetStakingSupport?.supportsStaking;

  const { data } = useWalletWithStakingInfoQuery({
    variables: { walletId: walletID ?? '' },
    onError: (error) => {
      reportError(
        new WrappedError('Error fetching wallet with staking info', error),
      );
    },
    skip: !walletID,
  });

  const wallet: WalletWithStakingInfo.node | null | undefined =
    data?.wallets.edges[0]?.node;
  const walletAsset = wallet?.assets[0];
  const walletWithStakingInfo = walletAsset?.staking;

  if (
    !walletID ||
    !wallet ||
    !walletWithStakingInfo ||
    !assetSupportsStandardizedStaking ||
    !assetTypeInfo
  ) {
    return null;
  }

  return (
    <StandardizedStakingProvider
      walletWithStakingInfo={wallet}
      assetTypeInfo={assetTypeInfo}
      standardizedStakingMode={standardizedStakingMode}
      validatorAddress={validatorAddress}
    />
  );
};

export default StandardizedStakingProviderWrapper;
