import cn from 'classnames';

const LocalShippingIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalShipping', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 16H34V8H6C3.79 8 2 9.79 2 12V34H6C6 37.31 8.69 40 12 40C15.31 40 18 37.31 18 34H30C30 37.31 32.69 40 36 40C39.31 40 42 37.31 42 34H46V24L40 16ZM12 37C10.34 37 9 35.66 9 34C9 32.34 10.34 31 12 31C13.66 31 15 32.34 15 34C15 35.66 13.66 37 12 37ZM39 19L42.93 24H34V19H39ZM36 37C34.34 37 33 35.66 33 34C33 32.34 34.34 31 36 31C37.66 31 39 32.34 39 34C39 35.66 37.66 37 36 37Z" />
    </svg>
  );
};

export { LocalShippingIcon };
