import cn from 'classnames';

const GavelIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Gavel', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M2 42.0001H26V46.0001H2V42.0001ZM10.49 16.1421L16.148 10.4851L44.432 38.7701L38.775 44.4261L10.49 16.1421ZM24.627 2.0061L35.94 13.3201L30.284 18.9761L18.97 7.6631L24.627 2.0061ZM7.657 18.9761L18.97 30.2901L13.314 35.9471L2 24.6331L7.657 18.9761Z" />
    </svg>
  );
};

export { GavelIcon };
