import cn from 'classnames';

const CardTravelIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CardTravel', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 12H34V8C34 5.79 32.21 4 30 4H18C15.79 4 14 5.79 14 8V12H8C5.79 12 4 13.79 4 16V38C4 40.21 5.79 42 8 42H40C42.21 42 44 40.21 44 38V16C44 13.79 42.21 12 40 12ZM18 8H30V12H18V8ZM40 38H8V34H40V38ZM40 28H8V16H14V20H18V16H30V20H34V16H40V28Z" />
    </svg>
  );
};

export { CardTravelIcon };
