import * as LabelPrimitive from '@radix-ui/react-label';
import cn from 'classnames';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { checkboxLabelClasses } from 'variants/checkbox-variants';

const CheckboxLabel = forwardRef<
  ElementRef<typeof LabelPrimitive.Root>,
  ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <LabelPrimitive.Root
      {...props}
      ref={ref}
      className={cn(checkboxLabelClasses, className)}
    />
  );
});

CheckboxLabel.displayName = 'CheckboxLabel';

export default CheckboxLabel;
