import cn from 'classnames';

const FlashOffIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FlashOff', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6.55 6L4 8.55L14 18.55V26H20V44L27.17 31.71L35.45 40L38 37.46L6.55 6ZM34 20H26L34 4H14V8.36L30.92 25.28L34 20Z" />
    </svg>
  );
};

export { FlashOffIcon };
