import { MenuItemProps, TSubMenuHeaderProps } from '../types';

import {
  MENU_ITEMS,
  MenuItemName,
  SUB_MENU_ITEMS,
  SubMenuItemName,
} from '../constants';

type MenuItem = MenuItemProps | TSubMenuHeaderProps;

const getOldTopMenuItems = ({
  isReportsTabEnabled,
  hasConnectedAppsViewPermissionsOrIsAdmin,
}: {
  isReportsTabEnabled?: boolean;
  hasConnectedAppsViewPermissionsOrIsAdmin?: boolean;
}) => {
  return [
    MENU_ITEMS[MenuItemName.DASHBOARD],
    MENU_ITEMS[MenuItemName.ACTIVITY],
    ...(isReportsTabEnabled ? [MENU_ITEMS[MenuItemName.REPORTS]] : []),
    ...(hasConnectedAppsViewPermissionsOrIsAdmin
      ? [MENU_ITEMS[MenuItemName.CONNECTED_APPS]]
      : []),
  ];
};

export const getTopMenuItems = ({
  isReportsTabEnabled,
  hasConnectedAppsViewPermissionsOrIsAdmin,
  isUserAdmin,
  isWebMenuActive,
}: {
  isReportsTabEnabled?: boolean;
  hasConnectedAppsViewPermissionsOrIsAdmin?: boolean;
  isUserAdmin?: boolean;
  isWebMenuActive?: boolean;
}): MenuItem[] => {
  if (!isWebMenuActive) {
    return getOldTopMenuItems({
      isReportsTabEnabled,
      hasConnectedAppsViewPermissionsOrIsAdmin,
    });
  }

  return [
    MENU_ITEMS[MenuItemName.DASHBOARD],
    MENU_ITEMS[MenuItemName.ACTIVITY],
    ...(isReportsTabEnabled ? [MENU_ITEMS[MenuItemName.REPORTS]] : []),
    {
      ...MENU_ITEMS[MenuItemName.SETTINGS],
      subMenuItems: [
        SUB_MENU_ITEMS[SubMenuItemName.TRUSTED_DESTINATIONS],
        ...(isUserAdmin ? [SUB_MENU_ITEMS[SubMenuItemName.WALLET_KEYS]] : []),
      ],
    },
    ...(hasConnectedAppsViewPermissionsOrIsAdmin
      ? [MENU_ITEMS[MenuItemName.CONNECTED_APPS]]
      : []),
  ];
};

const getOldBottomMenuItems = ({
  isUserAdmin,
  isWebTopBarActive,
}: {
  isUserAdmin?: boolean;
  isWebTopBarActive?: boolean;
}): MenuItem[] => {
  return [
    {
      ...MENU_ITEMS[MenuItemName.SETTINGS],
      subMenuItems: [
        SUB_MENU_ITEMS[SubMenuItemName.TRUSTED_DESTINATIONS],
        ...(isUserAdmin ? [SUB_MENU_ITEMS[SubMenuItemName.WALLET_KEYS]] : []),
      ],
    },
    ...(!isWebTopBarActive ? [MENU_ITEMS[MenuItemName.LEGAL_DISCLOSURES]] : []),
  ];
};

export const getBottomMenuItems = ({
  isUserAdmin,
  isWebTopBarActive,
  isWebMenuActive,
}: {
  isUserAdmin?: boolean;
  isWebTopBarActive?: boolean;
  isWebMenuActive?: boolean;
}): MenuItem[] => {
  if (!isWebMenuActive) {
    return getOldBottomMenuItems({
      isUserAdmin,
      isWebTopBarActive,
    });
  }

  return [];
};
