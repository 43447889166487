import cn from 'classnames';

const FormatTextdirectionRToLIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatTextdirectionRToL', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M20 20V30H24V8H28V30H32V8H36V4H20C15.58 4 12 7.58 12 12C12 16.42 15.58 20 20 20ZM16 34V28L8 36L16 44V38H40V34H16Z" />
    </svg>
  );
};

export { FormatTextdirectionRToLIcon };
