import cn from 'classnames';

const BrightnessLowIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BrightnessLow', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40.0001 30.63L46.6301 24L40.0001 17.37V8H30.6301L24.0001 1.37L17.3701 8H8.00012V17.37L1.37012 24L8.00012 30.63V40H17.3701L24.0001 46.63L30.6301 40H40.0001V30.63ZM24.0001 36C17.3701 36 12.0001 30.63 12.0001 24C12.0001 17.37 17.3701 12 24.0001 12C30.6301 12 36.0001 17.37 36.0001 24C36.0001 30.63 30.6301 36 24.0001 36Z" />
    </svg>
  );
};

export { BrightnessLowIcon };
