import cn from 'classnames';

const FormatClearIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatClear', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6.54 10L4 12.55L17.94 26.49L13 38H19L22.14 30.68L33.46 42L36 39.45L7.09 10.55L6.54 10ZM12 10V10.36L17.64 16H22.43L20.99 19.35L25.19 23.55L28.43 16H40V10H12Z" />
    </svg>
  );
};

export { FormatClearIcon };
