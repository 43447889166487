import type { PendingAttributionByOperation } from '../PendingAttributionsByOperation/_types';
import type { PendingAttribution } from '../_types';

export type TransformByOperationResult = PendingAttributionByOperation;

const transformByOperation = (
  pendingAttributions: Array<Record<string, PendingAttribution[]>>
): Array<TransformByOperationResult> =>
  pendingAttributions.reduce(
    (
      acc: Array<TransformByOperationResult>,
      curr: Record<string, PendingAttribution[]>
    ) => {
      const [operationId, pendingAttr] = Object.entries(curr)[0];

      const { amount, createdAt, vaultName, accountName } = pendingAttr[0];

      const addresses: Array<string> = pendingAttr.map(
        ({ sourceAddress }) => sourceAddress.address
      );

      return [
        ...acc,
        {
          operationId,
          addresses,
          amount,
          createdAt,
          vaultName,
          accountName,
        },
      ];
    },
    []
  );

export default transformByOperation;
