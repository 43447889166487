import { Text } from '@anchorage/common/dist/components';

import css from './styles.module.scss';

const ExternalBadge = () => {
  return (
    <Text className={css.badge} size="tiny" type="label">
      EXTERNAL
    </Text>
  );
};

export default ExternalBadge;
