import { IconSquare, IconSquareCheck, IconSquareMinus } from '@latitude/icons';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import CheckboxItemLabel from 'CheckboxLabel';
import cn from 'classnames';
import { forwardRef, useEffect, useId, useState } from 'react';
import { checkboxItemClasses } from 'variants/checkbox-variants';

import { CheckboxProps, CheckedState } from 'types';

const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  (
    {
      className,
      indeterminate,
      onCheckedChange,
      checked = false,
      label,
      children,
      wrapperClassname = '',
      labelClassname = '',
      id,
      disabled,
      rawProps = {},
      invalid = false,
      ...props
    },
    ref,
  ) => {
    const defaultId = useId();
    const idToUse = id ?? defaultId;
    const [isChecked, setIsChecked] = useState<CheckedState>(
      indeterminate ? 'indeterminate' : checked,
    );

    useEffect(() => {
      const nextState = indeterminate ? 'indeterminate' : checked;
      setIsChecked(nextState);
    }, [checked, indeterminate, onCheckedChange]);

    const handleCheckedChange = (nextChecked: CheckedState) => {
      setIsChecked(nextChecked);
      onCheckedChange && onCheckedChange(nextChecked);
    };

    return (
      <div className={cn(['flex', 'items-center', wrapperClassname])}>
        <CheckboxPrimitive.Root
          ref={ref}
          className={cn(checkboxItemClasses, className)}
          onCheckedChange={handleCheckedChange}
          checked={isChecked}
          id={idToUse}
          disabled={disabled}
          aria-invalid={invalid}
          {...props}
          {...rawProps}
        >
          <CheckboxPrimitive.Indicator forceMount>
            {(isChecked === 'indeterminate' || indeterminate) && (
              <IconSquareMinus />
            )}
            {isChecked === false && <IconSquare />}
            {isChecked === true && <IconSquareCheck />}
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {label ? (
          <CheckboxItemLabel htmlFor={idToUse} className={labelClassname}>
            {label}
          </CheckboxItemLabel>
        ) : null}
        {children}
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
