import { FC, PropsWithChildren } from 'react';

import type { NormalizedSignedPreviewLayout } from '@anchorage/vsp';

import css from './styles.module.scss';

import { getFieldComponent } from '..';

export const DETAILS_DRAWER_STYLES = {
  bodyStyle: {
    height: 'calc(100% - 48px)',
    padding: 36,
  },
  headerStyle: {
    background: 'none',
    borderBottom: 'none',
    paddingLeft: 36,
  },
  width: 600,
};

const RowWrapper: FC<PropsWithChildren> = ({ children }) => {
  return <div className={css.drawerRow}>{children}</div>;
};

function DetailsExpanded({
  expandedDetails,
}: {
  expandedDetails: NormalizedSignedPreviewLayout['expanded'];
}) {
  return (
    <div className={css.drawerWrapper}>
      {expandedDetails.fields.map((field, index) => {
        return <RowWrapper key={index}>{getFieldComponent(field)}</RowWrapper>;
      })}
    </div>
  );
}

export default DetailsExpanded;
