import cn from 'classnames';

const CasinoIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Casino', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 6H10C7.79 6 6 7.79 6 10V38C6 40.21 7.79 42 10 42H38C40.21 42 42 40.21 42 38V10C42 7.79 40.21 6 38 6ZM15 36C13.34 36 12 34.66 12 33C12 31.34 13.34 30 15 30C16.66 30 18 31.34 18 33C18 34.66 16.66 36 15 36ZM15 18C13.34 18 12 16.66 12 15C12 13.34 13.34 12 15 12C16.66 12 18 13.34 18 15C18 16.66 16.66 18 15 18ZM24 27C22.34 27 21 25.66 21 24C21 22.34 22.34 21 24 21C25.66 21 27 22.34 27 24C27 25.66 25.66 27 24 27ZM33 36C31.34 36 30 34.66 30 33C30 31.34 31.34 30 33 30C34.66 30 36 31.34 36 33C36 34.66 34.66 36 33 36ZM33 18C31.34 18 30 16.66 30 15C30 13.34 31.34 12 33 12C34.66 12 36 13.34 36 15C36 16.66 34.66 18 33 18Z" />
    </svg>
  );
};

export { CasinoIcon };
