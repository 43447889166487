import cn from 'classnames';

const EventBusyIcon = (props: SVGProps): JSX.Element => {
  const { title = 'EventBusy', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18.62 34L23.5 29.12L28.38 34L30.5 31.88L25.62 27L30.5 22.12L28.38 20L23.5 24.88L18.62 20L16.5 22.12L21.38 27L16.5 31.88L18.62 34ZM38 6H36V2H32V6H16V2H12V6H10C7.79 6 6.02 7.79 6.02 10L6 38C6 40.21 7.79 42 10 42H38C40.21 42 42 40.21 42 38V10C42 7.79 40.21 6 38 6ZM38 38H10V16H38V38Z" />
    </svg>
  );
};

export { EventBusyIcon };
