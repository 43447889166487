import cn from 'classnames';

const LocalSeeIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalSee', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 30.4C27.5346 30.4 30.4 27.5346 30.4 24C30.4 20.4654 27.5346 17.6 24 17.6C20.4654 17.6 17.6 20.4654 17.6 24C17.6 27.5346 20.4654 30.4 24 30.4Z" />
      <path d="M18 4L14.34 8H8C5.79 8 4 9.79 4 12V36C4 38.21 5.79 40 8 40H40C42.21 40 44 38.21 44 36V12C44 9.79 42.21 8 40 8H33.66L30 4H18ZM24 34C18.48 34 14 29.52 14 24C14 18.48 18.48 14 24 14C29.52 14 34 18.48 34 24C34 29.52 29.52 34 24 34Z" />
    </svg>
  );
};

export { LocalSeeIcon };
