import cn from 'classnames';

const FavoriteIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Favorite', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 42.7L21.1 40.07C10.8 30.72 4 24.55 4 17C4 10.83 8.83 6 15 6C18.48 6 21.82 7.62 24 10.17C26.18 7.62 29.52 6 33 6C39.17 6 44 10.83 44 17C44 24.55 37.2 30.72 26.9 40.07L24 42.7Z" />
    </svg>
  );
};

export { FavoriteIcon };
