import cn from 'classnames';

const BorderHorizontalIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BorderHorizontal', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6 42H10V38H6V42ZM10 14H6V18H10V14ZM6 34H10V30H6V34ZM14 42H18V38H14V42ZM10 6H6V10H10V6ZM18 6H14V10H18V6ZM34 6H30V10H34V6ZM26 14H22V18H26V14ZM26 6H22V10H26V6ZM38 34H42V30H38V34ZM22 42H26V38H22V42ZM6 26H42V22H6V26ZM38 6V10H42V6H38ZM38 18H42V14H38V18ZM22 34H26V30H22V34ZM30 42H34V38H30V42ZM38 42H42V38H38V42Z" />
    </svg>
  );
};

export { BorderHorizontalIcon };
