import cn from 'classnames';

const FormatColorFillIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatColorFill', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_6_9354)">
        <path d="M33.1199 17.88L15.2399 0L12.4099 2.83L17.1699 7.59L6.87994 17.88C5.70994 19.05 5.70994 20.95 6.87994 22.12L17.8799 33.12C18.4599 33.71 19.2299 34 19.9999 34C20.7699 34 21.5399 33.71 22.1199 33.12L33.1199 22.12C34.2899 20.95 34.2899 19.05 33.1199 17.88ZM10.4099 20L19.9999 10.42L29.5899 20H10.4099ZM37.9999 23C37.9999 23 33.9999 27.33 33.9999 30C33.9999 32.21 35.7899 34 37.9999 34C40.2099 34 41.9999 32.21 41.9999 30C41.9999 27.33 37.9999 23 37.9999 23Z" />
        <path opacity="0.3" d="M0 40H48V48H0V40Z" />
      </g>
      <defs>
        <clipPath id="clip0_6_9354">
          <rect width="48" height="48" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { FormatColorFillIcon };
