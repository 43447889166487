import cn from 'classnames';
import * as React from 'react';

import { Text } from '@anchorage/common/dist/components';

import css from './styles.module.scss';

import DecimalText from '../../../../DecimalText';
import PriceTimeTooltip from '../PriceTimeTooltip';

type Props = {
  assetQuantity?: string;
  assetValue?: string;
  abbreviation?: string;
  totalValue?: string;
  hasPriceTimeTooltip?: boolean;
  indicateDifference?: boolean;
};

const AssetQuantityDetails = ({
  assetQuantity = '0',
  assetValue = '$0.00',
  abbreviation = '-',
  totalValue = '$0.00',
  hasPriceTimeTooltip = false,
  indicateDifference = false,
}: Props) => {
  const differenceSign = React.useMemo(() => {
    if (indicateDifference && Number(assetQuantity) > 0) {
      return '+';
    }

    return '';
  }, [assetQuantity, indicateDifference]);

  const assetQuantityClasses = cn({
    [css.assetQuantity!]: true,
    [css.negativeValue!]: indicateDifference && Number(assetQuantity) < 0,
    [css.positiveValue!]: indicateDifference && Number(assetQuantity) > 0,
  });

  return (
    <div className={css.valueInfo}>
      <div className={css.text}>
        <Text className={assetQuantityClasses} type="mono" size="small">
          <DecimalText size="small">
            {`${differenceSign}${assetQuantity}`}
          </DecimalText>{' '}
          {abbreviation}
        </Text>
        <div className={css.assetDetail}>
          <div className={css.assetQuantity}>
            <DecimalText size="small">{totalValue}</DecimalText>@{' '}
            <DecimalText size="small">{assetValue}</DecimalText>
          </div>
          <Text type="mono" size="small">
            /{abbreviation}
            {hasPriceTimeTooltip && <PriceTimeTooltip />}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default AssetQuantityDetails;
