import { LocalGasStationIcon } from '@latitude/icons';
import Text from '@latitude/text';
import cn from 'classnames';
import { useMemo } from 'react';

import { AssetIcon } from '@anchorage/common/dist/components';
import { InfoOutlineIcon } from '@anchorage/common/dist/components/Icons';
import { useDeepCompare } from '@anchorage/hooks';

import DisclosureModal from 'components/shared/DisclosureModal';

import type {
  AmountFieldsFragment,
  TokenInfoFieldsFragment,
} from 'generated/graphql';

import css from './styles.module.scss';

import AssetQuantityDetails from '../../shared/AssetQuantityDetails';
import SectionRow from '../../shared/SectionRow';
import WidgetWrapper from '../../shared/WidgetWrapper';
import Amount from './Amount';

type Props = {
  amount?: AmountFieldsFragment | null;
  amountTitle?: string;
  fee?: AmountFieldsFragment | null;
  tokenInfo?: TokenInfoFieldsFragment | null;
  disclosureTitle?: string;
  showPriceDisclosure?: boolean;
  hasPriceTimeTooltip?: boolean;
  widgetWrapperClassName?: string;
  usesGasStation?: boolean;
};

const AmountAndFees = ({
  amount,
  amountTitle = 'Amount',
  fee,
  tokenInfo,
  disclosureTitle = 'Price disclosure',
  showPriceDisclosure = true,
  hasPriceTimeTooltip,
  widgetWrapperClassName,
  usesGasStation = false,
}: Props) => {
  const isFeeDefined = fee !== undefined;

  const wrapperClasses = cn({
    [css.widgetWrapper!]: true,
    [widgetWrapperClassName!]: widgetWrapperClassName,
  });

  const FeeDetails = useMemo(
    () => {
      if (fee?.assetTypeInfo) {
        return (
          <>
            <AssetIcon
              data-testid="fee-asset-icon"
              className={css.assetIcon}
              {...fee.assetTypeInfo}
            />
            <AssetQuantityDetails
              assetValue={fee.assetTypeInfo.displayValue}
              assetQuantity={fee.displayValue}
              abbreviation={fee.assetTypeInfo.abbreviation}
              totalValue={fee.displayValueInUSD}
              hasPriceTimeTooltip={hasPriceTimeTooltip}
            />
          </>
        );
      }
      return '-';
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([fee, usesGasStation, hasPriceTimeTooltip]),
  );

  return (
    <WidgetWrapper className={wrapperClasses}>
      {amount && (
        <SectionRow className={css.amount} title={amountTitle}>
          <Amount
            tokenInfo={tokenInfo}
            amount={amount}
            hasPriceTimeTooltip={hasPriceTimeTooltip}
          />
          {!isFeeDefined && showPriceDisclosure && (
            <DisclosureModal
              className={css.priceDisclosure}
              disclosureID="price"
            >
              <InfoOutlineIcon className={css.infoIcon} /> Price disclosure
            </DisclosureModal>
          )}
        </SectionRow>
      )}
      {isFeeDefined ? (
        <SectionRow className={css.fee} title="Fee">
          {usesGasStation ? (
            <Text className="mb-4 flex gap-2">
              <LocalGasStationIcon className="size-5" />
              Covered by Anchorage Digital
            </Text>
          ) : null}
          <div className={css.assetDetails}>{FeeDetails}</div>
          {showPriceDisclosure && (
            <DisclosureModal
              disclosureID="price"
              className={css.priceDisclosure}
            >
              <InfoOutlineIcon className={css.infoIcon} /> {disclosureTitle}
            </DisclosureModal>
          )}
        </SectionRow>
      ) : null}
    </WidgetWrapper>
  );
};

export default AmountAndFees;
