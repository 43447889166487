import _groupBy from 'lodash/groupBy';
import _pluralize from 'pluralize';

import { Accordion, Card, Text } from '@anchorage/common/dist/components';
import {
  AccordionHeader,
  AccordionPanel,
} from '@anchorage/common/dist/components/Accordion';

import css from './styles.module.scss';

import type { RoleChange } from '../../types';

import UpdatedIAMRoleResourceAssignmentsTitle from './UpdatedIAMRoleResourceAssignmentsTitle';

const UpdatedIAMRoleResourceAssigmentsAccordion = ({
  roleChange,
}: {
  roleChange: RoleChange;
}) => {
  // organize role changes by resource type
  const resourceByType = _groupBy(roleChange.resources, '__typename');
  return roleChange.resources.length >= 1 ? (
    <Accordion>
      <AccordionHeader className={css.accordionHeader}>
        {({ isExpanded }: { isExpanded: boolean }) => (
          <UpdatedIAMRoleResourceAssignmentsTitle
            roleChange={roleChange}
            expanded={isExpanded}
          />
        )}
      </AccordionHeader>
      <AccordionPanel>
        <div className={css.accordionContentWrapper} />
        <Card>
          <div className={css.accordionContentCardWrapper}>
            {Object.entries(resourceByType).map(([type, resources]) => (
              <div key={type}>
                <Text size="small" className={css.resourceName}>
                  {_pluralize(type ?? '', 2)}
                </Text>
                {resources.map((resource, idx) => (
                  <Text size="small" key={`${resource.name}${idx}`}>
                    {resource.name}
                  </Text>
                ))}
              </div>
            ))}
          </div>
        </Card>
      </AccordionPanel>
    </Accordion>
  ) : null;
};

export default UpdatedIAMRoleResourceAssigmentsAccordion;
