import cn from 'classnames';

const CallMergeIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CallMerge', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M34 40.83L36.83 38L30 31.17L27.17 34L34 40.83ZM15 16H22V27.17L11.17 38L14 40.83L26 28.83V16H33L24 7L15 16Z" />
    </svg>
  );
};

export { CallMergeIcon };
