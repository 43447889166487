import { Button } from '@latitude/button';
import React, { useState } from 'react';

import { Text } from '@anchorage/common/dist/components';
import {
  CompleteArrowForwardIcon,
  HyperlinkIcon,
} from '@anchorage/common/dist/components/Icons';
import { SETTLEMENTS_LIST_PATH } from '@anchorage/frontoffice/utils/routes';
import {
  FieldType,
  NormalizedSignedPreviewLayout,
  normalizeCanonicalOperationPayload,
} from '@anchorage/vsp';

import { getFieldComponent } from './components';
import DetailsExpanded from './components/DetailsExpanded';

import { OperationField } from 'generated/graphql';

import css from './styles.module.scss';

import TextSection from '../../widgets/TextSection';
import SectionRow from '../SectionRow';
import WidgetWrapper from '../WidgetWrapper';

interface GenericOperationProps {
  canonicalOperationPayload: string;
  operationFields?: OperationField[];
}

const CanonicalPayloadSection: React.FC<GenericOperationProps> = ({
  canonicalOperationPayload = '',
  operationFields,
}) => {
  const [rowIndex, setRowIndex] = useState<number | null>(null);
  const [isReviewed, setIsReviewed] = useState<number[]>([]);
  const normalizedPayload = normalizeCanonicalOperationPayload({
    canonicalOperationPayload,
    operationFields,
  });

  const fields = normalizedPayload.fields;

  const onClickRow = (index: number) => {
    setRowIndex(index);
    if (!isReviewed.includes(index)) {
      setIsReviewed([...isReviewed, index]);
    }
  };

  return (
    <>
      {rowIndex !== null && (
        <div className={css.expandedTitleWrapper}>
          <Button
            type="ghost"
            variant="strong"
            className={css.backButton}
            leadingElement={<CompleteArrowForwardIcon direction="left" />}
            onClick={() => setRowIndex(null)}
          />
          Back
        </div>
      )}
      {rowIndex === null ? (
        fields.map((field, index) => {
          switch (field.type) {
            case FieldType.PreviewLayout:
              return (
                <div
                  key={index}
                  className={css.container}
                  onClick={() => onClickRow(index)}
                >
                  {getFieldComponent(field, {
                    isReviewed: isReviewed.includes(index),
                  })}
                </div>
              );
            case FieldType.SignedAmount:
              return (
                <WidgetWrapper>
                  <SectionRow title={field.label}>
                    {getFieldComponent(field)}
                  </SectionRow>
                </WidgetWrapper>
              );

            case FieldType.SignedAddress:
              return (
                <WidgetWrapper key={index}>
                  <div className={css.containerAddressV2}>
                    {getFieldComponent(field)}
                  </div>
                </WidgetWrapper>
              );
            case FieldType.UnsignedDivider:
              return <div key={index}>{getFieldComponent(field)}</div>;
            default:
              // eslint-disable-next-line no-case-declarations
              const isSettlementID = field.label === 'Settlement ID';
              // eslint-disable-next-line no-case-declarations
              const fallbackText = (field.fallbackText as string) ?? '';
              // eslint-disable-next-line no-case-declarations
              const content = isSettlementID ? (
                <div className={css.linkContainer}>
                  <Text<'a'>
                    className={css.settlementText}
                    data-testid="settlementLink"
                    tag="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${SETTLEMENTS_LIST_PATH}?settlementID=${fallbackText}`}
                  >
                    {fallbackText}
                  </Text>
                  <HyperlinkIcon className={css.hyperlinkIcon} />
                </div>
              ) : (
                fallbackText
              );
              return (
                <TextSection
                  key={index}
                  title={field.label ?? ''}
                  content={content ?? ''}
                />
              );
          }
        })
      ) : (
        <DetailsExpanded
          expandedDetails={
            (fields?.[rowIndex] as NormalizedSignedPreviewLayout)?.expanded ??
            []
          }
        />
      )}
      {!fields && <Text size="small">Nothing to show here</Text>}
    </>
  );
};

export default CanonicalPayloadSection;
