import React from 'react';

// Components
import { Text } from '../../../components';

// Styles
import css from './index.css';

import type { TableSearchStateProps } from './_types';

// Constants
import { DEFAULT_SEARCH_OPTIONS } from '../_constants';

const TableSearchState = ({
  searchByOptions,
  hasNoResults = false,
  recentSearches = [],
  onRecentSearchClick,
}: TableSearchStateProps) => {
  const options = searchByOptions?.length
    ? searchByOptions
    : DEFAULT_SEARCH_OPTIONS;

  return (
    <div className={css.searchStateRoot} data-testid="table-search-state">
      {hasNoResults ? (
        <Text size="small" className={css.text}>
          No results found. Try again?
        </Text>
      ) : null}
      {recentSearches.length ? (
        <div className={css.recentSearches}>
          <Text size="small" className={css.grayText}>
            Most recent searches
          </Text>
          {recentSearches.map((option, index) => (
            <Text
              key={`${index}-${option}`}
              size="small"
              className={css.linkText}
              onClick={() => onRecentSearchClick?.(option)}
            >
              {option}
            </Text>
          ))}
        </div>
      ) : null}
      <div className={css.searchInfo}>
        <Text size="small" className={css.grayText}>
          You can search by...
        </Text>
        {options.map((option, index) => (
          <div
            key={`${index}-${option.title}`}
            className={css.searchInfoElement}
          >
            <Text size="small" className={css.text}>
              {option.title}
            </Text>
            <Text size="small" className={css.grayText}>
              {option.subtitle}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};

TableSearchState.displayName = 'TableSearchState';

export default TableSearchState;
