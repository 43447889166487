import { StandardizedStakingStep } from 'generated/graphql';

interface Props {
  isEnableAutoCompoundMode: boolean;
  autoCompoundFormStep:
    | StandardizedStakingStep.AutoCompoundStepInlineFragment
    | undefined;
}

const getAutoCompoundPrimaryButtonTitle = ({
  isEnableAutoCompoundMode,
  autoCompoundFormStep,
}: Props) => {
  if (!autoCompoundFormStep) {
    return '';
  }

  // fallback label for the button in case BE doesn't provide it
  const defaultLabel = isEnableAutoCompoundMode
    ? 'Start auto-claim & stake rewards'
    : 'Yes, stop auto-claim & stake rewards';

  const label = autoCompoundFormStep?.buttons.find(
    (button) => button.primary,
  )?.label;

  return label ?? defaultLabel;
};

export default getAutoCompoundPrimaryButtonTitle;
