import * as React from 'react';

// Components
import AuthorizeSpenderOperationPage from './components/operationPages/DetailsTab/AuthorizeSpenderOperation';
import BalanceAdjustmentOperationPage from './components/operationPages/DetailsTab/BalanceAdjustmentOperation';
import ChangeRoleOperationPage from './components/operationPages/DetailsTab/ChangeRoleOperation';
import CreateVaultOperationPage from './components/operationPages/DetailsTab/CreateVaultOperation';
import DelegateOperationPage from './components/operationPages/DetailsTab/DelegateOperation';
import DepositEventOperationPage from './components/operationPages/DetailsTab/DepositEventOperation';
import DepositOperationPage from './components/operationPages/DetailsTab/DepositOperation';
import DisableVaultOperationPage from './components/operationPages/DetailsTab/DisableVaultOperation';
import GenericOperationPage from './components/operationPages/DetailsTab/GenericOperation';
import GenericOrgChangeOperationPage from './components/operationPages/DetailsTab/GenericOrgChangeOperation';
import LockUnlockOperationPage from './components/operationPages/DetailsTab/LockUnlockOperation';
import OffChainOperationPage from './components/operationPages/DetailsTab/OffChainOperation';
import OneOffOperationPage from './components/operationPages/DetailsTab/OneOffOperation';
import ReplaceUserOperationPage from './components/operationPages/DetailsTab/ReplaceUserOperation';
import TransferOperationPage from './components/operationPages/DetailsTab/TransferOperation';
import TrustedDestinationOperationPage from './components/operationPages/DetailsTab/TrustedDestinationOperation';
import UpdateOrgPoliciesPage from './components/operationPages/DetailsTab/UpdateOrgPoliciesOperation';
import UpdateUserPage from './components/operationPages/DetailsTab/UpdateUser';
import UpdateVaultPoliciesOperationPage from './components/operationPages/DetailsTab/UpdateVaultPoliciesOperation';
import VoteOperationPage from './components/operationPages/DetailsTab/VoteOperation';
import WithdrawOperationPage from './components/operationPages/DetailsTab/WithdrawOperation';

// Constants
import {
  AssetTypeID,
  OperationImplementations,
  RoleAction,
  UserType,
} from 'generated/graphql';

export const ROLE_ACTIONS_TITLES = {
  [RoleAction.READ]: 'Read',
  [RoleAction.CREATE_DEPOSIT_ADDRESS]: 'Create address',
  [RoleAction.TRANSFER]: 'Transfer funds',
  [RoleAction.INTERNAL_TRANSFER]: 'Transfer within Anchorage Digital',
  [RoleAction.TRADE]: 'Trade',
  [RoleAction.HOLD]: 'Hold',
  [RoleAction.INITIATE_WITHDRAWAL]: 'Initiate Withdrawal',
  [RoleAction.READ_TRADE]: 'Read Trade',
  [RoleAction.SUBACCOUNT]: 'Subaccount',
  [RoleAction.LENDER_READ]: 'Read Lending Account',
  [RoleAction.FACILITY_ONLY]: 'Read Lending Facility',
  [RoleAction.ONBOARDING]: 'Onboarding',
  [RoleAction.EXTERNAL_LOAN_WRITE]: 'External Loan Origination',
  [RoleAction.READ_SUBACCOUNT]: 'Read Subaccount',
  [RoleAction.READ_DEPOSIT_ATTRIBUTION]: 'Read Deposit Attribution',
  [RoleAction.DEPOSIT_ATTRIBUTION]: 'Deposit Attribution',
  [RoleAction.PROPOSE_ACCEPT_SETTLEMENTS]: 'Propose and Accept Settlements',
  [RoleAction.AUTHORIZE_SETTLEMENTS]: 'Authorize Settlements',
  [RoleAction.DIEM_PREBURN]: 'Diem Preburn',
  [RoleAction.CONFIGURE_WEBHOOKS]: 'Configure Webhooks',
  [RoleAction.STAKE]: 'Initiate Staking and Unstaking',
};

const {
  AddOrganizationOperation,
  AddUserAndPoliciesOperation,
  AddTrustedDestinationOperation,
  AuthorizeSpenderOperation,
  BalanceAdjustmentOperation,
  ChangeRoleOperation,
  CreateVaultOperation,
  DelegateOperation,
  DepositEventOperation,
  DepositOperation,
  DisableVaultOperation,
  LockOperation,
  OffChainOperation,
  OneOffOperation,
  RemoveUserAndPoliciesOperation,
  RemoveTrustedDestinationOperation,
  ReplaceUserOperation,
  TransferOperation,
  UnlockOperation,
  UpdateOrgPoliciesOperation,
  UpdateVaultPoliciesOperation,
  VoteOperation,
  WithdrawOperation,
  GenericOperation,
  GenericOrgChangeOperation,
} = OperationImplementations;

type OperationImplementationsKeys = keyof typeof OperationImplementations;

export enum OPERATION_TABS {
  DETAILS = 'details',
  OPERATION = 'operation',
}

export const OPERATION_COMPONENTS: {
  [O in OperationImplementationsKeys]: React.ElementType;
} = {
  [AddOrganizationOperation]: () => <></>,
  [AddUserAndPoliciesOperation]: UpdateUserPage,
  [AuthorizeSpenderOperation]: AuthorizeSpenderOperationPage,
  [AddTrustedDestinationOperation]: TrustedDestinationOperationPage,
  [BalanceAdjustmentOperation]: BalanceAdjustmentOperationPage,
  [ChangeRoleOperation]: ChangeRoleOperationPage,
  [CreateVaultOperation]: CreateVaultOperationPage,
  [DelegateOperation]: DelegateOperationPage,
  [DepositOperation]: DepositOperationPage,
  [DepositEventOperation]: DepositEventOperationPage,
  [DisableVaultOperation]: DisableVaultOperationPage,
  [OffChainOperation]: OffChainOperationPage,
  [GenericOperation]: GenericOperationPage,
  [GenericOrgChangeOperation]: GenericOrgChangeOperationPage,
  [OneOffOperation]: OneOffOperationPage,
  [LockOperation]: LockUnlockOperationPage,
  [RemoveUserAndPoliciesOperation]: UpdateUserPage,
  [RemoveTrustedDestinationOperation]: TrustedDestinationOperationPage,
  [ReplaceUserOperation]: ReplaceUserOperationPage,
  [TransferOperation]: TransferOperationPage,
  [UnlockOperation]: LockUnlockOperationPage,
  [UpdateOrgPoliciesOperation]: UpdateOrgPoliciesPage,
  [UpdateVaultPoliciesOperation]: UpdateVaultPoliciesOperationPage,
  [VoteOperation]: VoteOperationPage,
  [WithdrawOperation]: WithdrawOperationPage,
};

export const READABLE_USER_TYPES = {
  [UserType.ADMIN]: 'Administrator',
  [UserType.BROKERAGE_DESK]: 'Brokerage desk',
  [UserType.MEMBER]: 'Member',
  [UserType.VIEW_ONLY]: 'View only',
  [UserType.NOT_A_USER]: 'Not a user',
  [UserType.REMOVED]: 'Removed',
};

export const DISCLOSURE_IDS: { [x in keyof typeof AssetTypeID]?: string } = {
  [AssetTypeID.CGLD]: 'celo_voting',
  [AssetTypeID.CEUR]: 'celo_voting',
  [AssetTypeID.MKR]: 'maker_voting',
  [AssetTypeID.MKR_R]: 'maker_voting',
  [AssetTypeID.ROSE]: 'oasis_delegation',
  [AssetTypeID.XTZ]: 'tezos_delegation',
  [AssetTypeID.FLOW]: 'flow_delegation',
};
