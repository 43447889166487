import { DISCLOSURE_IDS } from 'constants/disclosures';

import DisclosureModal from 'components/shared/DisclosureModal';

import type { AssetTypeID } from 'generated/graphql';

import WidgetWrapper from '../../shared/WidgetWrapper';

type Props = {
  assetTypeID: AssetTypeID;
};

const DisclosureSection = ({ assetTypeID }: Props) => {
  const disclosureID = DISCLOSURE_IDS[assetTypeID];

  if (!disclosureID) {
    return null;
  }

  return (
    <WidgetWrapper>
      <DisclosureModal disclosureID={disclosureID} />
    </WidgetWrapper>
  );
};

export default DisclosureSection;
