import cn from 'classnames';

const FormatPaintIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatPaint', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M36 8V6C36 4.9 35.1 4 34 4H10C8.9 4 8 4.9 8 6V14C8 15.1 8.9 16 10 16H34C35.1 16 36 15.1 36 14V12H38V20H18V42C18 43.1 18.9 44 20 44H24C25.1 44 26 43.1 26 42V24H42V8H36Z" />
    </svg>
  );
};

export { FormatPaintIcon };
