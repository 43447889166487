import cn from 'classnames';

const ReportIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Report', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M15.73 3H8.27L3 8.27v7.455L8.27 21h7.455L21 15.73V8.27L15.73 3zM12 17.3a1.3 1.3 0 11.001-2.601A1.3 1.3 0 0112 17.3zm1-4.3h-2V7h2v6z"></path>
    </svg>
  );
};

export { ReportIcon };
