import cn from 'classnames';

const LocalPhoneIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalPhone', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M13.25 21.59C16.13 27.25 20.76 31.88 26.43 34.76L30.83 30.35C31.38 29.8 32.17 29.64 32.86 29.86C35.1 30.6 37.51 31 40 31C41.11 31 42 31.89 42 33V40C42 41.11 41.11 42 40 42C21.22 42 6 26.78 6 8C6 6.89 6.9 6 8 6H15C16.11 6 17 6.89 17 8C17 10.49 17.4 12.9 18.14 15.14C18.36 15.83 18.2 16.62 17.65 17.17L13.25 21.59Z" />
    </svg>
  );
};

export { LocalPhoneIcon };
