import cn from 'classnames';

const ChangeHistoryIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ChangeHistory', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 15.55L36.78 36H11.22L24 15.55ZM24 8L4 40H44L24 8Z" />
    </svg>
  );
};

export { ChangeHistoryIcon };
