import cn from 'classnames';

const CircleInfoIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Info', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g id=" / Circle Info">
        <g id="Icon">
          <path d="M12.75 9.75V17.25H11.25V9.75H12.75Z" fill="#F5F8FF" />
          <path d="M12.75 8.25V6.75H11.25V8.25H12.75Z" fill="#F5F8FF" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5Z"
            fill="#F5F8FF"
          />
        </g>
      </g>
    </svg>
  );
};

export { CircleInfoIcon };
