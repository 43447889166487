import cn from 'classnames';

const GamepadIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Gamepad', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M30 15V4H18V15L24 21L30 15ZM15 18H4V30H15L21 24L15 18ZM18 33V44H30V33L24 27L18 33ZM33 18L27 24L33 30H44V18H33Z" />
    </svg>
  );
};

export { GamepadIcon };
