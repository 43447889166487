import cn from 'classnames';

const FormatAlignRightIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatAlignRight', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6 42H42V38H6V42ZM18 34H42V30H18V34ZM6 26H42V22H6V26ZM18 18H42V14H18V18ZM6 6V10H42V6H6Z" />
    </svg>
  );
};

export { FormatAlignRightIcon };
