import cn from 'classnames';

const AddToQueueIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AddToQueue', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 6H6C3.79 6 2 7.79 2 10V34C2 36.21 3.79 38 6 38H16V42H32V38H42C44.21 38 45.98 36.21 45.98 34L46 10C46 7.79 44.21 6 42 6ZM42 34H6V10H42V34ZM32 20V24H26V30H22V24H16V20H22V14H26V20H32Z" />
    </svg>
  );
};

export { AddToQueueIcon };
