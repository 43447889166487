import Text from '@latitude/text';
import React from 'react';

import ErrorMessage from 'components/shared/ErrorMessage';
import AddressInfo from 'components/shared/OperationDrawer/components/widgets/AddressInfo';
import TextSection from 'components/shared/OperationDrawer/components/widgets/TextSection';
import PolicyNotes from 'components/shared/PolicyNotes';

import {
  CreateTendermintClaimRewardsOneoffMutation,
  DepositEventOperation,
} from 'generated/graphql';

import css from './styles.module.scss';

import { EditCommentSection } from '../../../../../../shared/EditCommentSection';
import {
  STAKING_ERROR_MESSAGE_SUBTITLE,
  STAKING_ERROR_MESSAGE_TITLE,
} from '../../../constants';
import useStandardizedStakingContext from '../../StandardizedStakingContext/useStandardizedStakingContext';

type Props = {
  data?:
    | CreateTendermintClaimRewardsOneoffMutation['createTendermintClaimRewardsOneoff']
    | null;
};

const SSClaimRewardsReviewPage: React.FC<Props> = ({ data }) => {
  const depositEventOperationData = data as DepositEventOperation;

  const { assetTypeInfo, walletWithStakingInfo } =
    useStandardizedStakingContext();

  const vault = walletWithStakingInfo.vault;
  const sourceVaultName = vault.name;
  const accountName = vault.account.name;

  if (!depositEventOperationData) {
    return (
      <ErrorMessage
        title={STAKING_ERROR_MESSAGE_TITLE}
        subtitle={STAKING_ERROR_MESSAGE_SUBTITLE}
      />
    );
  }

  const {
    description,
    descriptionEditable,
    operationID = '',
  } = depositEventOperationData;

  const walletName = walletWithStakingInfo.name;
  const depositAddress = walletWithStakingInfo.depositAddress.address;
  const networkName = assetTypeInfo.networkInfo?.name;
  const assetName = assetTypeInfo.name;
  const assetAbbreviation = assetTypeInfo.abbreviation;

  return (
    <div className={css.mainContent}>
      <div className={css.heading}>
        <Text variant="headingTiny">
          {`Claim ${assetAbbreviation} rewards`}
        </Text>
        <Text>{`for ${assetAbbreviation} Staking`}</Text>
      </div>

      <EditCommentSection
        description={description}
        descriptionEditable={descriptionEditable}
        operationID={operationID}
        hasTopBorder={false}
      />

      {/* Source, Vault and Account section */}
      <AddressInfo
        addressSectionTitle={'Address'}
        walletName={walletName}
        addresses={{ [depositAddress]: [walletName] }}
        vaultName={sourceVaultName}
        accountName={accountName}
        widgetWrapperClassName={css.claimRewardsAddressInfoWrapper}
      />

      <TextSection
        content={`${assetAbbreviation} | ${assetName}`}
        title="Asset"
      />

      {networkName && <TextSection content={networkName} title="Network" />}

      <PolicyNotes />
    </div>
  );
};

export default SSClaimRewardsReviewPage;
