import type { MenuItemProps, TSubMenuHeaderProps } from './types';

import MenuItem from './MenuItem';
import SubMenuHeader from './SubMenuHeader';

export const menuEntriesMapper = (
  item: MenuItemProps | TSubMenuHeaderProps,
  currentPath: string,
  appPrefix: string,
) => {
  const { Icon, label, testId, extra } = item;

  if ('subMenuItems' in item) {
    return (
      <SubMenuHeader
        currentPath={currentPath}
        Icon={Icon}
        key={testId}
        label={label}
        testId={testId}
        subMenuItems={item.subMenuItems}
        appPrefix={appPrefix}
        extra={extra}
      />
    );
  }

  const { route, isSelected } = item as MenuItemProps;
  return (
    <MenuItem
      Icon={Icon}
      isSelected={isSelected}
      key={testId}
      label={label}
      route={route}
      testId={testId}
      extra={extra}
      onClick={(item as MenuItemProps).onClick}
      appPrefix={appPrefix}
    />
  );
};

export const isItemSelected = ({
  route = [],
  currentPath,
  appPrefix,
  exact = false,
}: {
  route: string | string[];
  currentPath: string;
  appPrefix: string;
  exact?: boolean;
}) => {
  const routes = Array.isArray(route) ? route : [route];
  const fullPath = `${appPrefix}${currentPath}`;

  return routes.some((route) => {
    if (exact) {
      return route === fullPath;
    } else {
      return fullPath.startsWith(route);
    }
  });
};

export const isDeployPR = () => {
  // pr-XXXXX (5 numbers)
  const deployPRRegex = /pr-\d{5}/;
  return deployPRRegex.test(window.location.pathname);
};

export const getDeployPRSubdirectory = () => {
  if (!isDeployPR()) {
    return '';
  }

  const pathname = window.location.pathname;
  return `/${pathname.split('/').find((x) => x.includes('pr-'))}` || '';
};
