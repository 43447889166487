import cn from 'classnames';

const CloudQueueIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CloudQueue', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38.71 20.07C37.35 13.19 31.28 8 24 8C18.22 8 13.21 11.28 10.7 16.07C4.69 16.72 0 21.81 0 28C0 34.63 5.37 40 12 40H38C43.52 40 48 35.52 48 30C48 24.72 43.89 20.44 38.71 20.07ZM38 36H12C7.58 36 4 32.42 4 28C4 23.58 7.58 20 12 20H13.42C14.73 15.39 18.96 12 24 12C30.08 12 35 16.92 35 23V24H38C41.31 24 44 26.69 44 30C44 33.31 41.31 36 38 36Z" />
    </svg>
  );
};

export { CloudQueueIcon };
