export const textVariantsAndStyles = {
  headingTiny: [
    'font-ref-web-heading-tiny-font-family',
    'text-ref-web-heading-tiny-font-size',
    'font-ref-web-heading-tiny-font-weight',
    'leading-ref-web-heading-tiny-line-height',
    'tracking-font-ref-web-heading-tiny-letter-spacing',
  ],
  headingSmall: [
    'font-ref-web-heading-small-font-family',
    'text-ref-web-heading-small-font-size',
    'font-ref-web-heading-small-font-weight',
    'leading-ref-web-heading-small-line-height',
    'tracking-font-ref-web-heading-small-letter-spacing',
  ],
  headingMedium: [
    'font-ref-web-heading-medium-font-family',
    'text-ref-web-heading-medium-font-size',
    'font-ref-web-heading-medium-font-weight',
    'leading-ref-web-heading-medium-line-height',
    'tracking-font-ref-web-heading-medium-letter-spacing',
  ],
  headingLarge: [
    'font-ref-web-heading-large-font-family',
    'text-ref-web-heading-large-font-size',
    'font-ref-web-heading-large-font-weight',
    'leading-ref-web-heading-large-line-height',
    'tracking-font-ref-web-heading-large-letter-spacing',
  ],
  bodyRegular: [
    'font-ref-web-body-regular-font-family',
    'text-ref-web-body-regular-font-size',
    'font-ref-web-body-regular-font-weight',
    'leading-ref-web-body-regular-line-height',
    'tracking-font-ref-web-body-regular-letter-spacing',
  ],
  bodyMedium: [
    'font-ref-web-body-medium-font-family',
    'text-ref-web-body-medium-font-size',
    'font-ref-web-body-medium-font-weight',
    'leading-ref-web-body-medium-line-height',
    'tracking-font-ref-web-body-medium-letter-spacing',
  ],
  bodyBold: [
    'font-ref-web-body-bold-font-family',
    'text-ref-web-body-bold-font-size',
    'font-ref-web-body-bold-font-weight',
    'leading-ref-web-body-bold-line-height',
    'tracking-font-ref-web-body-bold-letter-spacing',
  ],
  captionRegular: [
    'font-ref-web-caption-regular-font-family',
    'text-ref-web-caption-regular-font-size',
    'font-ref-web-caption-regular-font-weight',
    'leading-ref-web-caption-regular-line-height',
    'tracking-font-ref-web-caption-regular-letter-spacing',
  ],
  captionMedium: [
    'font-ref-web-caption-medium-font-family',
    'text-ref-web-caption-medium-font-size',
    'font-ref-web-caption-medium-font-weight',
    'leading-ref-web-caption-medium-line-height',
    'tracking-font-ref-web-caption-medium-letter-spacing',
  ],
  captionBold: [
    'font-ref-web-caption-bold-font-family',
    'text-ref-web-caption-bold-font-size',
    'font-ref-web-caption-bold-font-weight',
    'leading-ref-web-caption-bold-line-height',
    'tracking-font-ref-web-caption-bold-letter-spacing',
  ],
  displaySmall: [
    'font-ref-web-display-small-font-family',
    'text-ref-web-display-small-font-size',
    'font-ref-web-display-small-font-weight',
    'leading-ref-web-display-small-line-height',
    'tracking-font-ref-web-display-small-letter-spacing',
  ],
  displayMedium: [
    'font-ref-web-display-medium-font-family',
    'text-ref-web-display-medium-font-size',
    'font-ref-web-display-medium-font-weight',
    'leading-ref-web-display-medium-line-height',
    'tracking-font-ref-web-display-medium-letter-spacing',
  ],
  displayLarge: [
    'font-ref-web-display-large-font-family',
    'text-ref-web-display-large-font-size',
    'font-ref-web-display-large-font-weight',
    'leading-ref-web-display-large-line-height',
    'tracking-font-ref-web-display-large-letter-spacing',
  ],
  displayHuge: [
    'font-ref-web-display-huge-font-family',
    'text-ref-web-display-huge-font-size',
    'font-ref-web-display-huge-font-weight',
    'leading-ref-web-display-huge-line-height',
    'tracking-font-ref-web-display-huge-letter-spacing',
  ],
  link: [
    'font-ref-web-link-link-font-family',
    'text-ref-web-link-link-font-size',
    'font-ref-web-link-link-font-weight',
    'leading-ref-web-link-link-line-height',
    'tracking-font-ref-web-link-link-letter-spacing',
    'underline',
  ],
  linkStandalone: [
    'font-ref-web-link-standalone-font-family',
    'text-ref-web-link-standalone-font-size',
    'font-ref-web-link-standalone-font-weight',
    'leading-ref-web-link-standalone-line-height',
    'tracking-font-ref-web-link-standalone-letter-spacing',
  ],
  metricTiny: [
    'font-ref-web-metrics-tiny-font-family',
    'text-ref-web-metrics-tiny-font-size',
    'font-ref-web-metrics-tiny-font-weight',
    'leading-ref-web-metrics-tiny-line-height',
    'tracking-font-ref-web-metrics-tiny-letter-spacing',
  ],
  metricSmall: [
    'font-ref-web-metrics-small-font-family',
    'text-ref-web-metrics-small-font-size',
    'font-ref-web-metrics-small-font-weight',
    'leading-ref-web-metrics-small-line-height',
    'tracking-font-ref-web-metrics-small-letter-spacing',
  ],
  metricMedium: [
    'font-ref-web-metrics-medium-font-family',
    'text-ref-web-metrics-medium-font-size',
    'font-ref-web-metrics-medium-font-weight',
    'leading-ref-web-metrics-medium-line-height',
    'tracking-font-ref-web-metrics-medium-letter-spacing',
  ],
  metricLarge: [
    'font-ref-web-metrics-large-font-family',
    'text-ref-web-metrics-large-font-size',
    'font-ref-web-metrics-large-font-weight',
    'leading-ref-web-metrics-large-line-height',
    'tracking-font-ref-web-metrics-large-letter-spacing',
  ],
  metricHuge: [
    'font-ref-web-metrics-huge-font-family',
    'text-ref-web-metrics-huge-font-size',
    'font-ref-web-metrics-huge-font-weight',
    'leading-ref-web-metrics-huge-line-height',
    'tracking-font-ref-web-metrics-huge-letter-spacing',
  ],
  labelSmall: [
    'font-ref-web-labels-small-font-family',
    'text-ref-web-labels-small-font-size',
    'font-ref-web-labels-small-font-weight',
    'leading-ref-web-labels-small-line-height',
    'tracking-font-ref-web-labels-small-letter-spacing',
  ],
  labelMedium: [
    'font-ref-web-labels-medium-font-family',
    'text-ref-web-labels-medium-font-size',
    'font-ref-web-labels-medium-font-weight',
    'leading-ref-web-labels-medium-line-height',
    'tracking-font-ref-web-labels-medium-letter-spacing',
  ],
  snippetRegular: [
    'font-ref-web-snippet-regular-font-family',
    'text-ref-web-snippet-regular-font-size',
    'font-ref-web-snippet-regular-font-weight',
    'leading-ref-web-snippet-regular-line-height',
    'tracking-font-ref-web-snippet-regular-letter-spacing',
  ],
  snippetMedium: [
    'font-ref-web-snippet-medium-font-family',
    'text-ref-web-snippet-medium-font-size',
    'font-ref-web-snippet-medium-font-weight',
    'leading-ref-web-snippet-medium-line-height',
    'tracking-font-ref-web-snippet-medium-letter-spacing',
  ],
  snippetBold: [
    'font-ref-web-snippet-bold-font-family',
    'text-ref-web-snippet-bold-font-size',
    'font-ref-web-snippet-bold-font-weight',
    'leading-ref-web-snippet-bold-line-height',
    'tracking-font-ref-web-snippet-bold-letter-spacing',
  ],
};

/**
 * Represents the available variants for the Text component.
 */
export type TextVariant = keyof typeof textVariantsAndStyles;
