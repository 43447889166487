import { PolicySourceOfTruth, User } from '../generated/graphql';

import { Quorum as LegacyQuorum, PolicyQuorum } from '../types';

export class Quorum {
  readonly size: number;
  readonly threshold: number;
  readonly members: User[];
  readonly displayName: string;
  readonly description?: string;

  constructor(
    private readonly sourceOfTruth: PolicySourceOfTruth,
    private readonly fallbackDisplayName: string,
    private readonly rawQuorum?: PolicyQuorum | LegacyQuorum,
  ) {
    this.size = this.parseQuorumSize();
    this.threshold = this.parseQuorumThreshold();
    this.members = this.parseQuorumMembers();
    this.displayName = this.parseQuorumName();
    this.description = this.parseQuorumDescription();
  }

  private parseQuorumSize(): number {
    if (this.rawQuorum === undefined) {
      return 0;
    }

    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return (this.rawQuorum as LegacyQuorum).users?.length ?? 0;
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (this.rawQuorum as PolicyQuorum).expandedMembers?.length ?? 0;
    }
  }

  private parseQuorumThreshold(): number {
    if (this.rawQuorum === undefined) {
      return 0;
    }

    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return (this.rawQuorum as LegacyQuorum).signaturesRequired ?? 0;
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (this.rawQuorum as PolicyQuorum).threshold ?? 0;
    }
  }

  private parseQuorumMembers(): User[] {
    if (this.rawQuorum === undefined) {
      return [];
    }

    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return (this.rawQuorum as LegacyQuorum).users ?? [];
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (this.rawQuorum as PolicyQuorum).expandedMembers ?? [];
    }
  }

  private parseQuorumName(): string {
    if (this.rawQuorum === undefined) {
      return this.fallbackDisplayName;
    }

    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return this.fallbackDisplayName;
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (
          (this.rawQuorum as PolicyQuorum).displayName ??
          this.fallbackDisplayName
        );
    }
  }

  private parseQuorumDescription(): string | undefined {
    if (this.rawQuorum === undefined) {
      return;
    }

    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return;
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER: {
        const description = (this.rawQuorum as PolicyQuorum).description;
        if (description !== null) {
          return description;
        }
      }
    }
  }
}

class BaseQuorum extends Quorum {
  constructor(
    sourceOfTruth: PolicySourceOfTruth,
    rawQuorum?: PolicyQuorum | LegacyQuorum,
  ) {
    super(
      sourceOfTruth,
      sourceOfTruth !== PolicySourceOfTruth.POLICY_SOT_CONTAINER
        ? 'Approvers'
        : 'Base quorum',
      rawQuorum,
    );
  }
}

class Subquorum extends Quorum {
  constructor(
    sourceOfTruth: PolicySourceOfTruth,
    rawQuorum?: PolicyQuorum | LegacyQuorum,
  ) {
    super(sourceOfTruth, 'Required approvers', rawQuorum);
  }
}

export class QuorumGroup {
  readonly baseQuorum: Quorum;
  readonly subquorums: Quorum[];

  constructor(
    sourceOfTruth: PolicySourceOfTruth,
    baseQuorum?: PolicyQuorum | LegacyQuorum,
    subquorums?: (PolicyQuorum | LegacyQuorum)[],
  ) {
    this.baseQuorum = new BaseQuorum(sourceOfTruth, baseQuorum);
    this.subquorums =
      subquorums?.map((subquorum) => new Subquorum(sourceOfTruth, subquorum)) ??
      [];
  }
}
