import cn from 'classnames';

const ChevronRightIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ChevronRight', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M19.9999 12L17.1699 14.83L26.3399 24L17.1699 33.17L19.9999 36L31.9999 24L19.9999 12Z" />
    </svg>
  );
};

export { ChevronRightIcon };
