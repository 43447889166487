import { useMemo } from 'react';

import { useSnackbar } from '@anchorage/common/dist/components/Snackbar';

import DisclosurePage from 'components/Vault/shared/Staking/DisclosurePage';

import useStaticDataContext from 'hooks/useStaticDataContext';

import isDisclosureAccepted from 'utils/disclosures/isDisclosureAccepted';

import {
  AcceptDisclosureMutationFn,
  StaticDataFODocument,
  useAcceptDisclosureMutation,
} from 'generated/graphql';

interface UseDisclosureModalReturn {
  disclosureComponent: JSX.Element;
  hasAcceptedDisclosure: boolean;
  acceptDisclosureMutation: AcceptDisclosureMutationFn;
}

interface UseDisclosureModalProps {
  disclosureID: string;
  accountID: string;
  onAcceptedDisclosure?: () => void;
  handleMarkDisclosureAsRead: () => void;
}

export const useDisclosureModal = ({
  disclosureID,
  accountID,
  onAcceptedDisclosure,
  handleMarkDisclosureAsRead,
}: UseDisclosureModalProps): UseDisclosureModalReturn => {
  const { addSnackbar } = useSnackbar();
  const { disclosures } = useStaticDataContext();
  const disclosure = disclosures[disclosureID];

  const hasAcceptedDisclosure = useMemo(() => {
    return isDisclosureAccepted(disclosure, accountID);
  }, [disclosure, accountID]);

  const [acceptDisclosureMutation] = useAcceptDisclosureMutation({
    refetchQueries: [StaticDataFODocument],
    variables: {
      disclosureID: disclosureID,
      version: disclosure?.version ?? 0,
      accountID: accountID,
    },
    onError: () => {
      addSnackbar({
        type: 'error',
        text: 'There was an issue accepting the disclosure',
        subtext: 'Please try again',
      });
    },
    onCompleted: () => {
      onAcceptedDisclosure && onAcceptedDisclosure();
    },
  });

  return {
    disclosureComponent: (
      <DisclosurePage
        disclosureTitle={disclosure?.title || ''}
        disclosureContent={disclosure?.markdownContent || ''}
        handleMarkDisclosureAsRead={handleMarkDisclosureAsRead}
      />
    ),
    hasAcceptedDisclosure,
    acceptDisclosureMutation,
  };
};
