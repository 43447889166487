import React from 'react';

import { Text } from '@anchorage/common/dist/components';

import css from './styles.module.scss';

const ThankYou: React.FC = () => {
  return (
    <div className={css.surveyQuestionWrapper}>
      <Text className={css.surveyQuestion}>Thank you for participating</Text>
      <Text size="small" className={css.surveySubtext}>
        Your feedback is important and will help us make our products even
        better at serving your needs.
      </Text>
      <Text size="tiny">
        Please note that topics included in this survey or requested by
        respondents do not guarantee their addition to Anchorage Digital&apos;s
        products.
      </Text>
    </div>
  );
};

export default ThankYou;
