import cn from 'classnames';

const AirlineSeatIndividualSuiteIcon = (props: SVGProps): JSX.Element => {
  const {
    title = 'AirlineSeatIndividualSuite',
    className,
    ...restProps
  } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M14 26C17.31 26 20 23.31 20 20C20 16.69 17.31 14 14 14C10.69 14 8 16.69 8 20C8 23.31 10.69 26 14 26ZM38 14H22V28H6V14H2V34H46V22C46 17.58 42.42 14 38 14Z" />
    </svg>
  );
};

export { AirlineSeatIndividualSuiteIcon };
