// Components
// Types & Constants
import type { SimpleButtonProps } from '../../../components/ButtonGroup/_types';
import {
  AlphabeticalIcon,
  AppIcon,
  CalendarIcon,
  DollarSignIcon,
  GridViewIcon,
  HighQuantityIcon,
  ImageIcon,
  ListIcon,
  LowQuantityIcon,
} from '../../../components/Icons';
import { SVGProps } from '../../../components/Icons/_types';

import css from './index.css';

import { NFTContentNFTViews, NFTContentViews, NFTSorting } from '../_constants';

export const CONTENT_VIEW_BTNS: SimpleButtonProps<NFTContentViews>[] = [
  {
    Icon: AppIcon,
    iconClassName: css.iconClassName,
    btnValue: NFTContentViews.GRID,
    label: '',
  },
  {
    Icon: ListIcon,
    iconClassName: css.iconClassName,
    btnValue: NFTContentViews.TABLE,
    label: '',
  },
];

export const NFT_CONTENT_NFT_VIEW_BTNS: SimpleButtonProps<NFTContentNFTViews>[] =
  [
    {
      Icon: ImageIcon,
      iconClassName: css.iconClassName,
      btnValue: NFTContentNFTViews.SINGLE,
      label: 'NFT',
    },
    {
      Icon: GridViewIcon,
      iconClassName: css.iconClassName,
      btnValue: NFTContentNFTViews.COLLECTION,
      label: 'Collection',
    },
  ];

export const SORT_OPTIONS = [
  {
    label: 'Alphabetical: A-Z',
    value: NFTSorting.ALPHABETICAL_ASC,
  },
  {
    label: 'Alphabetical: Z-A',
    value: NFTSorting.ALPHABETICAL_DESC,
  },
  {
    label: 'Date: Last edited',
    value: NFTSorting.DATE,
  },
  {
    label: 'Price: Highest first',
    value: NFTSorting.PRICE_DESC,
  },
  {
    label: 'Price: Lowest first',
    value: NFTSorting.PRICE_ASC,
  },
];

export const COLLECTION_SORT_OPTIONS = [
  {
    label: 'Quantity: Highest first',
    value: NFTSorting.QUANTITY_DESC,
  },
  {
    label: 'Quantity: Lowest first',
    value: NFTSorting.QUANTITY_ASC,
  },
];

export const SORT_ICON_MAPPING: Record<NFTSorting, React.FC<SVGProps>> = {
  [NFTSorting.ALPHABETICAL_ASC]: AlphabeticalIcon,
  [NFTSorting.ALPHABETICAL_DESC]: AlphabeticalIcon,
  [NFTSorting.DATE]: CalendarIcon,
  [NFTSorting.PRICE_ASC]: DollarSignIcon,
  [NFTSorting.PRICE_DESC]: DollarSignIcon,
  [NFTSorting.QUANTITY_ASC]: HighQuantityIcon,
  [NFTSorting.QUANTITY_DESC]: LowQuantityIcon,
};
