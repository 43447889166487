import cn from 'classnames';

const ExposureZeroIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ExposureZero', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M32.28 25C32.28 27 32.08 28.7 31.69 30.1C31.3 31.5 30.74 32.63 30.02 33.51C29.3 34.38 28.43 35.02 27.42 35.41C26.41 35.8 25.28 36 24.03 36C22.79 36 21.66 35.8 20.64 35.41C19.62 35.02 18.75 34.38 18.02 33.51C17.29 32.64 16.72 31.5 16.33 30.1C15.93 28.7 15.73 27 15.73 25V20.92C15.73 18.92 15.93 17.22 16.32 15.83C16.71 14.44 17.27 13.31 18 12.45C18.72 11.59 19.59 10.96 20.61 10.58C21.63 10.2 22.76 10.01 24 10.01C25.25 10.01 26.39 10.2 27.41 10.58C28.43 10.96 29.3 11.59 30.03 12.45C30.75 13.31 31.31 14.44 31.71 15.83C32.1 17.22 32.3 18.92 32.3 20.92V25H32.28ZM28.06 20.27C28.06 18.98 27.97 17.9 27.79 17.03C27.61 16.16 27.35 15.45 27 14.91C26.65 14.37 26.23 13.98 25.72 13.75C25.22 13.51 24.64 13.39 23.99 13.39C23.34 13.39 22.76 13.51 22.26 13.75C21.75 13.99 21.33 14.38 20.98 14.91C20.63 15.45 20.37 16.16 20.19 17.03C20.01 17.9 19.92 18.99 19.92 20.27V25.61C19.92 26.88 20.01 27.97 20.2 28.86C20.39 29.75 20.65 30.48 21 31.03C21.35 31.59 21.78 31.99 22.29 32.25C22.8 32.5 23.38 32.63 24.03 32.63C24.69 32.63 25.27 32.5 25.77 32.25C26.27 32 26.69 31.59 27.03 31.03C27.37 30.47 27.63 29.75 27.8 28.86C27.97 27.97 28.06 26.88 28.06 25.61V20.27Z" />
    </svg>
  );
};

export { ExposureZeroIcon };
