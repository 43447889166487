import { strictQuorumFilterFn, weakQuorumFilterFn } from './utils';

import type {
  PolicyFragmentBinding as GraphQLPolicyFragmentBinding,
  User,
} from '../generated/graphql';
import { PolicySourceOfTruth } from '../generated/graphql';

import type {
  BarePolicy,
  BarePolicyFragmentBinding,
  Policy,
  PolicyFragmentBinding,
  PolicyQuorum,
  Quorum,
} from '../types';

import { SCOPE_DISPLAY_NAMES } from '../constants/scopes';
import { QuorumGroup } from './Quorum';

export class BasePolicy {
  readonly id: string;
  readonly scope: GraphQLPolicyFragmentBinding['scope'];
  readonly displayName: string;
  readonly description?: string;

  readonly quorumGroup: QuorumGroup;

  readonly quorumSize: number;
  readonly quorumNecessaryEndorsements: number;
  readonly quorumMembers: User[];

  readonly requiredUsersQuorumSize: number;
  readonly requiredUsersNecessaryEndorsements: number;
  readonly requiredUsersQuorumMembers: User[];

  constructor(
    private readonly sourceOfTruth: PolicySourceOfTruth,
    private readonly rawPolicy: BarePolicyFragmentBinding | BarePolicy,
  ) {
    this.id = this.parseId();
    this.scope = this.parseScope();
    this.displayName = this.parseDisplayName();
    this.description = this.parseDescription();

    this.quorumGroup = this.parseQuorumGroup();

    this.quorumSize = this.parseQuorumSize();
    this.quorumNecessaryEndorsements = this.parseQuorumNecessaryEndorsements();
    this.quorumMembers = this.parseQuorumMembers();

    this.requiredUsersQuorumSize = this.parseRequiredUsersQuorumSize();
    this.requiredUsersNecessaryEndorsements =
      this.parseRequiredUsersQuorumNecessaryEndorsements();
    this.requiredUsersQuorumMembers = this.parseRequiredUsersQuorumMembers();
  }

  private parseDisplayName(): string {
    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return SCOPE_DISPLAY_NAMES[this.scope] ?? '';
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (this.rawPolicy as PolicyFragmentBinding).displayName ?? '';
    }
  }

  private parseDescription(): string | undefined {
    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return;
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (this.rawPolicy as PolicyFragmentBinding).description;
    }
  }

  private parseQuorumGroup(): QuorumGroup {
    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY: {
        const legacyPolicy = this.rawPolicy as Policy;
        return new QuorumGroup(
          this.sourceOfTruth,
          legacyPolicy.quorums?.[0],
          legacyPolicy.requiredUsersQuorums,
        );
      }
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER: {
        const policyContainer = this.rawPolicy as PolicyFragmentBinding;
        const quorums = policyContainer.fragments?.[0]?.quorums;
        const baseQuorum = quorums?.find(strictQuorumFilterFn);
        const subquorums = quorums?.filter(weakQuorumFilterFn);
        return new QuorumGroup(this.sourceOfTruth, baseQuorum, subquorums);
      }
    }
  }

  private parseId(): string {
    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return (this.rawPolicy as Policy).action ?? '';
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (
          (this.rawPolicy as PolicyFragmentBinding).fragmentBindingID ?? ''
        );
    }
  }

  private parseScope(): string {
    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return (this.rawPolicy as Policy).action ?? '';
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (this.rawPolicy as PolicyFragmentBinding).scope ?? '';
    }
  }

  private getQuorum(): Quorum | PolicyQuorum | undefined {
    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return (this.rawPolicy as Policy).quorums?.[0];
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (
          this.rawPolicy as PolicyFragmentBinding
        ).fragments?.[0]?.quorums?.filter(strictQuorumFilterFn)[0];
    }
  }

  private parseQuorumSize(): number {
    const quorum = this.getQuorum();
    if (quorum === undefined) {
      return 0;
    }

    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return (quorum as Quorum).users?.length ?? 0;
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (quorum as PolicyQuorum).expandedMembers?.length ?? 0;
    }
  }

  private parseQuorumNecessaryEndorsements(): number {
    const quorum = this.getQuorum();
    if (quorum === undefined) {
      return 0;
    }

    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return (quorum as Quorum).signaturesRequired ?? 0;
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (quorum as PolicyQuorum).threshold ?? 0;
    }
  }

  private parseQuorumMembers(): User[] {
    const quorum = this.getQuorum();
    if (quorum === undefined) {
      return [];
    }

    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return (quorum as Quorum).users ?? [];
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (quorum as PolicyQuorum).expandedMembers ?? [];
    }
  }

  private getRequiredUsersQuorum(): Quorum | PolicyQuorum | undefined {
    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return (this.rawPolicy as Policy).requiredUsersQuorums?.[0];
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (
          this.rawPolicy as PolicyFragmentBinding
        ).fragments?.[0]?.quorums?.filter(weakQuorumFilterFn)[0];
    }
  }

  private parseRequiredUsersQuorumSize(): number {
    const quorum = this.getRequiredUsersQuorum();
    if (quorum === undefined) {
      return 0;
    }

    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return (quorum as Quorum).users?.length ?? 0;
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (quorum as PolicyQuorum).expandedMembers?.length ?? 0;
    }
  }

  private parseRequiredUsersQuorumNecessaryEndorsements(): number {
    const quorum = this.getRequiredUsersQuorum();
    if (quorum === undefined) {
      return 0;
    }

    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return (quorum as Quorum).signaturesRequired ?? 0;
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (quorum as PolicyQuorum).threshold ?? 0;
    }
  }

  private parseRequiredUsersQuorumMembers(): User[] {
    const quorum = this.getRequiredUsersQuorum();
    if (quorum === undefined) {
      return [];
    }

    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        return (quorum as Quorum).users ?? [];
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER:
        return (quorum as PolicyQuorum).expandedMembers ?? [];
    }
  }
}
