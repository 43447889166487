import cn from 'classnames';
import * as React from 'react';

import { Text } from '@anchorage/common/dist/components';
import { formatEnum } from '@anchorage/common/dist/utils/format';

import { getOperationStatusColor } from '../../../utils/operationStatus';

import type { AppStatus, OperationState } from 'generated/graphql';

import css from './styles.module.scss';

type Props = React.ComponentPropsWithoutRef<'div'> & {
  operationState: OperationState;
  appStatus: AppStatus;
  statusNote?: string;
};

const Status = ({
  operationState,
  appStatus,
  statusNote,
  ...restProps
}: Props) => {
  const status = formatEnum(appStatus, true);
  const statusIndicatorColor = getOperationStatusColor(operationState);

  const statusIndicatonsClassname = cn([
    css.statusIndicator,
    css[statusIndicatorColor],
  ]);

  return (
    <div className={css.statusContainer} {...restProps}>
      <div className={statusIndicatonsClassname} />
      <Text size="small">{status}</Text>
      {statusNote && (
        <Text size="tiny" className={css.statusNote}>
          {statusNote}
        </Text>
      )}
    </div>
  );
};

export default Status;
