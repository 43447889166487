import cn from 'classnames';

const AlarmOnIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AlarmOn', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M44 11.72L34.81 4.01L32.24 7.07L41.43 14.78L44 11.72ZM15.76 7.06L13.19 4L4 11.71L6.57 14.77L15.76 7.06ZM23.99 8.28C14.04 8.28 6 16.34 6 26.28C6 36.22 14.04 44.28 23.99 44.28C33.94 44.28 42 36.22 42 26.28C42 16.34 33.94 8.28 23.99 8.28ZM24 40.28C16.27 40.28 10 34.01 10 26.28C10 18.55 16.27 12.28 24 12.28C31.73 12.28 38 18.55 38 26.28C38 34.01 31.74 40.28 24 40.28ZM21.07 29.33L16.83 25.09L14.71 27.21L21.07 33.57L33.08 21.56L30.96 19.44L21.07 29.33Z" />
    </svg>
  );
};

export { AlarmOnIcon };
