import cn from 'classnames';

const LocationOffIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocationOff', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 13C26.76 13 29 15.24 29 18C29 19.47 28.35 20.78 27.34 21.7L34.6 28.96C36.55 25.24 38 21.37 38 18C38 10.27 31.73 4 24 4C20.04 4 16.47 5.65 13.93 8.29L20.3 14.66C21.21 13.65 22.53 13 24 13ZM32.75 32.2L23.5 22.95L23.28 22.73L6.54 6L4 8.55L10.36 14.91C10.13 15.9 10 16.93 10 18C10 28.5 24 44 24 44C24 44 27.34 40.3 30.75 35.3L37.45 42L40 39.45L32.75 32.2Z" />
    </svg>
  );
};

export { LocationOffIcon };
