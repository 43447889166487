import cn from 'classnames';

const ExitToAppIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ExitToApp', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M20.17 31.17L23 34L33 24L23 14L20.17 16.83L25.34 22H6V26H25.34L20.17 31.17ZM38 6H10C7.79 6 6 7.79 6 10V18H10V10H38V38H10V30H6V38C6 40.21 7.79 42 10 42H38C40.21 42 42 40.21 42 38V10C42 7.79 40.21 6 38 6Z" />
    </svg>
  );
};

export { ExitToAppIcon };
