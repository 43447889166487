import cn from 'classnames';

const ContactsIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Contacts', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 0H8V4H40V0ZM8 48H40V44H8V48ZM40 8H8C5.79 8 4 9.79 4 12V36C4 38.21 5.79 40 8 40H40C42.21 40 44 38.21 44 36V12C44 9.79 42.21 8 40 8ZM24 13.5C26.48 13.5 28.5 15.52 28.5 18C28.5 20.49 26.48 22.5 24 22.5C21.52 22.5 19.5 20.49 19.5 18C19.5 15.52 21.52 13.5 24 13.5ZM34 34H14V31C14 27.67 20.67 26 24 26C27.33 26 34 27.67 34 31V34Z" />
    </svg>
  );
};

export { ContactsIcon };
