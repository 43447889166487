import { createContext, useContext } from 'react';

import type { AppSharedComponentsState } from './types';

import {
  productUpdatesInitialState,
  useProductUpdatesState,
} from './useProductUpdatesState';

const componentsInitialState: AppSharedComponentsState = {
  productUpdates: productUpdatesInitialState,
};

export const AppSharedComponentsContext =
  createContext<AppSharedComponentsState>(componentsInitialState);

export const AppSharedComponents = ({
  children,
}: {
  children: JSX.Element;
}) => {
  return (
    <AppSharedComponentsContext.Provider
      value={{
        productUpdates: useProductUpdatesState(),
      }}
    >
      {children}
    </AppSharedComponentsContext.Provider>
  );
};

export const useAppSharedComponents = () =>
  useContext(AppSharedComponentsContext);
