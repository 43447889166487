import cn from 'classnames';

const BrushIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Brush', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M14 28C10.69 28 8 30.69 8 34C8 36.62 5.69 38 4 38C5.84 40.44 8.99 42 12 42C16.42 42 20 38.42 20 34C20 30.69 17.31 28 14 28ZM41.41 9.26L38.74 6.59C37.96 5.81 36.69 5.81 35.91 6.59L18 24.5L23.5 30L41.41 12.09C42.2 11.3 42.2 10.04 41.41 9.26Z" />
    </svg>
  );
};

export { BrushIcon };
