import cn from 'classnames';

const FilterVintageIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FilterVintage', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M37.39 24.8C36.83 24.48 36.25 24.22 35.67 24C36.25 23.78 36.83 23.52 37.39 23.2C41.23 20.98 43.37 16.95 43.38 12.81C39.79 10.75 35.23 10.59 31.39 12.81C30.83 13.13 30.32 13.5 29.83 13.9C29.93 13.27 30 12.65 30 12C30 7.56 27.58 3.69 24 1.62C20.42 3.69 18 7.56 18 12C18 12.65 18.06 13.27 18.16 13.89C17.67 13.5 17.16 13.13 16.6 12.8C12.76 10.58 8.19999 10.74 4.60999 12.8C4.61999 16.94 6.75999 20.97 10.6 23.19C11.16 23.51 11.74 23.77 12.32 23.99C11.74 24.21 11.16 24.47 10.6 24.79C6.75999 27.01 4.61999 31.04 4.60999 35.18C8.19999 37.24 12.76 37.4 16.6 35.18C17.16 34.86 17.67 34.49 18.16 34.09C18.07 34.73 18 35.35 18 36C18 40.44 20.42 44.31 24 46.38C27.58 44.3 30 40.44 30 36C30 35.35 29.93 34.73 29.84 34.11C30.33 34.5 30.84 34.87 31.4 35.2C35.24 37.42 39.8 37.26 43.39 35.2C43.38 31.05 41.24 27.02 37.39 24.8ZM24 32C19.58 32 16 28.42 16 24C16 19.58 19.58 16 24 16C28.42 16 32 19.58 32 24C32 28.42 28.42 32 24 32Z" />
    </svg>
  );
};

export { FilterVintageIcon };
