import React from 'react';

import { Text, Tooltip } from '../../../components';
import { CopyIcon } from '../../../components/Icons';
import { useSnackbar } from '../../../components/Snackbar';

import { useCopyToClipboard } from '../../../hooks';

import css from './index.css';

const PendingAttributionsAddresses = ({
  addresses,
}: {
  addresses: string[];
}) => {
  const { addSnackbar } = useSnackbar();
  const [copyToClipboard] = useCopyToClipboard();

  return (
    <div className={css.addressesContainer}>
      <Text size="small" type="body" className={css.addressesLabel}>
        From
      </Text>
      <ul>
        {addresses.map((address) => (
          <Tooltip
            key={address}
            className={css.addressesAddressTooltip}
            background="light"
            onClick={() => {
              copyToClipboard(address);
              addSnackbar({
                type: 'success',
                text: `Address copied to clipboard`,
              });
            }}
            title={
              <div className={css.addressesAddressTooltipText}>
                <Text size="small" className={css.addressesAddressTooltipTitle}>
                  Click to copy address
                </Text>
                <Text size="small">{address}</Text>
              </div>
            }
          >
            <>
              <CopyIcon className={css.addressesAddressTooltipIcon} />
              <Text
                tag="li"
                type="mono"
                size="small"
                className={css.addressesAddress}
              >
                {address}
              </Text>
            </>
          </Tooltip>
        ))}
      </ul>
    </div>
  );
};

export default PendingAttributionsAddresses;
