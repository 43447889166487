import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { bodyVariants } from 'variants/body-footer-variants';

/**
 * Body component of the modal.
 */
export const Body: FC<PropsWithChildren> = ({ children }) => {
  return <div className={cn(bodyVariants())}>{children}</div>;
};
