// Utils
import cn from 'classnames';
import React from 'react';

// Components
import { Text } from '../../../components';
import { Container, Header } from '../../../components/Card/_components';

// Styles
import css from './index.css';

// Types
import type { CardProps } from './_types';

const Card = ({
  className = '',
  title,
  TitleIcon,
  iconClassName,
  children,
  disabled,
  ...restProps
}: CardProps) => {
  const classes = cn({
    [css.cardRoot]: true,
    [className]: Boolean(className),
  });

  const iconClasses = cn({
    [css.icon]: true,
    [iconClassName]: Boolean(iconClassName),
  });

  return (
    <Container className={classes} disabled={disabled} {...restProps}>
      <Header className={css.header}>
        <div className={css.headerText}>
          {TitleIcon ? <TitleIcon className={iconClasses} /> : null}
          <Text>{title}</Text>
        </div>
      </Header>
      {children}
    </Container>
  );
};

Card.displayName = 'DetailsCard';

export default Card;
