import cn from 'classnames';

const LocalLaundryServiceIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalLaundryService', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18.34 33.66C21.46 36.78 26.53 36.78 29.65 33.66C32.77 30.54 32.77 25.47 29.65 22.35L18.34 33.66ZM36 4.02L12 4C9.79 4 8 5.79 8 8V40C8 42.21 9.79 44 12 44H36C38.21 44 40 42.21 40 40V8C40 5.79 38.21 4.02 36 4.02ZM20 8C21.1 8 22 8.9 22 10C22 11.1 21.1 12 20 12C18.9 12 18 11.1 18 10C18 8.9 18.9 8 20 8ZM14 8C15.1 8 16 8.9 16 10C16 11.1 15.1 12 14 12C12.9 12 12 11.1 12 10C12 8.9 12.9 8 14 8ZM24 40C17.37 40 12 34.63 12 28C12 21.37 17.37 16 24 16C30.63 16 36 21.37 36 28C36 34.63 30.63 40 24 40Z" />
    </svg>
  );
};

export { LocalLaundryServiceIcon };
