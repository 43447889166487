import cn from 'classnames';

const ColorLensIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ColorLens', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 6C14.06 6 6 14.06 6 24C6 33.94 14.06 42 24 42C25.66 42 27 40.66 27 39C27 38.22 26.71 37.52 26.22 36.99C25.75 36.46 25.47 35.77 25.47 35C25.47 33.34 26.81 32 28.47 32H32C37.52 32 42 27.52 42 22C42 13.16 33.94 6 24 6ZM13 24C11.34 24 10 22.66 10 21C10 19.34 11.34 18 13 18C14.66 18 16 19.34 16 21C16 22.66 14.66 24 13 24ZM19 16C17.34 16 16 14.66 16 13C16 11.34 17.34 10 19 10C20.66 10 22 11.34 22 13C22 14.66 20.66 16 19 16ZM29 16C27.34 16 26 14.66 26 13C26 11.34 27.34 10 29 10C30.66 10 32 11.34 32 13C32 14.66 30.66 16 29 16ZM35 24C33.34 24 32 22.66 32 21C32 19.34 33.34 18 35 18C36.66 18 38 19.34 38 21C38 22.66 36.66 24 35 24Z" />
    </svg>
  );
};

export { ColorLensIcon };
