import cn from 'classnames';

const BorderColorIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BorderColor', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_6_9313)">
        <path d="M35.5 14L28 6.5L8 26.5V34H15.5L35.5 14ZM41.41 8.09C42.19 7.31001 42.19 6.04 41.41 5.26L36.74 0.590005C35.96 -0.189995 34.69 -0.189995 33.91 0.590005L30 4.5L37.5 12L41.41 8.09Z" />
        <path opacity="0.3" d="M0 40H48V48H0V40Z" />
      </g>
      <defs>
        <clipPath id="clip0_6_9313">
          <rect width="48" height="48" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { BorderColorIcon };
