import cn from 'classnames';

const LocalTaxiIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalTaxi', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M37.84 12.02C37.43 10.84 36.31 10 35 10H30V6H18V10H13C11.69 10 10.57 10.84 10.16 12.02L6 24V40C6 41.1 6.9 42 8 42H10C11.11 42 12 41.1 12 40V38H36V40C36 41.1 36.9 42 38 42H40C41.11 42 42 41.1 42 40V24L37.84 12.02ZM13 32C11.34 32 10 30.66 10 29C10 27.34 11.34 26 13 26C14.66 26 16 27.34 16 29C16 30.66 14.66 32 13 32ZM35 32C33.34 32 32 30.66 32 29C32 27.34 33.34 26 35 26C36.66 26 38 27.34 38 29C38 30.66 36.66 32 35 32ZM10 22L13 13H35L38 22H10Z" />
    </svg>
  );
};

export { LocalTaxiIcon };
