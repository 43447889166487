// Components
// Utils
import _get from 'lodash/get';

// Types
import type { OperationDrawer } from 'generated/graphql';

import TitleAndSubtitle from '../../../shared/TitleAndSubtitle';

type VoteOperation = Extract<
  OperationDrawer.operation,
  { __typename: 'VoteOperation' }
>;

type Props = {
  operation: VoteOperation;
};

const CeloGovernanceVote = ({ operation }: Props) => {
  const vote = _get(
    operation,
    'extraParams.celoGovernanceVoteExtraParams.voteOption',
    '',
  );

  const title = `"${vote}" on "${_get(
    operation,
    'details.celoProposal.title',
    '-',
  )}"`;

  if (vote) {
    return <TitleAndSubtitle title={title} subtitle="Governance Poll" />;
  }

  return null;
};

export default CeloGovernanceVote;
