const TrustedDestinationEmptyState = (props: SVGProps): JSX.Element => {
  const { title = 'TrustedDestinationEmptyState', ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 210 157"
      {...restProps}
      fill="none"
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_3163_60765)">
        <path
          fill="transparent"
          stroke="#F4F5F5"
          strokeMiterlimit="10"
          strokeWidth="1.976"
          d="M4.892 1.36h176.137a3.703 3.703 0 013.696 3.696v138.649H1.196V5.055A3.703 3.703 0 014.892 1.36zM16.512 126.196H5.633M25.316 119.24H5.633M25.266 26.448l-19.682.257M103.879 22.88l-54.375.15M69.097 16.715l-19.692.257M96.775 64.49l-10.89.138M119.481 64.49l-10.879.138M69.097 64.43l-19.692.257M160.131 64.43l-19.683.257M25.316 112.293H5.633M25.316 105.347H5.633M17.797 98.39H5.633M37.834 1.498v142.207"
        />
        <path
          fill="#F4F5F5"
          d="M25.78 14.087L15.652 7.595 5.515 14.087l10.137 6.482 10.128-6.482z"
        />
        <path
          stroke="#F4F5F5"
          strokeMiterlimit="10"
          strokeWidth="1.976"
          d="M1.196 37.188h36.896M49.405 37.188h24.02M78.623 37.188h24.01M1.196 49.806h36.896M1.196 62.424h36.896"
        />
        <path
          fill="inherit"
          d="M178.232 45.498c.998 0 1.798.692 1.798 1.542v7.529c0 .85-.81 1.541-1.798 1.541H45.689c-.998 0-1.798-.691-1.798-1.541v-7.53c0-.849.81-1.54 1.798-1.54h132.543z"
        />
        <path
          stroke="#F4F5F5"
          strokeMiterlimit="10"
          strokeWidth="1.976"
          d="M49.405 72.533h124.549M92.96 50.735l-7.074.148M124.56 50.735l-15.958.148M61.578 50.676l-12.173.257M154.054 50.676l-13.606.257"
        />
        <path
          fill="inherit"
          d="M184.161 156.372c14.156 0 25.631-11.476 25.631-25.631 0-14.156-11.475-25.631-25.631-25.631s-25.631 11.475-25.631 25.631c0 14.155 11.475 25.631 25.631 25.631z"
        />
        <path
          stroke="#272525"
          strokeMiterlimit="10"
          strokeWidth="2.174"
          d="M184.161 118.844v23.793M196.057 130.741h-23.793"
        />
      </g>
      <defs>
        <clipPath id="clip0_3163_60765">
          <path
            fill="#fff"
            d="M0 0H209.584V156H0z"
            transform="translate(.208 .372)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { TrustedDestinationEmptyState };
