import cn from 'classnames';

const ChildCareIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ChildCare', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M29 23.5C30.3807 23.5 31.5 22.3807 31.5 21C31.5 19.6193 30.3807 18.5 29 18.5C27.6193 18.5 26.5 19.6193 26.5 21C26.5 22.3807 27.6193 23.5 29 23.5Z" />
      <path d="M19 23.5C20.3807 23.5 21.5 22.3807 21.5 21C21.5 19.6193 20.3807 18.5 19 18.5C17.6193 18.5 16.5 19.6193 16.5 21C16.5 22.3807 17.6193 23.5 19 23.5Z" />
      <path d="M45.89 25.33C45.96 24.9 46 24.45 46 24C46 23.55 45.96 23.1 45.89 22.67C45.38 19.65 43.17 17.2 40.28 16.33C39.23 14.1 37.72 12.12 35.89 10.51C32.72 7.71 28.56 6 24 6C19.44 6 15.28 7.71 12.11 10.51C10.28 12.13 8.78 14.1 7.72 16.33C4.83 17.19 2.62 19.64 2.11 22.67C2.04 23.1 2 23.55 2 24C2 24.45 2.04 24.9 2.11 25.33C2.62 28.35 4.83 30.8 7.72 31.67C8.76 33.88 10.25 35.84 12.06 37.45C15.24 40.27 19.41 42 24 42C28.59 42 32.76 40.27 35.94 37.45C37.75 35.84 39.24 33.88 40.28 31.67C43.17 30.8 45.38 28.35 45.89 25.33ZM38 28C37.8 28 37.61 27.97 37.43 27.94C37.04 29.27 36.45 30.52 35.71 31.66C33.2 35.47 28.89 38 24 38C19.11 38 14.8 35.47 12.3 31.66C11.55 30.52 10.97 29.27 10.58 27.94C10.39 27.97 10.2 28 10 28C7.8 28 6 26.2 6 24C6 21.8 7.8 20 10 20C10.2 20 10.39 20.03 10.57 20.06C10.96 18.73 11.55 17.48 12.29 16.34C14.8 12.53 19.11 10 24 10C28.89 10 33.2 12.53 35.7 16.34C36.45 17.48 37.03 18.73 37.42 20.06C37.61 20.03 37.8 20 38 20C40.2 20 42 21.8 42 24C42 26.2 40.2 28 38 28ZM15 28C16.52 31.53 19.97 34 24 34C28.03 34 31.48 31.53 33 28H15Z" />
    </svg>
  );
};

export { ChildCareIcon };
