import { ToastViewport } from './ToastViewport';
import { ToasterContent } from './ToasterContent';

/**
 * Renders the Toaster component.
 * The Toaster component displays toast notifications.
 */
export const Toaster = () => {
  return (
    <>
      <ToasterContent />
      <ToastViewport />
    </>
  );
};
