import cn from 'classnames';

const DoneIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Done', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M18 32.3399L9.65996 23.9999L6.82996 26.8299L18 37.9999L42 13.9999L39.17 11.1699L18 32.3399Z" />
    </svg>
  );
};

export { DoneIcon };
