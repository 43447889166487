import cn from 'classnames';

const GpsOffIcon = (props: SVGProps): JSX.Element => {
  const { title = 'GpsOff', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M41.88 22C40.96 13.66 34.34 7.04 26 6.12V2H22V6.12C19.74 6.37 17.62 7.05 15.69 8.06L18.69 11.06C20.33 10.38 22.12 10 24 10C31.73 10 38 16.27 38 24C38 25.88 37.62 27.67 36.95 29.31L39.95 32.31C40.96 30.38 41.63 28.26 41.88 26H46V22H41.88ZM6 8.55L10.07 12.62C7.93 15.24 6.51 18.46 6.12 22H2V26H6.12C7.04 34.34 13.66 40.96 22 41.88V46H26V41.88C29.54 41.49 32.76 40.06 35.38 37.92L39.45 42L42 39.46L8.55 6L6 8.55ZM32.53 35.08C30.17 36.9 27.22 38 24 38C16.27 38 10 31.73 10 24C10 20.78 11.1 17.83 12.92 15.47L32.53 35.08Z" />
    </svg>
  );
};

export { GpsOffIcon };
