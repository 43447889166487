import { ApolloError } from '@apollo/client';
import { useRouter } from 'next/router';

import { useAnalytics } from '@anchorage/analytics';
import { ActionResult } from '@anchorage/analytics/src/lib/constants';
import { useSnackbar } from '@anchorage/common/dist/components/Snackbar';
import { useAppUser } from '@anchorage/frontoffice/contexts/AppUser';
import { getGraphQLQueryName } from '@anchorage/frontoffice/utils/getGraphQLQueryName';

import {
  AutoCompoundEnabledDocument,
  VaultWalletsDocument,
  WalletStakingDetailsDocument,
  useUpdateAutoCompoundMutation,
} from 'generated/graphql';

import useStandardizedStakingContext from '../../StandardizedStakingContext/useStandardizedStakingContext';
import { StandardizedStakingMode } from '../../constants';

interface HookProps {
  onCompleted?: () => void;
  onError?: (error: ApolloError) => void;
  showSnackbarNotifications?: boolean;
  newAutoCompoundSettingValue: boolean;
  analyticsEventName: string;
}

const useUpdateAutoCompound = ({
  newAutoCompoundSettingValue,
  analyticsEventName,
  showSnackbarNotifications = true,
  onCompleted = () => {},
  onError,
}: HookProps) => {
  const router = useRouter();
  const { addSnackbar } = useSnackbar();
  const { track } = useAnalytics();
  const stakingMode =
    newAutoCompoundSettingValue === true
      ? StandardizedStakingMode.ENABLE_AUTO_COMPOUND
      : StandardizedStakingMode.DISABLE_AUTO_COMPOUND;

  const vaultSubID = router.query.vaultID as string;

  const {
    organization: { organizationKeyID },
  } = useAppUser();

  const { walletAsset, assetTypeInfo } = useStandardizedStakingContext();
  const assetTypeID = assetTypeInfo.assetTypeID;
  const assetSubID = walletAsset.assetID.assetSubID;

  return useUpdateAutoCompoundMutation({
    variables: {
      autoCompoundEnabled: newAutoCompoundSettingValue,
      organizationID: organizationKeyID,
      vaultSubID,
      assetTypeID,
      assetSubID,
    },
    refetchQueries: [
      getGraphQLQueryName(WalletStakingDetailsDocument),
      getGraphQLQueryName(VaultWalletsDocument),
      getGraphQLQueryName(AutoCompoundEnabledDocument),
    ],
    onCompleted: () => {
      showSnackbarNotifications &&
        addSnackbar({
          type: 'success',
          text: `Auto-claim & stake rewards ${newAutoCompoundSettingValue ? 'started' : 'stopped'}`,
        });
      track({
        name: analyticsEventName,
        data: {
          stakingType: stakingMode,
          status: ActionResult.SUCCESS,
          assetTypeID,
        },
      });
      onCompleted();
    },
    onError: (error) => {
      showSnackbarNotifications &&
        addSnackbar({
          type: 'error',
          text: error.message || 'There was an updating auto-claim rewards',
          subtext: 'Please try again',
        });

      onError && onError(error);

      track({
        name: analyticsEventName,
        data: {
          stakingType: stakingMode,
          status: ActionResult.FAIL,
          assetTypeID,
        },
      });
    },
  });
};

export default useUpdateAutoCompound;
