import { Button } from '@latitude/button';
import { CloseIcon } from '@latitude/icons';
import * as ToastPrimitives from '@radix-ui/react-toast';
import cn from 'classnames';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

/**
 * A component that represents the close button for a toast.
 *
 * @component
 * @example
 * ```tsx
 * <ToastClose className="custom-class" onClick={handleClose} />
 * ```
 */
export const ToastClose = forwardRef<
  ElementRef<typeof ToastPrimitives.Close>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn([], className)}
    toast-close=""
    asChild
    {...props}
  >
    <Button
      data-testid="toast-close"
      variant="strong"
      type="ghost"
      size="small"
      className="!text-current"
      leadingElement={<CloseIcon className="!fill-current" />}
    />
  </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;
