import cn from 'classnames';

const CardGiftcardIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CardGiftcard', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 12H35.63C35.85 11.37 36 10.7 36 10C36 6.69 33.31 4 30 4C27.91 4 26.07 5.07 25 6.69L24 8.05L23 6.69C21.93 5.07 20.09 4 18 4C14.69 4 12 6.69 12 10C12 10.7 12.14 11.37 12.37 12H8C5.79 12 4.02 13.79 4.02 16L4 38C4 40.21 5.79 42 8 42H40C42.21 42 44 40.21 44 38V16C44 13.79 42.21 12 40 12ZM30 8C31.1 8 32 8.9 32 10C32 11.1 31.1 12 30 12C28.9 12 28 11.1 28 10C28 8.9 28.9 8 30 8ZM18 8C19.1 8 20 8.9 20 10C20 11.1 19.1 12 18 12C16.9 12 16 11.1 16 10C16 8.9 16.9 8 18 8ZM40 38H8V34H40V38ZM40 28H8V16H18.16L14 21.67L17.25 24L22 17.53L24 14.81L26 17.53L30.75 24L34 21.67L29.84 16H40V28Z" />
    </svg>
  );
};

export { CardGiftcardIcon };
