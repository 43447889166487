import cn from 'classnames';

const CallMissedOutgoingIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CallMissedOutgoing', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M6 16.82L24 34.82L38 20.82V30H42V14H26V18H35.18L24 29.18L8.82 14L6 16.82Z" />
    </svg>
  );
};

export { CallMissedOutgoingIcon };
