import { Text } from '@anchorage/common/dist/components';
import { STEP_VARIANTS } from '@anchorage/common/dist/components/StepTracker/_constants';
import { formatDateTime } from '@anchorage/common/dist/utils/dates';

import { StateType } from 'generated/graphql';
import type { OperationDrawer } from 'generated/graphql';

type SimpleOperationStateItem = OperationDrawer.simpleOperationStateDiagram;

export const getStepVariant = (stateType: StateType) => {
  switch (stateType) {
    case StateType.PAST:
    case StateType.COMPLETE:
      return STEP_VARIANTS.complete;
    case StateType.PRESENT:
      return STEP_VARIANTS.active;
    case StateType.FAILED:
      return STEP_VARIANTS.error;
    case StateType.FUTURE:
      return STEP_VARIANTS.incomplete;
    default:
      return STEP_VARIANTS.warning;
  }
};

type SimpleStateDiagramItem = Omit<SimpleOperationStateItem, 'children'> &
  Partial<Pick<SimpleOperationStateItem, 'children'>>;

export const parseDescription = (state: SimpleStateDiagramItem) => {
  const { time, details: tempDetails, children } = state;

  const details = children && children.length > 0 ? null : tempDetails;
  if (!time && !details) {
    return null;
  }

  if (time && details) {
    return (
      <>
        <Text size="small">{details}</Text>
        <Text size="small">{formatDateTime(time)}</Text>
      </>
    );
  }

  return <Text size="small">{time ? formatDateTime(time) : details}</Text>;
};

export const generateProgressSteps = (
  simpleStateDiagram: SimpleOperationStateItem[],
) => {
  return simpleStateDiagram.map((state) => {
    return {
      variant: getStepVariant(state.stateType),
      title: state.stateDescription,
      description: parseDescription(state),
      // @ts-ignore Property 'children' does not exist on type 'simpleOperationStateDiagram'.
      substeps: state?.children?.map((child) => {
        return {
          variant: getStepVariant(child.stateType),
          title: child.stateDescription,
          description: parseDescription(child),
        };
      }),
    };
  });
};
