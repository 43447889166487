import cn from 'classnames';

const DnsIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Dns', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 26H8C6.9 26 6 26.9 6 28V40C6 41.1 6.9 42 8 42H40C41.1 42 42 41.1 42 40V28C42 26.9 41.1 26 40 26ZM14 38C11.79 38 10 36.21 10 34C10 31.79 11.79 30 14 30C16.21 30 18 31.79 18 34C18 36.21 16.21 38 14 38ZM40 6H8C6.9 6 6 6.9 6 8V20C6 21.1 6.9 22 8 22H40C41.1 22 42 21.1 42 20V8C42 6.9 41.1 6 40 6ZM14 18C11.79 18 10 16.21 10 14C10 11.79 11.79 10 14 10C16.21 10 18 11.79 18 14C18 16.21 16.21 18 14 18Z" />
    </svg>
  );
};

export { DnsIcon };
