import cn from 'classnames';

const AirlineSeatLegroomNormalIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AirlineSeatLegroomNormal', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M10 24V6H6V24C6 29.52 10.48 34 16 34H28V30H16C12.69 30 10 27.31 10 24ZM41 36H38V22C38 19.79 36.21 18 34 18H24V6H12V22C12 25.3 14.7 28 18 28H32V42H41C42.66 42 44 40.66 44 39C44 37.34 42.66 36 41 36Z" />
    </svg>
  );
};

export { AirlineSeatLegroomNormalIcon };
