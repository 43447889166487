// Types
import { Spacing } from '../../hooks/useSpacing';

import { Props as ButtonProps } from '../Button';

type Icon = (props?: any) => JSX.Element;

export type BannerIconProps = SVGProps & {
  Icon?: Icon;
};

export type BannerActionType = Pick<ButtonProps, 'isLoading' | 'onClick'> & {
  text: string;
};

export type BannerActions =
  | [BannerActionType]
  | [BannerActionType, BannerActionType];

export type BannerActionsProps = {
  actions: BannerActions;
};

export type BannerType = 'success' | 'info' | 'warning' | 'error';

export type SimpleBannerProps = React.HTMLAttributes<HTMLDivElement> & {
  actions?: BannerActions;
  closeable?: boolean;
  type?: BannerType;
  title: string;
  Icon?: Icon;
  spacing?: Spacing;
};

export type BannerProps = SimpleBannerProps & {
  onClose?: () => void;
  isVisible?: boolean;
};

export type BannerContextProps = {
  type: BannerType;
  onClose?: () => void;
  hasActions: boolean;
  setHasActions: React.Dispatch<React.SetStateAction<boolean>>;
};

export type BannerWrapperProps = React.HTMLAttributes<HTMLDivElement> & {
  isVisible?: boolean;
  onClose?: () => void;
  type?: BannerType;
  spacing?: Spacing;
};
