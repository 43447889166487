import cn from 'classnames';

const AllInclusiveIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AllInclusive', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M37.2 13.25C34.32 13.25 31.6 14.37 29.65 16.32L24 21.32L23.99 21.31L20.96 23.98L20.98 24L15.59 28.78C14.31 30.05 12.61 30.75 10.8 30.75C7.06 30.75 4.02 27.72 4.02 24C4.02 20.28 7.06 17.25 10.8 17.25C12.61 17.25 14.31 17.95 15.68 19.31L17.95 21.32L20.97 18.65L18.44 16.41C16.4 14.38 13.69 13.26 10.8 13.26C4.84 13.25 0 18.07 0 24C0 29.93 4.84 34.75 10.8 34.75C13.68 34.75 16.39 33.63 18.35 31.69L24 26.68L24.01 26.69L27.03 24.01L27.02 24L32.41 19.23C33.69 17.95 35.39 17.25 37.2 17.25C40.94 17.25 43.98 20.28 43.98 24C43.98 27.72 40.94 30.75 37.2 30.75C35.39 30.75 33.69 30.05 32.32 28.7L30.05 26.69L27.03 29.37L29.56 31.61C31.6 33.64 34.31 34.75 37.19 34.75C43.16 34.75 48 29.93 48 24C48 18.07 43.16 13.25 37.2 13.25Z" />
    </svg>
  );
};

export { AllInclusiveIcon };
