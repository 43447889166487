import cn from 'classnames';

const DialerSipIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DialerSip', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M34 6H32V16H34V6ZM30 10H26V8H30V6H24V12H28V14H24V16H30V10ZM36 6V16H38V12H42V6H36ZM40 10H38V8H40V10ZM40 31C37.51 31 35.11 30.6 32.86 29.86C32.17 29.64 31.38 29.8 30.83 30.35L26.43 34.76C20.77 31.88 16.14 27.25 13.25 21.59L17.65 17.18C18.2 16.63 18.36 15.84 18.14 15.15C17.4 12.9 17 10.49 17 8C17 6.89 16.11 6 15 6H8C6.89 6 6 6.89 6 8C6 26.78 21.22 42 40 42C41.11 42 42 41.11 42 40V33C42 31.89 41.11 31 40 31Z" />
    </svg>
  );
};

export { DialerSipIcon };
