import useIsPortoApp from '../../utils/useIsPortoApp';

import { SideNavProps } from './types';

import { ORG_OPERATOR_TYPES } from '../../constants/app';
import { default as ADBSideNav } from './ADBSideNav';
import { default as PortoSideNav } from './PortoSideNav';

const SideNav = (props: SideNavProps) => {
  const { organization } = props;
  // TODO remove this fallback once Porto domain is setup
  const isOrgPorto =
    organization.operator === (ORG_OPERATOR_TYPES.SELF as string);
  const isDomainPorto = useIsPortoApp();

  if (isDomainPorto || isOrgPorto) {
    return <PortoSideNav {...props} />;
  }

  return <ADBSideNav {...props} />;
};
export default SideNav;
