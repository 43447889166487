import {
  StandardizedClaimRewardsFlowWizardPage,
  StandardizedStakeFlowWizardPage,
  StandardizedUnstakeFlowWizardPage,
} from '../constants';

export const getStandardizedStakeFlowPageEventName = (
  page: StandardizedStakeFlowWizardPage,
) => {
  switch (page) {
    case StandardizedStakeFlowWizardPage.FIRST_TIME_STAKING:
      return 'StandardizedStakeIntroPage';
    case StandardizedStakeFlowWizardPage.DISCLOSURE:
      return 'StandardizedDisclosurePage';
    case StandardizedStakeFlowWizardPage.STAKE:
      return 'StandardizedStakeFormPage';
    case StandardizedStakeFlowWizardPage.REVIEW:
      return 'StandardizedStakeReviewPage';
    default:
      return '';
  }
};

export const getStandardizedUnstakeFlowPageEventName = (
  page: StandardizedUnstakeFlowWizardPage,
) => {
  switch (page) {
    case StandardizedUnstakeFlowWizardPage.INTRO:
      return 'StandardizedUnstakeIntroPage';
    case StandardizedUnstakeFlowWizardPage.UNSTAKE:
      return 'StandardizedUnstakeFormPage';
    case StandardizedUnstakeFlowWizardPage.REVIEW:
      return 'StandardizedUnstakeReviewPage';
    default:
      return '';
  }
};

export const getStandardizedClaimRewardsFlowPageEventName = (
  page: StandardizedClaimRewardsFlowWizardPage,
) => {
  switch (page) {
    case StandardizedClaimRewardsFlowWizardPage.CLAIM_REWARDS:
      return 'StandardizedClaimRewardsFormPage';
    case StandardizedClaimRewardsFlowWizardPage.REVIEW:
      return 'StandardizedClaimRewardsReviewPage';
    default:
      return '';
  }
};
