import cn from 'classnames';

const HotTubIcon = (props: SVGProps): JSX.Element => {
  const { title = 'HotTub', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M14 16C16.2091 16 18 14.2091 18 12C18 9.79086 16.2091 8 14 8C11.7909 8 10 9.79086 10 12C10 14.2091 11.7909 16 14 16Z" />
      <path d="M22.3 24C21.69 23.55 21.13 23.07 20.66 22.56L17.86 19.46C17.48 19.04 17 18.7 16.48 18.46C15.9 18.18 15.24 18 14.56 18H14.5C12.02 18 10 20.02 10 22.5V24H4V40C4 42.21 5.79 44 8 44H40C42.21 44 44 42.21 44 40V24H22.3ZM14 40H10V28H14V40ZM22 40H18V28H22V40ZM30 40H26V28H30V40ZM38 40H34V28H38V40ZM37.31 11.71L37.17 11.58C36.02 10.35 35.54 8.76 35.83 7.19L36 6H32.22L32.09 6.85C31.68 9.57 32.63 12.28 34.69 14.28L34.82 14.4C35.97 15.63 36.46 17.23 36.17 18.8L35.95 20H39.78L39.91 19.15C40.31 16.43 39.37 13.72 37.31 11.71ZM29.31 11.71L29.17 11.58C28.02 10.35 27.54 8.76 27.83 7.19L28 6H24.22L24.09 6.85C23.68 9.57 24.63 12.28 26.69 14.28L26.82 14.4C27.97 15.63 28.46 17.23 28.17 18.8L27.95 20H31.78L31.91 19.15C32.31 16.43 31.37 13.72 29.31 11.71Z" />
    </svg>
  );
};

export { HotTubIcon };
