import { cva } from 'class-variance-authority';

export const inputVariants = cva([
  'flex-1',
  'relative',
  'p-0',
  'min-w-[56px]',
  'min-h-[16px]',
  'border-0',
  'outline-none',
  'bg-transparent',
  '[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
]);

export const inputLTElementsVariants = cva(
  [
    '[&_>svg]:w-sizing-sys-size-24',
    '[&_>svg]:h-sizing-sys-size-24',
    '[&>svg]:fill-current',
    '[&>svg]:text-current',
    'size-[24px]',
    'flex-none',
    'items-center',
  ],
  {
    variants: {
      isString: {
        true: [
          'text-color-comp-text-field-on-container-enabled',
          'px-spacing-comp-text-field-medium-text-area-vertical',
          'h-auto',
        ],
        false: [],
      },
    },
    defaultVariants: {
      isString: false,
    },
  },
);
