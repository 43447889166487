import cn from 'classnames';

const HearingIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Hearing', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M34 39.9999C33.43 39.9999 32.87 39.8799 32.47 39.6999C31.06 38.9499 30.04 37.9299 29.05 34.9299C28.02 31.8199 26.11 30.3499 24.26 28.9199C22.68 27.6999 21.04 26.4399 19.63 23.8699C18.58 21.9499 18 19.8599 18 17.9999C18 12.3899 22.39 7.99994 28 7.99994C33.61 7.99994 38 12.3899 38 17.9999H42C42 10.1499 35.85 3.99994 28 3.99994C20.15 3.99994 14 10.1499 14 17.9999C14 20.5299 14.76 23.2999 16.13 25.7999C17.95 29.1099 20.1 30.7599 21.83 32.0999C23.45 33.3499 24.62 34.2499 25.26 36.1899C26.46 39.8199 28.01 41.8699 30.71 43.2899C31.75 43.7599 32.85 43.9999 34 43.9999C38.41 43.9999 42 40.4099 42 35.9999H38C38 38.2099 36.21 39.9999 34 39.9999ZM15.27 5.26994L12.44 2.43994C8.46 6.41994 6 11.9199 6 17.9999C6 24.0799 8.46 29.5799 12.44 33.5599L15.27 30.7299C12.01 27.4699 10 22.9699 10 17.9999C10 13.0299 12.01 8.52994 15.27 5.26994ZM23 17.9999C23 20.7599 25.24 22.9999 28 22.9999C30.76 22.9999 33 20.7599 33 17.9999C33 15.2399 30.76 12.9999 28 12.9999C25.24 12.9999 23 15.2399 23 17.9999Z" />
    </svg>
  );
};

export { HearingIcon };
