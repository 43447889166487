import cn from 'classnames';

const CropRotateIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CropRotate', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_6_8798)">
        <path d="M14.93 42.97C8.4 39.87 3.71 33.52 3 26H0C1.02 38.32 11.32 48 23.9 48C24.35 48 24.78 47.96 25.22 47.93L17.6 40.3L14.93 42.97ZM24.1 0C23.65 0 23.22 0.04 22.78 0.07L30.4 7.7L33.06 5.04C39.6 8.13 44.29 14.48 45 22H48C46.98 9.68 36.68 0 24.1 0ZM32 28H36V16C36 13.79 34.21 12 32 12H20V16H32V28ZM16 32V8H12V12H8V16H12V32C12 34.21 13.79 36 16 36H32V40H36V36H40V32H16Z" />
      </g>
      <defs>
        <clipPath id="clip0_6_8798">
          <rect width="48" height="48" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { CropRotateIcon };
