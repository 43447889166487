import cn from 'classnames';

const ImageAspectRatioIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ImageAspectRatio', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M32 20H28V24H32V20ZM32 28H28V32H32V28ZM16 20H12V24H16V20ZM24 20H20V24H24V20ZM40 8H8C5.79 8 4 9.79 4 12V36C4 38.21 5.79 40 8 40H40C42.21 40 44 38.21 44 36V12C44 9.79 42.21 8 40 8ZM40 36H8V12H40V36Z" />
    </svg>
  );
};

export { ImageAspectRatioIcon };
