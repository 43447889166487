import cn from 'classnames';

const AirlineSeatReclineExtraIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AirlineSeatReclineExtra', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M10.71 11.28C8.9 10.01 8.46 7.52002 9.72 5.71002C10.99 3.90002 13.48 3.46002 15.29 4.73002C17.1 6.00002 17.54 8.49002 16.27 10.3C15.01 12.1 12.52 12.54 10.71 11.28ZM32 38H17.86C14.9 38 12.38 35.84 11.93 32.92L8 14H4L7.99 33.51C8.73 38.39 12.93 42 17.87 42H32V38ZM32.46 30H22.7L20.65 21.8C23.8 23.58 27.21 24.89 30.95 24.24V19.99C27.69 20.6 24.07 19.44 21.57 17.5L18.28 14.94C17.83 14.59 17.3 14.34 16.75 14.18C16.12 14 15.44 13.95 14.77 14.06L14.72 14.07C12.27 14.5 10.63 16.83 11.06 19.28L13.77 31.12C14.31 33.95 16.78 36 19.66 36H33.36L41 42L44 39L32.46 30Z" />
    </svg>
  );
};

export { AirlineSeatReclineExtraIcon };
