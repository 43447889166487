import cn from 'classnames';

const Brightness7Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Brightness7', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 17.37V8H30.63L24 1.37L17.37 8H8V17.37L1.37 24L8 30.63V40H17.37L24 46.63L30.63 40H40V30.63L46.63 24L40 17.37ZM24 36C17.37 36 12 30.63 12 24C12 17.37 17.37 12 24 12C30.63 12 36 17.37 36 24C36 30.63 30.63 36 24 36ZM24 16C19.58 16 16 19.58 16 24C16 28.42 19.58 32 24 32C28.42 32 32 28.42 32 24C32 19.58 28.42 16 24 16Z" />
    </svg>
  );
};

export { Brightness7Icon };
