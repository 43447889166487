import { noop } from 'lodash';
import * as React from 'react';

import type { CommonHookParams, CommonHookReturn } from '../../types/hooks';

import type { ButtonGroupWrapperProps } from './_types';

// onChange and default value types are inherited from HTMLDivElement props, we don't want those types here
type UseButtonGroupHookProps<T = any> = {
  defaultValue?: T;
  onChange?: (newVal: T) => void;
};

// Figure out AnchorageComponentHooksV2 with generic types
const useButtonGroupHook = <T,>(
  defaultProps: CommonHookParams<
    ButtonGroupWrapperProps<T>,
    UseButtonGroupHookProps<T>
  >
): CommonHookReturn<ButtonGroupWrapperProps<T>> => {
  const {
    defaultValue,
    onChange = noop,
    type = 'secondary',
    ...restProps
  } = defaultProps;

  const [selectedButton, setSelectedButton] = React.useState<T | undefined>(
    defaultValue
  );

  const groupValueChange = (newVal: T) => {
    onChange(newVal);
    setSelectedButton(newVal);
  };

  return [
    {
      ...restProps,
      type,
      selectedButton,
      setSelectedButton: groupValueChange,
    },
    {
      ...restProps,
      type,
      selectedButton,
      setSelectedButton: groupValueChange,
    },
  ];
};

export default useButtonGroupHook;
