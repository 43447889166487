import { useFeatureFlagsContext } from '@anchorage/feature-flags';
import { FEATURE_FLAGS } from '@anchorage/frontoffice/constants/split';

import type { OperationDrawer } from 'generated/graphql';

import { UpdatedIAMRoleResourceAssignments } from '../../../widgets/IAMRoleChanges';
import User from '../../../widgets/User';
import UserPoliciesList from './UserPoliciesList';

type AddUserAndPoliciesType = Extract<
  OperationDrawer.operation,
  { __typename: 'AddUserAndPoliciesOperation' }
>;

type RemoveUserAndPoliciesType = Extract<
  OperationDrawer.operation,
  { __typename: 'RemoveUserAndPoliciesOperation' }
>;

type Props = {
  operation: AddUserAndPoliciesType | RemoveUserAndPoliciesType;
};

const UpdateUser = ({ operation }: Props) => {
  const { user } = operation;
  const { useIsFeatureActive } = useFeatureFlagsContext();
  const [isIamRoleChangesEnabled] = useIsFeatureActive(
    FEATURE_FLAGS.CD_PERMISSIONS_M3_2,
  );
  const userName = 'name' in user ? user.name : user.secureName;

  return (
    <>
      <User name={userName} role={user.userType} />
      <UserPoliciesList operation={operation} />
      {isIamRoleChangesEnabled &&
        (operation.roleChanges?.changes.length ?? 0) > 0 && (
          <UpdatedIAMRoleResourceAssignments
            changes={operation.roleChanges?.changes ?? []}
          />
        )}
    </>
  );
};

export default UpdateUser;
