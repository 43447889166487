import { ComponentProps, useMemo } from 'react';

import { EventName } from '@anchorage/analytics';
import { OperationPolicies } from '@anchorage/authorization';
import { Card, Text } from '@anchorage/common/dist/components';
import { useDeepCompare } from '@anchorage/common/dist/hooks';

import { getPoliciesMap, getVaultPoliciesMap } from './utils';

import {
  AddUserAndPoliciesOperation,
  OperationImplementations,
  RemoveUserAndPoliciesOperation,
  UpdateVaultPoliciesOperation,
} from 'generated/graphql';

import css from './styles.module.scss';

import SectionRow from '../../shared/SectionRow';
import WidgetWrapper from '../../shared/WidgetWrapper';
import PolicyComponent from './PolicyComponent';
import UpdatedPolicyComponent from './UpdatedPolicyComponent';

type VaultOperationType =
  | UpdateVaultPoliciesOperation['__typename']
  | AddUserAndPoliciesOperation['__typename']
  | RemoveUserAndPoliciesOperation['__typename'];

const Policies = ({
  policies,
  operationType,
  analytics,
}: {
  policies: ReturnType<typeof getPoliciesMap>;
  operationType: ComponentProps<typeof PolicyComponent>['operationType'];
  analytics?: ComponentProps<typeof PolicyComponent>['analytics'];
}) => (
  <>
    {Object.values(policies).map(
      ({ original: originalPolicy, updated: updatedPolicy }, idx) => {
        if (originalPolicy && !updatedPolicy) {
          return (
            <PolicyComponent
              key={originalPolicy.id}
              policy={originalPolicy}
              operationType={operationType}
              analytics={analytics}
            />
          );
        }

        if (updatedPolicy) {
          return (
            <UpdatedPolicyComponent
              key={updatedPolicy.id}
              originalPolicy={originalPolicy}
              updatedPolicy={updatedPolicy}
              title={updatedPolicy.displayName}
              expanded={idx === 0}
              analytics={analytics}
            />
          );
        }

        return <Text key={idx}>-</Text>;
      },
    )}
  </>
);

const isAdminType = (type: keyof typeof OperationImplementations) =>
  [
    OperationImplementations.UpdateOrgPoliciesOperation,
    OperationImplementations.AddUserAndPoliciesOperation,
    OperationImplementations.RemoveUserAndPoliciesOperation,
  ].includes(type as OperationImplementations);

export const OperationDetailPolicyWidget = ({
  policies,
  type,
}: {
  policies: OperationPolicies;
  type: ComponentProps<typeof PolicyComponent>['operationType'];
}) => {
  const policiesMap = useMemo(
    () =>
      getPoliciesMap(
        policies[
          isAdminType(type) ? 'updatedOrgPolicies' : 'updatedVaultPolicies'
        ]?.original ?? [],
        policies[
          isAdminType(type) ? 'updatedOrgPolicies' : 'updatedVaultPolicies'
        ]?.updated ?? [],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare(policies),
  );

  const analytics = useMemo(() => {
    return {
      name: `operation_detail:${
        isAdminType(type) ? 'admin' : 'vault'
      }_policies:accordion_clicked` as EventName,
    };
  }, [type]);

  return (
    <WidgetWrapper>
      <SectionRow
        title={isAdminType(type) ? 'Administrator policies' : 'Vault policies'}
      >
        <Card>
          <Policies
            policies={policiesMap}
            operationType={type}
            analytics={analytics}
          />
        </Card>
      </SectionRow>
    </WidgetWrapper>
  );
};

export const OperationDetailVaultPolicyWidget = ({
  policies,
  type,
}: {
  policies: OperationPolicies;
  type: VaultOperationType;
}) => {
  const policiesMap = useMemo(
    () =>
      getVaultPoliciesMap(
        policies.updatedVaultPolicies?.original ?? [],
        policies.updatedVaultPolicies?.updated ?? [],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare(policies),
  );

  return (
    <WidgetWrapper>
      <SectionRow title="Vault policies">
        {Object.entries(policiesMap).map(([vaultName, policies]) => (
          <Card
            key={vaultName}
            title={
              <Text type="body" size="large">
                {vaultName}
              </Text>
            }
            className={css.vaultCard}
          >
            <Policies
              policies={policies}
              operationType={type}
              analytics={{
                name: 'operation_detail:vault_policies:accordion_clicked',
                vault: vaultName,
              }}
            />
          </Card>
        ))}
      </SectionRow>
    </WidgetWrapper>
  );
};
