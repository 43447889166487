import cn from 'classnames';

const InsertEmoticonIcon = (props: SVGProps): JSX.Element => {
  const { title = 'InsertEmoticon', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M23.99 4C12.94 4 4 12.95 4 24C4 35.05 12.94 44 23.99 44C35.04 44 44 35.05 44 24C44 12.95 35.04 4 23.99 4ZM24 40C15.16 40 8 32.84 8 24C8 15.16 15.16 8 24 8C32.84 8 40 15.16 40 24C40 32.84 32.84 40 24 40ZM31 22C32.66 22 34 20.66 34 19C34 17.34 32.66 16 31 16C29.34 16 28 17.34 28 19C28 20.66 29.34 22 31 22ZM17 22C18.66 22 20 20.66 20 19C20 17.34 18.66 16 17 16C15.34 16 14 17.34 14 19C14 20.66 15.34 22 17 22ZM24 35C28.66 35 32.61 32.09 34.21 28H13.79C15.39 32.09 19.34 35 24 35Z" />
    </svg>
  );
};

export { InsertEmoticonIcon };
