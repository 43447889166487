import cn from 'classnames';

const HighlightIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Highlight', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M12 28L18 34V44H30V34L36 28V18H12V28ZM22 4H26V10H22V4ZM7 11.75L9.828 8.922L14.071 13.165L11.251 15.993L7 11.75ZM33.923 13.172L38.165 8.93L40.995 11.758L36.75 16L33.923 13.172Z" />
    </svg>
  );
};

export { HighlightIcon };
