// Utils
import pluralize from 'pluralize';
import React from 'react';

// Components
import { AssetIcon, Text } from '../../../../../components';
// Hooks
import {
  useFormContext,
} from '../../../../../components/Form/_reactHookForm';
import { HyperlinkIcon } from '../../../../../components/Icons';

import { formatDateTime } from '../../../../../utils/dates';

// Styles
import css from './index.css';

// Types
import type { PendingAttributionRow } from '../_types';
import { FormCheckbox } from '../../../../../components/Form/components';

export const CellCheckbox = ({ checkbox, isDisabled }: PendingAttributionRow) => {
  if (!checkbox || !checkbox.name) {
    return <></>;
  }

  const { control } = useFormContext();

  return (
    <FormCheckbox
      control={control}
      name={checkbox.name}
      data-testid="check-operation-deposit-attribution"
      disabled={isDisabled}
    />
  );
};

export const CellOperationDate = ({
  createdAt,
  addresses,
}: PendingAttributionRow) => {
  const filteredAddresses = addresses?.filter((address) => !!address);

  return(
  <>
    <Text size="small">
      {formatDateTime((new Date(createdAt)).getTime()*1000000)}
    </Text>
    {filteredAddresses && filteredAddresses.length > 0 ? (
      <Text size="small" className={css.addresses}>
        {filteredAddresses.length} {pluralize('address', filteredAddresses.length)}
      </Text>
    ) : null}
  </>
)};
export const CellAccount = ({ accountName }: PendingAttributionRow) => (
  <Text size="small">{accountName}</Text>
);

export const CellAmount = ({ amount }: PendingAttributionRow) => (
  <div className={css.amount}>
    <AssetIcon {...amount.assetTypeInfo} />
    <Text type="mono" size="small">
      {amount.displayValue} {amount.assetTypeInfo.abbreviation}
    </Text>
  </div>
);

export const CellVaultName = ({ vaultName }: PendingAttributionRow) => (
  <Text size="small">{vaultName}</Text>
);

export const CellOperation = ({
  operationId,
  onClickOperationId,
}: PendingAttributionRow) => (
  <div className={css.cellOperation}>
    <Text
      size="small"
      type="link"
      tag="button"
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        onClickOperationId?.(operationId);
      }}
      className={css.operationDetail}
    >
      View details
    </Text>
    <HyperlinkIcon className={css.hyperLinkIcon} />
  </div>
);
