import cn from 'classnames';
import { noop } from 'lodash';
import React, { MouseEvent, useMemo } from 'react';

import { Text } from '../../../components';
// Components
import {
  Container,
  Footer,
  Header,
} from '../../../components/Card/_components';
import { ClockIcon } from '../../../components/Icons';

// Utils & Hooks
import { useDeepCompare } from '../../../hooks';

// Styles
import css from './index.css';

// Types
import type { NFTData } from '../_types';

import ImageMosaic from '../ImageMosaic';
// Constants
import { FlexDirection } from '../_constants';

const MAX_TILES = 9;

// We want to show an overlay when we have 7-8 images not only on 9+
const INTERMEDIATE_TILES = 6;

const CollectionCard: React.FC<NFTData> = (
  { title, images, subtext, footerText, onClick },
) => {
  const hasOverlay = useMemo(
    () =>
      images.length > MAX_TILES
      || (images.length < MAX_TILES && images.length > INTERMEDIATE_TILES),
    useDeepCompare([images]),
  );

  const overlayLimit = useMemo(
    () => (images.length >= MAX_TILES ? MAX_TILES : INTERMEDIATE_TILES),
    useDeepCompare([images]),
  );

  const numOfSubContainers = useMemo(() => {
    if (images.length >= 2 && images.length <= 8) {
      return 2;
    }

    if (images.length >= 9) {
      return 3;
    }

    return 1;
  }, useDeepCompare([images]));

  // If the card has an overlay, images onClicks and a card onClick
  // We want the last "overlayed" image to actually have the same onClick as the card
  const imagesToUse = useMemo(
    () =>
      images.map((image, index) => {
        const shouldReplaceOnClick =
          image.onClick && onClick && hasOverlay && index === overlayLimit - 1;

        if (shouldReplaceOnClick) {
          return {
            ...image,
            onClick: (e: MouseEvent<HTMLImageElement>) => {
              e.stopPropagation();
              onClick();
            },
          };
        }

        return image;
      }),
    useDeepCompare([images, onClick]),
  );

  return (
    <Container
      onClick={onClick ?? noop}
      className={cn({
        [css.card]: true,
        [css.hoverCard]: Boolean(onClick),
      })}
      data-testid="collection-card"
    >
      <Header className={css.header}>
        <div>
          <Text>{title}</Text>
          {subtext && (
            <Text className={css.subtext} size="small">
              {subtext}
            </Text>
          )}
        </div>
        <div className={css.nftCounter}>
          <Text size="small">
            {images.length} {images.length > 1 ? 'NFTs' : 'NFT'}
          </Text>
        </div>
      </Header>
      <div className={css.mosaicContainer}>
        <ImageMosaic
          overlay={{
            hasOverlay,
            overlayText: `+${images.length - overlayLimit}`,
            overlayLimit,
          }}
          images={imagesToUse}
          numOfSubContainers={numOfSubContainers}
          containerDirection={
            images.length > 3 ? FlexDirection.COLUMN : FlexDirection.ROW
          }
          subContainerDirection={
            images.length <= 3 ? FlexDirection.COLUMN : FlexDirection.ROW
          }
        />
      </div>
      <Footer>
        <div className={css.footer}>
          {footerText && (
            <>
              <Text className={css.footerText} size="small">
                {footerText}
              </Text>
              <ClockIcon className={css.icon} />
            </>
          )}
        </div>
      </Footer>
    </Container>
  );
};

export default CollectionCard;
