import cn from 'classnames';

import { Text } from '@anchorage/common/dist/components';
import {
  AddCircleIcon,
  DoNotDisturbIcon,
} from '@anchorage/common/dist/components/Icons';

import css from './styles.module.scss';

const UpdatedUsersRowTitle = ({
  title,
  type,
}: {
  title: string;
  type: 'add' | 'remove' | 'unchanged';
}) => {
  const styles = cn({
    [css.titleContainer!]: true,
    [css.addText!]: type === 'add',
    [css.removeText!]: type === 'remove',
    [css.unchangedText!]: type === 'unchanged',
  });
  return (
    <div className={styles}>
      {type === 'add' && <AddCircleIcon className={css.addIcon} />}
      {type === 'remove' && <DoNotDisturbIcon className={css.removeIcon} />}
      {type === 'unchanged' && '—'}
      <Text size="small">{title}</Text>
    </div>
  );
};

export default UpdatedUsersRowTitle;
