import cn from 'classnames';

const KeyboardIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Keyboard', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 10H8C5.79 10 4.02 11.79 4.02 14L4 34C4 36.21 5.79 38 8 38H40C42.21 38 44 36.21 44 34V14C44 11.79 42.21 10 40 10ZM22 16H26V20H22V16ZM22 22H26V26H22V22ZM16 16H20V20H16V16ZM16 22H20V26H16V22ZM14 26H10V22H14V26ZM14 20H10V16H14V20ZM32 34H16V30H32V34ZM32 26H28V22H32V26ZM32 20H28V16H32V20ZM38 26H34V22H38V26ZM38 20H34V16H38V20Z" />
    </svg>
  );
};

export { KeyboardIcon };
