import { cva } from 'class-variance-authority';

export const inputRootVariants = cva(
  [
    '[&>svg]:fill-current',
    'flex',
    'search-input-none',
    // 'relative',
    'items-center',
    'self-stretch',
    'relative',
    'rounded-radius-comp-form-round',
    //default
    'bg-color-comp-text-field-container-enabled',
    'text-color-comp-text-field-on-container-enabled',
    '[&>input]:text-color-comp-text-field-on-container-enabled',
    //hover
    'hover:bg-color-comp-text-field-container-hover',
    //read-only:
    'has-[input:read-only:not(:disabled):not([role="combobox"]]:bg-color-comp-text-field-container-read-only',
    'has-[input:read-only:not(:disabled):not([role="combobox"]]:text-color-comp-text-field-on-container-selected',
    //focus
    'has-[input:focus]:bg-color-comp-text-field-container-selected',
    'has-[input:focus]:text-color-comp-text-field-on-container-selected',
    //active
    'has-[input:active]:bg-color-comp-text-field-container-selected',
    'has-[input:active]:text-color-comp-text-field-on-container-selected',
    //visited
    'has-[input:visited]:bg-color-comp-text-field-container-entered',
    'has-[input:visited]:text-color-comp-text-field-on-container-selected',
    //disabled
    'has-[input:disabled]:bg-color-comp-text-field-container-disabled',
    'has-[input:disabled]:text-color-comp-text-field-on-container-disabled',
    //error
    'has-[input:invalid]:text-color-comp-text-field-on-container-selected',
    'has-[input[aria-invalid="true"]]:text-color-comp-text-field-on-container-selected',
    //not empty
    'has-[input:not([value=""])]:text-color-comp-text-field-on-container-selected',

    // BORDER WIDTH DEFINITION
    'before:absolute',
    'before:top-0',
    'before:right-0',
    'before:left-0',
    'before:bottom-0',
    'before:content-[""]',
    'before:rounded-radius-comp-form-round',
    'before:border',
    'before:border-solid',
    'before:border-border-comp-text-field',
    //default
    'before:border-color-comp-text-field-border-enabled',
    //hover
    'before:hover:border-color-comp-text-field-border-hover',
    //read-only:
    'before:has-[input:read-only:not(:disabled):not([role="combobox"])]:border-color-comp-text-field-border-read-only',
    //focus
    'before:has-[input:focus]:border-color-comp-text-field-border-selected',
    //active
    'before:has-[input:active]:border-color-comp-text-field-border-selected',
    //visited
    'before:has-[input:visited]:border-color-comp-text-field-border-entered',
    //disabled
    'before:has-[input:disabled]:border-color-comp-text-field-border-disabled',
    //error
    'before:has-[input:invalid]:border-color-comp-text-field-border-danger',
    'before:has-[input[aria-invalid="true"]]:border-color-comp-text-field-border-danger',
    //not empty
  ],
  {
    variants: {
      inputSize: {
        small: [
          'font-comp-text-field-web-small-text-font-family',
          'font-comp-text-field-web-small-text-font-size',
          'font-comp-text-field-web-small-text-font-weight',
          'leading-comp-text-field-web-small-text-line-height',
          'py-spacing-comp-text-field-small-vertical',
          'pl-spacing-comp-text-field-small-horizontal-left',
          'pr-spacing-comp-text-field-small-horizontal-right',
          'text-comp-text-field-web-small-text-font-size',
          // 'min-h-sizing-sys-size-24', //24  (internal height) + 2(border-width)*2 = 32px of height
        ],
        medium: [
          'font-comp-text-field-web-medium-text-font-family',
          'font-comp-text-field-web-medium-text-font-size',
          'font-comp-text-field-web-medium-text-font-weight',
          'leading-comp-text-field-web-medium-text-line-height',
          'py-spacing-comp-text-field-medium-vertical',
          'pl-spacing-comp-text-field-medium-horizontal-left',
          'pr-spacing-comp-text-field-medium-horizontal-right',
          'text-comp-text-field-web-medium-text-font-size',
          // 'min-h-sizing-sys-size-36', //36 (internal height) + 2(border-width)*2 = 40px of height
        ],
      },
    },
    defaultVariants: {
      inputSize: 'medium',
    },
  },
);

export const contentWrapper = cva(
  'flex-2 relative flex w-full flex-row flex-wrap items-center justify-start space-x-1',
  {
    variants: {
      inputSize: {
        small: [
          'py-spacing-comp-text-field-small-text-area-vertical',
          'px-spacing-comp-text-field-small-text-area-horizontal',
          'text-comp-text-field-web-small-text-font-size',
          'gap-spacing-comp-text-field-small-gap',
          'min-h-sizing-sys-size-24',
          'leading-4',
        ],
        medium: [
          'py-spacing-comp-text-field-medium-text-area-vertical',
          'px-spacing-comp-text-field-medium-text-area-horizontal',
          'gap-spacing-comp-text-field-medium-gap',
          'text-comp-text-field-web-medium-text-font-size',
          'min-h-sizing-sys-size-24',
          'leading-6',
        ],
      },
    },
    defaultVariants: {
      inputSize: 'medium',
    },
  },
);
