export const SEPARATOR = '\u00A0\u00A0|\u00A0\u00A0';

export const DRAWER_STYLES = {
  bodyStyle: { padding: '0px 40px 40px 40px' },
  headerStyle: {
    padding: '84px 40px 24px 40px',
    border: 'none',
  },
  footerStyle: {
    padding: '20px 40px 40px 40px',
    border: 'none',
  },
};
