import { WalletStakingDetails } from 'generated/graphql';

// StakingPosition is a position that a Wallet/AssetID has with a validator.
const getStakingPositionsForWallet = (
  positions: WalletStakingDetails.positions[],
) => {
  // The GQL contract accidentally included a list within a list, but there will only ever be a single list within the positions list, so we index into the 0th edge
  // You can confirm this in the backend code at graphqlmonolith/resolvers/stakingresolver/staking.go:Positions
  return (
    (positions[0]?.edges
      .map(({ node }) => node)
      .filter(Boolean) as WalletStakingDetails._node[]) ?? []
  );
};

export default getStakingPositionsForWallet;
