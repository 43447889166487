import cn from 'classnames';

const InvertColorsIcon = (props: SVGProps): JSX.Element => {
  const { title = 'InvertColors', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M35.31 15.86L24 4.54004L12.69 15.86C6.44 22.11 6.44 32.24 12.69 38.49C15.81 41.61 19.91 43.18 24 43.18C28.09 43.18 32.19 41.62 35.31 38.49C41.56 32.24 41.56 22.11 35.31 15.86ZM24 39.17C20.79 39.17 17.78 37.92 15.52 35.65C13.25 33.39 12 30.38 12 27.17C12 23.96 13.25 20.95 15.52 18.68L24 10.2V39.17Z" />
    </svg>
  );
};

export { InvertColorsIcon };
