import cn from 'classnames';

const AttachMoneyIcon = (props: SVGProps): JSX.Element => {
  const { title = 'AttachMoney', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M23.5999 21.8C19.0599 20.62 17.5999 19.41 17.5999 17.51C17.5999 15.33 19.6099 13.8 22.9999 13.8C26.5599 13.8 27.8799 15.5 27.9999 18H32.4199C32.2899 14.55 30.1799 11.41 25.9999 10.38V6H19.9999V10.32C16.1199 11.17 12.9999 13.67 12.9999 17.54C12.9999 22.16 16.8299 24.46 22.3999 25.8C27.4099 27 28.3999 28.75 28.3999 30.63C28.3999 32 27.4299 34.2 22.9999 34.2C18.8799 34.2 17.2499 32.35 17.0399 30H12.6299C12.8799 34.38 16.1499 36.83 19.9999 37.66V42H25.9999V37.7C29.8899 36.95 32.9999 34.7 32.9999 30.59C32.9999 24.93 28.1399 22.99 23.5999 21.8Z" />
    </svg>
  );
};

export { AttachMoneyIcon };
