import cn from 'classnames';

const LocalPrintshopIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalPrintshop', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 16H10C6.69 16 4 18.69 4 22V34H12V42H36V34H44V22C44 18.69 41.31 16 38 16ZM32 38H16V28H32V38ZM38 24C36.89 24 36 23.11 36 22C36 20.89 36.89 20 38 20C39.11 20 40 20.89 40 22C40 23.11 39.11 24 38 24ZM36 6H12V14H36V6Z" />
    </svg>
  );
};

export { LocalPrintshopIcon };
