import cn from 'classnames';

const DoNotDisturbIcon = (props: SVGProps): JSX.Element => {
  const { title = 'DoNotDisturb', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 4C12.95 4 4 12.95 4 24C4 35.05 12.95 44 24 44C35.05 44 44 35.05 44 24C44 12.95 35.05 4 24 4ZM24 40C15.16 40 8 32.84 8 24C8 20.3 9.27 16.91 11.37 14.2L33.8 36.63C31.09 38.73 27.7 40 24 40ZM36.63 33.8L14.2 11.37C16.91 9.27 20.3 8 24 8C32.84 8 40 15.16 40 24C40 27.7 38.73 31.09 36.63 33.8Z" />
    </svg>
  );
};

export { DoNotDisturbIcon };
