import cn from 'classnames';

const BluetoothAudioIcon = (props: SVGProps): JSX.Element => {
  const { title = 'BluetoothAudio', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M28.48 24.02L33.12 28.66C33.68 27.21 34 25.64 34 24C34 22.37 33.69 20.81 33.14 19.37L28.48 24.02ZM39.06 13.43L36.53 15.96C37.78 18.37 38.5 21.1 38.5 24.01C38.5 26.92 37.78 29.64 36.53 32.06L38.93 34.46C40.86 31.36 42 27.73 42 23.83C42 20.01 40.91 16.46 39.06 13.43ZM31.41 15.41L20 4H18V19.17L8.83 10L6 12.83L17.17 24L6 35.17L8.83 38L18 28.83V44H20L31.41 32.59L22.83 24L31.41 15.41ZM22 11.66L25.76 15.42L22 19.17V11.66ZM25.76 32.59L22 36.34V28.82L25.76 32.59Z" />
    </svg>
  );
};

export { BluetoothAudioIcon };
