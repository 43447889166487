import React, { useMemo } from 'react';

// Hooks
import { useDeepCompare } from '../../../hooks';

import transformByOperation from './_utils/transformByOperation';

// Types
import type { PendingAttributionsListProps } from './_types';

// Components
import PendingAttributionsByOperation from './PendingAttributionsByOperation';

const PendingAttributionsList = (
  {
    pendingAttributions,
    onClickOperationId,
    isDisabled = false,
  }: PendingAttributionsListProps,
) => {
  const byOperation = useMemo(
    () => transformByOperation(pendingAttributions),
    useDeepCompare([pendingAttributions]),
  );

  return (
    <PendingAttributionsByOperation
      operations={byOperation}
      onClickOperationId={onClickOperationId}
      isDisabled={isDisabled}
    />
  );
};

export default React.memo(PendingAttributionsList);
