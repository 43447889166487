// Utils
import cn from 'classnames';
import React from 'react';

import commonCss from '../../common.css';

// Styles
import css from '../index.css';

const SingleEmptyPlaceholder: React.FC = () => (
  <div className={css.singlePlaceholderContainer}>
    <div
      className={cn([
        css.singleImagePlaceholder,
        commonCss.mediumShimmerAnimation,
      ])}
    />
    <div className={css.singlePlaceholderTextContainer}>
      <div
        className={cn(
          css.singleTextPlaceholder,
          commonCss.mediumShimmerAnimation
        )}
      />
      <div
        className={cn([
          css.singleSubTextPlaceholder,
          commonCss.mediumShimmerAnimation,
        ])}
      />
    </div>
  </div>
);

export default SingleEmptyPlaceholder;
