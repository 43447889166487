import { Text } from '@anchorage/common/dist/components';
import {
  FingerprintIcon,
  PolicyIcon,
  UserUnionIcon,
} from '@anchorage/common/dist/components/Icons';
import { useAppUser } from '@anchorage/frontoffice/contexts/AppUser';

import css from './styles.module.scss';

const PolicyNotes = () => {
  const { isPortoOrg } = useAppUser();

  return (
    <div className={css.policyNotesContainer}>
      <Text size="small" className={css.policyText}>
        <UserUnionIcon className={css.policyIcon} /> Vault policy
      </Text>
      <Text size="small" className={css.policyText}>
        <FingerprintIcon className={css.policyIcon} /> Biometric authentication
      </Text>
      {!isPortoOrg ? (
        <Text size="small" className={css.policyText}>
          <PolicyIcon className={css.policyIcon} /> Anchorage Digital risk
          review
        </Text>
      ) : null}
    </div>
  );
};

export default PolicyNotes;
