import { Disclosure } from 'generated/graphql';

const isDisclosureAccepted = (
  disclosure: Disclosure | null | undefined,
  accountID: string,
) => {
  return disclosure
    ? disclosure.reviews.some(
        (review) => review.accountID === accountID && review.acceptedAt,
      )
    : false;
};

export default isDisclosureAccepted;
