import { Button } from '@latitude/button';
import { Card } from '@latitude/card';
import Text from '@latitude/text';
import { Tooltip } from '@latitude/tooltip';

import walletHasBlockingOperation from 'components/Vault/shared/utils/walletHasBlockingOperation';

import { useStandardizedStakingModals } from 'hooks/useStandardizedStakingModals';
import useStaticAssetStandardizedStakingSupport from 'hooks/useStaticAssetStandardizedStakingSupport';

import getPositionAmout from '../../utils/getPositionAmout';

import { StakingPositionState, WalletStakingDetails } from 'generated/graphql';

import {
  BLOCKING_OPERATION_IN_PROGRESS,
  STAKING_NO_PERMISSION,
} from '../../../constants';
import useStandardizedStakingContext from '../../StandardizedStakingContext/useStandardizedStakingContext';

interface Props {
  stakingPosition: WalletStakingDetails._node;
}

const ValidatorCard = ({ stakingPosition }: Props) => {
  const { openUnstakeWizard, openClaimRewardsWizard } =
    useStandardizedStakingModals();
  const { walletWithStakingInfo, assetTypeInfo, hasStakingPermission } =
    useStandardizedStakingContext();

  const { staticAssetStakingSupport } =
    useStaticAssetStandardizedStakingSupport({
      assetTypeID: assetTypeInfo.assetTypeID,
    });

  const stakedStateAmount = getPositionAmout(
    stakingPosition.states,
    StakingPositionState.STAKED,
  );

  const allOtherStateAmounts = stakingPosition.states.filter(
    (state) => state.type !== StakingPositionState.STAKED,
  );

  const hasBlockingOperation = walletHasBlockingOperation(
    walletWithStakingInfo,
  );

  const unstakeDisabledHint = !hasStakingPermission
    ? STAKING_NO_PERMISSION
    : hasBlockingOperation
      ? BLOCKING_OPERATION_IN_PROGRESS
      : stakingPosition.unstakeForm?.disabledHint;
  const unstakeButtonDisabled =
    !stakingPosition.unstakeForm || !!unstakeDisabledHint;

  const claimRewardsDisabledHint = !hasStakingPermission
    ? STAKING_NO_PERMISSION
    : hasBlockingOperation
      ? BLOCKING_OPERATION_IN_PROGRESS
      : stakingPosition.claimRewardsForm?.disabledHint;

  const claimRewardsButtonDisabled =
    !stakingPosition.claimRewardsForm || !!claimRewardsDisabledHint;

  let MaybeClaimRewardsButton = null;
  if (staticAssetStakingSupport?.supportsClaimingRewards) {
    MaybeClaimRewardsButton = (
      <Tooltip
        text={
          claimRewardsDisabledHint ||
          'Claim your staking rewards to your wallet'
        }
      >
        <div>
          <Button
            variant="strong"
            type="outline"
            disabled={claimRewardsButtonDisabled}
            onClick={() => {
              openClaimRewardsWizard({
                walletID: walletWithStakingInfo.walletID,
                assetTypeID: assetTypeInfo.assetTypeID,
                validatorDestination: stakingPosition.validatorDestination,
              });
            }}
          >
            Claim
          </Button>
        </div>
      </Tooltip>
    );
  }

  let MaybeUnstakeButton = null;
  if (staticAssetStakingSupport?.supportsUnstaking) {
    MaybeUnstakeButton = (
      <Tooltip text={unstakeDisabledHint || 'Unstake your funds'}>
        <div>
          <Button
            variant="strong"
            type="outline"
            disabled={unstakeButtonDisabled}
            onClick={() => {
              openUnstakeWizard({
                walletID: walletWithStakingInfo.walletID,
                assetTypeID: assetTypeInfo.assetTypeID,
                validatorDestination: stakingPosition.validatorDestination,
              });
            }}
          >
            Unstake
          </Button>
        </div>
      </Tooltip>
    );
  }

  return (
    <Card className="grid grid-cols-[auto_180px]">
      <div className="*:mb-1">
        <Text>{stakingPosition.validatorName}</Text>
        <Text variant="captionRegular" className="text-color-ref-text-muted">
          {stakingPosition.validatorDestination}
        </Text>
        <Text
          variant="headingTiny"
          className="mt-2"
        >{`${stakedStateAmount?.amount.displayValue ?? '0'} ${assetTypeInfo.abbreviation}`}</Text>
        {allOtherStateAmounts.map((state) => {
          return (
            <Text
              key={state.type}
              variant="captionRegular"
              className="text-color-ref-text-muted"
            >{`${state.amount.displayValue} ${assetTypeInfo.abbreviation} ${state.label.toLowerCase()}`}</Text>
          );
        })}
      </div>
      <div className="self-center">
        <div className="flex justify-end gap-2">
          {MaybeClaimRewardsButton}
          {MaybeUnstakeButton}
        </div>
      </div>
    </Card>
  );
};

export default ValidatorCard;
