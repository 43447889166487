import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';
import cn from 'classnames';
import React from 'react';

const CollapsibleRoot = CollapsiblePrimitive.Root;

const CollapsibleContent = React.forwardRef<
  React.ElementRef<typeof CollapsiblePrimitive.CollapsibleContent>,
  React.ComponentPropsWithoutRef<typeof CollapsiblePrimitive.CollapsibleContent>
>(({ className, ...props }, ref) => (
  <CollapsiblePrimitive.CollapsibleContent
    ref={ref}
    className={cn(
      'overflow-hidden data-[state=open]:animate-collapsible-down data-[state=closed]:animate-collapsible-up',
      className,
    )}
    {...props}
  />
));

CollapsibleContent.displayName = 'CollapsibleContent';

export type CollapsibleProps = {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  children: React.ReactNode;
};

const Collapsible = React.forwardRef<
  React.ElementRef<typeof CollapsiblePrimitive.CollapsibleContent>,
  CollapsibleProps
>(({ open, children }, ref) => {
  return (
    <CollapsibleRoot open={open}>
      <CollapsibleContent ref={ref}>{children}</CollapsibleContent>
    </CollapsibleRoot>
  );
});

Collapsible.displayName = 'Collapsible';

export { Collapsible };
