import StepTracker from '@anchorage/common/dist/components/StepTracker';

import { generateProgressSteps } from 'components/shared/OperationDrawer/utils/progressSteps';

import type { StateDiagramFieldsFragment } from 'generated/graphql';

import SectionRow from '../../shared/SectionRow';
import WidgetWrapper from '../../shared/WidgetWrapper';

type Props = {
  stateDiagram: StateDiagramFieldsFragment[];
};

const ProgressTracker = ({ stateDiagram }: Props) => {
  const steps = generateProgressSteps(stateDiagram);

  if (!steps.length) {
    return null;
  }

  return (
    <WidgetWrapper>
      <SectionRow title="Progress">
        <StepTracker steps={steps} />
      </SectionRow>
    </WidgetWrapper>
  );
};

export default ProgressTracker;
