import _uniq from 'lodash/uniq';

import { isNotEmpty } from '../../../utils/filters';

import type {
  AttributionPair,
  DepositAttributionNode,
  PendingAttributionsFormField,
} from './_types';

export const getPairsOperationAddress = (
  checkboxes: Record<string, PendingAttributionsFormField>
): AttributionPair[] =>
  Object.entries(checkboxes)
    .map(([key, cbox]) =>
      cbox.checkbox // if there is a checked checkbox, create one pair per address
        ? cbox.addresses.map((address) => ({
            operationID: key,
            sourceAddress: address,
          }))
        : undefined
    )
    .flat(2)
    .filter(isNotEmpty);

export const countByOperation = (
  checkboxes: Record<string, PendingAttributionsFormField>
) => {
  const checkedOperations = Object.values(checkboxes).filter(
    (cb) => cb.checkbox
  );
  const checkedAddressess = _uniq(
    checkedOperations.map((op) => op.addresses).flat().filter((address) => !!address)
  );

  return {
    operations: checkedOperations.length,
    addresses: checkedAddressess.length,
  };
};

export const transformPendingAttributionsDataByOperation = (
  pendingAttributionsData: DepositAttributionNode[]
) =>
  pendingAttributionsData.map((node) => ({
    [node.key]: node?.attributions.map((attr) => ({
      amount: {
        displayValue: attr?.operation?.amountDeposit?.value,
        assetTypeInfo: attr?.operation?.amountDeposit?.assetTypeInfo,
      },
      vaultName:
        attr?.operation?.receivingBlockchainAddress?.managedAddress
          ?.wallets?.[0]?.vault?.name,
      createdAt: attr?.createdAt,
      operation: { operationID: node.key },
      sourceAddress: attr?.sourceAddress,
      accountName: attr.operation.account?.displayName,
    })),
  }));
