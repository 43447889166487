const EmptyTableFiltersIcon = (props: SVGProps): JSX.Element => {
  const { title = 'EmptyTableFilters', className, ...restProps } = props;

  return (
    <svg
      className={className}
      width="232"
      height="175"
      viewBox="0 0 232 175"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_5270_24808)">
        <path
          d="M98.3798 67.37C97.1398 44.56 78.2498 26.44 55.1198 26.44C31.9898 26.44 13.1098 44.56 11.8698 67.37H11.8398V68.08C11.8198 68.64 11.7998 69.2 11.7998 69.76C11.7998 70.32 11.8098 70.88 11.8398 71.44V144.26H98.4498V67.37H98.3798Z"
          stroke="white"
          strokeWidth="1.17"
          strokeMiterlimit="10"
        />
        <path
          d="M169.76 140.87V159.37C169.76 159.55 169.15 159.7 168.4 159.7H158.26C157.51 159.7 156.9 159.55 156.9 159.37V140.87"
          fill="#5681F6"
        />
        <path
          d="M163.71 95C190.17 95 211.62 73.8857 211.62 47.84C211.62 21.7943 190.17 0.68 163.71 0.68C137.25 0.68 115.8 21.7943 115.8 47.84C115.8 73.8857 137.25 95 163.71 95Z"
          stroke="white"
          strokeWidth="1.35"
          strokeMiterlimit="10"
        />
        <path
          d="M169.76 94.67V158.36C169.76 159.1 169.15 159.7 168.4 159.7H158.26C157.51 159.7 156.9 159.1 156.9 158.36V94.67"
          stroke="white"
          strokeWidth="1.35"
          strokeMiterlimit="10"
        />
        <path
          d="M143.96 159.07C143.96 159.07 158.25 149.09 156.9 127.8L168.63 118.49C168.63 118.49 172.85 113.5 168.63 106.85L142.78 124.62"
          fill="black"
        />
        <path
          d="M143.96 159.07C143.96 159.07 158.25 149.09 156.9 127.8L168.63 118.49C168.63 118.49 172.85 113.5 168.63 106.85L142.78 124.62"
          stroke="white"
          strokeWidth="1.35"
          strokeMiterlimit="10"
        />
        <path
          d="M136.99 147.49V120.77C136.99 112.56 148.93 105.16 148.93 105.16L156.65 100.13"
          stroke="white"
          strokeWidth="1.35"
          strokeMiterlimit="10"
        />
        <path
          d="M136.99 144.6H121.46V173.64C121.46 173.64 144.89 173.77 152.69 173.64C158.2 173.55 166.68 173.18 169.76 160.08V134.94"
          stroke="white"
          strokeWidth="1.35"
          strokeMiterlimit="10"
        />
        <path
          d="M183.411 91.77C183.171 92.57 182.801 93.37 182.241 94.12L169.771 103.12"
          stroke="white"
          strokeWidth="1.35"
          strokeMiterlimit="10"
        />
        <path
          d="M169.12 108.17L186.48 107.7C186.48 107.7 184.71 115.19 179.38 117.18H169.76"
          stroke="white"
          strokeWidth="1.35"
          strokeMiterlimit="10"
        />
        <path
          d="M169.76 117.03L186.48 117.18C186.48 117.18 184.71 124.67 179.38 126.66H169.12"
          stroke="white"
          strokeWidth="1.35"
          strokeMiterlimit="10"
        />
        <path
          d="M169.28 126.67H184.17C184.17 126.67 182.78 132.55 178.6 134.11H169.29"
          stroke="white"
          strokeWidth="1.35"
          strokeMiterlimit="10"
        />
        <path
          d="M144.8 173.63L220.76 173.57C226.63 173.57 231.39 169.44 231.39 163.66C231.39 157.88 226.63 153.2 220.76 153.2H193.56C187.69 153.2 182.93 148.51 182.93 142.74V139.58H169.77"
          stroke="white"
          strokeWidth="1.17"
          strokeMiterlimit="10"
        />
        <path
          d="M145.47 173.63H81.2204V163.31C81.1404 159.88 78.3104 157.09 74.8304 157.09H63.9704C60.4804 157.09 57.6504 154.31 57.6504 150.87C57.6504 147.44 60.4804 144.65 63.9704 144.65H137"
          stroke="white"
          strokeWidth="1.17"
          strokeLinejoin="round"
        />
        <path
          d="M163.711 84.57C184.322 84.57 201.031 68.1254 201.031 47.84C201.031 27.5546 184.322 11.11 163.711 11.11C143.099 11.11 126.391 27.5546 126.391 47.84C126.391 68.1254 143.099 84.57 163.711 84.57Z"
          stroke="white"
          strokeWidth="1.35"
          strokeMiterlimit="10"
        />
        <path
          d="M139.311 47.84C139.311 47.84 161.971 25.31 188.091 47.84C188.091 47.84 165.431 70.37 139.311 47.84Z"
          stroke="white"
          strokeWidth="1.35"
          strokeMiterlimit="10"
        />
        <path
          d="M163.71 56.85C168.769 56.85 172.87 52.8161 172.87 47.84C172.87 42.8639 168.769 38.83 163.71 38.83C158.651 38.83 154.55 42.8639 154.55 47.84C154.55 52.8161 158.651 56.85 163.71 56.85Z"
          fill="#5681F6"
        />
        <path
          d="M36.9905 108.22C36.9905 108.22 36.9905 101.86 43.7805 103.02C50.5705 104.18 52.7405 104.61 52.8805 101.43C53.0205 98.25 55.7705 95.07 62.1205 96.95C68.4805 98.83 75.1205 101.43 76.8505 99.98C78.5805 98.53 79.7405 98.39 78.4405 93.91C77.1405 89.43 84.3605 86.98 89.8505 89.43C95.3405 91.89 112.38 102.29 119.89 108.06C119.89 108.06 36.9805 107.34 36.9805 108.2L36.9905 108.22Z"
          fill="#5681F6"
        />
        <path
          d="M14.5403 63.81C22.5539 63.81 29.0503 57.3137 29.0503 49.3C29.0503 41.2863 22.5539 34.79 14.5403 34.79C6.52662 34.79 0.0302734 41.2863 0.0302734 49.3C0.0302734 57.3137 6.52662 63.81 14.5403 63.81Z"
          fill="#5681F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_5270_24808">
          <rect
            width="231.94"
            height="174.37"
            fill="white"
            transform="translate(0.0302734)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { EmptyTableFiltersIcon };
