import cn from 'classnames';

const CircleWarningIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Warning', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M30 38V18h4v20h-4zM30 42v4h4v-4h-4z"></path>
      <path
        fillRule="evenodd"
        d="M8 32C8 18.745 18.745 8 32 8s24 10.745 24 24-10.745 24-24 24S8 45.255 8 32zm24-20c-11.046 0-20 8.954-20 20s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export { CircleWarningIcon };
