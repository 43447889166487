import React from 'react';

import { useDeepCompare } from '@anchorage/common/dist/hooks';
import { normalizeCanonicalOperationPayload } from '@anchorage/vsp';

import type { OperationDrawer } from 'generated/graphql';

import CanonicalPayloadSection from '../../../shared/CanonicalPayloadSection';
import Transaction from '../../../widgets/Transaction';
import DAppBanner from '../OffChainOperation/DAppBanner';

type GenericOperationType = Extract<
  OperationDrawer.operation,
  { __typename: 'GenericOperation' }
>;

interface GenericOperationProps {
  operation: GenericOperationType;
}

const GenericOperation: React.FC<GenericOperationProps> = ({ operation }) => {
  const {
    operationInfo: { canonicalOperationPayload },
    maybeTransactionID,
    maybeViewTransactionLink,
  } = operation;

  const { fields } = normalizeCanonicalOperationPayload({
    canonicalOperationPayload: canonicalOperationPayload ?? '',
  });

  const dappID = React.useMemo(
    //@ts-ignore
    () => fields.find((field) => field.label === 'App')?.fallbackText,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([fields]),
  );

  return (
    <>
      {dappID ? <DAppBanner dappID={dappID} /> : null}
      {canonicalOperationPayload && (
        <CanonicalPayloadSection
          canonicalOperationPayload={canonicalOperationPayload}
        />
      )}
      <Transaction
        transactionID={maybeTransactionID}
        transactionLink={maybeViewTransactionLink}
      />
    </>
  );
};

export default GenericOperation;
