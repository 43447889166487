import cn from 'classnames';

import { BrandThemeSvgProps } from '../types';

import { BrandThemeColors, BrandThemeEnum } from '../_constants';

export { BrandThemeEnum } from '../_constants';

const AnchorageLogo = (props: BrandThemeSvgProps): JSX.Element => {
  const {
    title = 'AnchorageLogo',
    theme = BrandThemeEnum.Dark,
    color,
    className,
    ...restProps
  } = props;

  const logoColor = color ?? BrandThemeColors[theme];

  return (
    <svg
      className={cn('fill-current', className)}
      viewBox="0 0 100 100"
      fill={logoColor}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 38.4738L34.1485 28.322L50 18.1702L65.8536 28.322L50 38.4738ZM50 81.83L34.1485 71.6782L50 61.5264L65.8536 71.6782L50 81.83ZM11.5762 57.2302L27.4298 67.382L43.2814 57.2302L27.4298 47.0784L11.5762 57.2302ZM72.5723 67.382L56.7187 57.2302L72.5723 47.0784L88.4238 57.2302L72.5723 67.382Z"
      />
    </svg>
  );
};

export { AnchorageLogo };
