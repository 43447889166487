import cn from 'classnames';

const ExposureNeg2Icon = (props: SVGProps): JSX.Element => {
  const { title = 'ExposureNeg2', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M30.09 32.58L35.82 26.45C36.57 25.66 37.26 24.88 37.9 24.1C38.53 23.32 39.08 22.54 39.54 21.77C40 20.99 40.36 20.22 40.61 19.44C40.87 18.66 41 17.87 41 17.07C41 16 40.82 15.03 40.46 14.15C40.1 13.28 39.58 12.53 38.89 11.92C38.2 11.31 37.36 10.84 36.36 10.5C35.36 10.17 34.22 10 32.94 10C31.56 10 30.32 10.21 29.24 10.64C28.16 11.07 27.25 11.65 26.51 12.39C25.77 13.13 25.21 14 24.83 14.99C24.47 15.93 24.29 16.94 24.27 18H28.55C28.56 17.38 28.64 16.79 28.81 16.26C28.99 15.68 29.26 15.18 29.62 14.76C29.98 14.34 30.43 14.02 30.97 13.78C31.52 13.55 32.16 13.43 32.9 13.43C33.51 13.43 34.05 13.53 34.52 13.74C34.99 13.95 35.39 14.23 35.71 14.59C36.03 14.95 36.28 15.39 36.45 15.88C36.62 16.38 36.7 16.92 36.7 17.51C36.7 17.94 36.64 18.38 36.53 18.81C36.42 19.24 36.23 19.71 35.95 20.21C35.67 20.71 35.3 21.26 34.84 21.87C34.38 22.47 33.79 23.16 33.09 23.94L24.74 33.05V36H42V32.58H30.09ZM4 22V26H20V22H4Z" />
    </svg>
  );
};

export { ExposureNeg2Icon };
