export enum FlexDirection {
  COLUMN = 'column',
  ROW = 'row',
}

export enum NFTSorting {
  ALPHABETICAL_ASC = 'alphabeticalAsc',
  ALPHABETICAL_DESC = 'alphabeticalDesc',
  DATE = 'date',
  PRICE_ASC = 'priceAsc',
  PRICE_DESC = 'priceDesc',
  QUANTITY_ASC = 'quantityAsc',
  QUANTITY_DESC = 'quantityDesc',
}

export enum NFTContentViews {
  TABLE = 'table',
  GRID = 'grid',
}

export enum NFTContentNFTViews {
  COLLECTION = 'collection',
  SINGLE = 'single',
}

export const DEFAULT_SEARCH_OPTIONS = [
  {
    title: 'Collection Name',
    subtitle: 'Bored Ape Yacht Club',
  },
  {
    title: 'Ticker',
    subtitle: 'BAYC',
  },
  {
    title: 'Token ID',
    subtitle: '#1234',
  },
];
