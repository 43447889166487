import cn from 'classnames';

const LocalOfferIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LocalOffer', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42.82 23.16L24.83 5.17C24.1 4.45 23.1 4 22 4H8C5.79 4 4 5.79 4 8V22C4 23.11 4.45 24.11 5.18 24.83L23.18 42.83C23.9 43.55 24.9 44 26 44C27.1 44 28.11 43.55 28.83 42.83L42.83 28.83C43.55 28.1 44 27.1 44 26C44 24.89 43.55 23.89 42.82 23.16ZM11 14C9.34 14 8 12.66 8 11C8 9.34 9.34 8 11 8C12.66 8 14 9.34 14 11C14 12.66 12.66 14 11 14Z" />
    </svg>
  );
};

export { LocalOfferIcon };
