import cn from 'classnames';

const Brightness2Icon = (props: SVGProps): JSX.Element => {
  const { title = 'Brightness2', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M20 4C16.35 4 12.94 4.99 10 6.7C15.97 10.16 20 16.6 20 24C20 31.4 15.97 37.84 10 41.3C12.94 43.01 16.35 44 20 44C31.05 44 40 35.05 40 24C40 12.95 31.05 4 20 4Z" />
    </svg>
  );
};

export { Brightness2Icon };
