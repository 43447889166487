import { AssetIcon } from '@anchorage/common/dist/components';
import { truncateString } from '@anchorage/common/dist/utils/format';
import { NFTCard as NFTCardWidget } from '@anchorage/common/dist/widgets/NFT';
import type { NFTImage } from '@anchorage/common/dist/widgets/NFT/_types';

import type {
  AmountFieldsFragment,
  TokenInfoFieldsFragment,
} from 'generated/graphql';

import css from './styles.module.scss';

import AssetQuantityDetails from '../../shared/AssetQuantityDetails';

type Props = {
  amount: AmountFieldsFragment;
  tokenInfo?: TokenInfoFieldsFragment | null;
  hasPriceTimeTooltip?: boolean;
  indicateDifference?: boolean;
};

const Amount = ({
  amount,
  tokenInfo,
  hasPriceTimeTooltip = false,
  indicateDifference = false,
}: Props) => {
  const isNFT = !!tokenInfo;

  const nftImage: NFTImage = {
    src: tokenInfo?.imageURL || '',
    alt: `${tokenInfo?.name || 'nft'}-image`,
  };

  if (isNFT) {
    const { tokenID, displayValue } = tokenInfo;
    const truncatedTokenID = truncateString(tokenID, 12, 15);

    return (
      <div className={css.nftContainer}>
        <div className={css.assetDetails}>
          <AssetIcon className={css.assetIcon} {...amount.assetTypeInfo} />
          {amount.displayValue} {amount.assetTypeInfo.name}
        </div>
        <NFTCardWidget
          title={tokenInfo.name || `#${truncatedTokenID}`}
          subtext={amount.assetTypeInfo.name}
          footerText={`Floor price: ${displayValue}`}
          images={[nftImage]}
          isCondensedView
        />
      </div>
    );
  }

  return (
    <div className={css.assetDetails}>
      <AssetIcon
        data-testid="amount-asset-icon"
        className={css.assetIcon}
        {...amount.assetTypeInfo}
      />
      <AssetQuantityDetails
        assetValue={amount.assetTypeInfo.displayValue}
        assetQuantity={amount.displayValue}
        abbreviation={amount.assetTypeInfo.abbreviation}
        totalValue={amount.displayValueInUSD}
        hasPriceTimeTooltip={hasPriceTimeTooltip}
        indicateDifference={indicateDifference}
      />
    </div>
  );
};

export default Amount;
