import cn from 'classnames';

const ArtTrackIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ArtTrack', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M44 26H28V22H44V26ZM44 14H28V18H44V14ZM28 34H44V30H28V34ZM24 18V30C24 32.2 22.2 34 20 34H8C5.8 34 4 32.2 4 30V18C4 15.8 5.8 14 8 14H20C22.2 14 24 15.8 24 18ZM21 30L16.5 24L13 28.51L10.5 25.5L7 30H21Z" />
    </svg>
  );
};

export { ArtTrackIcon };
