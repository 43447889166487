import { Badge } from '@latitude/badge';

import { useAppUser } from '@anchorage/frontoffice/contexts/AppUser';

export const OrgInfo = () => {
  const appUser = useAppUser();

  if (!appUser.organization) {
    return null;
  }

  const { organization } = appUser;

  return (
    <Badge variant="primary" priority="low">
      <>{organization.name}</>
    </Badge>
  );
};
