import cn from 'classnames';

const FormatColorTextIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatColorText', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path opacity="0.3" d="M0 40H48V48H0V40Z" />
      <path d="M22 6L11 34H15.5L17.75 28H30.25L32.5 34H37L26 6H22ZM19.25 24L24 11.33L28.75 24H19.25Z" />
    </svg>
  );
};

export { FormatColorTextIcon };
