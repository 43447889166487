export enum StandardizedStakingMode {
  // Wizards
  STAKE = 'STAKE',
  UNSTAKE = 'UNSTAKE',
  CLAIM_REWARDS = 'CLAIM_REWARDS',
  // Modals with one page only
  VIEW_DETAILS = 'VIEW_DETAILS',
  ENABLE_AUTO_COMPOUND = 'ENABLE_AUTO_COMPOUND',
  DISABLE_AUTO_COMPOUND = 'DISABLE_AUTO_COMPOUND',
}

export type AutoCompoundMode =
  | StandardizedStakingMode.ENABLE_AUTO_COMPOUND
  | StandardizedStakingMode.DISABLE_AUTO_COMPOUND;

export enum StandardizedStakingQueryParams {
  WALLET_ID = 'stakeWalletID',
  ASSETTYPE_ID = 'stakeAssetTypeID',
  STAKE_MODE = 'stakeMode',
  VALIDATOR_ADDRESS = 'validator',
}

// Wizard page indexes for standardized staking stake flow, so we don't have to remember the indexes
export enum StandardizedStakeFlowWizardPage {
  FIRST_TIME_STAKING = 0,
  DISCLOSURE = 1,
  STAKE = 2,
  AUTO_CLAIM_REWARDS = 3,
  REVIEW = 4,
}

export enum StandardizedUnstakeFlowWizardPage {
  INTRO = 0,
  UNSTAKE = 1,
  REVIEW = 2,
}

export enum StandardizedClaimRewardsFlowWizardPage {
  CLAIM_REWARDS = 0,
  REVIEW = 1,
}

// --- MIXPANEL ---

// Mixpanel event origin for standardized stake flow
export enum StandardizedStakeFlowOrigin {
  WALLET_CARD = 'WALLET_CARD', // "Stake More" button on wallet card
  STAKING_DETAILS_PAGE = 'STAKING_DETAILS_PAGE', // "Stake with different validator" button on staking details page
  // Not used yet
  STAKE_PAGE = 'STAKE_PAGE', // "Stake" button on stake page
}
