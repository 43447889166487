import cn from 'classnames';

const CropIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Crop', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M34 30H38V14C38 11.79 36.21 10 34 10H18V14H34V30ZM14 34V2H10V10H2V14H10V34C10 36.21 11.79 38 14 38H34V46H38V38H46V34H14Z" />
    </svg>
  );
};

export { CropIcon };
