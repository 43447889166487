import { useMemo } from 'react';

import {
  AmountInputStep,
  AmountListStep,
  StakingStepImplementations,
  StandardizedStakingStepFragment,
} from 'generated/graphql';

import StakingAmountInputStep from './StakingAmountInputStep';
import StakingAmountListStep from './StakingAmountListStep';
import StakingAutoCompoundStep from './StakingAutoCompoundStep';
import StakingValidatorInputStep from './StakingValidatorInputStep';

interface Props {
  step: StandardizedStakingStepFragment;
}

const GenericStakingStep = ({ step }: Props) => {
  const StakingStepComponent = useMemo(() => {
    switch (step.__typename) {
      case StakingStepImplementations.AmountInputStep:
        return <StakingAmountInputStep step={step as AmountInputStep} />;
      case StakingStepImplementations.ValidatorInputStep:
        return <StakingValidatorInputStep />;
      case StakingStepImplementations.AutoCompoundStep:
        return <StakingAutoCompoundStep step={step} />;
      case StakingStepImplementations.AmountListStep:
        return <StakingAmountListStep step={step as AmountListStep} />;
      case StakingStepImplementations.AddressInputStep:
        // Not implemented on iOS
        // So we won't implement for FE for now
        return <></>;
      default:
        console.error(`Unsupported staking step type: ${step.__typename}`);
        return null;
    }
  }, [step]);

  return <div>{StakingStepComponent}</div>;
};
export default GenericStakingStep;
