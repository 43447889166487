import { useMemo } from 'react';

import { useDeepCompare } from '@anchorage/common/dist/hooks';
import { SLOW_POLL_INTERVAL } from '@anchorage/frontoffice/constants/apollo';
import usePollingQueryV2 from '@anchorage/frontoffice/hooks/usePollingQueryV2';

import {
  type UnattributedDepositsQuery,
  type UnattributedDepositsQueryVariables,
  useUnattributedDepositsQuery,
} from 'generated/graphql';

interface UnattributedDepositsTotalHookResult {
  total: number | undefined;
  totalForVault: number | undefined;
  updateTotals: () => void;
}

const useUnattributedDeposits = (
  vaultID: string | null,
): UnattributedDepositsTotalHookResult => {
  const { data, refetch } = usePollingQueryV2<
    UnattributedDepositsQuery,
    UnattributedDepositsQueryVariables
  >(useUnattributedDepositsQuery, {
    pollInterval: SLOW_POLL_INTERVAL,
    initialFetchPolicy: 'no-cache',
  });

  // Get the total number of deposit attributions
  const total = useMemo(
    () => {
      const totalResults = data?.depositAttributions.pageInfo.totalResults;
      if (
        totalResults !== null &&
        totalResults !== undefined &&
        totalResults >= 0
      ) {
        return totalResults;
      }
      return undefined;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([data]),
  );

  // If a vaultID is provided, we want to get the total number of deposit attributions for that vault
  const totalForVault = useMemo(
    () => {
      if (vaultID) {
        const depositAttributionsForVault =
          data?.depositAttributions?.edges?.filter((edge) => {
            // Because these are grouped by operation, there should only be one attribution per edge
            const attribution = edge?.node?.attributions[0];
            const itemVaultID =
              attribution?.operation?.receivingWallet?.vault?.vaultID
                ?.vaultSubID;
            return itemVaultID === vaultID;
          });
        return depositAttributionsForVault?.length;
      }
      return undefined;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([data, vaultID]),
  );

  return { total, updateTotals: refetch, totalForVault };
};

export default useUnattributedDeposits;
