import cn from 'classnames';

const LaptopIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Laptop', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M40 36C42.2 36 44 34.2 44 32V12C44 9.8 42.2 8 40 8H8C5.8 8 4 9.8 4 12V32C4 34.2 5.8 36 8 36H0V40H48V36H40ZM8 12H40V32H8V12Z" />
    </svg>
  );
};

export { LaptopIcon };
