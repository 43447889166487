import cn from 'classnames';

const AirlineSeatLegroomReducedIcon = (props: SVGProps): JSX.Element => {
  const {
    title = 'AirlineSeatLegroomReduced',
    className,
    ...restProps
  } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M39.94 38.41C40.31 40.32 38.85 42 37 42H28V36L30 28H18C14.7 28 12 25.3 12 22V6H24V18H34C36.21 18 38 19.79 38 22L34 36H36.89C38.34 36 39.67 36.98 39.94 38.41ZM10 24V6H6V24C6 29.52 10.48 34 16 34H24V30H16C12.69 30 10 27.31 10 24Z" />
    </svg>
  );
};

export { AirlineSeatLegroomReducedIcon };
