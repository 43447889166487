import * as React from 'react';

import Tabs, { Tab } from '@anchorage/common/dist/components/Tabs';

import css from './styles.module.scss';

import { OPERATION_TABS } from '../../constants';

const TABS = [OPERATION_TABS.DETAILS, OPERATION_TABS.OPERATION];

type TabLabel = OPERATION_TABS;

type Props = {
  tabContents: Record<TabLabel, React.ReactNode>;
};

const OperationTabsAndContent = ({ tabContents }: Props) => {
  const [currentTab, setCurrentTab] = React.useState<TabLabel>(
    OPERATION_TABS.DETAILS,
  );

  return (
    <>
      <Tabs
        className={css.tabsWrapper}
        align="left"
        data-testid="operation-drawer-tabs"
        label="operation-drawer-tabs"
      >
        {TABS.map((tab: string) => (
          <Tab
            key={tab}
            className={css.tab}
            selected={tab === currentTab}
            onClick={() => setCurrentTab(tab as TabLabel)}
          >
            {tab}
          </Tab>
        ))}
      </Tabs>
      {tabContents[currentTab]}
    </>
  );
};

export default OperationTabsAndContent;
