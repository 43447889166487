import cn from 'classnames';

const LastPageIcon = (props: SVGProps): JSX.Element => {
  const { title = 'LastPage', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M11.1799 14.82L20.3599 24L11.1799 33.18L13.9999 36L25.9999 24L13.9999 12L11.1799 14.82ZM31.9999 12H35.9999V36H31.9999V12Z" />
    </svg>
  );
};

export { LastPageIcon };
