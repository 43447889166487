import type { ApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { reportError } from '@anchorage/common/dist/utils/errors';

import { LOGIN_PATH } from '../../utils/routes';
import { logout } from '../../utils/userSessionApi';

export const useUserSessionManager = (gqlClient?: ApolloClient<unknown>) => {
  const router = useRouter();

  // TODO: Add the rest of the operations here (generateSessionId, claimSession)
  // Not adding those now, since the login page is a class component and hooks don't work in
  // class components

  const logoutUser = useCallback(async () => {
    let res;

    try {
      res = await logout();
      // 401 means the user probably clicked logout twice (which we can ignore)
      if (!res.ok && res.status !== 401) {
        throw new Error('Unable to log out with error ' + res.statusText);
      }
      // Clear the apollo cache
      gqlClient?.clearStore();

      router.push(LOGIN_PATH);
    } catch (e) {
      const err = e as Error;
      reportError(err);
    }
  }, [gqlClient, router]);

  return { logoutUser };
};
