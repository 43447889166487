import {
  isFragmentBindingNotInternalFilterFn,
  legacyPoliciesFilterFn,
} from './utils';

import { PolicySourceOfTruth } from '../generated/graphql';

import type { BarePolicyInfo, PoliciesContainerV1 } from '../types';

import { Policy } from './Policy';

export class PolicyContainer {
  readonly policies: Policy[] = [];

  constructor(
    private readonly sourceOfTruth: PolicySourceOfTruth = PolicySourceOfTruth.POLICY_SOT_CONTAINER,
    policiesContainerV1: PoliciesContainerV1,
    policiesContainerV2: BarePolicyInfo | null,
  ) {
    const v1Policies = this.parseV1PolicyContainer(policiesContainerV1);

    if (!policiesContainerV2) {
      this.policies = v1Policies;
      return;
    }

    switch (this.sourceOfTruth) {
      case PolicySourceOfTruth.POLICY_SOT_LEGACY_EXCLUSIVE:
      case PolicySourceOfTruth.POLICY_SOT_LEGACY:
        this.policies = v1Policies;
        break;
      case PolicySourceOfTruth.POLICY_SOT_CONTAINER: {
        const v2Policies = this.parseV2PolicyContainer(policiesContainerV2);

        this.policies = v2Policies;
        break;
      }
    }
  }

  private parseV1PolicyContainer(policiesContainerV1: PoliciesContainerV1) {
    return policiesContainerV1.policies
      .filter(legacyPoliciesFilterFn)
      .map(
        (policy) => new Policy(PolicySourceOfTruth.POLICY_SOT_LEGACY, policy),
      );
  }

  private parseV2PolicyContainer(policiesContainerV2: BarePolicyInfo) {
    return (
      policiesContainerV2.appliedPolicy?.fragmentContainer?.fragmentBindings
        .filter(isFragmentBindingNotInternalFilterFn)
        .map(
          (policy) =>
            new Policy(PolicySourceOfTruth.POLICY_SOT_CONTAINER, policy),
        ) ?? []
    );
  }
}
