import { differenceInMonths } from 'date-fns';

import {
  UIDeveloperError,
  reportError,
} from '@anchorage/common/dist/utils/errors';

import { SurveyRecord } from './_types';

const getLocalStorage = (surveyName: string = '') => {
  if (!surveyName) {
    return [];
  }

  try {
    return JSON.parse(localStorage.getItem(surveyName) || '[]') || [];
  } catch (error) {
    reportError(new UIDeveloperError(`Error parsing local storage: ${error}`));
    return [];
  }
};

export function registerSurveyShown(
  surveyName: string = '',
  currentUserEmail: string = '',
) {
  if (!surveyName || !currentUserEmail) {
    return false;
  }

  const localStorageCopy: SurveyRecord[] = getLocalStorage(surveyName);
  const record = {
    ...localStorageCopy.find((item) => item.email === currentUserEmail),
  };
  record.shownCount = (record.shownCount || 0) + 1;
  record.lastShownDate = new Date();
  record.email = currentUserEmail;

  const newLocalStorage = JSON.stringify([
    ...localStorageCopy.filter((item) => item.email !== currentUserEmail),
    record,
  ]);
  localStorage.setItem(surveyName, newLocalStorage);
}

export function registerSurveyClosed(
  surveyName: string = '',
  currentUserEmail: string = '',
) {
  const localStorageCopy: SurveyRecord[] = getLocalStorage(surveyName);
  const record = {
    ...localStorageCopy.find((item) => item.email === currentUserEmail),
  };
  record.lastShownDate = new Date();
  record.closed = true;
  record.email = currentUserEmail;

  const newLocalStorage = JSON.stringify([
    ...localStorageCopy.filter((item) => item.email !== currentUserEmail),
    record,
  ]);
  localStorage.setItem(surveyName, newLocalStorage);
}

export function registerSurveyTaken(
  surveyName: string = '',
  currentUserEmail: string = '',
) {
  const localStorageCopy: SurveyRecord[] = getLocalStorage(surveyName);
  const record = {
    ...localStorageCopy.find((item) => item.email === currentUserEmail),
  };
  record.submitted = true;
  record.lastShownDate = new Date();
  record.email = currentUserEmail;

  const newLocalStorage = JSON.stringify([
    ...localStorageCopy.filter((item) => item.email !== currentUserEmail),
    record,
  ]);
  localStorage.setItem(surveyName, newLocalStorage);
}

export function getHasSurveyBeenTaken(
  surveyName: string = '',
  currentUserEmail: string = '',
) {
  if (!surveyName || !currentUserEmail) {
    return false;
  }

  const localStorageCopy: SurveyRecord[] = getLocalStorage(surveyName);
  const record = {
    ...localStorageCopy.find((item) => item.email === currentUserEmail),
  };

  return record && record.submitted;
}

export function getShouldShowSurvey(
  surveyName: string = '',
  currentUserEmail: string = '',
) {
  if (!surveyName || !currentUserEmail) {
    return false;
  }

  const localStorageCopy: SurveyRecord[] = getLocalStorage(surveyName);
  const record = {
    ...localStorageCopy.find((item) => item.email === currentUserEmail),
  };

  if (!record.email) {
    return true;
  }

  const hasBeenClosed = record.closed;
  const hasBeenSubmitted = record.submitted;
  const shownCount = record.shownCount || 0;
  const lastShownDate = record.lastShownDate;

  const hasBeenShownEnoughTimes = shownCount >= 5 || hasBeenClosed;
  const hasBeenShownRecently =
    lastShownDate &&
    differenceInMonths(new Date(), new Date(lastShownDate)) < 3;

  return (
    !hasBeenSubmitted && (!hasBeenShownEnoughTimes || !hasBeenShownRecently)
  );
}
