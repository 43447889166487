import cn from 'classnames';

const AdbIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Adb', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M10 32C10 39.73 16.27 46 24 46C31.73 46 38 39.73 38 32V24H10V32ZM32.25 8.74001L36.45 4.54001L34.8 2.89001L30.19 7.50001C28.31 6.57001 26.23 6.00001 24 6.00001C21.77 6.00001 19.69 6.57001 17.81 7.50001L13.2 2.89001L11.55 4.54001L15.75 8.74001C12.28 11.28 10 15.36 10 20V22H38V20C38 15.36 35.72 11.28 32.25 8.74001ZM18 18C16.89 18 16 17.1 16 16C16 14.9 16.89 14 18 14C19.11 14 20 14.9 20 16C20 17.1 19.11 18 18 18ZM30 18C28.89 18 28 17.1 28 16C28 14.9 28.89 14 30 14C31.11 14 32 14.9 32 16C32 17.1 31.11 18 30 18Z" />
    </svg>
  );
};

export { AdbIcon };
