import cn from 'classnames';

const ContactMailIcon = (props: SVGProps): JSX.Element => {
  const { title = 'ContactMail', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M42 16V14L36 18L30 14V16L36 20L42 16ZM44 6H4C1.79 6 0 7.79 0 10V38C0 40.21 1.79 42 4 42H44C46.21 42 47.98 40.21 47.98 38L48 10C48 7.79 46.21 6 44 6ZM16 12C19.31 12 22 14.69 22 18C22 21.32 19.31 24 16 24C12.69 24 10 21.32 10 18C10 14.69 12.69 12 16 12ZM28 36H4V34C4 30 12 27.8 16 27.8C20 27.8 28 30 28 34V36ZM44 24H28V12H44V24Z" />
    </svg>
  );
};

export { ContactMailIcon };
