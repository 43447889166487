import { READABLE_USER_TYPES } from 'constants/operations';
import { useMemo } from 'react';

import { OperationPolicies } from '@anchorage/authorization';
import { Text } from '@anchorage/common/dist/components';
import { useDeepCompare } from '@anchorage/common/dist/hooks';
import { useFeatureFlagsContext } from '@anchorage/feature-flags';
import { FEATURE_FLAGS } from '@anchorage/frontoffice/constants/split';

import type { OperationDrawer } from 'generated/graphql';

import css from './styles.module.scss';

import SectionRow from '../../../shared/SectionRow';
import WidgetWrapper from '../../../shared/WidgetWrapper';
import {
  OperationDetailPolicyWidget,
  OperationDetailVaultPolicyWidget,
} from '../../../widgets/Policies/OperationDetailPolicyWidget';

type AddUserAndPoliciesType = Extract<
  OperationDrawer.operation,
  { __typename: 'AddUserAndPoliciesOperation' }
>;

type RemoveUserAndPoliciesType = Extract<
  OperationDrawer.operation,
  { __typename: 'RemoveUserAndPoliciesOperation' }
>;

type Props = {
  operation: AddUserAndPoliciesType | RemoveUserAndPoliciesType;
};

const UserPoliciesList = ({ operation }: Props) => {
  const { useIsFeatureActive } = useFeatureFlagsContext();
  const [isPermissionsM3_1Active] = useIsFeatureActive(
    FEATURE_FLAGS.CD_PERMISSIONS_M3_1,
  );

  const title =
    operation.__typename === 'AddUserAndPoliciesOperation'
      ? 'Add to'
      : 'Remove from';

  const userInfo = {
    title: operation.operationInfo.organization?.name,
    subtitle: `as ${READABLE_USER_TYPES[operation.user.userType]}`,
  };

  const opPolicies = useMemo(
    () => new OperationPolicies(operation),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare(operation),
  );

  return (
    <>
      <WidgetWrapper>
        <SectionRow title="Email">
          <Text>{(operation as AddUserAndPoliciesType).user?.email || ''}</Text>
        </SectionRow>
      </WidgetWrapper>
      <WidgetWrapper>
        <SectionRow title={title}>
          <Text>{userInfo.title}</Text>
          {!isPermissionsM3_1Active && (
            <Text className={css.role} size="small">
              {userInfo.subtitle}
            </Text>
          )}
        </SectionRow>
      </WidgetWrapper>
      <OperationDetailPolicyWidget
        type={operation.__typename}
        policies={opPolicies}
      />
      <OperationDetailVaultPolicyWidget
        policies={opPolicies}
        type={operation.__typename}
      />
    </>
  );
};

export default UserPoliciesList;
