import { VariantProps } from 'class-variance-authority';
import cn from 'classnames';
import { Command as CommandPrimitive } from 'cmdk';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { commandItemVariants } from 'variants/item-variants';

type CommandSeparatorProps = ComponentPropsWithoutRef<
  typeof CommandPrimitive.Separator
> &
  Pick<VariantProps<typeof commandItemVariants>, 'size' | 'state'>;

/**
 * Represents a separator for commands in the action list.
 */
export const CommandSeparator = forwardRef<
  ElementRef<typeof CommandPrimitive.Separator>,
  CommandSeparatorProps
>(({ className, size, state, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cn(commandItemVariants({ size, state }), className)}
    {...props}
  />
));

CommandSeparator.displayName = CommandPrimitive.Separator.displayName;
