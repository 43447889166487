import _groupBy from 'lodash/groupBy';
import _pluralize from 'pluralize';

import { Text } from '@anchorage/common/dist/components';

import css from './styles.module.scss';

import type { RoleChange } from '../../types';

const UpdatedIAMRoleResourceAssignmentsTitle = ({
  roleChange,
  expanded = false,
}: {
  roleChange: RoleChange;
  expanded: boolean;
}) => {
  const resourceByType = _groupBy(roleChange.resources, '__typename');
  const title = roleChange.roleSnapshot.name;
  let subtitle = 'in ';

  Object.entries(resourceByType).forEach(([type, resources], index, array) => {
    if (resources.length > 1) {
      subtitle = subtitle.concat(
        `${resources.length} ${_pluralize(
          type.toLowerCase(),
          resources.length,
        )}`,
      );
    } else {
      subtitle = subtitle.concat(`${resources[0]?.name}`);
    }
    if (index !== array.length - 1) {
      subtitle = subtitle.concat(' and ');
    }
  });

  return (
    <>
      <Text size="small">{title}</Text>
      {!expanded && (
        <Text size="small" className={css.subtitle}>
          {subtitle}
        </Text>
      )}
    </>
  );
};

export default UpdatedIAMRoleResourceAssignmentsTitle;
