import cn from 'classnames';

const AccessibleIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Accessible', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 8C28 10.2091 26.2091 12 24 12C21.7909 12 20 10.2091 20 8C20 5.79086 21.7909 4 24 4C26.2091 4 28 5.79086 28 8ZM38 22.0001V26.0001C34.49 25.9901 30.58 24.2301 28 22.1001V29.0001H34C36.21 29.0001 38 30.7901 38 33.0001V44.0001H34V34.0001H24C21.79 34.0001 20 32.2101 20 30.0001V18.1801C20 16.0901 21.53 14.2201 23.61 14.0201C24.48 13.9301 25.3 14.1301 25.99 14.5301H26.01C26.02 14.5401 26.03 14.5501 26.05 14.5601C26.5 14.7901 26.92 15.0901 27.27 15.4701L29.86 18.3301C31.82 20.5001 34.93 22.0401 38 22.0001ZM20 40.0001C22.61 40.0001 24.83 38.3301 25.65 36.0001H29.8C28.87 40.5601 24.84 44.0001 20 44.0001C14.48 44.0001 10 39.5201 10 34.0001C10 29.1601 13.44 25.1301 18 24.2001V28.3501C15.67 29.1701 14 31.3901 14 34.0001C14 37.3101 16.69 40.0001 20 40.0001Z"
      />
    </svg>
  );
};

export { AccessibleIcon };
