import cn from 'classnames';
import { Command as CommandPrimitive } from 'cmdk';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

/**
 * Renders a list of commands.
 *
 * @component
 * @example
 * ```tsx
 * <CommandList>
 *   <CommandItem>Command 1</CommandItem>
 *   <CommandItem>Command 2</CommandItem>
 *   <CommandItem>Command 3</CommandItem>
 * </CommandList>
 * ```
 */
export const CommandList = forwardRef<
  ElementRef<typeof CommandPrimitive.List>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.List> & {
    hasSearchInput?: boolean;
  }
>(({ hasSearchInput, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn('no-scrollbar max-h-[300px] overflow-scroll', {
      'h-min-[135px]': hasSearchInput,
    })}
    {...props}
  />
));

CommandList.displayName = CommandPrimitive.List.displayName;
