import Text from '@latitude/text';

import { WalletStakingDetails } from 'generated/graphql';

import ValidatorCard from '../ValidatorCard';

interface Props {
  stakingPositions: WalletStakingDetails._node[];
}

const ValidatorsList = ({ stakingPositions }: Props) => {
  return (
    <div className="mt-6">
      <Text variant="headingTiny" className="mb-2">
        Validators
      </Text>
      <div className="max-h-[480px] overflow-y-visible *:mb-2">
        {stakingPositions.map((stakingPosition) => (
          <ValidatorCard
            key={stakingPosition.validatorDestination}
            stakingPosition={stakingPosition}
          />
        ))}
        {stakingPositions.length === 0 && <Text>No validators found</Text>}
      </div>
    </div>
  );
};

export default ValidatorsList;
