import cn from 'classnames';

const LogoutIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Logout-48px', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M38 6C40.22 6 42 7.8 42 10V16H38V10H10V38H38V32H42V38C42 40.2 40.22 42 38 42H10C7.8 42 6 40.2 6 38V10C6 7.8 7.8 6 10 6H38ZM31 34L41 24L31 14L28.18 16.82L33.34 22H14V26H33.34L28.18 31.18L31 34Z" />
    </svg>
  );
};

export { LogoutIcon };
