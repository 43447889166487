import cn from 'classnames';

const CallEndIcon = (props: SVGProps): JSX.Element => {
  const { title = 'CallEnd', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M24 18C20.79 18 17.7 18.5 14.8 19.44V25.65C14.8 26.44 14.34 27.12 13.68 27.45C11.73 28.43 9.94 29.68 8.35 31.15C7.99 31.5 7.5 31.72 6.95 31.72C6.4 31.72 5.9 31.5 5.54 31.13L0.59 26.18C0.22 25.81 0 25.31 0 24.76C0 24.21 0.22 23.71 0.59 23.34C6.68 17.55 14.93 14 24 14C33.07 14 41.32 17.55 47.41 23.34C47.78 23.7 48 24.21 48 24.76C48 25.31 47.78 25.81 47.41 26.17L42.46 31.12C42.1 31.48 41.6 31.71 41.05 31.71C40.51 31.71 40.01 31.49 39.65 31.14C38.06 29.67 36.27 28.42 34.32 27.44C33.66 27.11 33.2 26.43 33.2 25.64V19.43C30.3 18.5 27.21 18 24 18Z" />
    </svg>
  );
};

export { CallEndIcon };
