import cn from 'classnames';

const FormatBoldIcon = (props: SVGProps): JSX.Element => {
  const { title = 'FormatBold', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path d="M31.2 21.58C33.13 20.23 34.5 18.05 34.5 16C34.5 11.49 31.01 8 26.5 8H14V36H28.08C32.27 36 35.5 32.6 35.5 28.42C35.5 25.38 33.77 22.79 31.2 21.58ZM20 13H26C27.66 13 29 14.34 29 16C29 17.66 27.66 19 26 19H20V13ZM27 31H20V25H27C28.66 25 30 26.34 30 28C30 29.66 28.66 31 27 31Z" />
    </svg>
  );
};

export { FormatBoldIcon };
